import { useCallback, useRef, useState } from 'react';
import {
    makeStyles, InputAdornment, Menu, MenuItem, IconButton, Tooltip, OutlinedInput
} from '@material-ui/core';
import { useDebounceState } from '../../utils/utils';
import ClearFilterButton from './ClearFilterButton';

const useStyles = makeStyles(theme => ({
    input: {
        ...theme.typography.caption,
        backgroundColor: theme.palette.background.paper,
        '& input': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    }
}))

const items = [
    {op: "text", icon: "≈", label: "Partial String"},
    {op: "startswith", icon: "s", label: "Starts With (% wildcard)"},
    {op: "like", icon: "=", label: "Exact String (% wildcard)"},
    {op: "~", icon: "r", label: "Regular Expression"},
]

export default function DefaultFilterInput({column: { filterValue, setFilter, filter, simpleFilter }}){
    const classes = useStyles();
    const openRef = useRef();
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => setOpen(true),[]);
    const handleClose = useCallback(() => setOpen(false),[]);
    const [operation, setOperation] = useState(items[0]);
    const [value, setValue, externalUpdate] = useDebounceState(filterValue, setFilter);
    if (externalUpdate && value?.op && operation.op !== value?.op){
        setOperation(items.find(i => i.op === value.op) || items[0]);
    }
    const handleClear = useCallback(() => setValue(undefined), [setValue]);
    const handleChange = useCallback((query, op) => {
        if (!query || query === ""){
            setValue(undefined);
        }
        else{
            setValue({query, type: op});
        }
    }, [setValue]);
    const handleClick = useCallback((item) => {
        setOperation(item);
        handleChange(value?.query, item.op)
        handleClose();
    },[handleClose, handleChange, value]);
    return (
        <OutlinedInput
            variant="outlined"
            value={value?.query || ""}
            onChange={ev => setValue((ev.target.value && ev.target.value !== "") ? {query: ev.target.value, type: filter || operation?.op} : undefined)}
            placeholder="Filter..."
            fullWidth
            className={classes.input}
            startAdornment={!filter ?
                <InputAdornment position="start">
                    <Tooltip title={operation.label}>
                        <IconButton ref={openRef} onClick={handleOpen} size="small">
                            {operation.icon}
                        </IconButton>
                    </Tooltip>
                    <Menu anchorEl={openRef.current} open={open} onClose={handleClose}>
                        {(simpleFilter ? [items[0]] : items).map((item, i) => 
                            <MenuItem key={i} onClick={()=>handleClick(item)}>{item.label}</MenuItem>
                        )}
                    </Menu>
                </InputAdornment> : undefined
            }
            endAdornment={ (value && value !== "") ? 
                <InputAdornment position="end">
                    <ClearFilterButton onClick={handleClear}/>
                </InputAdornment> : undefined
            }
            />
    );
}