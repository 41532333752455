import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import SmilesSVG from '../General/SmilesSVG';

const useStyles = makeStyles(theme => ({
    defaultCell: {
        display: "block",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tip: {
        background: "#00000080",
        borderRadius: 4,
        marginTop: 4,
        marginBottom: 4,
        padding: theme.spacing(1),
        width: 200,
        height: 200,
    }
}))

export default function SmilesCell({cell}){
    const classes = useStyles();
    const value = (cell.value && String(cell.value) !== "") ? (Array.isArray(cell.value) ? cell.value.map(v => String(v).trim()).join('; ') : String(cell.value)) : '\xa0';
    return (
        <Tooltip 
            placement="top"
            title={(value === "\xa0") ? "" : 
                <div className={classes.tip}>
                    <SmilesSVG smiles={value} theme="dark"/>
                </div>
            }

        >
            <div className={classes.defaultCell}>
                <span>{value}</span>
            </div>
        </Tooltip>
    )
}