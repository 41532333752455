import React, { useState } from 'react';
import { useQueryAutocomplete } from '../../API/queryHooks';
import MultiSelector from './MultiSelector';

/**
 * React Component for performing a multiselect on on instruments
 * @param props most props forwared to MultiSelector
 * @param props.selected forwared as the value prop
 */
export default function InstrumentSelector({selected, onChange, ...remainingProps }){
    const [input, setInput] = useState({});
    const { data: {data: options} = {}, isFetching } = useQueryAutocomplete(input, {Model: "Instrument"}, {logName: "Instrument Selector"});
    return (
        <MultiSelector
            label="Select Instrument"
            missingLabel="Missing or Private Instrument"
            loading={isFetching}
            options={options || []}
            value={selected || []}
            onBlur={() =>  setInput({})}
            onChange={(...args) => {onChange && onChange(...args); setInput({})}}
            inputValue={input.title || ""}
            onInputChange={(ev, newVal) => {ev && setInput({title: ev?.target?.value || ""})}}
            data-testid="instrument selector"
            {...remainingProps}
            />
    );
}