import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { aichemyProtoAxios } from "../../API/mmAxios";
import { AppDispatchContext, AppStateContext } from "../../AppStore";
import Box from "@material-ui/core/Box";

const isIE = /*@cc_on!@*/ !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function SaveNonImageFile(blob, filename) {
  let objectURL = URL.createObjectURL(blob);
  if (isEdge || isIE) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = objectURL;
    anchor.target = "_blank";
    anchor.download = filename;
    anchor.click();
    document.body.removeChild(anchor);
  }
}

export function sleep(milliseconds) {
  if (milliseconds > 0) {
    console.log('sleep ' + milliseconds)
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }

}
export let getTaskResults = (task_id) => {
  let url = `tasks/` + task_id + `/result`;
  console.log(`retrieving task results - task_id:${task_id}`);
  if (task_id === "") {
    console.error("No task id found");
    return "";
  }
  let config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  return aichemyProtoAxios(url, [], config)
};


export let checkTaskStatus = (task_id) => {
  let url = `tasks/` + task_id;
  console.log(`checkTaskStatus - task_id:${task_id}`);
  if (task_id === "") {
    console.error("No task id found");
    return "";
  }
  let config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  };
  return aichemyProtoAxios(url, [], config)
    .then((res) => {
      console.log("task status from API: " + res.data.status);
      return res;
    })
    .catch((res) => {
      console.error(res);
    });
};


export async function poll(fn, fnCondition, ms, args) {
  let result = await fn(args);
  while (!fnCondition(result)) {
    await wait(ms);
    result = await fn(args);
  }
  return result;
}

export function wait(ms = 1000) {
  return new Promise(resolve => {
    console.log(`waiting ${ms} ms...`);
    setTimeout(resolve, ms);
  });
}

// convert workflow.data to a list
export function data_to_list(data) {
  let colNames = ''
  let data_list = []
  if (data && data !== "[]" && data.length > 0) { // catch for when the data frame gets nuked on Aichemy 
    colNames = Object.keys(data[0]);
    data.forEach((item) => {
      let row = []
      colNames.forEach((colName) => row.push(item[colName]))
      data_list.push(row)
    })
  }
  return { colNames: colNames, data: data_list }
}

// export dataToList to allow for camelcasing without breaking usage elsewhere
export function dataToList(data) {
  return data_to_list(data);
}

const colIsRaw = (col, transform) => transform[col].raw

const getParentCol = (col, transform) => {
  if (colIsRaw(col, transform)) {
    return [col]
  } else {
    let oriCols = []
    let prevCols = Object.keys(transform).filter(
      (colName) => transform[colName].children.indexOf(col) > -1,
    )
    for (let index = 0; index < prevCols.length; index++) {
      oriCols = [...oriCols, ...getParentCol(prevCols[index], transform)]
    }
    return oriCols
  }
}

export let getOriColumns = (transform, cols) => {
  let oriCols = []
  for (let i = 0; i < cols.length; i++) {
    oriCols = [...oriCols, ...getParentCol(cols[i], transform)]
  }
  return [...new Set(oriCols)]
}

const splitPath = (path) => {
  const Re = /\[(.*?)]/g
  const matches = path.match(Re)
  if (!matches) {
    console.log(`path ${path} is not a valid path`)
  }

  return matches.map((match) => {
    // remove first and last character aka [ and ]
    let match_new = match.slice(1, -1);
    // remove '
    match_new = match_new.replace(/'/g, '');
    return match_new
  })
}

const assignObjValue = (obj, path, value) => {
  let arr = splitPath(path)
  while (arr.length > 1) {
    obj = obj[arr.shift()];
  }
  return obj[arr[0]] = value;
}

const appendArrValue = (obj, path, value) => {
  let arr = splitPath(path)
  while (arr.length > 2) {
    obj = obj[arr.shift()];
  }
  if (!(obj[arr[0]] instanceof Array)) {
    // if the entry isn't a valid array, make it one
    obj[arr[0]] = [value];
  } else {
    // insert value at index arr[1]
    obj[arr[0]].splice(Number(arr[1]), 0, value);
  }
}

const removeArrValue = (obj, path, value) => {
  let arr = splitPath(path)
  while (arr.length > 1) {
    obj = obj[arr.shift()];
  }
  obj[arr[0]] = obj[arr[0]].filter((v, i) => value.indexOf(String(i)) === -1);
}

const removeObjValue = (obj, path) => {
  let arr = splitPath(path)
  while (arr.length > 1) {
    obj = obj[arr.shift()];
  }
  delete obj[arr[0]]
}

export const applyWorkflowDiff = (workflow, diff) => {
  //  e.g. diff = {
  //    'iterable_item_added': {
  //      "root['data']['info']['Sheet2']['input_cols'][1]": 'x2',
  //      "root['history']['data_cleaning'][2]":
  //        {'operations': 'preprocess',
  //         'operation_name': 'ABCMeta',
  //         'args': "{
  //            'func': <class 'core.workflow.preprocess.set_cols.SetInputCols'>,
  //            '*args': (),
  //            '**kwargs': {'cols': ['x1', 'x2'], 'record': False, 'run': True}}",
  //         'timestamp': '2022-03-21 23:33:54.709355-05:00'}},
  //    'values_changed': {
  //      "root['history']['data_prep'][0]['args']": "{'reader': <core.workflow.data_reader.ExcelFileReader object at 0xffff7c1aefd0>}"}}
  let newWorkflow = { ...workflow }
  Object.keys(diff).forEach((key) => {
    if (key === 'iterable_item_added') {
      const iia_ops = diff[key]
      Object.keys(iia_ops).forEach((op_path) => {
        const op_value = iia_ops[op_path]
        appendArrValue(newWorkflow, op_path, op_value)
      })

    } else if (key === 'values_changed') {
      const vc_ops = diff[key]
      Object.keys(vc_ops).forEach((op_path) => {
        const op_value = vc_ops[op_path]
        assignObjValue(newWorkflow, op_path, op_value)
      })
    } else if (key === 'iterable_item_removed') {
      const iir_ops = diff[key]
      const collectOps = {}
      // collect index in the same array
      Object.keys(iir_ops).forEach((op_path) => {
        const op_path_split = splitPath(op_path)
        const current_array_path = op_path_split.slice(0, -1).map((v) => '[' + v + ']').join('')
        const current_array_index = op_path_split.slice(-1)
        if (collectOps[current_array_path]) {
          collectOps[current_array_path].push(current_array_index[0])
        } else {
          collectOps[current_array_path] = current_array_index
        }
      })
      // apply the diff
      Object.keys(collectOps).forEach((op_path) => {
        const op_value = collectOps[op_path]
        removeArrValue(newWorkflow, op_path, op_value)
      })
    } else if (key === 'dictionary_item_removed') {
      const oir_ops = diff[key]
      Object.keys(oir_ops).forEach((op_path) => {
        removeObjValue(newWorkflow, op_path)
      })
    }
  })
  return newWorkflow
}

export const aichemyProtoUpdateWorkflow = (update_dict, workflow, setWorkflow, diff = true) => {

  const url = diff ? `workflow/` + workflow.uuid : `workflow/` + workflow.uuid + `?diff=false`
  const config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }
  return aichemyProtoAxios.post(url, JSON.stringify(update_dict), config)
    .then(res => {
      if (diff) {
        const diff = res.data
        const newWf = applyWorkflowDiff(workflow, diff)
        setWorkflow(newWf);
      } else {
        const wf = res.data
        // Object.keys(wf.data.data_df).forEach(key => wf.data.data_df[key] = JSON.parse(wf.data.data_df[key]))
        setWorkflow(wf);
      }


    })
}

export const aichemyProtoSaveFigure = (update_dict, workflow, setWorkflow, setUuid, handleRemovePlot, idx) => {

  const url = `workflow/` + workflow.uuid + `?diff=false`
  const config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }
  // const nSavedFigure = workflow.viz_plot_params.length
  return aichemyProtoAxios.post(url, JSON.stringify(update_dict), config)
    .then(res => {
      const wf = res.data
      // Object.keys(wf.data.data_df).forEach(key => wf.data.data_df[key] = JSON.parse(wf.data.data_df[key]))
      setWorkflow(wf);
      // const nNewSavedFigure = wf.viz_plot_params.length
      // if (nNewSavedFigure > nSavedFigure) {
      //   setUuid(wf.viz_plot_params[nNewSavedFigure-1].uuid)
      // } else {
      //   setUuid('')
      // }
      handleRemovePlot && handleRemovePlot(idx)
    })
}

export const aichemyProtoUpdateData = (new_data, workflow, setWorkflow) => {
  // schema of new_data:
  // {
  //   sheet_name: 'new_sheet',
  //       data: {
  //   "col0":{"0":1,"1":2},
  //   "col1":{"0":2,"1":3},
  //   "col2":{"0":3,"1":4}
  // }
  // }
  const url = `workflow/` + workflow.uuid + `/data`
  const config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }
  return aichemyProtoAxios.post(url, JSON.stringify(new_data), config)
    .then(res => {
      const wf = res.data
      // Object.keys(wf.data.data_df).forEach(key => wf.data.data_df[key] = JSON.parse(wf.data.data_df[key]))
      setWorkflow(wf);
    })
}

export const getMSConfig = (setMSConfig) => {
  const url = `config`
  const config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }
  aichemyProtoAxios.get(url, config)
    .then(res => {
      const config = res.data
      setMSConfig(config)
    }).catch(err => {
      console.log("Loading config failed", err)
    })
}


export const ioIsDefined = (wf) => {
  const active_sheet = wf.data.active_sheet
  return wf.data.info[active_sheet].input_cols.length > 0 && wf.data.info[active_sheet].output_cols.length > 0;
}

export const outputIsCatEncode = (wf) => {
  // check if the output is categorical encoded
  const active_sheet = wf.data.active_sheet
  const output_cols = wf.data.info[active_sheet].output_cols
  const transform = wf.data.info[active_sheet].columns_transform
  return output_cols.some(col => transform[col].transformed_by === 'CategoricalEncoder.forward')
}

export const outputContainsString = (wf) => {
  const active_sheet = wf.data.active_sheet
  const output_cols = wf.data.info[active_sheet].output_cols
  return output_cols.some(col => {
    const col_vals = wf.data.data_df[active_sheet].map(row => row[col])
    return col_vals.some(val => typeof val === 'string')
  })
}


export const useMSState = (stateName) => {
  const state = useRef(useContext(AppStateContext));
  const dispatch = useContext(AppDispatchContext);
  const update = useCallback((payload) => dispatch({ payload: { MixingStudioState: { ...state.current, stateName: payload } } }), [dispatch]);
  return [state['MixingStudioState'][stateName], update];

}

export const defaultMixingStudioState = (wf) => {
  const visibleModelIdx = wf?.models.map((model, idx) => model.hide ? "" : idx).filter(idx => idx !== "")
  return {
    nLint: 0,    // number of linting problem
    nViewedPFuncChanges: wf ? wf.history.data_cleaning.filter(d => !d.args.includes("set_cols")).length : 0, // number of viewed preprocess function changes the user has seen
    excludedRules: [],  // excluded rules for AIchemyLint
    lintResults: [],    // lint results
    AIchemyLintExpand: true,  // expand AIChemyLint card
    ioSelectionExpand: true,  // expand IO selectin card
    addPFExpanded: true,      // expand Add preprocess function card
    plotCurrentList: [],      // current displayed plots
    plotParams: [],         // parameters list for current displayed plots
    plotLayout: [],         // layout list for current displayed plots
    submittedModel: wf ? wf.models.map(model => model) : [],      // submited model list
    submittedShap: wf ? wf.models.map(model => {
      if (Object.keys(model.plot).indexOf('shap') !== -1) {
        return { status: "Finished" }
      } else {
        return {
          task_id: "",
          status: "Not Submitted"
        }
      }
    }) : [],      // submited shap list: { task_id, status }, status: "Not Submitted", "Queued", "Running", "Finished"
    modelCurrentTab: wf ? visibleModelIdx[0] : 0,     // current tab for model card
    modelComparePlots: [],  // model compare plots
    modelComparePlotLayout: [],  // layout list for model compare plots
    modelComparePlotParams: [],  // parameters list for model compare plots
    pfCurrentList: ['catEnc', 'dropEmptyCells'],   // current displayed preprocess functions
    modelCurrentList: ['auto_reg', 'pls', "gpr"],        // current displayed models
    n_plots: 0,               // +=1 on new plots created
    nModel: 0,                // number of models
    optResults: {},           // optimization results, {'taskID'：results}
    enableTraining: true,
  }
}

export const getModelName = (idx, workflow) => {
  // rename model name to avoid conflict
  let n = 0
  const errorName = workflow.models[idx].error !== undefined ? "ERROR: " : ""
  const currentName = workflow.models[idx].info.name;
  for (let i = 0; i < idx; i += 1) {
    if (workflow.models[i].info.name === currentName) n += 1
  }
  if (n === 0) return errorName + currentName
  else return errorName + currentName + `(${n})`
}

export const timeToExpire = (unixTime) => {
  const currentDate = new Date();
  const expireDate = new Date(unixTime * 1000);
  let diff = Math.abs(expireDate.getTime() - currentDate.getTime());
  const diffDays = Math.floor(diff / (24 * 60 * 60 * 1000));
  diff -= diffDays * 24 * 60 * 60 * 1000;
  const diffHours = Math.floor(diff / (60 * 60 * 1000));
  diff -= diffHours * 60 * 60 * 1000;
  const diffMinutes = Math.floor(diff / (60 * 1000));
  diff -= diffMinutes * 60 * 1000;
  const diffSeconds = Math.floor(diff / 1000);
  return `${diffDays} days ${diffHours}h ${diffMinutes}m ${diffSeconds}s`
}

export const timeElapsed = (startDate) => {
  const currentDate = new Date();
  let diff = Math.abs(startDate.getTime() - currentDate.getTime());
  const diffDays = Math.floor(diff / (24 * 60 * 60 * 1000));
  diff -= diffDays * 24 * 60 * 60 * 1000;
  const diffHours = Math.floor(diff / (60 * 60 * 1000));
  diff -= diffHours * 60 * 60 * 1000;
  const diffMinutes = Math.floor(diff / (60 * 1000));
  diff -= diffMinutes * 60 * 1000;
  const diffSeconds = Math.floor(diff / 1000);
  let result = ''
  if (diffDays > 0) result += `${diffDays} days `
  if (diffHours > 0) result += `${diffHours} hours `
  if (diffMinutes > 0) result += `${diffMinutes} minutes `
  if (diffSeconds > 0) result += `${diffSeconds} seconds `

  return result
}


// Train a model
export const handleTrainModel = (modelParams, workflow, enqueueSnackbar, MSState, setMSState, modelName) => () => () => {
  if (!ioIsDefined(workflow)) {
    enqueueSnackbar('Please set input and output columns before model training', { variant: "warning" })
    return
  }
  let newSubmittedModel = [...MSState.submittedModel, { task_id: '', task_status: 'Queued', info: { name: modelName, description: null } }]
  // Need to add a new object to submittedShap to record the shap status
  let newSubmittedShap = [...MSState.submittedShap, { task_id: '', status: 'Not Submitted' }]
  setMSState({
    ...MSState,
    submittedModel: newSubmittedModel,
    modelCurrentTab: workflow.models.length,
    submittedShap: newSubmittedShap,
    enableTraining: false,
  })
  // submit task
  const model_params = {
    operations_dict: {
      model_training: modelParams
    }
  }
  let url = 'tasks'
  let task_body = JSON.stringify({
    workflow_id: workflow.uuid,
    operation: 'train',
    operation_args: JSON.stringify(model_params)
  })
  let config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }
  aichemyProtoAxios.post(url, task_body, config)
    .then(res => {
      let newSubmittedModel = [...MSState.submittedModel, { task_id: res.data.task_id, task_status: 'Queued', info: { name: modelName, description: null } }]
      // Need to add a new object to submittedShap to record the shap status
      let newSubmittedShap = [...MSState.submittedShap, { task_id: '', status: 'Not Submitted' }]
      setMSState({ ...MSState, submittedModel: newSubmittedModel, modelCurrentTab: workflow.models.length, submittedShap: newSubmittedShap })
    })
    .catch(
      err => { console.error(err) }
    )
}

// Compute shap
export const handleComputeShap = (modelIdx, workflow, enqueueSnackbar, MSState, setMSState) => {
  let newSubmittedShap = [...MSState.submittedModel]
  newSubmittedShap[modelIdx] = { task_id: '', status: 'Queued' }
  setMSState({ ...MSState, submittedShap: newSubmittedShap })

  // submit task
  const model_params = {
    operations_dict: {
      model_post_process: {
        ComputeShap: {
          kwargs: { model_id: workflow.models[modelIdx].uuid }
        }
      }
    },
  }
  let url = 'tasks'
  let task_body = JSON.stringify({
    workflow_id: workflow.uuid,
    operation: 'update',
    operation_args: JSON.stringify(model_params)
  })
  let config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }
  aichemyProtoAxios.post(url, task_body, config)
    .then(res => {
      let newSubmittedShap = [...MSState.submittedShap]
      newSubmittedShap[modelIdx] = { task_id: res.data.task_id, status: 'Queued' }
      setMSState({ ...MSState, submittedShap: newSubmittedShap })
    })
    .catch(
      err => { console.error(err) }
    )
}


// process model training results
export const processTrainModelResults = (currentTaskID, currentComponent, setWorkflow, enqueueSnackbar, MSState, setMSState) => {
  let task_status = ''
  if (currentTaskID) {
    let interval = 1000; // ms
    poll(
      checkTaskStatus,
      (res) => {
        if (!currentComponent.current) return true
        let newSubmitModel = [...MSState.submittedModel]
        if (res && (res.data.status === 'complete' || res.data.status === 'complete#success')) {
          newSubmitModel.map((model) => {
            if (model.task_id === currentTaskID) {
              model.task_status = 'Finished'
            }
            return model
          })
        } else if (res && res.data.status === 'complete#error') {
          newSubmitModel.map((model) => {
            if (model.task_id === currentTaskID) {
              model.task_status = 'Finished'
            }
            return model
          })
        } else {
          newSubmitModel.map((model) => {
            if (model.task_id === currentTaskID) {
              model.task_status = 'Running'
            }
            return model
          })
        }
        setMSState(MSState, 'submittedModel', newSubmitModel)
        return res ? (res.data.status === "complete" || res.data.status === "complete#error" || res.data.status === "complete#success") : false;
      },
      interval,
      currentTaskID
    )
      .then((result) => {
        task_status = result.data.status
      })
      .catch((res) => {
        task_status = 'error'
        console.error(res)
      })
      .finally(() => {
        if (currentComponent && !currentComponent.current) {
          console.error('Model training Component unmounted. Results not updated')
          return;
        }
        if (task_status === 'complete' || task_status === 'complete#success') {
          // task completed, get task results
          getTaskResults(currentTaskID).then(res => {
            let wf = res.data.data
            setWorkflow(wf)
          }).catch((err) => {
            console.error(err)
            // setIsLoading(false)
            enqueueSnackbar('Error during retrieving results.', { variant: "error" })
          })
        } else if (task_status === 'complete#error') {
          // task failed
          getTaskResults(currentTaskID).then(res => {
            // setIsLoading(false);
            const err_msg = res.data.data.error
            console.error(err_msg)
          }).catch((err) => console.error(err))
          // setIsLoading(false);
          enqueueSnackbar('Task finished. Error during getting task results.', { variant: "error" })
        }
      });
  }
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

// convert a string to js date and time
export const convertStringToDate = (str) => {
  return new Date(str)
}

// generate opt task summaries
export const displayOptResults = (opt_task, workflow) => {
  const allHeaders = workflow.data.info[workflow.data.active_sheet].all_headers
  let args = JSON.parse(opt_task.operation_args)
  let optimize_dict = args.optimize_dict
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  let create_time = new Date(opt_task.create_time)
  let primaryText = ''

  // generate primary text, target
  primaryText += `${optimize_dict['optimizer']} | Target: `
  Object.keys(optimize_dict.target).forEach(col => {
    if (optimize_dict.target[col].function === "min" || optimize_dict.target[col].function === "max") {
      primaryText += `${optimize_dict.target[col].function} ${col}`
    } else {
      primaryText += `${col} = ${optimize_dict.target[col].value}`
    }
    primaryText += ', '
  })
  primaryText = primaryText.slice(0, -2)
  primaryText += ' | '

  // generate primary text, constrains
  if (optimize_dict.constraints.length > 0) {
    primaryText += 'Constraints: '
  }
  optimize_dict.constraints.forEach(constraint => {
    if (constraint.operation === 'sum') {
      const col_names = constraint.col_idx.map(idx => allHeaders[idx])
      primaryText += `Σ(${col_names.join(', ')}) = ${constraint.target} ± ${constraint.tolerance}, `
    } else if (constraint.operation === '>=' || constraint.operation === '<=' || constraint.operation === '=') {
      const col_names = constraint.col_idx.map((idx) => allHeaders[idx]);
      primaryText += `${col_names[0]} ${constraint.operation} ${constraint.target}, `;
    } else {
      const col_names = constraint.col_idx.map(idx => allHeaders[idx])
      primaryText += `${col_names[0]} / ${col_names[1]} = ${constraint.target} ± ${constraint.tolerance}, `
    }
  })
  primaryText = primaryText.slice(0, -2)
  primaryText += ` | maxiter: ${optimize_dict.params.maxiter}, swarmsize: ${optimize_dict.params.swarmsize} | `

  // generate secondary text
  let secondaryText = create_time.toLocaleDateString('en-US', options)
  return { primaryText, secondaryText }
}

// workflow data is changed
export const isDataChanged = (workflow) => {
  const excludeFuncs = ['MinMaxScaler', 'PCAScaler', 'NormalizeSelectedCols']
  const history = workflow.history.data_cleaning
  const preprocessFunctions = history.map(h => h['operation_name'])
  for (let i = 0; i < preprocessFunctions.length; i++) {
    if (excludeFuncs.includes(preprocessFunctions[i])) {
      return true
    }
  }
  return false
}

export function objectEquals(x, y) {
  if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) { return false; }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) { return x === y; }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) { return x === y; }
  if (x === y || x.valueOf() === y.valueOf()) { return true; }
  if (Array.isArray(x) && x.length !== y.length) { return false; }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) { return false; }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) { return false; }
  if (!(y instanceof Object)) { return false; }

  // recursive object equality check
  let p = Object.keys(x);
  return Object.keys(y).every(function (i) { return p.indexOf(i) !== -1; }) &&
    p.every(function (i) { return objectEquals(x[i], y[i]); });
}

// combine two model lists and remove duplicates based on model uuid. The model order should be preserved depending on the longer list
export function combineModelLists(modelList1, modelList2) {
  // push every model from 1
  let modelList = [...modelList1]
  // for each model in 2, if it's in 1, replace it otherwise append
  modelList2.forEach((newModel) => {
    let insertIndex = undefined;
    modelList.forEach((oldModel, idx) => {
      if (oldModel.uuid === newModel.uuid) {
        insertIndex = idx;
      }
    })
    if (insertIndex === undefined) {
      modelList.push(newModel)
    } else {
      modelList[insertIndex] = newModel
    }
  })
  return modelList
}