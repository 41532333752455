import React from "react";
import Typography from "@material-ui/core/Typography";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useAppStoreKey } from "../../../AppStore";
import { ChartTimeline } from "mdi-material-ui";
import CompareModelCard from "./CompareModelCard";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

function CompareModels({plots}) {
  const [MSState, setMSState] = useAppStoreKey("MixingStudioState");
  const setPlotLayout = (newLayout) => {
    setMSState({ ...MSState, modelComparePlotLayout: newLayout })
  }

  const getIcon = () => {
    return <ChartTimeline style={{ marginTop: 14 }} />
  }

  const handleRemovePlot = (idx) => {
    let newLayout = MSState.modelComparePlotLayout.filter((v, id) => id !== idx)
    newLayout = newLayout.map((v, id) => {
      return { ...v, i: String(id) }
    })
    setMSState({
      ...MSState,
      modelComparePlots: MSState.modelComparePlots.filter((v, id) => id !== idx),
      modelComparePlotLayout: newLayout,
      modelComparePlotParams: MSState.modelComparePlotParams.filter((v, id) => id !== idx)
    })
  }

  return (
    <>
      <Typography
        variant="h6"
        style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
      >
        Compare Models
      </Typography>
      <ResponsiveReactGridLayout
        className="layout"
        rowHeight={30}
        cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
        onLayoutChange={setPlotLayout}
        // isResizable={false}
        layouts={{ lg: MSState.modelComparePlotLayout }}
        // minW={22}
        // maxW={22}
      >
        {plots.map((plot, idx) => <div key={String(idx)}>
            <CompareModelCard
              getTrace={() => plot.data}
              getLayout={() => {
                return {
                  ...plot.layout,
                  autosize: true,      // I don't know why this is necessary！ o(*≧▽≦)ツ┏━┓
                };
              }}
              getIcon={getIcon}
              idx={idx}
              handleRemovePlot={handleRemovePlot}
              visible={MSState.modelComparePlotParams[idx].expand}
            />
          </div>)}
      </ResponsiveReactGridLayout>

    </>
  )
}

export default React.memo(CompareModels);