import { getUserRoles } from "API/firecrackerAPI"

export async function checkLabs(labs) {
    if (!labs?.length) return "You must include at least one of your groups"
    try {
        const roles = await getUserRoles()
        const writable = labs.filter(lab => lab.accessLevel === "writer").find(lab => (roles.user_labs?.indexOf(lab.lab.id) >= 0) || (roles.admin_labs?.indexOf(lab.lab.id) >= 0))
        return writable ? undefined : "One of your groups must have write permissions"
    }
    catch(error) {
        return `API error validating labs: ${error.message}`
    }
}