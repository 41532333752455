import React, { useState, useEffect, useCallback, useMemo } from "react";
import FormControl from "@material-ui/core/FormControl";
import HotTable from "../../HoT/StyledHoT";
import Typography from "@material-ui/core/Typography";
import { useAppStoreKey } from "../../../AppStore";
import {data_to_list } from "../utils";
import IconButton from "@material-ui/core/IconButton";
import Handsontable from "handsontable";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./EditData.js"
import EditIcon from '@material-ui/icons/Edit';

function EditDataTable({ setOpen, editDataRef }) {
  const classes = useStyles(); // {selectedFileSheet, allData, sourceId, fileSheets};
  let hotTableComponent = React.createRef();
  const [HotData, setHotData] = useState({ data: [], headers: [] })
  const [MSState,] = useAppStoreKey('MixingStudioState')
  let lintResults = MSState['lintResults']
  let lintLocs = useMemo(() => {
    if (!lintResults) return {}
    let infoLocs = []
    let warningLocs = []
    let errorLocs = []
    for (let i = 0; i < lintResults.length; i++) {
      let lintResult = lintResults[i]
      if (!lintResult.passed) {
        if (lintResult.level <= 10) {
          infoLocs.push(...lintResult.location)
        } else if (lintResult.level <= 50) {
          warningLocs.push(...lintResult.location)
        } else {
          errorLocs.push(...lintResult.location)
        }
      }
    }
    return { infoLocs, warningLocs, errorLocs }
  }, [lintResults])

  const [workflow, ] = useAppStoreKey("Workflow");
  const getDataHeaders = useCallback(() => {
    let selectedSheet = workflow?.data ? workflow.data.active_sheet : ''
    let allData = workflow?.data ? workflow.data.data_df[selectedSheet] : ''
    let dataHeaders = []
    if (allData) {
      dataHeaders = data_to_list(allData).colNames
    }
    return dataHeaders
  }, [workflow])

  const [modifiedColHeaders, setModifiedColHeaders] = useState(getDataHeaders())

  useEffect(() => setModifiedColHeaders(getDataHeaders()), [getDataHeaders])

  const getData = useCallback(() => {
    const data = workflow.data;
    const selected_sheet = workflow.data.active_sheet
    if (!data || !selected_sheet || !data.info[selected_sheet].input_cols) return {}
    return { data: data, selected_sheet: selected_sheet }
  }, [workflow])

  const originalColumnOrdering = useMemo(() => {
    let { data, selected_sheet } = getData();
    if (!data) return { input: [], output: [] }
    const inputCols = data.info[selected_sheet].input_cols
    const outputCols = data.info[selected_sheet].output_cols
    let inputColNames
    let outputColNames
    if (inputCols) {
      inputColNames = [...inputCols]
    } else {
      inputColNames = []
    }

    if (outputCols) {
      outputColNames = [...outputCols]
    } else {
      outputColNames = []
    }

    const allCols = data.info[selected_sheet].all_headers
    return {
      input: inputColNames.map(colName => allCols.indexOf(colName)),
      output: outputColNames.map(colName => allCols.indexOf(colName)),
      allCols: allCols
    }
  }, [getData])

  // add lint error message
  const cellComments = useMemo(() => {
    // [{row: 1, col: 1, comment: {value: 'Some comment'}},]
    if (!lintResults) return []
    let all_results = {}
    // get all headers
    const all_headers = workflow.data.info[workflow.data.active_sheet].all_headers
    lintResults.forEach(result => {
      if (!result.passed && result.location) {
        result.location.forEach(cellLocation => {
          const col_name = cellLocation.col_name
          const col_idx = all_headers.indexOf(col_name)
          let row_idx = cellLocation.row_idx
          if (!row_idx) {
            workflow.data.data_df[workflow.data.active_sheet].forEach((d, idx) => {
              if (Object.keys(all_results).indexOf(String(idx)) === -1) {
                all_results[idx] = {}
              }
              if (Object.keys(all_results[idx]).indexOf(String(col_idx)) === -1) {
                all_results[idx][col_idx] = [result.message]
              }
              else {
                all_results[idx][col_idx].push(result.message)
              }
              // all_results.push({row: idx, col: col_idx, comment: {value: result.message}})
            })
          } else {
            row_idx.forEach(row => {
              if (Object.keys(all_results).indexOf(String(row)) === -1) {
                all_results[row] = {}
              }
              if (Object.keys(all_results[row]).indexOf(String(col_idx)) === -1) {
                all_results[row][col_idx] = [result.message]
              }
              else {
                all_results[row][col_idx].push(result.message)
              }
              // all_results.push({row: row - 1, col: col_idx, comment: {value: result.message}})
            })
          }
        })
      }
    })
    return all_results
  }, [lintResults, workflow])



  const cellStyle = useMemo(() => {

    function cellRenderer(instance, td, row, col, prop, value, cellProperties) {
      Handsontable.renderers.TextRenderer.apply(this, arguments);
      const message = cellComments[row] && cellComments[row][col] ? cellComments[row][col].join('\n') : ''
      if (message) {
        let content = instance.getDataAtCell(row, col)
        if (content === null || content === undefined) {
          td.innerHTML = '<div title="' + message + '">&nbsp;</div>'
        } else {
          td.innerHTML = '<div title="' + message + '">' + td.innerHTML + '</div>'
        }
      }

    }

    const inputIdx = originalColumnOrdering.input
    const outputIdx = originalColumnOrdering.output
    // get all headers
    const all_headers = workflow.data.info[workflow.data.active_sheet].all_headers

    // get all lint locations
    const { infoLocs, warningLocs, errorLocs } = lintLocs
    return (rowIndex, colIndex) => {

      const headerName = all_headers[colIndex]
      // draw lint results
      if (errorLocs.length > 0) {
        for (let i = 0; i < errorLocs.length; i++) {
          if (errorLocs[i].col_name === headerName) {
            if (!errorLocs[i].row_idx || errorLocs[i].row_idx.indexOf(rowIndex) > -1) {
              return {
                className: "errorCell",
                renderer: cellRenderer,
              }
            }
          }
        }
      }
      if (warningLocs.length > 0) {
        for (let i = 0; i < warningLocs.length; i++) {
          if (warningLocs[i].col_name === headerName) {
            if (!warningLocs[i].row_idx || warningLocs[i].row_idx.indexOf(rowIndex) > -1) {
              return {
                className: "warningCell",
                renderer: cellRenderer,

              }
            }
          }
        }
      }
      if (infoLocs.length > 0) {
        for (let i = 0; i < infoLocs.length; i++) {
          if (infoLocs[i].col_name === headerName) {
            if (!infoLocs[i].row_idx || infoLocs[i].row_idx.indexOf(rowIndex) > -1) {
              return {
                className: "infoCell",
                renderer: cellRenderer,
              }
            }
          }
        }
      }

      // Group Header row on top
      if (inputIdx.indexOf(colIndex) > -1) {
        return {
          className: "inputCell",
          renderer: cellRenderer,
        }
      } else if (outputIdx.indexOf(colIndex) > -1) {
        return {
          className: "outputCell",
          renderer: cellRenderer,
        }
      }
    }
  }, [originalColumnOrdering, lintLocs, workflow, cellComments])


  let allDataToHotTableData = (allData = '') => {
    if (!allData) {
      allData = workflow.data.data_df[workflow.data.active_sheet]
    }
    if (allData) {
      let colNames
      if (modifiedColHeaders) {
        colNames = modifiedColHeaders
      } else {
        colNames = Object.keys(allData[0]);
      }
      let HotTableData = data_to_list(allData).data;
      setHotData({ data: HotTableData, headers: colNames });
    }
  };

  useEffect(allDataToHotTableData, [modifiedColHeaders, workflow?.data, workflow])

  return (
    <div>
      {/*close button*/}
      <div style={{display: 'flex', flexDirection: "row", marginLeft: 24}}>
        <Typography variant="h6" style={{marginRight: '10px'}}>
          Data Quick View
        </Typography>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto'}}>
          <IconButton
            onClick={() => {
              setOpen(false)
              editDataRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

      </div>
      <FormControl className={classes.formControl} fullWidth>
        <div className={classes.htRoot}>
          <HotTable
            id="table-edit-data"
            ref={hotTableComponent}
            data={HotData.data}
            colHeaders={HotData.headers}
            rowHeaders={true}
            contextMenu={false}
            stretchH="all"
            height={300}
            minRows="15"
            cells={cellStyle}
            cell={cellComments}
            comments={false}
            autoColumnSize={{ useHeaders: true }}
            readOnly={true}
          />
        </div>
      </FormControl>
    </div>
  );
}

export default React.memo(EditDataTable);
