import dagre from 'dagre';
import { defaultOffset } from '../../schema/FlowChart';
import { mapObject } from '../../utils/utils';

export function autoScale(chart, canvasSize, defaultNodeSize = {width: 50, height: 16}, margin = {x: 20, y: 20}){
    const rect = {};
    Object.values(chart.nodes).forEach(node => {
        const pos = node.position;
        if (pos){
            const size = getNodeSize(node, defaultNodeSize)
            if (!rect.top || (pos.y) < rect.top){
                rect.top = pos.y;
            }
            if (!rect.bottom || (pos.y + size.height) > rect.bottom){
                rect.bottom = pos.y + size.height;
            }
            if (!rect.left || (pos.x) < rect.left){
                rect.left = pos.x;
            }
            if (!rect.right || (pos.x + size.width) > rect.right){
                rect.right = pos.x + size.width;
            }
        }
    });
    if (!rect.top) // must have no nodes - return original chart
        return chart;
    const totalSize = {
        x: (rect.right - rect.left + (margin.x * 2)),
        y: (rect.bottom - rect.top + (margin.y * 2))
    };
    const xScale = canvasSize.width / totalSize.x;
    const yScale = canvasSize.height / totalSize.y;
    let scale = (xScale < yScale ? xScale : yScale);
    scale = scale < 0.5 ? 0.5 : scale;
    scale = scale > 2 ? 2 : scale;
    const offset = {x: -rect.left + margin.x, y: -rect.top + margin.y, scale};
    return {...chart, offset};
}

function getNodeSize(node, defaultNodeSize) {
    if (defaultNodeSize.force) return {width: defaultNodeSize.width, height: defaultNodeSize.height}
    if (node.size) return {width: defaultNodeSize.width, height: defaultNodeSize.height, ...node.size}
    if (node.properties) {
        const defCount = Object.keys(node.properties.materialDefs || {}).length +
            Object.keys(node.properties.processDefs || {}).length +
            Object.keys(node.properties.propertyDefs || {}).length
        const height = Math.max(defCount * 17 + 18, defaultNodeSize.height)
        const labelLength = (node.properties.label?.length || 0) * 7 // (approx average character width)
        const width = Math.max(labelLength, defaultNodeSize.width)
        return {width, height}
    }
    return {width: defaultNodeSize.width, height: defaultNodeSize.height} 
}

export function autoArrange(chart, defaultNodeSize = {width: 100, height: 16, force: false}, offset = defaultOffset){
    const graph = new dagre.graphlib.Graph();

    graph.setGraph({rankdir: "LR", nodesep: 25, ranksep: 50});

    graph.setDefaultEdgeLabel(function() { return {}; });

    Object.values(chart.nodes).forEach(node => {
        graph.setNode(node.id, getNodeSize(node, defaultNodeSize));
    });
    Object.values(chart.links).forEach(link => {
        if (link.to.nodeId && link.from.nodeId){
            const options = link.properties?.label ? {width: defaultNodeSize.width, height: defaultNodeSize.height, labelpos: "c"} : {};
            graph.setEdge(link.from.nodeId, link.to.nodeId, options);
        }
    });
    dagre.layout(graph);

    const nodes = {...chart.nodes}
    for (let [id, node] of Object.entries(graph._nodes)){
        nodes[id].position.x = node.x - node.width/2 - offset.x;
        nodes[id].position.y = node.y - node.height/2 - offset.y;
    }
    return {...chart, nodes, offset: {...offset, x: offset.x+10, y: offset.y+10}};
}

export function nodeFromProcess(processDefinition, id){
    const ports = {
        input: {
            id: "input",
            type: "left",
            properties: {},
        },
        output: {
            id: "output",
            type: "right",
            properties: {},
        }
    }
    return{
            id,
            type: "process",
            properties: {
                label: processDefinition?.title || "Process",
                definitions: {[processDefinition.id]: processDefinition},
            },
            ports,
        }
}

export function sanitizeNode(node){
    return {
        id: node.id,
        label: node.properties?.label,
        type: node.type,
        ports: mapObject(node.ports, port => ({
            id: port.id,
            type: port.type,
        }))
    }
}

export function updateFlowChartProperties(flowChart, processView, materials){
    const newFlowChart = {...flowChart, nodes: {...flowChart.nodes}};
    let changed = false;

    function addDefinitions(id, instances=[], definitions){
        for (let instance of instances){
            const nodeId = instance?.node?.id;
            if (nodeId === id && !!instance.definition){
                if (!definitions[instance.definition.id]){
                    definitions[instance.definition.id] = instance.definition;
                }
            }
        }
    }

    for (let [id, node] of Object.entries(newFlowChart.nodes)){
        const definitions = {};
        
        let instanceKey;
        if (node.type === "material") {
            instanceKey = "components";
            addDefinitions(id, processView?.selected?.materials, definitions);
        }
        else if (node.type === "process"){
            instanceKey = "processSteps";
            addDefinitions(id, processView?.selected?.processes, definitions);         
        }
        else if (node.type === "properties") {
            instanceKey = "properties";
            addDefinitions(id, processView?.selected?.properties, definitions); 
        }
        
        // add instance object definitions
        if (instanceKey){
            for (let material of materials){
                addDefinitions(id, material[instanceKey], definitions);
            }
        }

        // check for changes
        if (Object.keys(definitions).length !== Object.keys(node.properties?.definitions || {}).length ||
            !Object.keys(definitions).every(def => node.properties?.definitions && node.properties?.definitions[def])){
            newFlowChart.nodes[id] = {...node, properties: {...node.properties, definitions}};
            changed = true;
        }
    }

    return changed ? newFlowChart : flowChart;
}

export function sanitizeFlowChartProperties(flowChart){
    const newFlowChart = {...flowChart, nodes: {...flowChart.nodes}, links: {...flowChart.links}, selected: {}, hovered: {}};
    let changed = false;
    for (let [id, node] of Object.entries(newFlowChart.nodes)){
        if (node.properties?.definitions){
            const definitions = mapObject(node.properties.definitions, def => ({id: def.id, units: def.units}));
            newFlowChart.nodes[id] = {...node, properties: {...node.properties, definitions, fade: undefined}};
            changed = true;
        }
    }
    for (let [id, link] of Object.entries(newFlowChart.links)){
        if (link.properties?.fade || link.properties?.dash){
            changed = true;
            newFlowChart.links[id] = {...link, properties: {...link.properties, fade: undefined, dash: undefined}};
        }
    }
    return changed ? newFlowChart : flowChart;
}

export function getAddedAndRemovedNodes(newChart, chart){
    const added = {};
    for (let [id, node] of Object.entries(newChart.nodes)){
        if (!chart.nodes[id])
            added[id] = sanitizeNode(node);
    }
    
    const removed = {};
    for (let [id, node] of Object.entries(chart.nodes)){
        if (!newChart.nodes[id])
            removed[id] = sanitizeNode(node);
    }
    return {added: Object.keys(added).length > 0 ? added : undefined, removed: Object.keys(removed).length > 0 ? removed : undefined};
}

/**
 * Sorting function for node objects. For use with Array.sort
 * @param {*} a 
 * @param {*} b 
 */
export function nodeSortFunc(a, b){
    if (a.type !== b.type) {    
        if (a.type === "material") return -1;
        if (b.type === "material") return 1;
        if (a.type === "process") return -1;
        if (b.type === "process") return 1;
    }
    if (!a.position || !b.position) return 0;
    const aX = a.position.x + (a.size.width || 0) / 2;
    const aY = a.position.y + (a.size.height || 0) / 2;
    const bX = b.position.x + (b.size.width || 0) / 2;
    const bY = b.position.y + (b.size.height || 0) / 2;
    if (aX < bX) return -1;
    if (aX > bX) return 1;
    if (aY < bY) return -1;
    if (aY > bY) return 1;
    return 0;
  }