import React, { useContext } from 'react';
import { Link, useMatch, LocationProvider } from '@reach/router';

import clsx from 'clsx';
import { useAppStoreKey } from '../../AppStore';

import { 
    List, ListItem, ListItemIcon, ListItemText, ListSubheader, Divider, Tooltip, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ListIcon from '@material-ui/icons/List';
import CreateIcon from '@material-ui/icons/Create';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NotificationsIcon from '@material-ui/icons/Notifications';

import FlaskIcon from 'mdi-material-ui/FlaskOutline';
import LightBulbOnIcon from 'mdi-material-ui/LightbulbOnOutline';
// import Film from 'mdi-material-ui/Film';
import PropertyIcon from 'mdi-material-ui/Ballot';
import ProcessIcon from 'mdi-material-ui/Sitemap';
import TestIcon from 'mdi-material-ui/TestTube';
import InstrumentIcon from 'mdi-material-ui/Printer3d';

import { AccountContext } from '../../context';

import EMIcon from '../../images/EMIcon';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    selected:
    {
        color: theme.palette.primary.main,
    },
    unselected:
    {
        color: theme.palette.text.primary,
    },
    text: {
        fontSize: '.9rem',
        fontWeight: 500,
    },
    subtitleClosed: {
        height: 0,
        overflow: "hidden",
        transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    subtitleOpen: {
        transition: theme.transitions.create(["height"], {
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    topItemClosed: {
        paddingTop: "10px"
    },
}));

function MenuItemBase({ icon, text, open, to, match, ...itemProps }) {
    const classes = useStyles();
    const Icon = icon;
    const selected = useMatch(match || to || "__unused__");

    return (
        <ListItem to={to} {...itemProps}>
            <ListItemIcon>
                <Tooltip title={open ? "" : text} placement="right">
                    <div>
                        <Icon className={clsx(selected ? classes.selected : classes.unselected)} />
                    </div>
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                disableTypography
                primary={
                    <Typography className={clsx(classes.text, selected ? classes.selected : classes.unselected)}>
                        {text}
                    </Typography>} />
        </ListItem>
    );
}
const MenuItem = (MenuItemBase);

/**
 * wrapper for MenuItem converting it from a current_view control to a external wb link.
 */
function MenuItemLink(props) {
    return <MenuItem component="a" target="_blank" rel="noopener noreferrer" {...props} />
}

/**
 * Component for the navigation menu list.
 * @param {boolean} props.open - whether the menu is currently open or minimized
 */
function MenuList({ open }) {
    const classes = useStyles();
    const account = useContext(AccountContext);
    const name = account?.name.split(" ", 1)[0];
    const [{ data: { id: setId = "new" } = {} } = {}] = useAppStoreKey("EditSampleSet");
    return (
        <div className={classes.root}>
            <LocationProvider>
                <Divider />
                <List component="nav" dense={false} id="list_formulations_button" subheader={
                    <ListSubheader className={open ? classes.subtitleOpen : classes.subtitleClosed}>
                        {name && ("Hello, " + name)}
                    </ListSubheader>}>
                    <MenuItem open={open} component={Link} to="/SampleSetLibrary" icon={ListIcon} text="Sample Sets" />
                    <MenuItem open={open} component={Link} to="/MaterialLibrary" icon={FlaskIcon} text="Materials" />
                    <MenuItem open={open} component={Link} to="/ProcessLibrary" icon={ProcessIcon} text="Processes" />
                    <MenuItem open={open} component={Link} to="/PropertyLibrary" icon={PropertyIcon} text="Properties" />
                    <MenuItem open={open} component={Link} to="/TestsLibrary" icon={TestIcon} text="Tests" />
                    <MenuItem open={open} component={Link} to="/InstrumentsLibrary" icon={InstrumentIcon} text="Instruments" />
                    <MenuItem open={open} component={Link} match="/SampleSetLibrary/:setId" to={`/SampleSetLibrary/${setId}`} icon={CreateIcon} text="Edit Sample Set" />
                    <MenuItem open={open} component={Link} to="/MixingStudio" icon={LightBulbOnIcon} text="Mixing Studio 1.0" />
                </List>
                <Divider variant="middle" />
                <List id="informatics_links" subheader={
                    <ListSubheader className={open ? classes.subtitleOpen : classes.subtitleClosed}>
                        Other Materials Informatics Apps
                    </ListSubheader>} >
                    <MenuItemLink open={open} icon={EMIcon} text="Experiment Manager" href={process.env.REACT_APP_EM_FRONTEND_URL} />
                    {/* <MenuItemLink open={open} icon={Film} text="Image Analysis " href={process.env.REACT_APP_DASH_IMAGE_ANALYSIS_URL} /> */}
                </List>
                <Divider variant="middle" />
                <List id="user_content" subheader={
                    <ListSubheader className={open ? classes.subtitleOpen : classes.subtitleClosed}>
                        User Content
                    </ListSubheader>} >
                    <MenuItem open={open} component={Link} to="/TrainingContent" icon={HelpOutlineIcon} text="Guides & Training" />
                    <MenuItem open={open} component={Link} to="/Updates" icon={NewReleasesIcon} text="Release Updates" />
                    <MenuItemLink open={open} icon={FeedbackIcon} text="Give Feedback" href={process.env.REACT_APP_FEEDBACK_FORM_URL} />
                    <MenuItemLink 
                        open={open}
                        icon={NotificationsIcon}
                        text="Email Notification Signup"
                        href={process.env.REACT_APP_EMAIL_SIGNUP_FORM_URL}
                    />
                </List>
            </LocationProvider>
        </div>
    );
}
export default React.memo(MenuList);