import { Base } from '../Base'
import { LabToSample, SampleInstance, ProcessInstance, PropertyInstance, Test, SampleSet, ProcessChart } from '../models'
import { schema, Schema } from '../schemaUtils'

export class Sample extends Base {
    contributors?: string[]
    title?: string
    qid?: string
    alternateNames?: string // \n delimited string
    categories?: string[]
    notes?: string
    totalAmount?: number
    components?: SampleInstance[]
    instances?: SampleInstance[]
    processSteps?: ProcessInstance[]
    properties?: PropertyInstance[]
    tests?: Test[]
    labs?: LabToSample[]
    processView?: SampleSet[]
    sets?: SampleSet[]

    nodeId?: string
    processChart?: ProcessChart

    static readonly [schema]: Schema<Sample> = {
        endPoint: "samples",
        type: "sample",
        relationships: {
            ...Base[schema].relationships,
            components: { map: "component_instances",  model: "SampleInstance", toMany: true, reverse: "sample" },
            instances: { map: "sample_instances",  model: "SampleInstance", toMany: true, reverse: "definition", indirect: true },
            processSteps: { map: "process_instances",  model: "ProcessInstance", toMany: true, reverse: "sample" },
            properties: { map: "property_instances",  model: "PropertyInstance", toMany: true, reverse: "sample" },
            sets: { map: "sample_sets",  model: "SampleSet", toMany: true, reverse: "samples" },
            tests: { map: "tests",  model: "Test", toMany: true, reverse: "samples" },
            labs: { map: "labs",  model: "LabToSample", toMany: true, reverse: "sample", meta: { of: "labs", through: "lab" } },
            processChart: { map: "process_chart", model: "ProcessChart", toMany: false, reverse: "samples" },
            processView: { map: "Sample_set", model: "SampleSet", toMany: true }
        },
        map: {
            ...Base[schema].map,
            "contributors": "contributors",
            "title": "title",
            "qid": "qid",
            "alternateNames": "alternate_names",
            "categories": "category",
            "notes": "meta.notes",
            "nodeId": "meta.node_id",
            "processChart.id": "process_chart_id",
        }
    }

    constructor(toCopy?: Partial<Sample>) {
        super()
        Object.assign(this, toCopy)
    }
}
