import React, { useState} from "react";
import { SelectColumns } from "./utils";
import PreprocessFuncsBase from "./PreprocessFuncsBase";
import { useAppStoreKey } from "../../../../AppStore";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import FpFuncParams from "./FpFuncParams";
import { useSnackbar } from "notistack";
import Divider from '@material-ui/core/Divider';

export default function Fingerprinting({pfKey, handleRemovePlot}) {
  const [workflow] = useAppStoreKey("Workflow");
  const [MSConfig] = useAppStoreKey("MixingStudioConfig");
  const [allCols, setAllCols] = useState([])
  const [selectedCols, setSelectedCols] = useState([])
  const funcName = 'Compute Molecule Fingerprinting'
  const description = 'Convert molecules encoded in SMILES to a set of features'
  const [stagedFP, setStagedFP] = useState([])
  const [selectedFPIdx, setSelectedFPIdx] = useState(0)
  const [selectedParams, setSelectedParams] = React.useState({});
  const fp_configs = MSConfig['fingerprinting'] ? MSConfig['fingerprinting'] : []
  const { enqueueSnackbar } = useSnackbar();

  const handleListItemClick = (event, index) => {
    setSelectedFPIdx(index);
  };

  const getSelectedCols = () => {
    const selected_idx = []
    allCols.forEach((value, idx) => { if (selectedCols[idx]) selected_idx.push(idx) })
    return selected_idx.map(idx => workflow.data.info[workflow.data.active_sheet].all_headers[idx]);
  }

  const updateDict = () => {
    if (getSelectedCols().length === 0) {
      enqueueSnackbar("Please select at least one column", { variant: "error" });
      return
    }
    return {
      "Fingerprinting": {
        "kwargs": {
          "cols": getSelectedCols(),
          "fp_type": fp_configs.fp_funcs[selectedFPIdx],
          "args": selectedParams
        }
      }
    }
  }

  return (
    <>
      <PreprocessFuncsBase
        funcName={funcName}
        description={description}
        pfKey={pfKey}
        handleRemovePlot={handleRemovePlot}
        updateDict={updateDict}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{width: '30%'}}>
            <SelectColumns
              allCols={allCols}
              setAllCols={setAllCols}
              selectedCols={selectedCols}
              setSelectedCols={setSelectedCols}
              legendText={'Select columns'}
            />
          </div>
          <Divider orientation="vertical" variant="middle" flexItem/>
          <div style={{width: '30%', padding: 15}}>
            <Typography variant="h3" gutterBottom style={{color: 'grey', fontSize:16, marginTop: 6}}>
              Selected a function
            </Typography>
            <List >
            {fp_configs.fp_funcs.map((fp_func, idx) => {
              return (
                <ListItem
                  button
                  selected={selectedFPIdx === idx}
                  onClick={(event) => handleListItemClick(event, idx)}
                >
                  <ListItemText primary={fp_func} secondary={fp_configs.fp_desc[idx] ? fp_configs.fp_desc[idx] : ''}/>
                </ListItem>
              )
            })}
          </List>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem/>
          {Object.keys(fp_configs.fp_params[selectedFPIdx]).length > 0 && <div style={{padding: 15}}>
            <Typography variant="h3" gutterBottom style={{color: 'grey', fontSize:16, marginTop: 6}}>
              Select parameters
            </Typography>
            <FpFuncParams
              params={fp_configs.fp_params[selectedFPIdx]}
              funcName={fp_configs.fp_funcs[selectedFPIdx]}
              stagedFP={stagedFP}
              setStagedFP={setStagedFP}
              selectedParams={selectedParams}
              setSelectedParams={setSelectedParams}
              selectedCols={allCols.filter((col, idx) => selectedCols[idx])}
            />
          </div>}

        </div>
      </PreprocessFuncsBase>
    </>
  );
}