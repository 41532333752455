import { makeStyles } from "@material-ui/core/styles"
import common_styles from "../../../styles/common_styles"

export default function usePredictionStyles() {
  const useStyles = makeStyles((theme) => ({
    ...common_styles(theme),
    customWidth: {
      maxWidth: 600,
    },
    // for the model selector
    modelSelector: {
      marginLeft: 24,
      marginTop: 0,
      marginBottom: 0,
      width: "50%",
      paddingTop: 0,
      paddingBottom: 0,
    },
    htRoot: { // for handsontable (hot) overrides
      '& tr': {
        background: theme.palette.background.paper
      },
      '& th': {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["200"],
        color: theme.palette.text.primary,
      },
      '& .htPlaceholder': {
        color: theme.palette.text.secondary,
      },
      '& tbody th.ht__highlight, thead th.ht__highlight': {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["500"] : theme.palette.grey["300"]
      },
      '& td': {
        background: theme.palette.background.paper,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      },
      '& td.noWrap': {
        whiteSpace: "nowrap",
      },
      '& .handsontableInput': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      },
      '& .handsontable.listbox .ht_master table': {
        background: theme.palette.background.paper,
      },
      '& .handsontable.listbox tr td.current, .handsontable.listbox tr:hover td': {
        background: theme.palette.action.hover,
      },
      '& .htMobileEditorContainer': {
        background: theme.palette.background.paper,
      },
      '& .htDimmed': {
        color: theme.palette.text.secondary,
      },
      '& .htSubmenu :after': {
        color: theme.palette.text.secondary,
      },
      '& td.currentRow': {
        backgroundColor: theme.palette.primary.main + "10",
      },
      '& td.outputCell': {
        backgroundColor: "#dcedc8",
        color: "black"
      },
      '& td.inputCell': {
        backgroundColor: "#e1f5fe",
        color: "black"
      },
      '& td.innerColHeader': {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
        color: theme.palette.text.primary,
      },
      '& td.innerColSubHeader': {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
      },
    },
  }))
  return {
    useStyles
  }
};