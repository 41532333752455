import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

export default function TraceListItem({ name, onClick, onDelete, loading }) {

  return (
    <ListItem button onClick={onClick} disabled={loading}>
      {loading && <CircularProgress />}
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText style={{ overflowX: "clip", whiteSpace: "nowrap" }}>
        {name}
      </ListItemText>
      <ListItemSecondaryAction onClick={onDelete} disabled={loading}>
        <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}