import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { useAppStoreKey } from '../../../../AppStore';
import { aichemyProtoUpdateWorkflow } from "../../utils"
import CalculatorIcon from "mdi-material-ui/Calculator"
import CloseIcon from "@material-ui/icons/Close"
import Paper from "@material-ui/core/Paper"


export default function PreprocessFuncsBase({children, updateDict, funcName, description, pfKey, handleRemovePlot}) {
  const { enqueueSnackbar } = useSnackbar();
  const [workflow, setWorkflow] = useAppStoreKey("Workflow");

  const handleApply = () => {
    if (!updateDict()){
      return
    }
    const update_dict = {
      "data_cleaning": updateDict()
    }
    aichemyProtoUpdateWorkflow(update_dict, workflow, setWorkflow).catch(err => {
      enqueueSnackbar("Failed to apply preprocess function.", { variant: "error" });
      console.error(err)
    })
  }

  return (
    <>
      <Paper variant="outlined" style={{margin:24}}>
        <div style={{display: "flex", flexDirection: "row", marginLeft: 24}}>
          <CalculatorIcon style={{marginTop: 12}}/>
          <Typography variant="subtitle1" style={{ textAlign: "left", marginLeft: 24, marginRight: 24, marginTop: 12 }}>
            {funcName}
          </Typography>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto'}}>
            <IconButton
              color="secondary"
              onClick={() => {
                handleRemovePlot(pfKey)
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {description && <Typography variant="body2" style={{ textAlign: "left", marginLeft: 27, marginTop: 12 }}>
          {description}
        </Typography>}
        {children}
        <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto'}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            style={{ marginBottom:12, marginRight: 12 }}
          >
            Apply
          </Button>
        </div>
      </Paper>
    </>
  );
}