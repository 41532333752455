import React, { useState, useMemo, createContext, useContext, useCallback, useReducer } from 'react';
import { useQueryAutocomplete } from '../../API/queryHooks';
import MultiSelector from './MultiSelector';
import { FormControlLabel, Switch, Paper, makeStyles, Typography } from '@material-ui/core';
import { useDebounceState } from '../../utils/utils';
import SelectMaterialTableDialog from './SelectMaterialTableDialog';
import DefinitionSelectorBase from './DefinitionSelectorBase';
import MaterialSummaryCard from '../MaterialsLibrary/MaterialSummaryCard'

const useStyles = makeStyles(theme => ({
    filter: {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["100"],
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center"
    },
    switch: {
        margin: theme.spacing(0, 1),
    },
    selectContainer: {
        display: "flex"
    },
    selectInner: {
        flex: 1,
    },
    tooltip: {
        maxWidth: 'none',
        backgroundColor: theme.palette.background.paper,
        border: "1px solid",
        borderColor: theme.palette.background.default,
        boxShadow: theme.shadows[1],
        color: theme.palette.text.primary
    },
    arrow: {
        color: theme.palette.background.paper,
    }
}))

const EXTEND_LIST_TEXT = "Start typing to filter results and extend list...";

const RawContext = createContext({});
function reducer(state, merge){
    return {...state, ...merge};
}

/**
 * React Component for performing a multiselect on on materials
 * @param props most props forwared to MultiSelector
 * @param props.selected forwared as the value prop
 */
export default function MaterialSelector(props){
    return (
        <DefinitionSelectorBase Selector={MaterialSelectorBase} TableSelectorDialog={SelectMaterialTableDialog} {...props}/>
    );
}

const keys = ["title","qid","alternateNames"]

export function MaterialSelectorBase({selected, onChange, ...remainingProps }){
    const [queryInput, setQueryInput] = useState({});
    const [input, setInput] = useDebounceState(queryInput, setQueryInput);
    const [state, dispatch] = useReducer(reducer, {open: false, raw: true, tags: []});
    const [highlightId, setHighlightId] = useState("")

    const classes = useStyles();

    const params = useMemo(() => ({
        Model: "Sample",
        filter: state.raw ? {
            name: "categories",
            val: "chemical",
            op: "list"
        } : undefined,
        fields: ["title", "id", "qid", "alternateNames"],
        sort: ["title"],
    }), [state.raw]);
    const { data: {data: options} = {}, isFetching } = useQueryAutocomplete(queryInput, params, {logName: "Material Selector"});
    const handleOpen = useCallback(() => dispatch({open: true}), []);
    const handleClose = useCallback((ev, source) => {dispatch({open: false})}, []);
    const handleHighlights = useCallback((e, highlighted) => {highlighted ? setHighlightId(highlighted.id) : setHighlightId("")}, [])

    const highlightTooltip = useMemo(() => {
        return {
            title: <MaterialSummaryCard id={highlightId}></MaterialSummaryCard>,
            placement:"right-start",
            interactive:true,
            arrow:true,
            classes: {tooltip: classes.tooltip, arrow: classes.arrow}
        }
    }, [classes.arrow, classes.tooltip, highlightId])

    const labelContent = state.open ? `Select Materials (${EXTEND_LIST_TEXT})` : "Select Materials";

    return (
        <RawContext.Provider value={[state, dispatch]}>
            <MultiSelector
                open={state.open}
                onOpen={handleOpen}
                onClose={handleClose}
                label={labelContent}
                missingLabel="Missing or Private Material"
                loading={isFetching}
                options={options || []}
                value={selected || []}
                onBlur={() => setInput({})}
                onChange={(...args) => {onChange && onChange(...args); setInput({})}}
                onHighlightChange={handleHighlights}
                highlightTooltip={highlightTooltip}
                inputValue={input.title || input.qid || ""}
                onInputChange={(ev, newVal) => {ev && setInput({title: ev?.target?.value || "", qid: ev?.target?.value, alternateNames: ev?.target?.value})}}
                PaperComponent={ListContainer}
                keys={keys}
                data-testid="material selector"
                {...remainingProps}
                />
        </RawContext.Provider>
    )
}

const ListContainer = React.forwardRef(function ListContainer({children, ...rest}, ref){
    const classes = useStyles();
    const [state, dispatch] = useContext(RawContext);
    return (
        <div>
            <Paper>
                <div className={classes.filter}>
                    <Typography style={{paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px'}}>{EXTEND_LIST_TEXT}</Typography>
                </div>
            </Paper>
            <Paper ref={ref} {...rest}>
                <div
                    className={classes.filter}
                    onMouseDown={ev => {ev.preventDefault(); ev.stopPropagation()}}
                >
                    <FormControlLabel
                        className={classes.switch}
                        onClick={ev => {dispatch({raw: !state.raw});}}
                        control={<Switch color="primary" checked={state.raw} />}
                        label="Raw"
                    />
                </div> 
                {children}
            </Paper>
        </div>
    )
})