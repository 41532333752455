import React, { useCallback } from 'react';
import { makeQueryFilters } from '../ReactTable/reactTableUtils';
import {MaterialSelectorBase} from '../General/MaterialSelector';
import TableSelectorDialogBase from './TableSelectorDialogBase';
import {materialColumns, defaultColumns} from '../MaterialsLibrary/materialsColumns';

/** Table selection dialog for materials */
export default function SelectMaterialTableDialog({open, onClose, category, initSelected}){
    const handleTableParam = useCallback(({tableParam, columns, serializedColumns}) => {
        const filters = makeQueryFilters(tableParam?.filters, columns);
        if (category) filters.push({ name: "categories", op: "list", val: category })
        // convert sort and append timeModified
        const sort = (tableParam?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");
        const fields = [];
        if (!!serializedColumns.find(col => col.colId.startsWith("components")) )
            fields.push("components.definition.title", "components.definition.tags", "components.definition.qid", "components.definition.alternateNames");
        if (!!serializedColumns.find(col => col.colId.startsWith("processSteps")) )
            fields.push("processSteps.definition.title", "processSteps.definition.tags", "processSteps.definition.description");
        if (!!serializedColumns.find(col => col.colId.startsWith("properties")) )
            fields.push("properties.definition.title", "properties.definition.tags", "properties.definition.description");
        if (!!serializedColumns.find(col => col.colId.startsWith("sets")) )
            fields.push("sets");
        if (!!serializedColumns.find(col => col.colId.startsWith("tests")) )
            fields.push("tests.instrument.title", "tests.instrument.id", "tests.instrument.description");
        return (tableParam && {
            Model: "Sample",
            filter: filters,
            fields,
            sort,
            pageNumber: tableParam.pageIndex+1, 
            pageSize: tableParam.pageSize, 
        });
    }, [category]);

    return (
        <TableSelectorDialogBase 
            open={open} 
            onClose={onClose} 
            initSelected={initSelected}
            localForageKey="SelectMaterialTableColumns"
            onTableParam={handleTableParam}
            SelectComponent={MaterialSelectorBase}
            title="Search and Select Materials"
            tableColumns={materialColumns}
            defaultColumns={defaultColumns}
        />
    );
}