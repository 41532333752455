export const drawerWidth = 240;
const styles = theme => ({
    root: {
      display: 'flex',
    },
    router: {
      display: 'flex',
      width: '100%'
    },
    toolbar: {
      paddingRight: 12, // keep right padding when drawer closed
      paddingLeft: 12, 
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      [theme.breakpoints.up('sm')]:{
        ...theme.mixins.toolbar,
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.background.paper,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuCloseButton: {
      color: "rgba(255,255,255,.8)"
    },
    menuButton: {
      marginRight: 12,
      marginLeft: 0,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    cornerTitle: {
      textAlign: 'center',
      lineHeight: 1.2,
      fontWeight: '500',
      color: 'white',
      textShadow: '1px 1px 1px black',
      whiteSpace: 'pre',
      paddingLeft: '16px'
    },
    drawerPaper: {
      overflowX: 'hidden',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      background: theme.palette.background.default,
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: '100vh',
      overflow: 'auto',
      color: theme.palette.text.primary
    },
    container: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    loader: {
      marginLeft: '0px',
    },
    loaderMessage:{
      ...theme.typography.h5,
      position: 'relative',
      color: 'white',
      textAnchor: 'middle',
      textAlign: 'center',
      top: '40%',
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,.75)',
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      zIndex: 1100,
    },
    overlayShift: {
      marginLeft: drawerWidth/2,
    },
    version: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      position: "absolute",
      bottom: "5px",
      left: "10px"
    },
    versionText: {
      textAlign: "left",
      color: theme.palette.text.secondary,
      lineHeight: "1",
      fontWeight: "600"
    },
    buildText: {
      textAlign: "left",
      color: theme.palette.text.secondary,
      fontSize: "0.6em"
    },
  })
export default styles;