import React, { useCallback, useEffect, useRef, useState } from "react";
import { poll } from "../msUtils/utils";
import { checkTaskStatus, convertStringToDate, displayOptResults, getOriColumns, getTaskResults } from "../utils";
import { useSnackbar } from "notistack";
import { useAppStoreKey } from "../../../AppStore";
import TaskStatusStepper from "./TaskStatusStepper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Timer from "./Timer";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ChartDonutIcon from "mdi-material-ui/ChartDonut";
import List from "@material-ui/core/List";
import DescriptionIcon from "@material-ui/icons/Description";
import ParaCoord from "../components/ParaCoord";
import AsteriskIcon from 'mdi-material-ui/Asterisk';
import InlineEdit from "../components/inlineTextEdit";
import { aichemyProtoAxios } from "../../../API/mmAxios";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";

function OptimizationResults({ optTask, setOptTask, modelID }) {
  const { enqueueSnackbar } = useSnackbar();
  const [taskStatus, setTaskStatus] = React.useState('Checking');   // Queued, Running, Finished
  const [taskResults, setTaskResults] = React.useState(null);
  const [task_update_time, setTaskUpdateTime] = React.useState(null);
  const [workflow] = useAppStoreKey("Workflow");
  const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
  const [title, setTitle] = React.useState(optTask.title ? optTask.title : '');
  const [description, setDescription] = React.useState(optTask.description ? optTask.description : '');
  const [isTitleInputActive, setIsTitleInputActive] = useState(false);
  const [isDescInputActive, setIsDescInputActive] = useState(false);
  const [isInfoLoading, setInfoLoading] = useState(false);

  const mounted = useRef(true)
  const optCalculatedResultsRef = useRef()
  useEffect(() => () => (mounted.current = false), [])
  let optResultsCache = MSState['optResults']
  let setOptResultsCache = useCallback((newResults) => {
    setMSState({ ...MSState, optResults: newResults })
  }, [MSState, setMSState])

  // check task status every second
  useEffect(() => {
    if (optTask.task_id) {
      // check cache first
      if (Object.keys(optResultsCache).indexOf(optTask.task_id) > -1) {
        setTaskResults(optResultsCache[optTask.task_id])
        setTaskStatus('Finished')
      } else {
        // check task status
        let interval = 1000; // ms
        poll(
          checkTaskStatus,
          (res) => {
            if (!mounted.current) return true
            if (res.data.status === "complete" || res.data.status === "complete#error" || res.data.status === "complete#success") {
              setTaskStatus('Finished')
            } else if (res.data.status === 'running') {
              setTaskStatus('Running')
              setTaskUpdateTime(res.data.update_time)
            } else if (res.data.status === 'queued') {
              setTaskStatus('Queued')
            }
            return res ? (res.data.status === "complete" || res.data.status === "complete#error" || res.data.status === "complete#success") : false;
          },
          interval,
          optTask.task_id,
        )
          .then((result) => {
            let task_status = result.data.status
            if (task_status === 'complete' || task_status === 'complete#success') {

              // task completed, get task results
              setTaskStatus('Finished');
              getTaskResults(optTask.task_id).then(res => {
                let optResults = res.data.data
                setTaskResults(optResults)
                setOptResultsCache({ ...optResultsCache, [optTask.task_id]: optResults })
                // can seemingly get loaded before the first frame render
                optCalculatedResultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
              }).catch(err => {
                console.error(err.data.data.error)
                enqueueSnackbar('Get task results error', { variant: "error" })
              })
            } else if (task_status === 'complete#error') {
              // task failed
              setTaskStatus('Error');
              console.error(result.data.message)
              enqueueSnackbar('The submitted task failed. If issues persist, please report the issue using the send feedback button.', { variant: "error" })
              getTaskResults(optTask.task_id).then(res => {
                console.error(res.data.data.error)
              }).catch(err => {
                console.error(err)
                enqueueSnackbar('Get task results error', { variant: "error" })
              })
            }
          })
          .catch((res) => console.error(res));
      }
    }
  }, [optTask, enqueueSnackbar, optResultsCache, setOptResultsCache])

  const getData = useCallback(() => {
    const data = workflow.data;
    const selected_sheet = workflow.data.active_sheet
    if (!data || !selected_sheet || !data.info[selected_sheet].input_cols) return {}

    return { data: data, selected_sheet: selected_sheet }
  }, [workflow.data])


  const getHeaders = useCallback(() => {
    let { data, selected_sheet } = getData();
    if (!data) return { inputColNames: [], outputColNames: [] }
    const transforms = data.info[selected_sheet].columns_transform;
    let inputCols = data.info[selected_sheet].input_cols
    inputCols = getOriColumns(transforms, inputCols)
    let outputCols = data.info[selected_sheet].output_cols
    outputCols = getOriColumns(transforms, outputCols)
    const inputColNames = [...inputCols]
    const outputColNames = [...outputCols]
    return { inputColNames: inputColNames, outputColNames: outputColNames };
  }, [getData])

  const getOptResults = useCallback(() => {
    if (taskResults) {
      // get last optimization result
      let input_col_names = taskResults.params.ori_input_columns
      if (input_col_names === undefined) {
        input_col_names = getHeaders().inputColNames
      }
      let output_col_names = taskResults.params.ori_output_columns
      if (output_col_names === undefined) {
        output_col_names = getHeaders().outputColNames
      }
      let headers = [...input_col_names, ...output_col_names]
      headers.push('fitness value')
      let optData = []

      let currentOptResult = taskResults.results
      Object.keys(currentOptResult.x_best).forEach((key, idx) => {
        let row_data = [...currentOptResult.x_best[key], ...currentOptResult.y_best[idx], currentOptResult.f_best[idx]]
        optData.push(row_data)
      })

      let xData = {}
      input_col_names.forEach((col_name, col_idx) => {
        xData[col_name] = Object.keys(currentOptResult.x_best).map((key) => currentOptResult.x_best[key][col_idx])
      })

      let yData = {}
      output_col_names.forEach((col_name, col_idx) => {
        yData[col_name] = Object.keys(currentOptResult.y_best).map((key) => currentOptResult.y_best[key][col_idx])
      })
      return { headers: headers, data: optData, xData: xData, yData: yData }
    }
    else return ''
  }, [taskResults, getHeaders])

  // update title or description
  const handleUpdateOptInfo = ({ title, description }) => {
    setInfoLoading(true)
    setTitle(title)
    setDescription(description)
    const url = `tasks/` + optTask.task_id + `/metadata`
    const metadata = {
      title: title,
      description: description
    }
    const config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    }

    aichemyProtoAxios.patch(url, JSON.stringify(metadata), config)
      .then(res => {
        setOptTask(res.data)
        setTitle(res.data.title ? res.data.title : '')
        setDescription(res.data.description ? res.data.description : '')
      }).catch(err => console.error(err)).finally(() => setInfoLoading(false))

  }

  return (
    <div>
      <TaskStatusStepper taskStatus={taskStatus} />
      <List style={{ marginTop: 12 }}>
        <ListItem key={'opt-title'} button onClick={() => setIsTitleInputActive(true)}>
          <ListItemIcon>
            <AsteriskIcon color='primary' />
          </ListItemIcon>
          <ListItemText
            primary={<InlineEdit
              text={title ? title : 'Click to add title'}
              onSetText={text => handleUpdateOptInfo({ description, title: text })}
              isInputActive={isTitleInputActive}
              setIsInputActive={setIsTitleInputActive}
              isLoading={isInfoLoading}
            />}
            secondary={'Title'}
          />
        </ListItem>
        <ListItem key={'opt-description'} button onClick={() => setIsDescInputActive(true)} >
          <ListItemIcon>
            <DescriptionIcon color='primary' />
          </ListItemIcon>
          <ListItemText
            primary={<InlineEdit
              text={description ? description : 'Click to add description'}
              onSetText={text => handleUpdateOptInfo({ title, description: text })}
              isInputActive={isDescInputActive}
              setIsInputActive={setIsDescInputActive}
              isLoading={isInfoLoading}
            />}
            secondary={'Description'}
          />
        </ListItem>
        <ListItem key={'opt-condition'} button >
          <ListItemIcon>
            <ChartDonutIcon color='primary' />
          </ListItemIcon>
          <ListItemText
            primary={displayOptResults(optTask, workflow).primaryText}
            secondary={displayOptResults(optTask, workflow).secondaryText} />
        </ListItem>
      </List>

      <div ref={optCalculatedResultsRef} />
      {taskResults ? (
        <div style={{ marginRight: 40 }}>
          <ParaCoord
            inputData={getOptResults().xData}
            outputData={getOptResults().yData}
            HoTData={getOptResults().data}
            HoTHeaders={getOptResults().headers}
            modelID={modelID}
          />
        </div>
      ) : taskStatus !== 'Error' ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center", }}>
          <CircularProgress
            size={48}
            style={{ marginRight: 12 }}
            color={'primary'}
          />
          <Timer
            startTime={convertStringToDate(task_update_time ? task_update_time : optTask.create_time)}
            style={{ marginRight: 12 }}
            stage={taskStatus + ' time'}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center", }}>
          <ErrorIcon
            style={{ marginRight: 12 }}
            color={'secondary'}
          />
          <Typography variant='h6' color='secondary'>
            Task Error
          </Typography>
        </div>
      )}
    </div>
  );
}

export default React.memo(OptimizationResults)