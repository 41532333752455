import React, { useMemo } from 'react';

import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {DragNode} from './DragNode';
import { dragMaterialGroupData, dragOutputLabelData, dragProcessStepData } from './dragData';
import { Divider } from '@material-ui/core';
import { getNextLabel, useDetachedDragData } from './processChartUtils';
import { NodeSummary } from './NodeSummary';

const useStyles = makeStyles(theme => {
    return ({
    container: {
        background: theme.palette.type === "dark" ? theme.palette.grey[600] : theme.palette.grey[200],
        overflow: "hidden",
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1, 0, 0, 1),
    },
})})


export default function DragProcessNodePane({readOnly = undefined,  processChart, materialDefs, processDefs, propertyDefs}){
    const classes = useStyles();

    const materialDragData = useDragData(processChart, dragMaterialGroupData)
    const processDragData = useDragData(processChart, dragProcessStepData)
    const propertyDragData = useDragData(processChart, dragOutputLabelData)

    const detached = useDetachedDragData(processChart)
   
    return (
        <div className={classes.container}>
            <Typography variant="caption">
                Drag to add elements to the diagram
            </Typography>
            <DragNode readOnly={readOnly} data={materialDragData}/>
            <DragNode readOnly={readOnly} data={processDragData}/>
            <DragNode readOnly={readOnly} data={propertyDragData}/>
            {(!!detached?.length) && 
            <>
                <Divider/>
                <Typography variant="caption">
                    Elements not on the diagram
                </Typography>
                {detached.map((data, i) => 
                    <NodeSummary node={data} materialDefs={materialDefs} processDefs={processDefs} propertyDefs={propertyDefs}>
                        <DragNode key={i} readOnly={readOnly} data={data} />
                    </NodeSummary>
                )}
            </>}
        </div>
        )
}

function useDragData(processChart, dragDataTemplate) {
    return useMemo(() => {
        const dragData = { ...dragDataTemplate };
        dragData.data = { ...dragData.data };
        dragData.data.properties = { ...dragData.data.properties };
        dragData.data.properties.label = getNextLabel("", processChart?.nodes, dragData.data.properties.label);
        return dragData
    }, [processChart, dragDataTemplate]);
}
