import { Schema, schema } from "../schemaUtils"

export class Tag {
    id?: string
    title?: string

    static readonly [schema]: Schema<Tag> = {
        endPoint: "tags",
        type: "tag",
        map: {
            title: "title",
            id: "id",
        }
    }

    constructor(toCopy?: Partial<Tag>) {
        Object.assign(this, toCopy)
    }
}