import React from "react";
import ButtonWithSpinner from "components/General/ButtonWithSpinner";

import { ButtonGroup, ButtonProps, Popover } from "@material-ui/core";

import { SampleSet } from "schema/models";
import { aichemyPayload, ColumnInfo } from "components/HoT/HoTUtils";
import { useSnackbar } from "notistack";
import PolybrewPayload, { IsReadyForPolyBrew } from "./polybrew";
import { emAxios } from "../../API/mmAxios";
import { LargeTooltip } from "../MixingStudio-proto/components/LargeTooltip";


type ModelHubButtons = {
    sampleSet: SampleSet
    loading: boolean
    onLoading: any
    open: any
    onOpen: any
    columnsInfo: ColumnInfo[]
    updateSampleSet: any
    enqueueDialogs: any
}

export function ModelHubPopover({
    sampleSet,
    open,
    onOpen,
    loading,
    onLoading,
    columnsInfo,
}: ModelHubButtons) {
    const handleClose = () => {
        onOpen(null)
    }
    return (
        <Popover
            open={!!open}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
        >
            <ButtonGroup color={"primary"} variant="outlined">
              <ExportPolyBrew
                loading={loading}
                onLoading={onLoading}
                sampleSet={sampleSet}
                columnsInfo={columnsInfo}
                disabled={!IsReadyForPolyBrew(sampleSet)}
              >
                PolyBrew
              </ExportPolyBrew>
            </ButtonGroup>
        </Popover>
    )
}

export const ExportPolyBrew: React.FC<
  {
    sampleSet: SampleSet
    loading: boolean
    onLoading?: (loading: boolean) => void
    columnsInfo: ColumnInfo[]
  } & ButtonProps
  > = React.forwardRef(
  ({ sampleSet, loading, onLoading, disabled, columnsInfo, ...props }, ref) => {
    const { enqueueSnackbar } = useSnackbar()
    const onClick = async () => {
      onLoading && onLoading(true)
      const title = sampleSet.title || "Sheet 1"
      const allData = aichemyPayload(
        title,
        sampleSet.samples,
        columnsInfo,
      )
      let payloadMH: any[] = []
      try {
        payloadMH = PolybrewPayload(sampleSet, allData)
      } catch (e) {
        // @ts-ignore
        enqueueSnackbar(e.message, { variant: "error" })
        onLoading && onLoading(false)
        return
      }


      // post to modelhub
      for (let i=0; i<payloadMH.length; i++) {
        const config = { headers: { "Content-Type": "application/json; charset=utf-8" } }
        const payload = {
          "data": {
            "lambda_name": "AddJobLambdaFunctionName",
            "payload": payloadMH[i],
          }
        }
        console.log(payloadMH[i])
        await emAxios.post(`${process.env.REACT_APP_EM_API_URL}modelhub`, payload, config)
          .then(res => {
            const data = JSON.parse(res.data)
            if (data.status === "Submitted") {
              const JobName = payloadMH[i].jobName
              console.log('PolyBrew Job submitted with id: ' + data.id)
              enqueueSnackbar( JobName + " submitted to ModelHub", { variant: "success" })
            } else {
              console.error(data)
              enqueueSnackbar("Error submitting job to ModelHub", { variant: "error" })
            }
          }).catch(err => {
            console.error(err)
            enqueueSnackbar("PolyBrew task submission failed", { variant: "error" })
          })
      }
      onLoading && onLoading(false)

    }
    return (
      <LargeTooltip title="Please save data before submit" placement="top">
      <ButtonWithSpinner
        ref={ref}
        onClick={onClick}
        spinnerColor="primary"
        loading={loading}
        disabled={disabled}
        {...props}
      /></LargeTooltip>
    )
  },
)
