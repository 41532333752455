/**
 * @format
 */
import React, { useCallback, useMemo, useState } from "react"
import MultiSelector from "./MultiSelector"
import {
    useQueryAutocomplete,
    useQueryFC,
    useQueryRoles,
} from "../../API/queryHooks"
import { Lab } from "schema/models"
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Tooltip,
    Typography,
} from "@material-ui/core"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import { FullUserSelector } from "./UserSelector"

const params = { Model: "Lab", fields: ["id", "title", "description"] }

/**
 * Permissions selector that allows selection of both read only and writer permissions.
 * @param {*} props forwarded to multiselect components
 * @returns
 */
export default function PermissionsSelector({
    selected,
    onSelect,
    error,
    helperText,
    required,
    Constructor,
    ...rest
}) {
    const writerSelected = useMemo(
        () => selected?.filter(l => l.accessLevel === "writer").map(l => l.lab),
        [selected],
    )
    const readerSelected = useMemo(
        () => selected?.filter(l => l.accessLevel === "reader").map(l => l.lab),
        [selected],
    )

    const onWriteSelected = useCallback(
        labsSelected => {
            const newSelected = labsSelected?.map(
                lab => new Constructor({ accessLevel: "writer", lab }),
            )
            newSelected.push(
                ...(readerSelected?.map(
                    lab => new Constructor({ accessLevel: "reader", lab }),
                ) || []),
            )
            onSelect(newSelected)
        },
        [Constructor, onSelect, readerSelected],
    )
    const onReadSelected = useCallback(
        labsSelected => {
            const newSelected = writerSelected?.map(
                lab => new Constructor({ accessLevel: "writer", lab }),
            )
            newSelected.push(
                ...(labsSelected?.map(
                    lab => new Constructor({ accessLevel: "reader", lab }),
                ) || []),
            )
            onSelect(newSelected)
        },
        [Constructor, onSelect, writerSelected],
    )

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <LabSelector
                    onSelect={onWriteSelected}
                    selected={writerSelected}
                    ignore={readerSelected}
                    label="Full Read/Write Permissions"
                    error={error}
                    helperText={helperText}
                    required
                    margin="dense"
                    {...rest}
                />
            </Grid>
            <Grid item xs={12}>
                <LabSelector
                    onSelect={onReadSelected}
                    selected={readerSelected}
                    ignore={writerSelected}
                    label="Read Only Permissions"
                    data-testid="reader selector"
                    margin="dense"
                    {...rest}
                />
            </Grid>
        </Grid>
    )
}

/**
 *
 * @param {*} props forwared to MultiSelect
 * @param props.selected same as value
 * @param props.onSelect use in place of onChange
 * @param {Lab[]} props.ignore array of labs to ignore and not provide as options
 */
export function LabSelector({ selected, onSelect, ignore, ...rest }) {
    const [input, setInput] = useState({})
    const [open, setOpen] = useState(false)
    const {
        data: { data: labs } = {},
        isFetching: isFetchingLabs,
        isLoading: isLoadingLabs,
    } = useQueryAutocomplete(input, params, { logName: "Lab Selector" })
    const {
        data: roles,
        isFetching: isFetchingRoles,
        isLoading: isLoadingRoles,
    } = useQueryRoles()

    const isFetching = isFetchingRoles || isFetchingLabs
    const isLoading = isLoadingLabs || !isLoadingRoles

    const data = useMemo(() => {
        return (
            roles &&
            labs
                ?.map(lab => {
                    // is user or admin
                    if (
                        roles.admin_labs?.find(id => id === lab.id) ||
                        roles.user_labs?.find(id => id === lab.id)
                    ) {
                        return new Lab({ ...lab, group: "My Groups" })
                    }
                    // other Labs
                    return new Lab({ ...lab, group: "Other Groups" })
                })
                .filter(lab => !ignore?.find(l => lab.id === l.id))
        )
    }, [ignore, labs, roles])

    return (
        <div>
            <UserLookupDialog
                open={open}
                onClose={lab => {
                    setOpen(false)
                    if (lab) onSelect((selected || []).concat(lab))
                }}
                selectedLabs={selected}
                ignore={ignore}
            />
            <MultiSelector
                fullWidth
                label={"Select Permissions Group(s)"}
                missingLabel="Missing or Private Lab"
                value={selected}
                options={data || []}
                loading={isFetching && isLoading}
                onChange={(ev, newVal) => {
                    onSelect(newVal)
                    setInput({})
                }}
                onBlur={() => setInput({})}
                inputValue={input.title || ""}
                onInputChange={(ev, newVal) => {
                    ev && setInput({ title: ev?.target?.value || "" })
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="User lookup">
                                <IconButton
                                    edge="end"
                                    size="small"
                                    onClick={() => setOpen(true)}
                                >
                                    <PersonAddIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
                groupBy={option => option?.group || "Other Groups"}
                data-testid="lab selector"
                {...rest}
            />
        </div>
    )
}

function UserLookupDialog({ onClose, selectedLabs, ignore, ...rest }) {
    const [user, setUser] = useState()
    const query = useMemo(() => {
        if (!user?.[0]?.labs?.length) return undefined
        return {
            Model: "Lab",
            filter: user[0].labs.map(l => l.id),
        }
    }, [user])
    const { data, isFetching } = useQueryFC(query)

    return (
        <Dialog fullWidth {...rest}>
            <DialogTitle>User Lookup</DialogTitle>
            <DialogContent>
                <Grid spacing={2} container>
                    <Grid item sm={12}>
                        <FullUserSelector
                            selectSingle
                            label="User"
                            selected={user}
                            onChange={(ev, vals) => setUser(vals)}
                            fullWidth
                        />
                    </Grid>
                    {data?.data && (
                        <Grid item sm={12}>
                            <Typography
                                variant="subtitle2"
                                style={{ marginBottom: "2px" }}
                            >
                                User's Permissions Groups (click to add)
                            </Typography>
                            {data.data.map((lab, i) => {
                                const sel = selectedLabs?.find(
                                    l => l.id === lab.id,
                                )
                                const ig = ignore?.find(l => l.id === lab.id)
                                return (
                                    <Chip
                                        key={i}
                                        style={{
                                            maxWidth: "25ch",
                                            margin: "2px",
                                        }}
                                        size="small"
                                        label={lab.title}
                                        disabled={!!ig}
                                        color={sel ? "primary" : undefined}
                                        clickable={!ig && !sel}
                                        onClick={
                                            sel ? undefined : () => onClose(lab)
                                        }
                                    />
                                )
                            })}
                        </Grid>
                    )}
                    {!!user?.length && !data?.data?.length && !isFetching && (
                        <Typography variant="body2" color="error">
                            This user does not appear to belong to any
                            permissions groups
                        </Typography>
                    )}
                    {isFetching && <LinearProgress />}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
