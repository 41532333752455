import React, { useCallback, useState, useRef } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles, InputAdornment,  MenuItem, IconButton, Menu, Tooltip } from '@material-ui/core';
import { useDebounceState } from '../../utils/utils';
import ClearFilterButton from './ClearFilterButton';

const useStyles = makeStyles(theme => ({
    input: {
        ...theme.typography.caption,
        backgroundColor: theme.palette.background.paper,
        '& input': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    }
}))

const items = [
    {op: "eq", icon: "=", label: "Equal"},
    {op: "ne", icon: "≠", label: "Not Equal"},
    {op: "gt", icon: ">", label: "Greater Than"},
    {op: "lt", icon: "<", label: "Less Than"},
    {op: "ge", icon: "≥", label: "Greater Than or Equal"},
    {op: "le", icon: "≤", label: "Less Than or Equal"},
    {op: "between", icon: "><", label: "Between"},
]

export default function NumberFilterInput({column: { filterValue, setFilter, filter }}){
    const classes = useStyles();
    const openRef = useRef();
    const [open, setOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [error, setError] = useState(false);
    const [operation, setOperation] = useState(items[0]);
    const [value, setValue, externalUpdate] = useDebounceState(filterValue, setFilter);
    if (externalUpdate && value?.op && operation.op !== value?.op){
        setOperation(items.find(i => i.op === value.op) || items[0]);
    }
    const handleClear = useCallback(() => setValue(undefined), [setValue]);
    const handleOpen = useCallback(() => setOpen(true),[]);
    const handleClose = useCallback(() => setOpen(false),[]);
    const handleFocus = useCallback(() => setIsFocused(true),[]);
    const handleBlur = useCallback(() => setIsFocused(false),[]);
    const handleChange = useCallback((query, op) => {
        if (!query || query === ""){
            setValue(undefined);
            setError(false);
        }
        else if (op !== "between"){
            const match = query.match(/^[-+]?(?:(?:\d*\.\d+)|(?:\d+\.?))(?:[Ee][+-]?\d+)?$/);
            setValue({query, op, type: "number"}, !!match);
            setError(!match ? "Entry must be a number" : undefined);
        }
        else{
            const match = query.match(/(^[-+]?(?:(?:\d*\.\d+)|(?:\d+\.?))(?:[Ee][+-]?\d+)?)(?:\s*(?:to|-)\s*)([-+]?(?:(?:\d*\.\d+)|(?:\d+\.?))(?:[Ee][+-]?\d+)?$)/);
            if (!match){
                setValue({query, op, type: "number"}, false);
                setError('e.g. "2.45 to 13.245", or "2.45 - 13.245"');

            }
            else{
                setValue({query, op, type: "number", between: [match[1], match[2]]});
                setError(undefined);
            }
        }
    }, [setValue]);
    const handleClick = useCallback((item) => {
        setOperation(item);
        handleChange(value?.query, item.op)
        handleClose();
    },[handleClose, handleChange, value]);
    return (
        <Tooltip title={(isFocused && error) ? error : ""} open={(isFocused && !!error)}>
        <OutlinedInput
            variant="outlined"
            value={value?.query || ""}
            onChange={(ev) => handleChange(ev.target.value, operation.op)}
            placeholder="Filter..."
            fullWidth
            className={classes.input}
            error={!!error}
            onFocus={handleFocus}
            onBlur={handleBlur}
            startAdornment={
                <InputAdornment position="start">
                    <Tooltip title={operation.label}>
                        <IconButton ref={openRef} onClick={handleOpen} size="small">
                            {operation.icon}
                        </IconButton>
                    </Tooltip>
                    <Menu anchorEl={openRef.current} open={open} onClose={handleClose}>
                        {items.map((item, i) => 
                            <MenuItem key={i} onClick={()=>handleClick(item)}>{item.label}</MenuItem>
                        )}
                    </Menu>
                </InputAdornment>
            }
            endAdornment={ (value && value !== "") ? 
                <InputAdornment position="end">
                    <ClearFilterButton onClick={handleClear}/>
                </InputAdornment> : undefined
            }
            />
        </Tooltip>
    );
}