import React, { useCallback, useState } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useDebounceState } from '../../utils/utils';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import format from 'date-fns/format';
import ClearFilterButton from './ClearFilterButton';

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
    },
    clear: {
        flex: 0,
    },
    button: {
        flex: 1,
        textAlign: "center",
    }
}))

export default function DateFilterInput({column: { filterValue, setFilter }}){
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useDebounceState(filterValue, setFilter);
    const handleClear = useCallback(() => {setValue(undefined); setOpen(false)}, [setValue]);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleChange = useCallback((item) => setValue({query: item.selection, type: "date" }), [setValue]);
    return (
        <div className={classes.container}>
            {open && 
            <Dialog maxWidth={'lg'} open={open} onClose={handleClose}>
                <DialogContent>
                    <DateRangePicker onChange={handleChange} 
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={value?.query ? [value.query] : [{startDate: new Date(), endDate: new Date(), key: "selection"}]}
                        direction="horizontal"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClear} color="primary">
                        Clear
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>}
            <div className={classes.button}>
                <Tooltip placement="top" title={value ? ("Filtered range: " + format(value.query.startDate, "P") + " - " + format(value.query.endDate, "P")) : "Select date range"}>
                    <IconButton onClick={() => setOpen(true)} size="small">
                        <DateRangeIcon color={value ? "primary" : undefined} fontSize="small"/>
                    </IconButton>
                </Tooltip>
            </div>
            {value &&
            <div className={classes.clear}>
                <ClearFilterButton onClick={handleClear}/>
            </div>}
        </div>
    );
}