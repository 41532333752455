/**
 * @format
 */
import React, { useState, useMemo, useRef, useEffect } from "react"
import {
    TextField,
    MenuItem,
    makeStyles,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
} from "@material-ui/core"
import InstrumentSelector from "../General/InstrumentSelector"
import TagSelector from "../General/TagSelector"
import MaterialSelector, {
    MaterialSelectorBase,
} from "../General/MaterialSelector"
import { TestTree } from "./TestTree"
import { PropertyTree } from "./PropertyTree"
import { PropertySelectorBase } from "../General/PropertySelector"
import { ComponentTree } from "./ComponentTree"
import {
    useQueryComponentProperties,
    useQueryMaterial,
} from "../../API/queryHooks"
import { mathjsKeywords } from "./functionList"
import ExpressionField from "./ExpressionField"
import { ExampleResultBase } from "./ExampleResults"
import { serializeVariable, getVariableResults } from "./expressionUtils"

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
    },
    item: {
        flex: 1,
        margin: theme.spacing(0, 0.5),
    },
    rowContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: theme.spacing(1, 0),
    },
    expressionPrefix: {
        alignSelf: "center",
        marginRight: theme.spacing(1),
    },
    rootSelector: {
        maxWidth: "20ch",
        marginRight: theme.spacing(0.5),
    },
    treeContainer: {
        maxHeight: "400px",
        overflow: "auto",
        minHeight: 70,
        width: "100%",
        border: "1px solid",
        borderRadius: theme.spacing(0.5),
        borderColor:
            theme.palette.type === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
        "&:hover": {
            borderColor: theme.palette.text.primary,
        },
        "&:focus-within": {
            borderColor: theme.palette.primary.main,
        },
    },
}))

const roots = {
    Tests: {
        key: "Tests",
        label: "Tests",
        element: "test",
        Filters: TestFilters,
        Tree: TestTree,
    },
    Components: {
        key: "Components",
        label: "Components",
        element: "component",
        Filters: ComponentsFilters,
        Tree: ComponentTree,
    },
    Properties: {
        key: "Properties",
        label: "Properties",
        element: "property",
        defaultKey: "property.data",
        Filters: PropertiesFilters,
        Tree: PropertyTree,
    },
}

export default function ExpressionVariableSelectorDialog({
    sample,
    setSample,
    variables,
    onClose,
    variable,
    ...dialogProps
}) {
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={onClose}
            open={!!variable}
            {...dialogProps}
        >
            <ExpressionVariableSelector
                sample={sample}
                setSample={setSample}
                variables={variables}
                onClose={onClose}
                variable={variable}
            />
        </Dialog>
    )
}

export function ExpressionVariableSelector({
    sample: rawSample,
    setSample,
    variables,
    onClose,
    variable,
}) {
    const classes = useStyles()

    const [elementExpression, setElementExpression] = useState(
        variable?.expression || "",
    )
    const [root, setRoot] = useState(roots[variable?.key] || roots.Properties)
    const [variableName, setVariableName] = useState(
        variable?.name || root?.element || "var",
    )

    const [errors, setErrors] = useState(variable?.errors || "show")

    const [filters, setFilters] = useState(variable?.filters || {})
    const [key, setKey] = useState("")
    const expressionRef = useRef()
    const [expressionCursor, setExpressionCursor] = useState(undefined)
    const Filters = root.Filters
    const Tree = root.Tree

    // inject component properties if necessary
    const [selectedSample, setSelectedSampleRaw] = useState(rawSample)
    const setSelectedSample = value => {
        setSelectedSampleRaw(value)
        setSample(value)
    }
    useEffect(() => {
        setSelectedSampleRaw(rawSample)
    }, [rawSample])

    const { data: material } = useQueryMaterial(selectedSample)
    const samples = useMemo(() => material && [material], [material])

    const { data: filledSamples } = useQueryComponentProperties(
        {
            samples,
        },
        filters.componentProperties,
        { keepPreviousData: true },
    )
    const sample = useMemo(() => filledSamples && filledSamples[0], [
        filledSamples,
    ])

    const handleSelectChange = ev =>
        setExpressionCursor({
            start: ev.target.selectionStart,
            end: ev.target.selectionEnd,
        })

    const expression = elementExpression || key

    const nameError = useMemo(() => {
        if (
            [
                "sample",
                "properties",
                "tests",
                "processes",
                "components",
            ].includes(variableName)
        )
            return "This name is a reseved keyword"
        if (
            variable &&
            variables?.find(
                (v, i) => v.name === variableName && variable?.index !== i,
            )
        )
            return "Variable names must be unique"
        if (mathjsKeywords.includes(variableName))
            return "This name is a reseved keyword"
        return undefined
    }, [variable, variableName, variables])

    const valid = expression && !nameError

    const serializedVariable = useMemo(
        () =>
            serializeVariable({
                name: variableName,
                expression,
                filters,
                root,
                errors,
            }),
        [errors, expression, filters, root, variableName],
    )

    return (
        <>
            <DialogTitle>New Sample Variable</DialogTitle>
            <DialogContent>
                <div className={classes.container}>
                    <div className={classes.rowContainer}>
                        <TextField
                            label="New Variable Name"
                            className={classes.item}
                            variant="outlined"
                            size="small"
                            margin="none"
                            value={variableName}
                            onChange={ev =>
                                setVariableName(
                                    ev.target.value.replace(
                                        /[^a-z0-9_]+|^[0-9]+/gi,
                                        "",
                                    ),
                                )
                            }
                            helperText={nameError}
                            error={!!nameError}
                        />
                    </div>
                    <div className={classes.rowContainer}>
                        <TextField
                            label="Root Key"
                            className={classes.rootSelector}
                            variant="outlined"
                            size="small"
                            margin="none"
                            select
                            value={root}
                            onChange={ev => {
                                setRoot(ev.target.value)
                                setKey(ev.target.value.defaultKey || "")
                                setElementExpression("")
                            }}
                        >
                            <MenuItem value={roots.Properties}>
                                {roots.Properties.label}
                            </MenuItem>
                            <MenuItem value={roots.Components}>
                                {roots.Components.label}
                            </MenuItem>
                            <MenuItem value={roots.Tests}>
                                {roots.Tests.label}
                            </MenuItem>
                        </TextField>
                        <Filters
                            classes={classes}
                            filters={filters}
                            setFilters={setFilters}
                            sample={sample}
                        />
                    </div>
                    <div className={classes.rowContainer}>
                        <div className={classes.treeContainer}>
                            <Tree
                                filters={filters}
                                setFilters={setFilters}
                                sample={sample}
                                onSelect={nodeId => setKey(nodeId)}
                                onClickKey={key => {
                                    setElementExpression(current =>
                                        expressionCursor
                                            ? current.slice(
                                                  0,
                                                  expressionCursor.start,
                                              ) +
                                              key +
                                              current.slice(
                                                  expressionCursor.end,
                                              )
                                            : current + key,
                                    )
                                    expressionRef.current.focus()
                                    setTimeout(() =>
                                        !!expressionCursor
                                            ? expressionRef.current.setSelectionRange(
                                                  expressionCursor.start,
                                                  expressionCursor.start +
                                                      key.length,
                                              )
                                            : expressionRef.current.setSelectionRange(
                                                  expressionRef.current.value
                                                      ?.length || 0,
                                                  (expressionRef.current.value
                                                      ?.length || 0) +
                                                      key.length,
                                              ),
                                    )
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.rowContainer}>
                        <Typography
                            className={classes.expressionPrefix}
                        >{`f(${root.element})=`}</Typography>
                        <ExpressionField
                            className={classes.item}
                            multiline
                            label="Variable Expression"
                            placeholder={expression}
                            scope={[
                                [
                                    root.element,
                                    `${root.element} - A ${root.element} object connected to the sample.`,
                                ],
                            ]}
                            margin="none"
                            expression={elementExpression}
                            setExpression={setElementExpression}
                            onBlur={handleSelectChange}
                            inputRef={expressionRef}
                            InputLabelProps={{
                                shrink: !!expression,
                            }}
                        />
                    </div>
                    <div className={classes.rowContainer}>
                        <div className={classes.item}>
                            <TextField
                                label="Error Handling"
                                variant="outlined"
                                size="small"
                                margin="none"
                                select
                                value={errors}
                                fullWidth
                                onChange={ev => {
                                    setErrors(ev.target.value)
                                }}
                            >
                                <MenuItem value="show">
                                    Show errors and fail calculations
                                </MenuItem>
                                <MenuItem value="ignore">
                                    Ignore errors
                                </MenuItem>
                                <MenuItem value="null">
                                    Return null on errors
                                </MenuItem>
                            </TextField>
                        </div>
                    </div>
                    <div className={classes.rowContainer}>
                        <div className={classes.item}>
                            <MaterialSelector
                                label="Example Sample"
                                selected={
                                    selectedSample ? [selectedSample] : []
                                }
                                fullWidth
                                selectSingle
                                onChange={(ev, values) =>
                                    setSelectedSample(values[0])
                                }
                            />
                        </div>
                        <div className={classes.item}>
                            <VariableExampleResult
                                sample={sample}
                                serializedVariable={serializedVariable}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={!valid}
                    onClick={ev =>
                        onClose(ev, "add", serializedVariable, variable.index)
                    }
                    color="primary"
                >
                    {variable?.index >= 0 ? "Update" : "Add"}
                </Button>
            </DialogActions>
        </>
    )
}

function VariableExampleResult({ sample, serializedVariable }) {
    const results = useMemo(() => {
        if (!sample) return { value: "" }
        try {
            const results = getVariableResults({ sample, serializedVariable })
            return { value: results?.length === 0 ? "Empty Array" : results }
        } catch (error) {
            return { error: true, value: error?.message || "Unknown error" }
        }
    }, [sample, serializedVariable])

    const value =
        typeof results.value !== "string"
            ? JSON.stringify(results.value)
            : results.value

    return (
        <ExampleResultBase
            value={value || "Select sample to see example results"}
            error={results.error}
        />
    )
}

function TestFilters({ classes, filters, setFilters, sample, onAdd }) {
    const { instruments, tags } = filters

    const instrumentOptions = useMemo(
        () =>
            sample
                ? (sample.tests || [])
                      .map(inst => inst.instrument)
                      .filter(
                          (obj, i, arr) =>
                              arr.findIndex(o => o.id === obj.id) === i,
                      )
                : [],
        [sample],
    )

    return (
        <>
            <InstrumentSelector
                className={classes.item}
                label="Instrument Filter"
                selected={instruments}
                onChange={(ev, values) =>
                    setFilters(current => ({ ...current, instruments: values }))
                }
                fullWidth
                appendOptions={instrumentOptions}
                groupBy={option =>
                    instrumentOptions?.includes(option)
                        ? "In Current Sample"
                        : "All"
                }
            />
            <TagSelector
                className={classes.item}
                label="Tag Filter"
                value={tags}
                onChange={(ev, values) =>
                    setFilters(current => ({ ...current, tags: values }))
                }
                fullWidth
                helperText={undefined}
            />
        </>
    )
}

function ComponentsFilters({ classes, filters, setFilters, sample }) {
    const { materials, tags } = filters

    const materialOptions = useMemo(
        () =>
            sample
                ? (sample.components || [])
                      .map(inst => inst.definition)
                      .filter(
                          (obj, i, arr) =>
                              arr.findIndex(o => o.id === obj.id) === i,
                      )
                : [],
        [sample],
    )

    return (
        <>
            <MaterialSelectorBase
                className={classes.item}
                label="Material Filter"
                selected={materials}
                onChange={(ev, values) =>
                    setFilters(current => ({ ...current, materials: values }))
                }
                fullWidth
                appendOptions={materialOptions}
                groupBy={option =>
                    materialOptions?.includes(option)
                        ? "In Current Sample"
                        : "All"
                }
            />
            <TagSelector
                className={classes.item}
                label="Tag Filter"
                value={tags}
                onChange={(ev, values) =>
                    setFilters(current => ({ ...current, tags: values }))
                }
                fullWidth
                helperText={undefined}
            />
        </>
    )
}

function PropertiesFilters({ classes, filters, setFilters, sample }) {
    const { tags, properties } = filters

    const propertyOptions = useMemo(
        () =>
            sample
                ? (sample.properties || [])
                      .map(inst => inst.definition)
                      .filter(
                          (obj, i, arr) =>
                              arr.findIndex(o => o.id === obj.id) === i,
                      )
                : [],
        [sample],
    )

    return (
        <>
            <PropertySelectorBase
                className={classes.item}
                label="Select Property"
                selected={properties}
                onChange={(ev, values) =>
                    setFilters(current => ({ ...current, properties: values }))
                }
                fullWidth
                appendOptions={propertyOptions}
                groupBy={option =>
                    propertyOptions?.includes(option)
                        ? "In Current Sample"
                        : "All"
                }
            />
            <TagSelector
                className={classes.item}
                label="Tag Filter"
                value={tags}
                onChange={(ev, values) =>
                    setFilters(current => ({ ...current, tags: values }))
                }
                fullWidth
                helperText={undefined}
            />
        </>
    )
}
