import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
    const offset = theme.spacing(2);
    const totalElementHeight = theme.spacing(2) + (theme.spacing(0.5) * 2);
    return ({
        fcPortLabelGroup: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: props => `${props.portsHeight * totalElementHeight}px`,
            "&.left":{
                marginLeft: `${offset}px`,
                marginRight: `${offset}px`,
            },
            "&.right":{
                marginRight: `${offset}px`,
                marginLeft: `${offset}px`,
            },
        },
        fcPortLabelText:{
            ...theme.typography.caption,
            whiteSpace: "nowrap",
            "&.left":{
                textAlign: "left",
            },
            "&.right":{
                textAlign: "right",
            },
        }
    });
});

export default function PortLabelGroup(props){
    const { ports, side } = props;
    const classes = useStyles({portsHeight: ports.length});
    return (
        <div className={clsx(classes.fcPortLabelGroup, side)}>
            {ports && ports.map((port, k) => (
                <div key={k} className={clsx(classes.fcPortLabelText, side)}>
                    {(port.properties && port.properties.label && port.properties.label !== "") ? port.properties.label : `Input ${k+1}`}
                </div>
            ))}
        </div>
    );
}