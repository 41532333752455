import React, { useEffect, useRef, useState } from "react"
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles"
import ButtonWithSpinner from "../../../General/ButtonWithSpinner"
import common_styles from "../../../../styles/common_styles"
import MemoryIcon from "mdi-material-ui/Memory"
import CloseIcon from "@material-ui/icons/Close"
import HelpCircleIcon from "mdi-material-ui/HelpCircleOutline";
import RocketLaunchIcon from "mdi-material-ui/RocketLaunchOutline";
import { Collapse, Paper, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAppStoreKey } from "../../../../AppStore";

const useStyles = makeStyles((theme) => ({
  ...common_styles(theme),
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

function MLModelBase({handleRemovePlot, handleTrainModel, isLoading, modelName, modelDescription, modelKey,  children}) {
  const classes = useStyles();
  const [modelExpanded, setModelExpanded] = useState(true)
  const currentComponent = useRef(true)
  const [MSState, ] = useAppStoreKey('MixingStudioState')
  useEffect(() => () => currentComponent.current = false, [])

  return (
    <>
      <Paper variant="outlined" style={{margin:4}}>
      <Collapse in={modelExpanded} timeout="auto" collapsedSize={55}>
        <div style={{display: "flex", flexDirection: "row", marginLeft: 24}}>
          <MemoryIcon style={{marginTop: 12}}/>
          <Typography variant="subtitle1" style={{ textAlign: "left", marginTop: 12, marginLeft: 12}}>
            {modelName}
          </Typography>
          <Tooltip title={<Typography variant="subtitle1">{modelDescription}</Typography>} placement="top">
            <HelpCircleIcon style={{ fontSize: 15, marginRight:12, marginTop: 12 }}/>
          </Tooltip>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto'}}>
            <ButtonWithSpinner
              color="primary"
              loading={isLoading}
              disabled={!MSState['enableTraining']}
              onClick={handleTrainModel}
              style={{ padding: 0 }}
            >
              <Tooltip title={<Typography variant="subtitle1">Start Training</Typography>} placement="top">
                <RocketLaunchIcon />
              </Tooltip>
            </ButtonWithSpinner>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: modelExpanded,
              })}
              onClick={() => { setModelExpanded(!modelExpanded) }}
              aria-expanded={modelExpanded}
              aria-label="show more"
              // style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 16, paddingRight: 16}}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton
                color="secondary"
                onClick={() => {handleRemovePlot(modelKey)}}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {children}
      </Collapse></Paper>
    </>
  );
}

export default React.memo(MLModelBase);