import { Card, CardHeader, Link, makeStyles, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

// const skylabProdStudioLink = "https://web.microsoftstream.com/channel/987ba9cd-7887-4827-be95-c1a26ce592c1";
const skylabProdStudioLink = "https://skydrive3m.sharepoint.com/teams/SkylabUsers/Lists/" + 
    "Materials%20Manager%20Videos/AllItems.aspx?viewid=3c393699%2Dc9cc%2D4073%2D890c%2Decb9d144f53" +
    "a&playlistLayout=playback&itemId=78"
const appColors = {
    'mixingStudio': '#00C8E6',
    'materialsManager': '#00B432',//'#75C172',
    'experimentManager': '#DC14AA', //'#ff0213',//'#F9B0B5',
    'skylabGeneral': '#F5821E'//'#FAAA19'
}

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        marginBottom: 'auto',
        overflowX: "auto",
        padding: theme.spacing(3),
        width: "100%",
    },
    description: {
        margin: theme.spacing(2)
    },
    line: {
        margin: theme.spacing(1, 0),
    },
    guide: {
        margin: theme.spacing(1, 0),
        fontSize: 20,
    },
    item: {
        margin: theme.spacing(2, 0),
        backgroundColor: alpha(appColors.skylabGeneral, 0.33)
    },
    materialsManager: {
        backgroundColor: alpha(appColors.materialsManager, 0.33)
    },
    experimentManager: {
        backgroundColor: alpha(appColors.experimentManager, 0.33)
    },
    mixingStudio: {
        backgroundColor: alpha(appColors.mixingStudio, 0.33)
    },
}))

export const UserGuide = ({ fontSize = 20, fontColor = appColors.skylabGeneral, text = "Skylab User Guide" }) => {
    return (
        <b>
            <u>
                <Link
                    href="https://skydrive3m-my.sharepoint.com/:o:/g/personal/a18w4zz_mmm_com/Eub6M9Y35KtJoa_ORlFZSNwB1KBqmnO5XqN-_OQAwElxRg"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: alpha(fontColor, 0.95), fontSize: fontSize }}
                >
                    {text}
                </Link>
            </u>
        </b>
    )
}

const SkylabProdStudio = ({ text = "Skylab Production Studio", fontColor = appColors.skylabGeneral }) => {
    return (
        <Link 
            style={{ color: fontColor, fontWeight: 'bold', fontSize: 20, textDecoration: "underline" }}
            href={skylabProdStudioLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            {text}
        </Link>
    )
}

const TrainingAndDocumentationWording = () => {
    return <UserGuide text="training documentation" fontColor={appColors.mixingStudio} />
}

const VideoContentWording = () => {
    return <SkylabProdStudio text="video content" fontColor={appColors.materialsManager}/>
}

function TrainingVideos() {
    const classes = useStyles();

    return (
        <Card className={classes.card} >
            <CardHeader title="Material Informatics Training Content" />
            <div className={classes.description}>
                <Typography className={classes.line}>
                    This Materials Informatics platform is ever-changing and dynamic. As such, all updated content
                    has been moved to the sites linked below. Users can access that content there.
                </Typography>
                <Typography className={classes.guide}>
                    Access <TrainingAndDocumentationWording /> through the <UserGuide />
                </Typography>
                <Typography className={classes.guide}>
                    Access <VideoContentWording /> through the <SkylabProdStudio />
                </Typography>
            </div>
        </Card>
    )
}

export default TrainingVideos;
