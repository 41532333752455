import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import PreprocessFuncsBase from "./PreprocessFuncsBase";


export default function JustApplyPreprocess({pfKey, handleRemovePlot}) {
    const funcName = "Arbitrary Preprocess";
    const description = "Add arbitrary preprocess function";
    const exampleDict = {
        "NormalizeSelectedCols": {
            "kwargs": {
                "cols": [0, 1],
                "sum_to": 10,
            }
        }
    }

    const [pfDict, setPFDict] = useState(JSON.stringify(exampleDict))
    const updateDict = () => JSON.parse(pfDict)

    return (
        <>
            <PreprocessFuncsBase
              funcName={funcName}
              description={description}
              pfKey={pfKey}
              handleRemovePlot={handleRemovePlot}
              updateDict={updateDict}
            >
                <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Model Parameters"
                    multiline
                    rows={4}
                    value={pfDict}
                    onChange={(event) => setPFDict(event.target.value)}
                    variant="outlined"
                    style={{ margin: 24, width: '90%'}}
                />
            </PreprocessFuncsBase>
        </>
    );
}