import React, { useCallback, useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { matchSorter } from 'match-sorter';
import clsx from 'clsx';

import SearchIcon from "@material-ui/icons/Search"
import {
  CircularProgress, makeStyles, Paper, TableContainer, TableSortLabel, Typography,
  Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';

import TableSpinner from './TableSpinner';
import DefaultCell from './DefaultCell';
import DefaultFilterInput from './DefaultFilterInput';
import { usePrevious } from '../../utils/utils';


const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "0 0 4px 4px"
  },
  resizer: {
    width: theme.spacing(2),
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translateX(50%)",
    touchAction: "none",
    cursor: "col-resize",
    '&:hover': {
      backgroundColor: theme.palette.primary.main + "50",
    }
  },
  tableHeaders: {
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  tableHeaderCell: {
    borderRight: "1px solid rgba(0,0,0,0.1)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tablePagination: {
    display: "flex",
    position: "relative",
    minHeight: "52px",
    paddingRight: theme.spacing(1),
    alignItems: "center"
  },
  tableCell: {
    display: "flex",
  },
  tableRoot: {
    position: "relative",
  },
  tableContainer: {
  },
  emptyCell: {
    boxSizing: "border-box",
    display: "flex",
  },
  emptyCellFlex: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "auto",
  },
  emptyCellBlock: {
    display: "inline-block",
  },
  emptyRow: {
    boxSizing: "border-box",
  },
  emptyRowFlex: {
    display: "flex",
  },
  emptyCellInner: {
    backgroundColor: theme.palette.text.primary,
    flex: 1,
    animationName: "$pulsateLight",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationDirection: "alternate"
  },
  '@keyframes pulsateLight': {
    from: {opacity: 0.15},
    to: {opacity: 0.05},
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.main + "20",
    '&:hover':{
      backgroundColor: theme.palette.primary.dark + "20",
    }
  },
  filterRow: {
    backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["100"],
  },
  filterCell: {
    boxSizing: "border-box",
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  paginationContainer: {
    position: "relative",
    minHeight: theme.spacing(4),
    borderTop: "2px solid rgba(0,0,0,0.1)",
    paddingRight: theme.spacing(2),
  },
  paginationSpacer: {
    flex: "1 1 100%"
  },
  paginationPages: {
    flexWrap: "nowrap",
    "& ul": {
      flexWrap: "nowrap",
    }
  },
  updatingInfoContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    transform: "translateY(-40%)",
    left: theme.spacing(2),
  },
  updatingText: {
    animationName: "$pulsate",
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    marginLeft: theme.spacing(2),
  },
  '@keyframes pulsate': {
    from: {opacity: 1},
    to: {opacity: 0.5},
  },
  // affects infinite scrolling feature & visibility of the target row
  tableBody: {
    position: "relative",
    display: "block",
    overflowY: "hidden",
    // overflowX is what fixes the "shaking" experienced by some users on fullscreen mode
    overflowX: "hidden"
  },
  // hide pagination back and next buttons
  paginationActions: {
    display: "none",
  },
  // no results message
  noResultsContainer: {
    backgroundColor: theme.palette.type === "dark" ? `#FFFFFF30` : `rgba(255,255,255,0.7)`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    left: '0px',
    textAnchor: 'middle',
    textAlign: 'center',
  },
  noResultsContent: {
    position: 'relative',
    top: '40%',
    left: '0%',

  },
  moreCell: {
    textAlign: "center",
    color: theme.palette.text.disabled
  }
}));

export const LOADING_ROW = {}
Object.freeze(LOADING_ROW)

export const defaultFilterTypes = {
  text: (rows, id, filterValue) => {
      return matchSorter(rows, filterValue?.query, { keys: [row => row.values[id]] });
  }
}

export const defaultColumn = {
  width: "100",
  Cell: DefaultCell,
  Filter: DefaultFilterInput,
}

export function getFullHeightRowsDisplayed(offset = 450) {
  return Math.min(Math.max(Math.floor((window.innerHeight - offset)/33), 5), 25)
}

const outerElementType = React.forwardRef(({children, style, ...rest}, ref) => {
  return (
  <div ref={ref} {...{...rest, style: {...style, overflow: undefined, overflowY: 'auto', overflowX: 'hidden'}}}>
    <div style={{width: style.width, position: "relative"}}>
      {children}
    </div>
  </div>)
})

/** the number of rows beyond those currently displayed to make sure are loaded and ready */
const LOOK_AHEAD = 8

/** for "pagination" the number of rows jumped. This is independent of pageSize which is the chuck of rows loaded in each block from the BE  */
const pageJumpSize = 50

function StyledReactTable({ 
  getTableProps,
  headerGroups,
  rows : _rows,
  totalRows,
  prepareRow,
  gotoPage,
  allColumns,
  state,
  loading,
  updating,
  onChangeParam,
  rowHeight = 33,
  rowsDisplayed,
  hideNoResultsMessage,
  selectedOnly,
  selectedRows
}) {  
  let rows = _rows;
  if(selectedOnly) {
    // Update props such that the rows are only those selected, the # of rows is correct, and remove rowsDisplayed in case that breaks anything
    rows = selectedRows;
    totalRows = selectedRows.length;
    rowsDisplayed = undefined;

  }

  if(!rows) rows = []
  const classes = useStyles()
  const component = 'div'
  if (totalRows === undefined) totalRows = rows.length
  if (rowsDisplayed === undefined) rowsDisplayed = getFullHeightRowsDisplayed()
  const allRowsLoaded = rows.length >= totalRows
  const hasNoResults = totalRows === 0
  const rowCount = Math.max(rows.length, rowsDisplayed)
  const [rowOffset, setRowOffset] = React.useState(1)
  const scrollRef = useRef()
  const scrollToRef = useRef()

  const renderRow = useRenderRow({
    allRowsLoaded,
    rows,
    totalRows,
    updating,
    allColumns,
    classes,
    component,
    prepareRow,
    selectedOnly
  })

  useUpdateEffects({
    gotoPage,
    state,
    onChangeParam
  })

  useEffect(() => {
    const lastRowNeeded = Math.min(rowOffset+rowsDisplayed+LOOK_AHEAD, totalRows)
    const lastIndex = Math.floor(totalRows / state.pageSize)
    const lastIndexNeeded = Math.min(lastIndex, Math.floor(lastRowNeeded / state.pageSize))
    if (lastIndexNeeded > state.pageIndex) {
      gotoPage(lastIndexNeeded)
    } 
  }, [gotoPage, rowOffset, rowsDisplayed, state.pageIndex, state.pageSize, totalRows])

  const gotoRow = useCallback((row) => {
    if (row > (state.pageIndex + 1) * state.pageSize) {
      const lastRowNeeded = Math.min(row+rowsDisplayed+LOOK_AHEAD, totalRows)
      const lastIndex = Math.floor(totalRows / state.pageSize)
      const lastIndexNeeded = Math.min(lastIndex, Math.floor(lastRowNeeded / state.pageSize))
      gotoPage(lastIndexNeeded)
      scrollToRef.current = row
    }
    else if (row < rows.length){
      scrollRef.current?.scrollTo(row*rowHeight)
    }
  }, [gotoPage, rowHeight, rows.length, rowsDisplayed, state.pageIndex, state.pageSize, totalRows])

  useEffect(() => {
    if (scrollToRef.current && rows.length > scrollToRef.current) {
      scrollRef.current?.scrollToItem(scrollToRef.current)
      scrollToRef.current = undefined
    }
  }, [rows])

  return (
    <Paper variant="outlined" className={classes.paper}>
      <div className={clsx(classes.tableContainer, classes.tableRoot)}>
        <TableContainer className={classes.tableContainer}>
          <Table component={component} size="small" {...getTableProps()}>
            <TableHead className={classes.tableHeaders} component={component}>
              <HeaderRows component={component} classes={classes} headerGroups={headerGroups} />
              {state.filters !== undefined &&
              <FilterRows component={component} classes={classes} headerGroups={headerGroups} />
              }
            </TableHead>
            <TableBody className={classes.tableBody} component={component}>
              <VirtualizeRows
                allRowsLoaded={allRowsLoaded}
                rowCount={rowCount}
                state={state}
                rowsDisplayed={rowsDisplayed}
                rowHeight={rowHeight}
                onScroll={({scrollOffset}) => {
                  setRowOffset(Math.ceil(scrollOffset/rowHeight + 0.5))
                }}
                scrollRef={scrollRef}
              >
                {renderRow}
              </VirtualizeRows>
              {!hideNoResultsMessage && hasNoResults && !loading && <NoResultsMessage component={component} classes={classes} />}
            </TableBody>
          </Table>
        </TableContainer>
        <TableSpinner loading={loading || false} />
      </div>
      {state.pageIndex !== undefined &&
        <StyledTablePagination
          classes={classes}
          state={state}
          totalRows={totalRows}
          loading={loading}
          updating={updating}
          rows={rows}
          rowOffset={rowOffset}
          rowsDisplayed={rowsDisplayed}
          gotoRow={gotoRow}
        />
      }
    </Paper>
  )
}
export default React.memo(StyledReactTable);

function VirtualizeRows({
  allRowsLoaded,
  rowCount,
  state,
  rowsDisplayed,
  rowHeight,
  scrollRef,
  children,
  ...rest
}) {

  const itemCount = allRowsLoaded ? rowCount : rowCount+1
  return (
    <div style={{width: '100%', height: rowsDisplayed * rowHeight}}>
      <AutoSizer>
        {({height, width}) => (
          <FixedSizeList
            ref={scrollRef}
            height={height}
            itemCount={itemCount}
            itemSize={rowHeight}
            width={width}
            outerElementType={outerElementType}
            forceRenderOnChangeInThisParam={state}
            overscanCount={LOOK_AHEAD}
            {...rest}
          >
            {children}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  )
}

function EmptyRow({
  allColumns,
  classes,
  component,
  loading,
  ...rest
}) {
  return (
    <TableRow className={clsx(classes.emptyRow, classes.emptyRowFlex)} component={component} hover {...rest}>
      {allColumns.map((col, j) => (
        <TableCell className={clsx(classes.emptyCell, classes.emptyCellFlex)} component={component} key={j} {...col.getHeaderProps()}>
          {loading ? 
          <div className={clsx(classes.emptyCellInner)}>
            {'\u00A0'}
          </div>
          :
          '\u00A0'}
        </TableCell>
      ))}
    </TableRow>
  )
}

function HeaderRows({
  component,
  classes,
  headerGroups,
}) {
  return (
    headerGroups.map(headerGroup => (
      <TableRow component={component} {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column, j) => (
          <TableCell
            padding={column.headerPadding}
            component={component}
            className={clsx(classes.tableCell, classes.tableHeaderCell)}
            {...column.getHeaderProps(column.canSort ? column.getSortByToggleProps(column.cellProps) : column.cellProps)}
          >
            {column.canSort ? <TableSortLabel 
              active={column.isSorted} 
              direction={column.isSortedDesc ? 'desc' : 'asc'} 
              >
              {column.render('Header')} 
            </TableSortLabel> : column.render('Header')}
            {column.getResizerProps &&
              <div className={classes.resizer} {...column.getResizerProps({onClick(ev){ev.stopPropagation()}})} />}
          </TableCell>
        ))}
      </TableRow>
    ))
  )
}

function FilterRows({
  component,
  classes,
  headerGroups,
}) {
  const lastHeaderGroup = headerGroups[headerGroups.length-1];
  return (
    <TableRow component={component} className={clsx(classes.filterRow)} {...lastHeaderGroup.getHeaderGroupProps()}>
      {lastHeaderGroup.headers.map((column, j) => (
        <TableCell padding={column.headerPadding} component={component} key={j} className={clsx(classes.filterCell)} {...column.getHeaderProps()}>
          {column.canFilter ? column.render('Filter') : '\u00A0'}
        </TableCell>
      ))}
    </TableRow>
  )
}

function useRenderRow({
  allRowsLoaded,
  rows,
  totalRows,
  updating,
  allColumns,
  classes,
  component,
  prepareRow,
  selectedOnly
}) {
  return useCallback(({index, style}) => {
    if (rows[index] === undefined || rows[index].original === LOADING_ROW) {
      return (allRowsLoaded || index !== rows.length || selectedOnly) ?
        <EmptyRow
          allColumns={allColumns}
          classes={classes}
          component={component}
          style={style}
          loading={updating && index < totalRows}
        />
        :
        <LoadMoreRow
          classes={classes}
          component={component}
          style={style}
          updating={updating}
        />
    }
    const row = rows[index]
    prepareRow(row);
    return (
      <TableRow hover className={clsx(row.isSelected && classes.selectedRow)} component={component} onClick={row.toggleRowSelected ? (ev)=>{
        row.toggleRowSelected();
        } : undefined} {...row.getRowProps({style})}>
        {row.cells.map(cell => {
          return (
            <TableCell padding={cell.column?.padding} component={component} align={cell?.column?.type === "number" ? "right" : "left"} className={classes.tableCell} {...cell.getCellProps(cell.column?.cellProps)}>
              {cell.render('Cell')}
            </TableCell>
          )
        })}
      </TableRow>
    )
  }, [rows, prepareRow, classes, component, allRowsLoaded, allColumns, updating, totalRows, selectedOnly]);
}

const LoadMoreRow = React.forwardRef(({component, classes, updating, ...rest}, ref) => {
  return (
    <TableRow 
      component={component} 
      ref={ref} 
      className={clsx(classes.emptyRow, classes.emptyRowFlex)}
      {...rest}
    >
      <TableCell 
        className={clsx(classes.emptyCell, classes.emptyCellFlex, classes.moreCell, classes.updatingText)}
        component={component}
      >
        Loading more...
      </TableCell>
    </TableRow>
  )
})

// Show a message in the table body when there is no data returned for a specific query
function NoResultsMessage({classes, component}) {
  return (
    <div className={classes.noResultsContainer}>
        <div className={classes.noResultsContent}>
          <SearchIcon fontSize="large" />
          <Typography>There are no results to show</Typography>
        </div>
    </div>
  )
}

function StyledTablePagination({
  classes,
  state,
  totalRows,
  loading,
  updating,
  rows,
  rowOffset,
  gotoRow,
}) {
  const currentPage = Math.ceil((rowOffset+1)/pageJumpSize) || 1
  const totalPages = Math.ceil(1+(totalRows/pageJumpSize)) || 1

  const renderItem = useCallback((item) => {
    const row = Math.min(((item.page - 1) * pageJumpSize) || 1, totalRows)
    return <PaginationItem {...item} page={item.page ? row : undefined} disabled={row >= totalRows || item.disabled} />
  }, [totalRows])

  return (
    <div className={classes.paginationContainer}>
        <div className={classes.tablePagination}>
          <div className={classes.paginationSpacer}/>
            <Pagination 
              size="small"
              count={totalPages} 
              page={currentPage} 
              renderItem={renderItem}
              className={classes.paginationPages}
              onChange={(ev, page) => {
                gotoRow(((page-1) * pageJumpSize) - 1)
              }}
            />
          </div>
        {!loading && updating && <div className={classes.updatingInfoContainer}>
          <CircularProgress size='25px' />
          <Typography variant="caption" className={classes.updatingText}>Fetching Data...</Typography>
        </div>}
      </div>
  )
}

function useUpdateEffects({
  gotoPage,
  state,
  onChangeParam
}) {
  const prevState = usePrevious(state); 
  useEffect(() => {
    // reset pagination on filter or sort change
    if (gotoPage && (state.filters !== prevState.filters || state.sortBy !== prevState.sortBy)){
      gotoPage(0)
    }
    else{
      onChangeParam && onChangeParam({ 
        sortBy: state.sortBy,
        filters: state.filters,
        pageIndex: state.pageIndex,
        pageSize: state.pageSize, 
        selectedFlatRows: state.selectedFlatRows,
      })
    }
  }, [onChangeParam, state.sortBy, state.pageIndex, state.pageSize, state.selectedFlatRows, state.filters, prevState.filters, prevState.sortBy, gotoPage]);
}