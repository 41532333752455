import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    fcPortsGroup: {
        position: "absolute",
        flexDirection: "column",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        top: "0",
        "& div":{
            margin: `${theme.spacing(0.5)}px 0`,
        },
        "&.left":{
            left: `-${theme.spacing(0.5)}px`,
        },
        "&.right":{
            right: `-${theme.spacing(0.5)}px`,
        },
    }
}));

function PortsGroupList(props, ref){
    const {config, className, side, ...rest} = props;
    const classes = useStyles();
    return (
        <div ref={ref} className={clsx(className, classes.fcPortsGroup, side)} {...rest}/>
    );
}
export default React.forwardRef(PortsGroupList);