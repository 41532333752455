import React, { useEffect, useState, useCallback } from "react"

import { ChartTree } from "mdi-material-ui";
import { useAppStoreKey } from "../../../AppStore"

import { aichemyProtoAxios } from "../../../API/mmAxios"
import PlotCard from "./PlotCard";
import ColumnSelectField from "./ColumnSelectField";
import ColumnMultiSelectField from "./ColumnMultiSelectField";
import { GetRemovePlotFunc, InitVizParams } from "./utils";

function CorrelationPlot({ handleRemovePlot, idx, visible, savedParams = '', plot_uuid }) {
  const [workflow,] = useAppStoreKey('Workflow')
  const [plotWidthSize, setPlotWidthSize] = InitVizParams(savedParams, 'plotWidthSize', 'm')
  const getNumericalCols = useCallback(() => {
    if (!workflow) return ['']
    const column_types = workflow.data.info[workflow.data.active_sheet].column_types
    const allHeaders = workflow.data.info[workflow.data.active_sheet].all_headers
    return allHeaders.filter(item => column_types[item] === 'number')
  }, [workflow])

  const getIOCols = useCallback(() => {
    if (!workflow) return ['']
    const column_types = workflow.data.info[workflow.data.active_sheet].column_types
    const columns = [
      ...workflow.data.info[workflow.data.active_sheet].input_cols,
      ...workflow.data.info[workflow.data.active_sheet].output_cols
    ]
    return columns.filter(item => column_types[item] === 'number')
  }, [workflow])

  const [isLoading, setIsLoading] = useState(false)
  const [corrData, setCorrData] = InitVizParams(savedParams, 'corrData', [[]])
  const corrMethodList = ['pearson', 'kendall', 'spearman']
  const [corrMethod, setCorrMethod] = InitVizParams(savedParams, 'corrMethod', corrMethodList[0])
  const [selectedAxis, setSelectedAxis] = InitVizParams(savedParams, 'selectedAxis', getIOCols())
  const [currentSheet, setCurrentSheet] = InitVizParams(savedParams, 'currentSheet', '')
  const [paramsToSave, setParamsToSave] = useState({})
  const plotType = 'correlation'
  const cardName = 'Correlation plot'

  // update params to save
  useEffect(() => {
    setParamsToSave({
      ...paramsToSave,
      selectedAxis: selectedAxis,
      currentSheet: currentSheet,
      corrMethod: corrMethod,
      corrData: corrData,
      plotWidthSize: plotWidthSize
    })
  }, [selectedAxis, currentSheet, corrMethod, corrData, plotWidthSize]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemoveCurrentPlot = GetRemovePlotFunc(savedParams, handleRemovePlot)

  // get correlation data from Ap
  const getCorrData = useCallback(() => {
    if (!savedParams) {
      setIsLoading(true)
      const url = `analyze/` + workflow.uuid
      const update_dict = {
        AnalyzeDataCorrelation: {
          method: corrMethod,
          cols: getNumericalCols()
        }
      }
      const config = {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      }
      aichemyProtoAxios.post(url, JSON.stringify(update_dict), config)
        .then(res => {
          const results = res.data.AnalyzeDataCorrelation.data
          setCorrData(results)
          setIsLoading(false)
        }).catch(err => console.error(err))
    }

  }, [workflow, corrMethod, getNumericalCols]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => getCorrData(), [getCorrData])
  const getCorrDataByCols = (cols) => {
    const allCols = getNumericalCols()
    const colsIdx = cols.map(col => allCols.indexOf(col))
    let result = corrData.filter((row, idx) => colsIdx.indexOf(idx) > -1)
    result = result.map(row => {
      return row.filter((ele, idx) => colsIdx.indexOf(idx) > -1)
    })
    return result
  }

  const getForm = () => {
    return (
      <>
        <ColumnSelectField
          label={"Correlation Method"}
          value={corrMethod}
          setValue={setCorrMethod}
          headers={corrMethodList}
        />
        <ColumnMultiSelectField
          label={"Select Columns"}
          value={selectedAxis}
          setValue={setSelectedAxis}
          headers={getNumericalCols()}
        />
      </>
    )
  }

  const getIcon = () => {
    return <ChartTree style={{ marginTop: 14 }} />
  }

  const getTrace = () => {
    const currentCorrData = getCorrDataByCols(selectedAxis)
    if (currentCorrData.length === 0) return [{}]
    if (currentCorrData[0].length === 0) return [{}]
    if (currentCorrData[0].length !== selectedAxis.length) return [{}]

    // transpose data.data so it displays better
    let colNames = [...selectedAxis]
    let data_t = currentCorrData.map((row => {
      return [...row]
    }))
    for (let i = 0; i < colNames.length; i++) {
      for (let j = 0; j < colNames.length; j++) {
        data_t[i][j] = currentCorrData[colNames.length - i - 1][j]
      }
    }
    let colNames_reverse = [...colNames].reverse()
    const trace = []
    trace.push({
      z: data_t,
      x: colNames,
      y: colNames_reverse,
      type: 'heatmap',
      showscale: true
    })
    return trace
  }

  const getLayout = () => {
    const currentCorrData = getCorrDataByCols(selectedAxis)
    if (currentCorrData.length === 0) return {}
    if (currentCorrData[0].length === 0) return {}
    if (currentCorrData[0].length !== selectedAxis.length) return {}
    let colNames = [...selectedAxis]
    let layout = {
      autosize: true,
      title: "Heatmap of data correlations (" + corrMethod + ")",
      annotations: [],
      xaxis: {
        ticks: 'outside',
        side: 'bottom',
        automargin: true
      },
      yaxis: {
        ticks: '',
        side: "left",
        automargin: true
      },
    }

    for (let i = 0; i < colNames.length; i++) {
      for (let j = 0; j < colNames.length; j++) {
        let result = {
          xref: 'x1',
          yref: 'y1',
          x: colNames[i],
          y: colNames[j],
          text: currentCorrData[i][j],
          showarrow: false,
          font: {
            color: 'rgb(255, 255, 255)'
          }
        };
        layout.annotations.push(result);
      }
    }
    return layout
  }

  return (
    <PlotCard
      plot_uuid={plot_uuid}
      getTrace={getTrace}
      getLayout={getLayout}
      getForm={getForm}
      getIcon={getIcon}
      isLoading={isLoading}
      cardName={cardName}
      savedParams={savedParams}
      currentSheet={currentSheet}
      setCurrentSheet={setCurrentSheet}
      paramsToSave={paramsToSave}
      plotWidthSize={plotWidthSize}
      setPlotWidthSize={setPlotWidthSize}
      setParamsToSave={setParamsToSave}
      plotType={plotType}
      idx={idx}
      handleRemovePlot={handleRemoveCurrentPlot}
      visible={visible}
    />
  )
}

export default React.memo(CorrelationPlot);