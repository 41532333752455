/**
 * @format
 */

import has from "lodash.has"
import get from "lodash.get"

/**
 * The key used to store the value in field property maps and update function maps
 */
export const value = Symbol("value")

/**
 * Values that can be used to index an object
 */
export type Index = string | number | symbol

/**
 * Same as built in Partial but recursive. Recursion stops when an EndType is encountered
 */
export type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends EndTypes ? T[P] : RecursivePartial<T[P]>
}
export type EndTypes =
    | boolean
    | string
    | number
    | Date
    | symbol
    | undefined
    | null

/**
 * Similar to built in Record but recursive and mapped values are placed on [value] symbol key at every level in the object. Recursion stops as EndTypes. Keys are all optional
 */
export type RecursiveRecord<O, T> = { [value]?: T | undefined } & {
    [K in keyof O]?: O[K] extends EndTypes
        ? { [value]?: T | undefined }
        : RecursiveRecord<O[K], T>
}

/**
 * Trim keys in record that are not present in the obj
 */
export function trimHandingKeys<O, T>(
    obj: O | undefined,
    record: RecursiveRecord<O, T> | undefined,
): RecursiveRecord<O, T> | undefined {
    if (obj === undefined || record === undefined) return undefined
    const newRecord = { ...record } as RecursiveRecord<O, T>
    const recKeys = Object.keys(record) as (keyof O)[]
    // @ts-ignore
    const objKeys = Object.keys(obj) as (keyof O)[]
    let changed = false
    for (const key of recKeys) {
        if (!objKeys.includes(key)) {
            delete newRecord[key]
            changed = true
        } else {
            // @ts-ignore
            const temp = trimHandingKeys(obj[key], newRecord[key] as any)
            if (temp !== newRecord[key]) {
                newRecord[key] = temp as any
                changed = true
            }
        }
    }
    return changed ? newRecord : record
}

/**
 * Sets the value of the [value] key of record at every path that has a defined key in obj
 */
export function setMatching<O, T>(
    obj: O | undefined,
    record: RecursiveRecord<O, T> | undefined,
    setValue: any,
): RecursiveRecord<O, T> | undefined {
    if (obj === undefined || record === undefined) return undefined
    const newRecord = { ...record } as RecursiveRecord<O, T>
    const recKeys = Object.keys(record) as (keyof O)[]
    // @ts-ignore
    const objKeys = Object.keys(obj) as (keyof O)[]
    let changed = false
    if (newRecord[value] !== setValue) {
        changed = true
        newRecord[value] = setValue
    }
    for (const key of recKeys) {
        if (objKeys.includes(key)) {
            // @ts-ignore
            const temp = setMatching(obj[key], newRecord[key] as any, setValue)
            if (temp !== newRecord[key]) {
                newRecord[key] = temp as any
                changed = true
            }
        }
    }
    return changed ? newRecord : record
}

/**
 * Trims keys with undefined values and empty objects
 */
export function clearEmptyKeys<T>(obj: T): T | undefined {
    if (typeof obj !== "object") return obj
    const newObj =
        (obj as any)[value] === undefined
            ? {}
            : ({ [value]: (obj as any)[value] } as any)
    // @ts-ignore
    const keys = Object.keys(obj) as (keyof T)[]
    let changed = false
    for (const key of keys) {
        // @ts-ignore
        const sub = clearEmptyKeys(obj[key])
        // @ts-ignore
        if (sub !== obj[key]) changed = true
        if (sub !== undefined) {
            newObj[key] = sub
        }
    }
    const newLength = Object.keys(newObj).length
    if (newLength <= 0 && newObj[value] === undefined) return undefined
    if (changed) return newObj as T
    return obj
}

/** Returns false if key doesn't exist otherwise returns all matching paths (arrays will return all indexes that match) */
export function checkKey(
    obj: unknown | undefined,
    path: string | undefined,
): false | string | string[] {
    if (!path) return false
    const splitIndex = (path as string).indexOf("[]")
    if (splitIndex < 0) return has(obj, path) && path
    const rootPath: string = (path as string).substring(0, splitIndex)
    const remainingPath: string = (path as string).substring(
        splitIndex + ((path as string)[splitIndex + 2] === "." ? 3 : 2),
    )
    const rootObj = get(obj, rootPath)
    if (rootObj === undefined) return false
    if (!remainingPath) {
        const rtn = []
        if (Array.isArray(rootObj)) {
            for (let i = 0; i < rootObj.length; ++i) {
                if (i in rootObj) {
                    rtn.push(`${rootPath}[${i}]`)
                }
            }
        } else {
            for (const key of Object.keys(rootObj)) {
                rtn.push(`${rootPath}["${key}"]`)
            }
        }
        return rtn.length > 0 ? rtn : false
    }
    const rtn: string[] = []
    if (Array.isArray(rootObj)) {
        for (let i = 0; i < rootObj.length; ++i) {
            if (!rootObj[i]) continue
            const elRtn = checkKey(rootObj[i], remainingPath as any)
            if (!elRtn) continue
            const elRoot = `${rootPath}[${i}]`
            if (Array.isArray(elRtn)) {
                rtn.push(...elRtn.map(el => elRoot + el))
            } else {
                rtn.push(elRoot + elRtn)
            }
        }
    } else {
        for (const key of Object.keys(rootObj)) {
            if (!rootObj[key]) continue
            const elRtn = checkKey(rootObj[key], remainingPath as any)
            if (!elRtn) continue
            const elRoot = `${rootPath}["${key}"]`
            if (Array.isArray(elRtn)) {
                rtn.push(...elRtn.map(el => elRoot + el))
            } else {
                rtn.push(elRoot + elRtn)
            }
        }
    }
    return rtn
}

export function getStatus(obj: any | undefined, path: string) {
    const keys = Object.keys(obj || {})
    const rtn: any = {}
    for (const key of keys) {
        const val = get(obj[key], path)
        if (val) rtn[key] = val
    }
    return Object.keys(rtn).length > 0 ? rtn : undefined
}

export function getFirstValue(status: any | undefined): unknown | undefined {
    if (!status) return undefined
    if (status[value]) return status[value]
    for (const val of Object.values(status) as any[]) {
        const check = getFirstValue(val)
        if (check) {
            return check
        }
    }
    return undefined
}
