import React, { useState} from "react";
import TextField from "@material-ui/core/TextField";
import { SelectColumns } from "./utils";
import PreprocessFuncsBase from "./PreprocessFuncsBase";


export default function NormalizeCols({pfKey, handleRemovePlot}) {
    const [allCols, setAllCols] = useState([])
    const [selectedCols, setSelectedCols] = useState([])
    const [sumTo, setSumTo] = useState(1)
    const funcName = 'Normalize Columns'
    const description = 'For each row, the sum of values in selected columns will be normalized to the target value.'

    const handleSumToChange = (event) => {
        const newValue = event.target.value
        setSumTo(newValue)
    }

    const updateDict = () => {
        const selected_idx = []
        allCols.forEach((value, idx) => { if (selectedCols[idx]) selected_idx.push(idx) })
        return {
            "NormalizeSelectedCols": {
                "kwargs": {
                    "cols": selected_idx,
                    "sum_to": sumTo
                }
            }
        }
    }

    return (
        <>
            <PreprocessFuncsBase
              funcName={funcName}
              description={description}
              pfKey={pfKey}
              handleRemovePlot={handleRemovePlot}
              updateDict={updateDict}
            >
                <SelectColumns
                  allCols={allCols}
                  setAllCols={setAllCols}
                  selectedCols={selectedCols}
                  setSelectedCols={setSelectedCols}
                />
                <TextField
                  label="Sum to"
                  type="number"
                  onChange={handleSumToChange}
                  style={{ marginLeft: 36 }}
                  value={sumTo} />
            </PreprocessFuncsBase>
        </>
    );
}