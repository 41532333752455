import React, { useEffect, useRef, useState } from "react"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useSnackbar } from "notistack"
import common_styles from "../../../../styles/common_styles"
import MLModelBase from "./MLModelBase";
import useTrainModel from "../useTrainModel";
// import TextField from "@material-ui/core/TextField"

const useStyles = makeStyles(common_styles);


function PLS({handleRemovePlot, modelKey}) {

  const classes = useStyles();
  const modelName = "Partial Least Squares Regression";
  const modelDescription = "Partial Least Squares (PLS) regression is a linear technique that tries\n" +
    "to identify a low dimensional mapping between input and output variables, fitting a linear model\n" +
    "over this low dimensional space."
  const [params, setParams] = useState({'scale': 'auto', 'n_components': 2})  // default values
  const [isLoading, ] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const { TrainModel } = useTrainModel({ enqueueSnackbar })
  const currentComponent = useRef(true)
  // const [nComponents, setNComponents] = useState(1)
  const [nComponents,] = useState("auto")

  // detect if model view is closed in case of memory leak
  useEffect(() => () => currentComponent.current = false, [])

  const parameter_space = {
    "scale": ['auto', 'true', 'false'],
  }

  const handleParamChange = (paramName) => (event) => {
    let newParam = {}
    newParam[paramName] = event.target.value
    setParams({...params, ...newParam})
  }

  const getAllParams = () => {
    let allParams = {...params, 'n_components': nComponents}
    if (allParams.scale === 'true') {allParams.scale = true}
    if (allParams.scale === 'false') {allParams.scale = false}
    return allParams
  }

  let modelParams = {
    PLS_model: {
      kwargs: { 'params': getAllParams() }
    }
  }
  return (
    <>
      <MLModelBase
        modelName={modelName}
        modelDescription={modelDescription}
        handleTrainModel={TrainModel(modelParams, modelName)}
        handleRemovePlot={handleRemovePlot}
        isLoading={isLoading}
        modelKey={modelKey}
      >
        {Object.keys(parameter_space).map((paramName, idx) => {
          return (
            <div key={idx} style={{ marginLeft: 36}}>
              <FormControl component="fieldset" className={classes.formControl}>
                <InputLabel id={paramName + "select-label"}>{paramName}</InputLabel>
                <Select
                  labelId={paramName + "select-label"}
                  id={paramName + "select-id"}
                  value={params[paramName]}
                  onChange={handleParamChange(paramName)}
                >
                  {parameter_space[paramName].map((paramOption, idx) => {
                    return (<MenuItem key={'param_' + idx} value={paramOption}>{paramOption}</MenuItem>);
                  })}
                </Select>
              </FormControl>
            </ div>
          );
        })}
      </MLModelBase>
    </>
  );
}

export default React.memo(PLS);