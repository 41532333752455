import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/HighlightOff';

//this will check to see if user is the owner of a model and if they can delete
const ableToDelete = ({ row, onDelete }) => {
  let icon;
  if(row.original.model_role === "owner"){
    icon = <Tooltip title="Delete Row" placement="right">
              <IconButton onClick={() => onDelete && onDelete(row)} size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
  } else {
    icon = null;
  }
  return icon;
}

export function addDeleteColumn({ allColumns }) {
  allColumns.push(columns => [
    ...columns,
    {
      id: "delete",
      Header: () => <span>{'\u00A0'}</span>,
      Cell: ableToDelete,
      disableResizing: true,
      disableSortBy: true,
      disableFilters: true,
      width: 32,
      padding: "none",
      headerPadding: "none",
      filterPadding: "none",
      cellProps: { style: { justifyContent: "center", flexGrow: 0 } }
    },
  ]);
}
