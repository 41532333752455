/**
 * @format
 */

import * as Models from "schema/models"
import { NodeTypes } from "schema/models"

export const schema = Symbol()

type ModelsType = typeof Models

/** All possible model keys as string */
export type ModelKey = keyof ModelsType

/** All model instance types */
export type Model = InstanceType<ModelsType[ModelKey]>

/** All model class types that have a schema */
export type ModelWithSchema = {
    [K in keyof typeof Models]: typeof Models[K] extends { [schema]: any }
        ? typeof Models[K]
        : never
}[keyof typeof Models]

/** flatten an array type to it's element type if necessary */
export type Flatten<T> = T extends (infer U)[] ? U : T

/** get the keys of O that have type T or T[] */
export type KeysofType<O, T> = {
    [K in keyof O]: T extends Flatten<O[K]> ? K : never
}[keyof O]

/** defines the relationships and conversions of a model */
export type Schema<T extends Model> = {
    /** the specific route or endpoint for get, post, patch, and delete operations related to this model */
    readonly endPoint?: string
    /** the jsonAPI spec "type" used by the BE */
    readonly type?: string
    /** the relationship models */
    readonly relationships?: {
        readonly [key in keyof OmitSchema<T>]?: Relationship<
            T,
            NonNullable<T[key]>
        >
    } &
        ExtraModelRelationships<T>
    /** aliases the BE uses for all of the attributes in this model */
    readonly map?: {
        readonly [key in keyof OmitSchema<T>]?: string | key
    } & {
        readonly [key: string]: string | undefined
    }
    /** though "model" will match the return type in practice, the way most models extends Base and Base uses Schema<Base>, makes a type conflict */
    readonly responsePostConvertion?: (model: Model) => T
    /** though "model" will match the return type in practice, the way most models extends Base and Base uses Schema<Base>, makes a type conflict */
    readonly mutatePreConversion?: (model: Model) => T
}

/** The relationships that are not an explicit key in the object. These are used only in filter conversions  */
type ExtraModelRelationships<T> = {
    creator?: Relationship<T, Models.User>
    modifier?: Relationship<T, Models.User>
    tagsRel?: Relationship<T, Models.Tag[]>
}

/** Meta information needed to define a relationship */
export type Relationship<T, R> = {
    /** the key within the relationship model the references this root object */
    readonly reverse?: KeysofType<Flatten<R>, T>
    /** true means this is a to-many relationship (an array of model type) */
    readonly toMany: R extends unknown[] ? true : false
    /** Flags if this relationship is required */
    readonly required?: boolean
    /** The import string/model name that represents this relationship type */
    readonly model: RelationshipModelKey<R>
    /** The alias the BE uses for this field */
    readonly map: string
    /** true tells the query to get relationship by filtering on the root id rather than the relationship id. Relationship ids are not always returned by the BE making this sometimes necessary */
    readonly indirect?: boolean
    /** true tells the converter to include the full relationship on a post or patch */
    readonly includeFull?: boolean
    /** true tells the converter to always include the relationship in posts or patches even when it hasn't changed */
    readonly alwaysInclude?: boolean
    /** tells the converter that the immediate object is serialized as meta relationship info. <of> is the relationship key. <through> is the key within the object that gives the id of the relationship */
    readonly meta?: { of: string; through: keyof Flatten<R> }
}

/** The key (string) for an export from schema/models/index.ts that matches type R or R[] */
type RelationshipModelKey<R> = {
    [K in ModelKey]: Required<InstanceType<ModelsType[K]>> extends Required<
        Flatten<R>
    >
        ? K
        : never
}[ModelKey]

export type OmitSchema<T> = Omit<T, typeof schema>

/** The key of Model T */
export type KeyOfModel<T extends Model> = {
    [K in ModelKey]: Required<
        InstanceType<typeof Models[K]>
    > extends Required<T>
        ? K
        : never
}[ModelKey]

/** Model type from Model Instance T */
export type ModelOfInstance<T extends Model> = {
    [K in ModelKey]: Required<
        InstanceType<typeof Models[K]>
    > extends Required<T>
        ? typeof Models[K]
        : never
}[ModelKey]

/**
 * Provides assumed type casting within a scope, see:
 * https://github.com/microsoft/TypeScript/issues/10421#issuecomment-706606449
 */
export function assumeType<T>(x: unknown): asserts x is T {}

const DEEP_MERGE_LIMIT = 15

/**
 * Merges two object trees. Shallow merges attributes and recursively deep merges relationships by matching id when toMany relationships are encounter
 * Circular references in target are safe. Direct circlar references in source should be safe but circular references in source should be avoided in general
 */
export function modelDeepMerge<T extends ModelWithSchema>(
    target: InstanceType<T> | undefined,
    source: InstanceType<T> | undefined | null,
    model: T,
    options?: { copyUndefined?: boolean; clearEmptyRelationships?: boolean },
    ignore?: string,
    counter?: number,
): InstanceType<T> | undefined {
    const count = counter || 0
    if (count > DEEP_MERGE_LIMIT) {
        console.error(
            "Excessively deep merge encountered. Truncating result. Check for circular references.",
        )
        return target
    }

    // handle cases with no merging necessary
    if (target === source) return target as InstanceType<T> | undefined
    if (target === undefined) return source as InstanceType<T> | undefined
    if (source === undefined) {
        return (options?.copyUndefined ? undefined : target) as
            | InstanceType<T>
            | undefined
    }
    if (source === null) return undefined
    const modelSchema = model[schema] as Schema<InstanceType<T>>
    if (!modelSchema) {
        return (
            source === undefined && !options?.copyUndefined ? target : source
        ) as InstanceType<T> | undefined
    }

    // loop and merge all properties
    const merged = { ...target } as Partial<InstanceType<T>>
    let changed = false
    for (const [key, val] of Object.entries(source) as [
        keyof typeof source,
        any,
    ][]) {
        // handle undefined source and/or target members
        if (val === undefined) {
            if (options?.copyUndefined) {
                changed = true
                delete merged[key]
            }
            continue
        }
        if (merged[key] === undefined) {
            merged[key] = val
            changed = true
            continue
        }

        // some safety against circular references
        if (ignore && key === ignore) continue

        // check if it's a relationship and deep merge recursively if it is
        const rel = (modelSchema.relationships as any)?.[key]
        if (rel) {
            if (rel.toMany) {
                if (options?.clearEmptyRelationships) {
                    const cleared = (merged[key] as unknown as any[]).filter(
                        d => d?.id,
                    )
                    if (
                        cleared.length !==
                        (merged[key] as unknown as any[]).length
                    ) {
                        changed = true
                        merged[key] = cleared as any
                    }
                }
                let copied = false
                for (let i = 0; i < (val as any[]).length; ++i) {
                    const j = rel.includeFull
                        ? i
                        : (merged[key] as unknown as any[]).findIndex(s =>
                              rel.meta
                                  ? s[rel.meta.through].id ===
                                    val[i][rel.meta.through].id
                                  : s.id === val[i].id,
                          )
                    if (j < 0) {
                        changed = true
                        if (copied) {
                            ;(merged[key] as unknown as any[]).push(val[i])
                            copied = true
                        } else {
                            merged[key] = [
                                ...(merged[key] as unknown as any[]),
                                val[i],
                            ] as any
                        }
                    } else {
                        if ((merged[key] as any)[j] === val[i]) continue
                        if (!copied) {
                            ;(merged[key] as any) = [...(merged[key] as any)]
                            copied = true
                        }
                        ;(merged[key] as any)[j] = modelDeepMerge(
                            (merged[key] as any)[j],
                            val[i],
                            (Models as any)[rel.model] as ModelWithSchema,
                            options,
                            rel.reverse,
                            count + 1,
                        ) as any
                    }
                }
            } else {
                if (merged[key] === val) continue
                merged[key] = modelDeepMerge(
                    merged[key] as any,
                    val,
                    (Models as any)[rel.model] as ModelWithSchema,
                    options,
                    rel.reverse,
                    count + 1,
                ) as any
                changed = true
            }
        }
        // not relationship so treat as simple attribute
        else if (merged[key] !== val) {
            changed = true
            merged[key] = val
        }
    }

    return changed ? (new model(merged) as InstanceType<T>) : target
}

export function updateReverseRelationships<T extends ModelWithSchema>(
    obj: InstanceType<T>,
    model: T,
    fullCircular: boolean = false,
) {
    const rels = model[schema].relationships

    if (!rels) return obj

    for (const [key, rel] of Object.entries(rels)) {
        assumeType<keyof Model>(key)
        assumeType<Relationship<T, Model>>(rel)
        if (!rel.reverse) continue
        const relModel = Models[rel.model]
        const reverseRel = relModel[schema].relationships?.[rel.reverse]
        if (!reverseRel)
            throw new Error(
                `model reverse relationships not properly defined (${model.name}.${key})`,
            )
        if (!obj[key]) continue
        if (fullCircular || reverseRel.required) {
            const handleUpdate = (objKey: any) => {
                const revObj = fullCircular ? obj : new model({ id: obj.id })
                if (reverseRel.toMany) {
                    if (!objKey[rel.reverse]?.length) {
                        objKey[rel.reverse] = [revObj]
                    } else {
                        const index = objKey[rel.reverse].findIndex(
                            (r: any) => r.id === revObj.id,
                        )
                        if (index < 0) {
                            // ambiguous what to do, should we replace whats present or append? Can't know so throw
                            throw new Error(
                                "Reverse relationship fill attempted on to-many with relationships not found and miss-matching",
                            )
                        } else {
                            objKey[rel.reverse][index] = revObj
                        }
                    }
                } else {
                    objKey[rel.reverse] = revObj
                }
            }

            if (rel.toMany) {
                for (const objKey of obj[key] as unknown as Model[]) {
                    if (!objKey) continue
                    handleUpdate(objKey)
                }
            } else {
                handleUpdate(obj[key])
            }
        }
    }

    return obj
}

export function getNodeTypeRank(type: NodeTypes) {
    return {
        material: 0,
        process: 1,
        property: 2,
        output: 2,
        mixed: 3,
        "": 4,
    }[type]
}
