import { Grid, makeStyles } from "@material-ui/core"
import MaterialSummaryCard from "./MaterialSummaryCard"

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: "350px",
        overflow: "auto"
    }
}))

export default function MaterialsSummaryList({ids}) {

    const classes = useStyles()
    
    const sliced = ids.slice(0, 20)

    return (
        <Grid className={classes.container} container spacing={2}>
            {sliced.map((id, i) => 
                <Grid key={i} item xs={12} md={6} >
                    <MaterialSummaryCard id={id} />
                </Grid>
            )}
        </Grid>
    )
}