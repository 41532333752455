import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AsteriskIcon from "mdi-material-ui/Asterisk";
import ListItemText from "@material-ui/core/ListItemText";
import InlineEdit from "../components/inlineTextEdit";
import DescriptionIcon from "@material-ui/icons/Description";
import ImageFilterCenterFocusIcon from "mdi-material-ui/ImageFilterCenterFocus";
import { makeStyles } from "@material-ui/core/styles";
import common_styles from "../../../styles/common_styles";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import TuneIcon from '@material-ui/icons/Tune';

const useStyles = makeStyles(theme => ({
  ...common_styles(theme),
  root: {
    width: '100%',
    marginTop: 36,
    marginBottom: 36,
    display: 'flex',
    flexDirection: 'row',

  },
  table: {
    minWidth: 800,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function createData(ensemble_weight, type, data_preprocessors, feature_preprocessors, balancing_strategy) {
  return { ensemble_weight, type, data_preprocessors, feature_preprocessors, balancing_strategy };
}

function ModelParams({
                       modelParametersExpanded,
                       setModelParametersExpanded,
                       handleUpdateModelInfo,
                       isInfoLoading,
                       model,
                     }) {

  const classes = useStyles();
  const [isTitleInputActive, setIsTitleInputActive] = useState(false);
  const [isDescInputActive, setIsDescInputActive] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentParam, setCurrentParam] = useState(null);
  const outputCols = () => {
    const active_sheet = model.data[0].active_sheet;
    return model.data[0].info[active_sheet].output_cols
  }
  const [openParams, setOpenParams] = useState(outputCols().map(() => false));
  const handleClickParams = (param) => () => {
    console.log('handleClickParams: ', param)
    setCurrentParam(param)
    setOpenDialog(true)
  };

  const handleClose = () => {
    setOpenDialog(false)
  };

  const tableHeaders = () => ["ensemble_weight", "type", "data_preprocessors", "feature_preprocessors", "balancing_strategy"]

  const getTableRows = () => {
    if(!currentParam) return []
    const modelIds = Object.keys(currentParam.type)
    return modelIds.map((modelId) => {
      const ensemble_weight = currentParam.ensemble_weight[modelId]
      const type = currentParam.type[modelId]
      const data_preprocessors = currentParam.data_preprocessors[modelId].length > 0 ? currentParam.data_preprocessors[modelId].join(' ,') : '-'
      const feature_preprocessors = currentParam.feature_preprocessors[modelId] ? currentParam.feature_preprocessors[modelId] : '-'
      const balancing_strategy = currentParam.balancing_strategy[modelId] ? currentParam.balancing_strategy[modelId] : '-'
      return createData(ensemble_weight, type, data_preprocessors, feature_preprocessors, balancing_strategy)
    }).sort((a, b) => b.ensemble_weight - a.ensemble_weight)
  }

  const handleModelParamsClick = (idx) => () => {
    const newOpenParams = openParams.map((item, i) => {
      if (i === idx) {
        return !item
      }
      return item
    })
    setOpenParams(newOpenParams)
  }

  return (<>
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-slide-title">{"AutoML Model details"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          The final model is a combination of the following models:
        </DialogContentText>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders().map((header, index) => (
                  <TableCell align="center" key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getTableRows().map((row, idx) => {
                return (
                <TableRow key={`model-row-${idx}`}>
                  <TableCell component="th" scope="row" align="center">
                    {row.ensemble_weight}
                  </TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">{row.data_preprocessors}</TableCell>
                  <TableCell align="center">{row.feature_preprocessors}</TableCell>
                  <TableCell align="center">{row.balancing_strategy}</TableCell>
                </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

    <Accordion
      expanded={modelParametersExpanded}
      onChange={() => setModelParametersExpanded(!modelParametersExpanded)}

    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography component={'span'} className={classes.heading}>Model Parameters</Typography>
        <Typography component={'span'} className={classes.secondaryHeading}>Actual parameters used in model training</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List style={{ flexBasis: "100%" }}>
          <ListItem key={'opt-title'} button onClick={() => setIsTitleInputActive(true)}>
            <ListItemIcon>
              <AsteriskIcon color='primary' />
            </ListItemIcon>
            <ListItemText
              primary={<InlineEdit
                text={model.info.name ? model.info.name : 'Name'}
                onSetText={text => handleUpdateModelInfo({ description: model.info.description, title: text })}
                isInputActive={isTitleInputActive}
                setIsInputActive={setIsTitleInputActive}
                isLoading={isInfoLoading}
              />}
              secondary={'Click to edit title'}
            />
          </ListItem>
          <ListItem key={'opt-description'} button onClick={() => setIsDescInputActive(true)}>
            <ListItemIcon>
              <DescriptionIcon color='primary' />
            </ListItemIcon>
            <ListItemText
              primary={<InlineEdit
                text={model.info.description ? model.info.description : 'Descriptions'}
                onSetText={text => handleUpdateModelInfo({ title: model.info.title, description: text })}
                isInputActive={isDescInputActive}
                setIsInputActive={setIsDescInputActive}
                isLoading={isInfoLoading}
              />}
              secondary={'Click to edit description'}
            />
          </ListItem>
          {outputCols().map((col, idx) => {
            if (Object.keys(model.results.params[idx]).includes("ensemble_weight") &&
              Object.keys(model.results.params[idx]).includes("data_preprocessors") &&
              Object.keys(model.results.params[idx]).includes("feature_preprocessors")) {
              return <ListItem key={`param_${idx}`} dense button onClick={handleClickParams(model.results.params[idx])}>
                  <ListItemIcon>
                    <ImageFilterCenterFocusIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText
                    id={idx}
                    primary={`Ensemble of ${Object.keys(model.results.params[idx].ensemble_weight).length} models - ${col}`}
                    secondary={`Click to see details`}
                  />
                </ListItem>
            } else {
              return <div key={`param_${idx}`}>
                <ListItem button onClick={handleModelParamsClick(idx)}>
                  <ListItemIcon>
                    <TuneIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={`Model Parameters for ${col}`} />
                  {openParams[idx] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              <Collapse in={openParams[idx]} timeout="auto" unmountOnExit>
                {Object.keys(model.results.params[idx]).map((param_name) => (
                  <ListItem key={`param_${idx}_${param_name}`} dense button style={{marginLeft: 24}}>
                    <ListItemIcon>
                      <ImageFilterCenterFocusIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      id={param_name}
                      primary={`${model.results.params[idx][param_name]}`}
                      secondary={`${param_name}`}
                    />
                  </ListItem>
                ))}
              </Collapse>
              </div>
            }})}
          {}
        </List>
      </AccordionDetails>
    </Accordion></>
  );
}

export default React.memo(ModelParams);