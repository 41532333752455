import React, { useCallback, useContext, useReducer } from 'react';
import { AppStoreKey, AppStoreState } from 'AppStoreType';


type AppStoreAction = {
    type?: "clear" | "merge"
    payload?: any
}
type AppStoreDispatch = React.Dispatch<AppStoreAction>
type AppStoreUpdate<K extends AppStoreKey> = (payload: AppStoreState[K]) => void

export const AppStateContext = React.createContext<AppStoreState>({});
export const AppDispatchContext = React.createContext<AppStoreDispatch>(() => { });

function reducerProd(state: AppStoreState, action: AppStoreAction) {
    switch (action.type) {
        case 'clear':
            return {};
        case 'merge':
        case undefined:
            return { ...state, ...action.payload };
        default:
            throw new Error(`App Store received undefined action type: ${action.type}`);
    }
}
function reducerDebug(state: AppStoreState, action: AppStoreAction) {
    const after = reducerProd(state, action);
    console.log("AppStore - before: %O action: %O after: %O", state, action, after);
    return after;
}
const reducer = process.env.NODE_ENV === 'production' ? reducerProd : reducerDebug;
const initialStore: AppStoreState = { ModelContext: { sourceId: "", modelName: "", allData: [], loadType: "" } };

export function useAppStoreKey<K extends AppStoreKey>(key: K): [AppStoreState[K], AppStoreUpdate<K>] {
    const state = useContext(AppStateContext);
    const dispatch = useContext(AppDispatchContext);
    const update = useCallback((payload: AppStoreState[K]) => dispatch({ payload: { [key]: payload } }), [dispatch, key]);
    return [state[key], update];
}

export function useAppStoreDispatchKey<K extends AppStoreKey>(key: K): AppStoreUpdate<K> {
    const dispatch = useContext(AppDispatchContext);
    return useCallback(payload => dispatch({ payload: { [key]: payload } }), [dispatch, key]);
}

/**
 * App context store for holding data independent of chaning views
 */
// @ts-ignore
export const AppStore: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialStore);
    return (
        <AppDispatchContext.Provider value={dispatch}>
            <AppStateContext.Provider value={state}>
                {children}
            </AppStateContext.Provider>
        </AppDispatchContext.Provider>
    );
}
