import React, { useEffect, useState, useCallback, useMemo } from 'react';

import Drawer from './Drawer';
import AppBar from './AppBar';
import NotConnected from './NotConnected';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/themes';
import styles, { drawerWidth } from '../../styles/dashboard_styles';
import { useQueryVersion } from '../../API/queryHooks';
import TrainingContent from '../TrainingContent/TrainingContent';
import ReleaseUpdates from '../Releases/ReleaseUpdates';
import { Router, Redirect } from '@reach/router';
import { useSnackbar } from 'notistack';
import ProcessLibrary from '../ProcessLibrary/ProcessLibrary';
import PropertyLibrary from '../PropertyLibrary/PropertyLibrary';
import TestsLibrary from '../TestsLibrary/TestsLibrary';
import InstrumentsLibrary from '../InstrumentsLibrary/InstrumentsLibrary';
import MaterialsLibrary from '../MaterialsLibrary/MaterialsLibrary';
import EditSampleSetsCard from '../SampleSetLibrary/EditSampleSetsCard';
import SampleSetLibrary from '../SampleSetLibrary/SampleSetLibrary';
import MixingStudioProto from '../MixingStudio-proto/MixingStudioProto';
import UpdateMessage from './UpdateMessage';
import { useResizeDetector } from 'react-resize-detector';
import { useAppStoreKey } from "../../AppStore";
import { defaultMixingStudioState, getMSConfig } from "../MixingStudio-proto/utils";
import { GatewayPing } from 'utils/AuthProvider';


const useStyles = makeStyles(styles);

const colorSwitchPeriod = 20;

export const AppBarContext = React.createContext({ height: 0, marginLeft: 0 })

export default function Dashboard() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(true); // drawer open state
    const [iconColor, setIconColor] = useState("#C30E97"); // state to syn color shift of icon to corner image
    const { data: backendVersion, isError, isLoading } = useQueryVersion();

    // init MixingStudio state
    const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
    // set default value
    if (MSState === undefined) {
        setMSState(defaultMixingStudioState())
    }
    // init MixingStudio config
    const [MSConfig, setMSConfig] = useAppStoreKey('MixingStudioConfig')
    useEffect(() => {
        if (MSConfig === undefined) {
            getMSConfig(setMSConfig)
        }
    }, [MSConfig, setMSConfig])

    useEffect(() => {
        if (isError === "error") {
            enqueueSnackbar("Server Error: Unable to connect to database. Try refreshing the page.", { variant: "error" });
        }
    }, [enqueueSnackbar, isError]);
    // make drawer responsive to window size
    const smallWindow = useMediaQuery(theme.breakpoints.down('lg'));
    useEffect(() => { setOpen(!smallWindow); }, [smallWindow]);

    const handleDrawerOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleDrawerClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleColorChange = useCallback((color) => {
        setIconColor(color);
    }, []);

    const [showMessage, setShowMessage] = useState()

    useEffect(() => {
        // ping gateway every 24 hours to trace your session
        // this is a workaround since none of our actual traffic flows through the skylab gateway
        // and thus we don't get user auth events/don't know about their sessions
        // handles the tab persisting again since no traffic is otherwise flowing
        GatewayPing()
        let pingInterval = setInterval(GatewayPing, 1000 * 60 * 60 * 24)
        return () => { clearInterval(pingInterval) }
    }, [])

    const { ref: appBarRef, height } = useResizeDetector()
    const appBarContext = useMemo(() => ({ height, marginLeft: open ? drawerWidth : 56 }), [height, open])

    return (
        <div className={classes.root}>
            <AppBar ref={appBarRef} classes={classes} open={open} iconColor={iconColor} onDrawerOpen={handleDrawerOpen} switchPeriod={colorSwitchPeriod} setShowMessage={setShowMessage} showMessage={showMessage} />
            <Drawer classes={classes} open={open} onColorChange={handleColorChange} switchPeriod={colorSwitchPeriod} onDrawerClose={handleDrawerClose} />
            <UpdateMessage open={showMessage} setOpen={setShowMessage} />
            <AppBarContext.Provider value={appBarContext}>
                <main className={classes.content}>
                    <div style={{ minHeight: height }} />
                    {backendVersion ?
                        <Router className={classes.router}>
                            <Redirect from="/*" to="/SampleSetLibrary/" noThrow />
                            {/* <Playground path='/Playground/' /> */}
                            <SampleSetLibrary path='/SampleSetLibrary/' />
                            <EditSampleSetsCard path='/SampleSetLibrary/:setId' />
                            <MaterialsLibrary path='/MaterialLibrary/*materialId' />
                            <ProcessLibrary path='/ProcessLibrary/*processId' />
                            <PropertyLibrary path='/PropertyLibrary/*propertyId' />
                            <TestsLibrary path='/TestsLibrary/*testId' />
                            <InstrumentsLibrary path='/InstrumentsLibrary/*instrumentId' />
                            <TrainingContent path="/TrainingContent" />
                            <MixingStudioProto path="/MixingStudio/*workflowId" />
                            <ReleaseUpdates path="/Updates" />
                        </Router>
                        : <NotConnected isLoading={isLoading} />}
                </main>
            </AppBarContext.Provider>
        </div>
    );

}