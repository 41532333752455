import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useAppStoreKey } from '../../../../AppStore';
import MenuItem from "@material-ui/core/MenuItem"
import PreprocessFuncsBase from "./PreprocessFuncsBase";
import { SelectColumns } from "./utils";


export default function PCA({pfKey, handleRemovePlot}) {
    const [workflow] = useAppStoreKey("Workflow");
    const [allCols, setAllCols] = useState([])
    const [selectedCols, setSelectedCols] = useState([])
    const [nComponents, setNComponents] = useState(2)
    const [method, setMethod] = useState('linear')

    const allMethods = ['linear', 'poly', 'rbf', 'sigmoid', 'cosine', 'precomputed']

    const updateDict = () => {
        const selected_idx = []
        allCols.forEach((value, idx) => { if (selectedCols[idx]) selected_idx.push(idx) })

        return {
            "PCAScaler": {
            "kwargs": {
                "cols": selected_idx.map(idx => workflow.data.info[workflow.data.active_sheet].all_headers[idx]),
                  'n_components': String(nComponents),
                  'method': method,
            }
        }
        }
    }

    const handleNComponentsChange = (event) => {
        const newValue = event.target.value
        setNComponents(newValue)
    }

    const handleMethodChange = (event) => {
        const newValue = event.target.value
        setMethod(newValue)
    }

    return (
        <PreprocessFuncsBase
            funcName="Principal Component Analysis"
            description=""
            pfKey={pfKey}
            handleRemovePlot={handleRemovePlot}
            updateDict={updateDict}
        >
            <SelectColumns
              allCols={allCols}
              setAllCols={setAllCols}
              selectedCols={selectedCols}
              setSelectedCols={setSelectedCols}
            />
            <div style={{marginLeft: 36, marginBottom: 24}}>
                <TextField label="N Components" type="number" onChange={handleNComponentsChange} value={nComponents} />
                <TextField
                  select
                  label="PCA method"
                  value={method}
                  onChange={handleMethodChange}
                  helperText="Please select PCA method"
                  style={{ marginLeft: 6}}
                >
                    {allMethods.map((option) => (
                      <MenuItem key={option} value={option}>
                          {option}
                      </MenuItem>
                    ))}
                </TextField>
            </div>
        </PreprocessFuncsBase>
    );
}