import React, { useCallback, useState } from 'react';
import { useParams } from '@reach/router';

import {
    FormGroup, FormControlLabel, Switch, Grid, Card, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import common_styles from '../../styles/common_styles';
import { useQueryFCSingle } from '../../API/queryHooks';
import DialogWithLoading from '../General/DialogWithLoading';
import TestsTable from './TestsTable';
import EditTestCard from './EditTestCard';
import LearnMore, { LearnMoreEnum } from '../General/LearnMore';


const TEST_LIBRARY_TITLE = "Test Library";
const TEST_LIBRARY_INFO = "A Test allows the capturing of metadata from the material testing " +
    "process that provide additional context to the test results. If storage of additional metadata " +
    "associated with a field is not required, users may want to store the individual field data as " +
    "Properties instead of Tests. Tests are not manually created but are generated by collections " +
    "of instrument output data. This is an advanced feature for experienced users and will require " +
    "contact with the Skylab team directly. ";

const useStyles = makeStyles(common_styles);
function TestsLibrary({ navigate }) {
    const classes = useStyles();
    const { testId } = useParams();

    const { data: test, isFetching } = useQueryFCSingle((testId !== "") ? {
        Model: "Test",
        filter: testId,
        fields: ["labs.lab", "instrument.title", "samples"]
    } : undefined, { logName: "Full Test Info" });

    const [unlinkedOnly, setUnlinkedOnly] = useState(false);
    const [validOnly, setValidOnly] = useState(false);
    const handleEdit = useCallback(row => navigate(row.original.id), [navigate]);
    const handleCloseEdit = useCallback(() => navigate(""), [navigate]);
    const idMatch = testId === test?.id;
    const openEdit = testId !== "" && (testId || isFetching);

    return (
        <div className={classes.layout}>
            <DialogWithLoading onClose={handleCloseEdit} open={openEdit} loading={isFetching && !test}>
                <EditTestCard initTest={idMatch ? test : undefined} onClose={handleCloseEdit} />
            </DialogWithLoading>

            <Card className={classes.paperBody} elevation={3}>
                <div>
                    <Typography className={classes.cardTitle} gutterBottom>
                        {TEST_LIBRARY_TITLE}
                    </Typography>
                    <Typography className={classes.cardBodyTextEnhanced}>
                        {TEST_LIBRARY_INFO}
                        <LearnMore model={LearnMoreEnum.test} />
                    </Typography>
                    <FormGroup row>
                        <FormControlLabel label="Only show Tests that are not linked to any Samples" control={
                            <Switch checked={unlinkedOnly} onChange={(ev) => setUnlinkedOnly(ev.target.checked)} color="primary" />
                        } />
                        <FormControlLabel label="Only show Tests that are validated" control={
                            <Switch checked={validOnly} onChange={(ev) => setValidOnly(ev.target.checked)} color="primary" />
                        } />
                    </FormGroup>
                </div>
                <Grid container justify="flex-start" alignItems="flex-end" spacing={3}>
                    <Grid item xs={12}>
                        <TestsTable
                            unlinkedOnlyFilter={unlinkedOnly}
                            validOnlyFilter={validOnly}
                            onEdit={handleEdit}
                        />
                    </Grid>
                </Grid>
            </Card>

        </div >
    );
}

export default React.memo(TestsLibrary);