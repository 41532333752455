import React, {useMemo } from "react";
import { Card, Button } from '@material-ui/core';
import DataHistPlot from '../DataVizPlots/DataHistPlot'
import ScatterPlot from '../DataVizPlots/ScatterPlot'
import BoxPlot from '../DataVizPlots/BoxPlot'
import ViolinPlot from '../DataVizPlots/ViolinPlot'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import common_styles from '../../../styles/common_styles';
import { isNumber, plotHeightMapper } from "../DataVizPlots/utils";
import { useAppStoreKey } from '../../../AppStore';
import { data_to_list } from "../utils"
import CorrelationPlot from "../DataVizPlots/CorrelationPlot"
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles(common_styles);

function MsVisualizeModel() {
    const classes = useStyles();

    const [workflow,] = useAppStoreKey('Workflow')
    const [MSState, setMSState] = useAppStoreKey('MixingStudioState')


    // read and parse data from string
    const allData = useMemo(() => {
        const all_data = workflow.data

        if (!all_data) return ''

        let allData_df = workflow.data.data_df
        let all_sheets = Object.keys(allData_df)
        let allData = {}
        all_sheets.map(header => {
            let currentData_list = data_to_list(allData_df[header])
            let currentData = { data: currentData_list.data, columns: currentData_list.colNames }
            const isNum = currentData.data[0].map(elem => isNumber(elem))

            for (let i = 0; i < isNum.length; i++) {
                if (isNum[i] === false) {
                    for (let j = 1; j < currentData.data.length; j++) {
                        if (isNumber(currentData.data[j][i]) === true) {
                            isNum[i] = true;
                            break;
                        }
                    }
                }
            }
            currentData.columns = currentData.columns.filter((column, idx) => isNum[idx])
            currentData.data = currentData.data.map((data) => data.filter((column, idx) => isNum[idx]))

            allData[header] = currentData
            return ""
        })
        return allData
    }, [workflow.data])

    const initalLayout = (idx, name) => {
        const funcKey = name.split('_')[0]
        return {
            x: (idx * 6) % 12,
            y: Infinity,
            w: 6,
            h: plotHeightMapper[funcKey],
            minH: plotHeightMapper[funcKey],
            maxH: plotHeightMapper[funcKey],
            minW: 4,
            i: name,
        };
    }

    const handleRemovePlot = (idx) => {
        const nSavedPlot = workflow.viz_plot_params.length
        setMSState({
            ...MSState,
            plotCurrentList: MSState.plotCurrentList.filter((v, id) => id !== idx),
            plotLayout: MSState.plotLayout.filter((v, id) => id !== idx + nSavedPlot),
            plotParams: MSState.plotParams.filter((v, id) => id !== idx)
        })
    }

    const plotLists = (savedParams='', uuid='') => {
        return {
        'Histogram': { key: "dataHist", component: (idx) =>
            <DataHistPlot
              key={'dataHist' + idx}
              plot_uuid={uuid}
              data={allData} handleRemovePlot={handleRemovePlot}
              idx={idx}
              visible={savedParams? true : MSState.plotParams[idx].expand}
              savedParams={savedParams}
            />
        },
        'Scatter plot': { key: "scatter", component: (idx) =>
              <ScatterPlot
                key={'scatter' + idx}
                plot_uuid={uuid}
                data={allData}
                handleRemovePlot={handleRemovePlot}
                idx={idx}
                visible={savedParams? true : MSState.plotParams[idx].expand}
                savedParams={savedParams}
              /> },
        'Box plot': { key: "box", component: (idx) =>
              <BoxPlot
                key={'box' + idx}
                plot_uuid={uuid}
                data={allData}
                handleRemovePlot={handleRemovePlot}
                idx={idx}
                visible={savedParams? true : MSState.plotParams[idx].expand}
                savedParams={savedParams}
              /> },
        'Violin plot': { key: "violin", component: (idx) =>
              <ViolinPlot
                key={'violin' + idx}
                plot_uuid={uuid}
                data={allData}
                handleRemovePlot={handleRemovePlot}
                idx={idx}
                visible={savedParams? true : MSState.plotParams[idx].expand}
                savedParams={savedParams}
              /> },
        'Correlation plot': { key: "correlation", component: (idx) =>
              <CorrelationPlot
                key={'correlation' + idx}
                plot_uuid={uuid}
                handleRemovePlot={handleRemovePlot}
                idx={idx}
                visible={savedParams? true : MSState.plotParams[idx].expand}
                savedParams={savedParams}
              /> },
    }}


    const buttonStyle = () => {
        return {
            margin: 10,
            color: "#2196f3",
        }
    }

    const handleVizClick = (name) => () => {
        const selectedModelKey = plotLists()[name].key
        handleAddCard(selectedModelKey)
    }

    const handleAddCard = (key) => {
        setMSState({
            ...MSState,
            plotCurrentList: [...MSState.plotCurrentList, `${key}_${MSState.n_plots}`],
            plotLayout: [...MSState.plotLayout, initalLayout(MSState.plotCurrentList.length, `${key}_${MSState.n_plots}`)],
            plotParams: [...MSState.plotParams, { expand: true }],
            n_plots: MSState.n_plots + 1
        })
    }

    const getComponent = (funcKey, idx) => {
        funcKey = funcKey.split("_")[0] // extract the function key
        let fullFuncName = Object.keys(plotLists()).filter((fullName) => plotLists()[fullName].key === funcKey)[0]
        return plotLists()[fullFuncName].component(idx)
    }

    // pass the saved parameters to the corresponding component
    const getSavedComponent = (plot, idx) => {
        const funcKey = plot.type
        const params = plot.params
        const uuid = plot.uuid
        let fullFuncName = Object.keys(plotLists()).filter((fullName) => plotLists()[fullName].key === funcKey)[0]
        return plotLists(params, uuid)[fullFuncName].component(idx)
    }

    return (
        <div>
            <Card
                className={classes.paperBody}
                elevation={1}
                style={{ marginTop: 24, marginBottom: 24 }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                    style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
                >
                    Data Visualization
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                      textAlign: "left",
                      marginLeft: 24,
                      marginRight: 24,
                      marginBottom: 0
                  }}
                >
                    Click button to add a plot.
                </Typography>
                {Object.keys(plotLists()).map(name => (
                  <Button
                    variant="outlined"
                    key={name}
                    style={buttonStyle(name)}
                    onClick={handleVizClick(name)}
                  >
                      {name}
                  </Button>
                ))}
                <Divider style={{ marginTop: 24, marginBottom: 24 }} variant='middle' />
                <Grid container>
                    {workflow.viz_plot_params.map((plot, idx) => {
                          return getSavedComponent(plot, idx)
                      }
                    )}
                    {MSState?.plotCurrentList.map((plot, idx) => {
                        return getComponent(plot, idx)
                    })
                    }
                </Grid>
            </Card>
        </div>
    );
}

export default React.memo(MsVisualizeModel);