import React, { useCallback, useState } from "react"
import Card from "@material-ui/core/Card"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import { useAppStoreKey } from "../../../AppStore"
import { getModelName } from "../utils"
import { Tab } from "@material-ui/core";
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import PredictionSweep from "./PredictionSweep/PredictionSweep"
import SinglePointPredictionView from "./SinglePoint/SinglePointPredictionView"
import BatchPredictionView from "./BatchPredictionView"
import useModelData from './useModelData'
import usePredictionStyles from './usePredictionStyles'

function MsPredictModel() {
  const { useStyles } = usePredictionStyles()
  const classes = useStyles();

  const [workflow,] = useAppStoreKey("Workflow");
  const visibleModelIdx = workflow.models.map((model, idx) => model.hide ? "" : idx).filter(idx => idx !== "")
  const [modelID, setModelID] = useState(visibleModelIdx[0]);
  const { OriginalColumnOrdering } = useModelData({ modelID: modelID });
  const [view, setView] = useState("0")

  const handleViewChange = (ev, value) => {
    setView(value)
  }

  // model ID handling
  const handleModelSelection = (event) => {
    setModelID(event.target.value)
  }

  const getModelID = useCallback(() => {
    let currentModelID = 0
    if (modelID === '') {
      setModelID(0)
    }
    else {
      currentModelID = modelID
    }
    return currentModelID
  }, [modelID])

  const cellStyle = () => {
    const nInput = OriginalColumnOrdering.input.length

    return (rowIndex, colIndex) => {
      // Group Header row on top
      if (colIndex >= nInput) {
        return {
          className: "htCenter outputCell"
        }
      } else {
        return {
          className: "htCenter inputCell"
        }
      }
    }
  }

  return (
    <>
      <Card
        className={classes.paperBody}
        elevation={3}
        style={{ paddingBottom: 12 }}
      >
        <div className={classes.modelSelector}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 4 }}>
            Choose a model:
          </Typography>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor={"model_select"}>Model</InputLabel>
            <Select
              style={{ textAlign: "left" }}
              value={modelID}
              onChange={handleModelSelection}
              input={<Input name={"pred_model_select"} id={"pred_model_select"} />}
            >
              {workflow.models && workflow.models.map((model, idx) => {
                if (model.hide) return ''
                return (<MenuItem key={'model' + idx} value={idx}>
                  {getModelName(idx, workflow)}
                </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
      </Card>
      <Card
        className={classes.paperBody}
        elevation={3}
        style={{ marginTop: 12, marginBottom: 12 }}
      >
        <TabContext value={view}>
          <TabList
            onChange={handleViewChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="prediction-views"
          >
            <Tab label="Single Prediction" value="0" />
            <Tab label="Batch Prediction" value="1" />
            <Tab label="1-D Sweep" value="2" />
          </TabList>
          <TabPanel value="0">
            <SinglePointPredictionView
              classes={classes}
              cellStyle={cellStyle}
              workflow_id={workflow.uuid}
              modelID={getModelID()}
            />
          </TabPanel>
          <TabPanel value="1">
            <BatchPredictionView
              classes={classes}
              cellStyle={cellStyle}
              workflow_id={workflow.uuid}
              modelID={getModelID()}
            />
          </TabPanel>
          <TabPanel value="2">
            <PredictionSweep
              workflow_id={workflow.uuid}
              modelID={getModelID()}
            />
          </TabPanel>
        </TabContext>
      </Card>
    </>
  );
}

export default React.memo(MsPredictModel);
