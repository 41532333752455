import React, { ReactNode, useContext } from 'react'
import { AppBar, makeStyles, Toolbar, Theme, AppBarProps } from '@material-ui/core'
import { useResizeDetector } from 'react-resize-detector'
import { AppBarContext } from 'components/Dashboard/Dashboard'
import clsx from 'clsx'

const useStyles = makeStyles<Theme, { color: "primary" | "secondary" | "none" }>(theme => ({
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: props => props.color === "none" ? "transparent" : theme.palette.type === "dark" ? theme.palette[props.color].dark : theme.palette[props.color].light,
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    spacer: {
        flexGrow: 1
    },
}))

export type MMToolbarProps = AppBarProps & {
    color?: "primary" | "secondary" | "none",
    Left: ReactNode,
    Center: ReactNode,
    Right: ReactNode
}

/**
 * Toolbar component that is used on the top of edit views like EditSampleSet Allows sub components to be place in the Left, Center, or Right locations. Wraps material-ui AppBar. Children will be placed on the far right
 * @param {*} props forwared to the AppBar
 * @param {string} props.className merged with internal class and forwared to the AppBar
 * @param {string | undefined} props.color the material-ui theme background color, default "primary"
 * @param {*} props.Left components to be placed on th left
 * @param {*} props.Center components to be placed in the center
 * @param {*} props.Right components to be placed on the right
 */
export const MMToolbar: React.FC<MMToolbarProps> = React.memo(({
    children,
    className,
    color,
    Left,
    Center,
    Right,
    ...rest
}) => {
    const classes = useStyles({color: color || "primary"})
    const {ref, height} = useResizeDetector()
    
    const appBarContext = useContext(AppBarContext)

    return (
        <>
            <AppBar ref={ref} className={clsx(classes.appBar, className)} style={{ marginTop: appBarContext.height, marginLeft: appBarContext.marginLeft }} {...rest}>
                <Toolbar className={classes.toolbar} style={{ marginLeft: appBarContext.marginLeft }} >
                    {Left}
                    <div className={classes.spacer} />
                    {Center}
                    <div className={classes.spacer} />
                    {Right}
                    {children}
                </Toolbar>
            </AppBar>
            <div style={{ minHeight: height }}/>
        </>
    )
})
export default MMToolbar