import React, { useState, useEffect, useCallback } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from "notistack";

import common_styles from '../../../styles/common_styles';
import { useAppStoreDispatchKey, useAppStoreKey } from "../../../AppStore";

import SetActiveSheet from "./SetActiveSheet";
import DataSelectTable from "./DataSelectTable";
import { aichemyProtoUpdateWorkflow } from "../utils"
import Collapse from "@material-ui/core/Collapse"
import clsx from "clsx"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import IconButton from "@material-ui/core/IconButton"
import { getOriColumns } from "../utils"
import PrevIcon from "mdi-material-ui/ChevronDoubleUp";
import NextIcon from "mdi-material-ui/ChevronDoubleDown";
import TopIcon from "mdi-material-ui/ChevronTripleUp";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";

const useStyles = makeStyles((theme) => ({
    ...common_styles(theme),
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

//#region Table Column Definitions
const UnassignedColumnDefinitions = [
    { type: "num", displayName: "# of points" },
    { type: "name", displayName: "Column" },
]

const InputColumnDefinitions = [
    { type: "name", displayName: "Inputs (X)" },
]

const OutputColumnDefinitions = [
    { type: "name", displayName: "Outputs (Y)" },
]
//#endregion

//#region Helper functions
function not(a, b) {
    // basic set difference
    return a.filter((value) => b.indexOf(value) === -1);
}
const compareColumn = (first, second) => {
    // sorting column objects by column index
    return first.index - second.index;
}
//#endregion

export default function SetInputOutput({ topAnchorRef, prevRef, nextRef }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [workflow, setWorkflow] = useAppStoreKey("Workflow");
    const [inputCols, setInputCols] = useState([]);
    const [outputCols, setOutputCols] = useState([]);
    const [allCols, setAllCols] = useState([]);
    const [selectedUnassigned, setSelectedUnassigned] = useState([]);
    const [selectedInput, setSelectedInput] = useState([]);
    const [selectedOutput, setSelectedOutput] = useState([]);
    // const [ioSelectExpanded, setIOSelectExpanded] = useState(true)
    const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
    const setActiveStep = useAppStoreDispatchKey("ActiveStep");
    let ioSelectExpanded = MSState['ioSelectionExpand']
    const setIOSelectExpanded = (value) => setMSState({ ...MSState, ioSelectionExpand: value })

    useEffect(() => {
        if (workflow.data) {
            // setup all columns for this sheet
            const selectedSheet = workflow.data.active_sheet;
            const transforms = workflow.data.info[selectedSheet].columns_transform
            // this is the source of ORIGINAL data columns
            const allHeaders = Object.keys(workflow.data.info[selectedSheet].ori_data_range)

            const columns = allHeaders.map((col, index) => {
                return {
                    name: col,
                    num: workflow.data.info[selectedSheet]?.column_counts[col],
                    index: index
                }
            })
            setAllCols(columns);

            // fill in the input values
            const inputs = workflow.data.info[selectedSheet].input_cols
            if (inputs) {
                const originalInputs = getOriColumns(transforms, inputs)
                setInputCols(columns.filter(col => originalInputs.indexOf(col.name) !== -1))
            } else {
                setInputCols([])
            }
            // fill in the output values
            const outputs = workflow.data.info[selectedSheet].output_cols
            if (outputs) {
                const originalOutputs = getOriColumns(transforms, outputs)
                setOutputCols(columns.filter(col => originalOutputs.indexOf(col.name) !== -1))
            } else {
                setOutputCols([])
            }
        }
    }, [workflow])

    const getUnassigned = useCallback(() => {
        let diff = not(allCols, inputCols);
        diff = not(diff, outputCols);
        return diff;
    }, [allCols, inputCols, outputCols])

    const onUnassignedSelection = (selection) => {
        setSelectedUnassigned(selection)
    }

    //#region Input Column Handling
    const patchInputCols = (cols) => {
        setLoading(true)
        const transforms = workflow.data.info[workflow.data.active_sheet].columns_transform
        let transformed_cols = []
        cols.forEach(col => {
            if (transforms[col].children.length > 0) {
                transformed_cols.push(...transforms[col].children)
            } else {
                transformed_cols.push(col)
            }
        })
        let patch = {
            data_cleaning: { SetInputCols: { kwargs: { cols: transformed_cols } } }
        }
        aichemyProtoUpdateWorkflow(patch, workflow, setWorkflow).catch(err => {
            enqueueSnackbar("Failed to update input columns.", { variant: "error" });
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    const onInputSelection = (selection) => {
        setSelectedInput(selection)
    }
    const moveUnassignedToInput = () => {
        if (selectedUnassigned.length > 0) {
            let unassigned = getUnassigned();
            let input = selectedUnassigned.map(i => unassigned[i]).concat(inputCols)
            input.sort(compareColumn)
            patchInputCols(input.map(i => i.name))
        }
        setSelectedUnassigned([]);
    }
    const moveInputToUnassigned = () => {
        if (selectedInput.length > 0) {
            let input = inputCols.filter((v, i) => selectedInput.indexOf(i) === -1)
            patchInputCols(input.map(i => i.name))
            setInputCols(input);
        }
        setSelectedInput([]);
    }
    //#endregion
    //#region Output Column Handling
    const patchOutputCols = (cols) => {
        setLoading(true)
        const transforms = workflow.data.info[workflow.data.active_sheet].columns_transform
        let transformed_cols = []
        cols.forEach(col => {
            if (transforms[col].children.length > 0) {
                transformed_cols.push(...transforms[col].children)
            } else {
                transformed_cols.push(col)
            }
        })
        let patch = {
            data_cleaning: { SetOutputCols: { kwargs: { cols: transformed_cols } } }
        }
        aichemyProtoUpdateWorkflow(patch, workflow, setWorkflow).catch(err => {
            enqueueSnackbar("Failed to update output columns.", { variant: "error" });
            console.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    const onOutputSelection = (selection) => {
        setSelectedOutput(selection)
    }
    const moveUnassignedToOutput = () => {
        if (selectedUnassigned.length > 0) {
            let unassigned = getUnassigned();
            let output = selectedUnassigned.map(i => unassigned[i]).concat(outputCols)
            output.sort(compareColumn)
            patchOutputCols(output.map(i => i.name))
        }
        setSelectedUnassigned([]);
    }
    const moveOutputToUnassigned = () => {
        if (selectedOutput.length > 0) {
            let output = outputCols.filter((v, i) => selectedOutput.indexOf(i) === -1)
            patchOutputCols(output.map(i => i.name))
            setOutputCols(output);
        }
        setSelectedOutput([]);
    }

    //#endregion
    const header = () => {
        return (<Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 12,
                marginTop: 0,
                paddingTop: 0,
                paddingBottom: 0
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography
                    variant="h6"
                    gutterBottom
                    style={{ textAlign: "left", marginTop: 5 }}
                >
                    Input/Output Selection
                </Typography>
                <Tooltip title={<Typography variant="h6">Previous</Typography>} placement="top">
                    <IconButton
                      onClick={() => prevRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                      color="primary"
                      style={{ textAlign: "right", marginTop: 0, marginLeft: 60}}
                    >
                        <PrevIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<Typography variant="h6">Next</Typography>} placement="top">
                    <IconButton
                      onClick={() => nextRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                      color="primary"
                      style={{ textAlign: "right", marginTop: 0, marginLeft: 12}}
                    >
                        <NextIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<Typography variant="h6">Top</Typography>} placement="top">
                    <IconButton
                      onClick={() => topAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                      color="primary"
                      style={{ textAlign: "right", marginTop: 0, marginLeft: 12}}
                    >
                        <TopIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<Typography variant="h6">Train A Model</Typography>} placement="top">
                    <IconButton
                      onClick={() => {
                          topAnchorRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
                          setActiveStep(3)
                      }}
                      color="primary"
                      style={{ textAlign: "right", marginTop: 0, marginLeft: 12}}
                    >
                        <BubbleChartIcon />
                    </IconButton>
                </Tooltip>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: ioSelectExpanded,
                    })}
                    onClick={() => { setIOSelectExpanded(!ioSelectExpanded) }}
                    aria-expanded={ioSelectExpanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </div>
            <Typography
                variant="subtitle1"
                style={{ textAlign: "left", marginBottom: 8 }}
            >
                Select input and output columns below.
            </Typography>
            <Grid item xs={12} sm={12} md={6} lg={5}>
                <SetActiveSheet id={"data-sheet-select"} />
            </Grid>
        </Grid>)
    }

    return (
        <>
            <Collapse in={ioSelectExpanded} timeout="auto" collapsedSize={40}>
                <div>
                    <Grid
                        container
                        style={{ marginTop: 0, paddingTop: 0 }}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={10}
                        fullwidth="true"
                    >
                        {header()}
                        <Grid item xs={5} style={{ height: 500 }}>
                            <DataSelectTable
                                items={getUnassigned()}
                                columnHeaders={UnassignedColumnDefinitions}
                                onSelectionChange={onUnassignedSelection}
                                minRows={20}
                                height={500}
                                hotProps={{ colWidths: [80, 80] }}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        className={classes.button}
                                        disabled={loading}
                                        onClick={moveInputToUnassigned}
                                        style={{ marginLeft: 15, marginRight: 15 }}
                                    >
                                        &lt;&lt;&lt;
                                    </Button>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        className={classes.button}
                                        disabled={loading}
                                        onClick={moveUnassignedToInput}
                                        style={{ marginLeft: 15, marginRight: 15 }}
                                    >
                                        &gt;&gt;&gt;
                                    </Button>
                                </Grid>
                                <Grid item xs={8} style={{ height: 250 }}>
                                    <DataSelectTable
                                        items={inputCols}
                                        columnHeaders={InputColumnDefinitions}
                                        onSelectionChange={onInputSelection}
                                        minRows={10}
                                        height={240}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        className={classes.button}
                                        disabled={loading}
                                        onClick={moveOutputToUnassigned}
                                        style={{ marginLeft: 15, marginRight: 15 }}
                                    >
                                        &lt;&lt;&lt;
                                    </Button>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        className={classes.button}
                                        disabled={loading}
                                        onClick={moveUnassignedToOutput}
                                        style={{ marginLeft: 15, marginRight: 15 }}
                                    >
                                        &gt;&gt;&gt;
                                    </Button>
                                </Grid>
                                <Grid item xs={8} style={{ height: 250 }}>
                                    <DataSelectTable
                                        items={outputCols}
                                        columnHeaders={OutputColumnDefinitions}
                                        onSelectionChange={onOutputSelection}
                                        minRows={10}
                                        height={240}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Collapse>
        </>
    )
}