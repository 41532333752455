import React, { useCallback, useEffect, useState } from 'react'
import { useContext } from 'react'
import ConfirmationDialog, { ConfirmationDialogProps } from './ConfirmationDialog'


type enqueueDialogsProps = {
    propArray: (ConfirmationDialogProps & { passOnCancel?: boolean })[],
    all?: boolean,
    onYes?: (val?: any) => Promise<any>,
    onCancel?: (val?: any) => Promise<void>,
    onCheck?: (res: boolean,
    vals: any[]) => boolean
}
const globalConfirmationDialogContext = React.createContext<(props: enqueueDialogsProps) => (boolean | Promise<boolean>)>(() => false)

// @ts-ignore
export const GlobalConfirmationDialogProvider: React.FC = ({ children }) => {
    const [queue, setQueue] = useState<any>()
    const [props, setProps] = useState({ open: false})
    
    useEffect(() => {
        if (!props.open) {
            if (queue?.propArray?.length > 0) {
                const {passOnCancel, ...prop} = queue.propArray[0]
                setProps({
                    ...prop,
                    open: true,
                    onClose(val: any){
                        if (val === false && !queue.all && !passOnCancel) {
                            setQueue(undefined)
                            setProps({open: false})
                            prop.onClose?.(val)
                            queue.onDone?.(val, [...queue.values, val])
                        }
                        else {
                            setProps({open: false})
                            setQueue({...queue, propArray: queue.propArray.slice(1), values: [...queue.values, val]})
                            prop.onClose?.(val)
                        }
                    }
                })
            }
            else if (queue?.propArray?.length === 0) {
                setQueue(undefined)
                setProps({open: false})
                queue.onDone?.(true, queue.values)
            }
        }
    }, [props.open, queue])

    const enqueueDialogs = useCallback(async ({propArray, all, onYes, onCancel, onCheck}: enqueueDialogsProps) => {
        let onDone
        const promise = new Promise<any>((resolve) => {
            onDone = async (res: any, vals: any[]) => {
                if (onCheck ? onCheck(res, vals) : res) {
                    if (onYes) resolve(onYes(vals))
                    else resolve(true)
                }
                else {
                    if (onCancel) await onCancel(vals)
                    resolve(false)
                }
            }
        })
        setQueue({propArray, all, onDone, values: []})
        return promise
    }, [])

    return (
        <globalConfirmationDialogContext.Provider value={enqueueDialogs}>
            {<ConfirmationDialog 
                {...props}
            />}
            {children}
        </globalConfirmationDialogContext.Provider>
    )
}

export function useEnqueueDialogs() {
    return useContext(globalConfirmationDialogContext)
}