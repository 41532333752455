import { Base } from '../Base'
import { LabToTest, Instrument, Sample, File } from '../models'
import { Schema, schema } from '../schemaUtils'

export class Test extends Base {
    title?: string
    metadata?: { [id: string]: any }
    labs?: LabToTest[]
    instrument?: Instrument
    samples?: Sample[]
    valid?: boolean
    files?: File[]
    meta?: any
    processor?: string

    static readonly [schema]: Schema<Test> = {
        endPoint: "tests",
        type: "test",
        relationships: {
            ...Base[schema].relationships,
            samples: { map: "samples", model: "Sample", toMany: true, reverse: "tests" },
            labs: { map: "labs", model: "LabToTest", toMany: true, reverse: "test", meta: { of: "labs", through: "lab" } },
            files: { map: "files", model: "File", toMany: true, reverse: "tests" },
            instrument: { map: "instrument", model: "Instrument", toMany: false, reverse: "tests" }
        },
        map: {
            ...Base[schema].map,
            "title": "title",
            "description": "description",
            "metadata": "meta.metadata",
            "valid": "is_valid",
            "meta": "meta",
            "processor": "meta.processor"            
        }
    }

    constructor(toCopy?: Partial<Test>) {
        super()
        Object.assign(this, toCopy)
    }
}