/**
 * @format
 */
import { compile, reviver } from "mathjs"

export function compileExpression(expression, force) {
    if (expression.compiled && !force) return expression
    return {
        expression: expression.expression,
        variables: (expression.variables || []).map(v =>
            compileVariable(v, force),
        ),
        compiled: compile(expression.expression + "\n"),
    }
}

export function getExpressionResults({ sample, expression }) {
    if (!sample || !expression?.expression) return

    const scope = (expression.variables || []).reduce(
        (scope, variable) => {
            try {
                let results = getVariableResults({
                    sample,
                    serializedVariable: variable,
                })
                try {
                    results = JSON.parse(results, reviver)
                } catch (error) {}
                scope[variable.name] = results
                return scope
            } catch (error) {
                throw new Error(`${variable.name}: ${error.message}`)
            }
        },
        { sample },
    )

    const compiled =
        expression.compiled || compile(expression.expression + "\n")

    return evalCompiled(compiled, scope)
}

export function evalCompiled(compiled, scope = {}) {
    const results = compiled.evaluate(JSON.parse(JSON.stringify(scope)))
    return results.entries?.length
        ? results.entries[results.entries.length - 1]
        : ""
}

function getTestResults({ sample, compiled, filters, errors }) {
    if (!compiled) return []
    const { instruments, tags } = filters
    const tests =
        sample?.tests?.filter(
            test =>
                (!instruments ||
                    instruments.find(inst => inst.id === test.instrument.id)) &&
                (!tags || tags.find(tag => test.tags?.includes(tag))),
        ) || []
    if (!tests.length) return []

    const results = []

    for (const test of tests) {
        try {
            const scope = { test }
            results.push(evalCompiled(compiled, scope))
        } catch (error) {
            if (errors === "ignore") {
            } else if (errors === "null") {
                results.push(null)
            } else {
                throw error
            }
        }
    }

    return results
}

function getPropertyResults({ sample, compiled, filters, errors }) {
    if (!compiled) return []
    const { properties: propFilter, tags } = filters
    const properties =
        sample?.properties?.filter(
            prop =>
                (!propFilter ||
                    propFilter.find(inst => inst.id === prop.definition.id)) &&
                (!tags || tags.find(tag => prop.tags?.includes(tag))),
        ) || []
    if (!properties.length) return []

    const results = []
    for (const property of properties) {
        try {
            const scope = { property }
            results.push(evalCompiled(compiled, scope))
        } catch (error) {
            if (errors === "ignore") {
            } else if (errors === "null") {
                results.push(null)
            } else {
                throw error
            }
        }
    }

    return results
}

function getComponentResults({ sample, compiled, filters, errors }) {
    if (!compiled) return []
    const { materials, tags, componentProperties } = filters
    const components =
        sample?.components?.filter(
            component =>
                (!materials ||
                    materials.find(
                        inst => inst.id === component.definition.id,
                    )) &&
                (!tags || tags.find(tag => component.tags?.includes(tag))),
        ) || []
    if (!components.length) return []

    const results = []

    for (const component of components) {
        try {
            if (
                componentProperties &&
                !componentProperties.every(prop =>
                    component.definition?.properties?.find(
                        p => p.definition?.id === prop.id,
                    ),
                )
            )
                throw new Error("Component missing required property")
            const scope = { component }
            results.push(evalCompiled(compiled, scope))
        } catch (error) {
            if (errors === "ignore") {
            } else if (errors === "null") {
                results.push(null)
            } else {
                throw error
            }
        }
    }

    return results
}

export function getVariableResults({ sample, serializedVariable }) {
    if (!serializedVariable) return []
    const { expression, filters, errors } = serializedVariable
    const compiled = serializeVariable.compiled || compile(expression + "\n")
    switch (serializedVariable.key) {
        case "Properties":
            return getPropertyResults({
                sample,
                expression,
                filters,
                compiled,
                errors,
            })
        case "Components":
            return getComponentResults({
                sample,
                expression,
                filters,
                compiled,
                errors,
            })
        case "Tests":
            return getTestResults({
                sample,
                expression,
                filters,
                compiled,
                errors,
            })
        default:
            return undefined
    }
}

export function compileVariable(serializedVariable, force) {
    if (serializedVariable.compiled && !force) return serializedVariable
    const expression = serializedVariable.expression
    return { ...serializedVariable, compiled: compile(expression + "\n") }
}

export function serializeVariable({ name, expression, filters, root, errors }) {
    const serializedFilters = {}
    for (const [key, value] of Object.entries(filters)) {
        if (Array.isArray(value)) {
            serializedFilters[key] = value.map(v =>
                typeof v === "string" ? v : { id: v.id, title: v.title },
            )
        }
    }
    return {
        name,
        key: root.key,
        expression,
        filters: serializedFilters,
        errors,
    }
}
