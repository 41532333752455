/**
 * @format
 */
import React, { useState, useCallback } from "react"
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    makeStyles,
} from "@material-ui/core"
import TagSelector from "../General/TagSelector"
import TreeItem from "@material-ui/lab/TreeItem"

const useStyles = makeStyles(theme => ({
    label: {
        color: theme.palette.text.secondary,
    },
}))

export default function AddInputTag({ onAdd, label, nodeId }) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [tags, setTags] = useState()
    const handleClose = useCallback(() => {
        setOpen(false)
        setTags(undefined)
    }, [])
    const handleAdd = useCallback(() => {
        onAdd && onAdd(tags.map(tag => ({ tag })))
        handleClose()
    }, [tags, handleClose, onAdd])
    return (
        <TreeItem
            nodeId={nodeId}
            label={
                <div>
                    <div
                        onClick={() => setOpen(true)}
                        className={classes.label}
                    >
                        {label}
                    </div>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Select Tag(s)</DialogTitle>
                        <DialogContent style={{ minWidth: 400 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TagSelector
                                        value={tags}
                                        onChange={(ev, values) =>
                                            setTags(values)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAdd} color="primary">
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            }
        />
    )
}
