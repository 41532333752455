import React from 'react';
import PredictionChart from "../Predict/PredictionChart";
import { HotTable } from "@handsontable/react";
import { makeStyles } from "@material-ui/core/styles";
import common_styles from "../../../styles/common_styles";

const useStyles = makeStyles((theme) => ({
  ...common_styles(theme),
  htRoot: { // for handsontable (hot) overrides
    '& td.allCells': {
      backgroundColor: "#ffffff",
      color: "black"
    },
  },
}));

function ParaCoord({ inputData, outputData, HoTData, HoTHeaders, modelID }) {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = React.useState('');

  const onSelectionEnd = (r0, c0, r1) => {
    // we're just going to use the last selected row
    setSelectedRow(r1)
  }

  return (
    <>
      <div style={{ flexDirection: "row", alignItems: "center", display: "flex", width: "95%" }}>
        <PredictionChart
          inputData={inputData}
          outputData={outputData}
          selectedRow={selectedRow}
          modelID={modelID}
        />
      </div>
      <div style={{ width: "90%", marginLeft: 24 }} className={classes.htRoot}>
        <HotTable
          data={HoTData}
          colHeaders={HoTHeaders}
          rowHeaders={true}
          columnSorting={false}
          height="300"
          stretchH="all"
          minRows="1"
          settings={{ outsideClickDeselects: false, readOnly: true }}
          style={{ fontSize: "smaller", fontFamily: "Roboto" }}
          id="predict_table1"
          afterSelectionEnd={onSelectionEnd}
          editable={false}
          cells={() => {return {
            className: "htCenter allCells"
          }}}
        />
      </div>
    </>

  );
}

export default React.memo(ParaCoord)