/**
 * @format
 */
import React, { useState } from "react"
import ButtonWithSpinner from "components/General/ButtonWithSpinner"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { SampleSet } from "schema/models"
import { ColumnInfo } from "components/HoT/HoTUtils"

import { ExportPopover } from "./ExportingButtons"
import { AdvancedPopover } from "./AdvancedButtons"
import { ModelHubPopover } from "./ModelHubButtons"

export enum ExpandingMenuButtonTypes {
    Export,
    Advanced,
    Calculation,
}

export type ExpandingMenuButtonProps = {
    sampleSet: SampleSet
    updateSampleSet: any
    enqueueDialogs: any
    columnsInfo: ColumnInfo[]
    menuType: ExpandingMenuButtonTypes
    toggleQIDFunction: any
}

export const ExpandingMenuButton: React.FC<ExpandingMenuButtonProps> =
    React.forwardRef(function (
        {
            sampleSet,
            columnsInfo,
            updateSampleSet,
            enqueueDialogs,
            menuType,
            toggleQIDFunction,
            ...props
        },
        ref,
    ) {
        const [open, setOpen] = useState<any>(null)
        const [loading, setLoading] = useState(false)

        // This isn't super clean, but we're passing tons of data, and a subfunction is kind
        // of messy this way.
        let returnContent = null
        let title = null
        switch (menuType) {
            case ExpandingMenuButtonTypes.Export:
                returnContent = (
                    <ExportPopover
                        updateSampleSet={updateSampleSet}
                        sampleSet={sampleSet}
                        columnsInfo={columnsInfo}
                        enqueueDialogs={enqueueDialogs}
                        open={open}
                        onOpen={setOpen}
                        loading={loading}
                        onLoading={setLoading}
                    />
                )
                title = "Export"
                break
            case ExpandingMenuButtonTypes.Advanced:
                returnContent = (
                    <AdvancedPopover
                        updateSampleSet={updateSampleSet}
                        sampleSet={sampleSet}
                        columnsInfo={columnsInfo}
                        enqueueDialogs={enqueueDialogs}
                        open={open}
                        onOpen={setOpen}
                        loading={loading}
                        onLoading={setLoading}
                        toggleQIDFunction={toggleQIDFunction}
                    />
                )
                title = "Advanced"
                break
            case ExpandingMenuButtonTypes.Calculation:
                returnContent = (
                    <ModelHubPopover
                        updateSampleSet={updateSampleSet}
                        sampleSet={sampleSet}
                        columnsInfo={columnsInfo}
                        enqueueDialogs={enqueueDialogs}
                        open={open}
                        onOpen={setOpen}
                        loading={loading}
                        onLoading={setLoading}
                    />
                )
                title = "Model Hub"
                break
        }

        return (
            <div>
                <ButtonWithSpinner
                    ref={ref}
                    color="primary"
                    spinnerColor="primary"
                    onClick={(event: MouseEvent) =>
                        setOpen(event?.currentTarget)
                    }
                    loading={loading}
                    {...(props as any)}
                >
                    {title}
                    <ExpandMoreIcon />
                </ButtonWithSpinner>
                {returnContent}
            </div>
        )
    })
export default ExpandingMenuButton
