import React, { useRef } from 'react';
import { useBufferState } from '../../utils/utils';
import { TextField, TextFieldProps } from '@material-ui/core';

type Props = {
    defaultValue?: string,
    onChange?: (...args: any[]) => void,
    multiline?: boolean
} & TextFieldProps
/**
 * Wrapper component around material-ui TextField
 * This takes all the same props as TextField, see their docs
 * The main difference is changes in defaultValue trigger updates to the input value despite otherwise acting like a uncontrolled component
 * Why? Performance. The input state is kept local like an uncontrolled component but still allows detection of external changes.
 */
export const BufferTextField = React.forwardRef<HTMLDivElement, Props>(({
    defaultValue,
    onChange,
    multiline,
    ...props
}, ref) => {
    const inputRef = useRef<HTMLDivElement>();
    const [value, setValue] = useBufferState(defaultValue);
    return (
        <TextField 
            ref={ref} 
            inputRef={inputRef}
            value={value} 
            onChange={ev => {setValue(ev.target.value); onChange && onChange(ev)}}
            onKeyDown={multiline ? undefined : ev => {if (inputRef.current && ev.key === "Enter") {inputRef.current.blur()}}}
            multiline={multiline}
            {...props}
        />
    )
})

export default BufferTextField