import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useAppStoreKey } from 'AppStore';
import ButtonWithSpinner from "../../General/ButtonWithSpinner"
import ColumnNameTable from "../Edit/ColumnNameTable";

function RenameColumnNamesDialog({ open, handleClose, handleSaveData }) {
    const [workflow,] = useAppStoreKey("Workflow");
    // remove the common string for all column names
    const trimCols = (idx, allCols) => {
        let allSubstrings = []
        for (let i = 0; i < allCols.length; i++) {
            if (i !== idx) {
                const subString = allCols[i].split(' | ')
                subString.forEach(sub => {
                    allSubstrings.push(sub)
                })
            }
        }
        let currentCol = allCols[idx];
        let currentColSubstring = currentCol.split(' | ')
        // find unique substring
        let uniqueSubstring = currentColSubstring.filter((substring) => {
            return allSubstrings.indexOf(substring) === -1;
        });
        if (uniqueSubstring.length === 0) {
            uniqueSubstring = currentColSubstring;
        }
        return uniqueSubstring.join(' | ');

    }

    function createData(name, newName) {
        return { name, newName, editable: false, selected: false };
    }

    const getAllCols = () => {
        const allCols = workflow.data.info[workflow.data.active_sheet].all_headers
        return allCols.map((col, idx) => {
            return createData(col, trimCols(idx, allCols));
        })
    }

    const [rows, setRows] = React.useState(getAllCols());


    let saveColName = () => {
        let modified = false
        const colNames = rows.map(row => {
            if (row.selected) {
                modified = true
                return row.newName
            }
            return row.name
        })
        if (modified) {
            handleSaveData('', colNames)
        }
        handleClose();

    };


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Rename Column Names"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Click pencil icon to change the name. Check the checkbox and click save to apply the changes.
                </DialogContentText>
                <ColumnNameTable rows={rows} setRows={setRows} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <ButtonWithSpinner
                    onClick={saveColName}
                    color="primary"
                    autoFocus
                    loading={false}>
                    Save
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    )

}


export default React.memo(RenameColumnNamesDialog);