/**
 * @format
 */
import React, { useState, useMemo } from "react"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { makeStyles, Typography } from "@material-ui/core"
import TreeItem from "@material-ui/lab/TreeItem"
import { robustType } from "../../utils/utils"
import TestSelector from "../General/TestSelector"
import AppendedSelector from "./AppendedSelector"
import TreeItemLabel from "./TreeItemLabel"

const useStyles = makeStyles(theme => ({
    testLabelContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    label: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: "14ch",
    },
    filters: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        justifySelf: "flex-end",
    },
    filter: {
        minWidth: "28ch",
        padding: theme.spacing(0, 1),
        border: "#00000015 solid 1px",
        borderTop: "none",
        borderBottom: "none",
    },
}))

export function TestTree({ onSelect, filters, sample, onClickKey }) {
    const classes = useStyles()

    const [expanded, setExpanded] = useState(["test"])
    const { instruments, tags } = filters

    return (
        <TreeView
            expanded={expanded}
            onNodeToggle={(ev, nodeIds) => setExpanded(nodeIds)}
            onNodeSelect={(ev, value) => onSelect && onSelect(value)}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            <TreeItem nodeId="test" label="test">
                <TestMetaTreeItem
                    classes={classes}
                    rootId="test"
                    sample={sample}
                    instruments={instruments}
                    tags={tags}
                    setExpanded={setExpanded}
                    onClickKey={onClickKey}
                />
            </TreeItem>
        </TreeView>
    )
}

function TestMetaTreeItem({
    classes,
    rootId,
    sample,
    instruments,
    tags,
    setExpanded,
    onClickKey,
}) {
    const [test, setTest] = useState()

    const filter = useMemo(() => {
        if (!instruments?.length && !tags?.length) return undefined
        const filter = []
        if (instruments?.length) {
            filter.push({
                name: "instrument.id",
                op: "in_",
                val: instruments.map(i => i.id),
            })
        }
        if (tags?.length) {
            filter.push({ name: "tags", val: tags, op: "list" })
        }
        return { and: filter }
    }, [instruments, tags])

    const nodeId = `${rootId}["metadata"]`

    const testSelector = (
        <div className={classes.testLabelContainer}>
            <Typography className={classes.label}>metadata</Typography>
            <div
                className={classes.filter}
                onClick={ev => ev.stopPropagation()}
            >
                <AppendedSelector
                    Selector={TestSelector}
                    selected={test && [test]}
                    selectSingle
                    placeholder={test ? "" : "Select Example Test"}
                    onChange={(ev, values) => {
                        setTest(values ? values[0] : undefined)
                        setExpanded &&
                            setExpanded(current =>
                                current?.includes(nodeId)
                                    ? current
                                    : current.concat(nodeId),
                            )
                    }}
                    appendOptions={sample?.tests}
                    filter={filter}
                />
            </div>
        </div>
    )

    return !!test ? (
        <RecursiveTreeItem
            keyvalue={test.metadata}
            keyname={"metadata"}
            parentPath={[rootId]}
            label={testSelector}
            onClickKey={onClickKey}
        />
    ) : (
        <TreeItem nodeId={nodeId} label={testSelector} />
    )
}

function RecursiveTreeItem({
    keyname,
    keyvalue,
    parentPath,
    label,
    onClickKey,
}) {
    const valueType = robustType(keyvalue)
    const path = useMemo(() => parentPath.concat(keyname), [
        keyname,
        parentPath,
    ])

    const childNodes = useMemo(() => {
        if (valueType === "object") {
            return Object.keys(keyvalue).map((key, index) => (
                <RecursiveTreeItem
                    key={index}
                    keyname={key}
                    keyvalue={keyvalue[key]}
                    parentPath={path}
                    onClickKey={onClickKey}
                />
            ))
        }
        if (valueType === "array") {
            return keyvalue.map((elem, index) => (
                <RecursiveTreeItem
                    key={index}
                    keyname={index}
                    keyvalue={elem}
                    parentPath={path}
                    onClickKey={onClickKey}
                />
            ))
        }
        return null
    }, [keyvalue, onClickKey, path, valueType])

    const nodeId = path.reduce((prev, current, index) => {
        if (index === 0) return current // root of tree
        return `${prev}["${current}"]`
    }, "")

    return (
        <TreeItem
            nodeId={nodeId}
            key={nodeId}
            label={
                <TreeItemLabel
                    label={label || keyname}
                    labelRight={label ? "" : "Insert"}
                    rightProps={
                        !label && {
                            onClick() {
                                onClickKey(nodeId)
                            },
                        }
                    }
                />
            }
        >
            {childNodes}
        </TreeItem>
    )
}
