import React, { useCallback, useMemo } from 'react';
import { Grid, Typography, Dialog, IconButton, DialogActions, Button, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTable, useFlexLayout } from 'react-table';
import StyledReactTable, { defaultColumn }  from './StyledReactTable';
import { useDragColumn }  from './useDragColumn';
import { makeStyles } from '@material-ui/core/styles';
import common_styles from '../../styles/common_styles';
import { stripColumns, makeColumns, } from './reactTableUtils';
import { useBufferState } from '../../utils/utils';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ColumnsTree } from './ColumnsTree';

const useStyles = makeStyles(theme => ({
    ...common_styles(theme),
    loadingText: {
        color: "white",
        marginBottom: theme.spacing(1),
    },
    loadingPaper: {
        background: "transparent",
    },
    loadingContainer: {
        textAlign: "center",
    },
    fieldset: {
        border: "none"
    }
}));

export default React.memo(function CustomizeColumnsDialog({columnDefinitions, serializedColumns: initialColumns, open, onClose, defaultColumns}){
    const classes = useStyles();
    const [columns, setColumns] = useBufferState(initialColumns || defaultColumns);
    const tableColumns = useMemo(() => stripColumns(makeColumns(columns, columnDefinitions) || makeColumns(defaultColumns, columnDefinitions)), [columns, columnDefinitions, defaultColumns]);
    const handleClose = useCallback(() => onClose && onClose(columns), [columns, onClose]);
    const handleCancel = useCallback(() => {setColumns(initialColumns || defaultColumns); onClose && onClose(initialColumns || defaultColumns)}, [initialColumns, onClose, defaultColumns, setColumns]);
    const handleColumnCheck = useCallback((checkedColumns) => {
        const newCols = columns.slice();
        const isArray = Array.isArray(checkedColumns);
        if (!isArray) checkedColumns = [checkedColumns];
        checkedColumns.forEach(column => {
            const index = newCols.findIndex(col => col.colId === column.colId);
            if (index >= 0) {
                !isArray && newCols.splice(index, 1);
            }
            else{
                newCols.push(column);
            }
        })
        setColumns(newCols);
    }, [columns, setColumns]);
    const handleColumnReorder = useCallback(cols => setColumns(cols.map(col => columns.find(c => c.colId === col.id) )),[columns, setColumns]);
    const table = useTable({
        columns: tableColumns,
        defaultColumn,
        data: [],
        totalRows: 0,
        onColumnReorder: handleColumnReorder,
        rowsDisplayed: 0,
    },
        useFlexLayout,
        useDragColumn,
    );
    const handleReset = useCallback(() => {
        setColumns(defaultColumns);
    }, [defaultColumns, setColumns]);

    return (
        <Dialog onClose={handleCancel} open={open} fullWidth maxWidth="lg" scroll={"body"}>
            <div className={classes.dialogPaperBody}>
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12}>
                        <Typography className={classes.cardTitle} gutterBottom>
                            Select Table Columns
                        </Typography>
                        <Typography className={classes.cardBodyText}>
                            Use the checkboxes below to add or remove columns. Click and drag column headers to reorder the columns.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DndProvider backend={HTML5Backend}>
                            <StyledReactTable {...table}/>
                        </DndProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <ColumnsTree columnDefinitions={columnDefinitions} columns={columns} tableColumns={tableColumns} handleColumnCheck={handleColumnCheck} />
                    </Grid>
                </Grid>
                <IconButton onClick={handleClose} className={classes.dialogCloseButton}>
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogActions>
                <Button onClick={handleReset}>
                    Reset Defaults
                </Button>
                <Button color="primary" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    ) 
});

