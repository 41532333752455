import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    defaultCell: {
        display: "block",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // '&:hover': {
        //     overflow: "visible",
        // },
        // '&:hover span':{
        //     position: "relative",
        //     backgroundColor: "white",
        //     display: "inline-block",
        //     whiteSpace: "normal",
        //     transform: "translate(-1px,-1px)",
        //     border: "1px solid gray",
        //     zIndex: 2,
        // }
    }
}))

export default function DefaultCell({cell}){
    const classes = useStyles();
    const stringify = cell.value && (typeof cell.value === "string" ? cell.value : (cell.value.length === 0 ? undefined : JSON.stringify(cell.value)));
    const value = stringify || '\xa0';
    return <div className={classes.defaultCell} title={value}><span>{value}</span></div>
}