import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Jsme } from "jsme-react";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-drawtool-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DrawingWidget({ initialValue, setValue, open, setOpen }) {
  const [smiles, setSmiles] = useState('')
  useEffect(() => {
    if (initialValue === undefined || initialValue === null) {
      setSmiles('')
    } else {
      setSmiles(initialValue)
    }
  }, [initialValue, open])

  const handleCancel = () => {
    setOpen(false)
  }
  const handleClose = () => {
    setValue(smiles)
    setOpen(false)
  }
  const onChange = (evt) => {
    setSmiles(evt)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-drawtool-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-drawtool-title">
        Molecule Drawer
      </DialogTitle>
      <DialogContent>
        <Jsme
          height="500px"
          width="500px"
          options="nofullScreenIcon,noautoez,nostereo"
          smiles={smiles}
          onChange={onChange}

        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}