import React from 'react';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { colorMap } from "../const";


const LegendContent = [
  {text: 'Input columns', color: colorMap.inputColumns},
  {text: 'Output columns', color: colorMap.outputColumns},
  {text: 'Suggestion', color: colorMap.infoCells},
  {text: 'Warning', color: colorMap.warningCells},
  {text: 'Error', color: colorMap.errorCells},
]

export default function Legend() {
  return (
    <List dense={true}>
      {LegendContent.map((item, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <div style={{backgroundColor: item.color, width: '20px', height: '20px'}} />
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  )
}