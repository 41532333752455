import React, { useState} from "react";
import TextField from "@material-ui/core/TextField";
import { SelectColumns } from "./utils";
import PreprocessFuncsBase from "./PreprocessFuncsBase";
import { useAppStoreKey } from "../../../../AppStore";


export default function MinMaxScaler({pfKey, handleRemovePlot}) {
  const [workflow] = useAppStoreKey("Workflow");
  const [allCols, setAllCols] = useState([])
  const [selectedCols, setSelectedCols] = useState([])
  const [minTo, setMinTo] = useState(-1)
  const [maxTo, setMaxTo] = useState(1)
  const funcName = 'Min-Max Scaler'
  const description = 'For each row, linearly scale the data to selected min and max value.'

  const handleMinMaxToChange = (type) => (event) => {
    if (type === 'min') {
      setMinTo(Number(event.target.value))
    } else {
      setMaxTo(Number(event.target.value))
    }
  }

  const updateDict = () => {
    const selected_idx = []
    allCols.forEach((value, idx) => { if (selectedCols[idx]) selected_idx.push(idx) })
    return {
      "MinMaxScaler": {
        "kwargs": {
          "cols": selected_idx.map(idx => workflow.data.info[workflow.data.active_sheet].all_headers[idx]),
          "to_range": [minTo, maxTo]
        }
      }
    }
  }

  return (
    <>
      <PreprocessFuncsBase
        funcName={funcName}
        description={description}
        pfKey={pfKey}
        handleRemovePlot={handleRemovePlot}
        updateDict={updateDict}
      >
        <SelectColumns
          allCols={allCols}
          setAllCols={setAllCols}
          selectedCols={selectedCols}
          setSelectedCols={setSelectedCols}
        />
        <div style={{display:'flex', flexDirection: 'row'} }>
          <div style={{marginLeft: 24}}>
            <TextField
              id="minTo"
              label="Min To"
              type="number"
              value={minTo}
              onChange={handleMinMaxToChange('min')}
              margin="normal"
              variant="outlined"
            />
          </div>
          <div style={{marginLeft: 24}}>
            <TextField
              id="maxTo"
              label="Max To"
              type="number"
              value={maxTo}
              onChange={handleMinMaxToChange('max')}
              margin="normal"
              variant="outlined"
            />
          </div>
        </div>
      </PreprocessFuncsBase>
    </>
  );
}