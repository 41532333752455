/**
 * @format
 */
import React, { useState, useCallback, useMemo } from "react"
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core"
import InstrumentSelector from "../General/InstrumentSelector"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { robustType } from "utils/utils"
import AppendedSelector from "components/ExpressionEditor/AppendedSelector"
import TestSelector from "components/General/TestSelector"
import TreeView from "@material-ui/lab/TreeView"
import get from "lodash.get"

const useStyles = makeStyles(theme => ({
    rootLabel: {
        color: theme.palette.text.secondary,
    },
    treeContainer: {
        padding: theme.spacing(1),
        maxHeight: "400px",
        overflow: "auto",
        minHeight: 70,
        border: "1px solid",
        borderRadius: theme.spacing(0.5),
        borderColor:
            theme.palette.type === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
        "&:hover": {
            borderColor: theme.palette.text.primary,
        },
        "&:focus-within": {
            borderColor: theme.palette.primary.main,
        },
    },
    testLabelContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    label: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: "14ch",
    },
    filters: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        justifySelf: "flex-end",
    },
    filter: {
        minWidth: "28ch",
        padding: theme.spacing(0, 1),
        border: "#00000015 solid 1px",
        borderTop: "none",
        borderBottom: "none",
    },
    itemLabel: {
        display: "flex",
        whiteSpace: "nowrap",
        justifyContent: "space-between",
    },
    labelKey: {
        color: theme.palette.text.disabled,
        whiteSpace: "nowrap",
        maxWidth: "20ch",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: theme.spacing(1),
    },
}))

export default function AddTestValue({ onAdd, label, nodeId }) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [test, setTest] = useState()
    const [testValues, setTestValues] = useState([])
    const [testTypes, setTestTypes] = useState([])
    const [instruments, setInstruments] = useState([])
    const [expanded, setExpanded] = useState([])
    const handleClose = useCallback(() => {
        setOpen(false)
        setTestValues(undefined)
    }, [])
    const handleTestValue = useCallback(
        (ev, value) => {
            setTestValues(value)
            value &&
                test &&
                setTestTypes(
                    value.map(v =>
                        isNaN(Number(get(test, v.substring(5))))
                            ? "text"
                            : "number",
                    ),
                )
        },
        [test],
    )
    const handleInstruments = useCallback((ev, value) => {
        setInstruments(value)
    }, [])
    const handleAdd = useCallback(() => {
        onAdd &&
            onAdd(
                testValues.map((t, index) => ({
                    metaField: t.substring(5), // remove leading 'tests'
                    type: testTypes[index],
                    instrumentTitles: instruments.map(i => i.title),
                    instrumentIds: instruments.map(i => i.id),
                })),
            )
        handleClose()
    }, [onAdd, testValues, handleClose, testTypes, instruments])
    return (
        <TreeItem
            nodeId={nodeId}
            label={
                <div>
                    <div
                        onClick={() => setOpen(true)}
                        className={classes.rootLabel}
                    >
                        {label}
                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogTitle>Select Test Values</DialogTitle>
                        <DialogContent style={{ minWidth: 400 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InstrumentSelector
                                        selected={instruments}
                                        fullWidth
                                        onChange={handleInstruments}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.treeContainer}>
                                        <TreeView
                                            multiSelect
                                            expanded={expanded}
                                            onNodeToggle={(ev, nodeIds) =>
                                                setExpanded(nodeIds)
                                            }
                                            onNodeSelect={handleTestValue}
                                            defaultCollapseIcon={
                                                <ExpandMoreIcon />
                                            }
                                            defaultExpandIcon={
                                                <ChevronRightIcon />
                                            }
                                        >
                                            <TestMetaTreeItem
                                                classes={classes}
                                                rootId="tests"
                                                instruments={instruments}
                                                setExpanded={setExpanded}
                                                test={test}
                                                setTest={setTest}
                                            />
                                        </TreeView>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAdd} color="primary">
                                Add
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            }
        />
    )
}

function TestMetaTreeItem({
    classes,
    rootId,
    instruments,
    setExpanded,
    test,
    setTest,
}) {
    const filter = useMemo(() => {
        if (!instruments?.length) return undefined
        return {
            name: "instrument.id",
            op: "in_",
            val: instruments.map(i => i.id),
        }
    }, [instruments])

    const nodeId = `${rootId}["metadata"]`

    const testSelector = (
        <div className={classes.testLabelContainer}>
            <Typography className={classes.label}>metadata</Typography>
            <div
                className={classes.filter}
                onClick={ev => ev.stopPropagation()}
            >
                <AppendedSelector
                    Selector={TestSelector}
                    selected={test && [test]}
                    selectSingle
                    placeholder={test ? "" : "Select Example Test"}
                    onChange={(ev, values) => {
                        setTest(values ? values[0] : undefined)
                        setExpanded &&
                            setExpanded(current =>
                                current?.includes(nodeId)
                                    ? current
                                    : current.concat(nodeId),
                            )
                    }}
                    filter={filter}
                />
            </div>
        </div>
    )

    return !!test ? (
        <RecursiveTreeItem
            classes={classes}
            keyvalue={test.metadata}
            keyname={"metadata"}
            parentPath={[rootId]}
            label={testSelector}
        />
    ) : (
        <TreeItem nodeId={nodeId} label={testSelector} />
    )
}

function RecursiveTreeItem({ keyname, keyvalue, parentPath, label, classes }) {
    const valueType = robustType(keyvalue)
    const path = useMemo(
        () => parentPath.concat(keyname),
        [keyname, parentPath],
    )

    const childNodes = useMemo(() => {
        if (valueType === "object") {
            return Object.keys(keyvalue).map((key, index) => (
                <RecursiveTreeItem
                    classes={classes}
                    key={index}
                    keyname={key.replace(/\./g, "\\.")}
                    keyvalue={keyvalue[key]}
                    parentPath={path}
                />
            ))
        }
        if (valueType === "array") {
            return keyvalue.map((elem, index) => (
                <RecursiveTreeItem
                    classes={classes}
                    key={index}
                    keyname={index}
                    keyvalue={elem}
                    parentPath={path}
                />
            ))
        }
        return null
    }, [classes, keyvalue, path, valueType])

    const nodeId = path.reduce((prev, current, index) => {
        if (index === 0) return current // root of tree
        return `${prev}["${current}"]`
    }, "")

    const showValue = valueType === "number" || valueType === "string"

    return (
        <TreeItem
            nodeId={nodeId}
            key={nodeId}
            label={
                <div className={classes.itemLabel}>
                    {label || keyname}
                    {showValue && (
                        <div className={classes.labelKey}>{keyvalue}</div>
                    )}
                </div>
            }
        >
            {childNodes}
        </TreeItem>
    )
}
