import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    fcLinkLabel: {
        position: "absolute",
        top: props => props.labelPos?.y,
        left: props => props.labelPos?.x,
        transform: "translate(-50%, -50%)",
        marginLeft: "auto",
        padding: theme.spacing(0.5, 2),
        borderRadius: theme.spacing(1),
        background: theme.palette.label.dark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        ...theme.typography.caption,
        color: "white",
        zIndex: "1100",
        '&.-faded': {
            opacity: 0.4,
        },
        '&.-selected': {
            border: `${2}px solid`,
            borderColor: theme.palette.selected.main,
            marginTop: `-${2}px`,
            marginLeft: `-${2}px`,
        }
    },
    fcLink: {
        overflow: 'visible',
        position: 'absolute',
        cursor: 'pointer',
        left: 0,
        right: 0,
        '&.-faded': {
            opacity: 0.4,
        },
    }
}));

function LinkSVG({ theme, noEnd, className, startPos, endPos, color, isSelected, isHovered, onLinkMouseEnter, onLinkMouseLeave, onLinkClick, dash }) {
    const start = startPos.x < endPos.x ? startPos : endPos;
    const end = startPos.x < endPos.x ? endPos : startPos;
    const curveX = Math.abs(startPos.x - endPos.x) / 3;
    const points = `M${start.x},${start.y} C ${start.x + curveX},${start.y} ${end.x - curveX},${end.y} ${end.x},${end.y}`
    return (
        <svg className={className}>
            <circle
                r="4"
                cx={startPos.x}
                cy={startPos.y}
                fill={color}
            />
            {/* Line to show selection */}
            {isSelected && <path
                d={points}
                stroke={theme?.palette.selected.main || "#F00"}
                strokeWidth="11"
                fill="none"
                strokeLinecap="round"
            />}
            {/* Main line */}
            <path
                d={points}
                stroke={color}
                strokeWidth={dash ? "5" : "8"}
                strokeDasharray={dash ? "6" : undefined}
                fill="none"
            />
            {/* Thick line to make selection easier */}
            <path
                d={points}
                stroke={color}
                strokeWidth="20"
                fill="none"
                strokeLinecap="round"
                strokeOpacity={(isHovered) ? 0.1 : 0}
                onMouseEnter={onLinkMouseEnter}
                onMouseLeave={onLinkMouseLeave}
                onClick={onLinkClick}
            />
            {!noEnd && <circle
                r="4"
                cx={endPos.x}
                cy={endPos.y}
                fill={color}
            />}
        </svg>
    );
}

export default function Link(props) {
    const { config,
        link,
        startPos,
        endPos,
        onLinkMouseEnter,
        onLinkMouseLeave,
        onLinkClick,
        isHovered,
        isSelected } = props;

    const theme = useTheme();
    const labelPos = { x: startPos.x + (endPos.x - startPos.x) / 2, y: startPos.y + (endPos.y - startPos.y) / 2 };
    const classes = useStyles({ labelPos });

    const fade = link.properties?.fade;
    const dash = link.properties?.dash;
    const labelled = link.properties?.label && link.properties?.label !== "";
    return (
        <div>
            <LinkSVG
                theme={theme}
                className={clsx(classes.fcLink, fade && "-faded")}
                startPos={startPos}
                endPos={labelled ? labelPos : endPos}
                noEnd={labelled}
                color={theme.palette.primary.main}
                isSelected={isSelected}
                isHovered={isHovered}
                onLinkMouseEnter={() => onLinkMouseEnter({ config, linkId: link.id })}
                onLinkMouseLeave={() => onLinkMouseLeave({ config, linkId: link.id })}
                onLinkClick={(e) => {
                    onLinkClick({ config, linkId: link.id })
                    e.stopPropagation()
                }}
                dash={dash} />
            {labelled &&
                <div
                    className={clsx(classes.fcLinkLabel, fade && "-faded", isSelected && "-selected")}
                    onMouseEnter={() => onLinkMouseEnter({ config, linkId: link.id })}
                    onMouseLeave={() => onLinkMouseLeave({ config, linkId: link.id })}
                    onClick={(e) => {
                        onLinkClick({ config, linkId: link.id })
                        e.stopPropagation()
                    }}
                >
                    {link.properties.label}
                </div>
            }
            {labelled &&
                <LinkSVG
                    className={clsx(classes.fcLink, fade && "-faded")}
                    startPos={labelPos}
                    endPos={endPos}
                    color={theme.palette.primary.main}
                    isSelected={isSelected}
                    isHovered={isHovered}
                    onLinkMouseEnter={() => onLinkMouseEnter({ config, linkId: link.id })}
                    onLinkMouseLeave={() => onLinkMouseLeave({ config, linkId: link.id })}
                    onLinkClick={(e) => {
                        onLinkClick({ config, linkId: link.id })
                        e.stopPropagation()
                    }} />
            }
        </div>
    );
}