import React from "react";
import PreprocessFuncsBase from "./PreprocessFuncsBase";


export default function DropEmptyCellsComponent({pfKey, handleRemovePlot}) {
    const funcName = "Drop Empty Cells";
    const description = "Remove any rows with one or more empty cells. Non-numeric values are acceptable.";

    const updateDict = () => {
        return { "DropEmptyCell": {} };
    }

    return (
        <>
            <PreprocessFuncsBase
              funcName={funcName}
              description={description}
              pfKey={pfKey}
              handleRemovePlot={handleRemovePlot}
              updateDict={updateDict}
            />
        </>
    );
}