import { ProcessDefinition, Sample } from '../models'
import { INode } from "react-flow-chart-mmm"
import { Schema, schema } from '../schemaUtils'
import { Base } from '../Base'

export class ProcessInstance extends Base {
    title?: string
    data?: {[id: string]: any}
    node?: INode
    definition?: ProcessDefinition
    sample?: Sample
    nodeId?: string
    roles?: string[]

    static readonly [schema]: Schema<ProcessInstance> = {
        endPoint: "process_instances",
        type: "process_instance",
        relationships: {
            ...Base[schema].relationships,
            sample: { map: "sample", model: "Sample", toMany: false, reverse: "processSteps", required: true },
            definition: { map: "process_definition", model: "ProcessDefinition", toMany: false, reverse: "instances", required: true }
        },
        map: {
            ...Base[schema].map,
            "sample.id": "sample_id",
            "definition.id": "process_definition_id",
            "title": "meta.title",
            "data": "meta.data",
            "node.id": "meta.node_id",
            "nodeId": "meta.node_id",
            "roles": "meta.roles",
        }
    }

    constructor(toCopy?: Partial<ProcessInstance>) {
        super()
        Object.assign(this, toCopy)
    }
}