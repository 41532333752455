import { green } from "@material-ui/core/colors";

const styles = theme => ({
  layout: {
    width: '100%',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    flexGrow: 1,
  },
  paperBody: {
    marginTop: theme.spacing(2),
    marginBottom: 'auto',
    overflowX: "auto",
    padding: "24px",
  },
  cardTitle: {
    textAlign: 'left',
    ...theme.typography.h6,
    color: theme.palette.text.primary
  },
  cardSubtitle: {
    textAlign: 'left',
    marginLeft: 0,
    marginRight: 24,
    marginBottom: 0,
  },
  cardBodyText: {
    textAlign: 'left',
    marginBottom: 0,
    ...theme.typography.body2,
    color: theme.palette.text.primary
  },
  cardBodyTextEnhanced: {
    textAlign: 'left',
    marginBottom: 20,
    ...theme.typography.body2,
    color: theme.palette.text.primary
  },
  dialogPaperBody: {
    padding: theme.spacing(4),
  },
  dialogCloseButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: "20px",
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  plscheck: {
    "&$checked": {
      color: "#0087ec",
    },
  },
  nncheck: {
    "&$checked": {
      color: "#4caf50",
    },
  },
  krrcheck: {
    "&$checked": {
      color: "#9c27b0",
    },
  },
  svrcheck: {
    "&$checked": {
      color: "#e91e63",
    },
  },
  hpocheck: {
    "&$checked": {
      color: "#00695c",
    },
  },
  checked: {},
  buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    margin: theme.spacing(1),
    color: green[500],
    position: "absolute",
    top: "100%",
    left: "100%",
    marginTop: 12,
    marginLeft: -90,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  htRoot: { // for handsontable (hot) overrides
    '& td.outputCell': {
      backgroundColor: "#dcedc870",
      color: "black"
    },
    '& td.inputCell': {
      backgroundColor: "#e1f5fe70",
      color: "black"
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginBottom: 30,
    minWidth: 200,
    flexWrap: "wrap",
    // underline: 'red',
  },
})
export default styles;