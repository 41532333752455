import React, { useEffect, useRef, useState } from "react"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useSnackbar } from "notistack"
import common_styles from "../../../../styles/common_styles"
import MLModelBase from "./MLModelBase";
import useTrainModel from "../useTrainModel";

const useStyles = makeStyles(common_styles);

function GPR({handleRemovePlot, modelKey}) {

  const classes = useStyles();
  const modelName = "Gaussian Process Regression";
  const modelDescription = "Gaussian process regression (GPR) is a nonparametric, Bayesian\n" +
    "approach to regression. GPR has several benefits, working well on small datasets and having the ability to\n" +
    "provide uncertainty measurements on the predictions."
  const [params, setParams] = useState({'kernel':'auto'})  // default values
  const [isLoading, ] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const { TrainModel } = useTrainModel({enqueueSnackbar})
  const currentComponent = useRef(true)
  // record if the component is mounted
  useEffect(() => () => currentComponent.current = false, [])
  const parameter_space = {
    "kernel": ['auto', "DotProduct", "WhiteKernel", "RBF", "DotProduct + WhiteKernel"],
  }

  const handleParamChange = (paramName) => (event) => {
    let newParam = {}
    newParam[paramName] = event.target.value
    setParams({...params, ...newParam})
  }

  let modelParams = {
    GPR_model: {
      kwargs: { "params": params }
    }
  }

  return (
    <>
      <MLModelBase
        modelName={modelName}
        modelDescription={modelDescription}
        handleTrainModel={TrainModel(modelParams, modelName)}
        handleRemovePlot={handleRemovePlot}
        isLoading={isLoading}
        modelKey={modelKey}
      >
        {Object.keys(parameter_space).map((paramName) => {
          return (
            <div key={'div_' + paramName} style={{ marginLeft: 36}}>
              <FormControl key={'FormControl_' + paramName} component="fieldset" className={classes.formControl}>
                <InputLabel key={'InputLabel_' + paramName} id={paramName + "select-label"}>{paramName}</InputLabel>
                <Select
                  key={'Select_' + paramName}
                  labelId={paramName + "select-label"}
                  id={paramName + "select-id"}
                  value={params[paramName]}
                  onChange={handleParamChange(paramName)}
                >
                  {parameter_space[paramName].map((paramOption) => {
                    return (
                      <MenuItem key={paramName + '_' + paramOption} value={paramOption}>{paramOption}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          );
        })}
      </MLModelBase>


    </>
  );
}

export default React.memo(GPR);