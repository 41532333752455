import { Card, CardHeader, makeStyles, Typography, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { UserGuide } from '../TrainingContent/TrainingContent';

const appColors = {
    'features': '#00C8E6',
    'resolved': '#00B432',//'#75C172',
    'bugs': '#DC14AA', //'#ff0213',//'#F9B0B5',
    'general': '#F5821E'
}

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2),
        marginBottom: 'auto',
        overflowX: "auto",
        padding: theme.spacing(3),
        width: "100%",
    },
    description: {
        margin: theme.spacing(2)
    },
    line: {
        margin: theme.spacing(1, 0),
    },
    item: {
        margin: theme.spacing(2, 0),
        background: theme.palette.background.default
    },
    materialsManager: {
        backgroundColor: alpha(appColors.resolved, 0.33)
    },
    experimentManager: {
        backgroundColor: alpha(appColors.bugs, 0.33)
    },
    mixingStudio: {
        backgroundColor: alpha(appColors.features, 0.33)
    },
}))

const GitHubLink = () => {
    return (
        <Link
            href="https://github.mmm.com/MMM/MaterialsManager/tree/master/release_notes"
            target="_blank"
            rel="noreferrer"
            style={{ color: alpha(appColors.features, 0.95), fontSize: 20 }}>
            GitHub Release Notes
        </Link>
    )
}

function ReleaseUpdates() {
    const classes = useStyles();

    return (
        <div>
            <Card className={classes.card} >
                <CardHeader title="Material Informatics Release Updates" />
                <div className={classes.description}>
                    <Typography className={classes.line}>
                        Starting in <b style={{ color: appColors.general }}>October 2021</b>, all new releases will be captured in the <UserGuide fontColor={appColors.bugs} /> under <b style={{ color: appColors.resolved }}>Skylab Updates</b> or
                        in the <GitHubLink />.
                    </Typography>
                </div>
            </Card>
        </div>
    )
}

export default ReleaseUpdates;
