import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from "notistack"
import common_styles from '../../../styles/common_styles';
import { aichemyProtoAxios } from "API/mmAxios";
import { useAppStoreKey, useAppStoreDispatchKey } from '../../../AppStore';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    ...common_styles(theme), root: {
        flexGrow: 1,
        minHeight: "95vh",
        margin: "auto",
        zIndex: 1,
        position: "relative",
        display: "flex",
        width: "100%"
    },
    appBar: {
        position: "fixed",
        marginLeft: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        color: "white"
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            position: "fixed"
        },
        minHeight: "100vh"
    },
    content: {
        minHeight: `calc(100vh - 48px)`,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: "100%",
        marginLeft: "auto",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    loader: {
        marginLeft: "0px",
        [theme.breakpoints.up("md")]: {
            marginLeft: `calc(${drawerWidth}px)`
        }
    }
}));

function DeleteModelDialog({ navigate, deleteContext, setDeleteContext, currentModels, setCurrentModels }) {

    const classes = useStyles();
    const [modelDialogContext, setModelDialogContext] = useAppStoreKey("ModelDialogContext");
    const setActiveStep = useAppStoreDispatchKey("ActiveStep");
    const { enqueueSnackbar } = useSnackbar();
    const { type: deleteType, workflow_id, model_name, model_idx } = deleteContext
    const [state, setState] = useState({
        mobileOpen: false,
        view: "upload",
        createProjOpen: false,
        modelDeleteName: "",
        modelSaveName: "",
        modelDescription: ""
    });


    let deleteModelConfirm = () => {
        setState({ ...state, modelDeleteName: "", modelSaveName: "", modelDescription: "" });
        let url = `workflow/` + workflow_id;
        aichemyProtoAxios.delete(url, [], {
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
            .then(() => {
                switch (deleteType) {
                    case "selected":
                        // deleting a model other than the one loaded
                        setCurrentModels(currentModels.filter((v, idx) => idx !== model_idx))
                        handleClose();
                        break;
                    case "current":
                        // enforce order of events on delete, add a state prop for the redirect and then change tabs
                        navigate("/MixingStudio", { replace: false, state: { deleted: true } }).then(() => {
                            setActiveStep(0)
                            setModelDialogContext({ ...modelDialogContext, deleted: true });
                        }).finally(() => {
                            handleClose();
                        })
                        break;
                    default:
                        break;
                }
            })
            .catch(error => {
                enqueueSnackbar("Model Deletion Failed.", { variant: "error" });
                console.error(error)
            });
    };

    // Handles the cancel action
    const handleClose = () => {
        setModelDialogContext({ ...modelDialogContext, deleteOpen: false, deleted: false });
        setDeleteContext({
            type: "",
            model_idx: "",
            workflow_id: "",
            model_name: ""
        })
    };

    return (
        <Dialog open={modelDialogContext ? modelDialogContext.deleteOpen : false} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Are you sure you want to delete {model_name}?</DialogContentText>
                <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => deleteModelConfirm()}
                    color="secondary"
                    autoFocus
                    data-cy="delete_model_btn"
                >
                    Delete Model
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default React.memo(DeleteModelDialog);