import React from 'react'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";


function OptimizationItem(props) {
  const { classes, colName, optTargets, setOptTargets, headerWidth  } = props;

  const handleSetTargetValue = (key) =>  (event) => {
    let newColTargets = {...optTargets[colName]}
    if (key === 'check') {newColTargets[key] = !newColTargets[key]}
    else if (key === 'weight' || key === 'targetValue') {newColTargets[key] = Number(event.target.value)}
    else {newColTargets[key] = event.target.value}
    let newOptTargets = {...optTargets}
    newOptTargets[colName] = newColTargets
    setOptTargets(newOptTargets)
  }


  const targetSelections = ['min', 'max', 'value']

  return (
    <div style={{display: "flex", flexDirection: "row"}}>
      <FormGroup style={{ marginRight: 24, marginTop: 20 }}>
        {/*checkbox*/}
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(optTargets[colName] && optTargets[colName].check)}
              onChange={handleSetTargetValue('check')}
              value={colName + " checkbox"}
              color="primary"
              style={{ textAlign: "right" }}
            />
          }
          label={
            <div style={{display: "flex", flexDirection: "row"}}>
              <Typography align="left" style={{ wordWrap: "break-word", width: headerWidth}}>
                {colName}
              </Typography>
            </div>
          }
        />
      </FormGroup>
      {/*dropdown menu*/}
      <FormControl style={{width: '200px', marginRight: 12, marginTop: 8}}>
        <InputLabel htmlFor={"model_opt_type_select_" + colName}>
          Optimization Type
        </InputLabel>
        <Select
          style={{ textAlign: "left" }}
          value={optTargets[colName] ? optTargets[colName].target : ""}
          onChange={handleSetTargetValue('target')}
          input={
            <Input
              name={"model_opt_type_select_" + colName}
              id={"model_opt_type_select_" + colName}
            />
          }
        >
          {targetSelections.map(value => {
            return <MenuItem key={value} value={value}>
              {value}
            </MenuItem>;
          })}
        </Select>
      </FormControl>
      {optTargets[colName] && optTargets[colName].target === "value" && (
        <>
          <FormControl className={classes.formControl}  style={{marginRight: 12}}>
            <TextField
              id={"opt_target_input_" + colName}
              label={"Target Value"}
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              onChange={handleSetTargetValue('targetValue')}
              value={optTargets[colName] ? optTargets[colName].targetValue : ""}
              style={{ marginTop: 0, width: "100%" }}
              data-cy={"opt_target_" + colName}
            />
          </FormControl>
        </>
      )}
      <FormControl className={classes.formControl}  style={{marginRight: 12}}>
        <TextField
          id={"opt_target_ub_" + colName}
          label={"Weight"}
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          onChange={handleSetTargetValue('weight')}
          value={optTargets[colName] ? optTargets[colName].weight : ""}
          style={{ marginTop: 0, width: "100%" }}
          data-cy={"opt_target_" + colName}
        />
      </FormControl>
    </div>

  );
}

export default React.memo(OptimizationItem)
