import { Schema, schema } from "../schemaUtils"
import { Lab } from "./"

export class User {
    id?: string
    name?: string
    uniqueName?: string
    timeCreated?: Date
    timeModified?: Date
    adGroups?: string[]
    labs?: Lab[]

    static readonly [schema]: Schema<User> = {
        endPoint: "users",
        type: "user",
        relationships: {
            "labs": { map: "labs", model: "Lab", toMany: true }
        },
        map: {
            "id": "id",
            "name": "full_name",
            "uniqueName": "unique_name",
            "timeCreated": "created",
            "timeModified": "modified",
            "adGroups": "ad_groups"
        }
    }

    constructor(toCopy?: Partial<User>) {
        Object.assign(this, toCopy)
    }
}