import React, { useMemo, useContext, useState } from 'react';
import { AccountContext } from "context";

import { Grid, Typography, Button, Tooltip } from "@material-ui/core";
import { Lock, LockOpen } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import EditCardBase, {
  useCheckReadOnly,
  useDefaultMutateHandlers,
} from "../General/EditCardBase";
import ConfirmationDialog, { useConfirmDialog } from "../General/ConfirmationDialog";
import BufferTextField from "../General/BufferTextField";
import { value } from "utils/useFormState/useFormStateUtils";
import FreeSoloSelector from "../General/FreeSoloSelector"
import { ObjectTree } from 'components/General/ObjectTree';
import { DefaultLabContext } from "../../DefaultLabProvider"

import common_styles from '../../styles/common_styles';

import useFormState from "utils/useFormState/useFormState"
import { Instrument } from 'schema/models';


const Model = "Instrument";
const LOCK_TEXT = "This model will always be READ-ONLY, as only Skylab Super Administrators can edit or create these." + 
  " Please contact them for assistance.";

const useStyles = makeStyles(theme => ({ ...common_styles(theme) }));
function EditInstrumentsCard({ initialInstr: initialInstrRaw, onClose }) {
  const classes = useStyles();
  const account = useContext(AccountContext);
  const defaultLab = useContext(DefaultLabContext);

  const initialInstr = useMemo(
    () =>
      initialInstrRaw ||
      new Instrument({
        labs: defaultLab
      }),
    [defaultLab, initialInstrRaw],
  )
  const invalidators = useMemo(
    () => [
      {
        path: "title",
        validator: title => {
          if (!title || title === "") return "Name required"
        },
      },],
    [],
  )

  const {
    data: instrument,
    updateData,
    validateAll,
    undoRedo,
    status,
  } = useFormState({
    initialState: { data: initialInstr },
    validators: invalidators,
  })
  const undo = useMemo(
    () => ({ callback: undoRedo.onUndo, name: undoRedo.undoName }),
    [undoRedo.onUndo, undoRedo.undoName],
  )
  const redo = useMemo(
    () => ({ callback: undoRedo.onRedo, name: undoRedo.redoName }),
    [undoRedo.onRedo, undoRedo.redoName],
  )

  const {
    handleDelete,
    handleSave,
    handleCopy,
    handleSaved,
  } = useDefaultMutateHandlers({
    Model,
    onClose,
    initialObject: initialInstr,
    object: instrument,
    validateAll,
    objectName: "Instrument",
    onCopy(newInstr) {
      updateData(newInstr, {
        name: `Copy ${initialInstr?.title}`,
      })
    },
    updateData,
    account,
  })

  const {
    getConfirmation: confirmSave,
    props: confirmContributorsProps,
  } = useConfirmDialog({
    callback: handleSave,
    title: "Are you sure?",
    message:
      "These changes will impact tests associated with this Instrument. Are you sure you want to save your changes?",
    autoConfirm: useMemo(() => {
      return true
    }, []),
  })
  const {
    getConfirmation: confirmDelete,
    props: confirmDeleteProps,
  } = useConfirmDialog({
    callback: handleDelete,
    title: "Confirm Delete Instrument",
    message:
      "Deleting cannot be undone, are you sure you wish to permanently delete this Instrument? Doing so will also delete any tests associated with it.",
  })

  const userCanOnlyRead = useCheckReadOnly({
    initialObject: initialInstr,
    object: instrument,
    platformAdmin: true,
    objectType: "Instrument"
  })
  const [readOnly, setReadOnly] = useState(true);

  return (
    <EditCardBase
      onDelete={confirmDelete}
      onSave={confirmSave}
      onSaved={handleSaved}
      onCopy={handleCopy}
      onClose={onClose}
      title={initialInstr?.title}
      editing={instrument.id}
      objectName="Instrument"
      cardDetail="Define the Instrument below by filling in the associated fields"
      undo={undo}
      redo={redo}
      readOnly={readOnly}
      data-testid="edit instrument"
      forceReadOnly={true}
    >
      <ConfirmationDialog {...confirmDeleteProps} />
      <ConfirmationDialog {...confirmContributorsProps} />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip title={LOCK_TEXT}>
                <Button onClick={() => { if (!userCanOnlyRead) { setReadOnly(true) } }}>
                  {readOnly ? <Lock /> : <LockOpen />}
                </Button>
              </Tooltip>
              <Typography
                variant="subtitle2"
                className={classes.cardSubtitle}
              >
                Basic Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <BufferTextField
                size="small"
                required
                defaultValue={instrument.title || ""}
                error={!!status?.invalid?.title?.[value]}
                helperText={status?.invalid?.title?.[value]}
                onBlur={ev => {
                  updateData(
                    { title: ev.target.value },
                    { name: "Title Edit" },
                  )
                }}
                fullWidth
                label="Name"
                placeholder="Enter Instrument Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <BufferTextField
                size="small"
                required
                defaultValue={instrument.description || ""}
                error={!!status?.invalid?.description?.[value]}
                helperText={status?.invalid?.description?.[value]}
                onBlur={ev => {
                  updateData(
                    { description: ev.target.value },
                    { name: "Description Edit" },
                  )
                }}
                fullWidth
                label="Description"
                placeholder="Enter Instrument Description"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <FreeSoloSelector
                value={instrument.tags}
                onChange={(ev, newVal) =>
                  updateData(
                    { tags: newVal },
                    { name: "Tags Edit" },
                  )
                }
                fullWidth
                label="Tags and Keywords"
                placeholder={
                  instrument.tags?.length === 0 ? "" : ""
                }
                helperText="Press enter between keywords"
                variant="outlined"
              />
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  className={classes.cardSubtitle}
                >
                  Instrument Metadata
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ObjectTree payload={instrument.meta}
                  onSelect={undefined}
                  root={"object"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EditCardBase>
  );
}
export default React.memo(EditInstrumentsCard);