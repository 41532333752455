import { createContext } from 'react';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './API/queryHooks';
import { AppStore } from './AppStore';
import DefaultLabProvider from './DefaultLabProvider';
import MMThemeProvider from 'MMThemeProvider';
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalConfirmationDialogProvider } from 'components/General/GlobalConfirmationDialog';
import { ForageCacheProvider } from 'utils/useLocalforageState';
import Auth0FailureDisplay from 'components/Dashboard/Auth0FailureDisplay';
export const AccountContext = createContext({});

AccountContext.displayName = "AccountContext";


const snackbarAnchorOrigin = { vertical: "bottom", horizontal: "center" };

export default function ContextProviders({ children }) {

    const { user, error } = useAuth0()
    
    if(error){
        return (
            <Auth0FailureDisplay />
        )
    }

    return (

        <AccountContext.Provider value={user}>
            <ForageCacheProvider>
                <QueryClientProvider client={queryClient}>
                    <AppStore>
                        <MMThemeProvider>
                            <SnackbarProvider maxSnack={3} anchorOrigin={snackbarAnchorOrigin}>
                                <DefaultLabProvider>
                                    <GlobalConfirmationDialogProvider>
                                        {children}
                                    </GlobalConfirmationDialogProvider>
                                </DefaultLabProvider>
                            </SnackbarProvider>
                        </MMThemeProvider>
                    </AppStore>
                </QueryClientProvider>
            </ForageCacheProvider>
        </AccountContext.Provider>
    );
}