import React from 'react';
import { makeStyles, InputBase } from '@material-ui/core';
import { useBufferState } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
    defaultCell: {
        display: "block",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "visible",
        textOverflow: "ellipsis",
        padding: "6px 24px 6px 16px",
        borderRight: "1px solid rgba(0,0,0,0.1)",
        '&:hover': {
            overflow: "visible",
            outline: "1px solid gray",
            outlineOffset: "-1px",
        },
        '&:focus-within':{
            outline: "2px solid",
            outlineColor: theme.palette.primary.main,
            outlineOffset: "-2px",
        },
    },
    input: {
        ...theme.typography.body2,
    }
}))

export default function EditCell({cell, row: {index}, column: {id, placeholder}, onEdit}){
    const classes = useStyles();
    const initialValue = cell.value && String(cell.value) !== "" ? String(cell.value) : "";
    const [value, setValue] = useBufferState(initialValue);
    return (
        <div className={classes.defaultCell}>
            <InputBase 
                inputProps={{style:{ padding: 0 }}} 
                fullWidth 
                className={classes.input} 
                value={value} 
                onChange={ev => setValue(ev.target.value)}
                onBlur={() => onEdit && onEdit(id, index, value)}
                placeholder={placeholder}
                />
        </div>
    )
}