import React from "react";
import {Backdrop, Dialog, DialogContentText, DialogContent, DialogTitle, Grid} from "@material-ui/core";

const Auth0FailureDisplay = () => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <Dialog
                open={true}
            >
                <DialogTitle>
                    Looks like something went wrong with our Authentication Service!
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <img src="/skylabDown.gif" alt="Network Connectivity Issues Parks and Rec meme" />
                        </Grid>
                    </Grid>
                    <DialogContentText>
                        It's possible there are outages being reported at <a href="https://status.auth0.com/"> Auth0's status page</a>.
                    </DialogContentText>
                    <DialogContentText>
                        Please alert a member of the Skylab User Support team and we will work to fix this issue if Auth0 is functioning normally.
                    </DialogContentText>
                    <DialogContentText>
                        If this service continues to be down for over 8 hours, contact Dave F. at Dave@mmm.com for more support. 
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Backdrop>
    );
}

export default Auth0FailureDisplay;