/**
 * @format
 */

import { useEffect, useMemo, useState, useRef } from "react"
import { useSnackbar } from "notistack"
import { getPubChemURL, getPubChem } from "./pubchemAxios"
import {
    PropertyDefinition,
    PropertyInstance,
    SampleSet,
    Sample,
    SampleInstance,
    ProcessDefinition,
    ProcessChart,
} from "../schema/models"
import commonProperties from "../components/PropertyLibrary/commonProperties"
import { escapeRegExp, levDist } from "../utils/utils"
import {
    QueryClient,
    QueryFunction,
    QueryKey,
    QueryOptions,
    useQuery,
    useQueries,
    UseQueryOptions,
    UseQueryResult,
    useQueryClient,
} from "react-query"
import {
    QueryParam,
    ArrayResponse,
    Field,
    QueryParamSingle,
    OnProgressCallback,
    QueryParamArray,
} from "./firecrackerTypes"
import {
    getBEVersion,
    getETLYProcessors,
    getQueryFC,
    getQueryFCSingle,
    getUserRoles,
    getUserPreferences,
} from "./firecrackerAPI"

export const firecrackerKey = "firecracker"

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1 * 10 * 1000,
            cacheTime: 5 * 60 * 1000,
            retry: false,
        },
    },
})

/** Type adding optional errorMessage, logName, and noLog options to the base useQueryOptions. */
export type UseMMQueryOptions<
    TData = unknown,
    TError = unknown,
> = UseQueryOptions<TData, TError> & {
    errorMessage?: (error: any) => string
    logName?: string
    noLog?: boolean
    onProgress?: OnProgressCallback
}

/** Wrapper around useQuery from react-query that enqueues error messages to the snackbar and performs debug logging (in non-production builds) */
export function useQueryBase<TData = unknown, TError = unknown>(
    queryKey: QueryKey,
    queryFn: QueryFunction<TData>,
    options?: UseMMQueryOptions<TData, TError>,
): UseQueryResult<TData, TError> {
    const { errorMessage, logName, noLog, ...queryOptions } = options || {}
    const { enqueueSnackbar } = useSnackbar()
    queryOptions.onError = (error: any) => {
        const message = errorMessage
            ? errorMessage(error)
            : `${
                  (Array.isArray(queryKey) ? queryKey[0] : queryKey) || ""
              } API Error`
        if (errorMessage) enqueueSnackbar(message, { variant: "error" })
        if (options?.onError) options.onError(error)
    }

    const query = useQuery(queryKey, queryFn, queryOptions)

    useDebugLogEffect(query, queryKey, options)
    return query
}

/** Workhorse query hook for get queries to the firecracker API */
export function useQueryFC<T = unknown>(
    // @ts-ignore
    queryParam: QueryParam<T> | undefined,
    // @ts-ignore
    options?: UseMMQueryOptions<ArrayResponse<T>>,
) {
    const { onProgress, ...queryOptions } = options || {}
    if (!queryOptions.errorMessage) {
        queryOptions.errorMessage = DefaultErrorMessage
    }
    if (!queryParam) {
        queryOptions.enabled = false
    }

    // @ts-ignore
    return useQueryBase<ArrayResponse<T>>(
        [firecrackerKey, queryParam],
        // @ts-ignore
        async () => getQueryFC<T>(queryParam, onProgress),
        queryOptions,
    )
}

/** Query hook for get single value queries to the firecracker API */
export function useQueryFCSingle<T = unknown>(
    // @ts-ignore
    queryParam: QueryParamSingle<T> | undefined,
    options?: UseMMQueryOptions<T | undefined>,
) {
    const { onProgress, ...queryOptions } = options || {}
    if (!queryOptions.errorMessage) {
        queryOptions.errorMessage = DefaultErrorMessage
    }
    if (!queryParam) {
        queryOptions.enabled = false
    }
    return useQueryBase<T | undefined>(
        [firecrackerKey, queryParam],
        // @ts-ignore
        async () => getQueryFCSingle<T>(queryParam, onProgress),
        queryOptions,
    )
}

/** Query hook for getting an array of objects from the firecracker API based on an id array. Changes to the id array are dynamically queried and optimized. Only Model and fields are used form the root query. */
export function useQueryFCArray<T = unknown>(
    ids: string[],
    // @ts-ignore
    rootQuery: QueryParamArray<T>,
    options?: UseMMQueryOptions<T | undefined>,
) {
    const queryClient = useQueryClient()

    const { enqueueSnackbar } = useSnackbar()
    const queryOptions = useMemo(() => {
        const { errorMessage, logName, noLog, ...queryOptions } = options || {}
        queryOptions.onError = (error: any) => {
            const message = errorMessage
                ? errorMessage(error)
                : `${rootQuery.Model || ""} Array API Error`
            if (errorMessage) enqueueSnackbar(message, { variant: "error" })
            if (options?.onError) options.onError(error)
        }
        return queryOptions
    }, [enqueueSnackbar, options, rootQuery.Model])

    const [queryGroups, setQueryGroups] = useState(ids.length ? [ids] : [])
    const queries = useMemo<QueryOptions<T>[]>(
        () =>
            queryGroups.length
                ? queryGroups.reduce((agg, ids) => {
                      if (ids.length > 0) {
                          const queryParam = {
                              Model: rootQuery.Model,
                              fields: rootQuery.fields,
                              filter: ids,
                          }
                          agg.push({
                              queryKey: [firecrackerKey, queryParam],
                              queryFn: () => getQueryFC(queryParam as any),
                              ...queryOptions,
                          } as any)
                      }
                      return agg
                  }, [] as any[])
                : [],
        [queryGroups, queryOptions, rootQuery.Model, rootQuery.fields],
    ) as any

    const rawResults = useQueries(queries)

    // make results array reference stable
    const prev = useRef<{
        results?: UseQueryResult[]
        ids?: string[]
        mergedResults?: UseQueryResult<T[]>
    }>({})
    const results =
        prev.current.results?.length === rawResults.length &&
        prev.current.results?.every((r, i) => r === rawResults[i])
            ? prev.current.results
            : rawResults

    const mergedResults: UseQueryResult<T[]> = useMemo(() => {
        let data = results.reduce((agg, val: any) => {
            if (val.data?.data) agg.push(...val.data.data)
            return agg
        }, [] as any[])

        // keep data reference stable if possible
        const pm = prev.current.mergedResults
        if (
            pm?.data?.length === data.length &&
            pm?.data?.every((d, i) => d === data[i])
        ) {
            data = pm.data
        }

        const merged: UseQueryResult<T[]> = mergeQueryResults(results, data)

        // check values have actually changed
        if (!prev.current.mergedResults) return merged
        for (const [key, val] of Object.entries(prev.current.mergedResults) as [
            keyof typeof merged,
            any,
        ][]) {
            if (val === merged[key]) continue
            if (typeof val === "function") continue
            return merged
        }
        return prev.current.mergedResults
    }, [results])

    useEffect(() => {
        let newGroups: string[][] = [...queryGroups]
        let changed = false
        let newCache: any = results[0]?.data
        // check for results to merge
        if (
            queryGroups.length > 1 &&
            results[0].isSuccess &&
            !prev.current.results?.every(
                (r, i) => results[i]?.isSuccess === r.isSuccess,
            )
        ) {
            const toMerge: string[][] = []
            const toMergeResults: any[] = []
            for (let i = 1; i < results.length; ++i) {
                if (results[i].isSuccess) {
                    toMerge.push(queryGroups[i])
                    toMergeResults.push(results[i].data)
                    newGroups[i] = []
                }
            }
            if (toMerge.length > 0) {
                changed = true
                // update queryGroups
                newGroups = newGroups.filter(g => g.length)
                newGroups[0] = (newGroups[0] || []).concat(...toMerge)
                newCache = toMergeResults.reduce(
                    (agg, val) => ({
                        data: agg.data.concat(val.data),
                        count: agg.count + val.count,
                    }),
                    results[0].data || { data: [], count: 0 },
                )
            }
        }

        if (ids !== prev.current.ids) {
            // check for removed ids that are in the root query
            const currentLength = newGroups[0]?.length
            if (currentLength) {
                newGroups[0] =
                    newGroups[0]?.filter(id => ids.includes(id)) || []
                if (currentLength > newGroups[0].length) {
                    changed = true
                    newCache = {
                        data: newCache.data.filter((obj: any) =>
                            newGroups[0].includes(obj.id),
                        ),
                        count:
                            newCache.count -
                            (currentLength - newGroups[0].length),
                    }
                }
            }

            // add query for any new ids
            const idsFlat = [].concat(...(queryGroups as any)) as string[]
            const newIds = ids.filter(id => !idsFlat.includes(id))
            if (newIds.length > 0) {
                changed = true
                newGroups.push(newIds)
            }
        }
        // update query cache if the root query has changed
        if (newCache !== results[0]?.data && newGroups[0]?.length) {
            queryClient.setQueryData(
                [
                    firecrackerKey,
                    {
                        Model: rootQuery.Model,
                        fields: rootQuery.fields,
                        filter: newGroups[0],
                    },
                ],
                newCache,
            )
        }

        if (changed) setQueryGroups(newGroups)

        prev.current.results = results
        prev.current.ids = ids
    }, [
        ids,
        queryClient,
        queryGroups,
        results,
        rootQuery.Model,
        rootQuery.fields,
    ])

    useEffect(() => {
        prev.current.mergedResults = mergedResults
    })

    useDebugLogEffect(mergedResults, "firecrackerArray", options)

    return mergedResults
}

/** default error message function used by useQueryFC */
function DefaultErrorMessage(error: any) {
    return error?.response?.status
        ? `API Error (${error?.response.status}): ${
              error?.response?.data?.data?.message || error?.message || ""
          }`
        : `API Error: ${error?.message || ""}`
}

/** Query and return pubchem properties based on a puChemId or CASID */
export function useQueryPubChem(id: string = "") {
    const propDefQuery = useQueryFC<PropertyDefinition>(propDefQueryParam, {
        enabled: !!id,
        logName: "Pubchem Prop Defs",
    })
    const propDefs = propDefQuery.data?.data
    const enabled = !!id && !!propDefs?.length
    const pubChemQuery = useQueryBase(
        ["PubChem", id],
        () => getPubChemData(id, propDefs),
        {
            enabled,
            errorMessage: (error: any) => {
                return error.message
            },
        },
    )
    return useMemo(
        () =>
            mergeQueryResults([propDefQuery, pubChemQuery], pubChemQuery.data),
        [propDefQuery, pubChemQuery],
    )
}
/** used by useQueryPubChem to define the property definition and pubchem queries */
const pubchemPropertyMap = {
    CID: commonProperties.pubChemId,
    MolecularWeight: commonProperties.molecularWeight,
    CanonicalSMILES: commonProperties.smiles,
    InChIKey: commonProperties.inChiKey,
    IUPACName: "IUPAC Name",
    XLogP: "XLogP",
}
/** used by useQueryPubChem to define the property definition queries */
const propDefQueryParam: QueryParam<PropertyDefinition> = {
    Model: "PropertyDefinition",
    filter: {
        name: "title",
        op: "in_",
        val: Object.values(pubchemPropertyMap).concat(commonProperties.casId),
    },
}
/** used by useQueryPubChem to fetch data from the pubChem API and covert it to a Sample with the property definitions */
async function getPubChemData(
    id: string,
    propDefs: PropertyDefinition[] | undefined,
) {
    const pubChemData = await getPubChem(getPubChemURL(id)).catch(
        (error: any) => {
            const message =
                "Pubchem Error: " +
                (error?.response?.data?.Fault?.Message ||
                    "No error details from PubChem")
            throw new Error(message)
        },
    )

    const rtn = new Sample()
    const props: PropertyInstance[] = []
    //get all synonyms
    const synonymList =
        pubChemData[1].data.InformationList.Information[0].Synonym
    const pubChemId = pubChemData[1].data.InformationList.Information[0].CID
    const sourceUrl = `https://pubchem.ncbi.nlm.nih.gov/compound/${pubChemId}`
    const sourceNotes = `Pubchem Import`
    //take next five elements in synonym list as alternate names
    try {
        if (synonymList.length > 0) {
            rtn.title = synonymList[0]
            const casIndex = synonymList.findIndex(s =>
                s.match(/^(\d{1,8})-(\d{2})-(\d{1})$/),
            )
            if (casIndex >= 0) {
                const casId = synonymList[casIndex]
                const casDef = propDefs?.find(
                    d => d.title === commonProperties.casId,
                )
                if (casDef)
                    props.push(
                        new PropertyInstance({
                            definition: casDef,
                            data: casId,
                            sourceNotes,
                            sourceUrl,
                        }),
                    )
            }
            if (synonymList.length > 0)
                rtn.alternateNames = synonymList.splice(0, 5).join("\n")
        }
    } catch (error) {
        throw new Error("Parsing PubChem data Failed")
    }
    for (let [key, val] of Object.entries(
        pubChemData[0].data.PropertyTable.Properties[0],
    )) {
        const def = propDefs?.find(
            d => d.title === (pubchemPropertyMap as any)[key],
        )
        if (def) {
            props.push(
                new PropertyInstance({
                    definition: def,
                    data: val,
                    sourceNotes,
                    sourceUrl,
                }),
            )
        }
    }
    rtn.properties = props
    return rtn
}

/** basic query to get the BE version */
export function useQueryVersion() {
    return useQueryBase(
        "BEVersion",
        () => {
            return getBEVersion()
        },
        backendVersionOptions,
    ) // get health check stale after 1 hour
}
const backendVersionOptions = {
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
}

export function useETLYProcessorsList() {
    return useQueryBase(
        "ETLYProcessorList",
        () => {
            return getETLYProcessors()
        },
        {},
    )
}

export function useUserPreferences(model?: string, _id?: string) {
    return useQueryBase(
        "UserPreferences",
        () => {
            return getUserPreferences(model, _id)
        },
        {},
    )
}

/** Query that gets a material and all associated instance relationships from an id */
export function useQueryMaterial(
    { id }: { id?: string } = {},
    options?: UseMMQueryOptions<Sample | undefined>,
) {
    const queryParam: QueryParamSingle<Sample> | undefined = !id
        ? undefined
        : {
              Model: "Sample",
              filter: id,
              fields: [
                  "labs.lab",
                  "components",
                  "processSteps",
                  "properties",
                  "processChart",
                  {
                      name: "tests",
                      fields: ["instrument"],
                      filter: {
                          name: "valid",
                          op: "eq",
                          val: true,
                      },
                  },
              ],
          }
    return useQueryFCSingle(queryParam, {
        logName: "Full Material",
        ...options,
    })
}

//** Query hook that queries all of the definitions in a processChart */
export function useQueryProcessChartDefinitions(
    Model: "Sample",
    processChart?: ProcessChart,
): UseQueryResult<Sample[]>
export function useQueryProcessChartDefinitions(
    Model: "ProcessDefinition",
    processChart?: ProcessChart,
): UseQueryResult<ProcessDefinition[]>
export function useQueryProcessChartDefinitions(
    Model: "PropertyDefinition",
    processChart?: ProcessChart,
): UseQueryResult<PropertyDefinition[]>
export function useQueryProcessChartDefinitions(
    Model: "Sample" | "ProcessDefinition" | "PropertyDefinition",
    processChart?: ProcessChart,
) {
    const ids = useMemo<string[]>(() => {
        if (!processChart?.nodes) return []
        const defIds = new Set<string>()
        const key =
            Model === "Sample"
                ? "materialDefs"
                : Model === "ProcessDefinition"
                ? "processDefs"
                : "propertyDefs"
        for (const node of Object.values(processChart.nodes)) {
            if (node[key]) {
                for (const id of Object.keys(node[key] || {})) {
                    defIds.add(id)
                }
            }
        }
        return Array.from(defIds)
    }, [Model, processChart?.nodes])
    const query = useMemo(
        () => ({
            Model,
            fields:
                Model === "Sample"
                    ? (["properties.definition"] as any)
                    : undefined,
        }),
        [Model],
    )
    return useQueryFCArray(ids, query as any, {
        logName: `${Model} Process Chart Definitions`,
    })
}

/** Query hook that takes a sampleSet Object and injects requested component property instances */
export function useQueryComponentProperties(
    sampleSet?: SampleSet,
    properties?: PropertyDefinition[],
    options?: UseMMQueryOptions<ArrayResponse<Sample>>,
): UseQueryResult<Sample[]> {
    const samples = sampleSet?.samples
    const componentDefId = useMemo(() => {
        if (!samples) return []
        const ids: { [K: string]: true } = {}
        for (const sample of samples) {
            if (sample?.components) {
                for (const comp of sample.components) {
                    if (comp?.definition?.id) {
                        ids[comp.definition.id] = true
                    }
                }
            }
        }
        return Object.keys(ids)
    }, [samples])
    const propertyDefIds = useMemo(() => {
        if (!properties) return undefined
        const ids: { [K: string]: true } = {}
        for (const property of properties) {
            if (property?.id) {
                ids[property.id] = true
            }
        }
        return Object.keys(ids)
    }, [properties])
    const componentDefQuery: QueryParam<Sample> | undefined =
        !componentDefId?.length
            ? undefined
            : {
                  Model: "Sample",
                  fields: [
                      !propertyDefIds
                          ? "properties.definition"
                          : {
                                name: "properties",
                                fields: ["definition"],
                                filter: {
                                    name: "definition.id",
                                    op: "in_",
                                    val: propertyDefIds,
                                },
                            },
                  ],
                  filter: componentDefId,
              }
    const componentsQuery = useQueryFC<Sample>(componentDefQuery, {
        logName: "Components With Properties",
        ...options,
    })

    const newSamples = useMemo(() => {
        if (!componentsQuery.data) return undefined
        const newSamples = samples?.map(sample => {
            if (!sample || !sample.components) return sample
            const components = sample.components.map(comp => {
                if (!comp?.definition) return comp
                const def = componentsQuery.data.data.find(
                    def => def.id === comp.definition?.id,
                )
                if (!def || def === comp.definition) return comp
                return new SampleInstance({ ...comp, definition: def })
            })
            if (
                components.length === sample.components.length &&
                components.every((c, i) => c === sample?.components?.[i])
            )
                return sample
            return new Sample({ ...sample, components })
        })
        return samples?.length === newSamples?.length &&
            newSamples?.every((s, i) => s === samples?.[i])
            ? samples
            : newSamples
    }, [samples, componentsQuery.data])
    return { ...componentsQuery, data: newSamples } as UseQueryResult<Sample[]>
}

/** Query hook that takes a sampleSet Object and injects requested component property instances */
export function useQuerySampleComponentProperties(
    sampleSet?: SampleSet,
    properties?: PropertyDefinition[],
    options?: UseMMQueryOptions<Sample | undefined>,
): UseQueryResult<Sample[]> {
    const samples = sampleSet?.samples
    const componentDefId = useMemo(() => {
        if (!samples) return []
        const ids = new Set<string>()
        for (const sample of samples) {
            if (sample?.components) {
                for (const comp of sample.components) {
                    if (comp?.definition?.id) {
                        ids.add(comp?.definition?.id)
                    }
                }
            }
        }
        return Array.from(ids)
    }, [samples])
    const propertyDefIds = useMemo(() => {
        if (!properties) return undefined
        const ids = new Set<string>()
        for (const property of properties) {
            if (property?.id) {
                ids.add(property.id)
            }
        }
        return Array.from(ids)
    }, [properties])
    const componentDefQuery: QueryParam<Sample> | undefined = {
        Model: "Sample",
        fields: [
            !propertyDefIds
                ? "properties.definition"
                : {
                      name: "properties",
                      fields: ["definition"],
                      filter: {
                          name: "definition.id",
                          op: "in_",
                          val: propertyDefIds,
                      },
                  },
        ],
    }
    return useQueryFCArray<Sample>(componentDefId, componentDefQuery, {
        logName: "Sample Components Properties",
        ...options,
    })
}

/** Query that gets a material and all associated instance relationships from an id */
export function useQueryFullSet(
    { id }: { id?: string } = {},
    options: UseMMQueryOptions<SampleSet | undefined>,
) {
    const queryParam: QueryParamSingle<SampleSet> | undefined = !id
        ? undefined
        : {
              Model: "SampleSet",
              filter: id,
              fields: [
                  "labs.lab",
                  "samples.labs.lab",
                  "samples.components",
                  "samples.processSteps",
                  "samples.properties",
                  "processChart",
                  {
                      name: "samples.tests",
                      fields: ["instrument"],
                      filter: {
                          name: "valid",
                          op: "eq",
                          val: true,
                      },
                  },
              ],
          }
    return useQueryFCSingle(queryParam, {
        ...options,
        logName: "Full Set",
    })
}

/** Given a processView sampleSet and array of materials, this hook queries and returns a fully populated react flow chart object */
export function useQueryFullProcessView(
    processView?: SampleSet,
    options?: UseMMQueryOptions<ArrayResponse<SampleSet>>,
) {
    const [sampleQuery, processQuery, propertyQuery] = useMemo(() => {
        if (!processView?.flowChart) return []
        const hashes: { [K: string]: { [S: string]: true } } = {
            material: {},
            process: {},
            output: {},
        }
        for (const node of Object.values(processView.flowChart.nodes)) {
            if (node.properties?.definitions && node.type) {
                for (const id of Object.keys(node.properties.definitions))
                    hashes[node.type][id] = true
            } else if (node.properties?.definition?.id && node.type) {
                hashes[node.type][node.properties.definition.id] = true
            }
        }
        return [
            {
                Model: "Sample",
                filter: Object.keys(hashes.material),
                fields: ["id", "title"],
            } as QueryParam<Sample>,
            {
                Model: "ProcessDefinition",
                filter: Object.keys(hashes.process),
                fields: ["id", "title", "meta"],
            } as QueryParam<ProcessDefinition>,
            {
                Model: "PropertyDefinition",
                filter: Object.keys(hashes.output),
                fields: ["id", "title", "meta"],
            } as QueryParam<PropertyDefinition>,
        ]
    }, [processView?.flowChart])

    const sampleResults = useQueryFC<Sample>(sampleQuery, {
        logName: "ProcessView Samples Query",
        ...options,
    } as UseMMQueryOptions<ArrayResponse<Sample>>)
    const processResults = useQueryFC<ProcessDefinition>(processQuery, {
        logName: "ProcessView Process Query",
        ...options,
    })
    const propertyResults = useQueryFC<PropertyDefinition>(propertyQuery, {
        logName: "ProcessView Property Query",
        ...options,
    })

    const mergedResults = useMemo(
        () =>
            mergeQueryResults([sampleResults, processResults, propertyResults]),
        [processResults, propertyResults, sampleResults],
    )

    const filledProcessView = useMemo(() => {
        if (!mergedResults.isSuccess || !processView?.flowChart)
            return processView
        const nodes = { ...processView.flowChart.nodes }
        for (const [nodeId, node] of Object.entries(nodes)) {
            if (node.type === "material" && node.properties.definitions) {
                const definitions = { ...node.properties.definitions }
                for (const definitionId in definitions) {
                    const newDef = sampleResults.data?.data?.find(
                        def => def.id === definitionId,
                    )
                    if (newDef)
                        definitions[definitionId] = {
                            ...definitions[definitionId],
                            ...newDef,
                        }
                }
                nodes[nodeId] = {
                    ...node,
                    properties: { ...node.properties, definitions },
                }
            }
            if (node.type === "process" && node.properties.definition) {
                const definition = processResults.data?.data?.find(
                    process => process.id === node.properties.definition.id,
                )
                nodes[nodeId] = {
                    ...node,
                    properties: {
                        ...node.properties,
                        definition: {
                            ...node.properties.definition,
                            ...definition,
                        },
                    },
                }
            }
            if (node.type === "output" && node.properties.definitions) {
                const definitions = { ...node.properties.definitions }
                for (const definitionId in definitions) {
                    const newDef = propertyResults.data?.data?.find(
                        property => property.id === definitionId,
                    )
                    if (newDef)
                        definitions[definitionId] = {
                            ...definitions[definitionId],
                            ...newDef,
                        }
                }
                nodes[nodeId] = {
                    ...node,
                    properties: { ...node.properties, definitions },
                }
            }
        }
        return new SampleSet({
            ...processView,
            flowChart: { ...processView.flowChart, nodes },
        })
    }, [
        mergedResults.isSuccess,
        processResults.data?.data,
        processView,
        propertyResults.data?.data,
        sampleResults.data?.data,
    ])

    return {
        ...mergedResults,
        data: filledProcessView,
    } as UseQueryResult<SampleSet>
}

/**
 * Wrapper for a query hook for use with auto completes. Allows autocomplete options with pagination. Defaults fields for id, title, description
 * @param {*} values - {[filterKeys]: filterValue} currently being used to filter autocomplete list (what the user has typed)
 * @param {*} params - params use with the useQueryFC
 * @param {*} options
 */
export function useQueryAutocomplete<T>(
    values: undefined | { [K: string]: string },
    // @ts-ignore
    params: QueryParam<T>,
    // @ts-ignore
    options: UseMMQueryOptions<ArrayResponse<T>>,
) {
    const [lastValues, setLastValues] = useState(values)
    // @ts-ignore
    const defaultParams = autocompleteDefaultParams as Partial<QueryParam<T>>
    const [autoParams, setAutoParams] = useState(defaultParams)
    // @ts-ignore
    const combinedParams = useMemo<QueryParam<T>>(() => {
        if (!params) return params as any
        return {
            ...autoParams,
            ...params,
            filter: params.filter
                ? { and: [params.filter, autoParams.filter] }
                : autoParams.filter,
        } as any
    }, [autoParams, params])
    const query = useQueryFC<T>(combinedParams, options)
    useEffect(() => {
        if (!values) return
        if (lastValues !== values) {
            if (Object.values(values).every(v => v === "" || !v)) {
                if (autoParams !== defaultParams) {
                    setLastValues(values)
                    setAutoParams(defaultParams)
                }
            } else {
                const paginated =
                    (query.data?.count || 0) > (query.data?.data?.length || 0)
                let update = false
                for (let [key, value] of Object.entries(values)) {
                    if (
                        paginated &&
                        (!lastValues ||
                            !lastValues[key] ||
                            value.length > lastValues[key].length ||
                            !lastValues[key].startsWith(value))
                    ) {
                        update = true
                        break
                    }
                    if (
                        lastValues &&
                        lastValues[key] &&
                        (!value.startsWith(lastValues[key]) ||
                            (value.length < lastValues[key].length &&
                                (autoParams !== defaultParams ||
                                    !lastValues[key].startsWith(value))))
                    ) {
                        update = true
                        break
                    }
                }
                if (update) {
                    setLastValues(values)
                    setAutoParams({
                        ...defaultParams,
                        filter: {
                            or: Object.entries(values).map(([key, value]) => ({
                                name: key,
                                op: "text",
                                val: value,
                            })),
                        },
                    })
                }
            }
        }
    }, [
        autoParams,
        lastValues,
        values,
        defaultParams,
        query.data?.count,
        query.data?.data?.length,
    ])
    return query
}
const autocompleteDefaultParams: Partial<QueryParam> = {
    pageNumber: 1,
    pageSize: 100,
}

/** Query for testing partial matching */
export function useQuerySimilarMaterials(
    material: Sample | undefined,
): UseQueryResult<Sample[]> {
    const [titleQueries, setTitleQueries] = useState<{
        close: QueryParam<Sample>
        fuzzy?: QueryParam<Sample>
    }>()
    const [propsQuery, setPropsQuery] = useState<QueryParam<Sample>>()
    const closeResults = useQueryFC<Sample>(titleQueries?.close, {
        logName: "Check Close Material Titles",
    })
    const fuzzyResults = useQueryFC<Sample>(titleQueries?.fuzzy, {
        logName: "Check Fuzzy Material Titles",
    })
    const propsResults = useQueryFC<Sample>(propsQuery, {
        logName: "Check Props Similar Materials",
    })

    const mergedResults = useMemo(
        () => mergeQueryResults([closeResults, fuzzyResults, propsResults]),
        [closeResults, fuzzyResults, propsResults],
    )

    // get material properties that may uniquely identify it
    const materialCommonProperties = useMemo(
        () =>
            targetProps.reduce((agg, target) => {
                const prop = material?.properties?.find(
                    prop => prop.definition?.title === commonProperties[target],
                )
                if (prop && prop.data !== undefined && prop.data !== null)
                    agg.push(prop)
                return agg
            }, [] as PropertyInstance[]),
        [material?.properties],
    )

    useEffect(() => {
        if (!material?.title) setTitleQueries(undefined)
        else {
            // build query with close match to title or alt name. Separate query to make sure closes match isn't paginated away
            const trimmed = material.title.trim()
            const close: QueryParam<Sample> = {
                Model: "Sample",
                fields: sampleFields,
                sort: "title",
                filter: {
                    or: [
                        {
                            name: "title",
                            op: "ilike",
                            val: trimmed,
                        },
                        {
                            name: "alternateNames",
                            op: "~*",
                            val: `(?:^|\n)${escapeRegExp(trimmed)}(?:\n|$)`,
                        },
                    ],
                },
            }
            let or = []
            const acronymMatch = trimmed.match(/(^[A-Z]{2,5})[0-9]*$/)
            if (acronymMatch) {
                const acronym = acronymMatch[1].split("")
                const query = acronym.reduce((prev, cur) => {
                    return `${prev}${cur}\\w+\\W*`
                }, "\\W*")
                or.push(
                    {
                        name: "title",
                        op: "~*",
                        val: `^${query}$`,
                    },
                    {
                        name: "alternateNames",
                        op: "~*",
                        val: `(?:^|\n)${query}(?:\n|$)`,
                    },
                )
            }
            const firstCharactersMatch = trimmed.match(/(\b[a-z])/gi)
            if (
                firstCharactersMatch &&
                (firstCharactersMatch.length || 0) >= 2
            ) {
                const query = firstCharactersMatch.join().toUpperCase() || ""
                or.push(
                    {
                        name: "title",
                        op: "~*",
                        val: query,
                    },
                    {
                        name: "alternateNames",
                        op: "~*",
                        val: `(?:^|\n)${query}(?:\n|$)`,
                    },
                )
            }

            // partial match
            if (trimmed?.length >= 3) {
                const split = (trimmed.match(/([^0-9\W]+|[0-9]+)/g) || []).map(
                    w => escapeRegExp(w),
                )
                const wordElement = "(?:\\W*\\w+){0,2}\\W*"
                if (split.length) {
                    let query = split.reduce((prev, cur) => {
                        let rtn
                        if (cur.match(/^[0-9]+$/)) {
                            rtn = `${prev}(?<=^|[^0-9])${cur}(?=[^0-9]|$)${wordElement}`
                        } else {
                            rtn = `${prev}${cur}${wordElement}`
                        }
                        return rtn
                    }, wordElement)
                    or.push(
                        {
                            name: "title",
                            op: "~*",
                            val: `^${query}$`,
                        },
                        {
                            name: "alternateNames",
                            op: "~*",
                            val: `(?:^|\n)${query}(?:$|\n)`,
                        },
                    )
                }
                // check for extra or re-ordered words
                if (split.length >= 3) {
                    const query = `(?:${split.join("\\W*|\\W*")}){${
                        split.length - 1
                    },${split.length}}`
                    or.push(
                        {
                            name: "title",
                            op: "~*",
                            val: `^${query}$`,
                        },
                        {
                            name: "alternateNames",
                            op: "~*",
                            val: `(?:^|\n)${query}(?:$|\n)`,
                        },
                    )
                }
            }

            const fuzzy = or.length
                ? ({
                      Model: "Sample",
                      sort: "title",
                      fields: sampleFields,
                      filter: {
                          and: [
                              {
                                  name: "categories",
                                  op: "list",
                                  val: "chemical",
                              },
                              ...materialCommonProperties.map(prop => ({
                                  not: {
                                      name: "properties.definition.id",
                                      op: "eq",
                                      val: prop.definition?.id || "",
                                  },
                              })),
                              { or },
                          ],
                      },
                  } as QueryParam<Sample>)
                : undefined

            setTitleQueries({ close, fuzzy })
        }
    }, [material?.title, materialCommonProperties])

    useEffect(() => {
        if (!materialCommonProperties.length) setPropsQuery(undefined)
        else {
            setPropsQuery({
                Model: "Sample",
                fields: sampleFields,
                filter: {
                    name: "properties",
                    val: {
                        or: materialCommonProperties.map(prop => ({
                            and: [
                                {
                                    name: "definition.id",
                                    op: "eq",
                                    val: prop.definition?.id || "",
                                },
                                {
                                    name: "data" as any,
                                    op: "like",
                                    val: String(prop.data),
                                },
                            ],
                        })),
                    },
                },
            })
        }
    }, [materialCommonProperties])

    const similarMaterials = useMemo(() => {
        if (!mergedResults.isSuccess) return undefined
        const similar: Sample[] = []
        if (closeResults.data?.data?.length)
            similar.push(...closeResults.data.data)
        if (propsResults.data?.data?.length)
            similar.push(...propsResults.data.data)
        if (fuzzyResults.data?.data?.length) {
            const fuzzyData = fuzzyResults.data.data
            const rankings = []
            for (let i = 0; i < fuzzyData.length; ++i) {
                const match = fuzzyData[i]
                let dist = levDist(match.title, material?.title)
                for (const altName of match.alternateNames || []) {
                    dist = Math.min(levDist(altName, material?.title), dist)
                }
                rankings.push({ i, dist })
            }
            rankings.sort((a, b) => {
                if (a.dist === b.dist) return 0
                if (a.dist < b.dist) return -1
                return 1
            })
            similar.push(...rankings.map(r => fuzzyData[r.i]))
        }
        return similar.filter((sample, i, arr) => {
            if (sample.id === material?.id) return false
            if (arr.findIndex(s => s.id === sample.id) !== i) return false
            return true
        })
    }, [
        mergedResults.isSuccess,
        closeResults.data?.data,
        propsResults.data?.data,
        fuzzyResults.data?.data,
        material?.title,
        material?.id,
    ])

    return useMemo(
        () => ({ ...mergedResults, data: similarMaterials }),
        [mergedResults, similarMaterials],
    ) as UseQueryResult<Sample[]>
}
const sampleFields: Field<Sample>[] = ["id", "title"]
const targetProps: (keyof typeof commonProperties)[] = [
    "casId",
    "pubChemId",
    "_3MId",
    "smiles",
]

/** Get user Roles hook wrapper */
export function useQueryRoles() {
    return useQueryBase(["UserRoles"], getUserRoles, { logName: "User Roles" })
}

/**
 * useEffect for logging query calls and state changes to the console.
 * To limit logging to debug builds only, call useDebugLogEffect instead.
 */
function useLogEffect(
    query: UseQueryResult,
    queryKey: any,
    options?: UseMMQueryOptions<any, any>,
) {
    const prevQuery = useRef(query)
    useEffect(() => {
        if (
            (query.status !== prevQuery.current?.status ||
                query.data !== prevQuery.current?.data) &&
            queryKey &&
            !options?.noLog
        ) {
            const logName =
                options?.logName ||
                (typeof queryKey === "string"
                    ? queryKey
                    : queryKey[1]?.Model || queryKey[0]) ||
                "-"
            console.log("Query - %s - %s: %O", logName, query.status, {
                queryKey,
                data: query.data,
            })
        }
        prevQuery.current = query
    }, [query, queryKey, options?.logName, options?.noLog])
}
export const useDebugLogEffect =
    process.env.NODE_ENV === "production" ? () => {} : useLogEffect

/** merges values from an array of UseQueryResult. Replaces data with suppled data */
function mergeQueryResults<T = unknown>(queries: UseQueryResult[], data?: T) {
    const [first, ...rest] = queries
    const rtn = rest.reduce(
        (agg, q) => {
            if (
                statusPriority.indexOf(q.status) >
                statusPriority.indexOf(agg.status)
            )
                agg.status = q.status
            agg.isLoadingError = q.isLoadingError || agg.isLoadingError
            agg.isRefetchError = q.isRefetchError || agg.isRefetchError
            agg.dataUpdatedAt = Math.max(agg.dataUpdatedAt, q.dataUpdatedAt)
            if (!agg.error) {
                agg.error = q.error
                agg.errorUpdatedAt = q.errorUpdatedAt
            }
            agg.isStale = q.isStale || agg.isStale
            agg.isPreviousData = q.isPreviousData
            agg.isFetched = q.isFetched && agg.isFetched
            agg.isFetchedAfterMount =
                q.isFetchedAfterMount && agg.isFetchedAfterMount
            agg.isFetching = q.isFetching || agg.isFetching
            agg.failureCount += q.failureCount

            return agg
        },
        { ...first },
    ) as UseQueryResult<T>
    rtn.isIdle = rtn.status === "idle"
    rtn.isLoading = rtn.status === "loading"
    rtn.isError = rtn.status === "error"
    rtn.isSuccess = rtn.status === "success"
    rtn.refetch = (...args) =>
        Promise.all(queries.map(q => q.refetch(...args))).then(res =>
            mergeQueryResults(res),
        )
    rtn.remove = () => queries.forEach(q => q.remove())
    rtn.data = data
    return rtn
}
const statusPriority = ["idle", "success", "loading", "error"]

export function useCheckReadOnly(
    initialObject?: { labs?: { lab?: { id?: string } }[] },
    object?: { id?: string },
) {
    const { data: roles } = useQueryRoles()
    return useMemo(() => {
        if (!roles) return true
        if (!object?.id) return false
        const labs = initialObject?.labs
        return !labs?.find(
            (lab: any) =>
                roles?.user_labs.indexOf(lab?.lab?.id) >= 0 ||
                roles?.admin_labs.indexOf(lab?.lab?.id) >= 0,
        )
    }, [initialObject, object, roles])
}

/*
 * Function for fetching a list of pages and return the result of the queries
 */
export function useQueryPagination<T = unknown>(
    pagesRequested: number,
    // @ts-ignore
    rootQuery: QueryParam<T> | undefined,
    options?: UseMMQueryOptions<T | undefined>,
    placeholder?: any,
) {
    const { enqueueSnackbar } = useSnackbar()

    const pageSize = rootQuery?.pageSize || 50

    const queryOptions = useMemo(() => {
        const { errorMessage, logName, noLog, ...queryOptions } = options || {}

        if (rootQuery) {
            queryOptions.onError = (error: any) => {
                const message = errorMessage
                    ? errorMessage(error)
                    : `${rootQuery.Model || ""} Array API Error`
                if (errorMessage) enqueueSnackbar(message, { variant: "error" })
                if (options?.onError) options.onError(error)
            }
            return queryOptions
        }
    }, [enqueueSnackbar, options, rootQuery])

    const queries = useMemo<QueryOptions[]>(() => {
        if (!rootQuery) return []
        const commonQueryParams = rootQuery

        const queries: any = []
        for (let page = 1; page <= pagesRequested; ++page) {
            const queryParam = {
                ...commonQueryParams,
                pageNumber: page,
            }
            queries.push({
                queryKey: [firecrackerKey, queryParam],
                queryFn: () => getQueryFC(queryParam as any),
                ...queryOptions,
            } as any)
        }
        return queries
    }, [pagesRequested, queryOptions, rootQuery]) as any

    const raw = useQueries(queries)
    const previousRef: any = useRef()
    const results: any = useMemo(() => {
        const count =
            (raw?.find(r => (r?.data as any)?.count !== undefined)?.data as any)
                ?.count || 0
        const dataArray = []
        for (let i = 0; i < pagesRequested; ++i) {
            for (let j = 0; j < pageSize; ++j) {
                if (i * pageSize + j >= count) break
                dataArray.push(
                    (raw?.[i]?.data as any)?.data?.[j] || placeholder,
                )
            }
        }
        const data: any = {
            data: dataArray,
            count,
        }
        if (
            previousRef.current &&
            previousRef.current.count === data.count &&
            data.data?.length === previousRef.current?.data?.length &&
            data.data?.every(
                (v: any, i: any) => v === previousRef.current?.data?.[i],
            )
        )
            return previousRef.current
        previousRef.current = data
        return data
    }, [pageSize, pagesRequested, placeholder, raw])

    const merged = mergeQueryResults(raw, results)
    const params = useMemo(
        () => ({ pagesRequested, rootQuery }),
        [pagesRequested, rootQuery],
    )
    useDebugLogEffect(results, params, options)

    return merged
}
