import React, { useMemo } from 'react'
import TestMultiPlot from './TestMultiplot';

function MultiplotOfMultiplots({ tests, title }) {

    const plots = useMemo(() => {
        if (!tests?.length) return []
        const plotMap = new Map()
        for (const test of tests) {
            let key = test.processor
            if (!key) {
                key = test.meta ? "etly.tabular.default" : "etly.tabular.image"
            }
            let current = plotMap.get(key)
            if (!current) {
                current = new Set()
                plotMap.set(key, current)
            }
            current.add(test.id)
        }
        return Array.from(plotMap)
    }, [tests])

    return (
        <div>
            {plots.map(([key, ids], index) => {
                // Compatibility with distillery for multiplotting
                let parser = key.toUpperCase();
                if (key.includes(".")) {
                    parser = key.split('.')[2].toUpperCase();
                }
                const titlePlot = title + `:  ${parser}`
                return (
                    <TestMultiPlot
                        key={index}
                        {...{ resource: "tests", selectedIds: Array.from(ids), title: titlePlot, useTitle: true }}
                    />
                )
            })}
        </div>
    )
}

export default React.memo(MultiplotOfMultiplots);