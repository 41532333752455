/**
 * @format
 */

import { saveNonImageFile } from "utils/utils"
import { applyNodeAlias } from "utils/schemaUtils"
import { ProcessChart, SampleSet } from "schema/models"
import { themeBase } from "styles/themes"
import { getQueryFC } from "API/firecrackerAPI"
import {
    getDefinition,
    getDefKey,
    getProcessChartDefinition,
    updateColumnsFromProcessNodes,
    updateProcessNodesFromSamples,
} from "components/FlowChart/processChartUtils"
import { firecrackerKey, queryClient } from "API/queryHooks"

export default async function exportExcelSet({ sampleSet }) {
    return import("exceljs").then(async exceljs => {
        const workbook = new exceljs.Workbook()
        workbook.creator = sampleSet.creatorName || "Unsaved Set Author"
        workbook.lastModifiedBy =
            sampleSet.modifierName || "Unsaved Set Modifier"

        // this is to bypass the Date. Namely, to export unsaved sample sets w/o polluting the DB!
        // always undefined if unsaved
        if (typeof sampleSet.timeCreated === "undefined") {
            console.log("This is an export of an unsaved set.. proceeding...")

            sampleSet.timeCreated = new Date()
            sampleSet.timeModified = new Date()

            if (typeof sampleSet.title === "undefined") {
                sampleSet.title = "Unsaved Set"
            } else {
                sampleSet.title = "Unsaved Set:" + sampleSet.title
            }
        }

        workbook.created = new Date(sampleSet.timeCreated)
        workbook.modified = new Date(sampleSet.timeModified)

        const setWorksheet = workbook.addWorksheet("Set Summary")
        const samplesWorksheet = workbook.addWorksheet("Samples", {
            views: [{ state: "frozen", xSplit: 2, ySplit: 4 }],
        })
        const componentsWorksheet = workbook.addWorksheet("Components", {
            views: [{ state: "frozen", xSplit: 2, ySplit: 4 }],
        })

        fillSampleSetSummarySheet({ sampleSet, worksheet: setWorksheet })
        await fillSampleSheet({
            sampleSet,
            worksheet: samplesWorksheet,
            workbook,
        })
        await fillComponentSheet({
            sampleSet,
            worksheet: componentsWorksheet,
        })

        workbook.xlsx.writeBuffer({ base64: true }).then(xlsx64 => {
            const blob = new Blob([xlsx64], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
            saveNonImageFile(blob, `${sampleSet.title || "Formulation"}.xlsx`)
        })
    })
}

function fillSampleSetSummarySheet({ sampleSet, worksheet }) {
    const info = [
        {
            title: sampleSet.title || "Sample Set",
            accessor: set => set.title || "SampleSet",
        },
        { title: "Samples", accessor: set => set.samples?.length || 0 },
        { title: "Description", accessor: set => set.description },
        { title: "Last Modified", accessor: set => new Date(set.timeModified) },
        { title: "Modifier", accessor: set => set.modifierName },
        { title: "Created", accessor: set => new Date(set.timeCreated) },
        { title: "Creator", accessor: set => set.creatorName },
        { title: "Contributors", accessor: set => set.contributors },
        {
            title: "Link",
            accessor: set =>
                set.id
                    ? {
                          text: `${window.location.hostname}/SampleSetLibrary/${set.id}`,
                          hyperlink: `https://${window.location.hostname}/SampleSetLibrary/${set.id}`,
                          tooltip: `${window.location.hostname}/SampleSetLibrary/${set.id}`,
                      }
                    : "",
        },
    ]

    for (const i of info) {
        worksheet.addRow([i.title, i.accessor(sampleSet)])
    }

    worksheet.getColumn(1).font = { bold: true }
    worksheet.getColumn(1).width = 15
    worksheet.getColumn(1).alignment = {
        vertical: "top",
    }

    worksheet.getColumn(2).width = 50
    worksheet.getColumn(2).alignment = {
        horizontal: "left",
        wrapText: true,
    }

    worksheet.mergeCells("A1:B1")
    const header = worksheet.getCell("A1")
    header.alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    header.border = {
        top: { style: "medium" },
        left: { style: "medium" },
        bottom: { style: "medium" },
        right: { style: "medium" },
    }
    header.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE0E0E0" },
    }
}

async function queryDefs(Model, processChart) {
    if (!processChart?.nodes) return []
    const defIds = new Set()
    const key =
        Model === "Sample"
            ? "materialDefs"
            : Model === "ProcessDefinition"
            ? "processDefs"
            : "propertyDefs"
    for (const node of Object.values(processChart.nodes)) {
        if (node[key]) {
            for (const id of Object.keys(node[key] || {})) {
                defIds.add(id)
            }
        }
    }
    const ids = Array.from(defIds)
    if (!ids?.length) return []
    const queryParam = {
        Model,
        filter: ids,
        fields: Model === "Sample" ? ["properties.definition"] : undefined,
    }
    let vals = undefined
    try {
        vals = await queryClient.fetchQuery({
            queryKey: [firecrackerKey, queryParam],
            queryFn: () => getQueryFC(queryParam),
        })
    } catch (error) {
        console.log(error)
    }
    return vals?.data || []
}

async function fillSampleSheet({
    sampleSet,
    worksheet,
    workbook,
    prefixColumns = [],
}) {
    let processChart = sampleSet.processChart
    if (!processChart?.nodes) {
        processChart = updateProcessNodesFromSamples(
            new ProcessChart(),
            sampleSet.samples,
        )
    }

    const materialDefs = await queryDefs("Sample", processChart)
    const processDefs = await queryDefs("ProcessDefinition", processChart)
    const propertyDefs = await queryDefs("PropertyDefinition", processChart)

    if (!processChart.columns) {
        processChart.columns = updateColumnsFromProcessNodes(
            [],
            processChart.nodes,
            materialDefs,
            processDefs,
            propertyDefs,
        )
    }

    const columns = processChart.columns || []

    const groupRow = worksheet.getRow(1)
    const headerRow = worksheet.getRow(2)
    const varRow = worksheet.getRow(3)
    const unitsRow = worksheet.getRow(4)
    const sampleRows =
        sampleSet.samples?.map((sample, i) => worksheet.getRow(i + 5)) || []

    groupRow.getCell(1).value = "Identifiers"
    groupRow.getCell(1).font = { bold: true }
    groupRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    groupRow.getCell(1).border = {
        top: { style: "medium" },
        left: { style: "medium" },
        bottom: { style: "medium" },
        right: { style: "medium" },
    }
    groupRow.getCell(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE0E0E0" },
    }
    worksheet.mergeCells(1, 1, 1, 2)

    headerRow.getCell(1).value = "Sample"
    headerRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    headerRow.getCell(2).value = "Sample"
    headerRow.getCell(2).alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    headerRow.getCell(2).border = {
        right: { style: "thin", color: { argb: "FF212121" } },
    }

    varRow.getCell(1).value = "QID"
    varRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    varRow.getCell(2).value = "Name"
    varRow.getCell(2).alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    varRow.getCell(2).border = {
        right: { style: "thin", color: { argb: "FF212121" } },
    }

    unitsRow.getCell(1).value = ""
    unitsRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    unitsRow.getCell(2).value = ""
    unitsRow.getCell(2).alignment = {
        vertical: "middle",
        horizontal: "center",
    }
    unitsRow.getCell(2).border = {
        right: { style: "thin", color: { argb: "FF212121" } },
    }

    sampleRows.forEach((row, i) => {
        row.getCell(1).value = sampleSet.samples[i].qid || ""
        row.getCell(2).value = sampleSet.samples[i].title || ""
        row.getCell(2).border = {
            right: { style: "thin", color: { argb: "FF212121" } },
        }
    })

    let colIndex = 3

    for (const col of prefixColumns) {
        groupRow.getCell(colIndex).value = col.group
        groupRow.getCell(colIndex).font = { bold: true }
        groupRow.getCell(colIndex).alignment = {
            vertical: "middle",
            horizontal: "center",
        }
        groupRow.getCell(colIndex).border = {
            top: { style: "medium" },
            left: { style: "medium" },
            bottom: { style: "medium" },
            right: { style: "medium" },
        }
        groupRow.getCell(colIndex).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFE0E0E0" },
        }

        headerRow.getCell(colIndex).value = col.name
        headerRow.getCell(colIndex).alignment = {
            vertical: "middle",
            horizontal: "center",
        }
        headerRow.getCell(colIndex).border = {
            right: { style: "thin", color: { argb: "FF212121" } },
        }

        unitsRow.getCell(colIndex).value = col.units
        unitsRow.getCell(colIndex).alignment = {
            vertical: "middle",
            horizontal: "center",
        }
        unitsRow.getCell(colIndex).border = {
            right: { style: "thin", color: { argb: "FF212121" } },
        }

        for (let i = 0; i < sampleRows.length; ++i) {
            const row = sampleRows[i]
            row.getCell(colIndex).value = col.data[i]
            row.getCell(colIndex).border = {
                right: { style: "thin", color: { argb: "FF212121" } },
            }
        }
        colIndex++
    }

    const groupMerges = {}
    let lastHeader
    let startIndex
    const defMerges = {}
    let lastDef
    let startDefIndex
    for (const col of columns) {
        const nodeLabel =
            processChart.nodes?.[col.nodeId]?.label || "*Unlabelled*"
        const processChartDef = getProcessChartDefinition(
            col,
            processChart.nodes,
        )
        const definition = getDefinition(
            col,
            materialDefs,
            processDefs,
            propertyDefs,
        )
        const defTitle =
            processChartDef?.title || definition?.title || "*Missing Label*"
        const count = processChartDef?.count || 1
        const column = processChartDef?.cols?.[col.colKey]
        const defKey = getDefKey(col)

        let color = undefined
        let units = ""
        if (defKey === "materialDefs") {
            color = { argb: "FF43a047" }
            units = column?.units || "g" // this autodefines to "g", column is always null when logged
        } else if (defKey === "processDefs") {
            color = { argb: "FF2196f3" }
            units =
                column?.units ||
                definition.processVariables?.some(
                    procVar => procVar.title === col.colKey,
                )
                    ? definition.processVariables?.find(
                          procVar => procVar.title === col.colKey,
                      ).units
                    : ""
        } else if (defKey === "propertyDefs") {
            color = { argb: "FFf4511e" }
            units = column?.units || definition.units || ""
        }
        for (let index = 0; index < count; index++) {
            if (lastHeader !== col.nodeId || lastDef !== col.defId) {
                defMerges[colIndex] = 0
                lastDef = col.defId
                startDefIndex = colIndex
            } else {
                defMerges[startDefIndex]++
            }
            if (lastHeader !== col.nodeId) {
                groupMerges[colIndex] = 0
                lastHeader = col.nodeId
                startIndex = colIndex
            } else {
                groupMerges[startIndex]++
            }

            const groupCell = groupRow.getCell(colIndex)
            groupCell.value = nodeLabel || ""
            groupCell.alignment = {
                vertical: "middle",
                horizontal: "center",
            }
            groupCell.font = { color, bold: true }
            groupCell.border = {
                top: { style: "medium", color },
                left: { style: "medium", color },
                bottom: { style: "medium", color },
                right: { style: "medium", color },
            }
            groupCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFE0E0E0" },
            }

            const headerCell = headerRow.getCell(colIndex)
            headerCell.value = defTitle || ""
            headerCell.alignment = {
                vertical: "middle",
                horizontal: "center",
            }

            const varCell = varRow.getCell(colIndex)
            varCell.value = col.colKey || ""
            varCell.alignment = {
                vertical: "middle",
                horizontal: "center",
            }

            const unitsCell = unitsRow.getCell(colIndex)
            unitsCell.value = units
            unitsCell.alignment = {
                vertical: "middle",
                horizontal: "center",
            }

            for (let i = 0; i < sampleRows.length; ++i) {
                const dataCell = sampleRows[i].getCell(colIndex)
                const sample = sampleSet.samples[i]
                if (defKey === "materialDefs") {
                    let value = getComponentAmount(
                        sample,
                        col.nodeId,
                        col.defId,
                        index,
                        sampleSet.nodeAliases,
                    )
                    // Some values are truly NaN (divide by 0, etc.), but others are chemical formulas
                    const testNaN = `${value}`
                    if (
                        value === null ||
                        value === undefined ||
                        (isNaN(value) && testNaN === "NaN")
                    )
                        value = ""
                    dataCell.value = value
                } else if (defKey === "processDefs") {
                    let value = getProcessVar(
                        sample,
                        col.nodeId,
                        col.defId,
                        index,
                        col.colKey,
                        sampleSet.nodeAliases,
                    )
                    // Some values are truly NaN (divide by 0, etc.), but others are chemical formulas
                    const testNaN = `${value}`
                    if (
                        value === null ||
                        value === undefined ||
                        (isNaN(value) && testNaN === "NaN")
                    )
                        value = ""
                    dataCell.value = value
                } else if (defKey === "propertyDefs") {
                    let value = getProperty(
                        sample,
                        col.nodeId,
                        col.defId,
                        index,
                        sampleSet.nodeAliases,
                    )
                    // Some values are truly NaN (divide by 0, etc.), but others are chemical formulas
                    const testNaN = `${value}`
                    if (
                        value === null ||
                        value === undefined ||
                        (isNaN(value) && testNaN === "NaN")
                    )
                        value = ""
                    dataCell.value = value
                }
            }

            colIndex++
        }
    }

    for (const [start, span] of Object.entries(groupMerges)) {
        if (span > 0) {
            const v = Number(start)
            worksheet.mergeCells(1, v, 1, v + span)
        }
    }
    for (const [start, span] of Object.entries(defMerges)) {
        if (span > 0) {
            const v = Number(start)
            worksheet.mergeCells(2, v, 2, v + span)
        }
    }

    // write additional sample information
    const additionalSampleKeys = [
        { title: "Last Modifier", accessor: sample => sample.modifierName },
        {
            title: "Last Modified",
            accessor: sample => new Date(sample.timeModified),
        },
        { title: "Creator", accessor: sample => sample.creatorName },
        { title: "Created", accessor: sample => new Date(sample.timeCreated) },
        {
            title: "Link",
            accessor: sample =>
                sample.id
                    ? {
                          text: `${window.location.hostname}/MaterialLibrary/${sample.id}`,
                          hyperlink: `https://${window.location.hostname}/MaterialLibrary/${sample.id}`,
                          tooltip: `${window.location.hostname}/MaterialLibrary/${sample.id}`,
                      }
                    : "",
        },
        { title: "Notes", accessor: sample => sample.notes || " " },
    ]

    if (additionalSampleKeys.length) {
        const end = additionalSampleKeys.length + colIndex
        const start = colIndex
        for (colIndex; colIndex < end; colIndex++) {
            const groupCell = groupRow.getCell(colIndex)
            const additional = additionalSampleKeys[colIndex - start]
            groupCell.value = "Additional Information"
            groupCell.alignment = {
                vertical: "middle",
                horizontal: "center",
            }
            groupCell.font = { bold: true }
            groupCell.border = {
                top: { style: "medium" },
                left: { style: "medium" },
                bottom: { style: "medium" },
                right: { style: "medium" },
            }
            groupCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFE0E0E0" },
            }

            const headerCell = headerRow.getCell(colIndex)
            headerCell.value = additional?.title || "*Missing Label*"
            headerCell.alignment = {
                vertical: "middle",
                horizontal: "center",
            }
            if (colIndex === end - 1) {
                headerCell.border = {
                    right: { style: "thin", color: { argb: "FF212121" } },
                }
            }
            const varCell = varRow.getCell(colIndex)
            const unitsCell = unitsRow.getCell(colIndex)
            unitsCell.value = additional.units || ""
            unitsCell.alignment = {
                vertical: "middle",
                horizontal: "center",
            }
            if (colIndex === end - 1) {
                unitsCell.border = {
                    right: { style: "thin", color: { argb: "FF212121" } },
                }
                varCell.border = {
                    right: { style: "thin", color: { argb: "FF212121" } },
                }
            }

            for (let i = 0; i < sampleRows.length; ++i) {
                const dataCell = sampleRows[i].getCell(colIndex)
                if (colIndex === end - 1) {
                    dataCell.border = {
                        right: { style: "thin", color: { argb: "FF212121" } },
                    }
                }
                dataCell.value = additional.accessor(sampleSet.samples[i])
            }
        }
        if (start < end) worksheet.mergeCells(1, start, 1, end - 1)
    }

    // if (workbook && flowChart && Object.keys(flowChart.nodes).length > 1) {
    //     const canvas = flowChartCanvas(flowChart)
    //     const objectURL = canvas.toDataURL()
    //     const imageId = workbook.addImage({
    //         base64: objectURL,
    //         extension: "png",
    //     })
    //     worksheet.addImage(imageId, {
    //         tl: { col: 3, row: 4 + (sampleSet.samples?.length || 0) },
    //         ext: { width: canvas.width, height: canvas.height },
    //     })
    // }
}

async function fillComponentSheet({ sampleSet, worksheet }) {
    // get all components
    const addedComponents = {}
    let units = ""

    if (sampleSet.samples) {
        for (const sample of sampleSet.samples) {
            if (sample.components) {
                for (const component of sample.components) {
                    if (component.definition?.id) {
                        addedComponents[component.definition.id] =
                            (addedComponents[component.definition.id] || 0) +
                            component.amount
                        if (!units) units = component.units || "g"
                        else if (component.units) units = "Mixed"
                    }
                }
            }
        }
    }
    if (sampleSet.processChart) {
        for (const node of Object.values(sampleSet.processChart.nodes || {})) {
            if (node.materialDefs) {
                for (const id of Object.keys(node.materialDefs || {})) {
                    addedComponents[id] = addedComponents[id] || 0
                }
            }
        }
    }

    const { data: components } = (Object.keys(addedComponents).length
        ? await getQueryFC({
              Model: "Sample",
              fields: ["properties.definition"],
              filter: Object.keys(addedComponents),
          })
        : { data: [] }) || { data: [] }

    components.sort((a, b) => (a.title < b.title ? -1 : 1))

    const componentSet = new SampleSet({ samples: components })

    await fillSampleSheet({
        sampleSet: componentSet,
        worksheet,
    })
}

export function flowChartCanvas(
    flowChart,
    { width = 100, height = 16 } = {},
    scale = 2,
) {
    const nodes = Object.values(flowChart.nodes)
    let top, left, bottom, right
    for (const node of nodes) {
        if (top === undefined || node.position.y < top) top = node.position.y
        if (bottom === undefined || node.position.y > bottom)
            bottom = node.position.y
        if (left === undefined || node.position.x < left) left = node.position.x
        if (right === undefined || node.position.x > right)
            right = node.position.x
    }
    const canvas = document.createElement("canvas")
    canvas.setAttribute("height", (bottom - top + height) * scale)
    canvas.setAttribute("width", (right - left + width) * scale)
    const ctx = canvas.getContext("2d")
    for (const node of nodes) {
        const color = themeBase.palette[node.type]?.dark
        ctx.strokeStyle = color || "#EEEEEE"
        ctx.fillStyle = color || "#EEEEEE"
        roundRect(
            ctx,
            node.position.x * scale,
            node.position.y * scale,
            width * scale,
            height * scale,
            4,
            true,
        )
        ctx.font = `sans-serif ${0.75 * scale}rem`
        ctx.fillStyle = "#FFFFFF"
        ctx.textAlign = "center"
        ctx.textBaseline = "middle"
        ctx.fillText(
            node.properties.label,
            node.position.x * scale + (width * scale) / 2,
            node.position.y * scale + (height * scale) / 2,
            width * scale - 8 * scale,
        )
    }
    for (const link of Object.values(flowChart.links)) {
        if (link.to?.nodeId && link.from?.nodeId) {
            const start = flowChart.nodes[link.from.nodeId].position
            const end = flowChart.nodes[link.to.nodeId].position
            ctx.strokeStyle = themeBase.palette.primary.main
            ctx.lineWidth = 2
            ctx.moveTo(
                start.x * scale + width * scale,
                start.y * scale + (height * scale) / 2,
            )
            ctx.lineTo(end.x * scale, end.y * scale + (height * scale) / 2)
            ctx.stroke()
        }
    }
    return canvas
}

function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof stroke === "undefined") {
        stroke = true
    }
    if (typeof radius === "undefined") {
        radius = 5
    }
    if (typeof radius === "number") {
        radius = { tl: radius, tr: radius, br: radius, bl: radius }
    } else {
        var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 }
        for (var side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side]
        }
    }
    ctx.beginPath()
    ctx.moveTo(x + radius.tl, y)
    ctx.lineTo(x + width - radius.tr, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
    ctx.lineTo(x + width, y + height - radius.br)
    ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius.br,
        y + height,
    )
    ctx.lineTo(x + radius.bl, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl)
    ctx.lineTo(x, y + radius.tl)
    ctx.quadraticCurveTo(x, y, x + radius.tl, y)
    ctx.closePath()
    if (fill) {
        ctx.fill()
    }
    if (stroke) {
        ctx.stroke()
    }
}

function getComponentAmount(sample, nodeId, defId, index, nodeAliases) {
    const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
    return sample?.components
        ?.filter(
            inst =>
                (inst.nodeId === nodeIdAlias || (!inst.nodeId && !nodeId)) &&
                inst.definition?.id === defId,
        )
        [index]?.amount?.toPrecision(6)
}
function getProcessVar(sample, nodeId, defId, index, colKey, nodeAliases) {
    const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
    return sample?.processSteps?.filter(
        inst =>
            (inst.nodeId === nodeIdAlias || (!inst.nodeId && !nodeId)) &&
            inst.definition?.id === defId,
    )[index]?.data?.[colKey]
}
function getProperty(sample, nodeId, defId, index, nodeAliases) {
    const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
    return sample?.properties?.filter(
        inst =>
            (inst.nodeId === nodeIdAlias || (!inst.nodeId && !nodeId)) &&
            inst.definition?.id === defId,
    )[index]?.data
}
