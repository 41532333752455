/**
 * @format
 */
import React, { useState, useMemo } from "react"
import TreeView from "@material-ui/lab/TreeView"
import TreeItem from "@material-ui/lab/TreeItem"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import { makeStyles } from "@material-ui/core"
import ChevronRightIcon from "mdi-material-ui/ChevronRightBox"
import ExpandMoreIcon from "mdi-material-ui/ChevronDownBox"
import ChevronRightOutlineIcon from "mdi-material-ui/ChevronRightBoxOutline"
import ExpandMoreOutlineIcon from "mdi-material-ui/ChevronDownBoxOutline"
import { SerializedColumn } from "schema/SerializedColumn"

const useStyles = makeStyles(theme => ({
    add: {
        color: theme.palette.text.secondary,
    },
    item: {
        //paddingBottom: theme.spacing(0.5),
    },
    treeContainer: {
        padding: theme.spacing(1),
        maxHeight: "400px",
        overflow: "auto",
        minHeight: 70,
        border: "1px solid",
        borderRadius: theme.spacing(0.5),
        borderColor:
            theme.palette.type === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
        "&:hover": {
            borderColor: theme.palette.text.primary,
        },
        "&:focus-within": {
            borderColor: theme.palette.primary.main,
        },
    },
}))

export function ColumnsTree({
    columnDefinitions,
    columns,
    tableColumns,
    handleColumnCheck,
}) {
    const classes = useStyles()

    const selectedGroups = useMemo(
        () =>
            columns.reduce((sel, col) => {
                if (!sel.find(s => s === col.groupId)) sel.push(col.groupId)
                if (col.dynamicId && !sel.find(s => s === col.dynamicId))
                    sel.push(col.dynamicId + "__group")
                return sel
            }, []),
        [columns],
    )

    const [expanded, setExpanded] = useState(selectedGroups)

    return (
        <div className={classes.treeContainer}>
            <TreeView
                expanded={expanded}
                onNodeToggle={(ev, nodeIds) => setExpanded(nodeIds)}
                disableSelection
                defaultCollapseIcon={<ExpandMoreOutlineIcon />}
                defaultExpandIcon={<ChevronRightOutlineIcon />}
            >
                {columnDefinitions.map(group => {
                    const groupSelected = !!columns.find(
                        c => c.groupId === group.id,
                    )
                    return (
                        <TreeItem
                            className={classes.item}
                            key={group.id}
                            nodeId={group.id}
                            label={group.Header}
                            expandIcon={
                                groupSelected ? (
                                    <ChevronRightIcon color="action" />
                                ) : undefined
                            }
                            collapseIcon={
                                groupSelected ? (
                                    <ExpandMoreIcon color="action" />
                                ) : undefined
                            }
                        >
                            {group.columns.map(col => {
                                const colId = col.id || col.accessor
                                return (
                                    <TreeItem
                                        className={classes.item}
                                        key={colId}
                                        onClick={() =>
                                            handleColumnCheck(
                                                new SerializedColumn({
                                                    groupId: group.id,
                                                    colId,
                                                }),
                                            )
                                        }
                                        nodeId={colId}
                                        label={col.Header}
                                        endIcon={
                                            !!columns.find(
                                                c => c.colId === colId,
                                            ) ? (
                                                <CheckBoxIcon
                                                    size="small"
                                                    color="primary"
                                                />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon size="small" />
                                            )
                                        }
                                    />
                                )
                            })}
                            {group.dynamic?.map(dynamicCol => {
                                const dynamicSelected = !!columns.find(
                                    c => c.dynamicId === dynamicCol.id,
                                )
                                return (
                                    <TreeItem
                                        className={classes.item}
                                        key={dynamicCol.id + "__group"}
                                        nodeId={dynamicCol.id + "__group"}
                                        label={dynamicCol.Header}
                                        expandIcon={
                                            dynamicSelected ? (
                                                <ChevronRightIcon color="action" />
                                            ) : undefined
                                        }
                                        collapseIcon={
                                            dynamicSelected ? (
                                                <ExpandMoreIcon color="action" />
                                            ) : undefined
                                        }
                                    >
                                        {columns
                                            .filter(
                                                c =>
                                                    c.dynamicId ===
                                                    dynamicCol.id,
                                            )
                                            .map(serialCol => {
                                                const col = tableColumns.find(
                                                    tc =>
                                                        serialCol.colId ===
                                                        (tc.id || tc.accessor),
                                                )
                                                if (!col) return null
                                                return (
                                                    <TreeItem
                                                        className={classes.item}
                                                        key={serialCol.colId}
                                                        onClick={() =>
                                                            handleColumnCheck(
                                                                new SerializedColumn(
                                                                    {
                                                                        colId:
                                                                            col.id ||
                                                                            col.accessor,
                                                                    },
                                                                ),
                                                            )
                                                        }
                                                        nodeId={serialCol.colId}
                                                        label={col.Header}
                                                        endIcon={
                                                            <CheckBoxIcon
                                                                size="small"
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                )
                                            })}
                                        <dynamicCol.Component
                                            label="Add more..."
                                            nodeId={dynamicCol.id + "__add"}
                                            onAdd={args =>
                                                handleColumnCheck(
                                                    args.map(dynamic => {
                                                        const tempCol = dynamicCol.make(
                                                            dynamic,
                                                        )
                                                        return new SerializedColumn(
                                                            {
                                                                groupId:
                                                                    group.id,
                                                                dynamicId:
                                                                    dynamicCol.id,
                                                                dynamic,
                                                                colId:
                                                                    tempCol.id ||
                                                                    tempCol.accessor,
                                                            },
                                                        )
                                                    }),
                                                )
                                            }
                                        />
                                    </TreeItem>
                                )
                            })}
                        </TreeItem>
                    )
                })}
            </TreeView>
        </div>
    )
}
