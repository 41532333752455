import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { TextField, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import ButtonWithSpinner from '../General/ButtonWithSpinner';
import { useQueryPubChem } from '../../API/queryHooks';
import commonProperties from '../PropertyLibrary/commonProperties';

function ImportInternal({ material, onClose }) {
    const initID = useMemo(() =>
        material.properties?.find(p => p.definition?.title === commonProperties.pubChemId)?.data ||
        material.properties?.find(p => p.definition?.title === commonProperties.casId)?.data ||
        ""
        , [material.properties]);
    const [importId, setImportId] = useState(initID);
    const [params, setParams] = useState();
    const [replace, setReplace] = useState(false); // UFBB story 210 wants a different default state.
    const { data: latestData, isFetching } = useQueryPubChem(params);
    useEffect(() => {
        if (!!latestData) {
            setImportId("");
            setParams(undefined);
            onClose(latestData, replace);
        }
    }, [latestData, replace, onClose]);
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <TextField
                    variant="outlined"
                    label="PubChem or CAS ID"
                    size="small"
                    fullWidth
                    value={importId}
                    onChange={ev => setImportId(ev.target.value)} />
            </Grid>
            <Grid item xs={6}>
                <ButtonWithSpinner
                    color="primary"
                    variant="contained"
                    loading={isFetching}
                    disabled={importId === ""}
                    onClick={() => setParams(importId)}
                >
                    Import From PubChem
                </ButtonWithSpinner>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox color="primary" checked={replace} onChange={ev => setReplace(ev.target.checked)} />}
                    label="Replace Current"
                />
            </Grid>
        </Grid>
    )
}

export default function ImportProperties({ onClose, material, ...other }) {

    return (
        <Dialog
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            onClose={onClose}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">Import Properties</DialogTitle>
            <DialogContent>
                <ImportInternal onClose={onClose} material={material} />
            </DialogContent >
            <DialogActions>
                <Button onClick={() => onClose(false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}