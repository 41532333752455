/**
 * @format
 */
import DateCell from "../../ReactTable/DateCell"
import { serializedColumnsFromIds } from "../../ReactTable/reactTableUtils"
import DateFilterInput from "../../ReactTable/DateFilterInput"

export const modelsColumns = [
    {
        Header: "Model Fields",
        id: "model",
        columns: [
            {
                Header: "Model Name",
                id: "model_title",
                accessor: "model_title",
                filterTransform(filter) {
                    return {
                        or: [
                            {
                                name: "model_title",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type,
                            },
                        ],
                    }
                },
            },
            {
                Header: "Description",
                id: "model_description",
                accessor: "model_description",
                filterTransform(filter) {
                    return {
                        or: [
                            {
                                name: "model_description",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type,
                            },
                        ],
                    }
                },
            },
            {
                Header: "Role",
                id: "model_role",
                accessor: "model_role",
                width: 50,
                filterTransform(filter) {
                    return {
                        or: [
                            {
                                name: "model_role",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type,
                            },
                        ],
                    }
                },
            },
            {
                Header: "Created",
                id: "creation_time",
                accessor: "creation_time",
                Cell: DateCell,
                // Filter: false,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Updated",
                id: "update_time",
                accessor: "update_time",
                Cell: DateCell,
                // Filter: false,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Expires in",
                id: "expires",
                accessor: "expires",
                Filter: false,
                //  Filter: DateFilterInput,
                width: 180,
                disableResizing: true,
            },
        ],
    },
]

export const defaultColumns = serializedColumnsFromIds(
    [
        "model_title",
        "model_description",
        "model_role",
        "creation_time",
        "update_time",
        "expires",
    ],
    modelsColumns,
)
