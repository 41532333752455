import { useCallback, useEffect, useMemo, useRef } from "react"
import { deepEqual, useStabilizedCallback } from "utils/utils"

export type Options = "Wrap" | "Transpose"

export type ColumnInfo = {
    headerRows: RowInfo[]
    colIndex?: number
    customSort?: (a: any, b: any) => number
    selectValue?: any
    get: (row: Object | undefined, rowContext: any) => string | number | null
    set?: DataSetter
    locked?: boolean
} & HoTOptions
export type RowInfo = {
    id: string
    title: string | null
    set?: HeaderSetter
    className?: string
} & HoTOptions
export type HoTOptions = {
    type?: "numeric" | "autocomplete" | "text" | "dropdown" | "date"
    className?: string
    width?: number
    allowInvalid?: boolean
    source?: string[]
    filter?: boolean
    strict?: boolean
    visibleRows?: number
    placeholder?: string
    editor?: any
    renderer?: any
}
export type DataSetter = (
    row: Object,
    original: Object | undefined,
    val: string | number | null,
) => any
export type HeaderSetter = (
    updated: Object,
    original: Object | undefined,
    val: string | number | null,
) => any
export type DataChange = [row: number, set: DataSetter, val: string | number | null]
export type HeaderChange = [
    row: number,
    set: HeaderSetter,
    val: string | number | null,
]
export type DataArray = (string | number | null)[][]
export type OnSelect = (rows: number[], cols: any[]) => void

type useStyledHoTProps = {
    rows: (Object | undefined)[] | undefined
    columnsInfo: ColumnInfo[]
    options: Options[]
    colHeaders: number
    rowHeaderLabels: string[]
    minRows: number | undefined
    minEmptyRows: number | undefined
    onDataChange?: ((changes: DataChange[]) => void) | undefined
    onHeaderChange?: ((changes: HeaderChange[]) => void) | undefined
    onSelect?: OnSelect | undefined
    updateColumnOrder?: ((toMove: number[], target: number) => void) | undefined,
    updateRowOrder?: ((order: number[]) => void) | undefined
}
export function useStyledHoT({
    rows,
    columnsInfo,
    options,
    colHeaders,
    rowHeaderLabels,
    minRows,
    minEmptyRows,
    onDataChange,
    onHeaderChange,
    onSelect,
    updateColumnOrder,
    updateRowOrder,
}: useStyledHoTProps) {
    const transpose = options.includes("Transpose")
    const tableRef = useRef()

    // build data ref buffer
    const { data, dataLength } = useData(
        rows,
        columnsInfo,
        colHeaders,
        minRows,
        minEmptyRows,
        transpose,
    )

    const columns = useColumns(columnsInfo, options, dataLength)
    const rowHeaders = useRowHeaders(rowHeaderLabels, dataLength)
    const cells = useCells(columnsInfo, transpose, colHeaders)
    const mergeCells = useMergeCells(columnsInfo, transpose, colHeaders)

    // handle changes
    const afterChange = useAfterChange(
        columnsInfo,
        onDataChange,
        onHeaderChange,
        tableRef,
        transpose,
        colHeaders,
    )

    const beforeColumnMove = useBeforeColumnOrder(
        columnsInfo,
        updateColumnOrder,
        tableRef,
    )

    const beforeColumnSort = useBeforeColumnSort(columnsInfo, tableRef, transpose, data, colHeaders, updateRowOrder)

    const afterSelection = useAfterSelection(
        columnsInfo,
        transpose,
        onSelect,
        tableRef,
        colHeaders
    )
    const afterDeselect = useAfterDeselect(onSelect)

    return {
        tableRef,
        data,
        columns,
        rowHeaders,
        cells,
        mergeCells,
        afterChange,
        afterSelection,
        afterDeselect,
        beforeColumnMove,
        transpose,
        beforeColumnSort,
    }
}


export function useData(
    rows: (Object | undefined)[] | undefined,
    columnsInfo: ColumnInfo[],
    headerRows: number,
    minRows: number = 0,
    minEmptyRows: number = 0,
    transpose: boolean = false,
) {
    const dataRef = useRef<DataArray>([])
    const prevDataRef = useRef<DataArray>()

    // raw data array
    const data = useMemo(() => {
        let data: DataArray = []

        // add headerRows
        appendDataHeaders(data, columnsInfo, headerRows)

        // build instance value rows filling missing data with blanks as necessary
        appendDataBody(data, rows, columnsInfo, minRows, minEmptyRows)

        // if change return new data otherwise return old data
        if (
            !prevDataRef.current ||
            data.length !== prevDataRef.current.length ||
            !data.every(
                (row, i) =>
                    row.length === prevDataRef.current?.[i]?.length &&
                    row.every(
                        (element, j) =>
                            element === prevDataRef.current?.[i]?.[j],
                    ),
            )
        ) {
            return data
        }
        return prevDataRef.current
    }, [columnsInfo, headerRows, minEmptyRows, minRows, rows])

    // transpose data if requested
    const orderedData = useMemo(() => {
        let orderedData = data
        if (transpose) {
            const tData: DataArray = []
            for (let i = 0; i < orderedData.length; ++i) {
                for (let j = 0; j < orderedData[i].length; ++j) {
                    if (!tData[j]) tData[j] = []
                    tData[j][i] = orderedData[i][j]
                }
            }
            orderedData = tData
        }
        return orderedData
    }, [data, transpose])
    const orderedRef = useRef<DataArray>()
    if (orderedData !== orderedRef.current) {
        dataRef.current = orderedData.map(r => r && r.slice())
    }
    useEffect(() => {
        prevDataRef.current = data
    }, [data])
    useEffect(() => {
        orderedRef.current = orderedData
    }, [orderedData])
    return { data: dataRef.current, dataLength: data.length }
}

export function appendDataHeaders(data: any[][], columnsInfo: ColumnInfo[], headerRows: number, forceSingle: boolean = false) {
    if (headerRows <= 0) return
    if (forceSingle) {
        const headers = columnsInfo.map(ci => ci.headerRows?.reduce((agg, val) => {
            if (val.title) {
                agg.push(val.title)
            }
            return agg
        }, [] as string[])?.join(" | ") || null)
        const duplicates = headers.map((header, i, arr) => {
            let count = 1
            for (let j = 0; j < i; ++j) {
                if (header === arr[j]) count++
            }
            return count
        })
        duplicates.forEach((count, i) => {
            if (count > 1) headers[i] = `${headers[i]} (${count})`
        })
        data.push(headers)
    }
    else {
        for (let i = 0; i < headerRows; ++i) {
            data.push(columnsInfo.map(ci => ci.headerRows[i]?.title || null))
        }
    }
}

export function appendDataBody(data: any[][], rows: any[] | undefined, columnsInfo: ColumnInfo[], minRows: number = 0, minEmptyRows: number = 0) {
    const targetLength = Math.max(
        minRows,
        (rows?.length || 0) + minEmptyRows,
    )
    for (let i = 0; i < targetLength; ++i) {
        const row = rows?.[i]
        const context = {}
        data.push(columnsInfo.map(ci => ci.get(row, context)))
    }
}

export function fillMaterialNull(data: any[][], columnsInfo: ColumnInfo[]) {
    const targetLength = data.length;
    columnsInfo.forEach((ci, index) => {
        if (ci.type === "numeric" && ci.headerRows[0].className?.includes("material")) {
            for (let i = 0; i < targetLength; ++i) {
                data[i][index] = parseFloat(data[i][index]) || 0;
            }
        }
    })
}

export function isNumeric(num: any){
    // https://stackoverflow.com/a/58550111
    return (typeof(num) === 'number' || (typeof(num) === "string" && num.trim() !== '')) && !isNaN(num as number)
}

export function tryNumericCast(data: any[][], columnsInfo: ColumnInfo[]) {
    const targetLength = data.length;
    columnsInfo.forEach((ci, index) => {
        for (let i = 0; i < targetLength; ++i) {
            if (isNumeric(data[i][index])) {
                data[i][index] = parseFloat(data[i][index])
            }
        }
    })
}

export function aichemyPayload(title: string, rows: any[] | undefined, columnsInfo: ColumnInfo[]) {
    const headers: string[][] = []
    const data: any[][] = []
    appendDataHeaders(headers, columnsInfo, 4, true)
    appendDataBody(data, rows, columnsInfo)
    fillMaterialNull(data, columnsInfo)
    tryNumericCast(data, columnsInfo)
    const payload = {
        [title]: {}
    }
    headers[0].forEach((header: string, col_idx) => {
        const arr = []
        for (let i = 0; i < data.length; ++i) {
            arr.push(data[i][col_idx])
        }
        Object.assign(payload[title], { [header]: arr })
    })
    return payload
}

export function useColumns(
    columnsInfo: ColumnInfo[],
    options: Options[],
    dataLength: number = 0,
) {
    const prevColumnDataRef = useRef<any[]>()
    const columnData = useMemo(() => {
        let columns
        if (!options?.includes("Transpose")) {
            columns = columnsInfo.map(colInfo => ({
                type: colInfo.type || "text",
                readOnly: !colInfo.set,
                source: colInfo.source,
                filter: colInfo.filter,
                allowInvalid: colInfo.allowInvalid,
                strict: colInfo.strict,
                width: colInfo.width,
                className: colInfo.className,
                placeholder: colInfo.placeholder,
                visibleRows: colInfo.visibleRows,
                editor: colInfo.editor,
                renderer: colInfo.renderer,
            }))
        } else {
            // tranpose
            columns = Array(dataLength).fill({
                type: "numeric",
                className: "htRight",
            })
        }
        const wrap = options?.includes("Wrap")
        columns.forEach(col => (col.wordWrap = wrap))
        // check column changes
        if (deepEqual(columns, prevColumnDataRef.current)) {
            return prevColumnDataRef.current
        }
        return columns
    }, [columnsInfo, dataLength, options])
    useEffect(() => {
        prevColumnDataRef.current = columnData
    }, [columnData])

    return columnData
}

export function useBeforeColumnSort(
    columnsInfo: ColumnInfo[],
    tableRef: React.MutableRefObject<any>,
    transpose: boolean,
    data: DataArray,
    colHeaders: number,
    updateRowOrder: any,
) {
    // @ts-ignore
    const callback = useCallback((currentSortConfig, destinationSortConfigs) => {
        const hotInstance = tableRef.current?.hotInstance
        const config = destinationSortConfigs?.[0] ? destinationSortConfigs?.[0] : currentSortConfig?.[0] ? { ...currentSortConfig[0], sortOrder: currentSortConfig[0].sortOrder === "desc" ? "asc" : "desc" } : undefined
        const columnSortPlugin = hotInstance?.getPlugin('columnSorting')
        columnSortPlugin?.setSortConfig(config)
        if (!hotInstance || !config || !updateRowOrder) return false
        const colIndex = hotInstance.toPhysicalColumn(config.column)
        const toSort: [any, number][] = []
        for (let i = colHeaders; i < data.length; ++i) {
            toSort.push([data[i][colIndex], i - colHeaders])
        }
        const flip = config.sortOrder === "desc"
        const sorter = columnsInfo[colIndex].customSort || columnsInfo[colIndex].type === "numeric" ? ((a: any, b: any) => Number(a) < Number(b) ? -1 : 1) : ((a: any, b: any) => a < b ? -1 : 1)
        toSort.sort((aa: [any, number][], ba: [any, number][]) => {
            const a: any = aa[0]
            const b: any = ba[0]
            if (a === b) return 0
            const aNull = !a && a !== 0
            const bNull = !b && b !== 0
            if (aNull && bNull) return 0
            if (aNull) return 1
            if (bNull) return -1
            return flip ? sorter(b, a) : sorter(a, b)
        })
        updateRowOrder(toSort.map(v => v[1]))
        hotInstance.render()
        return false
    }, [tableRef, updateRowOrder, columnsInfo, colHeaders, data])
    const beforeColumnSort = useStabilizedCallback(callback)
    return (transpose || !updateRowOrder) ? undefined : beforeColumnSort
}

export function useRowHeaders(rowHeaderLabels: string[] = [], dataLength: number = 0) {
    const prevRowHeadersRef = useRef<string[]>()
    const rowHeaders = useMemo(() => {
        const rowHeaders = [...rowHeaderLabels]
        rowHeaders.length = dataLength
        for (let i = rowHeaderLabels.length; i < rowHeaders.length; ++i) {
            rowHeaders[i] = String((i - rowHeaderLabels.length) + 1)
        }
        return !prevRowHeadersRef.current ||
            rowHeaders.length !== prevRowHeadersRef.current.length ||
            !rowHeaders.every((rh, i) => rh === prevRowHeadersRef.current?.[i])
            ? rowHeaders
            : prevRowHeadersRef.current
    }, [dataLength, rowHeaderLabels])
    useEffect(() => {
        prevRowHeadersRef.current = rowHeaders
    }, [rowHeaders])
    return rowHeaders
}

export function useCells(
    columnsInfo: ColumnInfo[],
    transpose: boolean,
    headerRows: number,
) {
    const columnsInfoRef = useRef(columnsInfo)
    columnsInfoRef.current = columnsInfo
    return useCallback(
        // @ts-ignore
        (rowIndex, colIndex, prop) => {
            if (transpose) {
                if (colIndex >= headerRows) {
                    const colInfo = columnsInfoRef.current[rowIndex]
                    if (!colInfo) return
                    const keys = [
                        "source",
                        "filter",
                        "allowInvalid",
                        "strict",
                        "width",
                        "placeholder",
                        "visibleRows",
                        "editor",
                        "renderer"
                    ]
                    if (
                        colInfo.type !== "numeric" ||
                        colInfo.className !== "htRight" ||
                        keys.some(k => (colInfo as any)[k] !== undefined)
                    ) {
                        return {
                            type: colInfo.type || "text",
                            readOnly: (!colInfo.set || (colInfo.locked)),
                            source: colInfo.source,
                            filter: colInfo.filter,
                            allowInvalid: colInfo.allowInvalid,
                            strict: colInfo.strict,
                            width: colInfo.width,
                            className: colInfo.className,
                            placeholder: colInfo.placeholder,
                            visibleRows: colInfo.visibleRows,
                            editor: colInfo.editor,
                            renderer: colInfo.renderer,
                            transpose: true,
                        }
                    } else return
                }
                // swap for header props
                let temp = rowIndex
                rowIndex = colIndex
                colIndex = temp
            }
            const colInfo = columnsInfoRef.current[colIndex]
            if (!colInfo) return
            if (rowIndex < headerRows) {
                const row = colInfo.headerRows[rowIndex]
                const rtn: any = {
                    type: "text",
                    className: "htCenter innerColHeader",
                }
                if (!row) return rtn
                if (row.type !== undefined) rtn.type = row.type
                if (row.className !== undefined) rtn.className = row.className
                if (row.source !== undefined) rtn.source = row.source
                if (row.strict !== undefined) rtn.strict = row.strict
                if (row.visibleRows !== undefined)
                    rtn.visibleRows = row.visibleRows
                if (row.placeholder !== undefined)
                    rtn.placeholder = row.placeholder
                if (row.editor !== undefined)
                    rtn.editor = row.editor
                if (row.renderer !== undefined)
                    rtn.renderer = row.renderer
                rtn.readOnly = !row.set
                return rtn
            }
            return undefined
        },
        [headerRows, transpose],
    )
}

type Merge = { row: number; col: number; rowspan: number; colspan: number }
export function useMergeCells(
    columnsInfo: ColumnInfo[],
    transpose: boolean,
    headerRows: number,
) {
    const prevMergeCellsRef = useRef<Merge[]>()
    const mergedCells = useMemo(() => {
        let mergedCells: Merge[] = []
        for (let i = 0; i < headerRows; ++i) {
            let span = 1
            let lastId = columnsInfo[0].headerRows[i]?.id
            let lastTitle = columnsInfo[0].headerRows[i]?.title
            let start = 0
            for (let j = 1; j < columnsInfo.length + 1; ++j) {
                const cell = columnsInfo[j]?.headerRows[i]
                if (!cell || cell.id !== lastId || cell.title !== lastTitle) {
                    if (span > 1) {
                        mergedCells.push({
                            row: i,
                            col: start,
                            rowspan: 1,
                            colspan: span,
                        })
                    }
                    start = j
                    span = 1
                    lastId = columnsInfo[j]?.headerRows[i]?.id
                    lastTitle = columnsInfo[j]?.headerRows[i]?.title
                    continue
                }
                if (lastId === cell.id && lastTitle === cell.title) {
                    span++
                }
            }
        }
        if (transpose)
            mergedCells = mergedCells.map(merge => ({
                row: merge.col,
                col: merge.row,
                rowspan: merge.colspan,
                colspan: merge.rowspan,
            }))
        return deepEqual(mergedCells, prevMergeCellsRef.current)
            ? prevMergeCellsRef.current
            : mergedCells
    }, [columnsInfo, headerRows, transpose])
    useEffect(() => {
        prevMergeCellsRef.current = mergedCells
    }, [mergedCells])

    return mergedCells
}

export function useBeforeColumnOrder(
    columnsInfo: ColumnInfo[],
    updateColumnOrder: ((toMove: number[], target: number) => void) | undefined,
    tableRef: React.MutableRefObject<any>,
) {
    const columnsInfoRef = useRef(columnsInfo)
    columnsInfoRef.current = columnsInfo
    return useCallback(
        (cols: number[], target: number) => {
            if (!tableRef.current?.hotInstance || !updateColumnOrder) return false
            const convert = (i: number) =>
                colsInfo[tableRef.current.hotInstance.toPhysicalColumn(i)]?.colIndex
            const colsInfo = columnsInfoRef.current
            const tarCol = convert(target)
            if (tarCol === undefined) return false
            const toMove = cols
                .map(convert)
                .filter(c => c !== undefined) as number[]
            if (toMove.length <= 0) return false
            updateColumnOrder(toMove, tarCol)
            return false
        },
        [tableRef, updateColumnOrder],
    )
}

export function useAfterChange(
    columnsInfo: ColumnInfo[],
    onDataChange: undefined | ((changes: DataChange[]) => void),
    onHeaderChange: undefined | ((changes: HeaderChange[]) => void),
    tableRef: React.MutableRefObject<any>,
    transpose: boolean,
    headerRows: number,
) {
    const callback = useCallback(
        (
            changes:
                | [
                    number,
                    number,
                    number | string | null,
                    number | string | null,
                ][]
                | undefined,
            source: string | undefined,
        ) => {
            if (source !== "loadData" && source !== "populateFromArray") {
                const hotInstance = tableRef.current?.hotInstance
                if (!hotInstance || !changes) return

                // convert to physical index
                changes = changes.map(change => [
                    hotInstance.toPhysicalRow(change[transpose ? 1 : 0]),
                    hotInstance.toPhysicalColumn(change[transpose ? 0 : 1]),
                    change[2],
                    change[3],
                ])

                // compile update functions
                const dataChanges: DataChange[] = []
                const headerChanges: HeaderChange[] = []
                for (const change of changes) {
                    if (change[2] === change[3]) continue // ignore no change
                    const colInfo = columnsInfo[change[1]]
                    const newVal =
                        typeof change[3] === "string"
                            ? change[3].trim()
                            : change[3]
                    if (change[0] >= headerRows && onDataChange) {
                        if (!colInfo?.set) continue // must be readOnly
                        dataChanges.push([
                            change[0] - headerRows,
                            colInfo.set,
                            newVal,
                        ])
                    } else if (onHeaderChange) {
                        if (!colInfo.headerRows[change[0]]?.set) continue // must be readOnly
                        headerChanges.push([
                            change[0],
                            colInfo.headerRows[change[0]].set as HeaderSetter,
                            newVal,
                        ])
                    }
                }

                if (dataChanges.length) onDataChange?.(dataChanges)
                if (headerChanges.length) onHeaderChange?.(headerChanges)
            }
        },
        [
            columnsInfo,
            headerRows,
            onDataChange,
            onHeaderChange,
            tableRef,
            transpose,
        ],
    )
    return useStabilizedCallback(callback)
}

export function useAfterSelection(
    columnsInfo: ColumnInfo[],
    transpose: boolean,
    onSelect: OnSelect | undefined,
    tableRef: React.MutableRefObject<any>,
    colHeaders: number
) {
    const callback = useCallback(
        // @ts-ignore
        (row, col, row2, col2) => {
            const hotInstance = tableRef.current?.hotInstance
            if (!onSelect || !hotInstance) return
            if (transpose) {
                let temp = row
                col = row
                row = temp
                temp = row2
                row2 = col2
                col2 = temp
            }
            const rows = []
            const startRow = Math.min(row, row2)
            const endRow = Math.max(row, row2)
            for (let i = startRow; i <= endRow; ++i) {
                let rowIndex = transpose
                    ? hotInstance.toPhysicalColumn(i)
                    : hotInstance.toPhysicalRow(i)
                if (rowIndex >= colHeaders)
                    rows.push(rowIndex - colHeaders)
            }
            const startCol = Math.min(col, col2)
            const endCol = Math.max(col, col2)
            const cols = []
            for (let i = startCol; i <= endCol; ++i) {
                let colIndex = transpose
                    ? hotInstance.toPhysicalRow(i)
                    : hotInstance.toPhysicalColumn(i)
                if (columnsInfo[colIndex]?.selectValue !== undefined)
                    cols.push(columnsInfo[colIndex].selectValue)
            }
            onSelect(rows, cols)
        },
        [tableRef, onSelect, transpose, colHeaders, columnsInfo],
    )

    return useStabilizedCallback(callback)
}

export function useAfterDeselect(
    onSelect: OnSelect | undefined
) {
    return useCallback(() => {
        if (onSelect) {
            onSelect([], [])
        }
    }, [onSelect])
}