import UserFilterInput from "../ReactTable/UserFilterInput";
import DateCell from "../ReactTable/DateCell";
import DateFilterInput from "../ReactTable/DateFilterInput";
import { serializedColumnsFromIds } from "../ReactTable/reactTableUtils";
import LabsFilterInput from "../ReactTable/labsFilterInput";

export const sampleSetColumns = [
    {
        Header: "Basic Sample Set Fields",
        id: "sampleSet",
        columns: [
            {
                Header: "Sample Set Name",
                accessor: "title",
            },
            {
                Header: "Samples",
                accessor: "samples.length",
                type: "number",
                width: 100,
                disableResizing: true,
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: "Tags",
                id: "tags",
                accessor: data => ((data.tags || []).concat(...((data.samples?.map(i => i.tags)?.filter(t => !!t)) || []))).filter((t, i, tags) => tags.indexOf(t) === i),
                disableSortBy: true,
                filterTransform(filter){
                    return {
                        or: [
                            {
                                name: "tagsRel.title",
                                op: filter.value?.op || filter.value?.type,
                                val: filter.value?.query,
                            },
                            {
                                name: "samples.tagsRel.title",
                                op: filter.value?.op || filter.value?.type,
                                val: filter.value?.query,
                            }
                        ]
                    }
                }
            },
            {
                Header: "Contributor(s)",
                id: "contributors",
                accessor: set => set.contributors?.join(", "),
                Filter: UserFilterInput,
            },
            {
                Header: "Created",
                accessor: "timeCreated",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Modified",
                accessor: "timeModified",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Permissions",
                id: "labs.lab.title",
                disableSortBy: true,
                accessor: data => data.labs?.map(lab => lab.lab?.title).filter((title, i, arr) => arr.indexOf(title) === i).join(" | "),
                Filter: LabsFilterInput,
            }
        ],
    },
    {
        Header: "Sample Fields",
        id: "sampleSet.samples",
        columns: [
            {
                Header: "Sample Names",
                id: "samples.title",
                disableSortBy: true,
                accessor: set => (set.samples?.map(sample => sample.title) || []).join(' | '),
                filterTransform(filter) {
                    return {
                        or: [
                            {
                                name: "samples.title",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type
                            },
                            {
                                name: "samples.alternateNames",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type
                            }
                        ]
                    }
                }
            },
            {
                Header: "Sample Alternate Names",
                id: "samples.alternateNames",
                disableSortBy: true,
                accessor: set => (set.samples?.map(sample => sample.alternateNames?.replace("\n"," | ") || "") || []).join(' || '),
                simpleFilter: true,
            },
            {
                Header: "Sample QIDs",
                id: "samples.qid",
                disableSortBy: true,
                accessor: set => (set.samples?.map(sample => sample.qid) || []).join(' | '),
                width: 130,
            },
            {
                Header: "Sample Notes",
                id: "samples.notes",
                disableSortBy: true,
                accessor: set => (set.samples?.map(sample => sample.notes) || []).join(' | '),
            },
            {
                Header: "Sample Components Name",
                id: "samples.components.definition.title",
                accessor: set =>
                    set.samples?.map(sample => sample.components?.map(i => i.definition?.title).filter(d => d)|| []).join(' |'),
                disableSortBy: true,
            }
        ],
    },
]

export const defaultColumns = serializedColumnsFromIds(["title", "samples.length", "contributors", "timeModified", "description"], sampleSetColumns);