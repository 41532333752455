import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack"
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import common_styles from "../../../styles/common_styles";
import OptimizationItem from "./OptimizationItem";
import ColumnMultiSelectField from "../DataVizPlots/ColumnMultiSelectField";
import { useAppStoreKey } from "../../../AppStore";
import { aichemyProtoAxios } from "../../../API/mmAxios";
import { displayOptResults, getModelName, getOriColumns, TabPanel } from "../utils";
import OptimizationConstraint from "./OptimizationConstraint";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OptimizationResults from "./OptimizationResults";
import { LargeTooltip } from "../components/LargeTooltip";
import HelpCircleIcon from "mdi-material-ui/HelpCircleOutline";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ...common_styles(theme),
  barColorPrimary1: {
    backgroundColor: "#e6194B"
  },
  barColorPrimary2: {
    backgroundColor: "#f58231"
  },
  barColorPrimary3: {
    backgroundColor: "#ffe119"
  },
  barColorPrimary4: {
    backgroundColor: "#bfef45"
  },
  barColorPrimary5: {
    backgroundColor: "#3cb44b"
  },
  barColorPrimary6: {
    backgroundColor: "#42d4f4"
  },
  barColorPrimary7: {
    backgroundColor: "#4363d8"
  },
  barColorPrimary8: {
    backgroundColor: "#9911eb4"
  },
  barColorPrimary9: {
    backgroundColor: "#f032e6"
  },
  barColorPrimary10: {
    backgroundColor: "#469990"
  },
  colorPrimary: {
    backgroundColor: "#F0F0F0"
  },
  buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    margin: theme.spacing(1),
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "100%",
    marginTop: 12,
    marginLeft: -110,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));

function Optimization() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const maxTaskDisplay = 5;
  const maxIteration = 100;
  const maxSwarmSize = 500;
  const [optTargets, setOptTargets] = useState({})
  // const [openHistory, setOpenHistory] = useState(false)
  const [maxiter, setMaxiter] = useState(20)
  const [swarmSize, setSwarmSize] = useState(50)
  const [optBound, setOptBound] = useState({ 'lb': {}, 'ub': {}, 'choices': {} })
  const [workflow,] = useAppStoreKey("Workflow");
  const visibleModelIdx = workflow.models.map((model, idx) => model.hide ? "" : idx).filter(idx => idx !== "")
  const [modelID, setModelID] = useState(visibleModelIdx[0]);
  const optResultsRef = useRef()
  const [optConstraints, setOptConstraints] = useState([{ "operation": "", "col_idx": [], "col_names": [], "target": '', "tolerance": '' }])
  const [previousOptResults, setPreviousOptResults] = useState([])
  const [currentTab, setCurrentTab] = useState(previousOptResults.length)

  const setSearchingRangeExplain = "This is the min/max value for each input dimension. " +
    "The optimization algorithm will only look for points within this range."
  const setOptTargetExplain = "This is the target property you want to optimize. " +
    "It can be minimized/maximized or held near to a specific value. " +
    "Weights define the relative importance of these targets when searching for a solution."
  const setOptConstrainsExplain = "The final solution will satisfy all constraints added here."

  const setOptHPExplain = "Increasing the two parameters will have a higher chance of finding a desired solution" +
    "but takes a longer time to execute."

  // load previous optimization tasks
  const loadPreviousOptResults = useCallback(() => {
    if (workflow.uuid) {
      let url = 'workflow/' + workflow.uuid + '/tasks?operation=optimization'
      let config = { headers: { "Content-Type": "application/json; charset=utf-8" }, }
      aichemyProtoAxios.get(url, config)
        .then(res => {
          let data = res.data
          data.sort((a, b) => {
            let t1 = new Date(a.create_time).getTime()
            let t2 = new Date(b.create_time).getTime()
            return t1 < t2 ? -1 : 1;
          })
          setPreviousOptResults(data)
          setCurrentTab(Math.min(data.length, maxTaskDisplay) - 1)
        })
    }
  }, [workflow.uuid])

  useEffect(() => loadPreviousOptResults(), [loadPreviousOptResults])

  const handleModelSelection = (event) => {
    setModelID(event.target.value)
  }

  // Get data from App store
  const getData = useCallback(() => {
    const data = workflow.models[modelID].data[0];
    const selected_sheet = workflow.models[modelID].data[0].active_sheet
    if (!data || !selected_sheet || !data.info[selected_sheet].input_cols) return {}

    return { data: data, selected_sheet: selected_sheet }
  }, [workflow.models, modelID])


  const getHeaders = useCallback(() => {
    let { data, selected_sheet } = getData();
    if (!data) return { inputColNames: [], outputColNames: [] }
    const transforms = data.info[selected_sheet].columns_transform;
    let inputCols = data.info[selected_sheet].input_cols
    inputCols = getOriColumns(transforms, inputCols)
    let outputCols = data.info[selected_sheet].output_cols
    outputCols = getOriColumns(transforms, outputCols)
    const inputColNames = [...inputCols]
    const outputColNames = [...outputCols]
    return { inputColNames: inputColNames, outputColNames: outputColNames };
  }, [getData])

  const getDataMinMax = useCallback(() => {
    let { data, selected_sheet } = getData();
    if (!data) return []
    return data.info[selected_sheet].ori_data_range
  }, [getData])

  const getDataTypes = useCallback(() => {
    let { data, selected_sheet } = getData();
    if (!data) return []
    return data.info[selected_sheet].column_types
  }, [getData])

  const setInitOptTarget = useCallback(() => {
    let initOptTarget = {}
    const data_range = getDataMinMax()
    const data_types = getDataTypes()
    let allHeaders = getHeaders()
    let upper_bounds = {}
    let lower_bounds = {}
    let choices = {}
    allHeaders.inputColNames.forEach((col) => {
      if (data_types[col] === 'number') {
        let max = data_range[col][1]
        let min = data_range[col][0]
        // force these values to be different on init, controlled element does
        // not force the setState call that changes the values being used,
        // just their rendered value
        if (max === min) {
          max += 1
          min -= 1
        }
        upper_bounds[col] = max
        lower_bounds[col] = min
      } else {
        choices[col] = data_range[col]
      }
    })
    setOptBound({ 'ub': upper_bounds, 'lb': lower_bounds, 'choices': choices })

    allHeaders.outputColNames.forEach((val) => initOptTarget[val] = {
      check: true,
      target: 'min',
      targetValue: '',
      upper_bound: upper_bounds,
      lower_bound: lower_bounds,
      weight: 1,
    })
    setOptTargets(initOptTarget)
  }, [getDataMinMax, getHeaders, getDataTypes])

  useEffect(() => {
    if (workflow && Object.entries(optTargets).length === 0) {
      setInitOptTarget();
    }
  }, [workflow, optTargets, setInitOptTarget]);

  useEffect(() => {
    // recompute the bounds when swapping between models
    setInitOptTarget()
  }, [setInitOptTarget, modelID])


  const handleSetBound = (type, idx) => (event) => {
    let newOptBound = { ...optBound }
    if (type === 'choices') {
      newOptBound[type][idx] = event // gets passed from autocomplete as list of values
    } else {
      newOptBound[type][idx] = Number(event.target.value)
    }
    setOptBound(newOptBound)
  }

  const getTarget = () => {
    let activeTargets = {}
    Object.keys(optTargets).forEach(col => {
      if (optTargets[col].check) {
        activeTargets[col] = {
          'function': optTargets[col].target,
          'weight': optTargets[col].weight,
          'value': optTargets[col].targetValue,
        }
      }
    })

    return activeTargets
  }

  const processOptConstraint = (optCons) => {
    // extract the equality and inequality constraints
    let allConstraint = optCons.map((constraint) => {
      let currentConstraint = { ...constraint }
      if (!currentConstraint.tolerance) currentConstraint.tolerance = Math.abs(currentConstraint.target) * 0.2
      return currentConstraint
    })
    const iEqConstraint = allConstraint.filter((constraint) => constraint.operation !== '' && constraint.operation !== '=')
    const eqConstraint = allConstraint.filter((constraint) => constraint.operation === '=')
    return [iEqConstraint, processEqConstraint(eqConstraint)]
  }

  const processEqConstraint = (cons) => {
    // convert equality constraint to list form
    const inputColNames = getHeaders().inputColNames
    const consDict = {}
    cons.forEach((constraint) => {
      consDict[inputColNames[constraint.col_idx]] = Number(constraint.target)
    })
    return consDict
  }

  const submitOpt = () => {
    // check that the opt categories are valid
    const hasCategories = Object.keys(optBound.choices).length > 0
    if (hasCategories) {
      let invalidCategories = []
      Object.keys(optBound.choices).forEach((item) => {
        if (optBound.choices[item].length === 0) {
          invalidCategories.push(item)
        }
      })
      if (invalidCategories.length > 0) {
        enqueueSnackbar(`Must set options for ${invalidCategories.join(", ")}`, { variant: "error" })
        return
      }
    }
    let update_dict = {
      'optimizer': hasCategories ? 'MixedVariableGA' : 'PSO',
      'params': {
        'swarmsize': swarmSize,
        'maxiter': maxiter,
        'eq_cons': processOptConstraint(optConstraints)[1],
        // exists to simplify getting the correct headers when flipping between models/tasks
        'bounds': optBound,
        'ori_input_columns': getHeaders().inputColNames,
        'ori_output_columns': getHeaders().outputColNames,
      },
      'target': getTarget(),
      'constraints': processOptConstraint(optConstraints)[0],
      'model_index': modelID ? modelID : 0,
    }
    let opt_body = {
      optimize_dict: update_dict,
    }

    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    }

    // create task for optimization
    let url = 'tasks'
    let body = JSON.stringify(
      {
        workflow_id: workflow.uuid,
        operation: 'optimization',
        operation_args: JSON.stringify(opt_body),
      });
    aichemyProtoAxios.post(url, body, config)
      .then(res => {
        setPreviousOptResults([...previousOptResults, res.data])
        setCurrentTab(Math.min(previousOptResults.length + 1, maxTaskDisplay) - 1)
        optResultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }).catch(err => {
        console.error(err)
      })
  }

  const addConstraint = () => {
    setOptConstraints([...optConstraints, { "operation": "", "col_idx": [], "col_names": [], "target": '', "tolerance": '' }])
  }

  const removeConstraint = (selectedIdx) => () => {
    setOptConstraints(optConstraints.filter((item, idx) => idx !== selectedIdx))
  }

  const getInputHeaderWidth = () => {
    let maxLength = Math.max(...getHeaders().inputColNames.map(col => col.length))
    if (maxLength < 20) return 100
    else if (maxLength < 40) return 200
    else if (maxLength < 60) return 250
    else return 300
  }

  const getOutputHeaderWidth = () => {
    let maxLength = Math.max(...getHeaders().outputColNames.map(col => col.length))
    if (maxLength < 20) return 100 - 10
    else if (maxLength < 40) return 200 - 10
    else if (maxLength < 60) return 250 - 10
    else return 300 - 10
  }

  const getOptBound = (item) => {
    if (getDataTypes()[item] === 'number') {
      let ub = optBound.ub[item] ? optBound.ub[item] : 0
      let lb = optBound.lb[item] ? optBound.lb[item] : 0
      if (ub === lb) {
        ub += 1
        lb -= 1
      }
      return { ub: ub, lb: lb }
    } else {
      return optBound.choices[item]
    }
  }

  const getTaskIdx = (taskId) => {
    let task_idx
    const taskLength = previousOptResults.length
    if (taskLength > maxTaskDisplay) {
      task_idx = taskId + taskLength - maxTaskDisplay + 1
    }
    else {
      task_idx = taskId + 1
    }
    return task_idx
  }

  const getTaskTitle = (taskId) => {
    let currentTask = previousOptResults[getTaskIdx(taskId) - 1]
    // Use default title if title is empty
    return currentTask.title ? currentTask.title : `task ${getTaskIdx(taskId)}`
  }

  const getTaskDescription = (taskId) => {
    let currentTask = previousOptResults[getTaskIdx(taskId) - 1]
    // Use opt conditions if description is empty
    return currentTask.description ? currentTask.description : displayOptResults(currentTask, workflow).primaryText
  }

  const NumericBounds = (item, idx) => {
    return <React.Fragment>
      <FormControl className={classes.formControl} style={{ marginBottom: 12 }}>
        <TextField
          id={"lower_bound_" + item}
          label={"Lower bound"}
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          onChange={handleSetBound('lb', item)}
          value={getOptBound(item).lb}
          style={{ marginTop: 0, width: "100%" }}
          data-cy={"opt_target_" + item}
        />
      </FormControl>
      <FormControl className={classes.formControl} style={{ marginBottom: 12 }}>
        <TextField
          id={"upper_bound_" + item}
          label={"Upper bound"}
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          onChange={handleSetBound('ub', item)}
          value={getOptBound(item).ub}
          style={{ marginTop: 0, width: "100%" }}
          data-cy={"opt_target_" + item}
        />
      </FormControl>
    </React.Fragment>
  }

  const CategoricalBounds = (item, idx) => {
    if (getOptBound(item) === undefined) {
      return <></>
    }
    return <React.Fragment>
      <FormControl className={classes.formControl} style={{ marginBottom: 12, marginTop: 0, width: "40%" }}>
        <ColumnMultiSelectField
          id={"cat_" + item}
          label={"Options"}
          value={getOptBound(item)}
          setValue={handleSetBound('choices', item)}
          headers={getDataMinMax()[item]}
          style={{ marginTop: 0, width: "100%" }}
          data-cy={"opt_target_" + item}
        />
        {getOptBound(item).length === 0 && (
          <FormHelperText error>Need to set options</FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  }

  return (
    <>
      <Card
        className={classes.paperBody}
        elevation={3}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <div style={{ margin: 24, width: '50%' }}>
          <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24 }}>
            Choose a model to optimize:
          </Typography>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor={"model_select"}>Model</InputLabel>
            <Select
              style={{ textAlign: "left" }}
              value={modelID}
              onChange={handleModelSelection}
              input={<Input name={"opt_model_select"} id={"opt_model_select"} />}
            >
              {workflow.models && workflow.models.map((model, idx) => {
                if (model.hide) return ''
                return (<MenuItem key={"model" + idx} value={idx}>
                  {getModelName(idx, workflow)}
                </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </Card>
      <Card
        className={classes.paperBody}
        elevation={3}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <div style={{ margin: 24, width: '100%' }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24 }}>
              Set searching range:
            </Typography>
            <Tooltip title={<Typography variant="subtitle1">{setSearchingRangeExplain}</Typography>} placement="top">
              <HelpCircleIcon style={{ fontSize: 15, marginRight: 12, marginTop: 24 }} />
            </Tooltip>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {getHeaders().inputColNames.map((item, idx) => <div key={'opt_range' + idx} style={{ flexDirection: 'row' }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="subtitle1" gutterBottom style={{ textAlign: "left", marginTop: 24, marginRight: 24, marginLeft: 49, wordWrap: "break-word", width: getInputHeaderWidth() }}>
                  {item}
                </Typography>
                {getDataTypes()[item] === "number" && NumericBounds(item, idx)}
                {getDataTypes()[item] === "string" && CategoricalBounds(item, idx)}
              </div>
            </div>)}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24 }}>
              Set optimization target:
            </Typography>
            <Tooltip title={<Typography variant="subtitle1">{setOptTargetExplain}</Typography>} placement="top">
              <HelpCircleIcon style={{ fontSize: 15, marginRight: 12, marginTop: 24 }} />
            </Tooltip>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {getHeaders().outputColNames.map((item, idx) => <div key={'OptItem_' + idx} style={{ marginLeft: 20 }}>
              <OptimizationItem
                classes={classes}
                colName={item}
                optTargets={optTargets}
                setOptTargets={setOptTargets}
                headerWidth={getOutputHeaderWidth()}
              />
            </div>)}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24 }}>
              Set optimization constraints:
            </Typography>
            <Tooltip title={<Typography variant="subtitle1">{setOptConstrainsExplain}</Typography>} placement="top">
              <HelpCircleIcon style={{ fontSize: 15, marginRight: 12, marginTop: 24 }} />
            </Tooltip>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {optConstraints.map((item, idx) => <div key={'OptConstrain_' + idx} style={{ marginTop: 12, marginBotton: 12 }}>
              <OptimizationConstraint
                classes={classes}
                cols={Object.keys(getDataTypes()).filter(k => getDataTypes()[k] === 'number' && getHeaders().inputColNames.includes(k))}
                optConstrain={item}
                allConstraints={optConstraints}
                setOptConstraints={setOptConstraints}
                constrainIdx={idx}
                removeConstraint={removeConstraint(idx)}
              />
            </div>)}
          </div>
          <Button
            variant="contained"
            style={{ float: 'right' }}
            color="primary"
            className={classes.button}
            onClick={addConstraint}>

            Add New Constraint
          </Button>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24 }}>
              Set optimization hyperparameters:
            </Typography>
            <Tooltip title={<Typography variant="subtitle1">{setOptHPExplain}</Typography>} placement="top">
              <HelpCircleIcon style={{ fontSize: 15, marginRight: 12, marginTop: 24 }} />
            </Tooltip>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginRight: 24, width: "90%" }}>
            <FormControl className={classes.formControl}>
              <TextField
                id={"maxIter_input"}
                label={"Max iteration"}
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                onChange={(event => setMaxiter(Math.min(Number(event.target.value), maxIteration)))}
                value={maxiter}
                style={{ marginTop: 0, width: "100%" }}
                data-cy={"maxIter_cy"}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id={"swarmSize_input"}
                label={"Swarm Size"}
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                onChange={(event => setSwarmSize(Math.min(Number(event.target.value), maxSwarmSize)))}
                value={swarmSize}
                style={{ marginTop: 0, width: "100%" }}
                data-cy={"swarmSize_cy"}
              />
            </FormControl>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={submitOpt}
            style={{ float: 'right' }}
            startIcon={<SearchIcon />}
          >
            Find Target
          </Button>
        </div></Card>
      {previousOptResults.length > 0 && <>
        <Card
          className={classes.paperBody}
          elevation={3}
          style={{ marginTop: 24, marginBottom: 24 }}
        >
          <div style={{ margin: 24, width: '100%' }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 24 }}>
              Optimization Results:
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{ textAlign: "left", marginBottom: 12 }}>
              Only the most recent 5 searches are shown.
            </Typography>

            <Tabs
              value={currentTab}
              onChange={(event, newValue) => { setCurrentTab(newValue) }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {previousOptResults.slice(-maxTaskDisplay).map((opt_task, idx) => {
                return <LargeTooltip placement="top" title={getTaskDescription(idx)}>
                  <Tab key={`task ${getTaskIdx(idx)}`} label={getTaskTitle(idx)} value={idx} />
                </LargeTooltip>
              })}
            </Tabs>
            {previousOptResults.slice(-maxTaskDisplay).map((opt_task, idx) => {
              return <TabPanel key={getTaskIdx(idx)} value={currentTab} index={idx}>
                <OptimizationResults
                  optTask={opt_task}
                  modelID={modelID}
                  setOptTask={(new_task) => {
                    setPreviousOptResults(previousOptResults.slice(0, idx).concat(new_task).concat(previousOptResults.slice(idx + 1)))
                  }}
                />
              </TabPanel>
            })}
          </div>
        </Card>
      </>}
      <div ref={optResultsRef} />
    </>
  );
}

export default React.memo(Optimization);