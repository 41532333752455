import DateCell from "../ReactTable/DateCell";
import DateFilterInput from "../ReactTable/DateFilterInput";
import { serializedColumnsFromIds } from "../ReactTable/reactTableUtils";

export const testColumns = [
    {
        Header: "Basic Test Fields",
        id: "test",
        columns: [
            {
                Header: "Test Name",
                accessor: "title",
            },
            {
                Header: "Instrument",
                accessor: "instrument.title",
                type: "string",
                filterTransform(filter) {
                    return {
                        name: "instrument.title",
                        val: filter.value?.query,
                        op: filter.value?.op || filter.value?.type
                    }
                }
            },
            {
                Header: "Created",
                accessor: "timeCreated",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Modified",
                accessor: "timeModified",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Processor",
                accessor: "processor",
            },
            {
                Header: "Samples",
                id: "samples.title",
                accessor: test => (test.samples?.map(sample => sample.title) || []).join(' | '),
                type: "string",
                filterTransform(filter) {
                    return {
                        or: [
                            {
                                name: "samples.title",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type
                            },
                            {
                                name: "samples.alternateNames",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type
                            }
                        ]
                    }
                },
            },
            {
                Header: "Tags",
                id: "tags",
                accessor: data => ((data.tags || []).concat(...((data.tests?.map(i => i.tags)?.filter(t => !!t)) || []))).filter((t, i, tags) => tags.indexOf(t) === i),
                disableSortBy: true,
                filterTransform(filter) {
                    return {
                        name: "tagsRel.title",
                        op: filter.value?.op || filter.value?.type,
                        val: filter.value?.query,
                    }
                }
            },
            {
                Header: "Valid?",
                accessor: "valid",
                disableFilters: true,
                disableSortBy: true
            }
        ],
    }
]

export const defaultColumns = serializedColumnsFromIds(["title", "instrument.title", "processor", "samples.title", "timeModified"], testColumns);