import { useEffect } from "react"
import { useAuth0, Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { gatewayAxios, aichemyProtoAxios, mbxAxios, mmAxios, emAxios } from "API/mmAxios";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';

import moment from 'moment';
const cookies = new Cookies();

export const GatewayPing = () => {
    gatewayAxios
        .get('')
        .then(() => console.debug("Gateway Ping Success"))
        .catch(() => console.debug("Gateway Ping Failed"))
}

export default function AuthProvider({ children }) {
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            redirectUri={window.location.origin}
            onRedirectCallback={redirectCallback}
            connection_scope="offline_access"
        >
            <AuthInjector>
                {children}
            </AuthInjector>
        </Auth0Provider>
    )
}

const AuthInjector = withAuthenticationRequired(({ children }) => {
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        async function updateConfig(config) {
            const auth0Token = await getUpdatedAccessTokenSilently(getAccessTokenSilently)
            if (auth0Token)
                config.headers.authorizationToken = `Bearer ${auth0Token}`
            return config
        }

        async function updateConfigMbx(config) {
            const auth0Token = await getUpdatedAccessTokenSilently(getAccessTokenSilently)
            if (auth0Token)
                config.headers.Authorization = `Bearer ${auth0Token}`
            return config
        }

        async function updateConfigEm(config) {
            const auth0Token = await getUpdatedAccessTokenSilently(getAccessTokenSilently)
            if (auth0Token)
                config.headers.Authorization = `Bearer ${auth0Token}`
            return config
        }

        async function handleError(error) { return error }

        gatewayAxios.interceptors.request.use(
            updateConfig,
            handleError
        );
        mmAxios.interceptors.request.use(
            // updateConfig,
            updateConfigEm,
            handleError
        );
        aichemyProtoAxios.interceptors.request.use(
            updateConfigEm,
            handleError
        );
        mbxAxios.interceptors.request.use(
            updateConfigMbx,
            handleError
        );
        emAxios.interceptors.request.use(
            updateConfigEm,
            handleError
        )

    }, [getAccessTokenSilently]);

    return <>{children}</>
})

export async function getUpdatedAccessTokenSilently(getAccessTokenSilently, options) {
    let auth0Token = await getAccessTokenSilently(options);
    if (auth0Token) {
        const decoded = jwt_decode(auth0Token)
        const graphDecoded = jwt_decode(decoded['https://azuread#ad_access_token']);
        cookies.set('graph_token', decoded['https://azuread#ad_access_token'], { path: '/' })
        if ((moment().unix() + 30) > graphDecoded.exp) {
            console.log(`Graph Token about to expired... getting refresh graph token`);
            auth0Token = await getAccessTokenSilently({ ...options, ignoreCache: true });
        }
    }
    return auth0Token
}

function redirectCallback(appState) {
    if (appState?.returnTo && window.location.pathname !== appState.returnTo)
        window.location.replace(appState.returnTo)
}