/**
 * @format
 */
import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core"
import { getExpressionResults } from "./expressionUtils"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
    results: {
        background:
            theme.palette.type === "light"
                ? "rgba(0, 0, 0, 0.05)"
                : "rgba(255, 255, 255, 0.05)",
        border: `solid 1px ${theme.palette.divider}`,
        borderRadius: theme.spacing(0.5),
        padding: "10.5px 14px",
        color: theme.palette.text.secondary,
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "18px",
        "&.error": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
        },
        "&.loading": {
            animationName: "$pulsate",
            animationDuration: "1s",
            animationIterationCount: "infinite",
            animationDirection: "alternate",
        },
    },
    "@keyframes pulsate": {
        from: { color: theme.palette.text.secondary },
        to: { color: theme.palette.action.disabledBackground },
    },
}))

export default function ExampleResult({ sample, expression, loading }) {
    const results = useMemo(() => {
        if (!sample) return { value: "" }
        if (!expression?.expression)
            return { value: "No expression providied", error: true }
        try {
            const results = getExpressionResults({ sample, expression })
            return { value: results }
        } catch (error) {
            return { error: true, value: error?.message || "Unknown error" }
        }
    }, [expression, sample])

    const value =
        typeof results.value !== "string"
            ? JSON.stringify(results.value)
            : results.value

    return (
        <ExampleResultBase
            value={!!sample ? value : "Select sample to see example results"}
            error={results.error}
            loading={loading}
        />
    )
}

export function ExampleResultBase({ value, error, loading }) {
    const classes = useStyles()

    return (
        <div
            className={clsx(
                classes.results,
                !loading && error && "error",
                loading && "loading",
            )}
        >
            {loading ? "Updating..." : value === "" ? "Empty String" : value}
        </div>
    )
}
