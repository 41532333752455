import React, { useCallback } from 'react';
import { useParams } from '@reach/router';

import {
    Switch, FormControlLabel, FormGroup, Typography, Card, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import common_styles from '../../styles/common_styles';
import ProcessTable from './ProcessTable';
import EditProcess from './EditProcess';
import { useQueryFCSingle } from '../../API/queryHooks';
import DialogWithLoading from '../General/DialogWithLoading';
import LearnMore, { LearnMoreEnum } from "../General/LearnMore";
import { useLocalForageState } from '../../utils/useLocalforageState';


const PROCESS_LIBRARY_TITLE = "Process Library";
const PROCESS_LIBRARY_INFO = "A Process in Skylab represents a single operation for processing a " +
    "material (material in/material out), which often have process variables (parameters or " +
    "conditions) that provide context and definition to the process. " +
    "Processes are used to define and describe the steps taken while making or testing the " +
    "Sample/Material and to document information necessary to recreate/retest the Sample in the " +
    "future. Processes are most commonly used to represent single operations used in the material " +
    "making process. ";

const useStyles = makeStyles(common_styles);

function ProcessLibrary({navigate}) {
    const classes = useStyles();
    const {processId} = useParams();
    const {data: processDefinition, isFetching} = useQueryFCSingle((processId !== "" && processId !== "new") ? {
        Model: "ProcessDefinition",
        filter: processId,
        fields: ["labs.lab"]
    } : undefined, { logName: "Full Process Definition"});
    const [selectedOnly, setSelectedOnly, selectedLoading] = useLocalForageState("ProcessLibrarySelected", false);
    const handleNew = useCallback(() => navigate("new"), [navigate]);
    const handleEdit = useCallback(row => navigate(row.original.id), [navigate]);
    const handleCloseEdit = useCallback(() => navigate(""), [navigate]);
    const openEdit = processId !== "" && (processId === "new" || processDefinition || isFetching);
    const idMatch = processId === processDefinition?.id || (processId === "new" && !processDefinition?.id)

    return (
        <div className={classes.layout}>
            <DialogWithLoading onClose={handleCloseEdit} open={!!openEdit} loading={isFetching && !processDefinition}>
                <EditProcess initialProcessDefinition={idMatch ? processDefinition : undefined} onClose={handleCloseEdit}/>
            </DialogWithLoading>
            <Card className={classes.paperBody} elevation={3}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <div>
                        <Typography className={classes.cardTitle} gutterBottom>
                            {PROCESS_LIBRARY_TITLE}
                        </Typography>
                        <Typography className={classes.cardBodyTextEnhanced}>
                            {PROCESS_LIBRARY_INFO}
                            <LearnMore model={LearnMoreEnum.process} />
                            <br />
                        </Typography>
                        <FormGroup row>
                            <FormControlLabel label="Only show selected processes" control={
                                <Switch checked={selectedOnly} onChange={(ev)=>setSelectedOnly(ev.target.checked)} color="primary"/>
                            }/>
                        </FormGroup>
                    </div>
                </div>
                <Grid container justify="flex-start" alignItems="flex-end" spacing={3}>
                    <Grid item xs={12}>
                        <ProcessTable 
                            selectedOnly={selectedOnly}
                            loading={selectedLoading}
                            onEdit={handleEdit}
                            onNew={handleNew}
                        />
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}

export default React.memo(ProcessLibrary);