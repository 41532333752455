import React, { useEffect, useCallback, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useAppStoreKey } from "../../../AppStore";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { getModelName } from "../utils";
import TopIcon from "mdi-material-ui/ChevronTripleUp";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import { LargeTooltip } from "../components/LargeTooltip";
import PlotModelResultsTab from "./PlotModelResultsTab";
import useTrainModel from "./useTrainModel";

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}

export default function PlotModelResults({ topAnchorRef }) {
  const [workflow,] = useAppStoreKey('Workflow')
  const { trainingState, errorState, ReloadTasks } = useTrainModel({})
  const [currentTab, setCurrentTab] = useAppStoreKey('MS/Training/CurrentTab')
  const [visibleTabs, setVisibleTabs] = useAppStoreKey('MS/Training/VisibleTabs')

  useEffect(() => {
    if (trainingState === undefined) {
      ReloadTasks()
    }
  }, [trainingState, ReloadTasks])

  const trainedModels = useMemo(() => {
    return workflow.models//.filter(model => !model.hide)
  }, [workflow.models])

  const submittedModels = useMemo(() => {
    if (trainingState === undefined) return []
    return trainingState.filter(state => state.operation === "train")
  }, [trainingState])

  const modelErrors = useMemo(() => {
    if (errorState === undefined) return []
    return errorState.filter(state => state.operation === "train")
  }, [errorState])

  const getModels = useCallback(() => {
    const allModels = trainedModels.concat(modelErrors).concat(submittedModels)
    return allModels
  }, [trainedModels, modelErrors, submittedModels])

  const getVisibleTabs = useCallback(() => {
    let indexes = []
    getModels().forEach((model, idx) => {
      if (model.hide === undefined || !model.hide) {
        indexes.push(idx)
      }
    })
    return indexes
  }, [getModels])

  useEffect(() => {
    if (currentTab === undefined) {
      if (visibleTabs === undefined) {
        setCurrentTab(getVisibleTabs()[0])
      } else {
        setCurrentTab(visibleTabs[0])
      }
    } else if (currentTab >= Math.max(...getVisibleTabs())) {
      setCurrentTab(getVisibleTabs()[getVisibleTabs().length - 1])
    }
  }, [currentTab, setCurrentTab, visibleTabs, getVisibleTabs])

  useEffect(() => {
    if (visibleTabs === undefined) {
      setVisibleTabs(getVisibleTabs())
    }
  }, [getModels, setVisibleTabs, getVisibleTabs, visibleTabs])

  const getTabValue = useCallback(() => {
    if (currentTab === undefined || visibleTabs === undefined) {
      return getVisibleTabs()[0]
    } else if (currentTab >= Math.max(...getVisibleTabs())) {
      return getVisibleTabs()[getVisibleTabs().length - 1]
    } else {
      return currentTab
    }
  }, [currentTab, visibleTabs, getVisibleTabs])

  const handleTabChange = (event, newValue) => {
    // newValue provides the VALUE in visibleTabs instead of the index
    setCurrentTab(newValue)
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="h6"
          style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
        >
          Model Results
        </Typography>
        <Tooltip title={<Typography variant="h6">Top</Typography>} placement="top">
          <IconButton
            onClick={() => topAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
            color="primary"
            style={{ textAlign: "left", marginTop: 16, marginBottom: 0, marginLeft: 60 }}
          >
            <TopIcon />
          </IconButton>
        </Tooltip>
      </div>

      <Tabs
        value={getTabValue()}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {getModels().map((model, idx) => {
          if (model.hide) {
            return ""
          } else {
            return <LargeTooltip value={idx} key={idx} placement="top" title={model.info.description ? model.info.description : ''}>
              <Tab key={`model${idx}`} label={getModelName(idx, { models: getModels() })} value={idx} />
            </LargeTooltip>
          }
        })}
      </Tabs>
      {/*plot the model results or the status stepper*/}
      {getModels().map((model, idx) => {
        if (model.hide) {
          return ""
        } else {
          return <TabPanel key={idx} value={currentTab} index={idx}>
            <PlotModelResultsTab model={model} id={idx} />
          </TabPanel>
        }
      })}
      <Divider variant="middle" />
    </>
  )
}