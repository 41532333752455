import React, { ReactNode, useCallback, useMemo } from 'react'
import { makeStyles, Tooltip, SvgIconTypeMap, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import StyledResizableBox from 'components/General/StyledResizableBox'
import common_styles from '../../styles/common_styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(
    theme =>
        ({
            ...common_styles(theme),
            toggleButton: {
                padding: theme.spacing(1),
                lineHeight: "initial",
                color: "rgba(255, 255, 255, 0.55)",
                "&.Mui-selected": {
                    color: "rgba(255, 255, 255, 1)",
                    backgroundColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.25)" : "rgba(0, 0, 0, 0.25)",
                    borderColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
                    boxShadow: theme.palette.type === "dark" ? "0 0 2px white" : "0 0 2px black"
                },
                whiteSpace: "noWrap",
            },
            displayItem: {
                margin: theme.spacing(1, 0),
                paddingTop: theme.spacing(1),
                width: "100%",
            },
            dividerContainer: {
                position: "relative",
                padding: theme.spacing(2, 0, 0, 0),
            },
        } as any),
) as any


export type DisplayTabs = DisplayTab[]
export type DisplayTab = {
    /** view label shown in the toggle button */
    title: string 
    /** (optional) Icon to display when the buttons are in icon mode */
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    /** the component node to be displayed (basically the view itself) */
    node: ReactNode
    /** (optional) the minimum height of the view */
    minHeight?: number
    /** (optional) the starting height of the view, if left undefined the view will not be resizable and take the size of the node */
    height?: number
}

let tab: Partial<DisplayTab> = {}
tab.title = ""

export type EditSampleSetViewSelectorProps = {
    /** definitions of all of the tabs */
    displayTabs: DisplayTabs
    /** tabs to be displayed */
    display: string[]
    /** callback for updating the currently toggled on tabs */
    onChange: (selected: string[]) => void
    /** if true, icon should be used in place of text */
    icons?: boolean
}

/**
 * Toggle buttons used for selecting views to display. Used in the toolbars of EditViews like EditSampleSet
 */
export const ResizableViewsSelector: React.FC<EditSampleSetViewSelectorProps> = ({
    displayTabs,
    display,
    onChange,
    icons,
}) => {
    const classes = useStyles()

    const handleChange = useCallback((ev: any, selected: string[]) => {
        onChange(selected)
    }, [onChange])

    return (
        <ToggleButtonGroup
            className={classes.toggleGroup}
            value={display}
            onChange={handleChange}
        >
            {displayTabs.map(tab => 
                <ToggleButton
                    key={tab.title}
                    className={classes.toggleButton}
                    value={tab.title}
                >
                    {(!tab.Icon || !icons) ? (
                        tab.title
                    ) : (
                        <Tooltip title={tab.title}>
                            <tab.Icon fontSize="small" />
                        </Tooltip>
                    )}
                </ToggleButton>
            )}
        </ToggleButtonGroup>
    )
}

export type ResizableViewsProps = {
    /** tabs to be displayed */
    display: string[]
    /** definitions of all of the tabs */
    displayTabs: DisplayTabs
    /** if true the order will match te order of "displayTabs" otherwise the order of display will be used */
    order?: boolean
}

/**
 * Component for displaying the given views in a resizable box
 */
export const ResizableViews: React.FC<ResizableViewsProps> = ({
    display,
    displayTabs,
    order
}) => {
    const classes = useStyles()
    const ordered = useMemo(() => {
        if (!order) return display
        return displayTabs?.map(val => display.find(d => val.title === d) || "") 
    }, [display, displayTabs, order])
    return (
        <div>
            {ordered.map(title => {
                const tab = displayTabs.find(t => t.title === title)
                if (!tab) return undefined
                return (tab.height && tab.node) ? 
                <div className={classes.displayItem} key={title}>
                    <StyledResizableBox height={tab.height} minHeight={tab.minHeight}>
                        {tab.node}
                    </StyledResizableBox>
                </div>
                :
                <div className={classes.displayItem} key={title}>
                    {tab.node}
                    <div className={classes.dividerContainer}>
                        <Divider variant="middle" />
                    </div>
                </div>
            })}
            {!ordered.length && (
                <Typography
                    variant="h5"
                    color="error"
                    style={{ textAlign: "center", marginTop: "16px" }}
                >
                    No display options selected. Click the toggle buttons on the
                    left side of the toolbar to view information about the set.
                </Typography>
            )}
        </div>
    )
}

/**
 * Component for displaying the given views in a accordian
 */
export const AccordianViews: React.FC<ResizableViewsProps> = ({
    display,
    displayTabs,
}) => {
    const classes = useStyles()
    return (
        <div>
            {displayTabs.map(tab => {
                return <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="subtitle1">{tab.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {(tab.height && tab.node) ? 
                        <div className={classes.displayItem} key={tab.title}>
                            <StyledResizableBox height={tab.height} minHeight={tab.minHeight}>
                                {tab.node}
                            </StyledResizableBox>
                        </div>
                        :
                        <div className={classes.displayItem} key={tab.title}>
                            {tab.node}
                        </div>}
                    </AccordionDetails>
                </Accordion> 
            })}
        </div>
    )
}