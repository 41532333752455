const isIE = /*@cc_on!@*/ false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;


export function SaveNonImageFile(blob, filename) {
    let objectURL = URL.createObjectURL(blob);
    if (isEdge || isIE) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let anchor = document.createElement("a");
      document.body.appendChild(anchor);
      anchor.href = objectURL;
      anchor.target = "_blank";
      anchor.download = filename;
      anchor.click();
      document.body.removeChild(anchor);
    }
  }

export function sleep(milliseconds) {
  if (milliseconds > 0) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds){
        break;
      }
    }
  }

}


export async function poll(fn, fnCondition, ms, args) {
  let result = await fn(args);
  while (!fnCondition(result)) {
    await wait(ms);
    result = await fn(args);
  }
  return result;
}

export function wait(ms = 1000) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}