import React, { useCallback, useMemo, useRef } from 'react'
import { Grid, makeStyles, TextField, Typography, Tooltip } from '@material-ui/core'
import MaterialSelector from './MaterialSelector'
import ProcessSelector from './ProcessSelector'
import PropertySelector from './PropertySelector'
import { useBufferState } from 'utils/utils'
import { updateColumnsFromProcessNodes } from 'components/FlowChart/processChartUtils'

export const PROCESS_CHART_INFO_PLACEHOLDER_OFFSET = 60;

const useStyles = makeStyles(theme => ({
    nodeInfo: {
        paddingTop: theme.spacing(1),
    },
    infoPlaceholder: {
        height: PROCESS_CHART_INFO_PLACEHOLDER_OFFSET,
        width: "100%",
        textAlign: "center",
    },
}))

/**
 * Component for editing the properties of a selected flow chart node
 * @param {*} props 
 * @param {string[]} props.selectedNodes array of selected node ids
 * @param {ProcessChart} props.processChart the process chart
 * @param {Sample[]} props.materialDefs object definitions for all the objects within the nodes
 * @param {ProcessDefinition[]} props.processDefs object definitions for all the objects within the nodes
 * @param {PropertyDefinition[]} props.propertyDefs object definitions for all the objects within the nodes
 * @param {*} props.onChangeProcessChart callback for changes to the process chart
 * @param {*} props.onChangeNode callback for changes to the selected definitions of the nodes
 */
export function NodeProperties({
    selectedNodes,
    processChart,
    materialDefs,
    processDefs,
    propertyDefs,
    onChangeProcessChart,
    onChangeNode,
}) {
    const classes = useStyles()
    const selectedId = selectedNodes[0]
    const rootSelected = processChart.nodes?.[selectedId]

    const matDefsSelected = useSelectedDefs(rootSelected?.materialDefs, materialDefs)
    const procDefsSelected = useSelectedDefs(rootSelected?.processDefs, processDefs)
    const propDefsSelected = useSelectedDefs(rootSelected?.propertyDefs, propertyDefs)

    const groupedMat = useGroupedDefs(processChart, materialDefs, "materialDefs")
    const groupedProc = useGroupedDefs(processChart, processDefs, "processDefs")
    const groupedProp = useGroupedDefs(processChart, propertyDefs, "propertyDefs")

    const handleLabelChange = useLabelChange(onChangeProcessChart, processChart, rootSelected, selectedId)

    const handleChangeMatDef = useDefinitionsChangeCallback("materialDefs", onChangeProcessChart, onChangeNode, processChart, rootSelected, selectedId)
    const handleChangeMProcDef = useDefinitionsChangeCallback("processDefs", onChangeProcessChart, onChangeNode, processChart, rootSelected, selectedId)
    const handleChangePropDef = useDefinitionsChangeCallback("propertyDefs", onChangeProcessChart, onChangeNode, processChart, rootSelected, selectedId)

    const existingLabels = useExistingLabels(processChart)

    const selector = useMemo(() => {
        if (!rootSelected?.type) return null
        switch (rootSelected.type) {
            case "material":
                return <MaterialSelector
                            selected={matDefsSelected}
                            onChange={handleChangeMatDef}
                            fullWidth
                            appendOptions={groupedMat}
                            groupBy={groupBy}
                        />
            case "process":
                return <ProcessSelector
                    selected={procDefsSelected}
                    onChange={handleChangeMProcDef}
                    fullWidth
                    selectSingle
                    appendOptions={groupedProc}
                    groupBy={groupBy}
                />
            case "property":
            case "output":
                return <PropertySelector
                    selected={propDefsSelected}
                    onChange={handleChangePropDef}
                    fullWidth
                    appendOptions={groupedProp}
                    groupBy={groupBy}
                />
            default:
                return null
        }
    }, [groupedMat, groupedProc, groupedProp, handleChangeMProcDef, handleChangeMatDef, handleChangePropDef, matDefsSelected, procDefsSelected, propDefsSelected, rootSelected?.type])

    return (
        <Grid container spacing={1}>
            {(rootSelected && selectedNodes.length === 1) ?
                <>
                    <Grid item xs={12} md={3} xl={2}>
                        <LabelField
                            defaultValue={rootSelected.label || ""}
                            onBlur={handleLabelChange}
                            existing={existingLabels}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} xl={10}>
                        {selector}
                    </Grid>
                    {rootSelected.type === "process" ?
                        <div className={classes.infoPlaceholder}>
                            <Typography variant="subtitle">
                                ***NOTE: Process nodes can have only ONE process associated with them***
                            </Typography>
                        </div>
                        :
                        <></>
                    }
                </>
                :
                <div className={classes.infoPlaceholder}>
                    <Typography variant="h6">
                        Select a single element to change label or details
                    </Typography>
                    <Typography variant="subtitle2">
                        (You may need to refresh this page to pull in saved flow chart arrangements)
                    </Typography>
                </div>}
        </Grid>
    )
}

function groupBy(option) {
    return option.group?.nodeId === undefined ? "*New*" : (option.group?.nodeId ? option.group?.label || "*" : "*Ungrouped*")
}

function useExistingLabels(chart) {
    return useMemo(() => {
        if (!chart.nodes) return undefined
        return Object.values(chart.nodes).reduce((agg, val) => {
            if (val?.label) agg.push(val.label)
            return agg
        }, [])
    }, [chart.nodes])
}

const LabelField = React.forwardRef(function BufferTextField({defaultValue, onChange, multiline, existing, onBlur, ...props}, ref){
    const inputRef = useRef();
    const [value, setValue] = useBufferState(defaultValue);
    const invalid = value === "" || (value !== defaultValue && existing?.includes(value))
    return (
        <Tooltip title={invalid ? "A unique label is required" : undefined} open={!!invalid}>
            <TextField
                ref={ref} 
                inputRef={inputRef}
                value={value} 
                onChange={ev => {
                    setValue(ev.target.value)
                    onChange?.(ev)
                }}
                onBlur={(ev) => {
                    if (!invalid) {
                        onBlur?.(ev)
                    }
                    else {
                        setValue(defaultValue)
                    }
                }}
                onKeyDown={multiline ? undefined : ev => {if (inputRef.current && ev.key === "Enter") {inputRef.current.blur()}}}
                multiline={multiline}
                error={invalid}
                fullWidth
                label="Group Label"
                placeholder="Label"
                variant="outlined"
                size="small"
                {...props}
            />
        </Tooltip>
    )
})

function useSelectedDefs(selectedDefs, allDefs) {
    return useMemo(() => 
        selectedDefs && 
        Object.entries(selectedDefs).map(([id, def]) => 
            allDefs?.find(d => d.id === id) || { id, title: def.title })
    ,[allDefs, selectedDefs])
}

function useGroupedDefs(processChart, definitions, key) {
    return useMemo(() => {
        const grouped = []
        if (!processChart?.nodes) return grouped
        for (const [nodeId, node] of Object.entries(processChart.nodes)) {
            if (node[key]){
                for (const [defId, d] of Object.entries(node[key])) {
                    const def = definitions?.find(def => def.id === defId) || {id: defId, title: d.title}
                    grouped.push({...def, group: {nodeId, label: node.label}})
                }
            }
        }
        return grouped
    }, [definitions, key, processChart.nodes])
}

function useLabelChange(onChangeProcessChart, processChart, rootSelected, selectedId) {
    return useCallback((ev) => {
        const newLabel = ev.target.value
        if (rootSelected?.label !== newLabel) {
            const newChart = {...processChart}
            newChart.nodes = {...newChart.nodes}
            newChart.nodes[selectedId] = {...newChart.nodes[selectedId]}
            newChart.nodes[selectedId].label = newLabel
            onChangeProcessChart(newChart, "LabelChange")
        }
    }, [onChangeProcessChart, processChart, rootSelected?.label, selectedId])
}

function useDefinitionsChangeCallback(defKey, onChangeProcessChart, onChangeNode, processChart, rootSelected, selectedId) {
    return useCallback((ev, values) => {
        if (rootSelected) {
            const newDefs = values.length ? values.reduce((agg, def) => {
                agg[def.id] = rootSelected[defKey]?.[def.id] || { title: def.title }
                return agg
            }, {}) : undefined
            let newChart = {...processChart}
            newChart.nodes = {...newChart.nodes}
            newChart.nodes[selectedId] = {...newChart.nodes[selectedId]}
            newChart.nodes[selectedId][defKey] = newDefs

            // removed defs
            const removed = []
            if (processChart?.nodes?.[selectedId]?.[defKey]){
                for (const defId of Object.keys(processChart.nodes[selectedId][defKey])) {
                    if (!newDefs?.[defId]) {
                        removed.push(defId)
                    }
                }
            }

            // check and track definitions moved between nodes
            let moved = []
            for (const val of values) {
                if (val.group?.nodeId && val.group.nodeId !== selectedId) {
                    moved.push({oldNodeId: val.group.nodeId, defId: val.id})

                    // remove old copy of definition
                    if (newChart.nodes[val.group.nodeId]?.[defKey]?.[val.id]) {
                        if (newChart.nodes[val.group.nodeId] === processChart.nodes?.[val.group.nodeId]) {
                            newChart.nodes[val.group.nodeId] = {...newChart.nodes[val.group.nodeId]}
                        }
                        if (newChart.nodes[val.group.nodeId][defKey] === processChart.nodes?.[val.group.nodeId]?.[defKey]) {
                            newChart.nodes[val.group.nodeId][defKey] = {...newChart.nodes[val.group.nodeId][defKey]}
                        }         
                        delete newChart.nodes[val.group.nodeId][defKey][val.id]
                    }
                }
            }

            const newCol = updateColumnsFromProcessNodes(newChart.columns, newChart.nodes, values, values, values)
            if (newCol !== newChart.columns) {
                if (newChart === processChart) newChart = {...newChart}
                newChart.columns = newCol
            }
            if (moved.length || removed.length) {
                onChangeNode(selectedId, removed, moved)
                onChangeProcessChart(newChart)
            }
            else {
                onChangeProcessChart(newChart, "Change Nodes")
            }
        }
    }, [defKey, onChangeProcessChart, onChangeNode, processChart, rootSelected, selectedId])
}