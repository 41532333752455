import React, { useRef, useState } from "react"
import Plot from "react-plotly.js";
import IconButton from "@material-ui/core/IconButton";
import { plotSizeMapper } from "../DataVizPlots/utils";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles"
import common_styles from "../../../styles/common_styles"
import { useAppStoreKey } from "../../../AppStore";
import SizeMIcon from "mdi-material-ui/SizeM";
import SizeLIcon from "mdi-material-ui/SizeL";

import ArrowCollapseIcon from "mdi-material-ui/ArrowCollapse";
import ArrowExpandIcon from "mdi-material-ui/ArrowExpand";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress, Paper } from "@material-ui/core";
import { plotHeightMapper } from "./ModelSelector";


const useStyles = makeStyles(common_styles);

function CompareModelCard({ getTrace, getLayout, getIcon, handleRemovePlot,
  idx, isLoading }) {
  const classes = useStyles();
  const [plotWidthSize, setPlotWidthSize] = useState('m')
  const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
  const parentRef = useRef(null);
  const plotTitle = getLayout().title
  const plotType = Object.keys(plotHeightMapper).filter(key => plotTitle.toLowerCase().includes(key.toLowerCase()))[0]

  const handleSizeChange = (event, newWidthSize) => {
    setPlotWidthSize(newWidthSize)
    if (newWidthSize === 'hide') {

    }
    setMSState({
      ...MSState,
      modelComparePlotLayout: MSState.modelComparePlotLayout.map((plot, i) => {
        if (i === idx) {
          return { ...plot, w: plotSizeMapper[newWidthSize] }
        }
        return plot;
      })
    })
  }

  const handleHidePlot = () => {
    if (MSState.modelComparePlotParams[idx].expand) {
      // plot is expanded, hide it
      setMSState({
        ...MSState,
        modelComparePlotLayout: MSState.modelComparePlotLayout.map((plot, i) => {
          if (i === idx) {
            return { ...plot, h: 4, minH: 4, maxH: 4, w: 3, maxW: 3, minW: 3 };
          }
          return plot;
        }),
        modelComparePlotParams: MSState.modelComparePlotParams.map((plot, i) => {
          if (i === idx) {
            return { ...plot, expand: false };
          }
          return plot;
        })
      })
    } else {
      // plot is collapsed, open it
      setMSState({
        ...MSState,
        modelComparePlotLayout: MSState.modelComparePlotLayout.map((plot, i) => {
          if (i === idx) {
            const height = plotHeightMapper[plotType]
            return { ...plot, h: height, minH: height, maxH: height, w: 6, maxW: undefined, minW: undefined };
          }
          return plot;
        }),
        modelComparePlotParams: MSState.modelComparePlotParams.map((plot, i) => {
          if (i === idx) {
            return { ...plot, expand: true };
          }
          return plot;
        })
      })
    }
  }

  const plotSizeButton = () => {
    if (!MSState.modelComparePlotParams[idx].expand) { return }

    return (
      <ToggleButtonGroup
        value={plotWidthSize}
        exclusive
        onChange={handleSizeChange}
        aria-label="text alignment"
      >
        <ToggleButton value="m" aria-label="centered">
          <SizeMIcon />
        </ToggleButton>
        <ToggleButton value="l" aria-label="right aligned">
          <SizeLIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }


  return (
    <Paper variant="outlined" style={{ margin: 24 }}>
      <div style={{ margin: 24 }}>
        <div style={{ display: "flex", flexDirection: "row", marginLeft: 12 }}>
          {getIcon && getIcon()}
          <Typography variant="subtitle1" style={{ textAlign: "left", marginTop: 12, marginLeft: 12, marginRight: 12 }}>
            {plotType}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}>
            {plotSizeButton()}
            <IconButton
              color="primary"
              onClick={() => {
                handleHidePlot(idx)
              }}
            >
              {MSState.modelComparePlotParams[idx].expand ? <ArrowCollapseIcon /> : <ArrowExpandIcon />}
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => {
                handleRemovePlot && handleRemovePlot(idx)
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {MSState.modelComparePlotParams[idx].expand &&
          <div>
            {isLoading ? <CircularProgress
              size={200}
              style={{ marginLeft: 245, marginTop: 100 }}
              color={'primary'}
            /> :
              <div className={classes.plot} ref={parentRef}>
                <Plot data={getTrace()} layout={getLayout()} style={{ alignItems: "inherit" }} />
              </div>
            }
          </div>
        }
      </div>
    </Paper>
  )
}

export default React.memo(CompareModelCard);