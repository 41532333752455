/**
 * @format
 */
import React, { useCallback, useState, useMemo } from "react"
import { useTable, useFlexLayout, useSortBy } from "react-table"
import StyledReactTable, {
    defaultColumn,
    defaultFilterTypes,
} from "../ReactTable/StyledReactTable"
import { addDeleteColumn } from "../ReactTable/addDeleteColumn"
import TableButtonGroup from "../General/TableButtonGroup"
import NewButton from "../General/NewButton"
import ExpressionVariableSelectorDialog from "./ExpressionVariableSelectorDialog"
import { Typography } from "@material-ui/core"
import { addOpenColumn } from "../ReactTable/addOpenColumn"
import { getVariableResults } from "./expressionUtils"

const columns = [
    {
        Header: "Name",
        accessor: "name",
        width: 120,
    },
    {
        Header: "Expression",
        accessor: "expression",
        width: 240,
    },
    {
        Header: "Example Result",
        accessor: "results",
        width: 120,
    },
]

export default function ExpressionVariableTable({
    variables,
    onChange,
    sample,
    setSample,
}) {
    const [variable, setVairable] = useState(undefined)

    const handleDelete = useCallback(
        row => {
            if (row.index < variables.length) {
                const newData = variables.slice()
                newData.splice(row.index, 1)
                onChange && onChange(newData)
            }
        },
        [onChange, variables],
    )

    const handleEdit = useCallback(row => {
        setVairable({ ...row.original, index: row.index })
    }, [])

    const data = useMemo(() => {
        return variables.map(v => {
            let results
            try {
                results = getVariableResults({ sample, serializedVariable: v })
            } catch (error) {
                results = error.message
            }
            return { ...v, results }
        })
    }, [sample, variables])

    const table = useTable(
        {
            initialState: { pageSize: 5 },
            columns,
            defaultColumn,
            data,
            autoResetPage: false,
            autoResetFilters: false,
            autoResetSortBy: false,
            filterTypes: defaultFilterTypes,
            onDelete: handleDelete,
            onEdit: handleEdit,
            rowsDisplayed: 5,
        },
        useFlexLayout,
        useSortBy,
        addOpenColumn,
        addDeleteColumn,
    )

    return (
        <div>
            <ExpressionVariableSelectorDialog
                variable={variable}
                variables={variables}
                sample={sample}
                setSample={setSample}
                onClose={(ev, source, value, index) => {
                    setVairable(undefined)
                    if (onChange && source === "add") {
                        if (index >= 0) {
                            const newVars = [...variables]
                            newVars[index] = value
                            onChange(newVars)
                        } else {
                            onChange(variables.concat(value))
                        }
                    }
                }}
            />
            <TableButtonGroup
                smallPadding
                left={
                    <Typography
                        variant="subtitle2"
                        color="primary"
                        style={{ marginLeft: 8 }}
                    >
                        Variables
                    </Typography>
                }
            >
                <NewButton onClick={() => setVairable({ key: "Properties" })}>
                    Property
                </NewButton>
                <NewButton onClick={() => setVairable({ key: "Components" })}>
                    Component
                </NewButton>
                <NewButton onClick={() => setVairable({ key: "Tests" })}>
                    Test
                </NewButton>
            </TableButtonGroup>
            <StyledReactTable {...table} hideNoResultsMessage />
        </div>
    )
}
