import React, { useState, useCallback, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import common_styles from "../../../styles/common_styles";

import { GetRemovePlotFunc, InitVizParams, plotlyColorSchemeRGBA } from "./utils";
import { ChartHistogram } from "mdi-material-ui";
import PlotCard from "./PlotCard";
import ColumnMultiSelectField from "./ColumnMultiSelectField";
const useStyles = makeStyles(common_styles);

function DataHistPlot({ data, handleRemovePlot, idx, visible, savedParams='', plot_uuid }) {
  const classes = useStyles();
  const [headers, setHeaders] = useState([])
  const [plotData, setPlotData] = useState({})
  const [plotWidthSize, setPlotWidthSize] = InitVizParams(savedParams, 'plotWidthSize','m')
  // NOTE: Plotly nbins does not actually force render with nbins...
  // https://plotly.com/javascript/reference/histogram/#histogram-nbinsx
  const [nbins, setnBins] = InitVizParams(savedParams, 'nbins', 10)

  const [selectedAxis, setSelectedAxis] = InitVizParams(savedParams,'selectedAxis', [])
  const [currentSheet, setCurrentSheet] = InitVizParams(savedParams, 'currentSheet', '')
  const [paramsToSave, setParamsToSave] = useState({})
  const plotType = 'dataHist'
  const cardName = 'Histogram Plot'

  // update params to save
  useEffect(() => {
    setParamsToSave({ ...paramsToSave,
      selectedAxis: selectedAxis,
      currentSheet: currentSheet,
      nbins: nbins,
      plotWidthSize: plotWidthSize
    })
  }, [selectedAxis, currentSheet, nbins, plotWidthSize]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleRemoveCurrentPlot = GetRemovePlotFunc(savedParams, handleRemovePlot)

  const getForm = () => {
    return (
      <>
        <ColumnMultiSelectField
          label={"Headers"}
          value={selectedAxis}
          setValue={setSelectedAxis}
          headers={headers}
        />
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel>Number of Bins</InputLabel>
          <Input
            label="Max number of bins"
            type="number"
            value={nbins}
            onChange={ev => setnBins(ev.target.value)}
            style={{ width: "50%" }}
          />
        </FormControl>
      </>
    )
  }

  const getIcon = () => {
    return <ChartHistogram style={{ marginTop: 14 }} />
  }

  const getTrace = () => {
    const trace = []
    selectedAxis.forEach((axis, idx) => {
      const x = plotData[axis]
      if (!x) return []
      const newTrace = {
        x: x,
        type: 'histogram',
        name: axis,
        marker: {
          color: plotlyColorSchemeRGBA(idx, 0.7),
          line: {
            color: plotlyColorSchemeRGBA(idx, 1),
            width: 1
          }
        },
        nbinsx: nbins,
      }
      trace.push(newTrace)
    })
    return trace
  }

  const getLayout = () => {
    return { autosize: true, title: "Histogram of " + selectedAxis }
  }

  const onSheetChange = useCallback((currentHeaders, currentData) => {
    setHeaders(currentHeaders)
    setPlotData(currentData)
    if (setSelectedAxis && selectedAxis.length === 0) {
      setSelectedAxis([currentHeaders[0]])
    }
  }, [setHeaders, setPlotData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PlotCard
      plot_uuid={plot_uuid}
      data={data}
      getTrace={getTrace}
      getLayout={getLayout}
      getForm={getForm}
      getIcon={getIcon}
      cardName={cardName}
      savedParams={savedParams}
      currentSheet={currentSheet}
      setCurrentSheet={setCurrentSheet}
      paramsToSave={paramsToSave}
      plotWidthSize={plotWidthSize}
      setPlotWidthSize={setPlotWidthSize}
      setParamsToSave={setParamsToSave}
      plotType={plotType}
      idx={idx}
      handleRemovePlot={handleRemoveCurrentPlot}
      onSheetChange={onSheetChange}
      visible={visible}
    />
  )
}

export default React.memo(DataHistPlot);