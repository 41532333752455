import React, { useMemo, useContext, useState } from 'react';
import { AccountContext } from "context";

import {
  Grid, FormControlLabel, Checkbox, Button, Typography, Tooltip
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Lock, LockOpen } from '@material-ui/icons';

import EditCardBase, {
  useCheckReadOnly,
  useDefaultMutateHandlers,
} from "../General/EditCardBase";
import ConfirmationDialog, {
  useConfirmDialog,
} from "../General/ConfirmationDialog";
import BufferTextField from "../General/BufferTextField";
import { value } from "utils/useFormState/useFormStateUtils";
import FreeSoloSelector from "../General/FreeSoloSelector"
import PermissionSelector from "../General/LabSelector"
import { DefaultLabContext } from "../../DefaultLabProvider"
import InstrumentSelector from "../General/InstrumentSelector";

import common_styles from '../../styles/common_styles';
import useFormState from "utils/useFormState/useFormState"
import { LabToTest, Test } from 'schema/models';
import { ObjectTree } from 'components/General/ObjectTree';
import ProcessorSelector from './ProcessorSelector';
import { SingleTestFileTab } from 'components/MaterialsLibrary/FileTab';
import MaterialSelector from 'components/General/MaterialSelector';
import TestMultiPlot from 'components/SampleSetLibrary/Utilities/TestMultiplot';


const Model = "Test";

const useStyles = makeStyles(theme => ({ ...common_styles(theme) }));
function EditTestCard({ initTest: initTestRaw, onClose }) {
  const classes = useStyles();
  const account = useContext(AccountContext);
  const defaultLab = useContext(DefaultLabContext);


  const initTest = useMemo(
    () =>
      initTestRaw ||
      new Test({
        labs: [
          new LabToTest({
            lab: defaultLab,
            accessLevel: "writer",
          }),
        ],
        samples: [],
        processor: "",
        files: []
      }),
    [defaultLab, initTestRaw],
  )
  const invalidators = useMemo(
    () => [
      {
        path: "title",
        validator: title => {
          if (!title || title === "") return "Name required"
        },
      },],
    [],
  )

  const {
    data: test,
    updateData,
    validateAll,
    undoRedo,
    status,
  } = useFormState({
    initialState: { data: initTest },
    validators: invalidators,
  })
  const undo = useMemo(
    () => ({ callback: undoRedo.onUndo, name: undoRedo.undoName }),
    [undoRedo.onUndo, undoRedo.undoName],
  )
  const redo = useMemo(
    () => ({ callback: undoRedo.onRedo, name: undoRedo.redoName }),
    [undoRedo.onRedo, undoRedo.redoName],
  )

  const userCanOnlyRead = useCheckReadOnly({
    initialObject: initTest,
    object: test,
  })
  const [readOnly, setReadOnly] = useState(true);

  const {
    handleDelete,
    handleSave,
    handleCopy,
    handleSaved,
  } = useDefaultMutateHandlers({
    Model,
    onClose,
    initialObject: initTest,
    object: test,
    validateAll,
    objectName: "Test",
    onCopy(newTest) {
      updateData(newTest, {
        name: `Copy ${initTest?.title}`,
      })
    },
    updateData,
    account,
  })

  const {
    getConfirmation: confirmSave,
    props: confirmContributorsProps,
  } = useConfirmDialog({
    callback: handleSave,
    title: "Confirm Save",
    message:
      "Doing so will modify this Test. Any data you've deleted will no longer be recoverable.",
    autoConfirm: useMemo(() => {
      if (!test.id) {
        return true;
      }
      return false; // Trigger call on modifying a test that's already saved.
    }, [test.id]),
  })
  const {
    getConfirmation: confirmDelete,
    props: confirmDeleteProps,
  } = useConfirmDialog({
    callback: handleDelete,
    title: "Confirm Delete Test",
    message:
      "Deleting cannot be undone, are you sure you wish to permanently delete this Test?",
  })

  const LOCK_TEXT = "Toggle Read-Only mode. LOCKING does NOT SAVE TEST. (Use 'Save' button above to save Test.) ";
  const ATTACHED_FILES_TEXT = "CLICK on a file to download it. To upload, use the Material Library portal. This is READ-ONLY."

  return (
    <EditCardBase
      onDelete={confirmDelete}
      onSave={confirmSave}
      onSaved={handleSaved}
      onCopy={handleCopy}
      onClose={onClose}
      title={initTest?.title}
      editing={test.id}
      objectName="Test"
      cardDetail="Define the Test below by filling in the associated fields"
      undo={undo}
      redo={redo}
      readOnly={readOnly}
      data-testid="edit Test"
    >
      <ConfirmationDialog {...confirmDeleteProps} />
      <ConfirmationDialog {...confirmContributorsProps} />
      <Grid container spacing={2} alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tooltip title={LOCK_TEXT} >
              <Button onClick={() => { if (!userCanOnlyRead) { setReadOnly(!readOnly) } }}>
                {readOnly ? <Lock /> : <LockOpen />}
              </Button>
            </Tooltip>
            <Typography
              variant="subtitle2"
              className={classes.cardSubtitle}
            >
              Basic Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BufferTextField
              size="small"
              required
              defaultValue={test.title || ""}
              error={!!status?.invalid?.title?.[value]}
              helperText={status?.invalid?.title?.[value]}
              onBlur={ev => {
                updateData(
                  { title: ev.target.value },
                  { name: "Title Edit" },
                )
              }}
              fullWidth
              label="Name"
              placeholder="Enter Test Name"
              variant="outlined"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FreeSoloSelector
              value={test.tags}
              onChange={(ev, newVal) =>
                updateData(
                  { tags: newVal },
                  { name: "Tags Edit" },
                )
              }
              fullWidth
              label="Tags and Keywords"
              placeholder={
                test.tags?.length === 0 ? "" : ""
              }
              helperText="Press enter between keywords"
              variant="outlined"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox checked={test.valid} onChange={() => updateData({ valid: !test.valid }, { name: "Test Edit" })} disabled={readOnly} />} label="Valid Test" />
          </Grid>
          <Grid item xs={12}>
            <ProcessorSelector
              value={[{ id: test.processor?.replace("etly.tabular.", ""), label: test.processor }]}
              onChange={(ev, procs) =>
                updateData(
                  { processor: "etly.tabular." + (procs[0]["id"] ? procs[0]["id"] : "undefined_processor") },
                  { name: "Processor Edit" },
                )
              }
              fullWidth
              label="Processor"
              helperText="Select an ETLY Processor"
              variant="outlined"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <InstrumentSelector
              value={[test.instrument]}
              onChange={(ev, instruments) =>
                updateData(
                  { instrument: instruments[0] },
                  { name: "Instrument Edit" },
                )
              }
              fullWidth
              label="Instrument"
              selectSingle
              helperText="Select a single Instrument"
              variant="outlined"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialSelector
              value={test.samples}
              onChange={(ev, samples) =>
                updateData(
                  { samples: samples },
                  { name: "Sample Edit" },
                )
              }
              fullWidth
              label="Samples"
              helperText="Link Samples to this Test"
              variant="outlined"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className={classes.cardSubtitle}
            >
              Access Permissions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PermissionSelector
              error={!!status?.invalid?.labs?.[value]}
              helperText={status?.invalid?.labs?.[value]}
              selected={test.labs || []}
              onSelect={labs => {
                updateData({ labs }, { name: "Labs Edit" })
              }}
              variant="outlined"
              Constructor={LabToTest}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <SingleTestFileTab
              files={test.files}
              instrumentId={test.instrument}
              setNewFiles={files => { updateData({ files: files }, { name: "Files Edit" }) }}
              setFileDeletion={pendingDeleteIds => { updateData({ files: test.files.filter(file => !pendingDeleteIds.includes(file.id)) }, { name: "File Deletion Edit" }) }}
              disabled={true}
              message={ATTACHED_FILES_TEXT}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              className={classes.cardSubtitle}
            >
              Test Metadata
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ObjectTree payload={test.meta}
              onSelect={undefined}
              root={"object"}
            />
          </Grid>
          <Grid item xs={12}>
            <TestMultiPlot selectedIds={[test.id]} title="Test Plot" useTitle={true} resource="tests" />
          </Grid>
        </Grid>
      </Grid>
    </EditCardBase>
  );
}
export default React.memo(EditTestCard);