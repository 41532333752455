/**
 * @format
 */
import { useReducer, useMemo, useCallback, useRef, useEffect } from "react"
import { deepmerge, replaceArrayMerge } from "utils/deepmerge"
import isMatch from "lodash.ismatch"
import set from "lodash.set"

import {
    State,
    Status,
    DataHistory,
    useFormStateProps,
    useFormPropsReturn,
    UpdateOptions,
    UpdateData,
    UpdateStatus,
    UndoRedo,
    Action,
    UpdateTransform,
    Validator,
    UpdateState,
    UpdateStatusTransform,
} from "./useFormStateTypes"
import {
    RecursivePartial,
    value,
    trimHandingKeys,
    setMatching,
    clearEmptyKeys,
    checkKey,
    getStatus,
} from "./useFormStateUtils"
import { get, has } from "lodash"
import { deepDifference } from "utils/utils"

const HISTORY_MAX = 100

/** State management hook for complex form data. See "Edit State Management - useFormState.md" for more details */
export function useFormState<O extends object>({
    transforms,
    statusTransforms,
    validators,
    initialState,
}: useFormStateProps<O>): useFormPropsReturn<O> {
    const mounted = useRef(true)
    useEffect(
        () => () => {
            mounted.current = false
        },
        [],
    )
    const [state, dispatch] = useReducer(reducer, initialState)

    const updateState: UpdateState<O> = useCallback(
        (updates: RecursivePartial<State<O>>, options?: UpdateOptions) => {
            dispatch({ type: "state", updates, options })
        },
        [],
    )

    const updateStatus: UpdateStatus<O> = useCallback(
        (updates: RecursivePartial<Status<O>>, options?: any) => {
            dispatch({
                type: "status",
                updates,
                statusTransforms: statusTransforms as
                    | UpdateStatusTransform<object>[]
                    | undefined,
                options,
            })
        },
        [statusTransforms],
    )

    const updateData: UpdateData<O> = useCallback(
        (
            updates:
                | RecursivePartial<O>
                | undefined
                | ((updates: RecursivePartial<O>) => RecursivePartial<O>),
            options?: UpdateOptions,
        ) => {
            dispatch({
                type: "data",
                updates,
                options,
                transforms,
                validators,
            })
        },
        [transforms, validators],
    )

    //handle auto validation
    useEffect(() => {
        const validatorProps = state.validatorProps
        if (validatorProps?.length) {
            validate(validatorProps, dispatch, mounted)
        }
    }, [state.validatorProps])

    // build validate all
    const validateAll = useCallback(
        // @ts-ignore
        async (options?) => {
            const validatorProps = readyValidators(
                validators,
                state.data,
                state.data,
                state.status,
                options,
                true,
            )
            if (validatorProps?.length) {
                return validate(validatorProps, dispatch, mounted)
            }
            return []
        },
        [state.data, state.status, validators],
    )

    // build undo/redo return
    const undoRedo = useMemo(() => {
        const length = state.history?.records.length || 0
        const offset = state.history?.offset || 0
        const hasUndo = length - offset > 1
        const hasRedo = offset > 0
        const onUndo = hasUndo
            ? () => {
                  dispatch({ type: "undo" })
              }
            : undefined
        const onRedo = hasRedo
            ? () => {
                  dispatch({ type: "redo" })
              }
            : undefined
        const undoName = hasUndo
            ? state.history?.records[length - offset - 1]?.name
            : undefined
        const redoName = hasRedo
            ? state.history?.records[length - offset]?.name
            : undefined
        return {
            onUndo,
            onRedo,
            undoName,
            redoName,
        } as UndoRedo
    }, [state.history?.offset, state.history?.records])

    return {
        state,
        updateState,
        data: state.data,
        updateData,
        status: state.status,
        updateStatus,
        validateAll,
        undoRedo,
    } as useFormPropsReturn<O>
}
export default useFormState

function reducerProd<O extends object>(
    state: State<O>,
    action: Action<O>,
): State<O> {
    switch (action.type) {
        case "state": {
            const updates =
                typeof action.updates === "function"
                    ? action.updates(state as any)
                    : action.updates
            return action.options?.method === "replace"
                ? updates
                : deepmerge(state, updates, {
                      copyUndefined: true,
                      arrayMerge: replaceArrayMerge,
                  })
        }
        case "data": {
            const updates = getUpdate(
                state.data,
                action.updates,
                action.options,
            )
            return getDataChange(
                state,
                action.transforms,
                updates,
                action.validators,
                action.options,
            )
        }
        case "status": {
            const updates =
                typeof action.updates === "function"
                    ? action.updates(state.status as any)
                    : action.updates
            return getStatusChange(
                state,
                action.statusTransforms,
                updates,
                action.options,
            )
        }
        case "undo": {
            // check if there is history to undo and if not ignore
            const offset = state.history?.offset || 0
            const length = state.history?.records.length || 0
            if (offset >= length - 1) return state
            return offsetStateHistory(state, offset + 1)
        }
        case "redo": {
            // check if there is history to redo and if not ignore
            const offset = state.history?.offset || 0
            if (offset <= 0) return state
            return offsetStateHistory(state, offset - 1)
        }
        case "startValidating": {
            if (!action.validatorProps?.length) return state
            let validating: any = {}
            for (const { path } of action.validatorProps) {
                const current = path
                    ? get(state.status?.validating, path)
                    : state.status?.validating
                const vv = { [value]: (current?.[value] || 0) + 1 }
                validating = path ? set(validating, path, vv) : vv
            }
            const validatorProps =
                state.validatorProps?.filter(
                    vp => !action.validatorProps.includes(vp),
                ) || []
            return {
                ...state,
                status: deepmerge(
                    state.status,
                    { validating },
                    { copyUndefined: true, arrayMerge: replaceArrayMerge },
                ),
                validatorProps,
            }
        }
        case "valid": {
            if (!action.results?.length) return state
            let validating: any = {}
            let invalid: any = {}
            for (const { path, valid, updates } of action.results) {
                const currentValidating = path
                    ? get(state.status?.validating, path)
                    : state.status?.validating
                if (currentValidating) {
                    currentValidating[value] =
                        currentValidating[value] <= 1
                            ? undefined
                            : currentValidating[value] - 1
                }
                validating = path
                    ? set(validating, path, currentValidating)
                    : currentValidating
                const current = path ? get(state.data, path) : state.data
                if (
                    current === updates ||
                    (typeof current === "object" &&
                        isMatch(current as object, updates))
                ) {
                    const inv = valid
                        ? { [value]: valid }
                        : { [value]: undefined }
                    invalid = path ? set(invalid, path, inv) : inv
                }
            }
            validating = deepmerge(state.status?.validating, validating, {
                copyUndefined: true,
                arrayMerge: replaceArrayMerge,
            })
            validating = clearEmptyKeys(validating)
            invalid = deepmerge(state.status?.invalid, invalid, {
                copyUndefined: true,
                arrayMerge: replaceArrayMerge,
            })
            invalid = clearEmptyKeys(invalid)
            return {
                ...state,
                status: { ...state.status, validating, invalid },
            }
        }
        default:
            return state
    }
}
function reducerDebug<O extends object>(
    state: State<O>,
    action: Action<O>,
): State<O> {
    const newState = reducerProd(state, action)
    console.log("FormState: ", {
        data: state.data,
        action,
        newData: newState.data,
        diff: deepDifference(state.data, newState.data),
        newState,
    })
    return newState
}
const reducer =
    process.env.NODE_ENV === "production" ? reducerProd : reducerDebug

function getUpdate(data: any, updates: any, options: any) {
    if (typeof updates !== "function") {
        return updates
    }
    if (options?.path) {
        return updates(get(data, options.path))
    }
    return updates(data)
}

/**
 * Handle status updates to the state
 */
function getStatusChange<O extends object>(
    state: State<O>,
    transforms: UpdateStatusTransform<O>[] | undefined,
    updates: RecursivePartial<Status<O>> | undefined,
    options?: UpdateOptions,
) {
    if (updates === undefined || state.status === updates) return state
    updates = { ...updates }
    if (!options?.skipTransforms) {
        updates = runStatusTransforms(
            transforms,
            state.status,
            updates,
            state.data,
            options,
        )
        if (updates === undefined) return state
    }
    let status =
        options?.method === "replace"
            ? { ...state.status, ...updates }
            : deepmerge(state.status, updates, {
                  copyUndefined: true,
                  arrayMerge: replaceArrayMerge,
              })
    status = clearEmptyKeys(status)
    return { ...state, status }
}

/**
 * Runs standard state updates associated with a data change such as history entry and invalid selection clearing
 */
function getDataChange<O extends object>(
    state: State<O>,
    transforms: UpdateTransform<O>[] | undefined,
    updates: RecursivePartial<O> | undefined,
    validators: Validator<O>[] | undefined,
    options?: UpdateOptions,
): State<O> {
    if (state.data === updates || updates === undefined) return state // no change

    let data
    switch (options?.method) {
        case "replace":
            if (options?.path) {
                const temp = set({}, options.path, Symbol())
                data = deepmerge(state.data, temp, {
                    arrayMerge: replaceArrayMerge,
                })
                set(data, options.path, updates)
            } else data = updates
            break
        case "deep":
            if (options?.path) {
                const temp = set({}, options.path, Symbol())
                data = deepmerge(state.data, temp, {
                    arrayMerge: replaceArrayMerge,
                })
                set(
                    data,
                    options.path,
                    deepmerge(get(state.data, options.path), updates, {
                        copyUndefined: true,
                        arrayMerge: replaceArrayMerge,
                    }),
                )
            } else
                data = deepmerge(state.data, updates, {
                    copyUndefined: true,
                    arrayMerge: replaceArrayMerge,
                })
            break
        default:
            if (options?.path) {
                const temp = set({}, options.path, Symbol())
                data = deepmerge(state.data, temp, {
                    arrayMerge: replaceArrayMerge,
                })
                set(data, options.path, {
                    ...get(state.data, options.path),
                    ...updates,
                })
            } else data = { ...state.data, ...updates }
    }

    if (!options?.skipTransforms) {
        data = runTransforms(
            transforms,
            state.data,
            data,
            state.status,
            options,
        ) as O
        if (data === state.data) return state // no changes
    }

    const newStatus: Status<O> = { ...state.status }
    const newState: State<O> = { ...state, data, status: newStatus }

    const updated = options?.path ? set({}, options.path, updates) : updates

    // update status props
    newStatus.invalid = setMatching(
        updated,
        newStatus.invalid as any,
        undefined,
    ) as any
    newStatus.invalid = trimHandingKeys(newState.data, newStatus.invalid)
    newStatus.selected = trimHandingKeys(newState.data, newStatus.selected)
    newStatus.hovered = trimHandingKeys(newState.data, newStatus.hovered)

    // clear status of empty keys
    for (const [key, val] of Object.entries(newStatus))
        (newStatus as any)[key] = clearEmptyKeys(val)

    // ready validation
    const validatorProps = readyValidators(
        validators,
        state.data,
        data,
        newStatus,
        options,
    )
    if (validatorProps.length)
        newState.validatorProps = (newState.validatorProps || []).concat(
            validatorProps,
        )

    // update history
    newState.history = updateHistory(
        newState.history,
        state,
        newState,
        options?.name,
    )
    return newState
}

/**
 * Run data update side effects
 */
function runTransforms<O extends object>(
    transforms: UpdateTransform<O>[] | undefined,
    current: O | undefined,
    updates: RecursivePartial<O> | undefined,
    status: Status<O> | undefined,
    options: any,
): RecursivePartial<O> | undefined {
    if (!transforms?.length || updates === undefined) return updates
    for (const transform of transforms) {
        let subs: any[] = []
        if (transform.path) {
            let path = checkKey(updates, transform.path)
            if (!path) continue
            if (!Array.isArray(path)) path = [path]
            for (let i = 0; i < path.length; ++i) {
                const c = get(current, path[i])
                const u = get(updates, path[i])
                const s = getStatus(status, path[i])
                if (
                    c !== u &&
                    (!options?.path ||
                        !!get(set({}, path[i], true), options.path))
                )
                    subs[i] = {
                        current: c,
                        updates: u,
                        status: s,
                        path: path[i],
                    }
            }
        } else {
            subs = [{ current, updates, status }]
        }
        for (const sub of subs) {
            if (
                transform.field &&
                get(sub.updates, transform.field as any) ===
                    get(sub.current, transform.field as any)
            )
                continue
            const transformed = transform.transform(
                sub.updates,
                sub.current,
                sub.status,
                options,
            )
            const u = sub.path ? set({}, sub.path, transformed) : transformed
            updates = deepmerge(updates, u, {
                copyUndefined: true,
            })
        }
    }
    return updates
}

/**
 * Run status update side effects
 */
function runStatusTransforms<O extends object>(
    transforms: UpdateStatusTransform<O>[] | undefined,
    current: Status<O> | undefined,
    updates: RecursivePartial<Status<O>> | undefined,
    obj: O | undefined,
    options: any,
): RecursivePartial<Status<O>> | undefined {
    if (!transforms?.length || updates === undefined) return updates
    for (const transform of transforms) {
        let subs: any[] = [{ current, updates, obj, path: transform.path }]
        if (transform.path) {
            let path = checkKey(updates, transform.path)
            let rootIndex = transform.path.indexOf(".")
            rootIndex = rootIndex < 0 ? transform.path.length : rootIndex
            if (!path) continue
            if (!Array.isArray(path)) path = [path]
            for (let i = 0; i < path.length; ++i) {
                const c = get(current, path[i])
                const u = get(updates, path[i])
                const oPath = path[i].substring(rootIndex + 1)
                const o = oPath ? get(obj, oPath) : obj
                subs[i] = { current: c, updates: u, obj: o, path: path[i] }
            }
        }
        for (const sub of subs) {
            if (transform.field && !has(sub.updates, transform.field as any))
                continue
            const transformed = transform.transform(
                sub.updates,
                sub.current,
                sub.obj,
                options,
            )
            const u = sub.path ? set({}, sub.path, transformed) : transformed
            updates = deepmerge(updates, u, { copyUndefined: true })
        }
    }
    return updates
}

/**
 * Construction object to pass validation requests to uesEffect for async running
 */
function readyValidators<O extends object>(
    validators: Validator<O>[] | undefined,
    data: O,
    updates: RecursivePartial<O> | undefined,
    status: Status<O> | undefined,
    options?: UpdateOptions,
    force?: boolean,
) {
    if (!validators?.length || updates === undefined || options?.skipValidate)
        return []
    const validatorProps = []
    for (const validator of validators) {
        let subUpdates: any = []
        let subData: any = []
        let paths: any = undefined
        if (validator.path) {
            let path = checkKey(updates, validator.path)
            if (!path) continue
            if (!Array.isArray(path)) path = [path]
            for (let i = 0; i < path.length; ++i) {
                const c = get(data, path[i])
                const u = get(updates, path[i])
                if (
                    force ||
                    (c !== u &&
                        (!options?.path ||
                            !!get(set({}, path[i], true), options.path)))
                ) {
                    subUpdates[i] = u
                    subData[i] = c
                    paths = path[i]
                }
            }
        } else {
            subUpdates = [updates]
            subData = [data]
        }
        for (let i = 0; i < subUpdates.length; ++i) {
            if (
                validator.field &&
                !force &&
                get(subUpdates[i], validator.field) ===
                    get(subData[i], validator.field)
            )
                continue
            validatorProps.push({
                validator: validator.validator,
                path: (Array.isArray(paths) ? paths?.[i] : paths) || undefined,
                updates: subUpdates[i],
                status,
                options: options?.path
                    ? { ...options, path: undefined }
                    : options,
            })
        }
    }
    return validatorProps
}

/**
 * Run async validators and update state with the result
 */
async function validate(
    validatorProps: any[],
    dispatch: React.Dispatch<Action<object>>,
    mounted: React.MutableRefObject<boolean>,
) {
    if (!validatorProps?.length) return []
    // set validating flag
    dispatch({
        type: "startValidating",
        validatorProps,
    })
    const promises = []
    for (const { validator, path, updates, options } of validatorProps) {
        if (validator) {
            const promise = Promise.resolve(validator(updates, options))
                .then((valid: string | undefined | false) => {
                    return { valid, path, updates }
                })
                .catch((error: Error) => {
                    return { valid: error.message || "Error", path, updates }
                })
            promises.push(promise)
        }
    }
    return Promise.all(promises).then(results => {
        if (mounted.current) {
            dispatch({ type: "valid", results })
        }
        return results?.map(r => r?.valid) || []
    })
}

/**
 * Function to update state based on a history move (undo/redo)
 */
function offsetStateHistory<O extends object>(state: State<O>, offset: number) {
    if (!state.history) return state
    const history = { ...state.history, offset }
    const index = state.history.records.length - offset - 1
    return {
        ...state,
        data: state.history.records[index].data,
        status: {
            ...state.status,
            invalid: state.history.records[index].invalid,
            selected: state.history.records[index].selected,
        },
        history,
    }
}

/**
 * Handle the history object update when the data changes
 */
function updateHistory<O extends object>(
    history: DataHistory<O> | undefined,
    oldState: State<O>,
    newState: State<O>,
    name: string | undefined,
): DataHistory<O> {
    if (history?.records[history.records.length - 1]?.data === newState.data)
        return history
    const newHist: DataHistory<O> = {
        offset: 0,
        ...history,
        records: [
            ...(history?.records || [
                {
                    data: oldState.data,
                    name: "Initial Data",
                    invalid: undefined,
                    selected: undefined,
                },
            ]),
        ],
    }
    const data = newState.data
    const invalid = newState.status?.invalid
    const selected = newState.status?.selected
    // if named updated record as new record entry
    if (name) {
        // if we are currently not looking at the lastest record, delete records and reset offset
        if (newHist.offset > 0) {
            newHist.records.splice(newHist.records.length - newHist.offset)
            newHist.offset = 0
        }
        // if we have hit the max allowed records, delete the oldest before appending the new
        if (newHist.records.length >= HISTORY_MAX) {
            newHist.records.splice(0, 1)
        }
        newHist.records.push({ data, invalid, selected, name })
    } else {
        // update is not named so just replace the current history point
        const index = Math.max(newHist.records.length - 1 - newHist.offset, 0)
        newHist.records[index] = {
            data,
            invalid,
            selected,
            name: newHist.records[index]?.name || "",
        }
    }
    return newHist
}
