import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
    return ({
        fcNode: {
            display: "flex",
            verticalAlign: "middle",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            background: (props = {}) => (props.type && theme.palette[props.type]) ? theme.palette[props.type].main : theme.palette.grey[400],
            borderRadius: theme.spacing(1),
            borderStyle: "none",
            minWidth: "96px",
            minHeight: `${theme.spacing(2)}px`,
            boxShadow: "0px 1px 5px -1px rgba(0,0,0,0.62)",
            '&.-selected':{
                boxShadow: "5px 5px 10px rgba(0,0,0,.75)",
                border: `${4}px solid`,
                borderColor: theme.palette.selected.main,
                marginTop: `-${4}px`,
                marginLeft: `-${4}px`,
            },
            '&.-faded': {
                opacity: 0.4,
            }
        },
    })
});

export default React.forwardRef(function Node(props, ref){
        const {config, isSelected, node, className, ...rest} = props;
        const classes = useStyles(node);
        const fade = node.properties?.fade && "-faded";
        return (
            <div ref={ref} className={clsx(className, classes.fcNode, isSelected && "-selected", node.type && ("type-" + node.type), fade)} {...rest}/>
        );
    }
);