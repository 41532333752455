import { useEffect, useState, useMemo, useCallback } from "react";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import FormControl, { useFormControl } from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { useAppStoreKey } from "AppStore";

function FormHandler({ onDefocus, errorMessage }) {
  const { focused } = useFormControl() || {};
  const [wasFocused, setWasFocused] = useState(false)
  useMemo(() => {
    if (focused) {
      setWasFocused(true)
      return;
    }
    if (wasFocused) {
      setWasFocused(false)
      onDefocus && onDefocus()
    }
  }, [focused, onDefocus, wasFocused])
  return <FormHelperText error>{errorMessage}</FormHelperText>
}

export default function CategoricalEntry({ variableName, options, initialValue, onChangeCommitted, otherProps, onVisible, onHidden, isLoading }) {
  const [value, setValue] = useState(initialValue)
  const [prevValue, setPrevValue] = useState(undefined)
  const [disabled, setDisabled] = useState(false)

  const [stashedData] = useAppStoreKey("MS/Predict/Single")

  useEffect(() => {
    let hidden = stashedData?.hidden?.[variableName]
    setDisabled(hidden !== undefined ? hidden : false)
  }, [stashedData.hidden, variableName])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const icon = useMemo(() => {
    if (disabled) {
      return <VisibilityOff />
    } else {
      return <Visibility />
    }
  }, [disabled])

  const toggleDisabled = () => {
    if (disabled) {
      onVisible && onVisible();
    } else {
      onHidden && onHidden();
    }
    setDisabled(!disabled);
  }

  const isError = useMemo(() => {
    return value === undefined || value === ""
  }, [value])

  const handleChange = (ev, val) => {
    setValue(val.props.value)
    // changeCommitted()
  }

  const changeCommitted = useCallback(() => {
    if (onChangeCommitted !== undefined) {
      onChangeCommitted(variableName, value);
    }
  }, [variableName, value, onChangeCommitted])

  useEffect(() => {
    // trigger the change 
    if (value === prevValue) {
      return;
    }
    setPrevValue(value);
    changeCommitted()
  }, [value, prevValue, changeCommitted])

  return (
    <Grid item key={variableName + "_cat_entry"} {...otherProps}>
      <Typography
        variant="subtitle1"
        gutterBottom
        style={{
          textAlign: "left",
          marginBottom: 15
        }}
      >
        <IconButton onClick={toggleDisabled}>
          {icon}
        </IconButton>
        {variableName}
      </Typography>
      <FormControl style={{ marginLeft: 0, width: "100%" }}>
        {/* if we lose focus on this, we should commit the change */}
        <Select
          style={{ textAlign: "left", width: "160px" }}
          value={value}
          onChange={handleChange}
          disabled={disabled || isLoading}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <FormHandler
          onDefocus={() => { setValue(value); }}
          errorMessage={isError ? "Value not set" : ""}
        />
      </FormControl>
    </Grid>
  )

}