import { useRef } from "react"
import IconButton from "@material-ui/core/IconButton";
import { CSVLink } from "react-csv";
import DescriptionIcon from "@material-ui/icons/Description"
import Tooltip from "@material-ui/core/Tooltip";

export default function PlotDownload({ visible, getTrace, plotType }) {
  const csvLink = useRef();

  let downloadCSV = () => {
    csvLink.current.link.click()
  }

  const heatmapData = (trace) => {
    let d = [['', ...trace['x']]]
    trace['x'].forEach((col, idx) => {
      // flipping the data table (how plotly expects the data)
      // otherwise I need to permute the labels in the first column
      d.push([col, ...trace.z[trace.z.length - idx - 1]])
    })
    return d
  }

  const getPlotlyFields = (trace) => {
    let plotlyCols = ["x"]
    if (trace.type === "scatter") {
      plotlyCols = ["x", "y"]
      if (trace.columns.length === 3) plotlyCols.push('marker.color')
    } else if (trace.type === "scatter3d") {
      plotlyCols = ["x", "y", "z"]
      if (trace.columns.length === 4) plotlyCols.push('marker.color')
    } else if (trace.type === "box") {
      plotlyCols = ["x"]
    } else if (trace.type === "violin") {
      plotlyCols = ["y"]
    } else if (trace.type === "histogram") {
      plotlyCols = ["x"]
    }
    return plotlyCols
  }

  const hstackFromPlotly = (trace) => {
    let plotlyCols = getPlotlyFields(trace[0]);
    let hstack = []
    trace.forEach((t, idx) => {
      plotlyCols.forEach(c => {
        if (c === 'marker.color') {
          hstack.push(t.marker.color)
        } else {
          hstack.push(t[c])
        }
      })
    })
    return hstack
  }

  const getColumnsFromPloty = (trace) => {
    if (trace[0].type === "scatter" || trace[0].type === "scatter3d") {
      return trace[0].columns
    }

    return trace.map(t => t.name)
  }

  const plotData = () => {
    if (!getTrace) return []
    let trace = getTrace();
    if (trace.length === 0) return []
    if (typeof (trace[0]) === 'object' && Object.keys(trace[0]).length === 0) return []

    if (trace[0].type === "heatmap") {
      return heatmapData(trace[0])
    }

    // grab the data out of the plotly figure
    const columns = getColumnsFromPloty(trace)
    let d = [[...columns]]
    let hstack = hstackFromPlotly(trace)
    // pivot the table
    hstack[0].forEach((_, idx) => {
      d.push(columns.map((_, colIdx) => hstack[colIdx]?.[idx]))
    })
    return d
  }

  return (
    visible ? (
      <div>
        <CSVLink
          data={plotData()}
          filename={plotType + '-data.csv'}
          className="hidden"
          ref={csvLink}
          target="_blank" />
        <Tooltip title="Download data">
          <IconButton
            color="primary"
            onClick={downloadCSV}
          >
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : <></>
  )
};