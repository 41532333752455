import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Collapse } from '@material-ui/core';
import FreeSoloSelector from '../General/FreeSoloSelector';

export default function ManualValueFields({propertyDefinition, onChange}){
    const handleChange = (value, key) => {
        onChange && onChange({...propertyDefinition, [key]: value}, { name: `${key} edit` });
    }

    return (
        <>
            <Grid item xs={12}>
            <Collapse in={propertyDefinition.type === "number"}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField 
                        size="small" 
                        value={propertyDefinition.units || ""} 
                        onChange={(ev) => handleChange(ev.target.value, "units")} 
                        fullWidth 
                        label="Units" 
                        placeholder="eg. N/m^2"
                        variant="outlined" />
                    </Grid>
                </Grid>
            </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={propertyDefinition.type === "categorical"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FreeSoloSelector 
                            value={propertyDefinition.allowedValues ? propertyDefinition.allowedValues : []} 
                            onChange={(ev, selected) => handleChange(selected, "allowedValues")} 
                            fullWidth 
                            label="Categories" 
                            placeholder="eg. Adhesive Cohesive"
                            variant="outlined" />
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </>
    );
}