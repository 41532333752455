import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    fcCanvas: {
        position: "relative",
        backgroundSize: "20px 20px",
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[600] : theme.palette.grey[200],
        backgroundImage: `linear-gradient(90deg,${theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[100]}80 1px,transparent 0),linear-gradient(180deg,${theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[100]}80 1px,transparent 0)`,
        width: "100%",
        overflow: "hidden",
        cursor: "not-allowed",
    }
}));

export default React.forwardRef(function CanvasOuter(props, ref){
        const classes = useStyles();
        return (
            <div ref={ref} className={classes.fcCanvas} {...props}/>
        );
    }
);