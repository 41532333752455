/**
 * @format
 */
import React, { useState } from "react"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import TreeItemLabel from "./TreeItemLabel"

export function PropertyTree({ onSelect, filters, sample, onClickKey }) {
    const [expanded, setExpanded] = useState(["property"])
    const label = !filters.properties?.length
        ? "all properties"
        : filters.properties.length === 1
        ? filters.properties[0].title
        : "properties"

    return (
        <TreeView
            expanded={expanded}
            onNodeToggle={(ev, nodeIds) => setExpanded(nodeIds)}
            onNodeSelect={(ev, value) => onSelect && onSelect(value)}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            <TreeItem nodeId="property" label={label}>
                <TreeItem
                    nodeId="property.data"
                    label={
                        <TreeItemLabel
                            label="Value"
                            rightProps={{
                                onClick() {
                                    onClickKey("property.data")
                                },
                            }}
                        />
                    }
                />
                <TreeItem
                    nodeId="property.definition.units"
                    label={
                        <TreeItemLabel
                            label="Units"
                            rightProps={{
                                onClick() {
                                    onClickKey("property.definition.units")
                                },
                            }}
                        />
                    }
                />
            </TreeItem>
        </TreeView>
    )
}
