import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters, useRowSelect } from 'react-table';

import ClearIcon from '@material-ui/icons/Clear';
import { Button, Typography, IconButton, Tooltip } from '@material-ui/core';

import CustomizeColumnsDialog from '../ReactTable/CustomizeColumnsDialog';
import { makeQueryFilters, useColumns, useOnRowSelect } from '../ReactTable/reactTableUtils';
import { DEFAULT_TABLE_STATE, DEFAULT_PAGE_SIZE } from '../ReactTable/constants';
import { addSelectColumn } from '../ReactTable/addSelectColumn';
import { addOpenColumn } from '../ReactTable/addOpenColumn';
import StyledReactTable, { defaultColumn, LOADING_ROW } from '../ReactTable/StyledReactTable';
import NewButton from '../General/NewButton';
import TableButtonGroup from '../General/TableButtonGroup';
import { useQueryPagination } from '../../API/queryHooks';
import { useAppStoreKey } from '../../AppStore';
import { sampleSetColumns, defaultColumns } from './sampleSetColumns';


function SampleSetTable({ users, tags, loading, onEdit, onNew/*, selectedOnly */}) {

    const [serializedColumns, setSerializedColumns, columns, columnsLoading] = useColumns("SampleSetTableColumns", sampleSetColumns, defaultColumns);
    const [editColumns, setEditColumns] = useState(false);
    const handleColumns = useCallback(() => setEditColumns(true), []);
    const handleCloseColumns = useCallback((cols) => { setEditColumns(false); setSerializedColumns(cols) }, [setSerializedColumns]);
    loading = loading || columnsLoading;

    const [tableState = DEFAULT_TABLE_STATE, setTableState] = useAppStoreKey("sampleSetTable");

    const queryParam = useMemo(() => {
        // merge table and user filters
        const userFilter = users?.length ? [{
            or: [
                { name: "creator.name", val: users.map(u => `"${u}"`).join(" "), op: "text" },
                { name: "modifier.name", val: users.map(u => `"${u}"`).join(" "), op: "text" }
            ]
        }] : [];
        const tagFilter = tags.length > 0 ? {
            or: [
                { name: "tagsRel.title", val: tags, op: "ilike" },
                { name: "samples.tagsRel.title", val: tags, op: "ilike" },
            ]
        } : [];
        const filters = userFilter.concat(tagFilter, makeQueryFilters(tableState?.filters, columns));
        const fields = [];
        if (columns.find(col => col.id === "tags" || ((col.id || col.accessor) !== "samples.length" && (col.id || col.accessor).startsWith("samples"))))
            fields.push("samples");
        if (!!serializedColumns.find(col => col.colId.startsWith("labs")))
            fields.push("labs.lab");
        // convert sort and append timeModified
        const sort = (tableState?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");
        return ({
            Model: "SampleSet",
            pageNumber: tableState?.pageIndex + 1,
            pageSize: tableState?.pageSize,
            filter: filters,
            sort,
            fields: fields.length > 0 ? fields : undefined
        });
    }, [columns, serializedColumns, tableState?.filters, tableState?.pageIndex, tableState?.pageSize, tableState?.sortBy, tags, users]);

    const { data: resolvedData, isLoading, isFetching } = useQueryPagination((tableState.pageIndex || 0) + 1, queryParam, { logName: "Set Table PP" }, LOADING_ROW);

    const data = useMemo(() => resolvedData?.data || [], [resolvedData]);
    const handleEdit = useCallback((row) => {
        onEdit([row.original])
    }, [onEdit])

    const table = useTable({
        columns,
        defaultColumn,
        initialState: tableState,
        data,
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        autoResetFilters: false,
        totalRows: resolvedData?.count || 0,
        pageCount: Math.max(Math.ceil((resolvedData?.count || 0) / (tableState?.pageSize || DEFAULT_PAGE_SIZE)), 1),
        getRowId: useCallback((row, relIndex, parent) => parent ? [parent.id, row.id || relIndex] : (row.id || relIndex), []),
        autoResetSelectedRows: false,
        onEdit: handleEdit
    },
        useFlexLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        addOpenColumn,
        addSelectColumn,

    );

    const [selected, setSelected] = useState([]);

    // Toggle single row
    const onToggleRowSelected = useCallback(() => {
        return table.toggleRowSelected
    }, [table]);

    // Clear row selection from the TableButtonGroup header
    const onClearAllSelectedRows = () => {
        setSelected([]);
        table.toggleAllRowsSelected(false);
    }

    useOnRowSelect({ selectedRowIds: table.state.selectedRowIds, toggleRowSelected: onToggleRowSelected, data, selected, setSelected });

    const gotoPage = table.gotoPage;
    useEffect(() => {
        gotoPage && gotoPage(0);
    }, [users, tags, gotoPage]);
    const { state: { filters }, setAllFilters } = table;
    const handleClearFilters = useCallback(() => {
        setAllFilters && setAllFilters([]);
    }, [setAllFilters]);
    const ssidString = selected.map( item => item.id ).join('&ssid=')
    const dashURL = selected?.length !== 0 ? `${process.env.REACT_APP_DASH_FRONTEND_URL}?ssid=${ssidString}` : "";

    return (
        <div style={{ textAlign: "initial" }}>
            <CustomizeColumnsDialog
                columnDefinitions={sampleSetColumns}
                serializedColumns={serializedColumns}
                defaultColumns={defaultColumns}
                open={editColumns}
                onClose={handleCloseColumns}
            />
            <TableButtonGroup
                left={
                    selected?.length > 0 &&
                    <>
                        <Typography variant="subtitle2" color="primary" style={{ marginLeft: 8 }}>
                            {`${selected.length} Sample Set${selected.length > 1 ? "s" : ""} Selected`}
                        </Typography>
                        <IconButton onClick={onClearAllSelectedRows} size="small">
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            >
                <Button disabled={!filters || filters.length === 0} color='primary' variant='text' onClick={handleClearFilters}>
                    Clear Filters
                </Button>
                <Button onClick={handleColumns}>
                    Edit Columns and Filters
                </Button>
                <Button 
                    color='primary'
                    variant='text'
                    disabled={selected.length === 0}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={dashURL}
                >
                    Compile Test Data
                </Button>
                {selected?.length ?
                    <Tooltip title={selected?.length > 1 ? "Create a new set by merge selected sets" : ""}>
                        <Button onClick={() => onEdit(selected)}>
                            {selected?.length > 1 ? "Create Merged Set" : "Open Sample Set"}
                        </Button>
                    </Tooltip>
                    :
                    <NewButton onClick={onNew}>
                        New Sample Set
                    </NewButton>
                }
            </TableButtonGroup>
            <StyledReactTable
                {...table}
                // selectedOnly={selectedOnly}
                loading={loading || (isLoading && !data?.length)}
                updating={isFetching || (isLoading && data?.length)}
                onChangeParam={setTableState}
            />
        </div>
    );
}
export default React.memo(SampleSetTable);