import { Base } from '../Base'
import { Lab, Test } from '../models'
import { Schema, schema } from '../schemaUtils'

export class Instrument extends Base {
    title?: string
    description?: string
    labs?: Lab // this is a typo from earlier. Changing this leads to a lot of cascading changes. labs -> single Lab not Lab[]
    tests?: Test[]
    meta?: any

    static readonly [schema]: Schema<Instrument> = {
        endPoint: "instruments",
        type: "instrument",
        relationships: {
            ...Base[schema].relationships,
            labs: { map: "lab", model: "Lab", toMany: false, reverse: "instruments" },
            tests: { map: "tests", model: "Test", toMany: true, reverse: "instrument" }
        },
        map: {
            ...Base[schema].map,
            title: "title",
            description: "description",
            "meta": "meta",
        }
    }

    constructor(toCopy?: Partial<Instrument>) {
        super()
        Object.assign(this, toCopy)
    }
}