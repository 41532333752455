/**
 * @format
 */
import React, { useMemo, useCallback, useEffect, useState } from "react"

import StyledHoTRaw from "../HoT/StyledHoT"
import TableButtonGroup from "components/General/TableButtonGroup"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { Button, ButtonGroup, makeStyles, Tooltip } from "@material-ui/core"
import WrapIcon from "@material-ui/icons/WrapText"
import TransposeIcon from "mdi-material-ui/RotateLeftVariant"
import {
    NodeAliases,
    ProcessChart,
    PropertyDefinition,
    PropertyInstance,
    Sample,
} from "schema/models"
import {
    ColumnInfo,
    DataChange,
    Options,
    useStyledHoT,
} from "components/HoT/HoTUtils"
import format from "date-fns/format"
import ImportProperties from "components/MaterialsLibrary/ImportProperties"
import SelectPropertyTableDialog from "components/General/SelectPropertyTableDialog"
import { commonProperties } from "components/PropertyLibrary/commonProperties"
import { useQueryFC } from "API/queryHooks"
import { buildDeleteRenderer } from "components/HoT/DeleteRenderer"

const StyledHoT: any = StyledHoTRaw

const ROW_HEADER_LABELS = [""]

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    toggleGroup: {
        margin: theme.spacing(0, 0.5),
    },
    toggleButton: {
        padding: theme.spacing(1),
        lineHeight: "initial",
    },
    tableButtons: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    toggleContainer: {
        display: "flex",
    },
}))

export type SamplePropertiesTableProps = {
    material: Sample
    processChart?: ProcessChart
    updateProcessChart?: React.Dispatch<
        React.SetStateAction<ProcessChart | undefined>
    >
    updateMaterial?: (
        updates: React.SetStateAction<Sample | undefined>,
        name?: string,
    ) => void
    selectedNodes: string[]
    propertyDefs?: PropertyDefinition[]
    height?: number
    setDataHeight?: (height: number) => void
    minRows?: number
    minEmptyRows?: number
    readOnly?: boolean
    onTableSelect?: (selected?: {
        samples: number[]
        columns: number[]
    }) => void
    nodeAliases?: NodeAliases
    infoMessage?: string
}
export function SamplePropertiesTable({
    material,
    processChart,
    updateMaterial,
    selectedNodes,
    propertyDefs,
    height,
    setDataHeight,
    minRows,
    minEmptyRows,
    readOnly,
    onTableSelect,
    nodeAliases,
    infoMessage = "",
}: SamplePropertiesTableProps) {
    const classes = useStyles()
    const [options, setOptions] = useState<Options[]>(() => [])

    const columnsInfo = useColumnsInfo(
        selectedNodes,
        nodeAliases,
        processChart,
        propertyDefs,
        readOnly,
        updateMaterial,
    )

    const {
        tableRef,
        data,
        columns,
        rowHeaders,
        cells,
        mergeCells,
        afterChange,
        afterSelection,
        afterDeselect,
        beforeColumnSort,
        transpose,
    } = useStyledHoT({
        rows: material?.properties,
        columnsInfo,
        options,
        colHeaders: 1,
        rowHeaderLabels: ROW_HEADER_LABELS,
        minRows,
        minEmptyRows,
        onDataChange: useOnDataChange(updateMaterial),
        onHeaderChange: undefined,
        updateRowOrder: useUpdateRowOrder(updateMaterial),
    })

    useEffect(() => {
        setDataHeight && setDataHeight(data.length)
    }, [data.length, setDataHeight])

    const [openSearch, setOpenSearch] = useState(false)
    const handleAddDialogClose = useCallback(
        (selected: PropertyDefinition[]) => {
            if (selected && updateMaterial) {
                const properties = selected.map(
                    s => new PropertyInstance({ definition: { id: s.id } }),
                )
                updateMaterial(
                    {
                        properties: (
                            material.properties?.filter(
                                c =>
                                    c.definition?.id ||
                                    Object.keys(c).length > 1,
                            ) || []
                        ).concat(properties),
                    },
                    "Add Properties",
                ) // filtering out default schema objects
            }
            setOpenSearch(false)
        },
        [material.properties, updateMaterial],
    )
    const [openImport, setOpenImport] = useState(false)
    const handleImportClose = useCallback(
        // @ts-ignore
        (data, replace) => {
            setOpenImport(false)
            if (data && updateMaterial) {
                const liveProp =
                    material.properties?.filter(
                        d => d.definition?.id || Object.keys(d).length > 1,
                    ) || []
                updateMaterial(
                    replace
                        ? {
                              ...material,
                              title: data.title,
                              alternateNames: data.alternateNames,
                              properties: data.properties,
                          }
                        : {
                              ...material,
                              alternateNames: (material.alternateNames || "")
                                  .split("\n")
                                  .concat(data.alternateNames.split("\n"))
                                  .join("\n"),
                              properties: (liveProp || []).concat(
                                  data.properties,
                              ),
                          },
                    "Import properties",
                )
            }
        },
        [updateMaterial, material],
    )
    const commonProps = useCommonProperties()
    const handleCommonProps = useCallback(() => {
        if (updateMaterial) {
            const liveProp =
                material.properties?.filter(
                    d => d.definition?.id || Object.keys(d).length > 1,
                ) || []
            commonProps &&
                updateMaterial(
                    {
                        properties: liveProp.concat(
                            commonProps.map(
                                (p: PropertyDefinition) =>
                                    new PropertyInstance({
                                        definition: { id: p.id },
                                    }),
                            ),
                        ),
                    },
                    "Append Common Properties",
                )
        }
    }, [commonProps, material.properties, updateMaterial])

    return data.length > 0 && (columns?.length || 0) > 0 ? (
        <div className={classes.container}>
            <p style={{ textAlign: "center", color: "#7c807d" }}>
                {infoMessage}
            </p>
            <ImportProperties
                material={material}
                open={openImport}
                onClose={handleImportClose}
            />
            <SelectPropertyTableDialog
                open={openSearch}
                onClose={handleAddDialogClose}
            />
            <TableButtonGroup
                // @ts-ignore
                right={
                    <div className={classes.toggleContainer}>
                        <ButtonGroup
                            color="primary"
                            variant="text"
                            size="small"
                        >
                            <Button
                                className={classes.tableButtons}
                                onClick={handleCommonProps}
                                disabled={!commonProps}
                            >
                                Append Common
                            </Button>
                            <Button
                                className={classes.tableButtons}
                                onClick={() => {
                                    setOpenSearch(true)
                                }}
                            >
                                Search and Add Properties
                            </Button>
                            <Button
                                className={classes.tableButtons}
                                onClick={() => {
                                    setOpenImport(true)
                                }}
                            >
                                Import
                            </Button>
                        </ButtonGroup>
                        <ToggleButtonGroup
                            className={classes.toggleGroup}
                            value={options}
                            onChange={(ev, selected) => setOptions(selected)}
                        >
                            <ToggleButton
                                className={classes.toggleButton}
                                value="Wrap"
                            >
                                <Tooltip
                                    title="Toggle Text Wrapping"
                                    placement="top"
                                >
                                    <WrapIcon fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                                className={classes.toggleButton}
                                value="Transpose"
                            >
                                <Tooltip
                                    title="Transpose Table"
                                    placement="top"
                                >
                                    <TransposeIcon fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                }
            ></TableButtonGroup>
            <StyledHoT
                ref={tableRef}
                height={height}
                data={data}
                columns={columns}
                colHeaders={transpose ? rowHeaders : true}
                rowHeaders={transpose ? true : rowHeaders}
                rowHeaderWidth={transpose ? undefined : 60}
                cells={cells}
                mergeCells={mergeCells}
                afterChange={afterChange}
                outsideClickDeselects
                parentOverflow={"horizontal"}
                autoColumnSize={true}
                fixedColumnsLeft={1}
                fixedRowsTop={1}
                observeChanges
                columnSorting={!transpose}
                beforeColumnSort={beforeColumnSort}
                afterSelection={afterSelection}
                afterDeselect={afterDeselect}
            />
        </div>
    ) : null
}
export default SamplePropertiesTable

const commonPropsParams = {
    Model: "PropertyDefinition",
    filter: {
        name: "title",
        op: "in_",
        val: Object.values(commonProperties),
    },
    sort: "title",
}

function useCommonProperties(): PropertyDefinition[] | undefined {
    const { data } = useQueryFC<PropertyDefinition>(commonPropsParams as any, {
        logName: "Common Properties",
        cacheTime: 60 * 60 * 1000,
    })
    return data?.data
}

function useColumnsInfo(
    selectedNodes: string[],
    nodeAliases: NodeAliases | undefined,
    processChart: ProcessChart | undefined,
    propertyDefs: PropertyDefinition[] | undefined,
    readOnly: boolean | undefined,
    updateMaterial: any,
) {
    return useMemo(() => {
        // identifier columns
        const columnsInfo: ColumnInfo[] = [
            {
                type: "text",
                headerRows: [
                    {
                        id: "property",
                        title: "Property",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetDefKey("title", propertyDefs, "*Missing Label*"),
                width: 130,
                className: "innerRowSubHeader",
                renderer: buildTitleRenderer(updateMaterial),
            },
            {
                type: "text",
                headerRows: [
                    {
                        id: "units",
                        title: "Units",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetDefKey("units", propertyDefs, ""),
                width: 130,
                className: "innerRowSubHeader",
            },
            {
                type: "text",
                headerRows: [
                    {
                        id: "value",
                        title: "Value",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetKey("data"),
                set: buildSetKey("data"),
                width: 130,
                className: "innerRowSubHeader",
            },
            {
                type: "text",
                headerRows: [
                    {
                        id: "source",
                        title: "Source Notes",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetKey("sourceNotes"),
                set: buildSetKey("sourceNotes"),
                width: 130,
                className: "innerRowSubHeader",
            },
            {
                type: "text",
                headerRows: [
                    {
                        id: "modified",
                        title: "Last Modified",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetTimeKey("timeModified"),
                width: 130,
                className: "innerRowSubHeader",
            },
            {
                type: "text",
                headerRows: [
                    {
                        id: "modifier",
                        title: "Modified By",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetKey("modifierName"),
                width: 130,
                className: "innerRowSubHeader",
            },
        ]

        // handle readonly
        if (readOnly) {
            columnsInfo.forEach(ci => {
                if (ci.set) ci.set = undefined
                ci.headerRows.forEach(hr => {
                    if (hr?.set) hr.set = undefined
                })
            })
        }

        return columnsInfo
    }, [propertyDefs, readOnly, updateMaterial])
}

function useUpdateRowOrder(
    updateMaterial?: (
        updates: React.SetStateAction<Sample | undefined>,
        name?: string,
    ) => void,
) {
    return useCallback(
        (order: number[]) => {
            updateMaterial?.(sample => {
                if (!sample?.properties) return sample
                const newSample = new Sample(sample)
                const length = Math.max(order.length, sample.properties.length)
                newSample.properties = []
                for (let i = 0; i < length; ++i) {
                    let index = order[i]
                    if (index === undefined) index = i
                    if (index <= sample.properties.length) {
                        if (sample.properties[index]?.definition?.id)
                            newSample.properties.push(sample.properties[index])
                    }
                }
                return newSample
            })
        },
        [updateMaterial],
    )
}

function useOnDataChange(
    updateMaterial?: (
        updates: React.SetStateAction<Sample | undefined>,
        name?: string,
    ) => void,
) {
    return useCallback(
        (changes: DataChange[]) => {
            if (!changes?.length || !updateMaterial) return
            updateMaterial(sample => {
                const newSample = new Sample(sample)
                newSample.properties = newSample.properties
                    ? [...newSample.properties]
                    : []
                for (const change of changes) {
                    const index = change[0]
                    const setter = change[1]
                    const val = change[2]
                    if (!newSample.properties[index])
                        newSample.properties[index] = new Sample()
                    newSample.properties[index] = setter(
                        newSample.properties[index],
                        newSample.properties?.[index],
                        val,
                    )
                }
                return newSample
            }, "Property Update")
        },
        [updateMaterial],
    )
}

// headers getters and setters
function buildGetKey(key: keyof PropertyInstance, defaultValue: any = "") {
    return (instance?: PropertyInstance) => {
        return instance?.[key] || defaultValue
    }
}
function buildSetKey(key: keyof PropertyInstance) {
    return (
        instance?: PropertyInstance,
        original?: PropertyInstance,
        val?: string | number | null,
    ) => {
        if (!instance || instance === original) {
            instance = new PropertyInstance(original)
        }
        ;(instance as any)[key] = val ? String(val) : undefined
        return instance
    }
}
function buildGetDefKey(
    key: keyof PropertyDefinition,
    defs: PropertyDefinition[] | undefined,
    defaultValue: any = "",
) {
    return (instance?: PropertyInstance) => {
        if (!instance?.definition?.id) return null
        return (
            defs?.find(def => def.id === instance?.definition?.id)?.[key] ||
            defaultValue
        )
    }
}
function buildGetTimeKey(
    key: "timeModified" | "timeCreated",
    defaultValue: any = "",
) {
    return (instance?: PropertyInstance) => {
        const val = instance?.[key] || defaultValue
        if (!val) return null
        return format(Date.parse(val), "Pp")
    }
}

function buildTitleRenderer(updateMaterial: any) {
    const onDelete = (ev: MouseEvent, row: number) => {
        const index = row - 1
        updateMaterial((material: Sample) => {
            if (!material.properties?.[index]) return material
            const newMaterial = new Sample(material)
            newMaterial.properties = [...(newMaterial.properties || [])]
            newMaterial.properties.splice(index, 1)
            return newMaterial
        }, "Delete Property")
        return false
    }
    return buildDeleteRenderer(onDelete)
}
