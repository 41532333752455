import React from 'react';
import PortsGroup from './PortsGroup';
import LabelledPortGroup from './LabelledPortGroup';

export default function Ports(props){
    const { children, config } = props;
    return (
        <div>
          <PortsGroup config={config} side="top">
            {children.filter((child) => ['input', 'top'].includes(child.props.port.type))}
          </PortsGroup>
          <PortsGroup config={config} side="bottom">
            {children.filter((child) => ['output', 'bottom'].includes(child.props.port.type))}
          </PortsGroup>
          <PortsGroup config={config} side="right">
            {children.filter((child) => child.props.port.type === "right" && !(child.props.port.properties && child.props.port.properties.label))}
          </PortsGroup>
          <PortsGroup config={config} side="left">
            {children.filter((child) => child.props.port.type === "left" && !(child.props.port.properties && child.props.port.properties.label))}
          </PortsGroup>
          <LabelledPortGroup config={config} side="left">
             {children.filter((child) => child.props.port.type === "left" && (child.props.port.properties && child.props.port.properties.label))}
          </LabelledPortGroup>
          <LabelledPortGroup config={config} side="right">
             {children.filter((child) => child.props.port.type === "right" && (child.props.port.properties && child.props.port.properties.label))}
          </LabelledPortGroup>
        </div>
      )
}