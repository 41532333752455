import React, { useCallback } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles, Select, MenuItem, Checkbox } from '@material-ui/core';
import { useDebounceState } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
    input: {
        ...theme.typography.caption,
        backgroundColor: theme.palette.background.paper,
        '& div': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            height: "1.1875em",
        }
    }
}))

function makeQuery(value, categories, filter){
    const arr = value.map(v => categories.find(cat => cat.label === v)?.value || v);
    if (filter !== "list") return arr.join(" ");
    else return arr;
}

export default function CategoryFilterInput({column: { filterValue, setFilter, filter, categories }}){
    const classes = useStyles();
    const [value, setValue] = useDebounceState(filterValue, setFilter);
    const handleChange = useCallback(ev => {
        if (!ev.target.value || ev.target.value.length === 0){
            setValue(undefined);
        }
        else{
            setValue({
                selection: ev.target.value,
                query: makeQuery(ev.target.value, categories, filter),
                type: filter,
            })
        }
    }, [filter, categories, setValue]);
    return (
        <Select
            multiple
            value={value?.selection || []}
            onChange={handleChange}
            renderValue={selected => selected.map(s => categories.find(c => c.value === s)?.label || s).join('; ')}
            input={
            <OutlinedInput
                variant="outlined"
                placeholder="Filter..."
                fullWidth
                className={classes.input}
                />}
            >
            {
                categories?.map(cat => 
                    <MenuItem key={cat.value} value={cat.value}>
                        <Checkbox checked={value?.selection?.indexOf(cat.value) >= 0} />
                        {cat.label || cat.value}
                    </MenuItem>)
            }
        </Select>
    );
}