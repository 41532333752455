import { useState, useEffect, } from "react";
import { Input, makeStyles, MenuItem } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useSnackbar } from "notistack";
import common_styles from '../../../styles/common_styles';
import { useAppStoreKey } from "../../../AppStore";
import { aichemyProtoUpdateWorkflow } from "../utils"

const useStyles = makeStyles(common_styles);

export default function SetActiveSheet({ id }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [workflow, setWorkflow] = useAppStoreKey("Workflow")
  const [activeSheet, setActiveSheet] = useState(workflow?.data?.active_sheet || null)
  useEffect(() => {
    if (workflow.data) {
      setActiveSheet(workflow.data.active_sheet)
    }
  }, [workflow.data])
  const onSheetChange = (e) => {
    let newActiveSheet = e.target.value;
    if (newActiveSheet !== activeSheet) {
      let patch = {
        data_cleaning: {
          SetActiveSheet: { kwargs: { value: newActiveSheet } },
        }
      }
      aichemyProtoUpdateWorkflow(patch, workflow, setWorkflow).catch(err => {
        enqueueSnackbar("Failed to update active sheet.", { variant: "error" });
        console.error(err)
      })
    }
  }
  return (
    <FormControl
      fullWidth
      className={classes.formControl}
    >
      <InputLabel htmlFor={id}>
        Use dropdown menu to select active sheet
      </InputLabel>
      <Select
        autoWidth
        style={{ textAlign: "left" }}
        value={activeSheet}
        onChange={onSheetChange}
        input={
          <Input
            name={id}
            id={id}
          />
        }
      >
        {Object.keys(workflow.data.info).map((sheet) => (
          <MenuItem key={sheet} value={sheet}>
            {sheet}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}