/**
 * @format
 */
import { MutationFunction, useMutation, UseMutationOptions } from "react-query"
import { useSnackbar } from "notistack"
import { firecrackerKey, queryClient } from "./queryHooks"
import { MutateParam, OnProgressCallback } from "./firecrackerTypes"
import { mutateRequestFC } from "./firecrackerAPI"
import { useCallback } from "react"

export type UseMutationOptionsMM<
    TData = unknown,
    TError = unknown,
    TVariables = void,
    TContext = unknown,
> = UseMutationOptions<TData, TError, TVariables, TContext> & {
    refetchKey?: string
    onProgress?: OnProgressCallback
}
export type MutationCallbacks<
    TData = unknown,
    TError = unknown,
    TVariables = void,
    TContext = unknown,
> = {
    onSuccess?: (
        data: TData,
        variables: TVariables,
        context?: TContext,
    ) => Promise<void> | void
    onSettled?: (
        data: TData | undefined,
        error: TError | null,
        variables: TVariables,
        context?: TContext,
    ) => Promise<void> | void
    onError?: (
        error: TError,
        variables: TVariables,
        context?: TContext,
    ) => Promise<void> | void
    successMessage?: (
        data: TData,
        variables: TVariables,
        context?: TContext,
    ) => string | undefined
    errorMessage?: (
        error: TError,
        variables: TVariables,
        context?: TContext,
    ) => string | undefined
}
/** useMutation wrapper that adds snackbar messages and console logging when not a production build */
export function useMutationBase<
    TData = unknown,
    TError = unknown,
    TVariables = void,
    TContext = unknown,
>(
    mutationFunc: MutationFunction<TData, TVariables>,
    options?: UseMutationOptionsMM<TData, TError, TVariables, TContext>,
) {
    const { refetchKey, ...mutationOptions } = options || {}
    const { enqueueSnackbar } = useSnackbar()

    mutationOptions.onSettled = (
        data: TData | undefined,
        error: TError | null,
        variables: TVariables,
        context?: TContext,
    ) => {
        if (refetchKey) {
            queryClient.invalidateQueries(refetchKey)
        }
        if (process.env.NODE_ENV !== "production") {
            console.log("Mutate Return: %O %O", variables, data || error)
        }
        if (options?.onSettled)
            return options.onSettled(data, error, variables, context)
    }

    if (process.env.NODE_ENV !== "production") {
        mutationOptions.onMutate = (variables: TVariables) => {
            console.log("Mutate Request: %O", variables)
            if (options?.onMutate) return options.onMutate(variables)
            return undefined
        }
    }

    const mutator = useMutation(mutationFunc, mutationOptions)

    const getCallbacks = useCallback(
        (
            callbacks?: MutationCallbacks<TData, TError, TVariables, TContext>,
        ) => {
            if (!callbacks) return callbacks
            const { successMessage, errorMessage, ...cb } = callbacks
            if (successMessage) {
                cb.onSuccess = (
                    data: TData,
                    variables: TVariables,
                    context?: TContext,
                ) => {
                    const message = successMessage
                        ? successMessage(data, variables, context)
                        : `Update Successful`
                    if (message)
                        enqueueSnackbar(message, { variant: "success" })
                    if (callbacks.onSuccess)
                        return callbacks.onSuccess(data, variables, context)
                }
            }
            if (errorMessage) {
                cb.onError = (
                    error: TError,
                    variables: TVariables,
                    context?: TContext,
                ) => {
                    const message = errorMessage
                        ? errorMessage(error, variables, context)
                        : `API Error: Mutation Failed`
                    if (message) enqueueSnackbar(message, { variant: "error" })
                    if (callbacks.onError)
                        return callbacks.onError(error, variables, context)
                }
            }
            return cb
        },
        [enqueueSnackbar],
    )

    const mutate = useCallback(
        (
            vars: TVariables,
            callbacks?: MutationCallbacks<TData, TError, TVariables, TContext>,
        ) => {
            const cb = getCallbacks(callbacks)
            const func = mutator.mutate // this indirect call makes the linter happy.... I don't like it either
            return func(vars, cb)
        },
        [getCallbacks, mutator.mutate],
    )

    const mutateAsync = useCallback(
        (
            vars: TVariables,
            callbacks?: MutationCallbacks<TData, TError, TVariables, TContext>,
        ) => {
            const cb = getCallbacks(callbacks)
            const func = mutator.mutateAsync // this indirect call makes the linter happy.... I don't like it either
            return func(vars, cb)
        },
        [getCallbacks, mutator.mutateAsync],
    )

    return { ...mutator, mutate, mutateAsync }
}

export type MutateArgs<T> = {
    // @ts-ignore
    param: MutateParam<T>
    payload: T | undefined
    previous: T | undefined
}
/** useMutationBase wrapper that connects to the firecracker API */
export function useMutationFC<T>(
    options?: UseMutationOptionsMM<T | undefined, unknown, MutateArgs<T>>,
) {
    const fcOptions = {
        refetchKey: firecrackerKey,
        ...options,
    } as UseMutationOptionsMM<T | undefined, unknown, MutateArgs<T>>
    return useMutationBase<T | undefined, unknown, MutateArgs<T>>(
        variables =>
            // @ts-ignore
            mutateRequestFC(
                variables.param,
                // @ts-ignore
                variables.payload,
                variables.previous,
                options?.onProgress,
            ),
        fcOptions,
    )
}
