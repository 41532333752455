import { Base } from '../Base'
import { Schema, schema } from '../schemaUtils'
import { Sample, SampleSet } from './'

export class ProcessChart extends Base {
    title?: string
    nodes?: ProcessNodes
    columns?: Column[]

    samples?: Sample[]
    sets?: SampleSet[]

    static readonly [schema]: Schema<ProcessChart> = {
        endPoint: "process_charts",
        type: "process_chart",
        relationships: {
            ...Base[schema].relationships,
            samples: { map: "samples", model: "Sample", toMany: true, reverse: "processChart" },
            sets: { map: "sample_sets", model: "SampleSet", toMany: true, reverse: "processChart" },
        },
        map: {
            ...Base[schema].map,
            "title": "title",
            "nodes": "meta.nodes",
            "columns": "meta.columns",
        }
    }

    constructor(toCopy?: Partial<ProcessChart>) {
        super()
        Object.assign(this, toCopy)
    }
}

export type ProcessNodes = {
    [uuid: string]: ProcessNode
}

export type ProcessNode = {
    to?: string[] | undefined
    color?: string | undefined
    type: NodeTypes | undefined
    label?: string | undefined
    materialDefs?: NodeDefinitions | undefined
    processDefs?: NodeDefinitions | undefined
    propertyDefs?: NodeDefinitions | undefined
    detached?: boolean | undefined
}

export type NodeDefinitions = {
    [defId: string]: NodeDefinition
}

export type NodeDefinition = {
    title?: string,
    cols?: NodeColumns
    count?: number
}

export type NodeColumns = {
    [colKey: string]: NodeColumn
}

export type NodeColumn = {
    default?: string | number
    units?: string
}

export type InstanceTypes = "material" | "property" | "process" | "output"
export type NodeTypes = InstanceTypes | "mixed" | ""

export type Column = {
    nodeType: NodeTypes
    nodeId: string
    defId: string
    colKey: string
}