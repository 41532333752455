import React, { useState} from "react";
import { SelectColumns } from "./utils";
import PreprocessFuncsBase from "./PreprocessFuncsBase";
import { useAppStoreKey } from "../../../../AppStore";


export default function StandardizeScaler({pfKey, handleRemovePlot}) {
  const [workflow] = useAppStoreKey("Workflow");
  const [allCols, setAllCols] = useState([])
  const [selectedCols, setSelectedCols] = useState([])
  const funcName = 'Standardize Data'
  const description = 'Linearly transform each column to mean = 0, variance = 1.'

  const updateDict = () => {
    const selected_idx = []
    allCols.forEach((value, idx) => { if (selectedCols[idx]) selected_idx.push(idx) })
    return {
      "StandardScaler": {
        "kwargs": {
          "cols": selected_idx.map(idx => workflow.data.info[workflow.data.active_sheet].all_headers[idx]),
        }
      }
    }
  }

  return (
    <>
      <PreprocessFuncsBase
        funcName={funcName}
        description={description}
        pfKey={pfKey}
        handleRemovePlot={handleRemovePlot}
        updateDict={updateDict}
      >
        <SelectColumns
          allCols={allCols}
          setAllCols={setAllCols}
          selectedCols={selectedCols}
          setSelectedCols={setSelectedCols}
        />
      </PreprocessFuncsBase>
    </>
  );
}