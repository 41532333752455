/**
 * @format
 */
import React, { useState, useMemo } from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Link,
    DialogActions,
    Button,
    TextField,
    Grid,
} from "@material-ui/core"
import {
    mathjsOperators,
    mathjsFunctions,
    mathjsConstants,
} from "./functionList"
import { useTable, useFlexLayout, useSortBy, useFilters } from "react-table"
import StyledReactTable, {
    defaultColumn,
    defaultFilterTypes,
} from "../ReactTable/StyledReactTable"
import { evaluate } from "mathjs"
import { ExampleResultBase } from "./ExampleResults"

const allKeywords = mathjsOperators.concat(mathjsConstants, mathjsFunctions)

const columns = [
    {
        Header: "Name",
        id: "name",
        accessor: row => row[0],
        width: 60,
        filter: "text",
    },
    {
        Header: "Description",
        id: "description",
        accessor: row => row[1],
        width: 180,
        filter: "text",
    },
]

export function ExpressionHelpDialog({ onClose, ...props }) {
    const heightInRows = Math.max((window.innerHeight - 450) / 33, 3) // 33 is row height. Aprox. 300 of spacing above and below.

    const [expression, setExpression] = useState()

    const result = useMemo(() => {
        try {
            let rtn = evaluate(expression || "")
            try {
                return { value: JSON.stringify(rtn) }
            } catch (error) {
                return { value: rtn }
            }
        } catch (error) {
            return { value: error.message, error: true }
        }
    }, [expression])

    const table = useTable(
        {
            initialState: { pageSize: heightInRows },
            columns,
            defaultColumn,
            data: allKeywords,
            autoResetPage: false,
            autoResetFilters: false,
            autoResetSortBy: false,
            filterTypes: defaultFilterTypes,
            rowsDisplayed: heightInRows,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
    )

    return (
        <Dialog fullWidth maxWidth="md" onClose={onClose} {...props}>
            <DialogTitle>Expression Help</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        Expressions are interpreted using the open source
                        library{" "}
                        <Link
                            href="https://mathjs.org/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Mathjs
                        </Link>
                        . For quick reference, some of the available operators,
                        constants and functions are described below.
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            title="Expression playground"
                            placeholder="Enter an expression here to test"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={expression || ""}
                            onChange={ev => setExpression(ev.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ExampleResultBase
                            value={result?.value || ""}
                            error={result.error}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledReactTable {...table} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
