import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import common_styles from '../../styles/common_styles';
import { useParams } from '@reach/router';
import { useQueryFullSet } from '../../API/queryHooks';
import EditSampleSet from './EditSampleSet';
import LoadingOverlay from '../General/LoadingOverlay';
import { Typography } from '@material-ui/core';
import { useAppStoreDispatchKey, useAppStoreKey } from '../../AppStore';
import { useMounted } from 'utils/utils';

const useStyles = makeStyles(theme => ({...common_styles(theme)}));
function EditSampleSetsCard() {
    const classes = useStyles();
    const {setId} = useParams();
    const activeId = setId && setId !== "new";
    const updateStore = useAppStoreDispatchKey("EditSampleSet");
    const [initialSet, setInitialSet] = useAppStoreKey("InitSampleSet");
    const [progress, setProgress] = useState()
    const mounted = useMounted()
    const {data: set, isFetching} = useQueryFullSet(activeId ? {id: setId} : undefined, { onProgress({completed, total}){ 
        if (mounted.current) setProgress(completed * 100 / total) 
    }});
    const noSetFound = activeId && !set && !isFetching;
    
    useEffect(() => {
        if (activeId && !isFetching){
            setInitialSet(set);
        }
    }, [set, setInitialSet, activeId, isFetching]);

    useEffect(() => {
        if (noSetFound){
            updateStore(undefined);
        }
    }, [noSetFound, updateStore]);

    const loading = (!initialSet || initialSet.id !== setId) && setId !== "new"
    
    return (
        <div className={clsx(classes.layout)}>
            {loading && isFetching && <LoadingOverlay loadingText = "Loading Set" filled progress={progress} />}
            {(!loading) && 
                <EditSampleSet />
            }
            {
                noSetFound &&
                <div style={{padding: "16px"}}>
                    <Typography variant="h4" color="error">
                        Sample Set not Found.
                    </Typography>
                    <Typography variant="subtitle2">
                        The URL may be bad or you may not have permissions to see the set.
                    </Typography>
                </div>
            }
        </div>
    );
}
export default React.memo(EditSampleSetsCard);