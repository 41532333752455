import React from "react"
import ButtonWithSpinner from "components/General/ButtonWithSpinner"

import { ButtonGroup, ButtonProps, Popover } from "@material-ui/core"

import { Sample, SampleSet } from "schema/models"
import { ColumnInfo } from "components/HoT/HoTUtils"


type ExportPopoverProps = {
    sampleSet: SampleSet
    loading: boolean
    onLoading: any
    open: any
    onOpen: any
    columnsInfo: ColumnInfo[]
    updateSampleSet: any
    enqueueDialogs: any
    toggleQIDFunction: any
}

export function AdvancedPopover({
    sampleSet,
    open,
    onOpen,
    loading,
    onLoading,
    columnsInfo,
    updateSampleSet,
    enqueueDialogs,
    toggleQIDFunction,
}: ExportPopoverProps) {
    const handleClose = () => {
        onOpen(null)
    }
    return (
        <Popover
            open={!!open}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
        >
            <ButtonGroup color={"primary"} variant="outlined">
                <QidEditEnablingButton
                    loading={loading}
                    onLoading={onLoading}
                    sampleSet={sampleSet}
                    toggleQIDFunction={toggleQIDFunction}
                ></QidEditEnablingButton>
                ,<ClearAllButton
                    loading={loading}
                    onLoading={onLoading}
                    sampleSet={sampleSet}
                    updateSampleSet={updateSampleSet}
                    enqueueDialogs={enqueueDialogs}
                ></ClearAllButton>
            </ButtonGroup>
        </Popover>
    )
}


export const ClearAllButton: React.FC<
    {
        loading: boolean
        onLoading: (val: boolean) => void
        sampleSet: SampleSet
        updateSampleSet?: (
            updates: React.SetStateAction<SampleSet | undefined>,
            name?: string,
        ) => void
        enqueueDialogs: any
    } & ButtonProps
> = React.forwardRef(
    (
        {
            onLoading,
            loading,
            sampleSet,
            updateSampleSet,
            enqueueDialogs,
            ...props
        },
        ref,
    ) => {
        return (
            <ButtonWithSpinner
                ref={ref}
                color="secondary"
                onClick={(event: MouseEvent, sampleSet: SampleSet) => {
                    return enqueueDialogs(
                        {
                            propArray: [
                                {
                                    title: "Are you sure you want to clear all data?",
                                    message:
                                        "This will clear all data in the MasterTable but will not affect your ProcessFlowChart. You may undo this action.",
                                    yes: "Confirm",
                                    cancel: "Cancel",
                                },
                            ],
                            onYes: async () => {
                                if (!updateSampleSet) return
                                updateSampleSet(sampleSet => {
                                    const newSamples: Sample[] = []
                                    return new SampleSet({
                                        ...sampleSet,
                                        samples: newSamples,
                                    })
                                }, "Clear Sample Data")
                            },
                        },
                        [updateSampleSet],
                    )
                }}
                {...(props as any)}
            >
                <div style={{ color: "red" }}>Clear All</div>
            </ButtonWithSpinner>
        )
    },
)

const QidEditEnablingButton: React.FC<
    {
        loading: boolean
        onLoading: (val: boolean) => void
        sampleSet: SampleSet
        toggleQIDFunction: () => void
    } & ButtonProps
> = React.forwardRef(({ onLoading, loading, sampleSet, toggleQIDFunction, ...props }, ref) => {
    const onExport = async () => {
        onLoading(true)
        toggleQIDFunction();
        onLoading(false)
    }

    return (
        <ButtonWithSpinner
            ref={ref as any}
            {...props}
            onClick={onExport}
            loading={loading}
        >
            Edit QID (NOT Recommended)
        </ButtonWithSpinner>
    )
})