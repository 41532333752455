/**
 * @format
 */
import { useContext, useRef, useState } from "react"
import { useNavigate } from "@reach/router"

import { Button, Divider, Typography, useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import InfoIcon from "@material-ui/icons/ListAlt"
import TableIcon from "@material-ui/icons/TableChartOutlined"
import BarChartIcon from "@material-ui/icons/BarChart"

import ChartIcon from "mdi-material-ui/Sitemap"
import CIcon from "mdi-material-ui/AlphaCCircleOutline"
import PIcon from "mdi-material-ui/AlphaPCircleOutline"

import { useSnackbar } from "notistack"
import { useAppStoreKey } from "AppStore"
import { AccountContext } from "context"

import {
    useCheckReadOnly,
    useQueryProcessChartDefinitions,
    useQuerySimilarMaterials,
} from "API/queryHooks"
import { useMutationFC } from "API/mutationHooks"
import { Sample } from "schema/models"
import { useUpdateDefs, useChangeSampleSetNode } from "utils/schemaUtils"

import {
    useEffectUpdateColumnsFromNodes,
    useHighlightedNodes,
} from "components/FlowChart/processChartUtils"
import ProcessFlowChartPane from "components/FlowChart/ProcessFlowChartPane"
import EditButtons from "components/General/EditButtons"
import MMToolbar from "components/General/MMToolbar"
import { useEnqueueDialogs } from "components/General/GlobalConfirmationDialog"
import MasterTable from "components/DataTables/MasterTable"
import SamplePropertiesTable from "components/DataTables/SamplePropertiesTable"
import { MultiPlotMaterial } from "components/SampleSetLibrary/MultiPlot"
import ComponentSummaryTable from "components/DataTables/ComponentSummeryTable"
import ConfirmationDialog, {
    useConfirmDialog,
} from "components/General/ConfirmationDialog"

import {
    ResizableViewsSelector,
    ResizableViews,
    DisplayTabs,
} from "../General/ResizableViews"

import {
    useCopy,
    useDelete,
    useEditMaterialState,
    useSave,
    useSaved,
} from "./editMaterialHooks"
import MaterialInfoFields from "./MaterialInfoFields"
import { useFileUpload } from "./FileTab"
import MaterialsSummaryList from "./MaterialsSummaryList"

const MATERIAL_INFO_NOTE =
    "Automatically import material information from PubChem using " +
    "'IMPORT'. Function 'APPEND COMMON' will add common properties, or use " +
    "'SEARCH AND ADD PROPERTIES' to add specific properties to the table."

export type EditMaterialProps = {
    onClose: () => void
    initialMaterial: Sample
}
export default function EditMaterial({
    onClose,
    initialMaterial,
}: EditMaterialProps) {
    const theme = useTheme()
    const medium = useMediaQuery(theme.breakpoints.up("md"))
    const large = useMediaQuery(theme.breakpoints.up("lg"))

    const navigate = useNavigate()
    const account = useContext(AccountContext) as any
    const { enqueueSnackbar } = useSnackbar()
    const enqueueDialogs = useEnqueueDialogs()

    const defsRef = useRef<any>()

    const {
        data,
        materialSet,
        status,
        updateData,
        updateMaterial,
        updateMaterialSet,
        updateProcessChart,
        validateAll,
        undo,
        redo,
        selectedNodes,
        setSelectedNodes,
        tableSelected,
        setTableSelected,
    } = useEditMaterialState(initialMaterial, defsRef)
    const material = data
    const processChart = data.processChart

    const { data: similar, isFetching: isFetchingSimilar } =
        useQuerySimilarMaterials(!material.id ? material : undefined)

    const { getConfirmation: viewDuplicates, props: duplicatesProps } =
        useSimilarDialog(similar)

    // query selected definitions
    const { data: materialDefs, isLoading: isLoadingMaterial } =
        useQueryProcessChartDefinitions("Sample", processChart)
    const { data: processDefs, isLoading: isLoadingProcess } =
        useQueryProcessChartDefinitions("ProcessDefinition", processChart)
    const { data: propertyDefs, isLoading: isLoadingProperty } =
        useQueryProcessChartDefinitions("PropertyDefinition", processChart)

    useUpdateDefs(
        defsRef,
        updateMaterial,
        materialDefs,
        processDefs,
        propertyDefs,
    )

    useEffectUpdateColumnsFromNodes(
        processChart,
        isLoadingMaterial,
        isLoadingProcess,
        isLoadingProperty,
        materialDefs,
        processDefs,
        propertyDefs,
        updateProcessChart,
    )

    // set mutate callbacks
    const [progress, setProgress] = useState<number>()
    const { mutateAsync } = useMutationFC({
        onProgress(tracker) {
            setProgress((tracker.completed * 100) / tracker.total)
        },
        onMutate() {
            setProgress(undefined)
        },
    })

    const onChangeProcessChartNode = useChangeSampleSetNode(updateMaterialSet)

    const highlightedNodes = useHighlightedNodes(
        materialSet,
        processChart,
        tableSelected,
    )

    const fileUploadProps = useFileUpload({ material, enqueueSnackbar })

    const readOnly = useCheckReadOnly(initialMaterial, material)
    const editing = !!material?.id && !readOnly
    const displayTabs: DisplayTabs = [
        {
            title: "Info",
            Icon: InfoIcon,
            node: (
                <MaterialInfoFields
                    material={material}
                    invalid={status?.invalid}
                    updateMaterial={updateMaterial}
                    accountName={account?.name}
                    fileUploadProps={fileUploadProps}
                />
            ),
        },
        {
            title: "Flowchart",
            Icon: ChartIcon,
            height: 250,
            minHeight: 200,
            node: !!processChart && (
                <ProcessFlowChartPane
                    readOnly={readOnly}
                    processChart={processChart}
                    selectedNodes={selectedNodes}
                    onChangeProcessChart={updateProcessChart}
                    onChangeSelectedNodes={setSelectedNodes}
                    onChangeNode={onChangeProcessChartNode}
                    materialDefs={materialDefs}
                    processDefs={processDefs}
                    propertyDefs={propertyDefs}
                    // @ts-ignore
                    highlightedNodes={highlightedNodes}
                />
            ),
        },
        {
            title: "Master Table",
            Icon: TableIcon,
            height: Math.max(430, (materialSet?.samples?.length || 0) * 23),
            minHeight: 225,
            node: processChart && (
                <div style={{ display: "flex", height: "100%" }}>
                    <MasterTable
                        sampleSet={materialSet}
                        processChart={processChart}
                        updateSampleSet={updateMaterialSet}
                        updateProcessChart={updateProcessChart}
                        onTableSelect={setTableSelected}
                        selectedNodes={selectedNodes}
                        materialDefs={materialDefs}
                        processDefs={processDefs}
                        propertyDefs={propertyDefs}
                        minRows={10}
                        minEmptyRows={1}
                        readOnly={readOnly}
                        allowExport
                    />
                </div>
            ),
        },
        {
            title: "Property Table",
            Icon: PIcon,
            height: Math.max(
                225,
                200 + (material?.properties?.length || 0) * 23,
            ),
            minHeight: 225,
            node: processChart && (
                <div style={{ display: "flex", height: "100%" }}>
                    <SamplePropertiesTable
                        material={material}
                        processChart={processChart}
                        updateMaterial={updateMaterial}
                        selectedNodes={selectedNodes}
                        propertyDefs={propertyDefs}
                        minRows={1}
                        minEmptyRows={0}
                        readOnly={readOnly}
                        onTableSelect={setTableSelected}
                        infoMessage={MATERIAL_INFO_NOTE}
                    />
                </div>
            ),
        },
        {
            title: "Components",
            Icon: CIcon,
            height: Math.max(
                150,
                135 + (material?.components?.length || 0) * 23,
            ),
            minHeight: 150,
            node: processChart && (
                <div style={{ display: "flex", height: "100%" }}>
                    <ComponentSummaryTable
                        sampleSet={materialSet}
                        materialDefs={materialDefs}
                        minRows={0}
                        minEmptyRows={0}
                        readOnly={readOnly}
                    />
                </div>
            ),
        },
        {
            title: "Multiplot",
            Icon: BarChartIcon,
            node: <MultiPlotMaterial material={material} />,
        },
    ]

    const [display = [displayTabs[0].title, displayTabs[3].title], setDisplay] =
        useAppStoreKey("EditMaterialDisplay")

    return (
        <div>
            <ConfirmationDialog {...duplicatesProps} />
            <MMToolbar
                color={editing ? "secondary" : "primary"}
                Left={
                    <ResizableViewsSelector
                        displayTabs={displayTabs}
                        display={display}
                        onChange={setDisplay}
                        icons={!large}
                    />
                }
                Center={
                    medium ? (
                        <Typography
                            variant="h6"
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                margin: "0 8px",
                            }}
                        >
                            {material?.title || ""}
                        </Typography>
                    ) : undefined
                }
                Right={
                    <>
                        {!material.id && similar?.length ? (
                            <Button color="secondary" onClick={viewDuplicates}>
                                {`Duplicate? (${
                                    similar.length > 20 ? ">20" : similar.length
                                })`}
                            </Button>
                        ) : undefined}
                        <EditButtons
                            // @ts-ignore
                            readOnly={readOnly}
                            // @ts-ignore
                            editing={editing}
                            // @ts-ignore
                            undo={undo}
                            // @ts-ignore
                            redo={redo}
                            // @ts-ignore
                            onSave={useSave({
                                validateAll,
                                material,
                                account,
                                initialMaterial,
                                mutateAsync,
                                enqueueSnackbar,
                                enqueueDialogs,
                                similar,
                                isFetchingSimilar,
                                fileUploadProps,
                            })}
                            // @ts-ignore
                            onSaved={useSaved({ navigate, updateData })}
                            // @ts-ignore
                            onDelete={useDelete({
                                mutateAsync,
                                material,
                                enqueueDialogs,
                            })}
                            // @ts-ignore
                            onCopy={useCopy({
                                material,
                                updateMaterial,
                                enqueueSnackbar,
                            })}
                            // @ts-ignore
                            onClose={onClose}
                            // @ts-ignore
                            progress={progress}
                            hasTests={!!material?.tests?.length}
                        />
                    </>
                }
            />
            <ResizableViews display={display} displayTabs={displayTabs} order />
        </div>
    )
}

function useSimilarDialog(similar?: Sample[]) {
    return useConfirmDialog({
        title: "Potential Duplicates",
        maxWidth: "md",
        yes: "Close",
        cancel: null,
        message: (
            <div>
                The material you are creating has loosely similar identifiers to
                the following material(s) already in the library. In order to
                cut down on duplicates in the database, please make sure none of
                these match your material. Consider adding an alternate name to
                an existing material if necessary. You may still need to save a
                duplicate to manage access permissions or to document batch
                specific properties such as purity.
                <Divider style={{ margin: "8px 0" }} />
                {similar && (
                    <MaterialsSummaryList
                        ids={similar.map(sample => sample.id)}
                    />
                )}
            </div>
        ),
    })
}
