/**
 * @format
 */
import React, { useState, useMemo } from "react"
import Grid from "@material-ui/core/Grid"
import { PropertyDefinition } from "../../schema/models"
import {
    useQueryMaterial,
    useQueryComponentProperties,
} from "../../API/queryHooks"
import ExpressionField from "../ExpressionEditor/ExpressionField"
import MaterialSelector from "../General/MaterialSelector"
import ExampleResult from "../ExpressionEditor/ExampleResults"
import ExpressionVariableTable from "../ExpressionEditor/ExpressionVariableTable"

export default function CalculatedFields({ propertyDefinition, onChange }) {
    const expression = useMemo(
        () => propertyDefinition?.sourceParameters?.expression || {},
        [propertyDefinition],
    )
    const setExpression = value =>
        onChange(
            new PropertyDefinition({
                ...propertyDefinition,
                sourceParameters: {
                    expression:
                        typeof value === "function" ? value(expression) : value,
                },
            }),
            { name: "Edit Expression" },
        )
    const [samples, setSamples] = useState([])
    const { data: material, isFetching } = useQueryMaterial(
        samples && samples[0],
    )
    const query = useMemo(() => {
        if (!material) return undefined
        const componentProperties = expression?.variables
            ?.reduce(
                (props, v) =>
                    v.filters?.componentProperties
                        ? [...props, ...v.filters.componentProperties]
                        : props,
                [],
            )
            .filter((prop, i, arr) =>
                arr.find((obj, j) => prop.id === obj.id && i === j),
            )
        return { samples: [material], componentProperties }
    }, [expression, material])
    const {
        data: filledSamples,
        isFetching: isFetchingcp,
    } = useQueryComponentProperties(query, query?.componentProperties, {
        keepPreviousData: true,
    })
    const sample = useMemo(() => filledSamples && filledSamples[0], [
        filledSamples,
    ])

    return (
        <>
            <Grid item xs={12}>
                <ExpressionField
                    label="Calculation Expression"
                    placeholder='"sample" or values in the table below are avaiable as variables'
                    scope={[
                        ["sample", `sample - The current sample object`],
                    ].concat(
                        expression.variables?.map(v => [
                            v.name,
                            `${v.name} - User defined variable (see table)`,
                        ]) || [],
                    )}
                    expression={expression.expression || ""}
                    setExpression={v =>
                        setExpression(current => ({
                            ...current,
                            expression: v,
                        }))
                    }
                    fullWidth
                    multiline
                />
            </Grid>
            <Grid item xs={6}>
                <MaterialSelector
                    selectSingle
                    selected={samples}
                    onChange={(ev, vals) => setSamples(vals)}
                    fullWidth
                    label="Example Sample"
                />
            </Grid>
            <Grid item xs={6}>
                <ExampleResult
                    expression={expression}
                    sample={sample}
                    loading={
                        expression?.expression && (isFetching || isFetchingcp)
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <ExpressionVariableTable
                    variables={expression.variables || []}
                    onChange={v =>
                        setExpression(current => ({ ...current, variables: v }))
                    }
                    sample={sample}
                    setSample={val => setSamples([val])}
                />
            </Grid>
        </>
    )
}
