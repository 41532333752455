import DateCell from "../ReactTable/DateCell";
import DateFilterInput from "../ReactTable/DateFilterInput";
import { serializedColumnsFromIds } from "../ReactTable/reactTableUtils";
// import LabsFilterInput from "components/ReactTable/labsFilterInput";

export const instrumentColumns = [
    {
        Header: "Basic Instrument Fields",
        id: "instrument",
        columns: [
            {
                Header: "Instrument Name",
                accessor: "title",
            },
            // {
            //     Header: "Lab",
            //     accessor: "labs.title",
            //     type: "string",
            //     Filter: LabsFilterInput
            // },
            {
                Header: "Created",
                accessor: "timeCreated",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Modified",
                accessor: "timeModified",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Description",
                accessor: "description",
            },
        ],
    }
]

export const defaultColumns = serializedColumnsFromIds(["title", "labs.title", "timeModified", "description"], instrumentColumns);
