import React, { useState, useCallback, useEffect } from "react";
import { GetRemovePlotFunc, InitVizParams, plotlyColorSchemeRGBA } from "./utils";
import { ChartTimeline } from "mdi-material-ui";
import PlotCard from "./PlotCard";
import ColumnMultiSelectField from "./ColumnMultiSelectField";

function ViolinPlot({ data, handleRemovePlot, idx, visible, savedParams='', plot_uuid }) {
  const [headers, setHeaders] = useState([])
  const [plotData, setPlotData] = useState({})
  const [selectedAxis, setSelectedAxis] = InitVizParams(savedParams,'selectedAxis', [])
  const [currentSheet, setCurrentSheet] = InitVizParams(savedParams, 'currentSheet', '')
  const [plotWidthSize, setPlotWidthSize] = InitVizParams(savedParams, 'plotWidthSize','m')
  const [paramsToSave, setParamsToSave] = useState({})
  const plotType = 'violin'
  const cardName = 'Violin Plot'

  // update params to save
  useEffect(() => {
    setParamsToSave({
      ...paramsToSave,
      selectedAxis: selectedAxis,
      currentSheet: currentSheet,
      plotWidthSize: plotWidthSize
    })
  }, [selectedAxis, currentSheet, plotWidthSize]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleRemoveCurrentPlot = GetRemovePlotFunc(savedParams, handleRemovePlot)

  const getForm = () => {
    return (
      <ColumnMultiSelectField
        label={"Headers"}
        value={selectedAxis}
        setValue={setSelectedAxis}
        headers={headers}
      />
    )
  }
  const getIcon = () => {
    return <ChartTimeline style={{ marginTop: 14 }} />
  }
  const getTrace = () => {
    const trace = []
    selectedAxis.forEach((axis, idx) => {
      const x = plotData[axis]
      if (!x) return []
      const newTrace = {
        y: x,
        type: 'violin',
        name: axis,
        opacity: 0.6,
        points: "all",
        jitter: 0,
        meanline: {
          visible: true
        },
        box: {
          visible: true
        },
        line: {
          color: 'black'
        },
        pointpos: -1.2,
        marker: {
          line: {
            width: 2,
            color: plotlyColorSchemeRGBA(idx, 1),
          },
          symbol: "line-ew"
        },
        fillcolor: plotlyColorSchemeRGBA(idx, 1),
      }
      trace.push(newTrace)
    })
    return trace
  }
  const getLayout = () => {
    return {
      autosize: true,
      title: "Violin plot of " + selectedAxis,
      yaxis: { zeroline: false }
    }
  }
  const onSheetChange = useCallback((currentHeaders, currentData) => {
    setHeaders(currentHeaders)
    setPlotData(currentData)
    if (setSelectedAxis && selectedAxis.length === 0) {
      setSelectedAxis([currentHeaders[0]])
    }
  }, [setHeaders, setPlotData, setSelectedAxis]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PlotCard
      plot_uuid={plot_uuid}
      data={data}
      getTrace={getTrace}
      getLayout={getLayout}
      getForm={getForm}
      getIcon={getIcon}
      cardName={cardName}
      savedParams={savedParams}
      currentSheet={currentSheet}
      setCurrentSheet={setCurrentSheet}
      paramsToSave={paramsToSave}
      plotType={plotType}
      setParamsToSave={setParamsToSave}
      plotWidthSize={plotWidthSize}
      setPlotWidthSize={setPlotWidthSize}
      idx={idx}
      handleRemovePlot={handleRemoveCurrentPlot}
      onSheetChange={onSheetChange}
      visible={visible}
    />
  )
}
export default React.memo(ViolinPlot);