import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Button with circular progress overlay. Button will be disabled when loading. 
 * @param props Most props are forwarded to the button Component
 * @param props.loading if true the loading spinner will be shown
 * @param props.spinnerColor material-ui theme color for the spinner
 * @param props.Component root button component to use, default material-ui Button
 * @param props.value optional loading progress value shown when loading is true
 */
export const ButtonWithSpinner: React.FC<{
    loading: boolean,
    spinnerColor?: "primary" | "secondary",
    disable?: boolean,
    Component?: any,
    value?: any
} & ButtonProps> =  React.forwardRef(function ButtonWithSpinner({
    loading, 
    children, 
    spinnerColor, 
    disabled, 
    Component=Button, 
    value=undefined, 
    ...props
}, ref){
    return (
        <Component ref={ref} disabled={disabled || !!loading} {...props}>
            {children}
            {loading && 
                <CircularProgress
                    size={24} 
                    color={spinnerColor}
                    variant={value !== undefined ? "determinate" : undefined}
                    value={value}
                    style={{ 
                        position: 'absolute', 
                        top: `calc(50% - 12px)`,
                        left: `calc(50% - 12px)`,
                        zIndex: 1,
                        }} 
                    />
            }
        </Component>
    );
})
export default ButtonWithSpinner
