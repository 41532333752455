import React, { useMemo, useState, useCallback, useEffect } from 'react';
import StyledReactTable, { defaultColumn, LOADING_ROW }  from '../ReactTable/StyledReactTable';
import { useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters, useRowSelect } from 'react-table';
import { useColumns, useOnRowSelect } from '../ReactTable/reactTableUtils';
import DialogWithLoading from '../General/DialogWithLoading';
import TableButtonGroup from '../General/TableButtonGroup';
import { Button, makeStyles, Typography, Grid, DialogActions } from '@material-ui/core';
import CustomizeColumnsDialog from '../ReactTable/CustomizeColumnsDialog';
import common_styles from '../../styles/common_styles';
import { addSelectColumn } from '../ReactTable/addSelectColumn';
import { useQueryPagination } from 'API/queryHooks';
import { DEFAULT_TABLE_STATE } from 'components/ReactTable/constants';

const useStyles = makeStyles(common_styles);
const emptyArray = [];
/** Basic dialog for doing table selections. This is meant to be wrapped by higher components for specific objects */
function TableSelectorDialogBase({
    open,
    onClose,
    selectSingle,
    initSelected = emptyArray,
    title,
    localForageKey,
    onTableParam,
    SelectComponent,
    tableColumns,
    defaultColumns,
}){
    const [selected, setSelectedRaw] = useState(initSelected);
    const setSelected = useCallback((values) => {
        if (!selectSingle || !values || values.length < 2) setSelectedRaw(values);
        else {
            const value = values.find(v => selected?.indexOf(v) < 0)
            setSelectedRaw(value ? [value] : [])
        }
    }, [selectSingle, selected, setSelectedRaw])
    const handleClose = useCallback((selected) => {
        onClose(selected?.length > 0 ? selected : undefined);
    }, [onClose])
    useEffect(() => {
        if (open) {
            setSelectedRaw(initSelected)
        }
    }, [initSelected, open])
    return (
        <DialogWithLoading open={open} onClose={() => handleClose(selected)} disableEscapeKeyDown={false} disableBackdropClick={false}>
            <Internal 
                selected={selected} 
                setSelected={setSelected} 
                onClose={handleClose} 
                title={title} 
                localForageKey={localForageKey}
                onTableParam={onTableParam}
                SelectComponent={SelectComponent}
                tableColumns={tableColumns}
                defaultColumns={defaultColumns}
            />
            <DialogActions>
                <Button color="primary" onClick={() => handleClose()}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => handleClose(selected)}>
                    Ok
                </Button>
            </DialogActions>
        </DialogWithLoading>
    );
}
export default React.memo(TableSelectorDialogBase);

function Internal({selected, setSelected, title, localForageKey, onTableParam, SelectComponent, tableColumns, defaultColumns}){
    const classes = useStyles();
    const [serializedColumns, setSerializedColumns, columns, loading] = useColumns(localForageKey, tableColumns, defaultColumns);
    const [editColumns, setEditColumns] = useState(false);
    const handleColumns = useCallback(() => setEditColumns(true), []);
    const handleCloseColumns = useCallback((cols) => {setEditColumns(false); setSerializedColumns(cols)}, [setSerializedColumns]);
    const [queryParam, setQueryParam] = useState();
    const [tableState, setTableState] = useState(DEFAULT_TABLE_STATE);
    const handleTableParam = useCallback(tableParam => {
        setTableState(tableParam);
        setQueryParam(onTableParam({tableParam, columns, serializedColumns}));
    }, [columns, onTableParam, serializedColumns]);
    const { data: resolvedData, isLoading, isFetching } = useQueryPagination((tableState?.pageIndex || 0) + 1, queryParam, { logName: "Select Table" }, LOADING_ROW);
    const handleSelect = useCallback((ev, value) => setSelected(value), [setSelected]);
    const data = useMemo(()=>resolvedData?.data || [], [resolvedData]);
    
    const table = useTable({
        columns,
        defaultColumn,
        data,
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        autoResetFilters: false,
        totalRows: useMemo(() => resolvedData?.count, [resolvedData]),
        pageCount: Math.max(Math.ceil((resolvedData?.count || 0) / (tableState?.pageSize || 10)), 1),
        getRowId: useCallback((row, relIndex, parent) => parent ? [parent.id, row.id || relIndex] : (row.id || relIndex), []),
        autoResetSelectedRows: false,
    },
        useFlexLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        addSelectColumn,
    );
    const {selectedRowIds} = table.state;

    useOnRowSelect({selectedRowIds, toggleRowSelected: table.toggleRowSelected, data, selected, setSelected});
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className={classes.cardTitle} gutterBottom>
                    {title || "Seach and Select"}
                </Typography>
                <Typography className={classes.cardBodyText}>
                    Use the table below to search and select from all available values
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <SelectComponent label="Selected Values" selected={selected} onChange={handleSelect} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <div style={{ textAlign: "initial" }}>
                    <CustomizeColumnsDialog columnDefinitions={tableColumns} serializedColumns={serializedColumns} defaultColumns={defaultColumns} open={editColumns} onClose={handleCloseColumns} />
                    <TableButtonGroup>
                        <Button onClick={handleColumns}>
                            Edit Columns and Filters
                            </Button>
                    </TableButtonGroup>
                    <StyledReactTable
                        {...table}
                        loading={loading || (isLoading && !data?.length)}
                updating={isFetching || (isLoading && data?.length)}
                        onChangeParam={handleTableParam}
                    />
                </div>
            </Grid>
        </Grid>
    );
}