import React, { useEffect, useRef, useState } from "react"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useSnackbar } from "notistack"
import common_styles from "../../../../styles/common_styles"
import MLModelBase from "./MLModelBase";
import useTrainModel from "../useTrainModel";

const useStyles = makeStyles(common_styles);


function KRR({handleRemovePlot, modelKey}) {

  const classes = useStyles();
  const modelName = "Kernel Ridge Regression";
  const modelDescription = "Kernel ridge regression (KRR) combines ridge regression (linear\n" +
    "least squares with l2-norm regularization) with the kernel trick. It thus learns a linear\n" +
    "function in the space induced by the respective kernel and the data. For non-linear kernels,\n" +
    "this corresponds to a non-linear function in the original space."
  const [params, setParams] = useState({'alpha': 'auto', 'kernel':'auto', 'degree': 'auto'})  // default values
  const [isLoading, ] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const { TrainModel } = useTrainModel({ enqueueSnackbar })
  const currentComponent = useRef(true)
  useEffect(() => () => currentComponent.current = false, [])
  const parameter_space = {
    "alpha": ['auto', 0.01, 0.1, 1.0, 5.0, 10.0],
    "kernel": ['auto', "linear", "rbf", "polynomial", "laplacian", "cosine"],
    "degree": ['auto', 3, 5, 7],
  }

  let modelParams = {
    KRR_model: {
      kwargs: { 'params': params }
    }
  }

  const handleParamChange = (paramName) => (event) => {
    let newParam = {}
    newParam[paramName] = event.target.value
    setParams({...params, ...newParam})
  }

  return (
    <>
      <MLModelBase
        modelName={modelName}
        modelDescription={modelDescription}
        handleTrainModel={TrainModel(modelParams, modelName)}
        handleRemovePlot={handleRemovePlot}
        isLoading={isLoading}
        modelKey={modelKey}
      >
        {Object.keys(parameter_space).map((paramName, idx) => {
          return (
            <div key={idx}  style={{ marginLeft: 36}}>
              <FormControl component="fieldset" className={classes.formControl}>
                <InputLabel id={paramName + "select-label"}>{paramName}</InputLabel>
                <Select
                  labelId={paramName + "select-label"}
                  id={paramName + "select-id"}
                  value={params[paramName]}
                  onChange={handleParamChange(paramName)}
                >
                  {parameter_space[paramName].map((paramOption, idx) => {
                    return (<MenuItem key={'param_' + idx} value={paramOption}>{paramOption}</MenuItem>);
                  })}
                </Select>
              </FormControl>
            </ div>
          );
        })}
      </MLModelBase>
    </>
  );
}

export default React.memo(KRR);