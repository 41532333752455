import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
    useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters, 
} from 'react-table';

import { Button } from '@material-ui/core';

import { useQueryPagination } from '../../API/queryHooks';
import StyledReactTable, { defaultColumn, LOADING_ROW } from '../ReactTable/StyledReactTable';
import { DEFAULT_TABLE_STATE, DEFAULT_PAGE_SIZE } from '../ReactTable/constants';

import { useAppStoreKey } from '../../AppStore';
import { makeQueryFilters, useColumns } from '../ReactTable/reactTableUtils';
import { testColumns, defaultColumns } from './TestColumns';
import CustomizeColumnsDialog from '../ReactTable/CustomizeColumnsDialog';
import TableButtonGroup from '../General/TableButtonGroup';
import { addOpenColumn } from 'components/ReactTable/addOpenColumn';


function TestsTable({ users, tags, onEdit, unlinkedOnlyFilter, validOnlyFilter }) {
    const [serializedColumns, setSerializedColumns, columns, columnsLoading] = useColumns("TestTableColumns", testColumns, defaultColumns);
    const [editColumns, setEditColumns] = useState(false);
    const handleColumns = useCallback(() => setEditColumns(true), []);
    const handleCloseColumns = useCallback((cols) => { setEditColumns(false); setSerializedColumns(cols) }, [setSerializedColumns]);
    const [tableState = { ...DEFAULT_TABLE_STATE }, setTableState] = useAppStoreKey("testTable");

    const queryParam = useMemo(() => {
        // merge table and user filters
        const userFilter = users?.length ? [{
            or: [
                { name: "creator.name", val: users.map(u => `"${u}"`).join(" "), op: "text" },
                { name: "modifier.name", val: users.map(u => `"${u}"`).join(" "), op: "text" }
            ]
        }] : [];
        const tagFilter = tags?.length > 0 ? {
            or: [
                { name: "tagsRel.title", val: tags, op: "ilike" },
                { name: "tests.tagsRel.title", val: tags, op: "ilike" },
            ]
        } : [];


        const unlinkedFilter = unlinkedOnlyFilter ? { not: { name: "samples", op: "any", val: { or: [] } } } : { name: "samples", op: "any", val: { or: [] } };
        let filters = userFilter.concat(tagFilter, unlinkedFilter, makeQueryFilters(tableState?.filters, columns));
        if (validOnlyFilter) {
            filters = filters.concat({ name: "valid", op: "eq", val: true });
        }
        const fields = [];
        if (columns.find(col => col.accessor === "instrument.title"))
            fields.push("instrument.title");
        if (columns.find(col => col.id === "tags" || ((col.id || col.accessor) !== "instruments.length" && (col.id || col.accessor).startsWith("instruments"))))
            fields.push("instrument.title");
        if (!!serializedColumns.find(col => col.colId.startsWith("labs")))
            fields.push("labs.title");
        fields.push("labs.lab");
        if (serializedColumns.find(col => col.colId.startsWith("samples")))
            fields.push("samples.title");

        // convert sort and append timeModified
        const sort = (tableState?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");
        return (tableState && {
            Model: "Test",
            pageNumber: tableState.pageIndex + 1,
            pageSize: tableState.pageSize,
            filter: filters,
            sort,
            fields: fields.length > 0 ? fields : undefined
        });
    }, [columns, serializedColumns, tableState, tags, users, unlinkedOnlyFilter, validOnlyFilter]);

    const { data: resolvedData, isLoading, isFetching } = useQueryPagination((tableState.pageIndex || 0) + 1, queryParam,  { logName: "Test Table" }, LOADING_ROW);
    const loading = columnsLoading;
    const data = useMemo(() => resolvedData?.data || [], [resolvedData]);
    const handleEdit = useCallback((row) => {
        onEdit(row)
    }, [onEdit])

    const table = useTable({
        columns,
        defaultColumn,
        initialState: tableState,
        data,
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        autoResetFilters: false,
        totalRows: resolvedData?.count,
        pageCount: Math.max(Math.ceil((resolvedData?.count || 0) / (tableState?.pageSize || DEFAULT_PAGE_SIZE)), 1),
        getRowId: useCallback((row, relIndex, parent) => parent ? [parent.id, row.id || relIndex] : (row.id || relIndex), []),
        autoResetSelectedRows: false,
        onEdit: handleEdit
    },
        useFlexLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        // useRowSelect, <-- uncomment when doing "compare" functionality
        addOpenColumn,
        // addSelectColumn, <-- uncomment when doing "compare" functionality

    );

    // const [selected, setSelected] = useState([]);
    // useOnRowSelect({ selectedRowIds: table.state.selectedRowIds, toggleRowSelected: table.toggleRowSelected, data, selected, setSelected });


    const gotoPage = table.gotoPage;
    useEffect(() => {
        gotoPage && gotoPage(0);
    }, [users, tags, gotoPage]);
    const { state: { filters }, setAllFilters } = table;
    const handleClearFilters = useCallback(() => {
        setAllFilters && setAllFilters([]);
    }, [setAllFilters]);

    return (
        <div style={{ textAlign: "initial" }}>
            <CustomizeColumnsDialog
                columnDefinitions={testColumns}
                serializedColumns={serializedColumns}
                defaultColumns={defaultColumns}
                open={editColumns}
                onClose={handleCloseColumns}
            />
            <TableButtonGroup
                // left={
                //     selected?.length > 0 &&
                //     <>
                //         <Typography variant="subtitle2" color="primary" style={{ marginLeft: 8 }}>
                //             {`${selected.length} Test${selected.length > 1 ? "s" : ""} Selected`}
                //         </Typography>
                //         <IconButton onClick={() => setSelected([])} size="small">
                //             <ClearIcon fontSize="small" />
                //         </IconButton>
                //     </>
                // }
            >
                <Button disabled={!filters || filters.length === 0} color='primary' variant='text' onClick={handleClearFilters}>
                    Clear Filters
                </Button>
                <Button onClick={handleColumns}>
                    Edit Columns and Filters
                </Button>
            </TableButtonGroup>
            <StyledReactTable
                {...table}
                loading={loading || (isLoading && !data?.length)}
                updating={isFetching || (isLoading && data?.length)}
                onChangeParam={setTableState}
            />
        </div>
    );
}
export default React.memo(TestsTable);