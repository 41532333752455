import React, { useCallback } from 'react';
import { makeQueryFilters } from '../ReactTable/reactTableUtils';
import {ProcessSelectorBase} from '../General/ProcessSelector';
import TableSelectorDialogBase from './TableSelectorDialogBase';
import {processColumns, defaultColumns} from '../ProcessLibrary/processColumns';

/** Table selection dialog for process */
export default function SelectProcessTableDialog({open, onClose, initSelected, ...rest}){
    const handleTableParam = useCallback(({tableParam, columns}) => {
        const filters = makeQueryFilters(tableParam?.filters, columns);
        // convert sort and append timeModified
        const sort = (tableParam?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");
        return (tableParam && {
            Model: "ProcessDefinition",
            filter: filters,
            sort,
            pageNumber: tableParam.pageIndex+1, 
            pageSize: tableParam.pageSize, 
        })
    }, [])

    return (
        <TableSelectorDialogBase 
            open={open} 
            onClose={onClose} 
            initSelected={initSelected}
            localForageKey="SelectProcessTableColumns"
            onTableParam={handleTableParam}
            SelectComponent={ProcessSelectorBase}
            title="Search and Select Process"
            tableColumns={processColumns}
            defaultColumns={defaultColumns}
            {...rest}
        />
    );
}