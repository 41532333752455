import React, { useEffect, useMemo } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useAppStoreKey } from "../../../../AppStore";
import { makeStyles } from "@material-ui/core/styles";
import common_styles from "../../../../styles/common_styles";

const useStyles = makeStyles(common_styles);
export function SelectColumns({allCols, setAllCols, selectedCols, setSelectedCols, legendText}) {

  const classes = useStyles();
  const [workflow, ] = useAppStoreKey("Workflow");
  const nSelected = useMemo(() => {
    let n = 0;
    for (let i = 0; i < selectedCols.length; i++) {
      if (selectedCols[i]) {
        n++;
      }
    }
    return n;
  }, [selectedCols]);

  const isAllSelected = () =>  allCols.length > 0 && nSelected === allCols.length;

  // const [allCols, setAllCols] = useState([])
  // const [selectedCols, setSelectedCols] = useState([])

  // On workflow change, update header information
  useEffect(() => {
    const allHeaders = workflow.data.info[workflow.data.active_sheet].all_headers
    setAllCols(allHeaders)
    setSelectedCols(allHeaders.map(() => false))
  }, [setAllCols, setSelectedCols, workflow])

  const getStatus = (item) => {
    let idx = allCols.indexOf(item)
    return selectedCols[idx] ? selectedCols[idx] : false

  }

  const handleSelectChange = (event) => {
    let colName = event.target.name
    let idx = allCols.indexOf(colName)
    let newSelectedCols = [...selectedCols]
    newSelectedCols[idx] = !selectedCols[idx]
    setSelectedCols(newSelectedCols)
  }

  // remove duplicated columns in allCols
  const getAllCols = () => [...new Set(allCols)]
  const handleSelectAll = () => {
    let newSelectedCols = [...selectedCols]
    for (let i = 0; i < allCols.length; i++) {
      if (isAllSelected()) {
        newSelectedCols[i] = false
      } else newSelectedCols[i] = nSelected >= 0;
    }
    setSelectedCols(newSelectedCols)
  }

  return <div style={{ marginLeft: 24, marginTop: 12}}>
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{legendText? legendText : 'Select columns to be scaled'}</FormLabel>
      <FormGroup>
        <FormControlLabel
          value="all"
         control={<Checkbox
           checked={isAllSelected()}
           indeterminate={ nSelected > 0 && nSelected < allCols.length }
           onChange={handleSelectAll}
         />} label={isAllSelected()? "Deselect All" : "Select All"}>
        </FormControlLabel>
        {getAllCols().map(item => {
          return <FormControlLabel
            key={item}
            control={<Checkbox checked={getStatus(item, 'input')} onChange={handleSelectChange} name={item} />}
            label={item}
          />;
        })}
      </FormGroup>
    </FormControl>
  </div>
}