import Typography from '@material-ui/core/Typography';

const style = {margin: "16px", textAlign: "center"};

export default function NotConnected({isLoading}){
    return (
        <Typography style={style} color="error" variant="h4">
            {isLoading ? "Connecting to database..." : "Database not connected"}
        </Typography>
    );
}