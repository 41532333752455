import { PropertyDefinition, Sample } from '../models'
import { INode } from "react-flow-chart-mmm"
import { Schema, schema } from '../schemaUtils'
import { Base } from '../Base'

export class PropertyInstance extends Base {
    data?: string | number
    sourceNotes?: string
    sourceUrl?: string
    node?: INode
    definition?: PropertyDefinition
    sample?: Sample
    nodeId?: string
    roles?: string[]

    static readonly [schema]: Schema<PropertyInstance> = {
        endPoint: "property_instances",
        type: "property_instance",
        relationships: {
            ...Base[schema].relationships,
            sample: { map: "sample", model: "Sample", toMany: false, reverse: "properties", required: true },
            definition: { map: "property_definition", model: "PropertyDefinition", toMany: false, reverse: "instances", required: true }
        },
        map: {
            ...Base[schema].map,
            "sample.id": "sample_id",
            "definition.id": "property_definition_id",
            "data": "meta.data",
            "node.id": "meta.node_id",
            "sourceNotes": "meta.source_notes",
            "sourceUrl": "meta.source_url",
            "nodeId": "meta.node_id",
            "roles": "meta.roles"
        }
    }

    constructor(toCopy?: Partial<PropertyInstance>) {
        super()
        Object.assign(this, toCopy)
    }
}