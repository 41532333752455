import { useEffect, useState, useCallback, useRef, createContext, useContext } from "react";
import localforage from 'localforage';

export const forageContext = createContext([{}, () => {}])

/**
 * Forage context store to cache data for faster retrival
 */
 export const ForageCacheProvider = ({children}) => {
    return (
        <forageContext.Provider value={useState({})}>
            {children}
        </forageContext.Provider>
        );
}

/**
 * hook to create a connection between a value and localforage. The value will update from localforage on mount.
 * @param {String} key localforge item key
 * @param {*} defaultValue value use while loading and if key is missing from localforage
 * @return {[any, function, boolean]} [value, setValue, isLoading]
 */
export function useLocalForageState(key, defaultValue){
    const [cache, setCache] = useContext(forageContext)
    const value = cache[key] !== undefined ? cache[key] : defaultValue
    const [isLoading, setIsLoading] = useState(true);
    const mounted = useRef(true);
    useEffect(() => () => mounted.current = false, []);
    // load from localforage on mount
    useEffect(() => forageEffect(key, setIsLoading, setCache, mounted), [key, setCache]);
    // sync localforge on update to users
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateValue = useCallback(buildForageSetCallback(key, setCache), [key]); 
    return [value, updateValue, isLoading];
}

function forageGet(key, setIsLoading, setCache, mounted){
    return localforage.getItem(key).then(item => {
        if (mounted.current){
            if (item !== undefined && item !== null) {
                setCache(v => ({...v, [key]: item}))
            }
            setIsLoading(false);
        }
        return item;
    });
}

function forageEffectProd(key, setIsLoading, setCache, mounted){
    forageGet(key, setIsLoading, setCache, mounted);
}
function forageEffectDebug(key, setIsLoading, setCache, mounted){
    forageGet(key, setIsLoading, setCache, mounted).then(item => console.log("Localforage get - %s %O", key, item));
}
const forageEffect = process.env.NODE_ENV === 'production' ? forageEffectProd : forageEffectDebug;

function buildForageSetCallbackProd(key, setCache){
    return (newValue) => {
        setCache(v => ({...v, [key]: newValue}))
        localforage.setItem(key, newValue);
    }
}
function buildForageSetCallbackDebug(key, setCache){
    return (newValue) => {
        console.log("Localforage set - %s %O", key, newValue);
        buildForageSetCallbackProd(key, setCache)(newValue);
    }
}
const buildForageSetCallback = process.env.NODE_ENV === 'production' ? buildForageSetCallbackProd : buildForageSetCallbackDebug;