import { Sample } from '../models'
import { INode } from "react-flow-chart-mmm"
import { Schema, schema } from '../schemaUtils'
import { Base } from '../Base'

export class SampleInstance extends Base {
    amount?: number
    totalFraction?: number
    groupFraction?: number
    lot?: string
    node?: INode
    units?: string
    definition?: Sample
    sample?: Sample

    nodeId?: string
    roles?: string[]

    static readonly [schema]: Schema<SampleInstance> = {
        endPoint: "sample_instances",
        type: "sample_instance",
        relationships: {
            ...Base[schema].relationships,
            sample: { map: "sample", model: "Sample", toMany: false, reverse: "components", required: true },
            definition: { map: "sample_definition", model: "Sample", toMany: false, reverse: "instances", required: true },
        },
        map: {
            ...Base[schema].map,
            "sample.id": "sample_id",
            "definition.id": "sample_definition_id",
            "amount": "meta.amount",
            "node.id": "meta.node_id",
            "units": "meta.units",
            "nodeId": "meta.node_id",
            "roles": "meta.roles"
        }
    }

    constructor(toCopy?: Partial<SampleInstance>) {
        super()
        Object.assign(this, toCopy)
    }
}
