import UserFilterInput from "../ReactTable/UserFilterInput";
import DateCell from "../ReactTable/DateCell";
import DateFilterInput from "../ReactTable/DateFilterInput";
import { serializedColumnsFromIds } from "../ReactTable/reactTableUtils";
import CategoryCell from "../ReactTable/CategoryCell";
import CategoryFilterInput from "../ReactTable/CategoryFilterInput";
import LabsFilterInput from "../ReactTable/labsFilterInput";

export const propertyColumns = [
    {
        Header: "Basic Process Fields",
        id: "property",
        columns: [
            {
                Header: "Property Name",
                accessor: "title",
            },
            {
                Header: "Contributor(s)",
                id: "contributors",
                accessor: set => set.contributors?.join(", "),
                Filter: UserFilterInput,
            },
            {
                Header: "Created",
                accessor: "timeCreated",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Modified",
                accessor: "timeModified",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Data Source",
                accessor: "source",
                Cell: CategoryCell,
                Filter: CategoryFilterInput,
                categories: [{label: "Manual Entry", value: "mm"}, {label: "Auto-ingestion", value: "em"}, {label: "Calculated", value: "cv"}],
            },
            {
                Header: "Permissions",
                id: "labs.lab.title",
                accessor: data => data.labs?.map(lab => lab.lab?.title).filter((title, i, arr) => arr.indexOf(title) === i).join(" | "),
                Filter: LabsFilterInput,
            }
        ],
    },
]

export const defaultColumns = serializedColumnsFromIds(["title", "contributors", "timeModified", "source", "description"], propertyColumns);