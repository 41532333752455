/**
 * @format
 */

/*
Pubchem citation:
Kim S, Chen J, Cheng T, Gindulyte A, He J, He S, Li Q, Shoemaker BA, Thiessen PA, 
Yu B, Zaslavsky L, Zhang J, Bolton EE. 
PubChem 2019 update: improved access to chemical data. 
Nucleic Acids Res. 2019 Jan 8; 47(D1):D1102-1109. doi:10.1093/nar/gky1033. [PubMed PMID: 30371825] 
*/

/*
desired features/functionality:
1.  search by name or CAS, or CID (or automatically determines which fields are populated and search based on that)
    - search by CID first, then by CAS, then by name
    - ability to handle multiple results
2.  Error handling
3.  pop up to ask user if he/she wants to overwrite the fields

*/

/*
Autocomplete search to find candidates
https://pubchem.ncbi.nlm.nih.gov/rest/autocomplete/compound/nit/json?limit=100
Note: if we don't provide limit option, it will default to 10, if the results exceed
100, it will start give status warning:
example of response body.
{
    "status": {
        "warning": "compound: too many records are found matching your query; the search was terminated after 100 records were processed to generate the final result | "
    },
    "total": 100,
    "dictionary_terms": {
        "compound": [
            "nitrendipine",
            "Nitrogen",
            "NITAZOXANIDE",
            .
            .
            .
        ]
    }
}
         
*/

import axios, { AxiosResponse } from "axios"
import { validateCasNo } from "../utils/utils"

const pubChemBaseURL = "https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/"
const pubchemAxios = axios.create({
    baseURL: pubChemBaseURL,
    headers: {},
})
export default pubchemAxios

/**
 * test Pubchem get
 * @param {String} pubchemId specific pubchem ID
 */

export function getPubChemURL(pubChemId: string) {
    if (validateCasNo(pubChemId)) {
        const searchBy = `name/${pubChemId}`
        return pubChemBaseURL + searchBy
    } else {
        const searchBy = `cid/${pubChemId}`
        return pubChemBaseURL + searchBy
    }
}

// full list: https://pubchemdocs.ncbi.nlm.nih.gov/pug-rest$_Toc494865567
interface PubchemProperties {
    CID: number
    MolecularFormula: any
    MolecularWeight: any
    CanonicalSMILES: any
    IsomericSMILES: any
    InChI: any
    InChIKey: any
    IUPACName: any
    XLogP: any
    ExactMass: any
    MonoisotopicMass: any
    TPSA: any
    Complexity: any
    Charge: any
    HBondDonorCount: any
    HBondAcceptorCount: any
    RotatableBondCount: any
    HeavyAtomCount: any
    IsotopeAtomCount: any
    AtomStereoCount: any
    DefinedAtomStereoCount: any
    UndefinedAtomStereoCount: any
    BondStereoCount: any
    DefinedBondStereoCount: any
    UndefinedBondStereoCount: any
    CovalentUnitCount: any
    Volume3D: any
    XStericQuadrupole3D: any
    YStericQuadrupole3D: any
    ZStericQuadrupole3D: any
    FeatureCount3D: any
    FeatureAcceptorCount3D: any
    FeatureDonorCount3D: any
    FeatureAnionCount3D: any
    FeatureCationCount3D: any
    FeatureRingCount3D: any
    FeatureHydrophobeCount3D: any
    ConformerModelRMSD3D: any
    EffectiveRotorCount3D: any
    ConformerCount3D: any
    Fingerprint2D: any
}

type PubchemPropertyNames = keyof PubchemProperties

// https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/cid/7268/property/IUPACName,MolecularWeight,CanonicalSMILES,XLogP,InChIKey/JSON
interface PubchemPropertyResponse {
    PropertyTable: {
        Properties: PubchemProperties[]
    }
}

interface PubchemSynonym {
    CID: number
    Synonym: string[]
}

// https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/cid/7268/synonyms/JSON
interface PubchemSynonymResponse {
    InformationList: {
        Information: PubchemSynonym[]
    }
}

/**
 * test Pubchem get
 * @param {String} url specific pubchem url with search param
 * @param {String} prop list of properties to acquire, comma separated
 */
export function getPropsAPI(
    url: string,
    propList: PubchemPropertyNames[] = [
        "IUPACName",
        "MolecularWeight",
        "CanonicalSMILES",
        "XLogP",
        "InChIKey",
    ],
): Promise<AxiosResponse<PubchemPropertyResponse>> {
    const prop = propList.join(",")

    //return properties
    return pubchemAxios.get(url + `/property/` + prop + `/JSON`)
}

//CAS also listed as part of synonym.
export function getNamesAPI(
    url: string,
): Promise<AxiosResponse<PubchemSynonymResponse>> {
    return pubchemAxios.get(url + `/synonyms/JSON`)
}

export function getPubChem(url: string, prop: string | null = null) {
    return Promise.all([
        getPropsAPI(
            url,
            prop?.split(",") as PubchemPropertyNames[] | undefined,
        ),
        getNamesAPI(url),
    ])
}
