import { useState } from "react";
import { aichemyProtoSaveFigure, aichemyProtoUpdateWorkflow } from "../utils";
import { useSnackbar } from "notistack";
import { useAppStoreKey } from "../../../AppStore";

export function plotlyColorSchemeRGBA(idx, A) {
  const shade = 500;
  const plotlyColorOrder = [9, 6, 15, 2, 18, 0, 3, 14]
  return MUColorRGBA(plotlyColorOrder[idx%plotlyColorOrder.length], shade, A)
}

export function MUColorRGB(idx, shade) {

  // from https://www.materialui.co/colors
  const allColors = [
    { 100: "", 200: "", 300: "", 400: "rgba(239,83,80)", 500: "rgba(244,67,54)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgba(233,30,99)", 500: "rgb(233,30,99)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(171,71,188)", 500: "rgb(156,39,176)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(126,87,194)", 500: "rgb(103,58,183)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(92,107,192)", 500: "rgb(63,81,181)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(66,165,245)", 500: "rgb(33,150,243)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(41,182,246)", 500: "rgb(3,169,244)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(38,198,218)", 500: "rgb(0,188,212)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(38,166,154)", 500: "rgb(0,150,136)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(102,187,106)", 500: "rgb(76,175,80)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(156,204,101)", 500: "rgb(139,195,74)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(212,225,87)", 500: "rgb(205,220,57)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(255,238,88)", 500: "rgb(255,235,59)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(255,202,40)", 500: "rgb(255,193,7)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(255,167,38)", 500: "rgb(255,152,0)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(255,112,67)", 500: "rgb(255,87,34)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(141,110,99)", 500: "rgb(121,85,72)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(189,189,189)", 500: "rgb(158,158,158)", 600: "", 700: "", 800: "", 900: "" },
    { 100: "", 200: "", 300: "", 400: "rgb(120,144,156)", 500: "rgb(96,125,139)", 600: "", 700: "", 800: "", 900: "" },
  ]

  return allColors[idx % allColors.length][shade]
}

export function MUColorRGBA(idx, shade, A) {
  const rgbColor = MUColorRGB(idx, shade).replace('rgb', 'rgba')
  return rgbColor.substring(0, rgbColor.length - 1) + "," + A.toString() + ")";
}

export const isNumber = function isNumber(value) {
  return typeof value === 'number' && isFinite(value);
}

export function wordWrap(str, charMax) {
  let arr = [];
  let space = /\s/;

  const words = str.split(space);
  // push first word into new array
  if (words[0].length) {
    arr.push(words[0]);
  }

  for (let i = 1; i < words.length; i++) {
    if (words[i].length + arr[arr.length - 1].length < charMax) {
      arr[arr.length - 1] = `${arr[arr.length - 1]} ${words[i
      ]}`;
    } else {
      arr.push(words[i]);
    }
  }
  return arr.join('<br>');
}

export const plotSizeMapper = { s: 4, m: 6, l: 12 }
export const plotHeightMapper = { dataHist: 22, box: 20, scatter: 26, violin: 19, correlation: 19 }

// init parameters used for plotting. If the parameter is saved, return it. If it's
// not saved, return useState
// export function InitVizParams(savedParams, key, defaultVal) {
//   const [value, setValue] = useState(defaultVal)
//   if (!savedParams[key]) {
//     return [value, setValue]
//   } else {
//     return [savedParams[key], null]
//   }
//
// }

export function InitVizParams(savedParams, key, defaultVal) {
  let new_defaultVal
  if (savedParams[key]) {
    new_defaultVal = savedParams[key]
  } else {
    new_defaultVal = defaultVal
  }
  const [value, setValue] = useState(new_defaultVal)
  return [value, setValue]

}

// Get the function to save plot in Ap
export const GetSavePlotFunc = (paramsToSave, savedParams, plotType, idx, uuid, setUuid, handleRemovePlot) => {
  const { enqueueSnackbar } = useSnackbar()
  const [workflow, setWorkflow] = useAppStoreKey("Workflow");
  return () => {
    let params = {
      type: plotType,
      params: paramsToSave
    };

    console.log('saving plot', paramsToSave)

    if (savedParams) {
      params.uuid = uuid
    }

    const payload = {
      visualization: {
        UpdateVisualizationFigure: {
          "kwargs": {
            "operation": !savedParams ? "add" : "update",
            "viz_plot_params": params
          }
        }
      }
    };

    aichemyProtoSaveFigure(payload, workflow, setWorkflow, setUuid, handleRemovePlot, idx).catch(err => {
      enqueueSnackbar("Failed to save figure.", { variant: "error" });
      console.error(err);
    });
  };
}

// For unsaved plot, return the normal handleRemovePlot. For saved plot, call API
// to remove the plot in Ap
export const GetRemovePlotFunc = (savedParams, handleRemovePlot) => {
  const { enqueueSnackbar } = useSnackbar()
  const [workflow, setWorkflow] = useAppStoreKey("Workflow");
  if (!savedParams) {
    return handleRemovePlot
  }
  return (idx) => {
    const params = {
      'uuid': workflow.viz_plot_params[idx].uuid
    }
    const payload = {
      visualization: {
        UpdateVisualizationFigure: {
          "kwargs": {
            "operation": 'remove',
            'viz_plot_params': params
          }
        }
      }
    }
    aichemyProtoUpdateWorkflow(payload, workflow, setWorkflow, false).catch(err => {
      enqueueSnackbar("Failed to remove figure.", { variant: "error" });
      console.error(err)
    })
  };
}
