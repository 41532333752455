export const commonProperties = {
    casId: "CAS ID",
    _3MId: "3M RM ID",
    smiles: "SMILES",
    molecularWeight: "Molecular Weight",
    density: "Density",
    manufacturer: "Manufacturer",
    thickness: "Tape Thickness", //"VHB product thickness",
    productTypes: "Product Type"
}
const defaultProperties = {
    ...commonProperties,
    url: "Reference Web URL",
    pubChemId: "PubChem ID",
    iupacName: "IUPAC Name",
    inChiKey: "InChI Key",
};
export default defaultProperties