import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import BufferTextField from '../General/BufferTextField';
import TagSelector from '../General/TagSelector';
import LabSelector from '../General/LabSelector';
import UserSelector from '../General/UserSelector';
import { value } from 'utils/useFormState/useFormStateUtils';
import { LabToSampleSet } from 'schema/models';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    editorClass: {
        backgroundColor: theme.palette.background,
        margin: "none",
        "& .public-DraftStyleDefault-block": {
            margin: 0
        }
    },
    toolbarClass:
    {
        backgroundColor: theme.palette.background,
        border: "0px",
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
    },
    hideToolbarClass: {
        display: "none"
    },
    dropdownClass:
    {
        color: "#000",
        border: "0px",
        borderRadius: "5px",
        "&:hover": {
            color: "#000"
        },
    },
    wrapperClass:
    {
        border: "1px solid",
        borderColor: theme.palette.text.disabled,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.background,
        "&:hover": {
            borderColor: theme.palette.text.primary,
        },
        "&:focus-within": {
            border: "2px solid",
            borderColor: theme.palette.primary.main
        }
    },
    title: {
        color: theme.palette.text.secondary,
    },
    focused: {
        color: theme.palette.primary.main,
    }
})
);

// Missing is 'image'. Add or remove for custom capabilities in the rich text field box (set result)
// link to others: https://github.com/jpuri/react-draft-wysiwyg/blob/f59ee8419cdbd45aab3bdfdf1995f112b09bbb6a/docs/src/components/Docs/Props/CustomizingToolbarProp/defaultToolbar.js
const RichTextFieldOptions = ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign',
    'colorPicker', 'link', 'embedded', 'emoji', 'remove']


export default function SampleSetInfoFields({
    sampleSet = undefined,
    invalid = undefined,
    updateSampleSet = undefined,
    accountName = undefined
}) {
    const classes = useStyles();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [hiddenToolbar, setHiddenToolbar] = useState(false);


    useEffect(() => {
        if (sampleSet.resultSummary === undefined) {
            const editorValue = EditorState.createEmpty();
            setEditorState(editorValue);
        }
        else {
            const convertedState = convertFromRaw(JSON.parse(sampleSet.resultSummary));
            const editorValue = EditorState.createWithContent(convertedState);
            setEditorState(editorValue);
        }


    }, [sampleSet.resultSummary]
    )


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <BufferTextField
                    variant="outlined"
                    size="small"
                    required
                    defaultValue={sampleSet.title || ""}
                    error={!!invalid?.title?.[value]}
                    helperText={invalid?.title?.[value]}
                    onBlur={ev => { updateSampleSet({ title: ev.target.value }, "Title Edit") }}
                    fullWidth
                    label="Set Name"
                    placeholder="Enter Set Name" />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TagSelector
                    value={sampleSet.tags || []}
                    onChange={(ev, newVal) => updateSampleSet({ tags: newVal }, "Tags Edit")}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabSelector
                    variant="outlined"
                    required
                    error={!!invalid?.labs?.[value]}
                    helperText={invalid?.labs?.[value]}
                    selected={sampleSet.labs || []}
                    onSelect={labs => { updateSampleSet({ labs }, "Labs Edit") }}
                    Constructor={LabToSampleSet}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <UserSelector
                    variant="outlined"
                    label={"Contributors"}
                    fullWidth
                    value={sampleSet.contributors || (accountName && [accountName]) || []}
                    disabled
                    onChange={(ev, values) => updateSampleSet({ contributors: values }, "Contributors Edit")} />
            </Grid>
            <Grid item xs={12}>
                <BufferTextField
                    variant="outlined"
                    size="small"
                    defaultValue={sampleSet.intent || ""}
                    onBlur={ev => updateSampleSet({ intent: ev.target.value }, "Intent Edit")}
                    fullWidth
                    label="Set Intent"
                    placeholder="Enter the intent of this sample set."
                    multiline />
            </Grid>
            <Grid item xs={12}>
                <BufferTextField
                    variant="outlined"
                    size="small"
                    defaultValue={sampleSet.description || ""}
                    onBlur={ev => updateSampleSet({ description: ev.target.value }, "Description Edit")}
                    fullWidth
                    label="Set Description"
                    placeholder="Enter any notes that cannot be captured through other fields."
                    multiline />
            </Grid>
            <Grid item xs={12}>
                <Typography className={hiddenToolbar ? classes.focused : classes.title} variant="caption">Set Result Summary</Typography>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={input => {
                        setEditorState(input)
                    }}
                    wrapperClassName={classes.wrapperClass}
                    editorClassName={classes.editorClass}
                    toolbarClassName={hiddenToolbar ? classes.toolbarClass : classes.hideToolbarClass}
                    onFocus={() => { setHiddenToolbar(true) }}
                    placeholder="Set Result Summary"
                    spellCheck
                    toolbar={{
                        options: RichTextFieldOptions,
                        fontFamily: { className: classes.dropdownClass, dropdownClassName: classes.dropdownClass },
                        fontSize: { className: classes.dropdownClass, dropdownClassName: classes.dropdownClass },
                        blockType: { className: classes.dropdownClass, dropdownClassName: classes.dropdownClass }
                    }}
                    onBlur={ev => {
                        updateSampleSet({ resultSummary: JSON.stringify(convertToRaw(editorState.getCurrentContent())) }, "Result Summary Edit");
                        setHiddenToolbar(false);
                    }}
                />
            </Grid>
        </Grid>
    )
}
