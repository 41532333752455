import {
    xTypeMenu, yTypeMenu, linearButton, logButton,
    xPlotMenu, yPlotMenu, plotSizeTopLevel, modeMenu,
    modeButton, UpdateUpdatemenus
} from './MenuBuilder';

export function layoutButton(layoutItem) {
    // Creates layout dropdown menus for rendering, primarily through organizing all 'updatemenus'
    // content so that it seamlessly works with each rendered plot.
    let updatedLayout = layoutItem;
    if (layoutItem === undefined) {
        return updatedLayout;
    }

    for (let i = 0; i < updatedLayout.updatemenus.length; i++) {
        if (updatedLayout.updatemenus[i].name !== undefined) {
            if (updatedLayout.updatemenus[i].name === 'x') {
                Object.keys(xPlotMenu).forEach((key) => {
                    updatedLayout.updatemenus[i][key] = xPlotMenu[key];
                })
            }
            if (updatedLayout.updatemenus[i].name === 'y') {
                Object.keys(yPlotMenu).forEach((key) => {
                    updatedLayout.updatemenus[i][key] = yPlotMenu[key];
                })
            }
        }
    }

    let xMenu = xTypeMenu;
    xMenu['buttons'] = [linearButton('x'), logButton('x')];

    let yMenu = yTypeMenu;
    yMenu['buttons'] = [linearButton('y'), logButton('y')];

    let mMenu = modeMenu;
    mMenu['buttons'] = modeButton();

    if (updatedLayout.xaxis.type === 'log') {
        xMenu['buttons'] = [logButton('x'), linearButton('x')];
    }

    if (updatedLayout.yaxis.type === 'log') {
        yMenu['buttons'] = [logButton('y'), linearButton('y')];
    }

    updatedLayout['updatemenus'] = [xMenu, yMenu, mMenu, ...updatedLayout['updatemenus']];
    let topLevel = plotSizeTopLevel;
    updatedLayout['width'] = topLevel['width'];
    updatedLayout['height'] = topLevel['height'];
    return updatedLayout;
}


export function CorrectLayout(curLayout, stateLayout) {
    // Inserts each plot of data correctly in each dropdown menu for multiplotting. By 'correctly',
    // this includes by processor or other type (i.e. image).
    // Both layout parameters are objects/dictionaries

    let newLayout, buttonLayoutInfo;

    if (curLayout === undefined) {
        // In some cases, new layout for this [resourced] plot may not exist if they are not of
        // the same numbers, so we just want to skip this step if so.
        return stateLayout;
    }

    if (Object.keys(stateLayout).length === 0) {
        newLayout = curLayout;
        buttonLayoutInfo = GetMaxLayoutIndices(newLayout);
    } else {
        newLayout = stateLayout;
        buttonLayoutInfo = GetMaxLayoutIndices(newLayout);
        newLayout = UpdateUpdatemenus(newLayout, curLayout, buttonLayoutInfo);
    }

    return newLayout;
}


function GetMaxLayoutIndices(layoutObj) {
    // Determine max indices of all buttons for stitching them together.
    var j;
    var loops = 0;
    var lengths = [];
    let numMenus = layoutObj['updatemenus'].length;

    for (j = 0; j < numMenus; j++) {
        if (layoutObj['updatemenus'][j]['buttons'].length > loops) {
            if (!CheckForPlotTypeButton(layoutObj['updatemenus'][j]['buttons'])) {
                loops = layoutObj['updatemenus'][j]['buttons'].length
            }
        }
    }

    for (j = 0; j < numMenus; j++) {
        // "j > 1" is a bit of a hack... but also to skip over the linear/log buttons
        if (j > 1) {
            if (layoutObj['updatemenus'][j]['buttons'].length === loops) {
                lengths = [...lengths, j]
            }
        }
    }
    return [lengths, loops];
}


function CheckForPlotTypeButton(buttonObj) {
    if (buttonObj[0]['member'] === 'ptype') {
        return true;
    }
    return false;
}

// export function MapPlotsToCorrectPlot(newSet, accumulatedSet, keyIndex, accumulatedKeySet, status) {
export function MapPlotsToCorrectPlot(state) {
    // Map multi-resources to correct multiplot sets. Note, if there is only one resource per test,
    // the "key" will be 'base'. End result will be "newAccumulatedSet", which is a array of arrays of
    // plot objects: [[{}], [{}], ...]. Each sub-array is appended to through addressing through
    // key names (mapping to the correct resource plot).
    let newSet = state.tempPlotData;
    let accumulatedSet = state.plotData;
    let keyIndex = state.tempKeyIndex;
    let accumulatedKeySet = state.accumulatedKeySet;
    let status = state.plotStatus;

    if (accumulatedSet.length === 0) {
        let returnContent = [newSet, keyIndex]
        return returnContent;
    }

    if (status === 'key-map') {
        Object.entries(keyIndex).forEach(([key, value]) => {
            if (!accumulatedKeySet.hasOwnProperty(key)) {
                if (!accumulatedKeySet.hasOwnProperty(value.toString())) {
                    accumulatedKeySet[key] = value;
                    accumulatedKeySet[value.toString()] = key;
                } else {
                    // Case where an index is already reserved.
                    accumulatedKeySet[key] = accumulatedSet.length;
                    accumulatedKeySet[accumulatedSet.length.toString()] = key;
                }
            }
        })
    }

    let newAccumulatedSet = accumulatedSet.map(content => []);
    if (accumulatedSet.length < newSet.length) {
        newAccumulatedSet = newSet.map(content => []);
    }


    newSet.forEach((plot, i) => {
        let accumulatedIndex = accumulatedKeySet[plot.name];
        if (plot.plot_title !== undefined) {
            accumulatedIndex = accumulatedKeySet[plot.plot_title];
        }
        let tempSet;

        if (status === 'key-map') {
            if (accumulatedSet[accumulatedIndex] === undefined) {
                // newSet now has something that accumulatedSet does not have
                tempSet = [];
            } else if (accumulatedSet[accumulatedIndex].length === undefined) {
                // Handle first pass case
                tempSet = [accumulatedSet[accumulatedIndex]];
            } else {
                tempSet = accumulatedSet[accumulatedIndex];
            }
            tempSet = [...tempSet, plot];
            newAccumulatedSet[accumulatedIndex] = tempSet;
        }

        else {
            // "array-map" or traditional case
            if (accumulatedSet[i] === undefined) {
                // newSet now has something that accumulatedSet does not have
                tempSet = [];
            } else if (accumulatedSet[i].length === undefined) {
                // Handle first pass case
                tempSet = [accumulatedSet[i]];
            } else {
                tempSet = accumulatedSet[i];
            }
            tempSet = [...tempSet, plot];
            newAccumulatedSet[i] = tempSet;
        }
    })

    if (status === 'key-map') {
        // Check if accumulated set is bigger than newSet, accommodate if so
        Object.entries(accumulatedKeySet).forEach(([key, value]) => {
            if (!keyIndex.hasOwnProperty(key)) {
                if (isNaN(Number(key)) && !isNaN(Number(value))) {
                    newAccumulatedSet[value] = accumulatedSet[value];
                }
            }
        })
    }

    let returnContent = [newAccumulatedSet, accumulatedKeySet];
    return returnContent;
}


export function MapLayoutToCorrectLayout(state) {
    // Map multi-resources to correct layout sets. Note, if there is only one resource per test,
    // the "key" will be 'base'. End result will be "newAccumulatedSet", which is a array of layout
    // objects: [{}, {}, ...]. Each sub-object is stitched together with mapped buttons from updatemenus
    // addressing through key names (mapping to the correct resource layout).
    let newSet = state.tempPlotLayout;
    let accumulatedSet = state.plotLayout;
    let keyIndex = state.tempKeyLayoutIndex;
    let accumulatedKeySet = state.accumlatedLayoutKeySet;
    let status = state.plotStatus;

    if ((status === 'key-map') || (Object.entries(accumulatedKeySet).length === 0)) {
        Object.entries(keyIndex).forEach(([key, value]) => {
            if (!accumulatedKeySet.hasOwnProperty(key)) {
                if (!accumulatedKeySet.hasOwnProperty(value.toString())) {
                    accumulatedKeySet[key] = value;
                    accumulatedKeySet[value.toString()] = key;
                } else {
                    // Case where an index is already reserved.
                    accumulatedKeySet[key] = accumulatedSet.length;
                    accumulatedKeySet[accumulatedSet.length.toString()] = key;
                }
            }
        })
    }

    let newAccumulatedSet = accumulatedSet.map(content => []);
    let mappedKeys = [];

    newSet.forEach((layout, i) => {
        let accumulatedIndex = accumulatedKeySet[layout.title['text']];
        mappedKeys = [...mappedKeys, accumulatedIndex];
        let tempSet;

        if (status === 'key-map') {
            if (accumulatedSet.length === 0) {
                tempSet = {}
            } else if (accumulatedSet[accumulatedIndex] === undefined) {
                // newSet now has something that accumulatedSet does not have
                tempSet = {};
            } else if (accumulatedSet[accumulatedIndex].length === undefined) {
                // Handle first pass case
                tempSet = accumulatedSet[accumulatedIndex];
            } else {
                tempSet = accumulatedSet[accumulatedIndex];
            }
        }

        else {
            if (accumulatedSet.length === 0) {
                tempSet = {}
            } else if (accumulatedSet[i] === undefined) {
                // newSet now has something that accumulatedSet does not have
                tempSet = {};
            } else if (accumulatedSet[i].length === undefined) {
                // Handle first pass case
                tempSet = accumulatedSet[i];
            } else {
                tempSet = accumulatedSet[i];
            }
            accumulatedIndex = i;
        }

        let buttonedLayout = layoutButton(layout);
        let cLayout = CorrectLayout(buttonedLayout, tempSet);
        newAccumulatedSet[accumulatedIndex] = cLayout;
    })

    if (status === 'key-map') {
        Object.entries(accumulatedKeySet).forEach(([key, value]) => {
            if (!keyIndex.hasOwnProperty(key)) {
                if (isNaN(Number(key)) && !isNaN(Number(value))) {
                    newAccumulatedSet[value] = accumulatedSet[value];
                }
            }
        })
    }

    let returnContent = [newAccumulatedSet, accumulatedKeySet];
    return returnContent
}


export function PlotsToLists(plotObjects) {
    // Samples with single resources need to have each plot as a list.
    let newPlotList = [];
    plotObjects.forEach(plot => {
        if (plot.length === undefined) {
            newPlotList = [...newPlotList, [plot]];
        } else {
            newPlotList = [...newPlotList, plot];
        }
    })
    return newPlotList
}