import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: 5,
    width: 400
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-sweeppts-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function SweepPointsDialog({ title, type, initialSweepPoints, onSuccess, open, setOpen }) {
  const classes = useStyles()
  const [sweepPoints, setSweepPoints] = useState("")

  useEffect(() => {
    setSweepPoints(initialSweepPoints?.join("\n"))
  }, [initialSweepPoints])

  const handleChange = (ev) => {
    setSweepPoints(ev.target.value)
  }

  const handleCancel = () => {
    setOpen(false)
  }
  const handleOk = () => {
    let result = sweepPoints.split("\n")
    if (type === "number") {
      result = result.map(val => parseFloat(val))
    }
    onSuccess(result)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-sweeppts-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-sweeppts-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom style={{ textAlign: "left" }}>
          Define the points to sample the primary X variable over.
        </Typography>
        <TextField
          id={"sweeppts"}
          data-cy={"sweeppts"}
          label={"Add each point on a new line"}
          multiline
          maxRows={6}
          InputLabelProps={{ shrink: true }}
          margin="normal"
          value={sweepPoints}
          onChange={handleChange}
          className={classes.textField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}