import React from 'react';
import Spinner from 'react-spinkit';
import Typography from '@material-ui/core/Typography';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    loadingText: {
        color: "white",
        marginBottom: theme.spacing(1),
    },
    loadingContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
    },
    filled: {
        background: theme.palette.type === "dark" ? "#FFFFFF30" : "#00000060",
        zIndex: "999",
    }
}));


const BWLinearProgress = withStyles((theme) => ({
    root: {
        border: "solid 1px white",
        borderRadius: theme.spacing(0.5),
        padding: "2px",
        maxWidth: "500px",
        width: "100%",
        alignSelf: "center",
        marginTop: theme.spacing(2)
    },
    colorPrimary: {
        backgroundColor: "transparent",
    },
    bar: { 
        backgroundColor: "white",
    }
}))(LinearProgress)

/**
 * Full view overlay with a spinner
 * @param {*} props
 * @param {string} props.loadingText text to display over spinner, default "Opening"
 * @param {boolean | undefined} props.filled if true, the background will be covered with a semi transparent overlay
 * @param {number | undefined} props.progress optional 0-100 progress for the spinner 
 */
export default function LoadingOverlay({loadingText = "Opening", filled=undefined, progress=undefined}) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.loadingContainer, filled && classes.filled)}>
            <Typography className={classes.loadingText} variant="h4">{loadingText}</Typography>
            <Spinner name='line-scale-pulse-out' color='white' fadeIn='none' />
            {progress !== undefined && <BWLinearProgress variant="determinate" value={progress}/>}
        </div>
    );
}