
import Handsontable from "handsontable"

export function buildDeleteRenderer(onDelete: ((ev: MouseEvent, row: any, col: any, prop: any, value: any) => any) | null = null) {
    return function (this: any, instance: any, td: HTMLTableDataCellElement, row: any, col: any, prop: any, value: any, cellProperties: any ) {
        Handsontable.renderers.TextRenderer.apply(this, arguments as any)
        if (value){
            const span = document.createElement("span")
            span.textContent = "x"
            span.className = "htDelete"
            span.title = "delete"
            span.onclick = onDelete ? (ev) => {
                const t = cellProperties.transpose
                onDelete(ev, t ? col : row, t ? row : col, prop, value)
            } : null
            td.appendChild(span)
        }
        return td
    }
}