import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from 'mdi-material-ui/Export';

export function addOpenColumn({ allColumns }) {
    allColumns.push(columns => [
        {
            id: "edit",
            Header: () => <span>{'\u00A0'}</span>,
            Cell: ({ row, onEdit }) => <Tooltip title="Open" placement="bottom">
                <IconButton 
                    size="small"
                    onClick={(ev) => {
                        ev.stopPropagation() 
                        onEdit && onEdit(row)
                    }}
                >
                    <EditIcon color={"primary"} fontSize="small" />
                </IconButton>
            </Tooltip>,
            disableResizing: true,
            disableSortBy: true,
            disableFilters: true,
            width: 32,
            padding: "none",
            headerPadding: "none",
            filterPadding: "none",
            cellProps: { style: { justifyContent: "flex-end", flexGrow: 0 } }
        },
        ...columns
    ]);
}
