import React, { createContext } from 'react';
import theme, { darkTheme } from './styles/themes';
import { ThemeProvider } from '@material-ui/core/styles';
import { useLocalForageState } from 'utils/useLocalforageState';
import { useMediaQuery } from '@material-ui/core';


export const DarkModeContext = createContext();

export default function MMThemeProvider({children}){

    const [darkMode, setDarkMode, isLoading] = useLocalForageState("DarkMode", useMediaQuery('(prefers-color-scheme: dark)'));

    return (
        <DarkModeContext.Provider value={setDarkMode}>
            {!isLoading && 
            <ThemeProvider theme={darkMode ? darkTheme : theme}>
                {children}
            </ThemeProvider>
            }
        </DarkModeContext.Provider>
    )
}