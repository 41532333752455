import { v4 as uuidv4 } from "uuid";

//#region constants
const MODEL_ID = "f7eec5fc-83ec-4203-b3a2-d29f7fcb3139"
const JOB_TYPE = 'single'
const OPERATION = 'MidPoint'
const TOTAL_RUNS = 1
const MODEL_NAME = 'polyBrew-MM'
const NON_EXP_NODE_MAP = {
  structure_design: "polyBrew - structure design",
  crosslinker_information: "polyBrew - crosslinker information",
}
//#endregion

//#region JSON templates
const INIT_PAYLOAD = {
  job_details: {header: "JOB DETAILS", items: []},
  monomer_composition: {header: "MONOMER COMPOSITION", items: []},
  polymer_structure_design: {header: "POLYMER STRUCTURE DESIGN", items: []},
  crosslinker_information: {header: "CROSSLINKER INFORMATION", items: []},
  virtual_experiment_flow: {header: "VIRTUAL EXPERIMENT FLOW", items: []},
  materials_manager_upload: {header: "MATERIALS MANAGER UPLOAD", items: []},
  meta_info: {
    description: "",
    jobName: "",
    modelId: MODEL_ID,
    jobType: JOB_TYPE,
    Type: JOB_TYPE,
    Operation: OPERATION,
    TotalRuns: TOTAL_RUNS,
    modelName: MODEL_NAME
  }
}

const initPayload_job_details_name = {
  "labelName": "Job Name",
  "name": "jobname",
  "contentType": "text",
  "value": "MM test1 - Z",
  "unit": null
}

const initPayload_job_details_description = {
  "labelName": "Job Description",
  "name": "jobdescription",
  "contentType": "text",
  "value": "test MM upload ",
  "unit": null
}

const MonomerComposition = (monomers, compositions) => {
  return {
    "labelName": "Monomer selection",
    "name": "monoSelect",
    "contentType": "layers",
    "value": monomers.map((monomer, index) => {
      return MonomerComposition_value(monomer, compositions[index])
    }),
    "unit": null
  }
}


const MonomerComposition_value = (monomer, composition) => {
  return {
    "fieldValues": [
      {
        "value": composition,
        "labelName": "Composition",
        "name": "comp",
        "unit": "percent",
        "contentType": "real"
      }
    ],
    "isRangeChecked": false,
    "layerIndex": 1,
    "layerId": {
      "value": uuidv4()
    },
    "layerType": monomer,
    "layerLabel": ""
  }
}

const PolymerStructureDesignOrder = [
  "Polymer type",
  "Number of chains",
  "Poly-dispersity type",
  "Chain length",
]


const PolymerStructureDesign = (ptype, nChains, pDisType, lChain) => {
  return [
      {
        "labelName": "Polymer type",
        "name": "polymer_type",
        "contentType": "select",
        "value": ptype,
        "unit": null
      },
      {
        "labelName": "Number of chains",
        "name": "number_chains",
        "contentType": "integer",
        "value": nChains,
        "unit": null
      },
      {
        "labelName": "Poly-dispersity type",
        "name": "dispersity_type",
        "contentType": "select",
        "value": pDisType,
        "unit": null
      },
      {
        "labelName": "Chain length",
        "name": "length",
        "contentType": "integer",
        "value": lChain,
        "unit": null
      }
    ]
}


const CrosslinkerInfoOrder = ['Monomer', 'Number of crosslinker molecules', 'Crosslinker']


const CrosslinkerInfo = (isOn, crosslinker_values) => {
  // isOn: Yes or No
  //, monomers, nMonomers, crosslinkers

  // convert to array of objects
  let monomers = []
  let nMonomers = []
  let crosslinkers = []

  if (isOn) {

    crosslinker_values.forEach((value) => {
      monomers.push(value[0])
      nMonomers.push(value[1])
      crosslinkers.push(value[2])
    } )
  } else {
    return [{
      "labelName": "Crosslinking reaction swith",
      "name": "crosslinking_flag",
      "contentType": "select",
      "value": 'No',
      "unit": null
    }]
  }
  return [
    {
      "labelName": "Crosslinking reaction swith",
      "name": "crosslinking_flag",
      "contentType": "select",
      "value": 'Yes',
      "unit": null
    },
    {
      "labelName": "Crosslinker selection",
      "name": "crosslinking_name",
      "contentType": "layers",
      "value": crosslinkers.map((crosslinker, index) => {
        return CrosslinkerInfo_value(monomers[index], nMonomers[index], crosslinker, index+1)
      }),
      "unit": null
    }
  ]
}


const CrosslinkerInfo_value = (monomer, nMonomer, crosslinker, idx) => {
  return {
    "fieldValues": [
      {
        "value": monomer,
        "labelName": "Monomer to react with this crosslinker",
        "name": "reactive_monomer",
        "unit": null,
        "contentType": "select"
      },
      {
        "value": nMonomer,
        "labelName": "Number of crosslinker molecules",
        "name": "crosslinker_number",
        "unit": null,
        "contentType": "integer"
      }
    ],
    "isRangeChecked": false,
    "layerIndex": idx,
    "layerId": {
      "value": uuidv4()
    },
    "layerType": crosslinker,
    "layerLabel": ""
  }
}


const VirtualExperimentFlow = (allCols, allValues, allNames) => {
  return [
    {
      "labelName": "Virtual experiment flow",
      "name": "experiment_list",
      "contentType": "layers",
      "value": allCols.map((cols, index) => {
        return VirtualExperimentFlow_value(index + 1, allNames[index], cols, allValues[index])
      }),
      "unit": null
    }
  ]
}


const VIRTUAL_EXPERIMENT_DATA = {
  "crosslinking_reaction": [
    {
      "value": 300,
      "labelName": "Temperature",
      "name": "Temp",
      "unit": "K",
      "contentType": "real"
    },
    {
      "value": 20,
      "labelName": "Desired Conversion",
      "name": "Conversion",
      "unit": "percent",
      "contentType": "real"
    }
  ],
  "uniaxial_extension" : [
    {
      "value": 300,
      "labelName": "Temperature",
      "name": "Temp",
      "unit": "K",
      "contentType": "real"
    },
    {
      "value": 100000000,
      "labelName": "Strain rate",
      "name": "strain_rate",
      "unit": "strain/s",
      "contentType": "real"
    },
    {
      "value": 1,
      "labelName": "Final strain",
      "name": "final_strain",
      "unit": null,
      "contentType": "real"
    }
  ],
  "glass_transition": [
    {
      "value": 300,
      "labelName": "Glass transition guess",
      "name": "Tg_guess",
      "unit": "K",
      "contentType": "real"
    },
    {
      "value": 100,
      "labelName": "Temperature spread",
      "name": "T_spread",
      "unit": "K",
      "contentType": "real"
    },
    {
      "value": 20,
      "labelName": "Temperature delta",
      "name": "delta_T",
      "unit": "K",
      "contentType": "real"
    }
  ],
  "bulk_pluck": [
    {
      "value": 300,
      "labelName": "Temperature",
      "name": "Temp",
      "unit": "K",
      "contentType": "real"
    },
    {
      "value": 100000000,
      "labelName": "Strain rate",
      "name": "strain_rate",
      "unit": "strain/s",
      "contentType": "real"
    },
    {
      "value": 1,
      "labelName": "Final strain",
      "name": "final_strain",
      "unit": null,
      "contentType": "real"
    }
  ],
  "simple_shear": [
    {
      "value": 300,
      "labelName": "Temperature",
      "name": "Temp",
      "unit": "K",
      "contentType": "real"
    },
    {
      "value": 100000000,
      "labelName": "Strain rate",
      "name": "strain_rate",
      "unit": "strain/s",
      "contentType": "real"
    },
    {
      "value": 1,
      "labelName": "Final strain",
      "name": "final_strain",
      "unit": null,
      "contentType": "real"
    }
  ],
  "DMA_mastercurve": [
    {
      "value": 300,
      "labelName": "Estimated Glass Transition Temperature",
      "name": "Tg_estimate",
      "unit": "K",
      "contentType": "real"
    },
    {
      "value": 5,
      "labelName": "Number of temperatures to sweep",
      "name": "Num_temp",
      "unit": null,
      "contentType": "integer"
    }
  ],
  "impact_resistance": [
    {
      "value": 1000000000,
      "labelName": "Max strain rate",
      "name": "strainRate_max",
      "unit": "strain/s",
      "contentType": "real"
    },
    {
      "value": 10000000,
      "labelName": "Min strain rate",
      "name": "strainRate_min",
      "unit": "strain/s",
      "contentType": "real"
    },
    {
      "value": 5,
      "labelName": "Number of strain rates to sweep",
      "name": "num_strainRate",
      "unit": null,
      "contentType": "integer"
    },
    {
      "value": 300,
      "labelName": "Temperature",
      "name": "temp",
      "unit": "K",
      "contentType": "real"
    }
  ],

}

const MMUpload = (qid) => {
  return [
    {
      "labelName": "Upload results to Materials Manager",
      "name": "upload_materials_manager",
      "contentType": "select",
      "value": "Yes",
      "unit": null
    },
    {
      "labelName": "QID of materials",
      "name": "qid",
      "contentType": "text",
      "value": qid,
      "unit": null
    }
  ]
}
//#endregion

//#region utility functions
const getVirtualExperimentData = (experimentType, attr) => {
  let result = ''
  const data = VIRTUAL_EXPERIMENT_DATA[experimentType]
  data.some(item => {
    if(item.labelName === attr) {
      result = item
      return true // break out of loop
    }
    return false
  })
  return result
}

const VirtualExperimentFlow_value = (idx, name, cols, values) => {

  return {
    "fieldValues": cols.map((col, index) => {
      const virtualExperimentData = getVirtualExperimentData(name, col)
      return {
        "value": values[index],
        "labelName": col,
        "name": virtualExperimentData.name,
        "unit": virtualExperimentData.unit,
        "contentType": virtualExperimentData.contentType
      }
    }),
    "isRangeChecked": false,
    "layerIndex": idx,
    "layerId": {
      "value": uuidv4()
    },
    "layerType": name,
    "layerLabel": ""
  }
}


const getProcessNodeIdByName = ( allNodes, nodeName ) => {
  let nodeId = []
  Object.keys(allNodes).forEach((node_id) => {
    if (allNodes[node_id].type === 'process') {
      const node = allNodes[node_id];
      const processDefId = Object.keys(node.processDefs)[0];
      if (node.processDefs[processDefId].title === nodeName) {
        nodeId.push(node_id)
      }
    }
  })

  if (nodeId.length === 0) return '';
  if (nodeId.length === 1) return nodeId[0];
  return nodeId
}

const getNodeIDsLinkedToNode = ( allNodes, nodeName ) => {
  let nodes = []
  const nodeId = getProcessNodeIdByName(allNodes, nodeName)
  if (nodeId === '') {
    console.error(`No node found with name ${nodeName}`)
    return nodes
  } else if ( typeof(nodeId) !== 'string' ) {
    console.error(`Multiple nodes found with name ${nodeName}`)
    return nodes
  }

  Object.keys(allNodes).forEach((node_id) => {
    const node = allNodes[node_id];
    if (node.to.indexOf(nodeId) > -1) {
      nodes.push(node_id)
    }
  })

  return nodes
}

const getPartentNodesByID = ( allNodes, nodeId ) => {
  let nodes = []
  Object.keys(allNodes).forEach((nodeID) => {
    const node = allNodes[nodeID];
    if (node.to.indexOf(nodeId) > -1) {
      nodes.push(node)
    }
  })
  return nodes
}

const findMaterialsLinkedToNode = ( allNodes, nodeName ) => {
  let materials = []
  const materialNodeIDs = getNodeIDsLinkedToNode(allNodes, nodeName)
  materialNodeIDs.forEach((nodeID) => {
    const node = allNodes[nodeID]
    if (node.type === 'material') {
      Object.keys(node.materialDefs).forEach((materialID) => {
        materials.push(node.materialDefs[materialID].title)
      })
    }
  })
  return materials
}

const findMaterialsLinkedToNodeByID = ( allNodes, nodeID ) => {
  let materials = []
  const ParentNodes = getPartentNodesByID(allNodes, nodeID)
  ParentNodes.forEach((node) => {
    if (node.type === 'material') {
      Object.keys(node.materialDefs).forEach((materialID) => {
        materials.push(node.materialDefs[materialID].title)
      })
    }
  })
  return materials
}

const findMaterialLabelsLinkedToNodeByID = ( allNodes, nodeID ) => {
  let materials = []
  const ParentNodes = getPartentNodesByID(allNodes, nodeID)
  ParentNodes.forEach((node) => {
    if (node.type === 'material') {
      Object.keys(node.materialDefs).forEach(() => {
        materials.push(node.label)
      })
    }
  })
  return materials
}

const getValueFromData = (allData, colName, row, label='') => {
  let value = ''
  const dataName = Object.keys(allData)[0]
  const data = allData[dataName]
  Object.keys(data).forEach((col) => {
    if(label === '' && col.includes(colName)) {
      value = data[col][row]
      return value
    } else if (label !== '' && col.includes(colName) && col.includes(label)) {
      value = data[col][row]
      return value
    }

  })
  return value
}

const getColsFromNodeName = (nodeCols, allNodes, nodeName) => {
  let nodeID = getProcessNodeIdByName(allNodes, nodeName)
  // if nodeID is an array, get the first element
  if (Array.isArray(nodeID)) {
    nodeID = nodeID[0]
  }
  let cols = []
  nodeCols.forEach((col) => {
    if (col.nodeId === nodeID) {
      cols.push(col.colKey)
    }
  })
  return cols
}

const getParamsFromCols = (cols, values, paramName) => {
  // cols = list of parameter names
  // values = list of corresponding values
  // paramName = name of parameter to find
  // returns the value of the parameter
  let value
  cols.forEach((col, idx) => {
    if (col.includes(paramName)) {
      value = values[idx]
      return value
    }
  })
  return value
}

const getProcessNodeName = (node) => {
  if (node.type === 'process') {
    const processDefId = Object.keys(node.processDefs)[0];
    return node.processDefs[processDefId].title
  }
  return ''
}

const getExpFlowNodes = (allNodes) => {
  const allNodeNames = Object.keys(allNodes).map((nodeID) => {
    const node = allNodes[nodeID]
    return getProcessNodeName(node)
  })
  const mandatoryNodes = Object.values(NON_EXP_NODE_MAP)
  return allNodeNames.filter((nodeName) => {
    return !mandatoryNodes.includes(nodeName) && nodeName !== ''
  })

}

const generatePayload = (data) => {
  let payload = {"inputForMaaS": []}
  Object.keys(data).forEach((key) => {
    if (key !== 'meta_info') {
      payload["inputForMaaS"].push(data[key])
    }
  })
  payload = {...payload, ...data.meta_info}
  return payload
}

//#endregion

export function IsReadyForPolyBrew(sampleSet) {
  if(sampleSet && sampleSet.processChart && sampleSet.processChart.nodes) {
    const nodes = sampleSet.processChart.nodes
    let isReady = false
    Object.keys(nodes).some((nodeID) => {
      const node = nodes[nodeID]
      if (node.type === 'process' && node.processDefs && Object.keys(node.processDefs).length) {
        const processDefId = Object.keys(node.processDefs)[0];
        if (node.processDefs[processDefId].title === 'polyBrew - structure design') {
          isReady = true
          return true // break out of loop
        }
      }
      return false // continue loop
    })
    return isReady
  } else {
    return false
  }

}

export default function PolybrewPayload(sampleSet, allData) {
  let payloads = []
  const nodes = sampleSet.processChart.nodes
  const nodeCols = sampleSet.processChart.columns

  sampleSet.samples.forEach((sample, row) => {
    let payload = JSON.parse(JSON.stringify(INIT_PAYLOAD))  // deep copy

    // meta info
    const description = sampleSet.description ? sampleSet.description : ''
    const qid = sample.qid
    const jobName = sample.title.slice(0, 19 - String(row+1)) + '_' + String(row+1)
    payload.meta_info.description = description
    payload.meta_info.jobName = jobName
    payload.job_details.items.push({ ...initPayload_job_details_name, value: jobName })
    payload.job_details.items.push({ ...initPayload_job_details_description, value: description })

    // monomer composition
    const monomers = findMaterialsLinkedToNode(nodes, NON_EXP_NODE_MAP.structure_design)
    const compositions = monomers.map(monomer => {
      return getValueFromData(allData, monomer, row)
    })
    payload.monomer_composition.items.push(MonomerComposition(monomers, compositions))

    // polymer structure design
    const cols = getColsFromNodeName(nodeCols, nodes, NON_EXP_NODE_MAP.structure_design)
    const values = cols.map(col => {
      return getValueFromData(allData, col, row)
    })
    const valueOrdered = PolymerStructureDesignOrder.map(param => {
      return getParamsFromCols(cols, values, param)
    })

    payload.polymer_structure_design.items.push(...PolymerStructureDesign(...valueOrdered))

    // crosslinker info, optional
    let isOn
    let crosslinker_nodes = getProcessNodeIdByName(nodes, NON_EXP_NODE_MAP.crosslinker_information)
    if (crosslinker_nodes !== '') {
      isOn = true
      if (typeof(crosslinker_nodes) === 'string') {
        crosslinker_nodes = [crosslinker_nodes]
      }
      const crosslinkers = crosslinker_nodes.map((node) => {
        return findMaterialsLinkedToNodeByID(nodes, node)[0]
      })
      const crosslinker_labels = crosslinker_nodes.map((node) => {
        return findMaterialLabelsLinkedToNodeByID(nodes, node)[0]
      })
      let crosslinker_values = []
      crosslinkers.forEach((crosslinker, idx) => {
        const cols = getColsFromNodeName(nodeCols, nodes, NON_EXP_NODE_MAP.crosslinker_information)
        const values = cols.map(col => {
          return getValueFromData(allData, col, row)
        })
        const valueOrdered = CrosslinkerInfoOrder.map(param => {
          if (param === 'Crosslinker') {
            return crosslinker
          }
          else {
            return getParamsFromCols(cols, values, param, crosslinker_labels[idx])
          }
        })
        crosslinker_values.push(valueOrdered)
      })
      payload.crosslinker_information.items.push(...CrosslinkerInfo(isOn, crosslinker_values))
    } else {
      isOn = false
      payload.crosslinker_information.items.push(...CrosslinkerInfo(isOn, []))
    }

    // experiment flow
    const expFlowNodes = getExpFlowNodes(nodes)
    let allCols = []
    let allValues = []
    let allNames = []
    expFlowNodes.forEach((step) => {
      const cols = getColsFromNodeName(nodeCols, nodes, step)
      const values = cols.map(col => {
        return getValueFromData(allData, col, row)
      })
      allCols.push(cols)
      allValues.push(values)
      allNames.push(step.split('-')[1].trim())
    })
    payload.virtual_experiment_flow.items.push(...VirtualExperimentFlow(allCols, allValues, allNames))

    // MM upload
    payload.materials_manager_upload.items.push(...MMUpload(qid))

    // convert to MH format
    const polybrewPayloadForMH = generatePayload(payload)
    payloads.push(polybrewPayloadForMH)
  })

  return payloads
}