import React, { useCallback, useContext } from 'react';

import { useAppStoreKey } from 'AppStore';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useAuth0 } from "@auth0/auth0-react";

import {
    AppBar, Badge, Toolbar, Typography, IconButton, Button, Tooltip, Switch,
    useTheme
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info'

import { copyTextToClipboard } from '../../utils/utils';
import { DarkModeContext } from '../../MMThemeProvider';

import MMIcon from '../../images/MMIcon';
import Logo from '../../images/Logo.png';
import LogoDark from '../../images/LogoDark.png';
import SkylabtheWhite from '../../images/white_text_enabled.svg';
import SkylabtheGrey from '../../images/black_text_enabled.svg';


const beTarget = (process.env.REACT_APP_MM_API_URL?.match(/https?:\/\/([0-9a-z-]+\.?[0-9a-z-]+)[.:]/i) || [])[1];

export default React.forwardRef(function MMMAppBar(
    { classes, open, iconColor, onDrawerOpen, switchPeriod, setShowMessage, showMessage }, ref) {
    const {
        logout,
        getAccessTokenSilently,
    } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();

    const [hasMessage] = useAppStoreKey("hasMessage")

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    const handleCopyJWT = useCallback(async () => {
        try {
            const auth0Token = await getAccessTokenSilently({ignoreCache: true});
            await copyTextToClipboard(auth0Token);
            enqueueSnackbar("JWT copied to clipboard", { variant: "success" });
        } catch (err) {
            if (err) {
                enqueueSnackbar("Failed to get JWT", { variant: "error" });
                console.error(err);
            }
        }
    }, [enqueueSnackbar, getAccessTokenSilently]);
    const setDarkMode = useContext(DarkModeContext);
    const theme = useTheme();
    
    return (
        <AppBar ref={ref}position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <MMIcon height={36} width={36} color={iconColor} switchPeriod={switchPeriod} />
                <IconButton
                    edge="start"
                    aria-label="Open drawer"
                    onClick={onDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                    <ChevronRightIcon />
                </IconButton>
                {process.env.REACT_APP_ENVIRON !== "pr" && 
                <Typography variant="h5" color="error" style={{ marginLeft: "16px" }}>
                    {`${process.env.REACT_APP_SITE_HEADER} to ${beTarget}`}
                </Typography>}
                <img src={theme.palette.type==="dark" ? SkylabtheWhite : SkylabtheGrey} alt="skylab" style={{ width: 100, marginLeft: "auto", marginTop: 10, marginBottom: -10 }} />
                <img src={theme.palette.type==="dark" ? LogoDark : Logo} alt="3M" style={{ marginRight: "auto" }} />
                <Tooltip title="Dark Mode">
                    <Switch checked={theme.palette.type === "dark"} onChange={(ev) => setDarkMode(ev.target.checked)}/>
                </Tooltip>
                <Tooltip title="Application Update Message">
                <IconButton
                    onClick={() => setShowMessage(true)}
                >
                    <Badge color="secondary" variant="dot" invisible={!hasMessage}>
                        <InfoIcon color={showMessage ? "primary" : "action"} />
                    </Badge>
                </IconButton>
                </Tooltip>
                <Tooltip title="Copy JWT">
                    <Button onClick={handleCopyJWT}>JWT</Button>
                </Tooltip>
                <Button color="secondary" onClick={logoutWithRedirect}>Logout</Button>
            </Toolbar>
        </AppBar>
    );
})