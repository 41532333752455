/**
 * @format
 */

import React from "react"
import { makeStyles } from "@material-ui/core"
import TagSelector from "../General/TagSelector"

const useStyles = makeStyles(theme => ({
    selectRoot: {
        "& .MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap",
        },
    },
}))

export default function AppendedSelector({
    Selector,
    appendOptions,
    ...props
}) {
    const classes = useStyles()

    return (
        <Selector
            className={classes.selectRoot}
            label=""
            fullWidth
            variant="standard"
            InputProps={{
                disableUnderline: true,
            }}
            appendOptions={appendOptions}
            groupBy={
                appendOptions &&
                (option =>
                    appendOptions?.includes(option)
                        ? "In Current Sample"
                        : "All")
            }
            {...props}
        />
    )
}

export function AppendedTagSelector({ value, ...props }) {
    const classes = useStyles()

    return (
        <TagSelector
            className={classes.selectRoot}
            label=""
            value={value}
            fullWidth
            variant="standard"
            placeholder={!value?.length ? "Tags Filter" : undefined}
            helperText={undefined}
            InputProps={{
                disableUnderline: true,
            }}
            {...props}
        />
    )
}
