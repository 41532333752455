import React, { useState, useContext, useEffect, useCallback } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import ShareUserMultiSelect from "./ShareUserMultiSelect";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { AccountContext } from '../../../context';
import { aichemyProtoAxios } from "API/mmAxios";
import { useAppStoreKey } from '../../../AppStore';
import common_styles from '../../../styles/common_styles';
import TableSpinner from '../../ReactTable/TableSpinner';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    ...common_styles(theme),
    root: {
        flexGrow: 1,
        // height: '100vh',
        minHeight: "95vh",
        margin: "auto",
        zIndex: 1,
        //overflow: 'hidden',
        position: "relative",
        display: "flex",
        width: "100%"
    },
    appBar: {
        position: "fixed",
        marginLeft: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        color: "white"
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            position: "fixed"
        },
        minHeight: "100vh"
    },
    content: {
        minHeight: `calc(100vh - 48px)`,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: "100%",
        marginLeft: "auto",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    loader: {
        marginLeft: "0px",
        [theme.breakpoints.up("md")]: {
            marginLeft: `calc(${drawerWidth}px)`
        }
    }
}));

function ShareModelDialog() {

    const classes = useStyles();
    const account = useContext(AccountContext);
    const [modelContext, setModelContext] = useAppStoreKey("ModelContext");
    const [modelDialogContext, setModelDialogContext] = useAppStoreKey("ModelDialogContext");
    const [workflow,] = useAppStoreKey("Workflow");
    const { enqueueSnackbar } = useSnackbar();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [modifiedSharedWith, setModifiedSharedWith] = useState([]);
    const [selectedShareUsers, setSelectedShareUsers] = useState([]);
    const [currentlySharedWith, setCurrentlySharedWith] = useState([]);
    const [selectedSharePermission, setSelectedSharePermission] = useState("read");
    const sharePermissions = [
        { display: "Read", value: "read" },
        { display: "Write", value: "write" },
        { display: "Owner", value: "owner" }
    ];

    // how we pass data back and forth with the search bar
    useEffect(() => {
        setSelectedShareUsers(modelContext.shareUsers ? modelContext.shareUsers : []);
    }, [modelContext.shareUsers])

    const setPermissions = useCallback(data => {
        let newShare = [];
        Object.keys(data).forEach(level => {
            data[level].forEach(user => {
                newShare.push({ upn: user.upn, displayName: user.user_name, permission: level, editable: user.user_name !== account.name })
            })
        })
        setCurrentlySharedWith(newShare);
        setModifiedSharedWith(newShare);
        setDeletedUsers([])
    }, [account]);


    const loadPermissions = useCallback(() => {
        setIsLoading(true);
        let url = `workflow/` + workflow.uuid + `/access`;
        let config = {
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }
        aichemyProtoAxios.get(url, config)
            .then(res => {
                let data = res.data;
                setPermissions(data)
            })
            .catch(err => {
                enqueueSnackbar("Failed to load model permissions", { variant: "error" });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [setPermissions, workflow, enqueueSnackbar])

    useEffect(() => {
        if (workflow?.uuid !== undefined && modelDialogContext?.shareOpen)
            loadPermissions();
    }, [workflow, modelDialogContext, loadPermissions]);

    let updatePermissions = () => {
        setIsSaving(true);
        let changes = false;
        let data = {
            update: {},
            remove: []
        }
        // deleting old users
        deletedUsers.forEach(user => {
            data.remove.push(user.upn);
            changes ||= true;
        })
        // adding new users
        selectedShareUsers.forEach(user => {
            let upn = user.userPrincipalName + "@mmm.com";
            data.update[upn] = { role: selectedSharePermission, user_name: user.displayName }
            changes ||= true;
        });

        // modifying existing users
        // TODO: could be smarter in searching but this is small
        modifiedSharedWith.forEach(mod => {
            currentlySharedWith.forEach(curr => {
                if (mod.upn === curr.upn) {
                    if (mod.permission !== curr.permission) {
                        data.update[mod.upn] = { role: mod.permission, user_name: mod.displayName };
                        changes ||= true;
                    }
                }
            })
        });
        // no changes, dont bother patching
        if (!changes) {
            setIsSaving(false);
            return;
        }

        let url = `workflow/` + workflow.uuid + `/access`;
        let config = {
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }

        aichemyProtoAxios.patch(url, JSON.stringify(data), config)
            .then(res => {
                let data = res.data;
                setPermissions(data);
                setModelContext({ ...modelContext, shareUsers: [] });
            })
            .catch(err => {
                enqueueSnackbar("Failed to update model permissions", { variant: "error" });
                setModifiedSharedWith(currentlySharedWith);
            })
            .finally(() => {
                setIsSaving(false);
            });
    }

    let selectedSharePermissionChange = event => {
        setSelectedSharePermission(event.target.value);
    };

    let deleteUser = (user, event) => {
        let displayName = user.displayName;
        setDeletedUsers(deletedUsers.concat(user))

        // REMOVE the user from the data
        let updatedShareUsers = JSON.parse(JSON.stringify(modifiedSharedWith));
        updatedShareUsers = updatedShareUsers.filter(el => {
            return el.displayName !== displayName
        })
        setModifiedSharedWith(updatedShareUsers);
    };

    let updateUserPermissionLevel = (user, event) => {
        let msw = JSON.parse(JSON.stringify(modifiedSharedWith));
        msw.filter(el => {
            return el.displayName === user;
        })[0].permission = event.target.value;
        setModifiedSharedWith(msw);
    };


    let shareHasChanged = () => {
        return !(
            JSON.stringify(modifiedSharedWith) !== JSON.stringify(currentlySharedWith) ||
            selectedShareUsers.length > 0
        );
    };

    // Handles the cancel action
    const handleClose = () => {
        setModelDialogContext({ ...modelDialogContext, shareOpen: false, shared: false });
    };

    return (
        <Dialog
            open={modelDialogContext && modelDialogContext.shareOpen ? modelDialogContext.shareOpen : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle id="alert-dialog-title">Share Current Model</DialogTitle>
            <DialogContent style={{ paddingBottom: 0, maxHeight: 650 }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <Typography variant="subtitle1" align="left" style={{ fontWeight: 500 }}>
                        Update Model Visbility{" "}
                    </Typography>
                </div>
                <TableSpinner loading={isSaving || false} message={"Saving Model..."} />
                <TableSpinner loading={isLoading || false} message={"Loading Model..."} />
                {
                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="subtitle1" align="left" style={{ fontWeight: 500, marginTop: 6 }}>
                                Grant Model Access{" "}
                            </Typography>
                        </div>
                        <Typography align="left" style={{ marginBottom: 12 }}>
                            Enter the names of your colleagues you wish to share this model with, and select an appropriate access level below.
                        </Typography>
                        {/* Access level on AIchemy is read/write/owner (reader/owner/editor on your side, change to read, write, share) */}
                        {/* Update user privleges format... {'pin@mmm.com'[accesslevel, displayname], 'pin2@mmm.com':[accesslevel, displayname] */}
                        <FormControl className={classes.formControl} fullWidth style={{ width: "100%", marginTop: 0, flexDirection: "row" }}>
                            <ShareUserMultiSelect shareUsersSelected={selectedShareUsers} />
                            <Select
                                fullWidth
                                style={{ textAlign: "left", backgroundColor: "white", select: { backgroundColor: "white" }, width: "auto", marginLeft: 12, height: 36 }}
                                value={selectedSharePermission}
                                onChange={selectedSharePermissionChange}
                                input={<Input name="share_permission_select" id="share_permission_select" />}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }
                                }}
                                data-cy="update_share_permission"
                            >
                                {sharePermissions
                                    .filter(el => {
                                        return el.value !== "Owner";
                                    })
                                    .map(key => (
                                        <MenuItem key={"main_select_" + key.value} value={key.value}
                                            data-cy={"permission_" + key.value}>
                                            <span>
                                                <span style={{ fontWeight: 400 }}>{key.display}</span>
                                            </span>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center"
                            }}
                        >
                            <Typography variant="subtitle1" align="left" style={{ fontWeight: 500, marginTop: 20, marginBottom: 6 }}>
                                Who Has Access{" "}
                            </Typography>

                        </div>

                        {modifiedSharedWith.length === 0 ? (
                            <div>
                                <Typography align="left" style={{ marginLeft: 0 }}>
                                    <i>Model Not Currently Shared</i>
                                </Typography>
                            </div>
                        ) : (
                            <div>
                                <Grid container spacing={6}>
                                    {modifiedSharedWith.map((user, index) => (
                                        <Grid key={index} item xs={6} >
                                            <FormControl
                                                className={classes.formControl}
                                                fullWidth
                                                style={{ width: "100%", marginTop: 0, flexDirection: "row", alignItems: "center", display: "flex" }}
                                            >
                                                {user.editable && (
                                                    <div onClick={() => deleteUser(user)} style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.87)", height: 24 }}>
                                                        <ClearIcon></ClearIcon>
                                                    </div>
                                                )}
                                                <Typography variant="subtitle1"> {user.displayName} – </Typography>
                                                <Select
                                                    fullWidth
                                                    style={{
                                                        textAlign: "left",
                                                        backgroundColor: "white",
                                                        select: { backgroundColor: "white" },
                                                        width: "auto",
                                                        marginLeft: 4
                                                    }}
                                                    value={user.permission}
                                                    onChange={ev => updateUserPermissionLevel(user.displayName, ev)}
                                                    input={<Input name={"share_permission_select_" + index} id={"share_permission_select_" + index} />}
                                                    disabled={!user.editable}
                                                    MenuProps={{
                                                        getContentAnchorEl: null,
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left"
                                                        }
                                                    }}
                                                >

                                                    {user.permission === "Owner"
                                                        ? sharePermissions
                                                            .filter(el => {
                                                                return el.value === "Owner";
                                                            })
                                                            .map(key => (
                                                                <MenuItem key={"main_select_" + key.value} value={key.value}>
                                                                    <span>{key.display}</span>
                                                                </MenuItem>
                                                            ))
                                                        : sharePermissions
                                                            .filter(el => {
                                                                return el.value !== "Owner";
                                                            })
                                                            .map(key => (
                                                                <MenuItem key={"main_select_" + key.value} value={key.value}>
                                                                    <span>{key.display}</span>
                                                                </MenuItem>
                                                            ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        )}
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={updatePermissions} color="primary" autoFocus
                    disabled={shareHasChanged()} data-cy="update_share">
                    Update Model
                </Button>
            </DialogActions>
        </Dialog>
    )

}


export default React.memo(ShareModelDialog);