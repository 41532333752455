import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Typography } from '@material-ui/core';
import { mbxAxios, mmAxios } from '../../API/mmAxios';

import FileDropzone from 'components/General/FileDropzone';
import { useMutationFC } from 'API/mutationHooks';
import { useQueryBase, useQueryFC } from 'API/queryHooks';


export default function FileTab({ docInstrument, material, isFetching, pendingDelete, setPendingDelete, files, setFiles }) {

  const linklessFiles = useMemo(() => {
    const tests = material?.tests?.filter(test => test.id && test.instrument?.title?.startsWith("DOCUMENT")) || []
    return tests.reduce((files, test) => {
      files.push(...(test.files || []).map(f => ({ id: f.id })))
      return files
    }, [])
  }, [material?.tests])

  const { data: links } = useQueryBase(["FileLinks", linklessFiles], () => downloadLinkQuery(linklessFiles), { enabled: !!linklessFiles.length, logName: "File Links" })
  const existingFiles = useMemo(() => links?.map(link => link.file) || [], [links])

  const missingInstrument = useMemo(() => {
    if (!isFetching && !docInstrument?.id) return 'Please add a permission group (lab) to this Material to upload files.'
    return undefined
  }, [docInstrument?.id, isFetching])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          Attached Files
        </Typography>
        <FileDropzone
          existingFiles={existingFiles}
          pendingDelete={pendingDelete}
          setPendingDelete={setPendingDelete}
          newFiles={files}
          setNewFiles={setFiles}
          disabled={isFetching || !!missingInstrument}
          message={isFetching ? "Checking DOCUMENT instrument..." : missingInstrument}
          messageError={!!missingInstrument}
        />
      </Grid>
    </Grid>
  );
}

export const SingleTestFileTab = ({ files, instrumentId, setNewFiles, setFileDeletion, disabled = false, message = "" }) => {
  const { data: links, isFetching } = useQueryBase(["FileLinks", files], () => downloadLinkQuery(files), { enabled: !!files.length, logName: "File Links" })
  const existingFiles = useMemo(() => links?.map(link => link.file) || [], [links])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          Attached Files
        </Typography>
        <FileDropzone
          existingFiles={existingFiles}
          pendingDelete={[]}
          setPendingDelete={setFileDeletion}
          newFiles={[]}
          setNewFiles={setNewFiles}
          disabled={isFetching || disabled}
          message={isFetching ? "Loading Files" : message}
          messageError={!!instrumentId}
        />
      </Grid>
    </Grid>
  );
}

async function downloadLinkQuery(files) {
  return mmAxios
    .post("files/downloads", { files })
    .then(res => {
      return res.data?.data?.file_links || []
    })
}

export function useFileUpload({ material, enqueueSnackbar }) {
  const [files, setFiles] = useState([])
  const [pendingDelete, setPendingDelete] = useState([])
  const mounted = useRef(true)
  useEffect(() => () => { mounted.current = false }, [])

  const docInstrumentQuery = useMemo(() => {
    // This query has changed. With instruments now being seen more as "tags", we will look for the
    // "DOCUMENT PORTAL" instrument in each environment.
    const labs = material?.labs?.filter(lab => lab.accessLevel === "writer")
    if (!labs?.length) return undefined
    return {
      Model: "Instrument",
      // filter: { and: [{ name: "labs.id", val: labs.map(lab => lab.lab.id), op: "in_" }, { name: "title", val: "DOCUMENT", op: "startswith" }] },
      filter: { name: "title", val: "DOCUMENT PORTAL", op: "startswith" },
      pageNumber: 1,
      pageSize: 50,
    }
  }, [material?.labs])

  const { data: latestData, isFetching } = useQueryFC(docInstrumentQuery, { logName: "Document Instruments" })
  const docInstrument = latestData?.data[0]

  const docTests = useMemo(() => material?.tests?.filter(test => test.instrument?.title?.startsWith("DOCUMENT")), [material?.tests])
  const { mutateAsync } = useMutationFC()

  const uploadOrDeleteFiles = useCallback(async (sample) => {
    if (!sample) {
      // With this new "onSave" hook, sample is not passed from anywhere anymore, so we'll just
      // set it to material if it doesn't exist (which it probably won't exist).
      sample = material;
    }

    const promises = []
    if (pendingDelete?.length) {
      const deleted = {}
      for (const fileId of pendingDelete) {
        const test = docTests.find(test => test.files.find(f => f.id === fileId))
        // delete file
        promises.push(mutateAsync(
          {
            param: {
              Model: "File",
              allow: { delete: true }
            },
            previous: { id: fileId }
          },
          {
            errorMessage: () => `Error: Failed to delete File`,
            successMessage: () => `File deleted.`,
          }
        ))
        // delete associated test if not already deleted and all files will be deleted
        if (test && !deleted[test.id] && test.files.every(f => pendingDelete.indexOf(f.id) >= 0)) {
          deleted[test.id] = true
          promises.push(mutateAsync(
            {
              param: {
                Model: "Test",
                allow: { delete: true },
              },
              previous: test,
            },
            {
              errorMessage: () => `Error: Failed to delete Test`,
            },
          ))
        }
      }
    }

    if (files.length && sample?.qid && docInstrument?.id) {
      let labUuids = sample.labs.map(item => item?.lab?.id).join();
      for (const file of files) {
        const formData = new FormData()
        for (const [key, val] of Object.entries({
          instrument_uuid: docInstrument?.id,
          upload_uuid: uuidv4(),
          module: 'etly.tabular',
          function: '',
          category: 'raw',
          qid: sample.qid,
          lab_uuids: labUuids,
          file
        })) {
          formData.append(key, val)
        }
        promises.push(
          mbxAxios
            .post(`upload`, formData)
            .then(res => {
              console.log("UPLOADING");
              if (res.data.status === 'created') {
                enqueueSnackbar(`${file.name} uploaded`, { variant: "success" })
              }
            })
            .catch(error => {
              console.error(error)
              enqueueSnackbar(`${file.name} failed to upload`, { variant: "error" })
            })
        )
      }
      if (mounted.current) setFiles([])

    }
    else if (files.length && !(docInstrument?.id)) {
      enqueueSnackbar(`No valid instrument to upload files`, { variant: "error" })
    }
    return Promise.all(promises)
  }, [docInstrument?.id, docTests, enqueueSnackbar, files, mutateAsync, pendingDelete, material])

  return { docInstrument, isFetching, files, setFiles, uploadOrDeleteFiles, pendingDelete, setPendingDelete }
}