import React, { useState, useCallback, useEffect } from "react";
import { GetRemovePlotFunc, InitVizParams, plotlyColorSchemeRGBA, wordWrap } from "./utils";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import ChartScatterPlotIcon from "mdi-material-ui/ChartScatterPlot";

import PlotCard from "./PlotCard";
import ColumnSelectField from "./ColumnSelectField";

function ScatterPlot({ data, handleRemovePlot, idx, visible, savedParams='', plot_uuid }) {
  const [headers, setHeaders] = useState([])
  const [plotData, setPlotData] = useState({})
  const [xAxis, setXAxis] = InitVizParams(savedParams,'xAxis', '')
  const [yAxis, setYAxis] = InitVizParams(savedParams,'yAxis', '')
  const [zAxis, setZAxis] = InitVizParams(savedParams,'zAxis', '')
  const [colorAxis, setColorAxis] = InitVizParams(savedParams,'colorAxis', '')
  const [plotType, setPlotType] = InitVizParams(savedParams,'plotType', '2d')
  const [currentSheet, setCurrentSheet] = InitVizParams(savedParams, 'currentSheet', '')
  const [paramsToSave, setParamsToSave] = useState({})
  const [plotWidthSize, setPlotWidthSize] = InitVizParams(savedParams, 'plotWidthSize','m')
  const cardName = 'Scatter Plot'
  const msPlotType = 'scatter'

  // update params to save
  useEffect(() => {
    setParamsToSave({ ...paramsToSave,
      xAxis: xAxis,
      yAxis: yAxis,
      zAxis: zAxis,
      colorAxis: colorAxis,
      plotType: plotType,
      currentSheet: currentSheet,
      plotWidthSize: plotWidthSize
    })
  }, [xAxis, yAxis, zAxis, colorAxis, plotType, currentSheet, plotWidthSize]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleRemoveCurrentPlot = GetRemovePlotFunc(savedParams, handleRemovePlot)

  const handleTogglePlot = (event, value) => {
    setPlotType(value);
    setXAxis(headers[0])
    setYAxis(headers[1])
    if (value === '3d') {
      setZAxis(headers[2])
    }
  }
  const getForm = () => {
    return (<>
      <ToggleButtonGroup
        value={plotType}
        exclusive
        onChange={handleTogglePlot}
        aria-label="text alignment"
        size="medium"
        style={{ marginTop: 15 }}
      >
        <Typography
          variant="subtitle1"
          align="center"
          style={{ marginRight: 10, marginTop: 10 }}
        >
          Plot type:{" "}
        </Typography>
        <ToggleButton value="2d">2D</ToggleButton>
        <ToggleButton value="3d">3D</ToggleButton>
      </ToggleButtonGroup>

      <ColumnSelectField
        label="X-axis"
        value={xAxis}
        setValue={setXAxis}
        headers={headers} />
      <ColumnSelectField
        label="Y-axis"
        value={yAxis}
        setValue={setYAxis}
        headers={headers}
      />
      {plotType === "3d" ? (
        <ColumnSelectField
          label="Z-axis"
          value={zAxis}
          setValue={setZAxis}
          headers={headers}
        />
      ) : (
        ""
      )}
      <ColumnSelectField
        label="Color"
        value={colorAxis}
        setValue={setColorAxis}
        headers={headers}
      />
    </>)
  }
  const getIcon = () => {
    return <ChartScatterPlotIcon style={{ marginTop: 14 }} />
  }

  const addColorbar = (trace, colorData, title) => {
    trace["marker"]["color"] = colorData;
    trace['marker']['colorscale'] = 'Viridis'
    trace['marker']['colorbar'] = { 'thickness': 20, title: { text: title, side: 'right' } }
    trace['showscale'] = true

    return trace
  }

  const getTrace = () => {
    let newTrace = {}
    const x = plotData[xAxis]
    const y = plotData[yAxis]
    if (!x) return []

    if (plotType === '2d') {
      newTrace = {
        x: x,
        y: y,
        columns: [xAxis, yAxis],
        mode: 'markers',
        type: 'scatter',
        hovertemplate: `${xAxis}: %{x}<br>${yAxis}: %{y}<br>`,
        marker: { size: 8 }
      }
    } else {
      const z = plotData[zAxis]
      newTrace = {
        x: x,
        y: y,
        z: z,
        columns: [xAxis, yAxis, zAxis],
        mode: 'markers',
        type: 'scatter3d',
        hovertemplate:
          `${xAxis}: %{x}<br>` +
          `${yAxis}: %{y}<br>` +
          `${zAxis}: %{z}<br>`,
        marker: {
          size: 8,
          color: plotlyColorSchemeRGBA(0, 1),
          line: {
            color: plotlyColorSchemeRGBA(0, 0.14),
            width: 0.5
          },
          opacity: 0.8
        },
      }
    }
    if (colorAxis) {
      newTrace = addColorbar(newTrace, plotData[colorAxis], colorAxis)
      newTrace.columns.push(colorAxis)
      newTrace.hovertemplate += `${colorAxis}: %{marker.color}<br>`
    }
    newTrace.hovertemplate += "<extra></extra>" // clear the trace0 bit from rendering
    return [newTrace]
  }
  const getLayout = () => {
    let title
    if (plotType === '2d') {
      if (colorAxis) {
        title = "Scatter plot of " + xAxis + ', ' + yAxis + ' and ' + colorAxis
      } else {
        title = "Scatter plot of " + xAxis + ' and ' + yAxis
      }
      return { autosize: true, title: wordWrap(title, 50), xaxis: { title: { text: xAxis } }, yaxis: { title: { text: yAxis } }, hovermode: 'closest' }
    }
    // 3d
    if (colorAxis) {
      title = "Scatter plot of " + xAxis + ', ' + yAxis + ', ' + zAxis + ' and ' + colorAxis
    } else {
      title = "Scatter plot of " + xAxis + ', ' + yAxis + ' and ' + zAxis
    }
    return {
      autosize: true,
      // height: plotWidth, 
      title: wordWrap(title, 50),
      scene: {
        xaxis: { title: { text: xAxis } },
        yaxis: { title: { text: yAxis } },
        zaxis: { title: { text: zAxis } },
      },
      margin: { l: 0, r: 0, b: 0, t: 50 },
      hovermode: 'closest',
    }
  }

  const onSheetChange = useCallback((currentHeaders, currentData) => {
    setHeaders(currentHeaders)
    setPlotData(currentData)
    if (setXAxis && !xAxis && ! yAxis) {
      setXAxis(currentHeaders?.[0])
      setYAxis(currentHeaders?.[1])
    }
  }, [setHeaders, setPlotData, setXAxis, setYAxis])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PlotCard
      plot_uuid={plot_uuid}
      data={data}
      getTrace={getTrace}
      getLayout={getLayout}
      getForm={getForm}
      getIcon={getIcon}
      cardName={cardName}
      savedParams={savedParams}
      currentSheet={currentSheet}
      setCurrentSheet={setCurrentSheet}
      paramsToSave={paramsToSave}
      plotWidthSize={plotWidthSize}
      setPlotWidthSize={setPlotWidthSize}
      setParamsToSave={setParamsToSave}
      plotType={msPlotType}
      idx={idx}
      handleRemovePlot={handleRemoveCurrentPlot}
      onSheetChange={onSheetChange}
      visible={visible}
    />
  )
}

export default React.memo(ScatterPlot);