import { useEffect, useMemo, useRef } from "react";
import { INode, IPort } from "react-flow-chart-mmm";
import { FlowChart } from "schema/FlowChart";
import { Column, NodeAliases, NodeColumns, NodeDefinitions, NodeTypes, ProcessChart, ProcessDefinition, ProcessNode, ProcessNodes, PropertyDefinition, Sample, SampleSet } from "schema/models";
import { getNodeTypeRank } from "schema/schemaUtils";
import { autoArrange } from "./flowChartUtils";

export function flowChartFromProcessChart(processChart: ProcessChart) {
    return autoArrange(updateFlowChartFromProcessChart(processChart, new FlowChart()))
}

export function updateFlowChartFromProcessChart(processChart: ProcessChart, flowChart: FlowChart): FlowChart {
    if (!processChart.nodes) return Object.keys(flowChart.nodes).length ? new FlowChart() : flowChart
    
    let newChart = flowChart

    // add or update nodes
    for (const [nodeId, pcNode] of Object.entries(processChart.nodes)) {
        if (!nodeId || pcNode.detached) continue
        const fcNode = newChart.nodes[nodeId]

        if (fcNode) {
            if (fcNode.type !== pcNode.type || !checkProperties(pcNode, fcNode)) {
                newChart = pushNode(nodeId, pcNode, newChart, flowChart)
            }
        }
        else {
            newChart = pushNode(nodeId, pcNode, newChart, flowChart)
        }
    }
    // add or update links after node
    for (const [nodeId, pcNode] of Object.entries(processChart.nodes)) {
        if (!nodeId || pcNode.detached) continue
        if (pcNode.to) {
            for (const toId of pcNode.to) {
                if (!toId) continue
                if (!checkLink(nodeId, toId, flowChart)){
                    const linkId = nodeId + toId
                    if (!newChart.links[linkId]) {
                        newChart = pushLink(linkId, nodeId, toId, newChart, flowChart)
                    }
                }
            }
        }
    }

    // remove nodes not in processNodes
    for (const nodeId of Object.keys(flowChart.nodes)) {
        if (!processChart.nodes[nodeId]) {
            newChart = removeNode(nodeId, newChart, flowChart)
        }
    }

    // remove links in in processNodes
    for (const link of Object.values(flowChart.links)) {
        if (!link.to || !processChart.nodes[link.from.nodeId]?.to?.includes(link.to.nodeId || "") || !flowChart.nodes[link.to.nodeId || ""] || !flowChart.nodes[link.from.nodeId || ""]) {
            newChart = removeLink(link.id, newChart, flowChart)
        }
    }

    // check selected
    if (newChart !== flowChart) {
        if (newChart.selected.type === "node" && newChart.nodes !== flowChart.nodes) {
            if (!newChart.nodes[newChart.selected.id || ""]) {
                newChart.selected = {}
            }
        }
        if (newChart.selected.nodes) {
            if (!Object.keys(newChart.selected.nodes).every(id => newChart.nodes[id])) {
                newChart.selected = {}
            }
        }
    }

    return newChart
}

export function updateFlowChartSelected(flowChart: FlowChart, selected: string[] | undefined): FlowChart {
    if (!selected?.length) {
        if ((flowChart.selected.id && flowChart.selected.type === "node") || (flowChart.selected.nodes && Object.keys(flowChart.selected.nodes).length)) {
            return {...flowChart, selected: {}}
        }
    }
    else {
        const selKeys = flowChart.selected.nodes ? Object.keys(flowChart.selected.nodes) : []
        const nodes = (selected.length === selKeys.length && selected.every(s => flowChart.selected.nodes?.[s])) ? flowChart.selected.nodes : selected.reduce((agg, val) => {
            agg[val] = true
            return agg
        }, {} as {[id: string]: boolean})
        if (flowChart.selected.id !== selected[0] || nodes !== flowChart.selected.nodes) {
            return {...flowChart, selected: {type: "node", id: selected[0], nodes}}
        }
    }
    return flowChart
}

function checkLink(fromId: string, toId: string, flowchart: FlowChart) {
    for (const link of Object.values(flowchart.links)) {
        if (link.from.nodeId === fromId && link.to?.nodeId === toId ) return true
    }
    return false
}

const Properties = ["label", "materialDefs", "processDefs", "propertyDefs", "color"] as (keyof ProcessNode)[]
function checkProperties(pcNode: ProcessNode, fcNode: INode): boolean {
    for (const key of Properties) {
        if (pcNode[key] !== fcNode.properties[key]) return false
    }
    return true
}

function removeNode(nodeId: string, newChart: FlowChart, flowChart: FlowChart) {
    if (newChart === flowChart) {
        newChart = {...flowChart}
    }
    if (newChart.nodes === flowChart.nodes) {
        newChart.nodes = {...flowChart.nodes}
    }
    delete newChart.nodes[nodeId]
    return newChart
}

function pushNode(nodeId: string, processNode: ProcessNode, newChart: FlowChart, flowChart: FlowChart): FlowChart {
    if (newChart === flowChart) {
        newChart = {...flowChart}
    }
    if (newChart.nodes === flowChart.nodes) {
        newChart.nodes = {...flowChart.nodes}
    }
    newChart.nodes[nodeId] = {
        ...newChart.nodes[nodeId],
        id: nodeId,
        type: processNode.type || "process",
        properties: {
            ...newChart.nodes?.[nodeId]?.properties,
        }
    }
    for (const key of Properties) {
        newChart.nodes[nodeId].properties[key] = processNode[key]
    }
    if (!newChart.nodes[nodeId].position) {
        newChart.nodes[nodeId].position = { x: -flowChart.offset.x + 10, y: -flowChart.offset.y + 10 }
    }
    if (!newChart.nodes[nodeId].ports) {
        newChart.nodes[nodeId].ports = getPorts(newChart.nodes[nodeId].type)
    }
    return newChart
}

function getPorts(type: string): {
    [id: string]: IPort
} {
    switch(type) {
        case "material":
            return {
                output: {
                    id: "output",
                    type: "right",
                }
            }
        case "property":
        case "output":
            return {
                "0": {
                    id: "0",
                    type: "left",
                }
            }
        case "process":
        default:
            return {
                "0": {
                    id: "0",
                    type: "left",
                },
                output: {
                    id: "output",
                    type: "right",
                }
            }
    }
}

function pushLink(linkId: string, nodeId: string, to: string, newChart: FlowChart, flowChart: FlowChart): FlowChart {
    if (!newChart.nodes[nodeId]?.ports?.["output"] || !newChart.nodes[to]?.ports?.["0"]) return newChart
    if (newChart === flowChart) {
        newChart = {...flowChart}
    }
    if (newChart.links === flowChart.links) {
        newChart.links = {...flowChart.links}
    }
    newChart.links[linkId] = {
        id: linkId,
        from: {
            nodeId: nodeId,
            portId: "output"
        },
        to: {
            nodeId: to,
            portId: "0"
        }
    }
    return newChart
}

function removeLink(linkId: string, newChart: FlowChart, flowChart: FlowChart) {
    if (newChart === flowChart) {
        newChart = {...flowChart}
    }
    if (newChart.links === flowChart.links) {
        newChart.links = {...flowChart.links}
    }
    delete newChart.links[linkId]
    return newChart
}

export function updateProcessChartFromFlowchart(flowchart: FlowChart, processChart: ProcessChart): ProcessChart {
    let newProcessChart = processChart
    // add new nodes
    for (const [nodeId, node] of Object.entries(flowchart.nodes)) {
        if (!newProcessChart.nodes?.[nodeId]) {
            newProcessChart = pushProcessNode(nodeId, node, newProcessChart, processChart)
        }
        else if (newProcessChart.nodes[nodeId].detached) {
            newProcessChart = pushProcessNode(nodeId, node, newProcessChart, processChart)
        }
    }
    // remove deleted nodes
    if (newProcessChart.nodes) {
        for (const [nodeId, node] of Object.entries(newProcessChart.nodes)) {
            if (!node.detached && !flowchart.nodes[nodeId]) {
                newProcessChart = removeProcessNode(nodeId, newProcessChart, processChart)
            }
        }
    }
    // update links
    const toMap = new Map<string, Set<string>>()
    for (const link of Object.values(flowchart.links)) {
        if (link.to.nodeId) {
            const forward = link.from.portId === "output"
            const fromId = forward ? link.from.nodeId : link.to.nodeId
            const toId = forward ? link.to.nodeId : link.from.nodeId
            if (newProcessChart.nodes?.[fromId]) {
                let current = toMap.get(fromId)
                if (!current) {
                    current = new Set<string>()
                    toMap.set(fromId, current)
                }
                current.add(toId)
            }
        }
    }
    for (const [nodeId, node] of Object.entries(newProcessChart.nodes || {})) {
        const to = toMap.get(nodeId)
        if (node) {
            if (((node.to?.length || 0) !== (to?.size || 0)) || !node.to?.every(l => to?.has(l))) {
                if (newProcessChart === processChart) {
                    newProcessChart = {...processChart}
                }
                if (newProcessChart.nodes === processChart.nodes) {
                    newProcessChart.nodes = {...processChart.nodes}
                }
                newProcessChart.nodes = newProcessChart.nodes || {}
                newProcessChart.nodes[nodeId] = {...node, to: to && Array.from(to)}
            }
        }
    }

    return newProcessChart
}

export function updateFlowChartHighlighted(flowChart: FlowChart, originalChart: FlowChart, highlightedNodes: string[] | undefined) {
    let newChart = flowChart
    for (const [nodeId, node] of Object.entries(newChart.nodes)) {
        const fade = highlightedNodes !== undefined && !highlightedNodes.includes(nodeId)
        if (!!node.properties?.fade !== fade) {
            if (newChart === originalChart) {
                newChart = {...newChart}
            }
            if (newChart.nodes === originalChart.nodes) {
                newChart.nodes = {...newChart.nodes}
            }
            if (newChart.nodes[nodeId] === originalChart.nodes[nodeId]) {
                newChart.nodes[nodeId] = {...newChart.nodes[nodeId]}
            }
            if (newChart.nodes[nodeId].properties === originalChart.nodes[nodeId].properties) {
                newChart.nodes[nodeId].properties ={...newChart.nodes[nodeId].properties}
            }
            newChart.nodes[nodeId].properties.fade = fade
        }
    }
    for (const [linkId, link] of Object.entries(newChart.links)) {
        if (!link.to) continue
        const fade = newChart.nodes[link.from.nodeId || ""].properties?.fade || newChart.nodes[link.to.nodeId || ""].properties?.fade
        if (!!link.properties?.fade !== fade) {
            if (newChart === originalChart) {
                newChart = {...newChart}
            }
            if (newChart.links === originalChart.links) {
                newChart.links = {...newChart.links}
            }
            if (newChart.links[linkId] === originalChart.links[linkId]) {
                newChart.links[linkId] = {...newChart.links[linkId]}
            }
            if (newChart.links[linkId].properties === originalChart.links[linkId].properties) {
                newChart.links[linkId].properties ={...newChart.links[linkId].properties}
            }
            newChart.links[linkId].properties.fade = fade
        }
    }
    return newChart
}

function pushProcessNode(nodeId: string, node: INode, newProcessChart: ProcessChart, processChart: ProcessChart): ProcessChart {
    if (newProcessChart === processChart) {
        newProcessChart = {...processChart}
    }
    if (newProcessChart.nodes === processChart.nodes) {
        newProcessChart.nodes = {...processChart.nodes}
    }
    newProcessChart.nodes = newProcessChart.nodes || {}
    newProcessChart.nodes[nodeId] = { to: [], type: node.type as NodeTypes, detached: false }
    for (const key of Properties) {
        newProcessChart.nodes[nodeId][key] = node.properties?.[key]
    }
    return newProcessChart
}

function removeProcessNode(nodeId: string, newProcessChart: ProcessChart, processChart: ProcessChart): ProcessChart {
    if (newProcessChart === processChart) {
        newProcessChart = {...processChart}
    }
    if (newProcessChart.nodes === processChart.nodes) {
        newProcessChart.nodes = {...processChart.nodes}
    }
    if (newProcessChart.nodes)
        delete newProcessChart.nodes[nodeId]
    return newProcessChart
}

export function updateColumnsFromProcessNodes(columns: Column[] | undefined, nodes: ProcessNodes | undefined, materialDefs: Sample[] | undefined, processDefs: ProcessDefinition[] | undefined, propertyDefs: PropertyDefinition[] | undefined) {
    const toAdd: (Column & { label: string, title: string })[] = []
    const toRemove: Column[] = []
    // check for removed nodes/defs
    if (columns) {
        for (const col of columns) {
            switch (col.nodeType) {
                case "material":
                    if (!nodes?.[col.nodeId]?.materialDefs?.[col.defId]) {
                        toRemove.push(col)
                    }
                    break
                case "process":
                    if (!nodes?.[col.nodeId]?.processDefs?.[col.defId]) {
                        toRemove.push(col)
                    }
                    break
                case "property":
                case "output":
                    if (!nodes?.[col.nodeId]?.propertyDefs?.[col.defId]) {
                        toRemove.push(col)
                    }
                    break
                default:
                    if (!(nodes?.[col.nodeId]?.materialDefs?.[col.defId] ||
                        nodes?.[col.nodeId]?.processDefs?.[col.defId] ||
                        nodes?.[col.nodeId]?.propertyDefs?.[col.defId])) {
                        toRemove.push(col)
                    }
            }

        }
    }
    let newCol = columns || []
    if (toRemove.length) {
        newCol = newCol?.filter(c => !toRemove.find(r => r === c)) || []
    }

    // check for new nodes/defs
    function checkDefType(defKey: "materialDefs" | "processDefs" | "propertyDefs", defs: (Sample | ProcessDefinition | PropertyDefinition)[] | undefined){
        if (nodes) {
            for (const [nodeId, node] of Object.entries(nodes as ProcessNodes)) {
                if (node[defKey]) {
                    for (const [defId, def] of Object.entries(node[defKey] as NodeDefinitions)) {
                        const definition = defs?.find(d => d.id === defId)
                        const cols: NodeColumns = def.cols || ColumnsFromDef(definition, defKey) || {}
                        for (const colKey of Object.keys(cols)) {
                            const found = columns?.find(c => c.nodeId === nodeId && c.defId === defId && c.colKey === colKey)
                            if (!found) {
                                toAdd.push({
                                    nodeType: node.type || "",
                                    nodeId,
                                    defId,
                                    colKey,
                                    label: node.label || "",
                                    title: node[defKey]?.[defId]?.title || definition?.title || "",
                                })
                            }
                        }
                    }
                }
            }
        }
    }    
    checkDefType("materialDefs", materialDefs)
    checkDefType("processDefs", processDefs)
    checkDefType("propertyDefs", propertyDefs)

    if (toAdd.length) {
        newCol = addColumns(newCol, columns, nodes, toAdd, materialDefs, processDefs, propertyDefs)
    }

    return (toAdd.length || toRemove.length) ? newCol : columns
}

function addColumns(
    newCol: Column[],
    columns: Column[] | undefined,
    nodes: ProcessNodes | undefined,
    toAdd: (Column & {label: string,
    title: string})[],
    materialDefs: Sample[] | undefined,
    processDefs: ProcessDefinition[] | undefined,
    propertyDefs: PropertyDefinition[] | undefined
) {
    if (newCol === columns) {
        newCol = [...newCol]
    }

    for (const add of toAdd) {
        // place new column in, sorted by: nodeType, nodeLabel, defTitle, then colKey
        let i = 0
        for (i = 0; i < newCol.length; ++i) {
            const col = newCol[i]
            if (getNodeTypeRank(add.nodeType) < getNodeTypeRank(col.nodeType)) {
                break
            }
            if (getNodeTypeRank(add.nodeType) > getNodeTypeRank(col.nodeType)) {
                continue
            }
            if (add.label < (nodes?.[col.nodeId].label || "")) {
                break
            }
            if (add.label > (nodes?.[col.nodeId].label || "")) {
                continue
            }
            const definition = getDefinition(col, materialDefs, processDefs, propertyDefs)
            if (add.title < (definition?.title || "")) {
                break
            }
            if (add.title > (definition?.title || "")) {
                continue
            }
            if (add.colKey < col.colKey) {
                break
            }
        }
        newCol.splice(i, 0, {nodeId: add.nodeId, defId: add.defId, colKey: add.colKey, nodeType: add.nodeType})
    }
    return newCol
}
export function getDefKey(col: Column, nodes?: ProcessNodes) {
    if (col.nodeType === "material") {
        return "materialDefs"
    }
    else if (col.nodeType === "process") {
        return "processDefs"
    }
    else if (col.nodeType === "property" || col.nodeType === "output") {
        return "propertyDefs"
    }
    if (nodes) {
        if (nodes?.[col.nodeId]?.materialDefs?.[col.defId]) return "materialDefs"
        if (nodes?.[col.nodeId]?.processDefs?.[col.defId]) return "processDefs"
        if (nodes?.[col.nodeId]?.propertyDefs?.[col.defId]) return "propertyDefs"
    }
    return undefined
}
export function getDefinition(col: Column, materialDefs: Sample[] | undefined, processDefs: ProcessDefinition[] | undefined, propertyDefs: PropertyDefinition[] | undefined) {
    if (col.nodeType === "material") {
        return materialDefs?.find(d => d.id === col.defId)
    }
    else if (col.nodeType === "process") {
        return processDefs?.find(d => d.id === col.defId)
    }
    else if (col.nodeType === "property" || col.nodeType === "output") {
        return propertyDefs?.find(d => d.id === col.defId)
    }
    return materialDefs?.find(d => d.id === col.defId) || processDefs?.find(d => d.id === col.defId) || propertyDefs?.find(d => d.id === col.defId)
}
export function getProcessChartDefinition(col: Column, nodes?: ProcessNodes) {
    const defKey = getDefKey(col)
    if (defKey) {
        return nodes?.[col.nodeId]?.[defKey]?.[col.defId]
    }
    return undefined
}

export function _ProcessChartFromOldFlowchart(flowchart: FlowChart): ProcessChart {
    const processChart = new ProcessChart()
    processChart.nodes = {}
    for (const [nodeId, node] of Object.entries(flowchart.nodes)) {
        processChart.nodes[nodeId] = { to: [], type: node.type as NodeTypes }
        for (const key of Properties) {
            processChart.nodes[nodeId][key] = node.properties?.[key]
        }
        processChart.nodes[nodeId].label = getNextLabel(nodeId, processChart.nodes, node.properties.label || "")
        if (node.type === "material" && node.properties?.definitions) {
            processChart.nodes[nodeId].materialDefs = {}
            for (const id of Object.keys(node.properties.definitions)) {
                (processChart.nodes[nodeId].materialDefs as any)[id] = { }
            }
        }
        if (node.type === "process" && node.properties?.definition) {
            processChart.nodes[nodeId].processDefs = {};
            (processChart.nodes[nodeId].processDefs as any)[node.properties.definition.id] = { }
        }
        if (node.type === "output" && node.properties?.definitions) {
            processChart.nodes[nodeId].propertyDefs = {}
            for (const id of Object.keys(node.properties.definitions)) {
                (processChart.nodes[nodeId].propertyDefs as any)[id] = { }
            }
        }
    }
    for (const link of Object.values(flowchart.links)) {
        const fromId = link.from.nodeId
        const toId = link.to.nodeId
        if (toId && fromId && processChart.nodes[fromId]) {
            processChart.nodes[fromId].to?.push(toId)
        }
    }
    return processChart
}

export function getNextLabel(nodeId: string, obj: any, label: string) {
    let newLabel = label
    let i = 2
    if (obj) {
        while(true) {
            let found = false
            for (const [id, v] of Object.entries(obj)) {
                if (id !== nodeId && (v as any)?.label === newLabel) {
                    found = true
                    break
                }
            }
            if (!found) break
            newLabel = `${label} ${i}`
            i++
        }
    }
    return newLabel
}

export function updateProcessNodesFromSamples(processChart: ProcessChart, samples?: Sample[], nodeAliases?: NodeAliases): ProcessChart {
    let newChart = processChart

    if (samples){
        for (const sample of samples) {
            newChart = pushNodesFromInstances(sample, newChart, processChart, nodeAliases, "components", "material", "Materials", "materialDefs")
            newChart = pushNodesFromInstances(sample, newChart, processChart, nodeAliases, "processSteps", "process", "Process", "processDefs")
            newChart = pushNodesFromInstances(sample, newChart, processChart, nodeAliases, "properties", "property", "Output", "propertyDefs")
        }
    }
    return newChart
}

function pushNodesFromInstances(
    sample: Sample | undefined,
    newChart: ProcessChart,
    processChart: ProcessChart,
    nodeAliases: NodeAliases | undefined,
    instKey: "components" | "processSteps" | "properties",
    type: NodeTypes,
    labelRoot: string,
    typeKey: "materialDefs" | "processDefs" | "propertyDefs",
) {
    if (sample){
        for (const inst of (sample[instKey] || [])) {
            let nodeId = inst.nodeId || "";
            if (sample.id && nodeAliases?.[sample.id]?.[nodeId]) {
                nodeId = nodeAliases[sample.id][nodeId]
            }
            const node: ProcessNode = newChart.nodes?.[nodeId] || { type, label: getNextLabel(nodeId, newChart, labelRoot), detached: true };
            const defId = inst.definition?.id;
            if (defId && !node[typeKey]?.[defId]) {
                if (newChart === processChart) {
                    newChart = { ...newChart };
                }
                if (newChart.nodes === processChart.nodes) {
                    newChart.nodes = { ...newChart.nodes };
                }
                if (newChart.nodes?.[nodeId] === processChart.nodes?.[nodeId]) {
                    (newChart.nodes as ProcessNodes)[nodeId] = { ...node };
                }
                if ((newChart.nodes as ProcessNodes)[nodeId][typeKey] === processChart.nodes?.[nodeId]?.[typeKey]) {
                    (newChart.nodes as ProcessNodes)[nodeId][typeKey] = { ...(newChart.nodes as ProcessNodes)[nodeId][typeKey] };
                }
                if (((newChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId]) {
                    if (((newChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId] === ((processChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId]) {
                        ((newChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId] = {...((newChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId]}
                    }
                    ((newChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId].count = (((newChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId].count || 1) + 1
                }
                else 
                    ((newChart.nodes as ProcessNodes)[nodeId][typeKey] as NodeDefinitions)[defId] = {};
            }
        }
    }
    return newChart;
}

export function ColumnsFromDef(def: Sample | ProcessDefinition | PropertyDefinition | undefined, type: "materialDefs" | "processDefs" | "propertyDefs"): NodeColumns | undefined {
    if (!def) return undefined
    switch(type) {
        case "materialDefs":
            return {
                Amount: {
                    units: "g"
                }
            }
        case "propertyDefs":
            return {
                Value: {
                    units: (def as PropertyDefinition).units
                }
            }
        case "processDefs":
            return (def as ProcessDefinition).processVariables?.reduce((agg, val) => {
                if (val.title)
                    agg[val.title] = { units: val.units }
                return agg
            }, {} as NodeColumns) || ({} as NodeColumns)
        default:
    }
    return
}

export const dragMaterialGroupData = {
    label: "Material Group",
    themePalette: "material",
    data: {
        type: "material",
        properties: {
            label: "Materials",
            data: [],
        },
        ports: {
            output: {
                id: "output",
                type: "right",
            }
        }
    }
}

export function useDetachedDragData(processChart: ProcessChart) {
    return useMemo(() => {
        if (!processChart?.nodes) return []
        const dragData = []
        for (const [nodeId, node] of Object.entries(processChart.nodes)) {
            if (nodeId && node.detached) {
                const label = getNextLabel(nodeId, processChart, node.label || "Group")
                const data = {
                    id: nodeId,
                    type: node.type,
                    properties: {}
                } as any
                for (const key of Properties) {
                    data.properties[key] = node[key]
                }
                data.properties.label = label
                data.ports = {
                    "0": {
                        id: "0",
                        type: "left",
                    },
                    output: {
                        id: "output",
                        type: "right",
                    }
                }
                let themePalette = undefined

                if (node.type === "material") {
                    themePalette = "material"
                    data.ports = {
                        output: {
                            id: "output",
                            type: "right",
                        }
                    }
                }
                else if (node.type === "output" || node.type === "property") {
                    node.type = "output"
                    themePalette = "label"
                    data.ports = {
                        "0": {
                            id: "0",
                            type: "left",
                        }
                    }
                }
                else if (node.type === "process") {
                    themePalette = "process"
                }
                
                dragData.push({
                    materialDefs: node.materialDefs,
                    processDefs: node.processDefs,
                    propertyDefs: node.propertyDefs,
                    label,
                    themePalette,
                    data,
                })
            }
        }
        return dragData
    },[processChart])
}

export function useEffectUpdateColumnsFromNodes(
    processChart: ProcessChart | undefined,
    isLoadingMaterial: boolean,
    isLoadingProcess: boolean,
    isLoadingProperty: boolean,
    materialDefs: Sample[] | undefined,
    processDefs: ProcessDefinition[] | undefined,
    propertyDefs: PropertyDefinition[] | undefined,
    updateProcessChart: any,
) {
    const updatePC = useRef(true)
    const nodesRef = useRef(processChart?.nodes)
    useEffect(() => {
        if (isLoadingMaterial || isLoadingProcess || isLoadingProperty)
            updatePC.current = true
        if (
            processChart &&
            !isLoadingMaterial &&
            !isLoadingProcess &&
            !isLoadingProperty &&
            (updatePC.current ||
                !processChart.columns ||
                processChart.nodes !== nodesRef.current)
        ) {
            const newCol = updateColumnsFromProcessNodes(
                processChart.columns,
                processChart.nodes,
                materialDefs || [],
                processDefs || [],
                propertyDefs || [],
            )
            if (newCol !== processChart.columns) {
                updateProcessChart({ columns: newCol })
            }
            updatePC.current = false
        }
        nodesRef.current = processChart?.nodes
    }, [
        processChart,
        isLoadingMaterial,
        isLoadingProcess,
        isLoadingProperty,
        materialDefs,
        processDefs,
        propertyDefs,
        updateProcessChart,
    ])
}

export function useHighlightedNodes(
    sampleSet: SampleSet,
    processChart: ProcessChart | undefined,
    tableSelected: { samples: number[]; columns: number[] } | undefined,
) {
    const highlightedNodesRef = useRef<string[]>([])
    return useMemo(() => {
        let newHighlighted: string[] | undefined = undefined
        if (
            tableSelected &&
            (tableSelected.samples.length > 0 ||
                tableSelected.columns.length > 0)
        ) {
            const nodeSet = new Set<string>()
            if (
                tableSelected.columns.length === 0 ||
                tableSelected.columns.length === processChart?.columns?.length
            ) {
                const addInstKey = (
                    sample: Sample,
                    instKey: "components" | "processSteps" | "properties",
                ) => {
                    for (const inst of sample[instKey] || []) {
                        if (inst.nodeId) {
                            const id =
                                sampleSet.nodeAliases?.[sample.id || ""]?.[
                                    inst.nodeId
                                ] || inst.nodeId
                            nodeSet.add(id)
                        }
                    }
                }
                for (const i of tableSelected.samples || []) {
                    const sample = sampleSet.samples?.[i]
                    if (sample) {
                        addInstKey(sample, "components")
                        addInstKey(sample, "processSteps")
                        addInstKey(sample, "properties")
                    }
                }
            } else if (processChart?.columns) {
                for (const i of tableSelected.columns) {
                    nodeSet.add(processChart.columns?.[i]?.nodeId || "")
                }
            }
            newHighlighted = Array.from(nodeSet)
        }
        if (
            newHighlighted?.length === highlightedNodesRef.current?.length &&
            newHighlighted?.every(nh =>
                highlightedNodesRef.current.includes(nh),
            )
        ) {
            newHighlighted = highlightedNodesRef.current
        }
        return newHighlighted
    }, [
        processChart?.columns,
        sampleSet.nodeAliases,
        sampleSet.samples,
        tableSelected,
    ])
}