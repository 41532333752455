import React, { useCallback } from 'react';

import StyledReactTable, { defaultColumn, defaultFilterTypes }  from '../ReactTable/StyledReactTable';

import { useTable, useFlexLayout, useSortBy } from 'react-table';
import EditCell from '../ReactTable/EditCell';
import { addDeleteColumn } from '../ReactTable/addDeleteColumn';
import { simplifyString } from '../../utils/utils';

const columns = [
        {
            Header: "Name",
            accessor: "title",
            width: 200,
            Cell: EditCell,
            padding: 'none',
            placeholder: "\u00A0New...",
        },
        {
            Header: "Units",
            accessor: "units",
            width: 100,
            Cell: EditCell,
            padding: 'none',
            placeholder: "\u00A0...",
        },
        {
            Header: "Description",
            accessor: "description",
            width: 400,
            Cell: EditCell,
            padding: 'none',
            placeholder: "\u00A0...",
        }
    ];

export default function ProcessVariablesTable({processVariables = [], onChange}){
    const data = React.useMemo(() => {
        const data = processVariables.slice();
        data.push({title: "", units: "", description: ""});
        return data;
    }, [processVariables]);

    const handleDelete = useCallback(row => {
        if (row.index < processVariables.length){
            const newData = processVariables.slice();
            newData.splice(row.index, 1);
            onChange && onChange(newData);
        }
    }, [onChange, processVariables]);

    const handleEdit = useCallback((id, index, value) => {
        const newData = processVariables.slice();
        const varId = id === "title" ? simplifyString(value) : newData[index]?.id;
        if (index >= processVariables.length){
            if (value === "") return;
            newData.push({[id]: value, id: varId});
        }
        else{
            newData[index] = {...newData[index], [id]: value, id: varId}; 
        }
        onChange && onChange(newData);
    }, [onChange, processVariables]);

    const table = useTable({
        initialState: {pageSize: 5},
        columns,
        defaultColumn,
        data,
        autoResetPage: false,
        autoResetFilters: false,
        autoResetSortBy: false,
        filterTypes: defaultFilterTypes,
        onEdit: handleEdit,
        onDelete: handleDelete,
        rowsDisplayed: 5,
    },
        useFlexLayout,
        useSortBy,
        addDeleteColumn,
    );


    return (
        <div style={{ textAlign: "initial"}}>
            <StyledReactTable 
                {...table}
                />
        </div>
    );
}