import React, { useMemo, useCallback, useRef } from 'react';
import StyledReactTable, { defaultColumn } from '../../ReactTable/StyledReactTable';
import { useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters, useRowSelect } from 'react-table';
import { useAppStoreKey } from '../../../AppStore';
import { defaultColumns, modelsColumns } from './modelsColumns';
import { useColumnsSimple } from '../../ReactTable/reactTableUtils';
import { addOpenColumn } from '../../ReactTable/addOpenColumn';
import { addDeleteColumn } from '../../ReactTable/addDeleteColumn';

function ModelsTable({
    modelsData,
    onDelete,
    onOpen,
    loading
}) {
    const [serializedColumns, columns] = useColumnsSimple("ModelsTableColumns", modelsColumns, defaultColumns);
    let modelsDataDisplayed = useRef(modelsData);
    const [tableState, setTableState] = useAppStoreKey("modelsTable");

    const queryParam = useMemo(() => {
        const filters = tableState?.filters ? [...tableState.filters] : [];
        const sort = tableState?.sortBy ? [...tableState.sortBy] : [];

        const fields = !!serializedColumns.find(col => col.colId.startsWith("model")) ? ["model.model_title"] : undefined;
        return (tableState && {
            Model: "Models",
            fields,
            pageNumber: tableState.pageIndex + 1,
            pageSize: tableState.pageSize,
            filter: filters,
            sort
        });
    }, [serializedColumns, tableState]);

    const filterAndSortTable = useCallback(() => {
        let mData = modelsData ? [...modelsData] : [];

        // Handle Sort
        if (queryParam && queryParam.sort && queryParam.sort.length > 0) {
            queryParam.sort.forEach((sort) => {
                //check if ascending or descending
                if (sort.desc) {
                    mData.sort((a, b) => (a[sort.id] > b[sort.id]) ? 1 : -1)
                } else {
                    mData.sort((a, b) => (a[sort.id] < b[sort.id]) ? 1 : -1)
                }
            });
        }

        // Handle Filters
        if (queryParam && queryParam.filter && queryParam.filter.length > 0) {
            // For each filtered field, filter the table
            queryParam.filter.forEach((filter) => {
                switch (filter.value.type) {
                    case 'text':
                        let val = filter.value.query.toLowerCase()
                        mData = mData.filter(el => {
                            return el[filter.id].toLowerCase().includes(val)
                        })
                        break;
                    case 'startswith':
                        mData = mData.filter(el => {
                            return el[filter.id].startsWith(filter.value.query)
                        })
                        break;
                    case 'endswith':
                        mData = mData.filter(el => {
                            return el[filter.id].endsWith(filter.value.query)
                        })
                        break;
                    case '~': // regex
                        mData = mData.filter(el => {
                            return el[filter.id].match(filter.value.query)
                        })
                        break;
                    case 'date':
                        let startDate = filter.value.query.startDate.getTime();
                        // bump the end date by 1 day (minus 1 ms)
                        let endDate = new Date(filter.value.query.endDate.getTime());
                        endDate.setDate(endDate.getDate()+1)
                        endDate = endDate.getTime() - 1;

                        mData = mData.filter(el => {
                            let time = new Date(el[filter.id]).getTime();
                            return startDate <= time && time <= endDate;
                        })
                        break
                    default:
                        break
                }
            });
        }

        modelsDataDisplayed.current = mData
    }, [queryParam, modelsData]);

    filterAndSortTable();

    const data = modelsDataDisplayed.current;

    const handleDelete = useCallback(row => {
        if (row) {
            onDelete && onDelete(row);
        }
    }, [onDelete]);

    const handleOpen = useCallback(row => {
        if (row) {
            onOpen && onOpen(row);
        }
    }, [onOpen]);


    const table = useTable({
        columns,
        defaultColumn,
        initialState: tableState,
        data,
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        autoResetFilters: false,
        totalRows: modelsData.length,
        pageCount: Math.max(Math.ceil((modelsData.length || 0) / (tableState?.pageSize || 10)), 1),
        getRowId: useCallback((row, relIndex, parent) => parent ? [parent.id, row.id || relIndex] : (row.id || relIndex), []),
        autoResetSelectedRows: false,
        onEdit: handleOpen,
        onDelete: handleDelete
    },
        useFlexLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        addOpenColumn,
        addDeleteColumn
    );

    return (

        <div style={{ textAlign: "initial" }}>
            <StyledReactTable
                {...table}
                loading={loading}
                updating={false}
                onChangeParam={setTableState}
            />
        </div>
    );
}
export default React.memo(ModelsTable);