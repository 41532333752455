import React, { useEffect, useRef, useState } from "react";
import Card from "@material-ui/core/Card";
import TrainModelCard from "./TrainModelCard"
import PlotModelResults from "./PlotModelResults";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useAppStoreKey } from "../../../AppStore"
import common_styles from "../../../styles/common_styles"
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import GiftOpenIcon from 'mdi-material-ui/GiftOpenOutline';
import MuiAlert from '@material-ui/lab/Alert';
import ModelSelector from "./ModelSelector";
import CompareModels from "./CompareModels";
import useTrainModel from "./useTrainModel";

const useStyles = makeStyles(common_styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MsTrainModel() {
  const classes = useStyles()
  const [workflow,] = useAppStoreKey('Workflow')
  const { errorState, trainingState, ReloadTasks } = useTrainModel({})
  const [MSState,] = useAppStoreKey('MixingStudioState')
  const comparePlots = MSState.modelComparePlots
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const modelResultsRef = useRef()
  const modelCompareResultsRef = useRef()
  const topAnchorRef = useRef()
  const scrollToResults = () => {
    modelResultsRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    setOpenSnackbar(false)
  }

  useEffect(() => {
    if (trainingState === undefined) {
      ReloadTasks()
    }
  }, [trainingState, ReloadTasks])

  const nError = () => errorState === undefined ? 0 : errorState.filter(state => state.operation === "train").length
  const nTraining = () => trainingState === undefined ? 0 : trainingState.filter(task => task.operation === "train").length
  const nVisibleModel = () => workflow.models.filter(model => !model.hide).length

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={10000}
      >
        <Alert
          severity="success"
          onClose={() => setOpenSnackbar(false)}
          action={
            <React.Fragment>
              <IconButton color="inherit" size="small" onClick={scrollToResults} >
                <GiftOpenIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" color="inherit" onClick={() => setOpenSnackbar(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          Model training finished
        </Alert>
      </Snackbar>
      <div ref={topAnchorRef} />
      <Card
        className={classes.paperBody}
        elevation={1}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <div style={{ margin: 24 }}>
          <TrainModelCard nextRef={modelResultsRef} />
        </div>
      </Card>
      <div ref={modelResultsRef} />
      {(workflow && nVisibleModel() + nTraining() + nError() > 0) && (
        <Card
          className={classes.paperBody}
          elevation={3}
          style={{ marginTop: 24, marginBottom: 24 }}
        >
          <div style={{ margin: 24 }}>
            <PlotModelResults topAnchorRef={topAnchorRef} />
          </div>
        </Card>
      )}
      {(workflow && nVisibleModel() > 0) && <><Card
        className={classes.paperBody}
        elevation={3}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <div style={{ margin: 24 }}>
          <ModelSelector modelCompareResultsRef={modelCompareResultsRef} />
        </div>
      </Card>
      </>}
      {comparePlots.length > 0 && <Card
        className={classes.paperBody}
        elevation={3}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <div ref={modelCompareResultsRef} />
        <div style={{ margin: 24 }} >
          <CompareModels plots={comparePlots} />
        </div>
      </Card>}
    </>
  )
}