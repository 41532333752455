import React, { useCallback } from 'react';
import { makeQueryFilters } from '../ReactTable/reactTableUtils';
import {PropertySelectorBase} from '../General/PropertySelector';
import TableSelectorDialogBase from './TableSelectorDialogBase';
import {propertyColumns, defaultColumns} from '../PropertyLibrary/propertyColumns';

/** Table selection dialog for property */
export default function SelectPropertyTableDialog({open, onClose, initSelected = undefined, selectSingle = undefined}){
    const handleTableParam = useCallback(({tableParam, columns}) => {
        const filters = makeQueryFilters(tableParam?.filters, columns);
        // convert sort and append timeModified
        const sort = (tableParam?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");
        return (tableParam && {
                Model: "PropertyDefinition",
                pageNumber: tableParam.pageIndex+1, 
                pageSize: tableParam.pageSize, 
                filter: filters, 
                sort
            });
    }, []);

    return (
        <TableSelectorDialogBase 
            selectSingle={selectSingle}
            open={open} 
            onClose={onClose} 
            initSelected={initSelected}
            localForageKey="SelectPropertyTableColumns"
            onTableParam={handleTableParam}
            SelectComponent={PropertySelectorBase}
            title="Search and Select Properties"
            tableColumns={propertyColumns}
            defaultColumns={defaultColumns}
        />
    );
}