import Dashboard from './components/Dashboard/Dashboard';
import ContextProviders from './context';
import AuthProvider from 'utils/AuthProvider';
import React from "react";


export default function App() {
    return (
        <div className="App">
            <AuthProvider>
                <ContextProviders>
                    <Dashboard />
                </ContextProviders>
            </AuthProvider>
        </div>
    );
}
