import {
    Instrument, LabToSample, LabToSampleSet,
    LabToProcessDefinition, LabToPropertyDefinition, LabToTest
} from "../models"
import { Schema, schema } from "../schemaUtils"

export class Lab {
    id?: string
    title?: string
    description?: string
    timeCreated?: Date
    timeModified?: Date

    group?: string // used locally in FE for groupsing labs by user role 

    instruments?: Instrument[]
    samples?: LabToSample[]
    sampleSets?: LabToSampleSet[]
    propertyDefinitions?: LabToPropertyDefinition[]
    processDefinitions?: LabToProcessDefinition[]
    tests?: LabToTest[]


    static readonly [schema]: Schema<Lab> = {
        endPoint: "labs",
        type: "lab",
        relationships: {
            instruments: { map: "instruments", model: "Instrument", toMany: true, reverse: "labs" },
            samples: { map: "samples", model: "LabToSample", toMany: true, reverse: "lab" },
            sampleSets: { map: "sample_sets", model: "LabToSampleSet", toMany: true, reverse: "lab" },
            propertyDefinitions: { map: "property_definitions", model: "LabToPropertyDefinition", toMany: true, reverse: "lab" },
            processDefinitions: { map: "process_definitions", model: "LabToProcessDefinition", toMany: true, reverse: "lab" },
            tests: { map: "tests", model: "LabToTest", toMany: true, reverse: "lab" },
        },
        map: {
            id: "id",
            title: "title",
            description: "description",
            timeCreated: "created",
            timeModified: "modified",
        }
    }

    get [schema](): Schema<Lab> {
        return this[schema]
    }

    constructor(toCopy?: Partial<Lab>) {
        Object.assign(this, toCopy)
    }
}