import { Base } from '../Base'
import { LabToProcessDefinition, ProcessInstance } from '../models'
import { ProcessVariable } from '../ProcessVariable'
import { Schema, schema } from '../schemaUtils'

export class ProcessDefinition extends Base {
    contributors?: string[]
    title?: string
    alternateNames?: string // \n delimited string
    description?: string
    processVariables?: ProcessVariable[]
    labs?: LabToProcessDefinition[]

    instances?: ProcessInstance[]

    static readonly [schema]: Schema<ProcessDefinition> = {
        endPoint: "process_definitions",
        type: "process_definition",
        relationships: {
            ...Base[schema].relationships,
            instances: { map: "process_instances", model: "ProcessInstance", toMany: true, reverse: "definition" },
            labs: { map: "labs", model: "LabToProcessDefinition", toMany: true, reverse: "processDefinition", meta: { of: "labs", through: "lab" } }
        },
        map: {
            ...Base[schema].map,
            "alternateNames": "alternate_names",
            "contributors": "contributors",
            "title": "title",
            "description": "description",
            "processVariables[{id}]": "meta.process_variables",
        }
    }

    constructor(toCopy?: Partial<ProcessDefinition>) {
        super()
        Object.assign(this, toCopy)
    }
}