import { useEffect, useMemo, useState } from "react";
import Typography from "@material-ui/core/Typography";
import FormControl, { useFormControl } from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText";
import Slider from "@material-ui/core/Slider"
import { Grid, Input } from "@material-ui/core";
// for the visiblity button
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useAppStoreKey } from "AppStore";

function FormHandler({ onDefocus, errorMessage }) {
  const { focused } = useFormControl() || {};
  const [wasFocused, setWasFocused] = useState(false)
  useMemo(() => {
    if (focused) {
      setWasFocused(true)
      return;
    }
    if (wasFocused) {
      setWasFocused(false)
      onDefocus && onDefocus()
    }
  }, [focused, onDefocus, wasFocused])
  return <FormHelperText error>{errorMessage}</FormHelperText>
}

export default function VariableSlider({ variableName, minValue, maxValue, initialValue, initialHidden, onChangeCommitted, otherProps, onVisible, onHidden, isLoading }) {
  const [value, setValue] = useState(0)
  const [prevValue, setPrevValue] = useState(undefined)
  const [disabled, setDisabled] = useState(false)
  const [stashedData] = useAppStoreKey("MS/Predict/Single")

  useEffect(() => {
    let hidden = stashedData?.hidden?.[variableName]
    setDisabled(hidden !== undefined ? hidden : false)
  }, [stashedData.hidden, variableName])

  useEffect(() => {
    setValue(initialValue)
    setDisabled(initialHidden)
  }, [initialValue, initialHidden])

  const handleChange = (ev, val) => {
    // we're keeping the number as a string until the last possible second
    if (val !== undefined) {
      setValue(Number(val).toString()); // slider change
    } else if (typeof (ev) === "string") {
      setValue(Number(ev).toString()) // text box exit
    } else if (typeof (ev) === "object") {
      if (ev.type === "change") {
        setValue(ev.target.value) // textbox change
      }
    } else {
      console.log(typeof (ev))
    }
  }

  const changeCommitted = () => {
    if (value === prevValue) {
      return;
    }
    setPrevValue(value);
    if (onChangeCommitted !== undefined) {
      onChangeCommitted(variableName, parseFloat(value));
    }
  }

  const icon = useMemo(() => {
    if (disabled) {
      return <VisibilityOff />
    } else {
      return <Visibility />
    }
  }, [disabled])

  const toggleDisabled = () => {
    if (disabled) {
      onVisible && onVisible();
    } else {
      onHidden && onHidden();
    }
    setDisabled(!disabled);
  }

  const isError = useMemo(() => {
    return value === undefined || value === "" || isNaN(value)
  }, [value])

  return (
    <Grid item key={variableName + "as"} {...otherProps}>
      <Typography
        variant="subtitle1"
        gutterBottom
        style={{
          textAlign: "left",
          marginBottom: 15
        }}
      >
        <IconButton onClick={toggleDisabled}>
          {icon}
        </IconButton>
        {variableName}
      </Typography>
      <FormControl style={{ marginLeft: 0, width: "100%" }} >
        <Input
          error={isError}
          value={value} // remove leading zeros from number
          type="number"
          onChange={handleChange}
          style={{ marginTop: 0 }}
          disabled={disabled || isLoading}
          onWheel={event => { event.preventDefault() }}
        />
        {/* if we lose focus on this, we should commit the change */}
        <FormHandler
          onDefocus={() => { handleChange(value); changeCommitted() }}
          errorMessage={isError ? "Value not set" : ""}
        />
      </FormControl>
      <Slider
        value={Number(value)} // need to convert for purposes of handling the slider
        min={minValue}
        max={maxValue}
        onChange={handleChange}
        onChangeCommitted={changeCommitted}
        step={(maxValue - minValue) / 100}
        disabled={disabled || isLoading}
      />
    </Grid>
  )
}
