import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, makeStyles } from '@material-ui/core';
import InstrumentSelector from '../General/InstrumentSelector';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles(theme => ({
    label: {
        color: theme.palette.text.secondary
    },
}))

export default function AddTestCount({onAdd, label, nodeId}){
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [instruments, setInstruments] = useState();
    const handleClose = useCallback(() => {
        setOpen(false);
        setInstruments(undefined);
    }, []);
    const handleAdd = useCallback(() => {
        onAdd && onAdd((instruments?.length && [{instrumentTitles: instruments.map(i => i.title), instrumentIds: instruments.map(i => i.id)}]) || [{}]);
        handleClose();
    }, [instruments, handleClose, onAdd]);
    return (
        <TreeItem
            nodeId={nodeId}
            label={<div>
                <div onClick={() => setOpen(true)} className={classes.label}>{label}</div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Select Instrument(s) (optional)</DialogTitle>
                    <DialogContent style={{minWidth: 400}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InstrumentSelector selected={instruments} fullWidth onChange={(ev, values) => setInstruments(values)}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAdd} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>}    
        />
    )
}