import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PortLabelGroup from './PortLableGroup';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    fcNodeInner: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "&.type-":{
            "&titled":{
                alignItems: "start",
                alignSelf: "start",
            }
        },
        "&.-highlighted": {
            border: "solid 2px #FFFF00",
            borderRadius: theme.spacing(1),
        }
    },
    fcNodeInnerText: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        ...theme.typography.caption,
    },
    fcNodeInnerTitleBar: {
        minWidth: "100%",
        height: `${theme.spacing(4) + (2 * theme.spacing(0.5))}px`,     
        background: (props = {}) => (props.node && props.node.type && theme.palette[props.node.type]) ? theme.palette[props.node.type].dark : theme.palette.grey[800],
        borderRadius: theme.spacing(1),
        borderBottomLeftRadius: props => props.portsHeight < 1 ? theme.spacing(1) : 0,
        borderBottomRightRadius: props => props.portsHeight < 1 ? theme.spacing(1) : 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    fcNodeInnerTitleText: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        ...theme.typography.caption,
        textAlign: "center",
        color: (props = {}) => (props.node && props.node.type && theme.palette[props.node.type]) ? theme.palette.getContrastText(theme.palette[props.node.type].dark) : "#FFFFFF",
    },
    fcNodeInnerContents:{
        minWidth: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    fcNodeInnerListContents:{
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    fcNodeInnerTitleListBar: {
        minWidth: "100%",
        height: `${theme.spacing(2) + (2 * theme.spacing(0.5))}px`,     
        background: "#00600f",
        borderRadius: theme.spacing(1),
        borderBottomLeftRadius: props => props.portsHeight < 1 ? theme.spacing(1) : 0,
        borderBottomRightRadius: props => props.portsHeight < 1 ? theme.spacing(1) : 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function NodeInner(props, ref){
    const {config, node} = props;
    const {getInnerComponent} = config;
    let InnerComponent = (getInnerComponent && getInnerComponent(node)) || DefaultNodeInner;
    InnerComponent = InnerComponent ? InnerComponent : DefaultNodeInner;
    return <InnerComponent ref={ref} {...props}/>
}
export default React.forwardRef(NodeInner);


export const DefaultNodeInner = (React.forwardRef((props, ref) => {
    const {config, node, className, children, ...rest} = props;
    const leftLabelledPorts = node.ports ? Object.values(node.ports).filter(p => p.type === "left" && p.properties && p.properties.label) : [];
    const rightLabelledPorts = node.ports ? Object.values(node.ports).filter(p => p.type === "right" && p.properties && p.properties.label) : [];
    const portsHeight = leftLabelledPorts.length > rightLabelledPorts.length ? leftLabelledPorts.length : rightLabelledPorts.length;
    const styleProps = {portsHeight, node};
    const classes = useStyles(styleProps);

    return (
        <div ref={ref} className={clsx(className, classes.fcNodeInner, node.type && ("type-" + node.type))} {...rest}>
            <div className={classes.fcNodeInnerTitleBar}>
                <div className={classes.fcNodeInnerTitleText}>
                    <Typography variant="h5">{(node.properties && node.properties.label)}</Typography>
                </div>
            </div>
            <div className={classes.fcNodeInnerContents}>
                {leftLabelledPorts.length > 0 && <PortLabelGroup ports={leftLabelledPorts} side="left"/>}
                {rightLabelledPorts.length > 0 && <PortLabelGroup ports={rightLabelledPorts} side="right"/>}
            </div>
            {children}
        </div>
    );
}));