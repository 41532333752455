import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1),
        background:theme.palette.primary.main + "20",
        borderRadius: theme.spacing(0.5,0.5,0,0),
        "&.smallPadding": {
            padding: theme.spacing(0.5,1),
        }
    },
    left: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    right: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}))
/** Styling for placing buttons above a table */
export default function TableButtonGroup({
    children = undefined,
    right = undefined,
    left = undefined,
    smallPadding = undefined,
    ...rest
}) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.container, smallPadding && "smallPadding")}>
            <div className={classes.left}>
                {left}
            </div>
            <div className={classes.right}>
                {right}
                {children && 
                <ButtonGroup color='primary' variant='text' {...rest}>
                    {children}
                </ButtonGroup>}
            </div>
        </div>
    );
}