import React, { useEffect, useState, useRef, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import redImage from '../../images/3MTrifectaMeshRed.png';
import blueImage from '../../images/3MTrifectaMeshBlue.png';
import purpleImage from '../../images/3MTrifectaMeshPurple.png';
import magentaImage from '../../images/3MTrifectaMeshMagenta-02.png';
import grayImage from '../../images/3MTrifectaMeshGray.png';
import greenImage from '../../images/3MTrifectaMeshGreen.png';

const imageRing = [magentaImage, purpleImage, blueImage, redImage, greenImage, grayImage];
const colorRing = ["#c30E97", "#4722A2", "#0C85C6", "#CC1B4B", "#48C91D", "#8D8F92"];
const switchPeriodDefault = 20; // seconds

const useStyles = makeStyles({
    all:{
        backgroundSize: 'cover',
        padding: 0,
        margin: 0,
    },
    gradient:{
        backgroundSize: 'cover',
        maskImage: "linear-gradient(30deg, rgba(0,0,0,0), rgba(0,0,0,1))",
    },
    revgradient:{
        backgroundSize: 'cover',
        maskImage: "linear-gradient(30deg, rgba(0,0,0,1), rgba(0,0,0,0))",  
    },
    visible:{
        transition: props => `opacity ${props.switchPeriod ? props.switchPeriod : switchPeriodDefault}s linear`,
        opacity: "1",
    },
    invisible:{
        transition: props => `opacity ${props.switchPeriod ? props.switchPeriod : switchPeriodDefault}s linear`,
        opacity: "0",
    },
  });

function useDelay(callback, delay, resetChange){
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const timeout = setTimeout(savedCallback.current, delay);
        return () => clearTimeout(timeout);
    }, [resetChange, delay])

}

export default function FadeCorner(props){
    const {children, className, onColorChange, switchPeriod = switchPeriodDefault} = props;
    const classes = useStyles(props);

    const [transition, setTransition] = useState(0);
    const [backIndex, setBackIndex] = useState(0);
    const [frontIndex, setFrontIndex] = useState(1);

    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const divRef = useCallback(node => {
        if (node !== null) {
            const rect = node.getBoundingClientRect();
            setWidth(rect.width);
            setHeight(rect.height);
        }
      }, []);

    const handleTransition = useCallback(() => {
        const next = transition >= 3 ? 0 : transition + 1;
        setTransition(next);
        if (transition === 2){
            setBackIndex(backIndex >= imageRing.length-1 ? 0 : backIndex + 1);
        }
        else if (transition === 0){
            setFrontIndex(frontIndex >= imageRing.length-1 ? 0 : frontIndex + 1);
        }
    }, [transition, backIndex, frontIndex]);
    
    // jump start transition
    useEffect(() => {
        setTransition(1);
    }, [setTransition]);
    useDelay(handleTransition, switchPeriod * 1500, [transition]); // restart if it stops

    const color = onColorChange ? (colorRing[(transition === 3 || transition === 0) ? backIndex : frontIndex]) : undefined;
    useEffect(() => {
        onColorChange && onColorChange(color);
    }, [color, onColorChange]);

    const backImage = imageRing[backIndex];
    const frontImage = imageRing[frontIndex];

    return (
        <div ref={divRef}>
            {
                imageRing.map((image, index) => <img key={index} src={image} style={{display: "none"}} alt=""/>)
            }
            <div className={clsx(classes.all, className)} style={{padding: 0, backgroundImage: `url(${backImage})`, width: width, height: height}}>
                <div onTransitionEnd={(transition === 1 || transition === 0) ? handleTransition : undefined} className={clsx((transition === 3 || transition === 0) ? classes.revgradient : classes.gradient, classes.all, (transition > 0) ? classes.visible: classes.invisible)} style={{padding: 0, backgroundImage: `url(${frontImage})`, width: width, height: height}}/>
                <div onTransitionEnd={(transition === 2 || transition === 3) ? handleTransition : undefined} className={clsx(classes.all, (transition === 2) ? classes.visible: classes.invisible)} style={{display:"block", width: width, height: height, position:"absolute", top:0, left:0, padding: 0, backgroundImage: `url(${frontImage})`,}}/>
            </div>
            <div style={{display:"block", width: "100%", height: "100%", position:"absolute", top:0, left:0,}}>
                <div className={className}>
                    {children}
                </div>
            </div>
        </div>
    );
}