import React from 'react';
import DefaultCell from './DefaultCell';

function getValue(value, categories){
    return (categories && categories.find(cat => cat.value === value)?.label) || value
}

export default function CategoryCell(props){
    const {column: {categories}} = props;
    const value = Array.isArray(props.cell.value) ? props.cell.value.map(v => getValue(v, categories)).join('; ') : getValue(props.cell.value, categories);
    const cell = {...props.cell, value};
    return <DefaultCell {...props} cell={cell}/>
}