import React from 'react'
import { useState, useEffect } from 'react';
import { timeElapsed } from "../utils";
import Typography from "@material-ui/core/Typography";

const Timer = ({startTime, stage}) => {
  const [time, setTime] = useState('');

  useEffect(() => {
    let myInterval = setInterval(() => {
      setTime(timeElapsed(startTime));
    }, 1000)
    return ()=> {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 7}}>
        {stage}: {time}
      </Typography>
    </div>
  )
}

export default Timer;