import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, makeStyles } from '@material-ui/core';
import PropertySelector from '../General/PropertySelector';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles(theme => ({
    label: {
        color: theme.palette.text.secondary
    },
}))

export default function AddPropertyValue({onAdd, label, nodeId}){
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [properties, setProperties] = useState([]);
    const handleClose = useCallback(() => {
        setOpen(false);
        setProperties(undefined);
    }, []);
    const handleMaterial = useCallback((ev, value) => {
        setProperties(value);
    }, []);
    const handleAdd = useCallback(() => {
        onAdd && onAdd(properties.map(prop => ({id: prop.id, title:prop.title, type: prop.type, source: prop.source, allowedValues: prop.allowedValues})));
        handleClose();
    }, [properties, handleClose, onAdd]);
    return (
        <TreeItem
            nodeId={nodeId}
            label={<div>
                <div onClick={() => setOpen(true)} className={classes.label}>{label}</div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Select Properties</DialogTitle>
                    <DialogContent style={{minWidth: 400}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PropertySelector selected={properties} fullWidth onChange={handleMaterial}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAdd} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>}    
        />
    )
}