import React, { useMemo, useCallback, useState } from 'react';
import { useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters } from 'react-table';
import { useQueryPagination } from 'API/queryHooks';

import { Button } from '@material-ui/core';

import { useAppStoreKey } from '../../AppStore';
import StyledReactTable, { defaultColumn, LOADING_ROW }  from '../ReactTable/StyledReactTable';
import { useColumns, makeQueryFilters } from '../ReactTable/reactTableUtils';
import { DEFAULT_TABLE_STATE, DEFAULT_PAGE_SIZE } from '../ReactTable/constants';
import { addOpenColumn } from '../ReactTable/addOpenColumn';
import CustomizeColumnsDialog from '../ReactTable/CustomizeColumnsDialog';
import TableButtonGroup from '../General/TableButtonGroup';
import NewButton from '../General/NewButton';
import { defaultColumns, propertyColumns } from './propertyColumns';


function PropertyTable({onEdit, onNew, loading}){
    const [tableState=DEFAULT_TABLE_STATE, setTableState] = useAppStoreKey("propertyDefintionTable");
    const [serializedColumns, setSerializedColumns, columns, columnsLoading] = useColumns("PropertyTableColumns", propertyColumns, defaultColumns);
    const [editColumns, setEditColumns] = useState(false);
    const handleColumns = useCallback(() => setEditColumns(true), []);
    const handleCloseColumns = useCallback((cols) => {setEditColumns(false); setSerializedColumns(cols)}, [setSerializedColumns]);
    loading = loading || columnsLoading;

    const queryParam = useMemo(() => {
        const filters = makeQueryFilters(tableState?.filters, columns);
        // convert sort and append timeModified
        const sort = (tableState?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");
        const fields = !!serializedColumns.find(col => col.colId.startsWith("labs")) ? ["labs.lab.title"] : undefined;
        return tableState && {
            Model: "PropertyDefinition",
            fields, 
            pageNumber: tableState.pageIndex+1, 
            pageSize: tableState.pageSize, 
            filter: filters, 
            sort
        }
    }, [columns, serializedColumns, tableState]);
 
    const { data: resolvedData, isLoading, isFetching } = useQueryPagination((tableState.pageIndex || 0) + 1, queryParam,  {logName: "Property Table"}, LOADING_ROW);

    const data = useMemo(()=>resolvedData?.data || [], [resolvedData]);

    const table = useTable({
        columns,
        defaultColumn,
        initialState: tableState,
        data: useMemo(()=>resolvedData?.data || [], [resolvedData]),
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        autoResetFilters: false,
        totalRows: resolvedData?.count,
        pageCount: Math.max(Math.ceil((resolvedData?.count || 0) / (tableState?.pageSize || DEFAULT_PAGE_SIZE)), 1),
        getRowId: useCallback((row, relIndex, parent) => parent ? [parent.id, row.id || relIndex] : (row.id || relIndex), []),
        onEdit,
    },
        useFlexLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        addOpenColumn,
    );
    const {state: {filters}, setAllFilters} = table;
    const handleClearFilters = useCallback(() => {
        setAllFilters && setAllFilters([]);
    }, [setAllFilters]);

    return (
        <div style={{ textAlign: "initial" }}>
            <CustomizeColumnsDialog 
                columnDefinitions={propertyColumns} 
                serializedColumns={serializedColumns} 
                defaultColumns={defaultColumns} 
                open={editColumns} 
                onClose={handleCloseColumns}
            />
            <TableButtonGroup>
                <Button disabled={!filters || filters.length === 0 } color='primary' variant='text' onClick={handleClearFilters}>
                    Clear Filters
                </Button>
                <Button onClick={handleColumns}>
                    Edit Columns and Filters
                </Button>
                <NewButton onClick={onNew}>
                    New Property
                </NewButton>
            </TableButtonGroup>
            <StyledReactTable 
                {...table}
                loading={loading || (isLoading && !data?.length)}
                updating={isFetching || (isLoading && data?.length)}
                onChangeParam={setTableState}
                />
        </div>
    );
}
export default React.memo(PropertyTable);