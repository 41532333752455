import { Tooltip, Typography } from "@material-ui/core"
import React, { useMemo } from "react"
import { ProcessDefinition, ProcessNode, PropertyDefinition, Sample } from "schema/models"

type NodeSummaryProps ={
    node: ProcessNode
    materialDefs?: Sample[]
    processDefs?: ProcessDefinition[]
    propertyDefs?: PropertyDefinition[]
}
// @ts-ignore
export const NodeSummary: React.FC<NodeSummaryProps> = ({node, materialDefs, processDefs, propertyDefs, children}) => {
    const elements = useMemo(() => {
        const labels: string[] = []
        if (node.materialDefs) {
            for (const id of Object.keys(node.materialDefs)) {
                const inst = materialDefs?.find(d => d.id === id)
                if (inst?.title) {
                    labels.push(inst.title)
                } 
            }
        }
        if (node.processDefs) {
            for (const id of Object.keys(node.processDefs)) {
                const inst = processDefs?.find(d => d.id === id)
                if (inst?.title) {
                    labels.push(inst.title)
                } 
            }
        }
        if (node.propertyDefs) {
            for (const id of Object.keys(node.propertyDefs)) {
                const inst = propertyDefs?.find(d => d.id === id)
                if (inst?.title) {
                    labels.push(inst.title)
                } 
            }
        }
        return labels
    }, [materialDefs, node.materialDefs, node.processDefs, node.propertyDefs, processDefs, propertyDefs])

    return (
        <Tooltip 
            placement="right"
            title={elements?.length ?
                <div style={{display: "flex", flexDirection: "column"}}>
                    {elements.map((label, i) => <Typography key={i} variant="caption">{label}</Typography>)}
                </div>
                : ""
            }
        >
            <div>
                {children as any}
            </div>
        </Tooltip>
    )
}