import './utils/polyfills';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MM_APP_VERSION } from 'components/Dashboard/Drawer';

if (window === window.parent) {
    {// override some event handlers to prevent chrome errors. TODO: This is a temp hack... hopefully new versions of react will provide a better fix, otherwise remove scrollwheel events
        const EVENTS_TO_MODIFY = ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel'];

        const originalAddEventListener = document.addEventListener.bind();
        document.addEventListener = (type, listener, options, wantsUntrusted) => {
            let modOptions = options;
            if (EVENTS_TO_MODIFY.includes(type)) {
                if (typeof options === 'boolean') {
                    modOptions = {
                        capture: options,
                        passive: false,
                    };
                } else if (typeof options === 'object') {
                    modOptions = {
                        passive: false,
                        ...options,
                    };
                }
            }
            return originalAddEventListener(type, listener, modOptions, wantsUntrusted);
        };

        const originalRemoveEventListener = document.removeEventListener.bind();
        document.removeEventListener = (type, listener, options) => {
            let modOptions = options;
            if (EVENTS_TO_MODIFY.includes(type)) {
                if (typeof options === 'boolean') {
                    modOptions = {
                        capture: options,
                        passive: false,
                    };
                } else if (typeof options === 'object') {
                    modOptions = {
                        passive: false,
                        ...options,
                    };
                }
            }
            return originalRemoveEventListener(type, listener, modOptions);
        };
    }

    console.log(`*** Materials Manager Commit Hash: ${process.env.REACT_APP_VERSION} ***`);
    console.log(`*** Materials Manager Version: ${MM_APP_VERSION} ***`)

    ReactDOM.render(
        <App />
        , document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
