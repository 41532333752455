import { mmAxios } from './mmAxios';

export const maxCalls = 5000
export const maxPageSize = 50

/**
 * Wrapper for mmAxios.get to automically split multiple API calls when requested pages size is greater than maxPageSize.
 * Assumes JSON API spec with total-count returned in the meta field.
 */
export async function splitGet(endPoint, config, axiosGet = mmAxios.get) {
    const { params } = config;
    const size = params["page[size]"];
    const page = params["page[number]"] || 1;
    if (size <= maxPageSize || !size) {
        return axiosGet(endPoint, config);
    }
    else {
        const newPage = Math.floor(((page - 1) * size) / maxPageSize) + 1;
        const offset = ((page - 1) * size) - ((newPage - 1) * maxPageSize);
        const payload = await axiosGet(endPoint, { ...config, params: { ...params, "page[number]": newPage, "page[size]": maxPageSize } });
        const count = payload.data.meta && payload.data.meta["total-count"];
        if (!count)
            return payload;
        if ((count - ((newPage - 1) * maxPageSize)) <= maxPageSize) {
            if (offset > 0)
                payload.data.data = payload.data.data.slice(offset);
            return payload;
        }
        let calls = Math.ceil(Math.min(count - ((newPage - 1) * maxPageSize), size) / maxPageSize);
        if (calls > maxCalls) {
            console.warn("Max API calls exceeded. Request is too large. Truncating data returned.");
            calls = maxCalls;
        }
        const promises = [];
        for (let i = 1; i < calls; ++i) {
            promises.push(axiosGet(endPoint, { ...config, params: { ...params, "page[number]": newPage + i, "page[size]": maxPageSize } }));
        }
        const payloads = await Promise.all(promises);
        payload.data.data = payload.data.data.concat(...payloads.map(p => p.data.data));
        payload.data.data = payload.data.data.slice(offset, offset + size);
        return payload;
    }
}
