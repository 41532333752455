export function MessageTranslator(status) {
    if (status === 404) {
        return "  ::  (Missing file, not found - 404)";
    }
    if (status === 400) {
        return "  ::  (Something went wrong with the request - 400)"
    }
    if (status === 401) {
        return "  ::  (User does not have authentication access to this resource - 401)"
    }
    if (status === 403) {
        return "  ::  (User does not have authorization access to this resource - 403)"
    }
    if (status === 408) {
        return "  ::  (The request timed out - 408)"
    }
    return `  ::  ${status}`;
}