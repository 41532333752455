import React, { useMemo, useState, useRef, useEffect } from 'react';
import { nodeFromProcess } from '../FlowChart/flowChartUtils';
import { FlowChart } from "../../schema/FlowChart";
import Node from '../FlowChart/Node';
import NodeInner from '../FlowChart/NodeInner';
import Link from '../FlowChart/Link';
import Port from '../FlowChart/Port';
import Ports from '../FlowChart/Ports';
import CanvasOuter from '../FlowChart/CanvasOuter';
import {FlowChartWithActions} from 'react-flow-chart-mmm';

const config = {readonly: true, nodeValid: true};

/**
 * React Component form for setting formulation set meta data
 * @param {*} props 
 */
export default function ProcessNodePreview({process}) {
    const [nodeSize, setNodeSize] = useState({});
    const ref = useRef();

    const [chart, setChart] = useState(() => updateChart(ref, process, nodeSize));    
    useEffect(() => {
        setChart(updateChart(ref, process, nodeSize))
    }, [nodeSize, process])

    const height = nodeSize && nodeSize.height ? Math.max(160, nodeSize.height + 50) : 160; 

    useEffect(() => setNodeSize({width: 96, height: 24}),[]); // update size after initial mount.

    const callbacks = useMemo(() => {
        const callbacks = {};
        const off = () => chart => chart;
        callbacks["onDeleteKey"] = off
        callbacks["onLinkStart"] = off
        callbacks["onLinkComplete"] = off
        callbacks["onCanvasDrop"] = off
        callbacks["onCanvasWheel"] = off
        return callbacks;
    }, [])

    return (
        <div ref={ref} style={{height: height, display: "flex", overflow: "hidden"}}>
            <FlowChartWithActions callbacks={callbacks} chart={chart} onChange={chart => setChart(chart)} Components={{Node, NodeInner, Link, Port, Ports, CanvasOuter}} config={config} />
        </div>                  
    );
}

function updateChart(ref, process, nodeSize) {
    if (!ref.current) return new FlowChart();
    const node = nodeFromProcess(process);
    node.position = (ref.current && nodeSize.width && nodeSize.height) ? 
        {
            x: (ref.current.clientWidth - nodeSize.width)/2 + 4000,
            y: (ref.current.clientHeight - nodeSize.height)/2 + 4000
        }
        : {x: 4050, y: 4050};
    node.size = nodeSize.width && nodeSize.height ? {width: nodeSize.width, height: nodeSize.height} : undefined;
    node.id = "node";
    return new FlowChart({offset: {x: -4000, y: -4000}, nodes: {node}});
}