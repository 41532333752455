import { useEffect, useRef, useCallback, useMemo, useState } from "react"
import { Divider } from "@material-ui/core"
import { NavigateFn } from "@reach/router"

import { updateProcessNodesFromSamples } from "components/FlowChart/processChartUtils"
import { updateSamplesOnNodeDelete } from "components/SampleSetLibrary/editSampleHooks"

import {
    Sample, ProcessChart, SampleInstance, ProcessInstance, PropertyInstance
} from "schema/models"

import { checkLabs } from "utils/invalidators"
import useFormState from "utils/useFormState/useFormState"
import { State, UpdateTransform, Validator } from "utils/useFormState/useFormStateTypes"
import { simpleString, usePrevious } from "utils/utils"
import {
    addDefsToSample, addSampleCalProps, updateSampleCalculatedProperties
} from "utils/schemaUtils"

import MaterialsSummaryList from "./MaterialsSummaryList"


const QID_CHANGED_DIALOGUE = <div>
        <p>
        Changing QIDs for existing samples is generally <b>not recommended</b> unless you are fixing an error in 
        recently created samples. <i style={{color: "#D11F15"}}>Changing QIDs will NOT associate already uploaded tests.</i>
        </p>
        <p>Do you wish to continue with the QID and other changes?</p>
        <p>(If <b style={{color: '#03F0FF'}}>copying a Material</b>, ignore this and select <b style={{color: '#03F0FF'}}><i>Yes</i></b>.)</p>
    </div>

enum SaveMaterialOriginEnum {
    NORMAL,
    COPY_MATERIAL
}

type useSaveProps = {
    validateAll: any,
    material: Sample,
    account: any,
    initialMaterial: Sample,
    mutateAsync: any,
    enqueueSnackbar: any,
    enqueueDialogs: any,
    similar?: Sample[],
    isFetchingSimilar?: boolean,
    fileUploadProps?: any,
    saveOrigin?: SaveMaterialOriginEnum
}
export function useSave({
    validateAll,
    material,
    account,
    initialMaterial,
    mutateAsync,
    enqueueSnackbar,
    enqueueDialogs,
    similar,
    isFetchingSimilar,
    fileUploadProps,
    saveOrigin = SaveMaterialOriginEnum.NORMAL
}: useSaveProps) {
    const handleSave = useCallback(async () => {
        // add user to contributors list
        const payload = preMaterialSave(
            material,
            account.name,
        )
        const prevValid =
            initialMaterial?.id && initialMaterial.id === payload.id
        const previous = prevValid
            ? new Sample({
                ...initialMaterial,
                tests: undefined,
                sets: undefined,
            })
            : undefined

        // save sample set
        const saved: Sample = await mutateAsync(
            {
                param: {
                    Model: "Material",
                    allow: { patch: true, post: true },
                    relationships: {
                        processChart: {
                            allow: {
                                post: true,
                                patch: true,
                            },
                        },
                    },
                },
                payload,
                previous,
            },
            {
                errorMessage: () =>
                    `Server Error: Failed to save ${material.title}`,
                successMessage: prevValid
                    ? () => `${material.title} updated.`
                    : () => `${material.title} saved.`,
            },
        )

        return saved
    }, [material, account.name, initialMaterial, mutateAsync])

    const userIsContributor = useMemo(() => {
        if (
            material.id &&
            (!material.contributors || material.contributors.indexOf(account.name) < 0)
        ) {
            return false
        }
        return true
    }, [account.name, material.contributors, material.id])

    const qidChanges = initialMaterial?.qid && initialMaterial.qid !== material.qid

    const prevSimilar = usePrevious(similar) as Sample[] | undefined
    const resolveSimilarRef = useRef<(value: Sample[] | PromiseLike<Sample[] | undefined> | undefined) => void>()
    const similarRef = useRef<Promise<Sample[] | undefined>>()
    useEffect(() => {
        if (similar !== prevSimilar) {
            if (!resolveSimilarRef.current) {
                similarRef.current = new Promise<Sample[] | undefined>(
                    resolve => (resolveSimilarRef.current = resolve),
                )
            }
            if (!isFetchingSimilar) {
                resolveSimilarRef.current?.(similar)
                resolveSimilarRef.current = undefined
            }
        }
    }, [similar, prevSimilar, isFetchingSimilar])

    const onSave = useCallback(async () => {
        const allValid = (await validateAll()).filter(Boolean)
        if (allValid.length !== 0) {
            enqueueSnackbar(allValid[0] || "Incomplete or bad data: Check Fields", {
                variant: "error",
            })
            return
        }

        const dialogs: any[] = []
        if (!userIsContributor) {
            dialogs.push({
                title: "Confirm New Contributor",
                message:
                    "You do not appear to be a past contributor to this material. Please make sure the owners are aware of any edits you make. Do you wish to be added as a contributor and save these changes?",
            })
        }
        const similar = await Promise.resolve(similarRef.current)

        if (similar?.length) {
            dialogs.push({
                title: "Confirm this is a New Material",
                yes: "Confirm and Save",
                cancel: "Cancel",
                maxWidth: "md",
                message: <div>
                    The material you are saving has loosely similar identifiers to
                    the following material(s) already in the library. In order to
                    cut down on duplicates in the database, please make sure none of
                    these match your material. Consider adding an alternate name to
                    an existing material if necessary. You may still need to save a
                    duplicate to manage access permissions or to document batch
                    specific properties such as purity.
                    <Divider style={{ margin: "8px 0" }} />
                    {similar && (
                        <MaterialsSummaryList
                            ids={similar.map(sample => sample.id)}
                        />
                    )}
                </div>,
            })
        }
        if (qidChanges && saveOrigin === SaveMaterialOriginEnum.NORMAL) {
            dialogs.push({
                title: "Confirm QID Changes",
                message: QID_CHANGED_DIALOGUE,
            })
        }

        if (fileUploadProps) {
            console.log("Upload/Delete Files")
            if (fileUploadProps.files?.length > 0 || fileUploadProps.pendingDelete?.length > 0) {
                fileUploadProps.uploadOrDeleteFiles();
            }
        }

        return enqueueDialogs({
            propArray: dialogs,
            onYes: async () => handleSave(),
        })
    }, [validateAll, userIsContributor, qidChanges, enqueueDialogs, enqueueSnackbar, handleSave, fileUploadProps, saveOrigin])

    return onSave
}

function preMaterialSave(material: Sample, accountName: any) {
    // add user to contributors list
    const contributors = (material.contributors || [])
        .filter(c => c !== accountName)
        .concat([accountName])

    // clear empty qid
    const qid = material.qid ? material.qid : undefined

    // clear empty instances
    const properties = (material.properties || []).filter(
        p =>
            p.definition?.id &&
            p.data !== undefined &&
            p.data !== null &&
            p.data !== "",
    )

    properties.forEach((element, index) => {
        if (!isNaN(properties[index].data as number)) {
            properties[index].data = Number(properties[index].data);
        }
    });

    const processChart = material.processChart?.nodes ? material.processChart : undefined

    const components = (material.components || []).filter(
        c => c.definition?.id && Number(c.amount),
    )
    const processSteps = (material.processSteps || []).filter(
        c => c.definition?.id,
    )

    return new Sample({
        ...material,
        contributors,
        qid,
        properties,
        components,
        processSteps,
        processChart,
        tests: undefined,
    })
}

type useSavedProps = { navigate: NavigateFn, updateData: any }
export function useSaved({ navigate, updateData }: useSavedProps) {
    return useCallback(
        // @ts-ignore
        saved => {
            updateData(saved)
            navigate(`./${saved.id}`)
            return saved
        },
        [navigate, updateData],
    )
}

type useDeleteProps = { mutateAsync: any, material: Sample, enqueueDialogs: any }
export function useDelete({ mutateAsync, material, enqueueDialogs }: useDeleteProps) {
    const handleDelete = useCallback(async () => {
        const allow = { delete: true }
        const deleteProcessChart = material.processChart?.samples?.length === 1 && material.processChart.samples[0].id === material.id
        await mutateAsync(
            {
                param: {
                    Model: "Sample",
                    allow: { delete: true },
                    relationships: {
                        properties: { allow },
                        processSteps: { allow },
                        components: { allow },
                        processChart: deleteProcessChart ? { allow } : undefined
                    },
                },
                previous: material,
            },
            {
                errorMessage: () => `Error: Failed to delete Material`,
                successMessage: () => `Material deleted.`,
            },
        )
    }, [mutateAsync, material])

    return useCallback(async () => {
        return enqueueDialogs({
            propArray: [{
                title: "Deleting Material",
                message: "Deleting cannot be undone, are you sure you wish to permanently delete this material?",
                yes: "Delete",
            }],
            onYes: async () => handleDelete(),
        })
    }, [enqueueDialogs, handleDelete])
}

type useCopyProps = {
    enqueueSnackbar: any,
    material: Sample,
    updateMaterial: (update: Sample, name: string | undefined, replace?: boolean) => void
}
export function useCopy({ enqueueSnackbar, material, updateMaterial }: useCopyProps) {
    const onCopy = useCallback(
        async () => {
            const newSample = new Sample({
                ...material,
                id: undefined,
                contributors: undefined,
                title: undefined,
                qid: undefined,
                components: material.components?.map(
                    instance =>
                        new SampleInstance({
                            ...instance,
                            id: undefined,
                        }),
                ),
                processSteps: material.processSteps?.map(
                    instance =>
                        new ProcessInstance({
                            ...instance,
                            id: undefined,
                        }),
                ),
                properties: material.properties?.map(
                    instance =>
                        new PropertyInstance({
                            ...instance,
                            id: undefined,
                        }),
                ),
                processChart: new ProcessChart({ ...material.processChart, id: undefined, samples: undefined, sets: undefined })
            })
            updateMaterial(newSample, "Copy Material", true)
            enqueueSnackbar(
                `${material.title
                } copied. Click save to upload.`,
                { variant: "info" },
            )
        },
        [enqueueSnackbar, material, updateMaterial],
    )
    return material?.id ? onCopy : undefined
}

export function useEditMaterialState(initialMaterial: Sample, defsRef: any) {
    const [selectedNodes, setSelectedNodes] = useState([])
    const [tableSelected, setTableSelected] =
        useState<{ samples: number[]; columns: number[] }>()

    const invalidators = useMemo(
        () => makeInvalidators(initialMaterial),
        [initialMaterial],
    )

    const initialState: State<Sample> = useMemo(() => {
        // We want the default new material to definitely be a chemical. This corresponds to the
        // default state change of the checkbox as "raw" to be true.
        if (!(initialMaterial.categories)) initialMaterial.categories = ["chemical"];
        const rtn = new Sample(initialMaterial)
        if (!rtn.processChart) rtn.processChart = new ProcessChart()
        rtn.processChart = updateProcessNodesFromSamples(rtn.processChart, [rtn])
        return { data: rtn }
    }, [initialMaterial])

    const { data, status, updateData, validateAll, undoRedo } =
        useFormState({
            initialState,
            validators: invalidators,
            transforms: useTransforms(defsRef),
        })
    const undo = useMemo(
        () => ({ callback: undoRedo.onUndo, name: undoRedo.undoName }),
        [undoRedo.onUndo, undoRedo.undoName],
    )
    const redo = useMemo(
        () => ({ callback: undoRedo.onRedo, name: undoRedo.redoName }),
        [undoRedo.onRedo, undoRedo.redoName],
    )
    // create sampleSet update function
    const updateMaterial = useCallback(
        // @ts-ignore
        (updates, name?: string, replace?: boolean) => {
            updateData(updates, {
                name,
                method: replace ? "replace" : undefined,
            })
        },
        [updateData],
    )

    // create processChart update function
    const updateProcessChart = useCallback(
        // @ts-ignore
        (updates, name?: string, replace?: boolean) => {
            updateData(updates, {
                name,
                path: "processChart",
                method: replace ? "replace" : undefined,
            })
        },
        [updateData],
    )

    const materialSet = useMemo(() => ({ samples: [data] }), [data])

    const updateMaterialSet = useCallback(
        // @ts-ignore
        (updates, name?: string, replace?: boolean) => {
            if (updates.samples?.[0]) {
                updateData(updates.samples[0], {
                    name,
                    method: replace ? "replace" : undefined,
                })
            }
        },
        [updateData],
    )

    return {
        data,
        materialSet,
        status,
        updateData,
        updateMaterial,
        updateMaterialSet,
        updateProcessChart,
        validateAll,
        undo,
        redo,
        selectedNodes,
        setSelectedNodes,
        tableSelected,
        setTableSelected,
    }
}

function makeInvalidators(initMaterial: Sample) {
    return [
        {
            path: "title",
            validator: (title: string | undefined, { saveAs }: any = {}) => {
                if (!title || title === "") return "Name required"
                else if (saveAs && title === initMaterial.title)
                    return "Name must be unique"
            },
        },
        {
            path: "labs",
            validator: checkLabs,
        },
        {
            path: "qid",
            validator: (qid: string | undefined) =>
                qid && !simpleString(qid)
                    ? "A Qid cannot have special characters"
                    : undefined,
        },
    ] as Validator<Sample>[]
}

function useTransforms(defsRef: any) {
    return useMemo<UpdateTransform<Sample>[]>(() => [
        { transform: deleteNodeUpdateTransform },
        { transform: sampleToProcessChartTransform },
        { transform: buildDefsTransform(defsRef) },
        { transform: buildAddCalcProps(defsRef) },
        { transform: updateCalculatedPropsTransform },
    ], [defsRef])
}

function deleteNodeUpdateTransform(
    updated: Sample,
    current: Sample,
    status: any,
    options: any,
) {
    let newSample = updated
    if (
        options?.name === "Delete Node" &&
        updated.processChart &&
        updated.processChart !== current.processChart
    ) {
        newSample = updateSamplesOnNodeDelete(
            [updated],
            [updated],
            updated.processChart,
            current.processChart as ProcessChart | undefined,
        )[0]
    }
    return newSample
}

function sampleToProcessChartTransform(
    updated: Sample,
    current: Sample,
    status: any,
    options: any,
) {
    const sample = updated || current
    updated.processChart = updateProcessNodesFromSamples(
        updated.processChart || current.processChart || new ProcessChart(),
        [sample],
    )
    return updated
}

function buildDefsTransform(defsRef: any) {
    return (
        updated: Sample,
        current: Sample,
        status: any,
        options: any) => {
        if (!defsRef?.current) return updated
        return addDefsToSample(updated, current, defsRef.current.materialDefs, defsRef.current.processDefs, defsRef.current.propertyDefs)
    }
}
function buildAddCalcProps(defsRef: any) {
    return (
        updated: Sample,
        current: Sample,
        status: any,
        options: any) => {
        if (!defsRef?.current) return updated
        updated = addSampleCalProps(updated, current, updated.processChart?.nodes, defsRef.current.propertyDefs)
        return updated
    }
}

function updateCalculatedPropsTransform(
    updated: Sample,
    current: Sample,
    status: any,
    options: any,
) {
    return updateSampleCalculatedProperties(updated, current)
}