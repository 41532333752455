import {useCallback, useMemo, useState} from 'react';
import { matchSorter } from 'match-sorter';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, OutlinedInput } from '@material-ui/core';

import { useQueryAutocomplete } from '../../API/queryHooks';

const useStyles = makeStyles(theme => ({
    input: {
        ...theme.typography.caption,
        backgroundColor: theme.palette.background.paper,
        '& input': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            height: "1.1875em",
        }
    }
}))

function filterOptions(options, {inputValue}){
    return matchSorter(options, inputValue);
}

const staticArray = [];
const labsParams = {Model: "Lab", fields: ["title"], sort: "title"};

/**
 * React Component for array selection
 */
export default function LabsFilterInput({column: { filterValue, setFilter }}){
    const classes = useStyles();
    const [inputValue, setInputValue] = useState("");
    const values = useMemo(() => ({title: inputValue}), [inputValue]);
    const { data: {data = staticArray} = {}, isFetching } = useQueryAutocomplete(values, labsParams, {logName: "Labs Filter"});
    const options = useMemo(() => data.map(d => d.title),[data]);
    const renderInput = useCallback(({InputLabelProps, InputProps, ...params}) => (
        <OutlinedInput
            {...params}
            {...InputProps}
            variant="outlined"
            placeholder="Filter... type to extend list"
            fullWidth
            className={classes.input}
            />
    ), [classes]);
    const value = filterValue?.query || "";
    return (
        <Autocomplete
            options={value === "" ? options.concat([""]) : options}
            filterSelectedOptions={value === ""}
            filterOptions={filterOptions}
            loading={isFetching}
            value={value}
            renderInput={renderInput}
            onBlur={() => {if (value === "") setInputValue("")}}
            onChange={(ev, newVal) => {setFilter((newVal && newVal !== "") ? {query: newVal} : undefined); setInputValue("")}}
            size="small"
            inputValue={inputValue}
            onInputChange={(ev, newVal) => setInputValue(newVal || ev?.target?.value || "")}
            />
    );
}