import React, {useCallback} from 'react';
import { matchSorter } from 'match-sorter'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

function getOptionSelected(options, values){
    return options?.id === values?.id;
}

const defaultKeys = ["label", "title"]
function getKey(option, keys) {
    if (!option) return undefined;
    for (const key of keys) {
        if (option[key]) return option[key];
    }
    return undefined;
}

/**
 * Styled wrapper around material-ui Autocomplete
 * TextField props passed to the TextField through TextFieldProps
 */
function StyledAutocomplete({label,
    placeholder = undefined,
    variant = undefined,
    required = undefined,
    options = undefined,
    value = undefined,
    fullWidth = undefined,
    loading = undefined,
    missingLabel = undefined,
    error = undefined,
    helperText = undefined,
    InputProps = undefined,
    TextFieldProps = undefined,
    keys=defaultKeys,
    groupBy = undefined,
    ...remainingProps 
}){

    const renderInput = useCallback(params => (
        <TextField
            {...params}
            variant={variant || "outlined"}
            label={label}
            placeholder={placeholder}
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            required={required}
            InputProps={{...params.InputProps, ...InputProps, endAdornment: InputProps?.endAdornment ? <>{InputProps?.endAdornment} {params.InputProps.endAdornment}</> : params.InputProps.endAdornment}}
            {...TextFieldProps}
        />
    ), [variant, label, placeholder, fullWidth, error, helperText, required, TextFieldProps, InputProps]);

    const getOptionLabel = useCallback(
        option => getKey(option, keys) || (loading ? "Loading label..." : (missingLabel || "Missing Label..."))
    ,[keys, loading, missingLabel]);

    const filterOptions = useCallback((options, {inputValue}) => matchSorter(options, inputValue, {keys}).sort((a, b) => {
        if (!groupBy || groupBy(a) === groupBy(b)) return 0
        return groupBy(a) < groupBy(b) ? -1 : 1
    }), [groupBy, keys]);

    return (
        <Autocomplete
            options={options}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            value={value}
            filterSelectedOptions
            getOptionSelected={getOptionSelected}
            renderInput={renderInput}
            size="small"
            loading={loading}
            groupBy={groupBy}
            {...remainingProps}
            />
    );
}

export default (StyledAutocomplete);