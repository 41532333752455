import { Schema, schema } from "./schemaUtils"

export class Base {
    id?: string
    creatorId?: string
    creatorName?: string
    timeCreated?: Date
    modifierId?: string
    modifierName?: string
    timeModified?: Date
    tags?: string[]

    static readonly [schema]: Schema<Base> = {
        relationships: {
            creator: { map: "creator", model: "User", toMany: false },
            modifier: { map: "modifier", model: "User", toMany: false },
            tagsRel: { map: "tags", model: "Tag", toMany: true }
        },
        map: {
            id: "id",
            creatorId: "creator_id",
            creatorName: "creator",
            timeCreated: "created",
            modifierId: "modifier_id",
            modifierName: "modifier",
            timeModified: "modified",
            tags: "tags"
        }
    }

}
