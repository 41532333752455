import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
    const portHeight = theme.spacing(2) - (2 * 2);
    const innerPortHeight = portHeight - (2 * 2);
    return ({
        fcPort: {
            display: "flex",
            width: `${portHeight}px`,
            height: `${portHeight}px`,
            borderRadius: `${2 * 2}px`,
            background: "white",
            border: `${2}px solid gray`,
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            "&:hover div": {
                background: theme.palette.primary.main,
            },
        },
        fcPortInner: {
            width: `${innerPortHeight}px`,
            height: `${innerPortHeight}px`,
            cursor: "pointer",
            "&.-highlight": {
                background: theme.palette.primary.main,
            },
        }
    });
});

function Port(props, ref){
    const {config, className, port, isSelected, isHovered, isLinkSelected, isLinkHovered, ...rest} = props;
    const classes = useStyles(port.properties);
    return (
        <div ref={ref} className={clsx(className, classes.fcPort, (isLinkSelected || isLinkHovered || isHovered) && "-highlight", port.type && ("type-" + port.type))} {...rest}>
            <div className={clsx(className, classes.fcPortInner, (isLinkSelected || isLinkHovered || isHovered) && "-highlight", port.type && ("type-" + port.type))}/>
        </div>
    );
}
export default React.forwardRef(Port);