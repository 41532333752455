import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckIcon from "mdi-material-ui/Check"
import AccountHardHatIcon from "mdi-material-ui/AccountHardHat"
import MotionPauseOutlineIcon from "mdi-material-ui/MotionPauseOutline"
import StepConnector from '@material-ui/core/StepConnector';
import ButtonWithSpinner from "../../General/ButtonWithSpinner";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <MotionPauseOutlineIcon />,
    2: <AccountHardHatIcon />,
    3: <CheckIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function getSteps() {
  return ['Queued', 'Running', 'Finished'];
}

function TaskStatusStepper({ taskStatus, stopTask, loading }) {
  const steps = getSteps();
  return <div style={{marginTop: 2}}>
    {stopTask && (
      <div style={{ width: "100%", marginTop: 36, marginBottom: 36, display: 'flex', flexDirection: 'column' }}>
        {/*Remove model*/}
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginLeft: 'auto',
          marginBottom: 15,
        }}>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            spinnerColor="primary"
            onClick={stopTask}
            loading={loading}
          >
            Stop Task
          </ButtonWithSpinner>
        </div>
      </div>
    )}
    <Stepper alternativeLabel activeStep={steps.indexOf(taskStatus)} connector={<ColorlibConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </div>
}

export default React.memo(TaskStatusStepper)