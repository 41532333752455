import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from '@reach/router';

import {
    Switch, FormControlLabel, FormGroup, Typography, Card, Grid, makeStyles
} from '@material-ui/core';

import { Sample } from 'schema/models';
import LoadingOverlay from 'components/General/LoadingOverlay';

import { useQueryMaterial } from '../../API/queryHooks';
import common_styles from '../../styles/common_styles';
import { CompileSet } from '../General/CompileSet';
import  LearnMore, { LearnMoreEnum } from "../General/LearnMore";
import { useLocalForageState } from '../../utils/useLocalforageState';
import TagSelector from '../General/TagSelector';

import MaterialsTable from './MaterialsTable';
import EditMaterial from './EditMaterial';


const MATERIALS_LIBRARY_TITLE = "Sample/Material Library";
const MATERIALS_LIBRARY_INFO = "A sample/material/article/product is represented in Skylab as either" +
    " a (raw) Material (off-the-shelf or intermediates) or a Sample " +
    "(user-generated sample/material/article/product). Samples/Materials can be defined by the " +
    "Processes and Materials used to create it, associated Properties, and Tests conducted on it. ";

const useStyles = makeStyles(common_styles);
const emptyArray = [];

function MaterialLibrary({navigate}) {
    const classes = useStyles();
    const { materialId } = useParams();
    const [openIds, setOpenIds] = useState();
    const [rawOnly, setRawOnly, rawLoading] = useLocalForageState("MaterialLibraryRaw", true);
    const [selectedOnly, setSelectedOnly, selectedLoading] = useLocalForageState("MaterialLibrarySelected", false);
    const { data: material, isLoading } = useQueryMaterial((materialId !== "" && materialId !== "new") ? {id: materialId} : undefined);
    const [tags, setTags, loadingTags] = useLocalForageState("MaterialLibraryTags", emptyArray);
    const defaultMaterial = useMemo(() => new Sample(), [])
    const handleNew = useCallback(() => navigate("new"), [navigate]);
    const handleEdit = useCallback(selected => { 
        if (selected.length === 1) navigate(selected[0].id);
        else if (selected.length){
            setOpenIds(selected.map(sample => sample.id));
        }
    }, [navigate]);
    const handleCloseEdit = useCallback(() => navigate(""), [navigate]);
    const handleTagsChange = useCallback((ev, values) => {
        setTags(values);
    }, [setTags]);

    const openEdit = materialId !== "" && (materialId === "new" || material);

    return (
        <div className={classes.layout}>
            <CompileSet sampleIds={openIds} failRoute="/MaterialLibrary/" onFail={() => setOpenIds(undefined)}/>
            {isLoading && <LoadingOverlay loadingText = "Loading Material" filled />}
            {
            openEdit ?
                <EditMaterial initialMaterial={material || defaultMaterial} onClose={handleCloseEdit}/>
            :<>
                <Card className={classes.paperBody} elevation={3}>
                    <div>
                        <Typography className={classes.cardTitle} gutterBottom>
                            {MATERIALS_LIBRARY_TITLE}
                        </Typography>
                        <Typography className={classes.cardBodyTextEnhanced}>
                            {MATERIALS_LIBRARY_INFO}
                            <LearnMore model={LearnMoreEnum.material}/>
                        </Typography>
                        <FormGroup row>
                            <FormControlLabel label="Only show raw component materials" control={
                                <Switch checked={rawOnly} onChange={(ev)=>setRawOnly(ev.target.checked)} color="primary"/>
                            }/>
                            <FormControlLabel label="Only show selected materials" control={
                                <Switch checked={selectedOnly} onChange={(ev)=>setSelectedOnly(ev.target.checked)} color="primary"/>
                            }/>
                        </FormGroup>
                    </div>
                    <Grid container justifyContent="flex-end" spacing={2} className={classes.selectorContainer}>
                        <Grid item xs={12} md={6}>
                            <TagSelector 
                                label="Tags (Show Materials with these Tags)" 
                                placeholder="Tags (optional)"
                                value={tags} 
                                helperText={undefined}
                                onChange={handleTagsChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={3}>
                        <Grid item xs={12}>
                            <MaterialsTable 
                                category={rawOnly ? "chemical" : undefined} 
                                selectedOnly={selectedOnly}
                                loading={rawLoading || selectedLoading || loadingTags}
                                onEdit={handleEdit}
                                onNew={handleNew}
                                tags={tags}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </>}
        </div>
    );
}

export default React.memo(MaterialLibrary);