import React, { useCallback, useEffect, useState } from "react";
import { useAppStoreKey } from "../../../AppStore";
import { Tooltip, Typography } from "@material-ui/core";
import { aichemyProtoAxios } from "../../../API/mmAxios";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AutoFixIcon from "mdi-material-ui/AutoFix";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import StarIcon from "@material-ui/icons/Star";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import NextIcon from "mdi-material-ui/ChevronDoubleDown";
import TopIcon from "mdi-material-ui/ChevronTripleUp";
import PrevIcon from "mdi-material-ui/ChevronDoubleUp";
import Zoom from "@material-ui/core/Zoom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import common_styles from "../../../styles/common_styles";

const useStyles = makeStyles((theme) => ({
    ...common_styles(theme),
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tooltip: {
        fontSize: "1.2em",
    },
}));

export default function DisplayLintResult({ topAnchorRef, prevRef, nextRef }) {
    const classes = useStyles();

    const [workflow, setWorkflow] = useAppStoreKey("Workflow");
    const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
    // const [lintResults, setLintResults] = useState([])
    // const [linterExpanded, setLinterExpanded] = useState(true)
    let linterExpanded = MSState['AIchemyLintExpand']
    const setLinterExpanded = (value) => setMSState({ ...MSState, AIchemyLintExpand: value })
    let lintResults = MSState['lintResults']
    // const setLintResults = (value) => setMSState({ ...MSState, lintResults: value })
    const [activeStep,] = useAppStoreKey("ActiveStep");
    const [updating, setUpdating] = useState(false);

    const updateLintResults = useCallback(() => {
        const url = `analyze/` + workflow.uuid
        const update_dict = {
            lint: { exclude_rules: MSState.excludedRules }
        }
        const config = {
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }
        aichemyProtoAxios.post(url, JSON.stringify(update_dict), config)
            .then(res => {
                const results = res.data.lint
                // setLintResults(results)
                let NResults = results.filter(r => !r.passed).length
                if (NResults === 0) {
                    setMSState({ ...MSState, nLint: NResults, AIchemyLintExpand: false, lintResults: results })
                } else {
                    setMSState({ ...MSState, nLint: NResults, AIchemyLintExpand: true, lintResults: results })
                }
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                setUpdating(false)
            })
    }, [workflow, setMSState, MSState, setUpdating])

    const refresh = () => {
        if (MSState.excludedRules === []) {
            updateLintResults()
        } else {
            setMSState({ ...MSState, excludedRules: [] })
        }
    };

    useEffect(() => {
        if (activeStep === 1 && !updating) {
            setUpdating(true)
            updateLintResults()
        }
        // only want workflow changes to trigger this, otherwise inf loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, updateLintResults, workflow])

    const allCheckPassed = () => {
        let checkResults = lintResults.map((res) => res.passed)
        return !checkResults.includes(false)
    }

    const removeRule = (name) => () => {
        setMSState({ ...MSState, excludedRules: [...MSState.excludedRules, name] })
    }
    const fixRule = (ruleIdx) => () => {
        let url = lintResults[ruleIdx].solution.url
        const payload = lintResults[ruleIdx].solution.payload
        const method = lintResults[ruleIdx].solution.method
        const config = { headers: { "Content-Type": "application/json; charset=utf-8" }, }

        // generate url
        url = url.replace('{workflow_id}', String(workflow.uuid)).replace('{data_id}', String(workflow.data.uuid))

        // post
        if (method === 'post') {
            aichemyProtoAxios.post(url, JSON.stringify(payload), config).then(res => {
                const res_data = res.data
                if (Object.keys(res_data).indexOf('data') > -1) {
                    // res_data is workflow
                    // Object.keys(wf.data.data_df).forEach(key => wf.data.data_df[key] = JSON.parse(wf.data.data_df[key]))
                    setWorkflow(res_data);
                } else {
                    // res_data is data
                    const data_obj = res_data
                    Object.keys(data_obj.data_df).forEach(key => data_obj.data_df[key] = JSON.parse(data_obj.data_df[key]))
                    setWorkflow({ ...workflow, data: data_obj });
                }

            })
        } else if (method === 'get') {
            console.error('Can not handle GET method')
        }
    }

    const getMessageIcon = (level) => {
        if (level <= 10)
            return <StarIcon htmlColor='#92979E' />
        else if (level <= 50)
            return <WarningIcon htmlColor='#F3E251' />
        else
            return <ErrorIcon color='error' />
    }

    return (
        <>
            <Collapse in={linterExpanded} timeout="auto" collapsedSize={50}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ textAlign: "left", marginTop: 5 }}
                    >
                        Data Checking
                    </Typography>
                    <Tooltip title={<Typography variant="h6">Previous</Typography>} placement="top">
                        <IconButton
                            onClick={() => prevRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                            color="primary"
                            style={{ textAlign: "right", marginTop: 0, marginLeft: 60 }}
                        >
                            <PrevIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography variant="h6">Next</Typography>} placement="top">
                        <IconButton
                            onClick={() => nextRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                            color="primary"
                            style={{ textAlign: "right", marginTop: 0, marginLeft: 12 }}
                        >
                            <NextIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography variant="h6">Top</Typography>} placement="top">
                        <IconButton
                            onClick={() => topAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                            color="primary"
                            style={{ textAlign: "right", marginTop: 0, marginLeft: 12 }}
                        >
                            <TopIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography variant="h6">Rerun Checks</Typography>} placement="top">
                        <IconButton edge="end" aria-label="refresh" onClick={refresh} style={{ marginLeft: 20, padding: 0 }}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: linterExpanded,
                        })}
                        onClick={() => { setLinterExpanded(!linterExpanded) }}
                        aria-expanded={linterExpanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </div>

                <div>
                    <List dense={false}>
                        {lintResults.map((res, idx) => {
                            if (!res.passed) {
                                return <div key={'div_list_' + res.name}>
                                    <ListItem key={'list_' + res.name}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                {getMessageIcon(res.level)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={res.message}
                                            secondary={res.fix ? res.fix : null}
                                        />
                                        <ListItemSecondaryAction>
                                            {res.solution.description && <Tooltip classes={{ tooltip: classes.tooltip }} title={res.solution.description} placement="top" TransitionComponent={Zoom}>
                                                <IconButton key={res.name} edge="end" aria-label="delete" onClick={fixRule(idx)}>
                                                    <AutoFixIcon />
                                                </IconButton>
                                            </Tooltip>}

                                            <IconButton key={res.name} edge="end" aria-label="delete" onClick={removeRule(res.name)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem></div>
                            }
                            else return ''
                        })
                        }
                        {allCheckPassed() && <div style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton>
                                <CheckIcon htmlColor='#4caf50' />
                            </IconButton>
                            <Typography variant="body1" style={{ marginTop: 12 }}>
                                All checks passed!
                            </Typography>
                        </div>}

                    </List>
                </div></Collapse>
        </>
    );
}