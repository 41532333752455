/**
 * @format
 */
import CategoryCell from "../ReactTable/CategoryCell"
import CategoryFilterInput from "../ReactTable/CategoryFilterInput"
import UserFilterInput from "../ReactTable/UserFilterInput"
import DateCell from "../ReactTable/DateCell"
import DateFilterInput from "../ReactTable/DateFilterInput"
import AddInputAmount from "./AddInputAmount"
import AddPropertyValue from "./AddPropertyValue"
import { serializedColumnsFromIds } from "../ReactTable/reactTableUtils"
import NumberFilterInput from "../ReactTable/NumberFilterInput"
import AddInputTag from "./AddInputTag"
import SmilesCell from "../ReactTable/SmilesCell"
import get from "lodash.get"
import AddTestValue from "./AddTestValue"
import AddTestCount from "./AddTestCount"
import LabsFilterInput from "../ReactTable/labsFilterInput"
import DefaultFilterInput from "components/ReactTable/DefaultFilterInput"

export const materialColumns = [
    {
        Header: "Sample Fields",
        id: "sample",
        columns: [
            {
                Header: "Sample Name",
                accessor: "title",
                filterTransform(filter) {
                    return {
                        or: [
                            {
                                name: "title",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type,
                            },
                            {
                                name: "alternateNames",
                                val: filter.value?.query,
                                op: filter.value?.op || filter.value?.type,
                            },
                        ],
                    }
                },
            },
            {
                Header: "Alternate Names",
                accessor: "alternateNames",
                simpleFilter: true,
            },
            {
                Header: "Tags",
                accessor: "tags",
                filterTransform(filter) {
                    return {
                        name: "tagsRel.title",
                        val: filter.value?.query,
                        op: filter.value?.op || filter.value?.type,
                    }
                },
                disableSortBy: true,
            },
            {
                Header: "QID",
                accessor: "qid",
                width: 130,
            },
            {
                Header: "Contributor(s)",
                id: "contributors",
                accessor: data => data.contributors?.join(", "),
                Filter: UserFilterInput,
            },
            {
                Header: "Created",
                accessor: "timeCreated",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Modified",
                accessor: "timeModified",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Notes",
                accessor: "notes",
            },
            {
                Header: "Permissions",
                id: "labs.lab.title",
                accessor: data =>
                    data.labs
                        ?.map(lab => lab.lab.title)
                        .filter((title, i, arr) => arr.indexOf(title) === i)
                        .join(" | "),
                Filter: LabsFilterInput,
            },
        ],
    },
    {
        Header: "Component Fields",
        id: "sample.components",
        columns: [
            {
                Header: "Component Names",
                id: "components.definition.title",
                accessor: data =>
                    data.components
                        ?.map(i => i.definition?.title)
                        .filter(d => d) || [],
                disableSortBy: true,
            },
            {
                Header: "Component Tags",
                id: "components.tagsRel.title",
                accessor: data =>
                    []
                        .concat(
                            ...(data.components
                                ?.map(i => i.tags)
                                ?.filter(t => !!t) || []),
                        )
                        .filter((t, i, tags) => tags.indexOf(t) === i),
                disableSortBy: true,
            },
        ],
        dynamic: [
            {
                id: "components.amount",
                Header: "Component Amounts",
                make: makeInputAmountColumn,
                Component: AddInputAmount,
            },
            {
                id: "components.tags.length",
                Header: "Component Tag Count",
                make: makeInputTagCountColumn,
                Component: AddInputTag,
            },
            {
                id: "components.tags.definition.title",
                Header: "Components With Tag",
                make: makeInputTagColumn,
                Component: AddInputTag,
            },
        ],
    },
    {
        Header: "Process Fields",
        id: "sample.processSteps",
        columns: [
            {
                Header: "Process Step Names",
                id: "processSteps.title",
                accessor: data =>
                    data.processSteps?.map(i => i.title).filter(d => d) || [],
                disableSortBy: true,
            },
        ],
    },
    {
        Header: "Property Fields",
        id: "sample.properties",
        columns: [
            {
                Header: "Available Properties",
                id: "properties.definition.title",
                accessor: data =>
                    data.properties
                        ?.map(i => i.definition?.title)
                        .filter(d => d) || [],
                disableSortBy: true,
            },
        ],
        dynamic: [
            {
                id: "properties.data",
                Header: "Property Values",
                make: makePropertyValueColumn,
                Component: AddPropertyValue,
            },
        ],
    },
    {
        Header: "Test Fields",
        id: "sample.tests",
        columns: [
            {
                Header: "Test Names",
                id: "tests.title",
                accessor: data =>
                    data.tests?.map(i => i.title).filter(d => d) || [],
                disableSortBy: true,
            },
            {
                Header: "Test Instrument Names",
                id: "tests.instrument.title",
                accessor: data =>
                    data.tests
                        ?.map(t => t.instrument?.title)
                        .filter(
                            (test, i, arr) => test && arr.indexOf(test) === i,
                        ) || [],
                disableSortBy: true,
            },
        ],
        dynamic: [
            {
                id: "tests.count",
                Header: "Test Count",
                make: makeTestCount,
                Component: AddTestCount,
            },
            {
                id: "tests.metadata",
                Header: "Test Values",
                make: makeTestValueColumn,
                Component: AddTestValue,
            },
        ],
    },
    {
        Header: "Sample Set",
        id: "sample.sets",
        columns: [
            {
                Header: "Set Names",
                id: "sets.title",
                accessor: data => data.sets?.map(set => set.title) || [],
                disableSortBy: true,
            },
            {
                Header: "Sets Tags",
                id: "sets.tags",
                accessor: data =>
                    data.sets
                        ?.map(set => set.tags)
                        .reduce((prev, curr) => prev.concat(curr || []), []) ||
                    [],
                disableSortBy: true,
            },
        ],
    },
]

export const defaultColumns = serializedColumnsFromIds(
    ["title", "contributors", "timeModified", "notes"],
    materialColumns,
)

/////////// Make Dynamic Column Functions ///////////////////////

function makeInputAmountColumn({ id, title, tags }) {
    const and = [{ name: "definition.id", val: id, op: "eq" }]
    if (tags && tags.length > 0) and.push({ id: "tags", val: tags, op: "list" })
    return {
        Header: `${title}${
            tags && tags.length > 0 ? " - " + tags.join(" ") : ""
        }`,
        id: `components.amount(${id}${tags ? "." + tags.join(".") : ""})`,
        accessor: data =>
            data.components
                ?.filter(
                    i =>
                        i.definition?.id === id &&
                        (!tags ||
                            tags.length === 0 ||
                            tags.every(t => i.tags.indexOf(t) >= 0)),
                )
                .map(i => i.amount),
        type: "number",
        disableSortBy: true,
        Filter: NumberFilterInput,
        filterTransform(filter) {
            return {
                name: "components",
                val: {
                    and: [
                        {
                            name: "amount",
                            val: filter.value?.query,
                            op: filter.value?.op || filter.value?.type,
                        },
                        ...and,
                    ],
                },
            }
        },
    }
}

function makeInputTagCountColumn({ tag }) {
    return {
        Header: `${tag} - Count`,
        id: `components.tags.length(${tag})`,
        accessor: data =>
            data.components?.filter(i => i.tags?.indexOf(tag) >= 0).length,
        type: "number",
        width: 100,
        disableSortBy: true,
        disableFilters: true,
        disableResizing: true,
    }
}

function makeInputTagColumn({ tag }) {
    return {
        Header: `Component with ${tag}`,
        id: `components(tag(${tag})).definition.title`,
        accessor: data =>
            data.components
                ?.filter(i => i.tags?.indexOf(tag) >= 0)
                .map(i => i.definition?.title)
                .filter(t => !!t),
        disableSortBy: true,
        filterTransform(filter) {
            return {
                name: "components",
                val: {
                    and: [
                        {
                            name: "definition.title",
                            val: filter.value?.query,
                            op: filter.value?.op || filter.value?.type,
                        },
                        {
                            name: "tags",
                            val: tag,
                            op: "list",
                        },
                    ],
                },
            }
        },
    }
}

function makePropertyValueColumn({ id, title, type, source, allowedValues }) {
    const params = {}
    switch (type) {
        case "smiles":
            params.Cell = SmilesCell
            break
        case "number":
            params.Filter = NumberFilterInput
            params.type = "number"
            break
        case "categorical":
            params.Filter = CategoryFilterInput
            params.Cell = CategoryCell
            params.categories = allowedValues?.map(v => ({
                value: v,
                label: v,
            }))
            break
        default:
    }
    return {
        Header: `${title}`,
        id: `properties.data(${id})`,
        accessor: data =>
            data.properties
                ?.filter(p => p.definition?.id === id)
                .map(p => p.data),
        disableSortBy: true,
        disableFilters: source !== "mm",
        ...params,
        filterTransform(filter) {
            return {
                name: "properties",
                val: {
                    and: [
                        {
                            name: "data",
                            val: filter.value?.query,
                            op: filter.value?.op || filter.value?.type,
                        },
                        {
                            name: "definition.id",
                            val: id,
                            op: "eq",
                        },
                    ],
                },
            }
        },
    }
}

function makeTestValueColumn({
    metaField,
    instrumentTitles,
    instrumentIds,
    type,
}) {
    return {
        Header: `${metaField.substring(12)} - ${instrumentTitles.join("| ")}`,
        id: `tests${metaField}`,
        Filter: type === "number" ? NumberFilterInput : DefaultFilterInput,
        accessor: data => {
            const tests = instrumentIds?.length
                ? data.tests?.filter(t =>
                      instrumentIds.includes(t.instrument?.id),
                  )
                : data.tests
            if (!tests) return null
            return [
                ...new Set(
                    tests.map(t => get(t, metaField)).filter(v => v || v === 0),
                ),
            ]
        },
        disableSortBy: true,
    }
}

function makeTestCount({ instrumentIds, instrumentTitles }) {
    const inst = instrumentIds?.length > 0
    return {
        Header: inst
            ? `Test Count - ${instrumentTitles.join("; ")}`
            : "Test Count",
        id: inst ? `tests.count.${instrumentTitles.join(":")}` : "tests.count",
        accessor: inst
            ? data =>
                  data.tests?.filter(test =>
                      instrumentIds.includes(test.instrument?.id),
                  )?.length
            : data => data.tests?.length,
        type: "number",
        width: 100,
        disableSortBy: true,
        disableFilters: true,
        disableResizing: true,
    }
}
