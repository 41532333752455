import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

import { robustType } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
    labelValue: {
        fontWeight: 'inherit',
        color: theme.palette.text.secondary,
    }
}));


/**
 * Wrapper around material-ui TreeView for displaying an object as a recursive tree
 * @param {*} props forwarded to TreeView 
 * @param {*} props.payload the object to be recursively displayed
 * @param {*} props.onSelect optional callback for when an element is selected
 * @param {string} props.root the label used for the root of the tree, defaults to "object"
 */
export const ObjectTree = ({ payload, onSelect=undefined, root="object", ...rest }) => {
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={onSelect}
            multiSelect={false}
            {...rest}
        >
            <RecursiveTreeItem keyvalue={payload} keyname={root} parentPath={[]} />
        </TreeView>
    )
};

export const RecursiveTreeItem = ({ keyname, keyvalue, parentPath }) => {
    const valueType = robustType(keyvalue);
    const path = parentPath.concat(keyname);

    const childNodes = useMemo(() => {
        if (valueType === "object") {
            return Object.keys(keyvalue).filter(k => keyvalue[k] !== undefined).map((key, i) => <RecursiveTreeItem key={i} keyname={key.replace(/\./g,"\\.")} keyvalue={keyvalue[key]} parentPath={path} />)
        }
        if (valueType === "array") {
            return keyvalue.filter(v => v !== undefined).map((elem, index) => <RecursiveTreeItem key={index} keyname={index} keyvalue={elem} parentPath={path} />)
        }
        return null
    }, [keyvalue, path, valueType]);

    const labelValue = ["number", "string", "boolean", "null", "undefined"].includes(valueType) ? JSON.stringify(keyvalue) : null;
    const nodeId = path.join(".");

    return (
        <TreeItem 
            nodeId={nodeId} 
            key={nodeId} 
            label={<LinkedLabel name={keyname} value={labelValue} />}
        >
            {childNodes}
        </TreeItem>
    )
};

export const LinkedLabel = ({ name, value, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.labelRoot}>
            <Typography variant="body2" className={classes.labelText}>
                {name}
            </Typography>
            {value ?
                <Typography variant="caption" className={classes.labelValue}>
                    {value}
                </Typography> : null}
        </div>
    )
}