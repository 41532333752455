import Handsontable from "handsontable";
import { useState, useEffect } from "react";
import HotTable from "../../HoT/StyledHoT";


export default function DataSelectTable({ items, columnHeaders, onSelectionChange, minRows = 10, height = 500, hotProps = {} }) {
  const [selected, setSelected] = useState([]); // indexes (int)
  const [data, setData] = useState([]);
  useEffect(() => {
    let result = [];
    items.forEach((item) => {
      let row = [];
      columnHeaders.forEach((columnDef) => {
        if (item[columnDef.type] !== undefined) {
          row.push(item[columnDef.type])
        }
      })
      result.push(row);
    })
    setData(result)
  }, [items, columnHeaders])

  // render a hover tooltip for this cell
  function cellRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    let len = instance.getDataAtCell(row, col)?.length
    if (len > 0) {
      td.innerHTML = '<div title="' + td.innerHTML + '">' + td.innerHTML.slice(0, len) + '</div>'
    }
  }

  // setup specific columns to have the tooltip renderer
  function cellFunction(row, col) {
    let cellProperties = {};
    if (columnHeaders[col].type === "name") {
      cellProperties.renderer = cellRenderer
    }
    return cellProperties;
  }

  // handle changes on selection
  function onSelectionEnd(row, col, row2, col2, selectionLayerLevel) {
    let base = []
    if (selectionLayerLevel !== 0) {
      // don't reset the selected cells
      base = [...selected]
    }
    let rowStart = Math.min(row, row2)
    let rowEnd = Math.min(Math.max(row, row2), items.length - 1);
    if (rowStart === rowEnd && base.indexOf(rowStart) === -1) {
      base.push(rowStart)
    } else {
      for (let i = rowStart; i <= rowEnd; i++) {
        if (base.indexOf(i) === -1)
          base.push(i)
      }
    }
    base.sort()
    if (base !== selected) {
      setSelected(base)
      if (onSelectionChange !== undefined) {
        onSelectionChange(base)
      }
    }
  }

  return (
    <HotTable
      data={data.length > 0 ? data : [[""]]}
      colHeaders={columnHeaders.map(col => col.displayName)}
      rowHeaders={true}
      readOnly={true}
      height={height}
      stretchH="last"
      minRows={minRows}
      afterSelectionEnd={onSelectionEnd}
      settings={{ outsideClickDeselects: true }}
      cells={cellFunction}
      root="hot"
      style={{
        fontSize: "smaller",
        fontFamily: "Roboto"
      }}
      selectionMode="multiple"
      {...hotProps}
    />
  )
}