import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { makeStyles } from "@material-ui/core"

import DragProcessNodePane from "./DragProcessNodePane"
import ProcessFlowChart from "./ProcessFlowChart"
import {
    flowChartFromProcessChart, updateColumnsFromProcessNodes, updateFlowChartFromProcessChart,
    updateFlowChartHighlighted, updateFlowChartSelected, updateProcessChartFromFlowchart
} from "./processChartUtils"
import { NodeProperties } from "components/General/NodeProperties"
import { useAppStoreKey } from "AppStore"
import { deepEqual } from "utils/utils"

const useStyles = makeStyles(theme => ({
    chartContainer: {
        display: "flex",
        flexDirection: "column",
        flex: "1 0",
        height: "100%",
        overflow: "hidden",
    },
    chart: {
        background: theme.palette.type === "dark" ? theme.palette.grey[600] : theme.palette.grey[200],
        borderRadius: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        flex: "1 0",
    },
    nodeInfo: {
        paddingTop: theme.spacing(1),
    },
    infoPlaceholder: {
        height: 48,
        width: "100%",
        textAlign: "center",
    },
    flowChartHeader: {
        borderRadius: theme.spacing(1, 1, 0, 0),
        background: theme.palette.grey[200],
    },
    alignRight: {
        textAlign: "right",
    },
    buttonContainer: {
        paddingRight: theme.spacing(2),
    }
}))

export default function ProcessFlowChartPane({
    readOnly,
    processChart,
    selectedNodes,
    onChangeProcessChart,
    onChangeSelectedNodes,
    onChangeNode,
    materialDefs,
    processDefs,
    propertyDefs,
    initKey = "InitSetFlowChart",
    highlightedNodes = undefined,
    sampleSetId = undefined
}) {
    const classes = useStyles()
    const [cachedState, setCachedState] = useAppStoreKey(initKey)

    const [flowChart, setFlowChart] = useState(() => (processChart && cachedState?.flowChart && deepEqual(processChart, cachedState.processChart)) ? cachedState.flowChart : flowChartFromProcessChart(processChart))
    const stateRef = useRef({ flowChart, processChart })
    useEffect(() => { stateRef.current = { flowChart, processChart } })
    useEffect(() => () => setCachedState(stateRef.current), [setCachedState])

    // update flowchart with changes in instanceGroups or selection
    const lastProcessChartRef = useRef(processChart)
    const lastSelectedNodesRef = useRef()
    const lastHighlightNodesRef = useRef()
    useEffect(() => {
        const updatedProcessChart = lastProcessChartRef.current !== processChart
        const updatedSelectedNodes = lastSelectedNodesRef.current !== selectedNodes
        const updatedHighlightedNodes = lastHighlightNodesRef.current !== highlightedNodes
        if (updatedProcessChart || updatedSelectedNodes || updatedHighlightedNodes) {
            setFlowChart(flowChart => {
                let newChart = flowChart
                if (updatedProcessChart) newChart = updateFlowChartFromProcessChart(processChart, newChart)
                if (updatedSelectedNodes) newChart = updateFlowChartSelected(newChart, selectedNodes)
                if (updatedHighlightedNodes) newChart = updateFlowChartHighlighted(newChart, flowChart, highlightedNodes)
                return newChart
            })
            lastProcessChartRef.current = processChart
            lastSelectedNodesRef.current = selectedNodes
            lastHighlightNodesRef.current = highlightedNodes
        }
    }, [highlightedNodes, processChart, selectedNodes])

    const handleChartChange = useCallback((newChart, source) => {
        setFlowChart(newChart)
        // check for changes that can impact processChart
        if (
            source === "onCanvasDrop" ||
            source === "onLinkComplete" ||
            source === "onDeleteKey" ||
            source === "delete"
        ) {
            let newProcessChart = updateProcessChartFromFlowchart(newChart, processChart)
            let updateSource
            switch (source) {
                case "onCanvasDrop":
                    updateSource = "Add Node"
                    break
                case "onLinkComplete":
                    updateSource = "Add Link"
                    break
                case "onDeleteKey":
                case "delete":
                    updateSource = "Delete Node"
                    const newCol = updateColumnsFromProcessNodes(newProcessChart.columns, newProcessChart.nodes, materialDefs, processDefs, propertyDefs)
                    if (newCol !== newProcessChart.columns) {
                        if (newProcessChart === processChart) newProcessChart = { ...newProcessChart }
                        newProcessChart.columns = newCol
                    }
                    break
                default:
                    updateSource = "Chart Update"
            }
            if (newProcessChart !== processChart) {
                lastProcessChartRef.current = newProcessChart
                onChangeProcessChart(newProcessChart, updateSource)
            }
        }
        // check for changes in selected nodes
        if ((newChart.selected?.type === "node" && !selectedNodes.includes(newChart.selected.id)) ||
            Object.keys(newChart.selected?.nodes || {}).length !== selectedNodes?.length ||
            !selectedNodes.every(id => newChart.selected.nodes?.[id])) {
            const newSelected = Object.keys(newChart.selected?.nodes || {})
            if (newChart.selected?.type === "node") {
                const index = newSelected.indexOf(newChart.selected.id)
                if (index >= 0) {
                    if (index !== 0) {
                        newSelected[index] = newSelected[0]
                        newSelected[0] = newChart.selected.id
                    }
                }
                else {
                    newSelected.unshift(newChart.selected.id)
                }
            }
            lastSelectedNodesRef.current = newSelected

            onChangeSelectedNodes(newSelected)
        }

    }, [materialDefs, onChangeProcessChart, onChangeSelectedNodes, processChart, processDefs, propertyDefs, selectedNodes])

    const config = useMemo(() => ({ materialDefs, processDefs, propertyDefs }), [materialDefs, processDefs, propertyDefs])

    return (
        <div className={classes.chartContainer}>
            <div className={classes.chart} >
                <DragProcessNodePane readOnly={readOnly} processChart={processChart} materialDefs={materialDefs} processDefs={processDefs} propertyDefs={propertyDefs} />
                <ProcessFlowChart readOnly={readOnly} chart={flowChart} onChange={handleChartChange} config={config} sampleSetId={sampleSetId} />
            </div>
            {!readOnly && flowChart &&
                <div className={classes.nodeInfo}>
                    <NodeProperties
                        selectedNodes={selectedNodes}
                        processChart={processChart}
                        materialDefs={materialDefs}
                        processDefs={processDefs}
                        propertyDefs={propertyDefs}
                        onChangeProcessChart={onChangeProcessChart}
                        onChangeNode={onChangeNode}
                    />
                </div>}
        </div>
    )
}
