import React, { useEffect, useRef, useState, useCallback, useMemo } from "react"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useSnackbar } from "notistack"
import { useAppStoreKey } from "../../../../AppStore";
import common_styles from "../../../../styles/common_styles"
import MLModelBase from "./MLModelBase";
import useTrainModel from "../useTrainModel";

const useStyles = makeStyles(common_styles);
const set_points = [
  { seconds: 60, total_time: '1 min', time_per_iteration: '15 sec' }, // 4:1
  { seconds: 300, total_time: '5 mins', time_per_iteration: '1 min' }, // 5:1
  { seconds: 600, total_time: '10 mins', time_per_iteration: '2 mins' }, // 5:1
  { seconds: 1800, total_time: '30 mins', time_per_iteration: '5 mins' }, // 6:1
  { seconds: 3600, total_time: '1 hour', time_per_iteration: '10 mins' }, // 6:1
  { seconds: 7200, total_time: '2 hour', time_per_iteration: '15 mins' }, // 8:1
]

function AutoMLReg({ handleRemovePlot, modelKey }) {

  const classes = useStyles();
  const modelName = "AutoML Regression";
  const modelDescription = "Auto Machine Learning (AutoML) automatically apply hyper-parameter tuning using Bayesian " +
    "optimization methods over 15 classifiers, 14 feature preprocessing methods, and 4 data preprocessing methods. If " +
    "you don't have a preference in model selection, we recommend using AutoML. However, it might take a while to run. "

  const [workflow,] = useAppStoreKey("Workflow");
  const [isLoading,] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const { TrainModel } = useTrainModel({ enqueueSnackbar })
  const currentComponent = useRef(true)
  // record if the component is mounted
  useEffect(() => () => currentComponent.current = false, [])

  const getValidParams = useCallback(() => {
    const active_sheet = workflow.data.active_sheet
    const output_cols = workflow.data.info[active_sheet].output_cols
    const min_time = output_cols === undefined ? 0 : output_cols.length * 30
    const points = set_points.filter((p) => p.seconds > min_time)
    let validParams = {
      "total_time": points.map((p) => p.total_time)
    }
    return validParams
  }, [workflow])
  const [params, setParams] = useState({
    "total_time": getValidParams().total_time[0]
  })  // default values

  const handleParamChange = (paramName) => (event) => {
    let newParam = {}
    newParam[paramName] = event.target.value
    setParams({ ...params, ...newParam })
  }

  const modelParams = useMemo(() => {
    const points = set_points.filter((p) => p.total_time === params.total_time)
    return {
      AutoML_regression_model: {
        kwargs: {
          params: {
            total_time: points[0].total_time,
            time_per_iteration: points[0].time_per_iteration
          }
        }
      }
    }
  }, [params])

  return (
    <>
      <MLModelBase
        modelName={modelName}
        modelDescription={modelDescription}
        handleTrainModel={TrainModel(modelParams, modelName)}
        handleRemovePlot={handleRemovePlot}
        isLoading={isLoading}
        modelKey={modelKey}
      >
        {Object.keys(getValidParams()).map((paramName) => {
          return (
            <div key={'div_' + paramName} style={{ marginLeft: 36 }}>
              <FormControl key={'FormControl_' + paramName} component="fieldset" className={classes.formControl}>
                <InputLabel key={'InputLabel_' + paramName} id={paramName + "select-label"}>{paramName}</InputLabel>
                <Select
                  key={'Select_' + paramName}
                  labelId={paramName + "select-label"}
                  id={paramName + "select-id"}
                  value={params[paramName]}
                  onChange={handleParamChange(paramName)}
                >
                  {getValidParams()[paramName].map((paramOption) => {
                    return (
                      <MenuItem key={paramName + '_' + paramOption} value={paramOption}>{paramOption}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          );
        })}
      </MLModelBase>
    </>
  );
}

export default React.memo(AutoMLReg);