import axios from 'axios'

const baseURL = process.env.REACT_APP_MM_API_URL
export const mmAxios = axios.create({ baseURL: baseURL })
export const emAxios = axios.create({ baseUrl: process.env.REACT_APP_EM_API_URL })
export const gatewayAxios = axios.create({ baseURL: process.env.REACT_APP_GATEWAY_AUTH_URL })
export const aichemyProtoAxios = axios.create({ baseURL: process.env.REACT_APP_AICHEMY_PROTO_API_URL })
export const mbxAxios = axios.create({ baseURL: process.env.REACT_APP_MBX_API_URL })

const MAX_CONCURRENT_REQUESTS = 20
let pendingRequests = 0
let pausedRequests = []

mmAxios.interceptors.request.use(async config => {
    pendingRequests++
    if (pendingRequests > MAX_CONCURRENT_REQUESTS) {
        return new Promise(resolve => {
            pausedRequests.push(() => resolve(config))
        })
    }
    return config
})

mmAxios.interceptors.response.use(async res => {
    pendingRequests--
    if (pausedRequests.length > 0) {
        const resolve = pausedRequests.shift()
        resolve()
    }
    return res
}, error => {
    pendingRequests--
    if (pausedRequests.length > 0) {
        const resolve = pausedRequests.shift()
        resolve()
    }
    return Promise.reject(error)
})