import React, { useState } from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default function ColumnMultiSelectField({ label, value, setValue, headers }) {
  const [inputValue, setInputValue] = useState("");

  const allSelected = headers.length === value.length;
  const handleToggleOption = value =>
    setValue(value);
  const handleClearOptions = () => setValue([]);
  const getOptionLabel = option => `${option}`;
  const handleSelectAll = isSelected => {
    if (isSelected) {
      if(inputValue.length > 0) {
        setValue(value => [...value, ...headers.filter((header) => { return header.toLowerCase().match(new RegExp(inputValue.toLowerCase(), 'g'))})]);
        setValue(value => [...new Set(value)]); // remove possible duplicates
      } else {
        setValue(headers);
      }
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, value, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (value.find(option => option === "Select All")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(value);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option === "Select All" // To control the state of 'Select All' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };

  const getOptionSelected = (option, anotherOption) => {
    return option === anotherOption;
  }

  const filter = createFilterOptions();

  return (
    <>
      <br />
      <Autocomplete
        multiple
        size="small"
        options={headers}
        value={value}
        inputValue={inputValue}
        onClose={() => setInputValue('')}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        limitTags={6}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [ "Select All", ...filtered];
        }}
        onChange={handleChange}
        renderOption={optionRenderer}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label={label}
            placeholder="Search"
          />
        )}
      />
    </>
  );
}