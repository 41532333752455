import { Link } from '@material-ui/core';

export enum LearnMoreEnum {
    material = 'material',
    property = "property",
    sampleSet = "sample_set",
    process = "process",
    instrument = "instrument",
    test = "test"
}

export type LearnMoreType = {
    model: LearnMoreEnum
}


const LearnMore = (content: LearnMoreType) => {
    /*
        Map "Learn More" links to their respective model.
    */
    const { model } = content;
    let link = "https://skydrive3m-my.sharepoint.com/personal/a18w4zz_mmm_com/_layouts/15/Doc.aspx?sourcedoc={d633fae6-e437-49ab-a1af-ce46515948dc}&action=view&wd=target%28Skylab%20Overview.one%7Cb254bb44-a239-45c9-81a3-7d898987428b%2FOverview%7Ccee2dd0d-ade5-4a75-98ce-67ae1085d69b%2F%29&wdorigin=703"
    
    // if (model === LearnMoreEnum.material) link = "https://skydrive3m-my.sharepoint.com/personal/aawprzz_mmm_com/_layouts/15/Doc.aspx?sourcedoc=%7b31d2087a-29fc-484a-b35e-e0a7cc31d670%7d&action=view&wd=target%28Skylab%20Overview.one%7C97b0c7b7-bbaf-4f58-ada0-75e21bd5fcd2%2FOverview%7Ccee2dd0d-ade5-4a75-98ce-67ae1085d69b%2F%29&wdorigin=703"
    // if (model === LearnMoreEnum.property) link = "https://skydrive3m-my.sharepoint.com/personal/aawprzz_mmm_com/_layouts/15/Doc.aspx?sourcedoc=%7b31d2087a-29fc-484a-b35e-e0a7cc31d670%7d&action=view&wd=target%28Skylab%20Overview.one%7C97b0c7b7-bbaf-4f58-ada0-75e21bd5fcd2%2FOverview%7Ccee2dd0d-ade5-4a75-98ce-67ae1085d69b%2F%29&wdorigin=703"
    // if (model === LearnMoreEnum.sampleSet) link = "https://skydrive3m-my.sharepoint.com/personal/aawprzz_mmm_com/_layouts/15/Doc.aspx?sourcedoc=%7b31d2087a-29fc-484a-b35e-e0a7cc31d670%7d&action=view&wd=target%28Skylab%20Overview.one%7C97b0c7b7-bbaf-4f58-ada0-75e21bd5fcd2%2FOverview%7Ccee2dd0d-ade5-4a75-98ce-67ae1085d69b%2F%29&wdorigin=703"
    // if (model === LearnMoreEnum.process) link = "https://skydrive3m-my.sharepoint.com/personal/aawprzz_mmm_com/_layouts/15/Doc.aspx?sourcedoc=%7b31d2087a-29fc-484a-b35e-e0a7cc31d670%7d&action=view&wd=target%28Skylab%20Overview.one%7C97b0c7b7-bbaf-4f58-ada0-75e21bd5fcd2%2FOverview%7Ccee2dd0d-ade5-4a75-98ce-67ae1085d69b%2F%29&wdorigin=703"
    if (model === LearnMoreEnum.test) link = "https://skydrive3m-my.sharepoint.com/personal/a18w4zz_mmm_com/_layouts/15/Doc.aspx?sourcedoc={d633fae6-e437-49ab-a1af-ce46515948dc}&action=view&wd=target%28Creating%20Tests.one%7C24f834af-6c7e-415f-8a19-e8c33c2452c6%2FOverview%7C05329fa6-65cc-4045-af54-e81770bd72d1%2F%29&wdorigin=703"
    return (
        <div>
            <b>
                <u>
                    <Link
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        >
                        Learn More
                    </Link>
                </u>
            </b>
        </div>
    )
}
export default LearnMore;