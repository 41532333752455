import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import HotTable from "../../HoT/StyledHoT";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from "notistack";
import common_styles from '../../../styles/common_styles';
import { useAppStoreKey } from "../../../AppStore";
import { aichemyProtoUpdateData, aichemyProtoUpdateWorkflow, data_to_list } from "../utils";
import NextIcon from 'mdi-material-ui/ChevronDoubleDown'
import TopIcon from 'mdi-material-ui/ChevronTripleUp'
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import { colorMap } from "../const";
import Legend from "./Legend";
import Handsontable from "handsontable";
import RenameColumnNamesDialog from "../Dialogs/RenameColumnNamesDialog";

export const useStyles = makeStyles((theme) => ({
  ...common_styles(theme),
  htRoot: { // for handsontable (hot) overrides
    '& tr': {
      background: theme.palette.background.paper
    },
    '& th': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["200"],
      color: theme.palette.text.primary,
    },
    '& .htPlaceholder': {
      color: theme.palette.text.secondary,
    },
    '& tbody th.ht__highlight, thead th.ht__highlight': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["500"] : theme.palette.grey["300"]
    },
    '& td': {
      background: theme.palette.background.paper,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    '& td.noWrap': {
      whiteSpace: "nowrap",
    },
    '& .handsontableInput': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    '& .handsontable.listbox .ht_master table': {
      background: theme.palette.background.paper,
    },
    '& .handsontable.listbox tr td.current, .handsontable.listbox tr:hover td': {
      background: theme.palette.action.hover,
    },
    '& .htMobileEditorContainer': {
      background: theme.palette.background.paper,
    },
    '& .htDimmed': {
      color: theme.palette.text.secondary,
    },
    '& .htSubmenu :after': {
      color: theme.palette.text.secondary,
    },
    '& td.currentRow': {
      backgroundColor: theme.palette.primary.main + "10",
    },
    '& td.outputCell': {
      backgroundColor: colorMap.outputColumns,
      color: "black"
    },
    '& td.inputCell': {
      backgroundColor: colorMap.inputColumns,
      color: "black"
    },
    '& td.infoCell': {
      backgroundColor: colorMap.infoCells,
      color: "black"
    },
    '& td.warningCell': {
      backgroundColor: colorMap.warningCells,
      color: "black"
    },
    '& td.errorCell': {
      backgroundColor: colorMap.errorCells,
      color: "black"
    },
    '& td.plainCell': {
      backgroundColor: "#ffffff",
      color: "black"
    },
    '& td.innerColHeader': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
      color: theme.palette.text.primary,
    },
    '& td.innerColSubHeader': {
      backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
    },
  },
}));

function EditData({ topAnchorRef, nextRef }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles(); // {selectedFileSheet, allData, sourceId, fileSheets};
  const csvLink = useRef();
  let hotTableComponent = React.createRef();
  const [showRenameColBtn, setShowRenameColBtn] = useState(false)
  const [selectedCol, setSelectedCol] = useState('')
  const [renameButtonText, setRenameButtonText] = useState('Rename Column')
  const [showRenameColInput, setshowRenameColInput] = useState(false)
  const [selectingCell, setSelectingCell] = useState(false)
  const [hoveringRenameBtn, setHoveringRenameBtn] = useState(false)
  const [newColName, setNewColName] = useState('')
  const [HotData, setHotData] = useState({ data: [], headers: [] })
  const [csvData, setCSVData] = useState({ data: [], filename: 'download.csv' })
  const [dataChanged, setDataChanged] = useState(false)
  const [MSState,] = useAppStoreKey('MixingStudioState')
  const [openRenameCol, setOpenRenameCol] = useState(false)
  let lintResults = MSState['lintResults']
  let lintLocs = useMemo(() => {
    if (!lintResults) return {}
    let infoLocs = []
    let warningLocs = []
    let errorLocs = []
    for (let i = 0; i < lintResults.length; i++) {
      let lintResult = lintResults[i]
      if (!lintResult.passed) {
        if (lintResult.level <= 10) {
          infoLocs.push(...lintResult.location)
        } else if (lintResult.level <= 50) {
          warningLocs.push(...lintResult.location)
        } else {
          errorLocs.push(...lintResult.location)
        }
      }
    }
    return { infoLocs, warningLocs, errorLocs }
  }, [lintResults])

  const [workflow, setWorkflow] = useAppStoreKey("Workflow");
  const getDataHeaders = useCallback(() => {
    let selectedSheet = workflow?.data ? workflow.data.active_sheet : ''
    let allData = workflow?.data ? workflow.data.data_df[selectedSheet] : ''
    let dataHeaders = []
    if (allData) {
      dataHeaders = data_to_list(allData).colNames
    }
    return dataHeaders
  }, [workflow])

  const [modifiedColHeaders, setModifiedColHeaders] = useState(getDataHeaders())

  useEffect(() => setModifiedColHeaders(getDataHeaders()), [getDataHeaders])

  const parseHotValue = (val) => {
    if (typeof (val) === 'number') {
      return val
    } else {
      // string or object (null)
      if (!val) {
        // '' or null
        return val  // '' or null -> '' or null
      } else {
        // 'A' or '4'
        if (isNaN(val)) {
          // 'A'
          return val  // 'A' -> 'A'
        }
        else {
          return + val
        }  // '4' -> 4
      }
    }
  }

  const formatHotData = (rawData, newHeaders = '') => {
    // schema of result:
    // {
    //   sheet_name: 'new_sheet',
    //   data: {
    //        "col0":{"0":1,"1":2},
    //        "col1":{"0":2,"1":3},
    //        "col2":{"0":3,"1":4}
    //  }
    // }
    const currentSheetName = workflow.data.active_sheet
    let currentHeaders = []
    if (newHeaders) {
      currentHeaders = [...newHeaders]
    } else {
      currentHeaders = [...rawData.headers]
    }
    let result = { sheet_name: currentSheetName, data: {} }
    // add headers to result
    currentHeaders.forEach(item => {
      result.data[item] = {}
    })
    // add values, remove null row, convert string to number
    rawData.data.forEach((row, rowIdx) => {
      const row_unique = row.filter((v, i, a) => a.indexOf(v) === i)
      if (!(row_unique.length === 1 && !row_unique[0])) {
        row.forEach((value, colIdx) => {
          result.data[currentHeaders[colIdx]][String(rowIdx)] = parseHotValue(value)
        })
      }
    })
    return result
  }

  let handleSaveData = (ev, newHeaders = '') => {
    const formatedData = formatHotData(HotData, newHeaders)
    aichemyProtoUpdateData(formatedData, workflow, setWorkflow).catch(err => {
      enqueueSnackbar("Failed to update data", { variant: "error" });
      console.error(err)
    })
    setDataChanged(false)
  };

  let handleCancel = () => {
    setDataChanged(false)
    setWorkflow(workflow)
    // force update
    allDataToHotTableData(workflow.data.data_df[workflow.data.active_sheet])
  };

  let handleCancelRenameCols = () => {
    setshowRenameColInput(false)
    setRenameButtonText('Rename Column')
    setShowRenameColBtn(false)
  }

  const getData = useCallback(() => {
    const data = workflow.data;
    const selected_sheet = workflow.data.active_sheet
    if (!data || !selected_sheet || !data.info[selected_sheet].input_cols) return {}
    return { data: data, selected_sheet: selected_sheet }
  }, [workflow])

  const originalColumnOrdering = useMemo(() => {
    let { data, selected_sheet } = getData();
    if (!data) return { input: [], output: [] }
    const inputCols = data.info[selected_sheet].input_cols
    const outputCols = data.info[selected_sheet].output_cols
    let inputColNames
    let outputColNames
    if (inputCols) {
      inputColNames = [...inputCols]
    } else {
      inputColNames = []
    }

    if (outputCols) {
      outputColNames = [...outputCols]
    } else {
      outputColNames = []
    }

    const allCols = data.info[selected_sheet].all_headers
    return {
      input: inputColNames.map(colName => allCols.indexOf(colName)),
      output: outputColNames.map(colName => allCols.indexOf(colName)),
      allCols: allCols
    }
  }, [getData])

  // add lint error message
  const cellComments = useMemo(() => {
    // [{row: 1, col: 1, comment: {value: 'Some comment'}},]
    if (!lintResults) return []
    let all_results = {}
    // get all headers
    const all_headers = workflow.data.info[workflow.data.active_sheet].all_headers
    lintResults.forEach(result => {
      if (!result.passed && result.location) {
        result.location.forEach(cellLocation => {
          const col_name = cellLocation.col_name
          const col_idx = all_headers.indexOf(col_name)
          let row_idx = cellLocation.row_idx
          if (!row_idx) {
            workflow.data.data_df[workflow.data.active_sheet].forEach((d, idx) => {
              if (Object.keys(all_results).indexOf(String(idx)) === -1) {
                all_results[idx] = {}
              }
              if (Object.keys(all_results[idx]).indexOf(String(col_idx)) === -1) {
                all_results[idx][col_idx] = [result.message]
              }
              else {
                all_results[idx][col_idx].push(result.message)
              }
              // all_results.push({row: idx, col: col_idx, comment: {value: result.message}})
            })
          } else {
            row_idx.forEach(row => {
              if (Object.keys(all_results).indexOf(String(row)) === -1) {
                all_results[row] = {}
              }
              if (Object.keys(all_results[row]).indexOf(String(col_idx)) === -1) {
                all_results[row][col_idx] = [result.message]
              }
              else {
                all_results[row][col_idx].push(result.message)
              }
              // all_results.push({row: row - 1, col: col_idx, comment: {value: result.message}})
            })
          }
        })
      }
    })
    return all_results
  }, [lintResults, workflow])



  const cellStyle = useMemo(() => {

    function cellRenderer(instance, td, row, col, prop, value, cellProperties) {
      Handsontable.renderers.TextRenderer.apply(this, arguments);
      const message = cellComments[row] && cellComments[row][col] ? cellComments[row][col].join('\n') : ''
      if (message) {
        let content = instance.getDataAtCell(row, col)
        if (content === null || content === undefined) {
          td.innerHTML = '<div title="' + message + '">&nbsp;</div>'
        } else {
          td.innerHTML = '<div title="' + message + '">' + td.innerHTML + '</div>'
        }
      }

    }

    const inputIdx = originalColumnOrdering.input
    const outputIdx = originalColumnOrdering.output
    // get all headers
    const all_headers = workflow.data.info[workflow.data.active_sheet].all_headers

    // get all lint locations
    const { infoLocs, warningLocs, errorLocs } = lintLocs
    return (rowIndex, colIndex) => {

      const headerName = all_headers[colIndex]
      // draw lint results
      if (errorLocs.length > 0) {
        for (let i = 0; i < errorLocs.length; i++) {
          if (errorLocs[i].col_name === headerName) {
            if (!errorLocs[i].row_idx || errorLocs[i].row_idx.indexOf(rowIndex) > -1) {
              return {
                className: "errorCell",
                renderer: cellRenderer,
              }
            }
          }
        }
      }
      if (warningLocs.length > 0) {
        for (let i = 0; i < warningLocs.length; i++) {
          if (warningLocs[i].col_name === headerName) {
            if (!warningLocs[i].row_idx || warningLocs[i].row_idx.indexOf(rowIndex) > -1) {
              return {
                className: "warningCell",
                renderer: cellRenderer,

              }
            }
          }
        }
      }
      if (infoLocs.length > 0) {
        for (let i = 0; i < infoLocs.length; i++) {
          if (infoLocs[i].col_name === headerName) {
            if (!infoLocs[i].row_idx || infoLocs[i].row_idx.indexOf(rowIndex) > -1) {
              return {
                className: "infoCell",
                renderer: cellRenderer,
              }
            }
          }
        }
      }

      // Group Header row on top
      if (inputIdx.indexOf(colIndex) > -1) {
        return {
          className: "inputCell",
          renderer: cellRenderer,
        }
      } else if (outputIdx.indexOf(colIndex) > -1) {
        return {
          className: "outputCell",
          renderer: cellRenderer,
        }
      }
    }
  }, [originalColumnOrdering, lintLocs, workflow, cellComments])


  const afterChange = (change) => {
    if (change !== null) {
      setDataChanged(true)
    }
  }

  const afterRemoveCol = (index, amount) => {
    let headers = [...HotData.headers]
    headers.splice(index, amount)
    setHotData({ ...HotData, headers: headers });

    afterChange(true)
  }

  let allDataToHotTableData = (allData = '') => {
    if (!allData) {
      allData = workflow.data.data_df[workflow.data.active_sheet]
    }
    if (allData) {
      let colNames
      if (modifiedColHeaders) {
        colNames = modifiedColHeaders
      } else {
        colNames = Object.keys(allData[0]);
      }
      let HotTableData = data_to_list(allData).data;
      setHotData({ data: HotTableData, headers: colNames });
    }
  };

  let handleSheetChange = (event) => {
    let new_sheet = event.target.value;
    let update_dict = {
      "data_cleaning": {
        "SetActiveSheet": {
          "kwargs": {
            "value": new_sheet
          }
        },
      }
    }
    aichemyProtoUpdateWorkflow(update_dict, workflow, setWorkflow).catch(err => {
      enqueueSnackbar("Failed to update active sheet.", { variant: "error" });
      console.error(err)
    })
  }

  let handleRenameCol = () => {
    if (renameButtonText === 'Rename Column') {
      setShowRenameColBtn(true)
      setshowRenameColInput(true)
      setRenameButtonText('Save new name')
    }
    else {
      setShowRenameColBtn(false)
      setshowRenameColInput(false)
      setRenameButtonText('Rename Column')
      // Temporarily save new column name to local state
      let colNames = [...modifiedColHeaders];
      // rename if duplicated
      let newColNameUnique = newColName
      while (colNames.includes(newColNameUnique)) {
        newColNameUnique = newColNameUnique + '_1'
      }
      colNames[selectedCol] = newColNameUnique;
      setModifiedColHeaders(colNames)
      // save data
      handleSaveData('', colNames)

    }

  }

  let cellSelected = (row, column, row2, column2) => {
    setSelectingCell(true)

    if (column === column2) {
      setSelectingCell(true)
      setSelectedCol(column)
      setNewColName(HotData.headers[column])
    } else {
      setSelectingCell(false)
      setSelectedCol('')
    }
  }

  let cellDeselected = () => {
    setSelectingCell(false)
  }

  let updateRenameColText = event => {
    let currVal = event.target.value;
    setNewColName(currVal);
  };

  let downloadCSV = () => {
    csvLink.current.link.click()
  }

  let generateCSVData = () => {
    let oriFilename = workflow.name
    if (Array.isArray(HotData.data[0])) {
      let csvName = oriFilename.split('.')[0] + '_MS.csv'
      let newData = HotData.data;
      let newHeaders = HotData.headers;

      // convert " to \"\" so it's correctly written to csv file
      newHeaders = newHeaders.map((item) => {
        if (item.includes("\"")) {
          item = item.replace("\"", "\"\"")
        }
        return item
      })

      // collect data
      let data = [newHeaders]
      newData.forEach((item) => { data.push(item) })

      setCSVData({
        data: data,
        filename: csvName
      })
    }
    else setCSVData(
      { data: [], filename: 'test.csv' }
    )
  }

  useEffect(generateCSVData, [HotData, workflow])
  useEffect(allDataToHotTableData, [modifiedColHeaders, workflow?.data, workflow])

  return (
    <div style={{ margin: 24 }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h6" gutterBottom style={{ textAlign: "left", marginTop: 6 }}>
              Data Cleaning
            </Typography>
            <Tooltip title={<Typography variant="h6">Next</Typography>} placement="top">
              <IconButton
                onClick={() => nextRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                color="primary"
                style={{ textAlign: "right", marginTop: 0, marginLeft: 12 }}
              >
                <NextIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="h6">Top</Typography>} placement="top">
              <IconButton
                onClick={() => topAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                color="primary"
                style={{ textAlign: "right", marginTop: 0, marginLeft: 12 }}
              >
                <TopIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Typography
            variant="subtitle1"
            style={{ textAlign: "left", marginBottom: 16 }}
          >
            Edit data below or add/remove data by right clicking the table cells.
          </Typography>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={8}
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={10}>
              <FormControl
                fullWidth
                className={classes.formControl}
              >
                <InputLabel htmlFor="newmodel_sheet_select">
                  Use dropdown menu to select an Excel tab
                </InputLabel>
                <Select
                  // autoWidth
                  style={{ textAlign: "left" }}
                  value={workflow?.data?.active_sheet}
                  onChange={handleSheetChange}
                  input={
                    <Input
                      name="edit_data_sheet_select"
                      id="edit_data_sheet_select"
                    />
                  }
                >
                  {Object.keys(workflow.data.data_df).map(sheet => (
                    <MenuItem key={sheet} value={sheet}>
                      {sheet}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div >
          {Legend()}
        </div>

      </div>
      <FormControl className={classes.formControl} fullWidth>
        <div className={classes.htRoot}>
          <HotTable
            id="table-edit-data"
            ref={hotTableComponent}
            data={HotData.data}
            colHeaders={HotData.headers}
            afterSelectionEnd={cellSelected}
            afterDeselect={cellDeselected}
            afterChange={afterChange}
            afterRemoveCol={afterRemoveCol}
            afterRemoveRow={afterChange}
            rowHeaders={true}
            contextMenu={true}
            stretchH="all"
            height={300}
            minRows="15"
            undo={true}
            cells={cellStyle}
            cell={cellComments}
            comments={false}
            autoColumnSize={{ useHeaders: true }}
          />
        </div>
      </FormControl>
      <Grid
        container
        style={{ marginBottom: 14, marginTop: 10 }}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {showRenameColInput ?
          <TextField
            id="standard-basic"
            label="New column name"
            defaultValue={HotData.headers[selectedCol]}
            onChange={updateRenameColText}
          /> : ''}
        {(showRenameColBtn || selectingCell || hoveringRenameBtn) ?
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={classes.button}
            onClick={handleRenameCol}
            onMouseEnter={() => { setHoveringRenameBtn(true) }}
            onMouseLeave={() => { setHoveringRenameBtn(false) }}
            style={{ marginTop: 12, marginLeft: 12 }}
          >
            {renameButtonText}
          </Button>
          : ''}
        {renameButtonText === 'Save new name' && <Button
          variant="contained"
          color="secondary"
          component="span"
          className={classes.button}
          onClick={handleCancelRenameCols}
          style={{ marginTop: 12 }}
        >
          Cancel
        </Button>}
        {dataChanged && <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          onClick={handleSaveData}
          style={{ marginTop: 12 }}
        >
          Apply Changes
        </Button>}
        {dataChanged && <Button
          variant="contained"
          color="secondary"
          component="span"
          className={classes.button}
          onClick={handleCancel}
          style={{ marginTop: 12 }}
        >
          Cancel
        </Button>}
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          onClick={() => {setOpenRenameCol(!openRenameCol)}}
          style={{ marginTop: 12 }}
        >
          Simplify column names
        </Button>
        <RenameColumnNamesDialog
          open={openRenameCol}
          handleClose={() => setOpenRenameCol(false)}
          handleSaveData={handleSaveData}
        />

        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
          onClick={downloadCSV}
          style={{ marginTop: 12 }}
        >
          Download
        </Button>
        <div>
          <CSVLink
            data={csvData.data}
            filename={csvData.filename}
            className="hidden"
            ref={csvLink}
            target="_blank" />
        </div>
      </Grid>

    </div>
  );
}

export default React.memo(EditData);
