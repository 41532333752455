import React, { useCallback, useRef } from "react";
import { Card, Paper, Zoom } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import common_styles from '../../../styles/common_styles';
import EditData from './EditData';
import DisplayLintResult from "../DataPreprocess/DisplayLintResult"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Badge from "@material-ui/core/Badge"
import { withStyles } from '@material-ui/core/styles';
import MsDataPreprocess from "../DataPreprocess/MsDataPreprocess"
import SetInputOutput from "../DataPreprocess/SetInputOutput"
import { useAppStoreKey } from "../../../AppStore"
import { Slide } from "@material-ui/core";
import EditDataTable from "./EditDataTable";
import GridOnIcon from '@material-ui/icons/GridOn';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';
import BellRingIcon from "mdi-material-ui/BellRing";

const useStyles = makeStyles(theme => ({
    ...common_styles(theme),
    wrapper: {
        zIndex: 10000,
        width: "100%",
        position: "fixed",
        top: 30,
        left: 0,
    },
    paper: {
        zIndex: 10000,
        margin: "auto",
        marginTop: theme.spacing(4),
        maxWidth: "1200px",
        position: "relative",
        padding: theme.spacing(2),
        // backgroundColor: theme.palette.type === "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
        // color: "white"
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 10000,
    },
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 40,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 0px',
    },
}))(Badge);


function ShowEditDataTable(props) {
    const { setShowFloatPage, showFloatPage, dataChanged } = props;
    const classes = useStyles();
    const [workflow, ] = useAppStoreKey("Workflow");
    const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
    const nPreprocessFuncs = () => workflow.history.data_cleaning.filter(d => !d.args.includes("set_cols")).length;

    const handleClick = () => {
        setShowFloatPage(!showFloatPage);
        if (dataChanged) {
            setMSState({ ...MSState, nViewedPFuncChanges: nPreprocessFuncs() });
        }
    };

    return (
      <div>
          <Zoom in={true}>
              <div onClick={handleClick}>
                  {dataChanged > 0 && <Fab variant="extended" size="medium" className={classes.fab} style={{backgroundColor: "#4caf50", color: "white"}}>
                      <Badge badgeContent={dataChanged} color="secondary" >
                          <BellRingIcon style={{ marginRight: 14 }} />
                          New!
                      </Badge>
                  </Fab>}

                  {dataChanged <= 0 && (!showFloatPage ? <Fab color="primary" variant="extended" size="medium" className={classes.fab}>
                      <GridOnIcon style={{ marginRight: 14 }} />
                      Data
                  </Fab>: <Fab color="secondary" variant="extended" size="medium" className={classes.fab}>
                      <ClearIcon style={{ marginRight: 5 }} />
                      Close
                  </Fab>)}
              </div>
          </Zoom>
      </div>

    );
}
function MsEditModel(props) {
    const classes = useStyles();
    const topAnchorRef = useRef()
    const editDataRef = useRef()
    const linterRef = useRef()
    const ioSelectRef = useRef()
    const addPreprocessFuncRef = useRef()
    const [showFloatPage, setShowFloatPage] = React.useState(false)
    const [MSState] = useAppStoreKey('MixingStudioState')
    const [workflow, ] = useAppStoreKey("Workflow");

    const dataChanged = useCallback(() => {
        const nPreprocessFuncs = () => workflow.history.data_cleaning.filter(d => !d.args.includes("set_cols")).length;
        return nPreprocessFuncs() - MSState.nViewedPFuncChanges;
    }, [MSState, workflow.history.data_cleaning])

    return (<div>
        <div ref={topAnchorRef} />
        <Card
            className={classes.paperBody}
            elevation={1}
            style={{ marginTop: 24 }}
        >
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
                >
                    Edit
                </Typography>
                <Button
                    onClick={() => editDataRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    color="primary"
                    style={{ textAlign: "left", marginTop: 20, marginLeft: 40 }}
                >
                    Data Cleaning
                </Button>
                <StyledBadge badgeContent={MSState?.nLint} color="secondary" >
                    <Button
                        onClick={() => linterRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                        color="primary"
                        style={{ textAlign: "left", marginTop: 20, marginLeft: 24 }}
                    >
                        Data Checking
                    </Button>
                </StyledBadge>
                <Button
                    onClick={() => ioSelectRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    color="primary"
                    style={{ textAlign: "left", marginTop: 20, marginLeft: 40 }}
                >
                    Set Input/Output
                </Button>
                <Button
                    onClick={() => addPreprocessFuncRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    color="primary"
                    style={{ textAlign: "left", marginTop: 20, marginLeft: 24 }}
                >
                    Add Preprocess Functions
                </Button>
            </div>
            <Divider />
            <div ref={editDataRef} />
            <EditData  topAnchorRef={topAnchorRef} nextRef={linterRef}/>
        </Card>

        <Card className={classes.paperBody} elevation={1}>
            <div ref={linterRef}/>
            <DisplayLintResult topAnchorRef={topAnchorRef} prevRef={editDataRef} nextRef={ioSelectRef}/>
        </Card>

        <Card className={classes.paperBody} elevation={1}>
            <div ref={ioSelectRef} />
            <SetInputOutput topAnchorRef={topAnchorRef} prevRef={linterRef} nextRef={addPreprocessFuncRef}/>
        </Card>
        <div className={classes.wrapper}>
          <Slide direction="down" in={showFloatPage} mountOnEnter unmountOnExit>
              <Paper elevation={4} className={classes.paper}>
                  <EditDataTable  setOpen={setShowFloatPage} editDataRef={editDataRef}/>
              </Paper>
          </Slide>
        </div>
        <MsDataPreprocess
          topAnchorRef={topAnchorRef}
          addPreprocessFuncRef={addPreprocessFuncRef}
          prevRef={ioSelectRef}
          setShowFloatPage={setShowFloatPage}
        />
        <ShowEditDataTable
          {...props}
          setShowFloatPage={setShowFloatPage}
          showFloatPage={showFloatPage}
          dataChanged={dataChanged()}
        />
    </div>
    );
}

export default React.memo(MsEditModel);