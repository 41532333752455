import { usePrevious } from "../../utils/utils";
import { useEffect, useCallback, useMemo } from "react";
import { useLocalForageState } from "utils/useLocalforageState";

export function makeQueryFilters(filters = [], columns){
    return filters.map(filter => {
        const col = columns.find(col => filter.id === (col.id || col.accessor));
        if (col.filterTransform) return col.filterTransform(filter)
        return filter.id && filter.value?.query && {
            name: filter.id,
            op: filter.value.op || filter.value.type,
            val: filter.value.query
        }
    }).filter(Boolean);
}
export function shiftColumn(columns, index, direction){
    const reordered = [...columns];
    if ((index + direction) < 0) direction = -index;
    if ((index + direction) >= columns.length) direction = columns.length - index - 1;
    if (direction === 0) return columns;
    reordered.splice(index+direction, 0, reordered.splice(index, 1)[0]);
    return reordered;
}

export function useColumns(localForageKey, columnDefinitions, defaultColumns) {
    const [serializedColumns, setSerializedColumns, loading] = useLocalForageState(localForageKey, defaultColumns);
    const columns = useMemo(() => makeColumns(serializedColumns, columnDefinitions), [serializedColumns, columnDefinitions]);
    useEffect(() => {
        if (!columns) setSerializedColumns(defaultColumns)
    }, [columns, defaultColumns, setSerializedColumns])
    return [serializedColumns, setSerializedColumns, columns || makeColumns(defaultColumns, columnDefinitions), loading || !columns.length]
}

export function useColumnsSimple(localForageKey, columnDefinitions, defaultColumns) {
    const [serializedColumns, setSerializedColumns] = useLocalForageState(localForageKey, defaultColumns);
    const columns = useMemo(() => makeColumns(serializedColumns, columnDefinitions), [serializedColumns, columnDefinitions]);
    useEffect(() => {
        if (!columns) setSerializedColumns(defaultColumns)
    }, [columns, defaultColumns, setSerializedColumns])
    return [serializedColumns, columns || makeColumns(defaultColumns, columnDefinitions)]
}

/**
 * Take any array of serialize columns and a columns definition array. Returns react table column object 
 * @param {[]} serialized [{groupId, colId, dynamic?: {args}}]
 * @param {*} columnDefinitions 
 */
export function makeColumns(serialized, columnDefinitions){
    return serialized.reduce((prev, serial) => {
        if (!prev) return prev
        const group = columnDefinitions.find(c => c.id === serial.groupId);
        if (!group) {
            return undefined;
        }
        if (!serial.dynamic){
            const col = group.columns?.find(c => serial.colId === (c.id || c.accessor))
            return col ? prev.concat(col) : undefined
        }
        else{
            const dynamic = group.dynamic?.find(d => d.id === serial.dynamicId);
            if (!dynamic) return undefined;
            return prev.concat(dynamic.make(serial.dynamic))
        }
    }, []);
}

export function stripColumns(columns){
    return columns?.map(col => ({id: (col.id || col.accessor), Header: col.Header, accessor: () => ""})) || [];
}

export function serializedColumnsFromIds(ids, columnDefinitions){
    return ids.map(id => {
        let colId = undefined;
        let groupId = undefined;
        for (const group of columnDefinitions){
            const col = group.columns?.find(c => id === (c.id || c.accessor));
            if (col) {
                colId = col.id || col.accessor;
                groupId = group.id;
                break;
            }
        }
        if (!colId) return undefined;
        return {colId, groupId};
    }).filter(c => !!c);
}

export function useOnRowSelect({selectedRowIds, toggleRowSelected, data, selected, setSelected, selectedRows, setSelectedRows, rows}){
    const handleRowSelected = useCallback((changes) => {
        const newSelected = selected.filter(s => changes[s.id] !== false);
        let changed = newSelected.length !== selected.length;
        for (let [id, checked] of Object.entries(changes)){
            if (checked && !newSelected.find(s => s.id === id)){
                const newSel = data.find(d => d.id === id);
                if (newSel){
                    changed = true;
                    newSelected.push(newSel);
                }
            }
        }

        // Duplicate behavior of regular selected to enable retrieving an updated list of rows with the original data passed in (so that these rows can be used interchangeably)
        const newSelectedRows = selectedRows?.filter(s => changes[s.id] !== false);
        let changedRows = newSelectedRows?.length !== selectedRows?.length;
        for (let [id, checked] of Object.entries(changes)){
            if (checked && !newSelectedRows?.find(s => s.id === id)){
                const newSelRows = rows?.find(d => d.id === id);
                if (newSelRows){
                    changedRows = true;
                    newSelectedRows?.push(newSelRows);
                }
            }
        }

        if (changed){
            setSelected(newSelected);
        }
        if(changedRows) {
            setSelectedRows?.(newSelectedRows || [])
        }
    }, [data, selected, setSelected, setSelectedRows, rows, selectedRows]);

    // update selected values based on selected rows
    const prevSelectedRowIds = usePrevious(selectedRowIds);
    useEffect(() => {
        if (selectedRowIds !== prevSelectedRowIds){
            const changes = {};
            for (let [id, checked] of Object.entries(selectedRowIds)){
                if (checked && !prevSelectedRowIds[id]){
                    changes[id] = true;
                }
            }
            for (let [id, checked] of Object.entries(prevSelectedRowIds)){
                if (checked && !selectedRowIds[id]){
                    changes[id] = false;
                }
            }
            if (Object.keys(changes).length > 0)
                handleRowSelected(changes);
        }
    }, [selectedRowIds, prevSelectedRowIds, handleRowSelected]);

    // update selected rows if new values externally selected
    const prevSelected = usePrevious(selected);
    const prevData = usePrevious(data);
    useEffect(() => {
        if (prevSelected !== selected || data !== prevData){
            // remove ids
            for (let [id, checked] of Object.entries(selectedRowIds)){
                if (checked && !selected.find(s => s.id === id) && data.find(d => d.id === id)){
                    toggleRowSelected(id, false);
                }
            }
            // add ids
            selected.forEach(s => {
                if (!selectedRowIds[s.id] && data.find(d => d.id === s.id)){
                    toggleRowSelected(s.id, true);
                }
            });
        }
    }, [selected, prevSelected, selectedRowIds, data, prevData, toggleRowSelected]);
}