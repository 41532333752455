import React, { useImperativeHandle, useRef, useCallback, useState, useEffect, } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import ReactResizeDetector from 'react-resize-detector';

import { dualSortArrayOfArrays, autoFillDownExcel } from '../../utils/utils'

import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        position: "relative",
        paddingBottom: 0,
        paddingTop: 0,
        flexGrow: 1,
        overflow: "hidden",
        height: props => props.height && props.height + "px",
    },
    htRoot: { // for handsontable (hot) overrides
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: props => props.height,
        '& tr': {
            background: theme.palette.background.paper
        },
        '& th': {
            backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["200"],
            color: theme.palette.text.primary,
        },
        '& .htPlaceholder': {
            color: theme.palette.text.secondary,
        },
        '& tbody th.ht__highlight, thead th.ht__highlight': {
            backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["500"] : theme.palette.grey["300"]
        },
        '& td': {
            background: theme.palette.background.paper,
            overflow: "hidden",
            textOverflow: "ellipsis",
            '& .htDelete': {
                float: "right",
                color: theme.palette.primary,
                paddingLeft: theme.spacing(1),
                opacity: .25,
                '&:hover': {
                    opacity: 1
                }
            },
        },
        '& td.noWrap': {
            whiteSpace: "nowrap",
        },
        '& .handsontableInput': {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
        '& .handsontable.listbox .ht_master table': {
            background: theme.palette.background.paper,
        },
        '& .handsontable.listbox tr td.current, .handsontable.listbox tr:hover td': {
            background: theme.palette.action.hover,
        },
        '& .htMobileEditorContainer': {
            background: theme.palette.background.paper,
        },
        '& .htDimmed': {
            color: theme.palette.text.secondary,
        },
        '& .htSubmenu :after': {
            color: theme.palette.text.secondary,
        },
        '& td.currentRow': {
            backgroundColor: theme.palette.primary.main + "10",
        },
        '& td.groupHeader': {
            backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[600] : theme.palette.grey[300],
            color: theme.palette.text.primary,
            fontWeight: "bold",
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineOffset: "-1px",
            '&.material': {
                color: theme.palette.type === "dark" ? theme.palette.material.light : theme.palette.material.dark,
                outlineColor: theme.palette.type === "dark" ? theme.palette.material.light : theme.palette.material.dark,
            },
            '&.process': {
                color: theme.palette.type === "dark" ? theme.palette.process.main : theme.palette.process.dark,
                outlineColor: theme.palette.type === "dark" ? theme.palette.process.main : theme.palette.process.dark,
            },
            '&.output': {
                color: theme.palette.type === "dark" ? theme.palette.output.main : theme.palette.output.dark,
                outlineColor: theme.palette.type === "dark" ? theme.palette.output.main : theme.palette.output.dark,
            }
        },
        '& td.innerColHeader': {
            backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
            color: theme.palette.text.primary,
        },
        '& td.innerColSubHeader': {
            backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
        },
        '& td.innerRowSubHeader': {
            backgroundColor: alpha(theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[200], .25),
        },
        '& td.outputCell': {
            backgroundColor: "#dcedc8",
            color: "black"
        },
        '& td.inputCell': {
            backgroundColor: "#e1f5fe",
            color: "black"
        },
    },
}));

const settingsDefault = { outsideClickDeselects: false, rowHeaderWidth: 125 };

export default React.memo(React.forwardRef(function StyleHot(HoTProps, ref) {
    const { beforeChange, height, data, columns, columnSorting, ...props } = HoTProps
    const { columnInfo } = HoTProps;
    const localRef = useRef();
    useImperativeHandle(ref, () => localRef.current);
    const [tableHeight, setTableHeight] = useState(height);
    const handleResize = useCallback((x, y) => {
        if (!height) {
            setTableHeight(y - 20);
        }
        else {
            localRef.current.hotInstance.render();
        }
    }, [height]);
    const liveHeight = height || Math.min(tableHeight, (data?.length || 0) * 23 + 46);

    const classes = useStyles({ height });
    const handleBeforeChange = useCallback((changes, source) => {

        if (source === "Autofill.fill") {

            let selRange = localRef.current.hotInstance.getSelectedLast();
            let selTopRowIdx = selRange[0] < selRange[2] ? selRange[0] : selRange[2];
            let selBottomRowIdx = selTopRowIdx === selRange[0] ? selRange[2] : selRange[0];

            if (selRange[1] === selRange[3]) {

                changes = dualSortArrayOfArrays(changes, 1, 0);

                // if selected rows overlap with the changes, remove the overlap from the selection. HoT appears inconsistent on reporting the selection before or after the autofill.
                selTopRowIdx = selTopRowIdx >= changes[0][0] ? changes[changes.length - 1][0] + 1 : selTopRowIdx
                selBottomRowIdx = selBottomRowIdx <= changes[changes.length - 1][0] ? changes[0][0] - 1 : selBottomRowIdx
                let dragDown = changes[0][0] > selTopRowIdx

                if (selTopRowIdx <= selBottomRowIdx && dragDown) {
                    let selVals = localRef.current.hotInstance.getSourceDataAtCol(
                        selRange[1]).slice(selTopRowIdx, selBottomRowIdx + 1);

                    changes = autoFillDownExcel(changes, selVals);
                }
            }
        }

        beforeChange && beforeChange(changes, source);
    }, [beforeChange]);

    const lastColLength = useRef(columns?.length)
    React.useEffect(() => {
        lastColLength.current = columns?.length
    })

    // work around for HoT bug that causes crash when sorting enabled and the number of columns shrinks...
    const [retries, setRetries] = useState(-1)
    let tries = retries
    let colSort = useRef(columnSorting)
    if (colSort?.current && localRef?.current && columns?.length < lastColLength?.current) {
        const hotInstance = localRef.current.hotInstance
        const plugin = hotInstance.getPlugin('columnSorting')
        if (plugin?.isEnabled()) {
            colSort.current = undefined
            plugin.setSortConfig(undefined)
            plugin.disablePlugin()
            tries = tries === 0 ? 1 : 0
        }
    }
    else if (!colSort?.current && columnSorting) {
        if (localRef?.current && columns?.length >= lastColLength?.current) {
            const hotInstance = localRef.current.hotInstance
            const plugin = hotInstance.getPlugin('columnSorting')
            if (plugin && !plugin.isEnabled()) {
                colSort.current = columnSorting
            }
        }
    }
    useEffect(() => {
        if (!colSort?.current && columnSorting) {
            if (tries < 4)
                setRetries(tries + 1)
        }
    }, [columnSorting, tries])

    LockRowIfDesired(columns, columnInfo)

    return (
        <div className={classes.container}>
            <ReactResizeDetector handleHeight handleWidth onResize={handleResize} />
            <div className={classes.htRoot}>
                <HotTable
                    data={data}
                    columns={columns}
                    columnSorting={colSort.current}
                    manualColumnResize={true}
                    stretchH={"all"}
                    autoColumnSize={false}
                    settings={settingsDefault}
                    ref={localRef}
                    beforeChange={handleBeforeChange}
                    height={liveHeight}
                    {...props}
                />
            </div>
        </div>
    )
}));


const LockRowIfDesired = (columnData, columnInfo) => {
    (columnInfo || []).forEach((col, index) => {
        // this seems redundant, but col.locked could be undefined
        columnData[index].readOnly = col.locked ? true : false;
    })
}
