import { ProcessDefinition, Lab } from '../models'
import { Schema, schema } from '../schemaUtils'

export class LabToProcessDefinition {
    id?: string             // always undefined, adding appeases typescript
    creatorId?: string
    timeCreated?: Date
    accessLevel?: "reader" | "writer"
    processDefinition?: ProcessDefinition
    lab?: Lab

    static readonly [schema]: Schema<LabToProcessDefinition> = {
        relationships: {
            processDefinition: { map: "processDefinition", model: "ProcessDefinition", toMany: false, reverse: "labs", required: true },
            lab: { map: "lab", model: "Lab", toMany: false, reverse: "processDefinitions", required: true },
        },
        map: {
            "creatorId": "creator_id",
            "timeCreated": "created",
            "accessLevel": "access_level",
            "sample.id": "sample_id",
        }
    }

    constructor(toCopy?: Partial<LabToProcessDefinition>) {
        Object.assign(this, toCopy)
    }
}