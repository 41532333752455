import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import common_styles from "../../../styles/common_styles";
import Typography from "@material-ui/core/Typography";
import NormalizeCols from "./PreprocessFuncs/NormalizeCols";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import DropEmptyCellsComponent from "./PreprocessFuncs/DropEmptyCellsComponent";
import PCA from "./PreprocessFuncs/PCA";
import CategoricalEncoding from "./PreprocessFuncs/CategoricalEncoding";
import JustApplyPreprocess from "./PreprocessFuncs/JustApplyPreprocess";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAppStoreDispatchKey, useAppStoreKey } from "../../../AppStore";
import PrevIcon from "mdi-material-ui/ChevronDoubleUp";
import TopIcon from "mdi-material-ui/ChevronTripleUp";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import { Tooltip } from "@material-ui/core";
import StandardizeScaler from "./PreprocessFuncs/StandardizeScaler";
import MinMaxScaler from "./PreprocessFuncs/MinMaxScaler";
import Fingerprinting from "./PreprocessFuncs/Fingerprinting";

const useStyles = makeStyles((theme) => ({
    ...common_styles(theme),
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export default function PreprocessCard({topAnchorRef, addPreprocessFuncRef, prevRef, setShowFloatPage}) {
    const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
    let currentFunc = MSState['pfCurrentList']
    const setActiveStep = useAppStoreDispatchKey("ActiveStep");
    const setCurrentFunc = (value) => setMSState({...MSState, pfCurrentList: value})

    const funcLists = {
        'Categorical Encoding': 'catEnc',
        'Remove Empty Cells': 'dropEmptyCells',
        'Normalize Columns': 'normCols',
        'PCA Scaler': 'pca',
        'MinMax Scaler': 'minmax',
        'Standard Scaler': 'std',
        'Fingerprinting': 'fingerprinting',
        // 'Advanced mode': 'Jap'
    }
    const funcSize = {
        'normCols': 6,
        'dropEmptyCells': 6,
        'catEnc': 6,
        'pca': 6,
        'minmax': 6,
        'std': 6,
        'fingerprinting': 12,
    }
    const classes = useStyles();
    // const [addPFExpanded, setAddPFExpanded] = useState(true)
    let addPFExpanded = MSState['addPFExpanded']
    const setAddPFExpanded = (value) => setMSState({...MSState, addPFExpanded: value})

    const handleRemoveFuncs = (key) => {
        setCurrentFunc(currentFunc.filter((item) => item !== key))
    }

    // Returns the preprocessor component associated with each preprocessing step. Returns a default "Coming soon" message if the function doesn't exist
    const getPreprocessComponent = (preprocessName) => {
        switch (preprocessName) {
            case "normCols":
                return <NormalizeCols pfKey={'normCols'} handleRemovePlot={handleRemoveFuncs} setShowFloatPage={setShowFloatPage}/>;
            case "dropEmptyCells":
                return <DropEmptyCellsComponent pfKey={'dropEmptyCells'} handleRemovePlot={handleRemoveFuncs} />
            case "catEnc":
                return <CategoricalEncoding pfKey={'catEnc'} handleRemovePlot={handleRemoveFuncs} />
            case "pca":
                return <PCA pfKey={'pca'} handleRemovePlot={handleRemoveFuncs} />
            case "Jap":
                return <JustApplyPreprocess pfKey={'Jap'} handleRemovePlot={handleRemoveFuncs} />
            case "minmax":
                return <MinMaxScaler pfKey={'minmax'} handleRemovePlot={handleRemoveFuncs} />
            case "std":
                return <StandardizeScaler pfKey={'std'} handleRemovePlot={handleRemoveFuncs} />
            case "fingerprinting":
                return <Fingerprinting pfKey={'fingerprinting'} handleRemovePlot={handleRemoveFuncs} />
            default:
                return <Typography variant="h6">Coming soon...</Typography>
        }
    }

    const handleModelClick = (name) => () => {
        console.log(currentFunc)
        const selectedModelKey = funcLists[name]
        if(currentFunc.includes(selectedModelKey)) {
            // remove selectedModelKey from selectedFunc list
            setCurrentFunc(currentFunc.filter((item) => item !== selectedModelKey))
        } else {
            // add selectedModelKey to selectedFunc list
            setCurrentFunc([...currentFunc, selectedModelKey])
        }

    }

    return (
        <>
            <Collapse in={addPFExpanded} timeout="auto" collapsedSize={90}>
            <Card className={classes.paperBody} elevation={1}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ textAlign: "left", marginTop: 5 }}
                    >
                        Add Preprocess Functions
                    </Typography>
                    <Tooltip title={<Typography variant="h6">Previous</Typography>} placement="top">
                        <IconButton
                          onClick={() => prevRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                          color="primary"
                          style={{ textAlign: "right", marginTop: 0, marginLeft: 60}}
                        >
                            <PrevIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography variant="h6">Top</Typography>} placement="top">
                        <IconButton
                          onClick={() => topAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                          color="primary"
                          style={{ textAlign: "right", marginTop: 0, marginLeft: 12}}
                        >
                            <TopIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography variant="h6">Train A Model</Typography>} placement="top">
                        <IconButton
                          onClick={() => {
                              topAnchorRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
                              setActiveStep(3)
                          }}
                          color="primary"
                          style={{ textAlign: "right", marginTop: 0, marginLeft: 12}}
                        >
                            <BubbleChartIcon />
                        </IconButton>
                    </Tooltip>

                    {/*<Tooltip title={<Typography variant="h6">Auto apply preprocess functions</Typography>} placement="top">*/}
                    {/*    <IconButton key={'autoPF'} edge="end"  onClick={autoApplyPF}>*/}
                    {/*        <AutoFixIcon />*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: addPFExpanded,
                        })}
                        onClick={() => {setAddPFExpanded(!addPFExpanded)}}
                        aria-expanded={addPFExpanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </div>

                <Typography
                    variant="subtitle1"
                    style={{
                        textAlign: "left",
                        marginLeft: 24,
                        marginRight: 24,
                        marginBottom: 0
                    }}
                >
                    Select a preprocess function and click to add.
                </Typography>
                <div ref={addPreprocessFuncRef} />
                {Object.keys(funcLists).map(name => (
                  <Button
                    variant="outlined"
                    key={name}
                    style={{margin: 10, color: currentFunc.includes(funcLists[name])? "#2196f3" : "#bdbdbd"}}
                    onClick={handleModelClick(name)}
                  >
                      {name}
                  </Button>
                ))}
                <Grid container>
                    {currentFunc.map((func, idx) => {
                        return (
                            <Grid key={"preprocess_component_" + idx} item xs={funcSize[func]} style={{ padding: 10 }}>
                                {getPreprocessComponent(func)}
                            </Grid>
                        );
                    })}
                </Grid>
            </Card></Collapse></>
    );
}
