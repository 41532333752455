//import { useContext } from "react";
//import { AccountContext } from "../../context";
import { useLocalForageState } from "../../utils/useLocalforageState";

export function useUsers(){
    //const account = useContext(AccountContext);
    return useLocalForageState("usersFilter",[]);
}

export function useProjects(){
    return useLocalForageState("projectsFilter",[]);  
}