import UserFilterInput from "../ReactTable/UserFilterInput";
import DateCell from "../ReactTable/DateCell";
import DateFilterInput from "../ReactTable/DateFilterInput";
import { serializedColumnsFromIds } from "../ReactTable/reactTableUtils";
import LabsFilterInput from "../ReactTable/labsFilterInput";

export const processColumns = [
    {
        Header: "Basic Process Fields",
        id: "process",
        columns: [
            {
                Header: "Process Name",
                accessor: "title",
            },
            {
                Header: "Tags",
                accessor: "tags",
                filterTransform(filter) {
                    return {
                        name: "tagsRel.title",
                        val: filter.value?.query,
                        op: filter.value?.op || filter.value?.type
                    }
                },
                disableSortBy: true,
            },
            {
                Header: "Contributor(s)",
                id: "contributors",
                accessor: data => data.contributors?.join(", "),
                Filter: UserFilterInput,
            },
            {
                Header: "Created",
                accessor: "timeCreated",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Modified",
                accessor: "timeModified",
                Cell: DateCell,
                Filter: DateFilterInput,
                width: 120,
                disableResizing: true,
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Permissions",
                id: "labs.lab.title",
                accessor: data => data.labs?.map(lab => lab.lab?.title).filter((title, i, arr) => arr.indexOf(title) === i).join(" | "),
                Filter: LabsFilterInput,
            }
        ],
    },
]

export const defaultColumns = serializedColumnsFromIds(["title", "contributors", "timeModified", "description"], processColumns);
