import React from 'react';
import { Checkbox } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export function addSelectColumn({ allColumns }) {
  allColumns.push(columns => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => <Checkbox color='primary' icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="small" />} {...getToggleAllRowsSelectedProps()} />,
      Cell: ({ row }) => <Checkbox color='primary' icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="small" />} {...row.getToggleRowSelectedProps()} />,
      disableResizing: true,
      disableSortBy: true,
      disableFilters: true,
      width: 48,
      padding: "checkbox",
      headerPadding: "checkbox",
      cellProps: { style: { justifyContent: "flex-end" } }
    },
    ...columns
  ]);
}
