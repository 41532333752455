import React from 'react';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Tooltip from '@material-ui/core/Tooltip';

import TrifectaCorner from './TrifectaCorner';
//import MenuList from './Dashboard/MenuList';

import { useQueryVersion } from '../../API/queryHooks';
import MenuList from './MenuList';

const REACT_VERSION = process.env.REACT_APP_VERSION_SEMANTIC ? process.env.REACT_APP_VERSION_SEMANTIC : "1.0.0";
const VERSION_COUNT = process.env.REACT_APP_VER_COUNT ? process.env.REACT_APP_VER_COUNT : 1001;
const TAG_COUNT = process.env.REACT_APP_TAG_COUNT ? process.env.REACT_APP_TAG_COUNT : 1000;
export const MM_APP_VERSION = `${REACT_VERSION.replace('v', '')}.${VERSION_COUNT - TAG_COUNT}`;


export default function MMMDrawer({ classes, open, onColorChange, switchPeriod, onDrawerClose }) {
    const { data: backendVersion } = useQueryVersion();
    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <TrifectaCorner className={classes.toolbarIcon} onColorChange={onColorChange} switchPeriod={switchPeriod}>
                <Typography variant="h6" color="inherit" className={classes.cornerTitle}>
                    Materials Manager
                </Typography>
                <IconButton onClick={onDrawerClose}>
                    <ChevronLeftIcon className={classes.menuCloseButton} />
                </IconButton>
            </TrifectaCorner>
            <MenuList open={open} />
            {open &&
                <div className={classes.version}>
                    <Typography variant="caption" className={classes.buildText}>{`Frontend: ${MM_APP_VERSION}`}</Typography>
                    <Tooltip title={process.env.REACT_APP_MM_API_URL} placement="top">
                        <Typography variant="caption" className={classes.buildText}>{"Backend: " + (backendVersion || "Not Connected")}</Typography>
                    </Tooltip>
                </div>
            }
        </Drawer>
    );
}