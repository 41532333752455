/**
 * @format
 */
import { useCallback, useContext, useMemo, useRef, useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import { Typography, useMediaQuery } from "@material-ui/core"

import BarChartIcon from "@material-ui/icons/BarChart"
import InfoIcon from "@material-ui/icons/ListAlt"
import TableIcon from "@material-ui/icons/TableChartOutlined"

import ChartIcon from "mdi-material-ui/Sitemap"
import CIcon from "mdi-material-ui/AlphaCCircleOutline"

import { useSnackbar } from "notistack"
import { useNavigate } from "@reach/router"

import {
    useCheckReadOnly,
    useQueryProcessChartDefinitions,
    useUserPreferences,
} from "API/queryHooks"
import { SampleSet } from "schema/models"
import {
    useEffectUpdateColumnsFromNodes,
    useHighlightedNodes,
} from "components/FlowChart/processChartUtils"
import ProcessFlowChartPane from "components/FlowChart/ProcessFlowChartPane"
import EditButtons from "components/General/EditButtons"
import SampleSetInfoFields from "components/SampleSetLibrary/SampleSetInfoFields"

import { useAppStoreKey } from "AppStore"
import { AccountContext } from "context"
import MMToolbar from "components/General/MMToolbar"
import {
    ResizableViewsSelector,
    ResizableViews,
    DisplayTabs,
} from "../General/ResizableViews"
import {
    useCopy,
    useDelete,
    useSave,
    useSaved,
    useEditSampleSetState,
} from "./editSampleHooks"

import { useMutationFC } from "API/mutationHooks"
import { useEnqueueDialogs } from "components/General/GlobalConfirmationDialog"
import MasterTable from "components/DataTables/MasterTable"

import { MultiPlotSampleSet } from "./MultiPlot"
import ComponentSummaryTable from "components/DataTables/ComponentSummeryTable"
import { useUpdateDefs, useChangeSampleSetNode } from "utils/schemaUtils"

const DEFAULT_PROCESS_CHART_SIZE = 265

export default function EditSampleSet() {
    const theme = useTheme()
    const medium = useMediaQuery(theme.breakpoints.up("md"))
    const large = useMediaQuery(theme.breakpoints.up("lg"))

    const navigate = useNavigate()
    const account = useContext(AccountContext) as any
    const { enqueueSnackbar } = useSnackbar()
    const enqueueDialogs = useEnqueueDialogs()

    // setup initial data
    const defaultSet = useMemo(() => new SampleSet(), [])
    const [initialSampleSet = defaultSet, setInitialSet] =
        useAppStoreKey("InitSampleSet")
    const initialProcessChart = initialSampleSet?.processChart

    const defsRef = useRef<any>()

    const {
        data,
        status,
        updateData,
        updateSampleSet,
        updateProcessChart,
        validateAll,
        undo,
        redo,
        selectedNodes,
        setSelectedNodes,
        setSampleSetStore,
        closedRef,
        tableSelected,
        setTableSelected,
    } = useEditSampleSetState(initialSampleSet, defsRef)
    const sampleSet = data.sampleSet
    const processChart = data.processChart

    // query selected definitions
    const { data: materialDefs, isLoading: isLoadingMaterial } =
        useQueryProcessChartDefinitions("Sample", processChart)
    const { data: processDefs, isLoading: isLoadingProcess } =
        useQueryProcessChartDefinitions("ProcessDefinition", processChart)
    const { data: propertyDefs, isLoading: isLoadingProperty } =
        useQueryProcessChartDefinitions("PropertyDefinition", processChart)
    const { data: userPreferences, isLoading: isLoadingUPs } =
        useUserPreferences("sample_sets", sampleSet.id)

    useUpdateDefs(
        defsRef,
        updateSampleSet,
        materialDefs,
        processDefs,
        propertyDefs,
    )

    useEffectUpdateColumnsFromNodes(
        processChart,
        isLoadingMaterial,
        isLoadingProcess,
        isLoadingProperty,
        materialDefs,
        processDefs,
        propertyDefs,
        updateProcessChart,
    )

    // set mutate callbacks
    const [progress, setProgress] = useState<number>()
    const { mutateAsync } = useMutationFC({
        onProgress(tracker) {
            setProgress((tracker.completed * 100) / tracker.total)
        },
        onMutate() {
            setProgress(undefined)
        },
    })

    const onChangeProcessChartNode = useChangeSampleSetNode(updateSampleSet)

    const onClose = useCallback(() => {
        closedRef.current = true
        setSampleSetStore(undefined)
        setInitialSet(undefined)
        navigate("/SampleSetLibrary/")
    }, [closedRef, navigate, setInitialSet, setSampleSetStore])

    const highlightedNodes = useHighlightedNodes(
        sampleSet,
        processChart,
        tableSelected,
    )

    const readOnly = useCheckReadOnly(initialSampleSet, sampleSet)
    const editing = !!sampleSet?.id && !readOnly

    const components = useMemo<number>(() => {
        const s = new Set<string>()
        for (const sample of sampleSet.samples || []) {
            for (const comp of sample.components || []) {
                if (comp.definition?.id)
                    s.add(comp.definition.id + (comp.units || "g"))
            }
        }
        return s.size
    }, [sampleSet.samples])

    const processChartHeight = useMemo<number>(() => {
        if (isLoadingUPs) return DEFAULT_PROCESS_CHART_SIZE
        if (!userPreferences) return DEFAULT_PROCESS_CHART_SIZE
        if (
            userPreferences.id &&
            userPreferences.id === sampleSet.id &&
            userPreferences.chartContainer &&
            userPreferences.chartContainer.height
        ) {
            return userPreferences.chartContainer.height
        }
        return DEFAULT_PROCESS_CHART_SIZE
    }, [isLoadingUPs, userPreferences, sampleSet.id])

    const displayTabs: DisplayTabs = [
        {
            title: "Set Info",
            Icon: InfoIcon,
            node: (
                <SampleSetInfoFields
                    // @ts-ignore
                    sampleSet={sampleSet}
                    // @ts-ignore
                    invalid={status?.invalid?.sampleSet}
                    // @ts-ignore
                    updateSampleSet={updateSampleSet}
                    accountName={account.name}
                />
            ),
        },
        {
            title: "Flowchart",
            Icon: ChartIcon,
            height: processChartHeight, //250,
            minHeight: 215,
            node: !!processChart && (
                <ProcessFlowChartPane
                    readOnly={readOnly}
                    processChart={processChart}
                    selectedNodes={selectedNodes}
                    onChangeProcessChart={updateProcessChart}
                    onChangeSelectedNodes={setSelectedNodes}
                    onChangeNode={onChangeProcessChartNode}
                    materialDefs={materialDefs}
                    processDefs={processDefs}
                    propertyDefs={propertyDefs}
                    // @ts-ignore
                    highlightedNodes={highlightedNodes}
                    // @ts-ignore
                    sampleSetId={sampleSet.id}
                />
            ),
        },
        {
            title: "Master Table",
            Icon: TableIcon,
            height: Math.max(430, (sampleSet?.samples?.length || 0) * 23),
            minHeight: 225,
            node: processChart && (
                <div style={{ display: "flex", height: "100%" }}>
                    <MasterTable
                        sampleSet={sampleSet}
                        processChart={processChart}
                        updateSampleSet={updateSampleSet}
                        updateProcessChart={updateProcessChart}
                        onTableSelect={setTableSelected}
                        selectedNodes={selectedNodes}
                        materialDefs={materialDefs}
                        processDefs={processDefs}
                        propertyDefs={propertyDefs}
                        minRows={10}
                        minEmptyRows={1}
                        readOnly={readOnly}
                        allowExport
                    />
                </div>
            ),
        },
        {
            title: "Components",
            Icon: CIcon,
            height: Math.max(150, 135 + components * 23),
            minHeight: 150,
            node: processChart && (
                <div style={{ display: "flex", height: "100%" }}>
                    <ComponentSummaryTable
                        sampleSet={sampleSet}
                        materialDefs={materialDefs}
                        minRows={1}
                        minEmptyRows={0}
                        readOnly={readOnly}
                    />
                </div>
            ),
        },
        {
            title: "Multiplot",
            Icon: BarChartIcon,
            node: <MultiPlotSampleSet sampleSet={initialSampleSet} />,
        },
    ]

    const [
        display = [
            displayTabs[0].title,
            displayTabs[1].title,
            displayTabs[2].title,
        ],
        setDisplay,
    ] = useAppStoreKey("EditSampleSetDisplay")

    return (
        <div>
            <MMToolbar
                color={editing ? "secondary" : "primary"}
                Left={
                    <ResizableViewsSelector
                        displayTabs={displayTabs}
                        display={display}
                        onChange={setDisplay}
                        icons={!large}
                    />
                }
                Center={
                    medium ? (
                        <Typography
                            variant="h6"
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                margin: "0 8px",
                            }}
                        >
                            {sampleSet?.title || ""}
                        </Typography>
                    ) : undefined
                }
                Right={
                    <EditButtons
                        // @ts-ignore
                        readOnly={readOnly}
                        // @ts-ignore
                        editing={editing}
                        // @ts-ignore
                        undo={undo}
                        // @ts-ignore
                        redo={redo}
                        // @ts-ignore
                        onSave={useSave({
                            validateAll,
                            sampleSet,
                            account,
                            initialSampleSet,
                            processChart,
                            initialProcessChart,
                            mutateAsync,
                            enqueueSnackbar,
                            enqueueDialogs,
                        })}
                        // @ts-ignore
                        onSaved={useSaved({ navigate, updateData })}
                        // @ts-ignore
                        onDelete={useDelete({
                            mutateAsync,
                            sampleSet,
                            enqueueDialogs,
                        })}
                        // @ts-ignore
                        onCopy={useCopy({
                            sampleSet,
                            processChart,
                            updateData,
                            enqueueSnackbar,
                        })}
                        // @ts-ignore
                        onClose={onClose}
                        // @ts-ignore
                        progress={progress}
                    />
                }
            />
            <ResizableViews display={display} displayTabs={displayTabs} order />
        </div>
    )
}
