import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  copyButton: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    color: "white"
  },
  textField: {
    marginTop: 5,
    width: "100%"
  }
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-addtrace-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function TraceDialog({ title, columns, columnDetails, initialTraceName, initialTrace, traceNumber, onSuccess, open, setOpen, onCopy }) {
  const classes = useStyles()
  const [traceName, setTraceName] = useState("")
  const [values, setValues] = useState({})

  useEffect(() => {
    setTraceName(initialTraceName)
  }, [initialTraceName])

  useEffect(() => {
    if (initialTrace === undefined) {
      return
    }
    let vals = {}
    columns.forEach(col => {
      if (initialTrace[col] === undefined || initialTrace[col] === null) {
        vals[col] = "0"
      } else if (columnDetails[col].type === "number") {
        vals[col] = parseFloat(initialTrace[col])
      } else if (columnDetails[col].type === "string") {
        vals[col] = initialTrace[col]
      }
    })
    setValues(vals)
  }, [columns, initialTrace, columnDetails])

  const handleCancel = () => {
    setOpen(false)
  }
  const handleOk = () => {
    let result = {
      name: traceName,
      data: {},
      output: {},
      loading: true
    }
    columns.forEach(col => {
      if (columnDetails[col].type === "number") {
        result.data[col] = parseFloat(values[col])
      } else if (columnDetails[col].type === "string") {
        result.data[col] = values[col]
      }
    })
    onSuccess(result, traceNumber)
    setOpen(false)
  }
  const handleChange = (col) => (ev) => {
    let vals = { ...values }
    vals[col] = ev.target.value
    setValues(vals)
  }
  const changeName = (ev) => {
    setTraceName(ev.target.value)
  }

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-addtrace-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-addtrace-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom style={{ textAlign: "left" }}>
          These values are held constant when sweeping the primary X input variable.
        </Typography>
        <TextField
          id={"fixed_field_add_name"}
          data-cy={"fixed_field_add_name"}
          label={"TraceName"}
          InputLabelProps={{ shrink: true }}
          margin="normal"
          value={traceName}
          onChange={changeName}
          className={classes.textField}
        />
        {columns.map((col) => {
          if (columnDetails[col].type === "number") {
            return (
              <TextField
                key={"fixed_field_add_" + col}
                id={"fixed_field_add_" + col}
                data-cy={"fixed_field_add_" + col}
                label={col}
                type="number"
                InputLabelProps={{ shrink: true }}
                margin="normal"
                onChange={handleChange(col)}
                value={values[col]}
                className={classes.textField}
              />
            )
          } else if (columnDetails[col].type === "string") {
            return (
              <div style={{ "marginTop": "5px", "marginBottom": "8px" }}>
                <InputLabel shrink id={"fixed_field_add_label_" + col}>
                  {col}
                </InputLabel>
                <Select
                  labelId={"fixed_field_add_label_" + col}
                  key={"fixed_field_add_" + col}
                  id={"fixed_field_add_" + col}
                  data-cy={"fixed_field_add_" + col}
                  label={col}
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  onChange={handleChange(col)}
                  value={values[col]}
                  className={classes.textField}
                >
                  {columnDetails[col].options.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )
          } else {
            return <></>
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCopy} className={classes.copyButton} variant="contained" disabled={onCopy === undefined}>
          Copy
        </Button>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}