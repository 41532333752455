import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSnackbar } from "notistack"

import SortDescendingIcon from 'mdi-material-ui/SortDescending';
import CollapseIcon from 'mdi-material-ui/PageNextOutline';
import SetMergeIcon from 'mdi-material-ui/SetMerge';

import { IconButton, Tooltip, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import SaveIcon from '@material-ui/icons/Save';

import { FlowChartWithActions } from 'react-flow-chart-mmm';

import { FlowChart } from "../../schema/FlowChart";
import { useLocalForageState } from 'utils/useLocalforageState';
import { emAxios } from '../../API/mmAxios';

import Node from './Node';
import NodeInner from './NodeInner';
import Link from './Link';
import Port from './Port';
import Ports from './Ports';
import CanvasOuter from './CanvasOuter';
import { getInnerComponent } from './InnerComponents';
import { autoScale, autoArrange } from './flowChartUtils';
import { PROCESS_CHART_INFO_PLACEHOLDER_OFFSET } from '../General/NodeProperties';


const BASE_FC_USER_PREFS_URL = `${process.env.REACT_APP_EM_API_URL}user_preferences/sample_sets`;


const useStyles = makeStyles(theme => ({
    toolbarButton: {
        opacity: 0.3,
        "&:hover": {
            opacity: 1.0,
        }
    },
    toolbar: {
        display: "flex",
        flexDirection: "row-reverse",
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    noNodeMessage: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    noNodeText: {
        textAlign: "center",
        color: "#808080",
    },
    container: {
        border: `solid ${theme.palette.grey[400]} 1px`,
        borderRadius: "8px",
        overflow: "hidden",
        position: "relative",
        flex: "1 1",
        "&:hover": {
            borderColor: theme.palette.action.active,
        },
        "&.focused": {
            borderColor: theme.palette.primary.main,
            borderWidth: "2px",
            marginTop: "-1px",
            marginLeft: "-1px",
        }
    }
}));

const defaultConfig = {
    getInnerComponent,
    validateLink: ({ chart, fromNodeId, fromPortId, toNodeId, toPortId }) => {
        if (chart.nodes[fromNodeId]?.ports[fromPortId]?.type === chart.nodes[toNodeId]?.ports[toPortId]?.type) {
            return false;
        }
        if (Object.values(chart.links).find(link =>
            link.from.nodeId === fromNodeId &&
            link.to.nodeId === toNodeId &&
            link.from.portId === fromPortId &&
            link.to.portId === toPortId)) {
            return false;
        }
        return true;
    },
};

export default function ProcessFlowChart({
    config,
    chart,
    onChange,
    onMerge,
    readOnly = false,
    hideToolBar = false,
    sampleSetId = undefined,
    ...rest
}) {
    const classes = useStyles();
    const containerRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    // State and cache management for handling process chart design / alignment
    const [chartFocused, setChartFocused] = useState(false);
    const [collapse, setCollapse] = useLocalForageState("FlowChartCollapse", false);

    const [userPrefsLoading, setUserPrefsLoading] = useState(true);
    const [needsUserPrefs, setNeedsUserPrefs] = useState(true);
    const [hasNotQueried, setHasNotQueried] = useState(true);

    const [triggerAutoChart, setTriggerAutoChart] = useState(false);
    const triggerAutoChartRef = useRef(triggerAutoChart);

    const callbacks = useMemo(() => {
        const callbacks = {};
        const off = () => chart => chart;
        if (readOnly) { // override and turn off edit actions when read only
            callbacks["onDeleteKey"] = off
            callbacks["onLinkStart"] = off
            callbacks["onLinkComplete"] = off
            callbacks["onCanvasDrop"] = off
        }
        if (!chartFocused) callbacks["onCanvasWheel"] = off
        return callbacks;
    }, [chartFocused, readOnly])

    const chartRef = useRef(chart)
    useEffect(() => { chartRef.current = chart }, [chart])

    const onAutoscale = useCallback((ev) => {
        ev?.stopPropagation();
        if (containerRef.current) {
            const newChart = autoScale(chartRef.current, { width: containerRef.current.clientWidth, height: containerRef.current.clientHeight })
            onChange(newChart, "autoscale")
            setTriggerAutoChart(!triggerAutoChartRef.current);
        }
    }, [onChange])


    const onAutoArrange = useCallback((ev) => {
        ev?.stopPropagation();
        let newChart = autoArrange(chartRef.current)
        if (containerRef.current)
            newChart = autoScale(newChart, { width: containerRef.current.clientWidth, height: containerRef.current.clientHeight })
        onChange(newChart, "autoscale");
        setTriggerAutoChart(!triggerAutoChartRef.current);
    }, [onChange])


    const onDelete = useCallback((ev) => {
        ev.stopPropagation();
        const chart = chartRef.current
        const newChart = new FlowChart({ ...chart, nodes: { ...chart.nodes }, links: { ...chart.links } });
        for (const id of Object.keys(newChart.selected.nodes || {})) {
            delete newChart.nodes[id];
            for (const linkId of Object.keys(newChart.links)) {
                if (newChart.links[linkId].to?.nodeId === id || newChart.links[linkId].from?.nodeId === id) {
                    delete newChart.links[linkId];
                }
            }
        }
        if (newChart.selected.type === "link") {
            delete newChart.links[newChart.selected.id];
        }
        newChart.selected = {};
        onChange(newChart, "delete");
    }, [onChange]);


    const mergeable = React.useMemo(() => {
        if (!onMerge || !chart.selected?.nodes) return false;
        const nodeIds = Object.keys(chart.selected.nodes);
        if (nodeIds.length < 2) return false;
        const type = chart.nodes[nodeIds[0]].type;
        return nodeIds.every(id => chart.nodes[id].type === type);
    }, [onMerge, chart.selected.nodes, chart.nodes]);


    const handleMerge = useCallback(ev => {
        ev.stopPropagation();
        const mergedNodeIds = Object.keys(chart.selected.nodes);
        onMerge(mergedNodeIds, mergedNodeIds[0]);
    }, [chart.selected.nodes, onMerge]);

    // check for scroll outside of window
    useEffect(() => {
        const handleWheel = ev => {
            if (chartFocused && containerRef.current && !containerRef.current.contains(ev.target)) {
                setChartFocused(false)
            }
        }
        document.addEventListener("wheel", handleWheel)
        return () => document.removeEventListener("wheel", handleWheel)
    }, [chartFocused])


    const handleCollapse = useCallback(() => {
        setCollapse(!collapse);
        setTriggerAutoChart(!triggerAutoChartRef.current);
    }, [setCollapse, collapse]);

    const resetChartSize = useCallback(() => {
        const newChart = { ...chart }
        newChart.nodes = { ...newChart.nodes }
        for (const id of Object.keys(newChart.nodes)) {
            newChart.nodes[id] = { ...newChart.nodes[id], size: undefined }
        }
        onChange(newChart);
    }, [chart, onChange]);

    const forceUpdateUserPreferences = useCallback(() => {
        if (sampleSetId) {
            const sampleSetUserPreferenceData = { processChart: chartRef.current };
            sampleSetUserPreferenceData["chartContainer"] = {
                width: containerRef.current.clientWidth,
                height: containerRef.current.clientHeight + PROCESS_CHART_INFO_PLACEHOLDER_OFFSET
            };
            sampleSetUserPreferenceData["id"] = sampleSetId;
            sampleSetUserPreferenceData["collapsedNodes"] = collapse;

            emAxios
                .post(`${BASE_FC_USER_PREFS_URL}/${sampleSetId}`, sampleSetUserPreferenceData)
                .then(res => {
                    console.log("posted chart to user preferences", res.data);
                    enqueueSnackbar(`Chart Arrangement saved`, { variant: "success" });
                }).catch(error => {
                    console.log("error in posting to user preferences", error);
                    enqueueSnackbar(`Chart Arrangement failed to save`, { variant: "error" });
                })
        }
    }, [sampleSetId, enqueueSnackbar, collapse]);

    // reset node sizes when collapse state changes
    const prevCollapseRef = useRef(collapse)
    useEffect(() => {
        if (collapse !== prevCollapseRef.current) {
            resetChartSize();
            prevCollapseRef.current = collapse
        }
    }, [chart, collapse, onChange, resetChartSize])


    if (needsUserPrefs && hasNotQueried) {
        // needsUserPrefs is to prevent the chart object from being rendered before the User Preferences
        // formatting is retrieved. hasNotQueried prevents this request from firing more than once per
        // process chart load.
        if (sampleSetId) {
            emAxios
                .get(`${BASE_FC_USER_PREFS_URL}/${sampleSetId}`)
                .then(res => {
                    // Pull the saved chart layout from S3 / User Preferences
                    let newChart = Object.keys(res.data).length === 0 ? chart : res.data;
                    if (Object.keys(res.data).length > 0) {
                        if (res.data.processChart && Object.keys(res.data?.processChart).length > 0) {
                            newChart = res.data.processChart;
                        }
                        // Boo backward compatibility...
                        if (res.data.chartObject && !res.data.processChart && Object.keys(res.data?.chartFocused).length > 0) {
                            // The original name for this key was "chartObject", but in adding more potential settings stored,
                            // I inadvertently changed the name. Go me! so add some backward compatibility that will be wiped on
                            // the next save. -NGA
                            newChart = res.data.chartObject;
                        }
                        setCollapse(res.data.collapsedNodes ? res.data.collapsedNodes : false);
                    }

                    if (Object.keys(chart.nodes).length === Object.keys(newChart.nodes).length) {
                        // If you save the chart, then add something, but then don't save the chart
                        // the node will be missing even though it exists.
                        chart = newChart;
                    }
                    onChange(chart, "autoscale");
                    setUserPrefsLoading(false);
                    setNeedsUserPrefs(false);
                }).catch(error => {
                    console.log("error", error);
                    setUserPrefsLoading(false);
                    setNeedsUserPrefs(false);
                })
        } else {
            setUserPrefsLoading(false);
            setNeedsUserPrefs(false);
        }
        setHasNotQueried(false);
    }

    const localConfig = React.useMemo(() => config ? { ...defaultConfig, ...config, collapseInfo: collapse, resetSizeFunc: resetChartSize } : { defaultConfig, collapseInfo: collapse, resetSizeFunc: resetChartSize }, [config, collapse, resetChartSize]);

    return (
        <div ref={containerRef} className={clsx(classes.container, chartFocused && "focused")} data-testid="process-flow-container">
            <ClickAwayListener onClickAway={() => chartFocused && setChartFocused(false)}>
                <div style={{ position: "absolute", top: 0, left: 0 }} onClick={() => !chartFocused && setChartFocused(true)}>
                    {!userPrefsLoading &&
                        <FlowChartWithActions callbacks={callbacks} chart={chart} onChange={onChange} Components={{ Node, NodeInner, Link, Port, Ports, CanvasOuter }} {...rest} config={localConfig} />
                    }
                </div>
            </ClickAwayListener>
            {!hideToolBar &&
                <FlowChartToolbar
                    onAutoscale={onAutoscale}
                    onAutoArrange={onAutoArrange}
                    handleMerge={handleMerge}
                    handleCollapse={handleCollapse}
                    onDelete={onDelete}
                    mergeable={mergeable}
                    readOnly={readOnly}
                    deleteable={chart.selected.id && chart.selected.id !== ""}
                    userPrefFire={forceUpdateUserPreferences}
                />
            }
        </div>
    );
}

const FlowChartToolbar = React.memo(({
    onAutoscale,
    onAutoArrange,
    handleMerge,
    handleCollapse,
    onDelete,
    mergeable,
    readOnly,
    deleteable,
    userPrefFire,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.toolbar}>
            <Tooltip title="Save Chart Arrangement (saves only chart not table or data)">
                <IconButton onClick={userPrefFire} size="small" className={classes.toolbarButton}>
                    <SaveIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Autoscale">
                <IconButton onClick={onAutoscale} size="small" className={classes.toolbarButton}>
                    <AspectRatioIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Auto-arrange chart">
                <IconButton onClick={onAutoArrange} size="small" className={classes.toolbarButton}>
                    <SortDescendingIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Collapse Node Info">
                <IconButton onClick={handleCollapse} size="small" className={classes.toolbarButton}>
                    <CollapseIcon />
                </IconButton>
            </Tooltip>
            {mergeable &&
                <Tooltip title="Merge Selected Nodes">
                    <IconButton onClick={handleMerge} size="small" className={classes.toolbarButton}>
                        <SetMergeIcon />
                    </IconButton>
                </Tooltip>
            }
            {!readOnly && deleteable &&
                <Tooltip title="Delete Selected">
                    <IconButton onClick={onDelete} size="small" className={classes.toolbarButton}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            }
        </div>
    )
})