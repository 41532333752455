/**
 * @format
 */
import React, { useMemo, useEffect, useState, useRef } from "react"

import StyledHoTRaw from "../HoT/StyledHoT"
import TableButtonGroup from "components/General/TableButtonGroup"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { makeStyles, Tooltip } from "@material-ui/core"
import WrapIcon from "@material-ui/icons/WrapText"
import TransposeIcon from "mdi-material-ui/RotateLeftVariant"
import { Sample, SampleSet } from "schema/models"
import { ColumnInfo, Options, useStyledHoT } from "components/HoT/HoTUtils"
import { deepEqual } from "utils/utils"
import StyledAutocomplete from "components/General/StyledAutocomplete"
import { commonProperties } from "components/PropertyLibrary/commonProperties"

const StyledHoT: any = StyledHoTRaw

const ROW_HEADER_LABELS = [""]

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    toggleGroup: {
        margin: theme.spacing(0, 0.5),
    },
    toggleButton: {
        padding: theme.spacing(1),
        lineHeight: "initial",
    },
    tableButtons: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    toggleContainer: {
        display: "flex",
    },
    selectorContainer: {
        width: "300px",
    },
}))

export type ComponentSummaryTableProps = {
    sampleSet: SampleSet
    materialDefs?: Sample[]
    height?: number
    setDataHeight?: (height: number) => void
    minRows?: number
    minEmptyRows?: number
    readOnly?: boolean
    onTableSelect?: (selected?: {
        samples: number[]
        columns: number[]
    }) => void
}
export function ComponentSummaryTable({
    sampleSet,
    materialDefs,
    height,
    setDataHeight,
    minRows,
    minEmptyRows,
    readOnly,
    onTableSelect,
}: ComponentSummaryTableProps) {
    const classes = useStyles()
    const [options, setOptions] = useState<Options[]>(() => [])
    const [selected, setSelected] = useState(ALL_SAMPLES)
    const sampleOptions = useSampleOptions(sampleSet.samples)
    const components = useComponentSummary(sampleSet, materialDefs, selected)

    const columnsInfo = useColumnsInfo(readOnly)

    const {
        tableRef,
        data,
        columns,
        rowHeaders,
        cells,
        mergeCells,
        afterChange,
        afterSelection,
        afterDeselect,
        transpose,
    } = useStyledHoT({
        rows: components,
        columnsInfo,
        options,
        colHeaders: 1,
        rowHeaderLabels: ROW_HEADER_LABELS,
        minRows,
        minEmptyRows,
    })

    useEffect(() => {
        setDataHeight && setDataHeight(data.length)
    }, [data.length, setDataHeight])

    return data.length > 0 && (columns?.length || 0) > 0 ? (
        <div className={classes.container}>
            <TableButtonGroup
                // @ts-ignore
                left={
                    <div className={classes.selectorContainer}>
                        <StyledAutocomplete
                            // @ts-ignore
                            fullWidth
                            label="Sample"
                            // @ts-ignore
                            options={sampleOptions}
                            // @ts-ignore
                            value={
                                sampleSet.samples?.length === 1
                                    ? sampleOptions[1]
                                    : !sampleSet.samples?.length
                                    ? ALL_SAMPLES
                                    : selected
                            }
                            onChange={(ev: any, vals: any) =>
                                setSelected(vals || ALL_SAMPLES)
                            }
                            disabled={sampleSet.samples?.length === 1}
                        />
                    </div>
                }
                // @ts-ignore
                right={
                    <div className={classes.toggleContainer}>
                        <ToggleButtonGroup
                            className={classes.toggleGroup}
                            value={options}
                            onChange={(ev, selected) => setOptions(selected)}
                        >
                            <ToggleButton
                                className={classes.toggleButton}
                                value="Wrap"
                            >
                                <Tooltip
                                    title="Toggle Text Wrapping"
                                    placement="top"
                                >
                                    <WrapIcon fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                                className={classes.toggleButton}
                                value="Transpose"
                            >
                                <Tooltip
                                    title="Transpose Table"
                                    placement="top"
                                >
                                    <TransposeIcon fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                }
            ></TableButtonGroup>
            <StyledHoT
                ref={tableRef}
                height={height}
                data={data}
                columns={columns}
                colHeaders={transpose ? rowHeaders : true}
                rowHeaders={transpose ? true : rowHeaders}
                rowHeaderWidth={transpose ? undefined : 60}
                cells={cells}
                mergeCells={mergeCells}
                afterChange={afterChange}
                outsideClickDeselects
                parentOverflow="horizontal"
                stretchH="last"
                autoColumnSize={true}
                fixedColumnsLeft={1}
                fixedRowsTop={1}
                observeChanges
                afterSelection={afterSelection}
                afterDeselect={afterDeselect}
            />
        </div>
    ) : null
}
export default ComponentSummaryTable

type ComponentSummary = Sample & {
    total?: number
    units?: string
}

const ALL_SAMPLES = { id: "all", title: "All Samples", index: -1 }
function useSampleOptions(samples: Sample[] | undefined) {
    return useMemo(() => {
        const options = [ALL_SAMPLES]
        if (!samples) return options
        options.push(
            ...samples.map((s, i) => ({
                index: i,
                id: s.id || String(i),
                title: s.title || s.qid || String(i),
            })),
        )
        return options
    }, [samples])
}

function useComponentSummary(
    sampleSet: SampleSet,
    materialDefs: Sample[] | undefined,
    selected?: { index: number },
) {
    const prev = useRef<ComponentSummary[]>()
    const compSummary = useMemo(() => {
        let newSummary: ComponentSummary[] = []
        if (sampleSet.samples) {
            const compMap = new Map<string, ComponentSummary>()
            function addSample(sample: Sample) {
                if (sample.components) {
                    for (const comp of sample.components) {
                        if (comp.amount && comp.definition?.id) {
                            const key = comp.definition.id + (comp.units || "")
                            let current = compMap.get(key)
                            if (!current) {
                                const def = materialDefs?.find(
                                    def => def.id === comp.definition?.id,
                                )
                                if (!def) continue
                                current = {
                                    ...def,
                                    units: comp.units || "g",
                                    total: 0,
                                }
                                compMap.set(key, current)
                            }
                            ;(current as ComponentSummary).total =
                                (current.total || 0) + comp.amount
                        }
                    }
                }
            }
            if (selected?.index === undefined || selected.index < 0) {
                for (const sample of sampleSet.samples) {
                    addSample(sample)
                }
            } else if (selected && sampleSet.samples[selected.index]) {
                addSample(sampleSet.samples[selected.index])
            }

            newSummary = Array.from(compMap.entries())
                .sort()
                .map(v => v[1])
        }
        if (
            prev.current &&
            newSummary.length === prev.current.length &&
            newSummary.every((v, i) => deepEqual(v, prev.current?.[i]))
        ) {
            return prev.current
        }
        return newSummary
    }, [materialDefs, sampleSet.samples, selected])
    useEffect(() => {
        prev.current = compSummary
    })
    return compSummary
}

function useColumnsInfo(readOnly: boolean | undefined) {
    return useMemo(() => {
        // identifier columns
        const columnsInfo: ColumnInfo[] = [
            {
                type: "text",
                headerRows: [
                    {
                        id: "property",
                        title: "Component",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetKey("title", "*Missing Label*"),
                width: 130,
                className: "innerColHeader",
            },
            {
                type: "text",
                headerRows: [
                    {
                        id: "units",
                        title: "Units",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetKey("units", ""),
                width: 130,
                className: "innerColHeader",
            },
            {
                type: "text",
                headerRows: [
                    {
                        id: "value",
                        title: "Total",
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildGetKey("total"),
                width: 130,
                className: "innerRowSubHeader",
            },
        ]

        for (const prop of Object.values(commonProperties)) {
            columnsInfo.push({
                type: "text",
                headerRows: [
                    {
                        id: prop,
                        title: prop,
                        className: "htCenter groupHeader",
                    },
                ],
                get: buildPropertyGet(prop),
            })
        }

        columnsInfo.push({
            type: "text",
            headerRows: [
                {
                    id: "notes",
                    title: "Notes",
                    className: "htCenter groupHeader",
                },
            ],
            get: buildGetKey("notes"),
            width: 130,
            className: "innerRowSubHeader",
        })

        // handle readonly
        if (readOnly) {
            columnsInfo.forEach(ci => {
                if (ci.set) ci.set = undefined
                ci.headerRows.forEach(hr => {
                    if (hr?.set) hr.set = undefined
                })
            })
        }

        return columnsInfo
    }, [readOnly])
}

// headers getters and setters
function buildGetKey(key: keyof ComponentSummary, defaultValue: any = "") {
    return (instance?: ComponentSummary) => {
        return instance?.[key] || defaultValue
    }
}

// headers getters and setters
function buildPropertyGet(key: string, defaultValue: any = "") {
    return (instance?: ComponentSummary) => {
        return (
            instance?.properties?.find(p => p.definition?.title === key)
                ?.data || defaultValue
        )
    }
}
