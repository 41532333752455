import { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';

import MultiplotOfMultiplots from './Utilities/MultiplotOfMultiplots';

export const MultiPlotMaterial = ({ material }) => {
    // Used for looking at multiplots of a sample.

    const tests = useMemo(() => {
        if (!material.tests) return []
        const testMap = new Map()
        for (const test of material.tests) {
            if (test.id)
                testMap.set(test.id, test)
        }
        return Array.from(testMap.values())
    }, [material.tests])

    return (
        <div> 
            {tests?.length === 0 && <Typography>No multiplot data available.</Typography>}
            <MultiplotOfMultiplots tests={tests} title={`Tests of ${material.title || "Sample"}`} />   
        </div>)
};

export const MultiPlotSampleSet = ({ sampleSet }) => {
    // Used for looking at multiplots of a Sample Set.
    
    const tests = useMemo(() => {
        if (!sampleSet.samples?.length) return []
        const testMap = new Map()
        for (const sample of sampleSet.samples) {
            if (!sample?.tests) continue
            for (const test of sample.tests) {
                if (test.id)
                    testMap.set(test.id, test)
            }
        }
        return Array.from(testMap.values())
    }, [sampleSet.samples])

    return (
        <div>
            {tests?.length === 0 && <Typography>No multiplot data available.</Typography>}
            <MultiplotOfMultiplots tests={tests} title={`Tests of ${sampleSet.title || "Sample Set"}`} />
        </div>
    )
};
