import React, { useState, useMemo } from 'react';
import FreeSoloSelector from './FreeSoloSelector';
import { useQueryAutocomplete } from '../../API/queryHooks';
import {matchSorter} from 'match-sorter';
import MultiSelector from './MultiSelector';

const staticArray = [];
const userParams = {Model: "User", fields: ["name"], sort: "name"};


function filterOptions(options, {inputValue}){
    return matchSorter(options, inputValue);
}


export default function UserSelector({label, onChange, value, ...remainingProps }){
    const [inputValue, setInputValue] = useState("");
    const queryValue = useMemo(() => ({name: inputValue}), [inputValue]);
    const { data: {data = staticArray} = {}, isFetching } = useQueryAutocomplete(queryValue, userParams, {logName: "User Filter"});
    const options = useMemo(() => data.map(d => d.name),[data]);    
    return (
        <FreeSoloSelector 
            label={label || "User(s)"} 
            value={value}
            options={options}
            filterOptions={filterOptions}
            loading={isFetching}
            size="small"
            onBlur={(ev) => {setInputValue(""); onChange && inputValue !== "" && onChange(ev, value.concat(inputValue))}}
            onChange={(ev, newVal) => {onChange && onChange(ev, newVal); setInputValue("")}}
            inputValue={inputValue}
            onInputChange={(ev, newVal) => setInputValue(newVal || ev.target.value || "")}
            data-testid="user selector"
            {...remainingProps}/>
    )
}

/**
 * React Component for performing a multiselect on on users
 * @param props most props forwared to MultiSelector
 * @param props.selected forwared as the value prop
 */
export function FullUserSelector({selected, onChange, filter, ...remainingProps }){
    const [input, setInput] = useState({});
    const params = useMemo(() => ({
        Model: "User",
        filter,
    }), [filter])
    const { data: {data: options} = {}, isFetching } = useQueryAutocomplete(input, params, {logName: "User Selector"});
    return (
        <MultiSelector
            label="User(s)"
            missingLabel="Missing User"
            loading={isFetching}
            options={options || []}
            value={selected || []}
            onBlur={() =>  setInput({})}
            onChange={(...args) => {onChange && onChange(...args); setInput({})}}
            inputValue={input.name || ""}
            onInputChange={(ev, newVal) => {ev && setInput({name: ev?.target?.value || ""})}}
            data-testid="full user selector"
            keys={["name"]}
            {...remainingProps}
            />
    );
}