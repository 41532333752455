import React, { useRef, useMemo, useLayoutEffect } from 'react';
import SmilesDrawer from 'smiles-drawer';
let globalIdIndex = 1;

/**
 * React wrapper for SmilesDrawer
 * @param {string} smiles the smiles string to display
 * @param {"light" | "dark"} theme color theme, default light
 * @param options object forwarded to SimplesDrawer, defaults empty
 */
export default React.memo(function SmilesSVG({smiles, theme="light", options={}}){
    const svgRef = useRef();
    const idRef = useRef();
    if (!idRef.current) {
        idRef.current = "smilesSVG-" + globalIdIndex;
        globalIdIndex++;
    }
    
    const svgDrawer = useMemo(() => new SmilesDrawer.SvgDrawer(options), [options]);
    useLayoutEffect(() => {
        SmilesDrawer.parse(smiles, tree => {
            svgDrawer.draw(tree, svgRef.current.id, theme, false);
        })
    }, [svgDrawer, smiles, theme]);
    return <svg id={idRef.current} ref={svgRef} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"/>
})