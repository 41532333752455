import React from "react";
import ButtonWithSpinner from "components/General/ButtonWithSpinner";

import { ButtonGroup, ButtonProps, Popover } from "@material-ui/core";

import { SampleSet } from "schema/models";
import { aichemyPayload, ColumnInfo } from "components/HoT/HoTUtils";

import { aichemyProtoAxios } from "API/mmAxios";
import { navigate } from "@reach/router";
import { useSnackbar } from "notistack";
import { useAppStoreDispatchKey, useAppStoreKey } from "AppStore";
import exportExcelSet from "./excelExportSet";

const VOYAGER_URL_BASE = process.env.REACT_APP_VOYAGER_REQUEST_URL
const MISTY_URL_BASE = process.env.REACT_APP_DASH_FRONTEND_URL

type ExportPopoverProps = {
    sampleSet: SampleSet
    loading: boolean
    onLoading: any
    open: any
    onOpen: any
    columnsInfo: ColumnInfo[]
    updateSampleSet: any
    enqueueDialogs: any
}

export function ExportPopover({
    sampleSet,
    open,
    onOpen,
    loading,
    onLoading,
    columnsInfo,
    updateSampleSet,
    enqueueDialogs,
}: ExportPopoverProps) {
    const handleClose = () => {
        onOpen(null)
    }
    return (
        <Popover
            open={!!open}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
        >
            <ButtonGroup color={"primary"} variant="outlined">
                <ExcelExportButton
                    loading={loading}
                    onLoading={onLoading}
                    sampleSet={sampleSet}
                />
                ,
                <ExportMSButton
                    loading={loading}
                    onLoading={onLoading}
                    sampleSet={sampleSet}
                    columnsInfo={columnsInfo}
                >
                    Mixing Studio
                </ExportMSButton>
                ,
                <VoyagerExportButton
                    loading={loading}
                    onLoading={onLoading}
                    sampleSet={sampleSet}
                />
                 <MistyExportButton
                    loading={loading}
                    onLoading={onLoading}
                    sampleSet={sampleSet}
                />
            </ButtonGroup>
        </Popover>
    )
}

export const ExportMSButton: React.FC<
    {
        sampleSet: SampleSet
        loading: boolean
        onLoading?: (loading: boolean) => void
        columnsInfo: ColumnInfo[]
    } & ButtonProps
> = React.forwardRef(
    ({ sampleSet, loading, onLoading, columnsInfo, ...props }, ref) => {
        const { enqueueSnackbar } = useSnackbar()
        const [modelContext, setModelContext] = useAppStoreKey("ModelContext")
        const setWorkflow = useAppStoreDispatchKey("Workflow")
        const setActiveStep = useAppStoreDispatchKey("ActiveStep")

        return (
            <ButtonWithSpinner
                ref={ref}
                onClick={async () => {
                    onLoading && onLoading(true)
                    const title = sampleSet.title || "Sheet 1"
                    const payload = aichemyPayload(
                        title,
                        sampleSet.samples,
                        columnsInfo,
                    )
                    const blob = new Blob([JSON.stringify(payload)], {
                        type: "application/json",
                    })
                    const formData = new FormData()
                    formData.append("name", sampleSet?.title || "")
                    formData.append("description", sampleSet?.description || "")
                    formData.append("input_file", blob)
                    await aichemyProtoAxios
                        .post(`workflow`, formData, {
                            headers: {
                                "Content-Type":
                                    "multipart/form-data; charset=utf-8",
                            },
                        })
                        .then(res => {
                            const wf = res.data.workflow
                            setWorkflow(wf)
                            setModelContext({
                                ...modelContext,
                                modelName: wf.name,
                                loadedModelData: wf.name,
                            })
                            setActiveStep(1)
                            navigate(`/MixingStudio`)
                        })
                        .catch(error => {
                            console.error(error)
                            enqueueSnackbar(
                                "Error during Mixing Studio export",
                                {
                                    variant: "error",
                                },
                            )
                        })
                    onLoading && onLoading(false)
                }}
                spinnerColor="primary"
                loading={loading}
                {...props}
            />
        )
    },
)

export const ExcelExportButton: React.FC<
    {
        loading: boolean
        onLoading: (val: boolean) => void
        sampleSet: SampleSet
    } & ButtonProps
> = React.forwardRef(({ onLoading, loading, sampleSet, ...props }, ref) => {
    const onExport = async () => {
        onLoading(true)
        try {
            await exportExcelSet({
                sampleSet,
            })
        } catch (error) {
            console.log(error)
        }
        onLoading(false)
    }

    return (
        <ButtonWithSpinner
            ref={ref as any}
            {...props}
            onClick={onExport}
            loading={loading}
        >
            Excel
        </ButtonWithSpinner>
    )
})

export const VoyagerExportButton: React.FC<
    {
        loading: boolean
        onLoading: (val: boolean) => void
        sampleSet: SampleSet
    } & ButtonProps
> = React.forwardRef(({ onLoading, loading, sampleSet, ...props }, ref) => {
    const onExport = async () => {
        onLoading(true)
        let queryString = ""
        if (sampleSet.samples) {
            const uuids = sampleSet.samples.map(sample => sample.id).join(",")
            const qids = sampleSet.samples.map(sample => sample.qid).join(",")
            queryString = `?numSamples=${
                sampleSet.samples.length * 2
            }&sids=${uuids}&qids=${qids}`
        }
        window.open(
            `${VOYAGER_URL_BASE}${queryString}`,
            "_blank",
            "noopener, noreferrer",
        )
        onLoading(false)
    }

    return (
        <ButtonWithSpinner
            ref={ref as any}
            {...props}
            onClick={onExport}
            loading={loading}
        >
            Voyager
        </ButtonWithSpinner>
    )
})

export const MistyExportButton: React.FC<
    {
        loading: boolean
        onLoading: (val: boolean) => void
        sampleSet: SampleSet
    } & ButtonProps
> = React.forwardRef(({ onLoading, loading, sampleSet, ...props }, ref) => {
    const onExport = async () => {
        onLoading(true)
        window.open(
            `${MISTY_URL_BASE}?ssid=${sampleSet.id}`,
            "_blank",
            "noopener, noreferrer",
        )
        onLoading(false)
    }

    return (
        <ButtonWithSpinner
            ref={ref as any}
            {...props}
            onClick={onExport}
            loading={loading}
        >
            COMPILE TEST VIEW
        </ButtonWithSpinner>
    )
})
