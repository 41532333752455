import React from 'react';
import MixingStudioProtoModelStepper from '../MixingStudio-proto/MixingStudioProtoModelStepper';

import { makeStyles } from '@material-ui/core/styles';
import common_styles from '../../styles/common_styles';

const useStyles = makeStyles(common_styles);

function MixingStudioProto({ navigate }) {
    const classes = useStyles();

    return (
        <div className={classes.layout}>
            <MixingStudioProtoModelStepper navigate={navigate} />
        </div>
    );
}

export default React.memo(MixingStudioProto);