import {useRef, useEffect, useCallback, useState} from 'react';
export * from './deepmerge'

const isIE = /*@cc_on!@*/false || !!document.documentMode;
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

function fallbackCopyTextToClipboard(text) {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Copy to clipboard failed', err);
  }
  document.body.removeChild(textArea);
}
export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return Promise.resolve();
  }
  return navigator.clipboard.writeText(text);
}

/**
 * Curstom react hook used for debugging/optimizing React renders. Prints to the console the props that changed to call a render.
 * @param {*} props React props passed to the function component to be check for differences
 */
export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v, deepDifference(prev.current[k], v)];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        console.log('Changed props [old, new, diff]:', changedProps);
      }
      prev.current = props;
    });
  }

  /**
 * Hook to debounce a callback. Only the most recent callback will trigger after the timer runs down.
 * @param {function} func callback function
 * @param {[]} dependencies *unused* - makes compatible with useCallback
 * @param {Number} debounceDelay timer value passed to setTimeout
 */
export function useDebounceCallback(func, dependencies, debounceDelay = 500){
  const timerRef = useRef();
  const funcRef = useRef(func);
  useEffect(() => {funcRef.current = func}, [func]);
  useEffect(() => () => timerRef.current && clearTimeout(timerRef.current), []); // clear any debounce on unmount
  return useCallback((...args) => {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => funcRef.current(...args), debounceDelay, ...args);
  }, [debounceDelay]);
}

/**
 * Function for debouncing a set state call. An internal buffer is used to keep local state updated quickly while debouncing the setValue calls.
 * @param {*} value - external state value
 * @param {*} setValue - callback to set external state (this call will be debounced)
 * @param {*} debounceDelay - timer delay used for debounce
 */
export function useDebounceState(value, setValue, debounceDelay = 500){
  const [buffer, setBuffer] = useState(value);
  const prevValue = usePrevious(value);
  let timerRef = useRef();
  const update = useCallback((value, callSet = true) => {
    setBuffer(value);
    timerRef.current && clearTimeout(timerRef.current);
    if (callSet)
      timerRef.current = setTimeout(setValue, debounceDelay, value);
  }, [setValue, debounceDelay]);
  useEffect(()=>()=>timerRef.current && clearTimeout(timerRef.current), []); // clear any remaining timeout on unmount
  if (prevValue !== value && value !== buffer){ // value has changed externally, update buffer if necessary.
    setBuffer(value);
    return [value, update];
  }
  return [buffer, update, prevValue !== value];
}

/**
 * Custom react hook for capturning a value from the previous render.
 * @param {*} value - value to be stored and loaded from previous render
 * @return {*} previous value
 */
export function usePrevious(value){
    const ref = useRef(value);
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
}

/** Hook that returns a Ref that's true if the component is mounted and false otherwise */
export function useMounted() {
  const mounted = useRef(true)
  useEffect(() => () => mounted.current = false, [])
  return mounted
}

/** Makes a callback reference stable by wrapping a reference. Note the callback function will only be updated when a render completes and will not trigger downstream re-renders since it's reference stable. */
export function useStabilizedCallback(callback) {
  const ref = useRef(callback)
  useEffect(() => {ref.current = callback})
  return useCallback((...args) => ref.current(...args), [])
}

/**
 * Hook to create a local buffer of a prop. The state can be locally changed with setBuffer without affecting the prop. If the prop changes, the local value will update.
 * @param {*} value - input value. The returned value will intially match value.
 * @return {[buffer, setBuffer]} - returned local value and function to change only the local value.
 */
export function useBufferState(value){
  const [buffer, setBuffer] = useState(value);
  const prev = useRef(value)
  useEffect(() => {
    if (value !== prev.current) {
      prev.current = value
      if (value !== buffer) {
        setBuffer(value)
      }
    }
  }, [buffer, value])
  return [buffer, setBuffer];
}

/**
 * Call file save
 * @param {*} blob
 * @param {*} filename
 */
export function saveNonImageFile(blob, filename) {
    if (isEdge || isIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else {
      const objectURL = URL.createObjectURL(blob);
      saveDataURL(objectURL, filename);
    }
};
export function saveDataURL(dataURL, filename) {
  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = dataURL;
  anchor.target = '_blank';
  anchor.download = filename;
  anchor.click();
  document.body.removeChild(anchor);
}

/**
 * Map function for objects {[keys]: val} => {[keys]: func(val)}
 * @param {*} object - object to be mapped
 * @param {*} func - callback run on each key value
 */
export function mapObject(object, func){
  return object && Object.assign({}, ...Object.entries(object).map(([k, v]) => ({[k]: func(v,k)})));
}

/**
 * Allow dynamic programatic access to nested object keys using "." delimited string. Will return undefined if key is missing at any level.
 * @param {string} key - nested key string
 * @param {*} object - object to apply key to
 */
export function nestedKey(key, object) {
  if (object === undefined || key === undefined) return undefined;
  if (key === "") return object;
  if (!object) return undefined;
  const match = key.match(/(\.)|(\[\])|\[\{([a-z0-9,_-\s()[\].]*)\}\]/i);
  if (!match)
    return object[key];
  const beforeToken = key.substring(0, match.index);
  const afterToken = key.substring(match.index + match[0].length);
  const obj = beforeToken === "" ? object : object[beforeToken];
  if (obj === undefined) return undefined
  if (match[1]){ // token is "."
    return nestedKey(afterToken, obj);
  }
  if (match[2]){ // token is "[]"   
    return Array.isArray(obj) ? obj.map(o => nestedKey(afterToken, o)) : [nestedKey(afterToken, obj)];
  }
  if (match[3]){ // token is [{key, index, withKeys}] - convert array of objects to object...
    const args = match[3].split(',').map(s => s.trim());
    const objKey = args[0] === "" ? "key" : args[0];
    const arrObj = Array.isArray(obj) ? obj : [obj];
    const index = args[1];
    const copyKeys = args.slice(2);
    return Object.assign({}, ...arrObj.map((o, i) => {
      const newObj = !copyKeys.length ? {...o} : Object.assign({}, ...copyKeys.map(ck => ({[ck]: o[ck]})));
      if (index && index !== "undefined" && index !== "") newObj[index] = i;
      return {[o[objKey]]: newObj};
    }))
  }
  return undefined; // should never get here....
}

/**
 * In place write value to nested object using "." delimited string. Will create new nested objects at all levels if necessary.
 * @param {string} key - nest key string
 * @param {*} value - value to write to key
 * @param {*} object - object to write value to.
 */
export function nestedKeyWrite(key, value, object) {
  if (!key || key === "") return;
  const match = key.match(/(\.)|(\[\])|\[\{([a-z0-9,_-\s()[\].]*)\}\]/i);
  if (!match){ // no token, just use key directly
    object[key] = value;
    return;
  }
  const beforeToken = key.substring(0, match.index);
  const afterToken = key.substring(match.index + match[0].length);
  if (match[1]){ // token is "."
    if (beforeToken === ""){
      return nestedKeyWrite(afterToken, value, object);
    }
    else{
      if (!object[beforeToken]) object[beforeToken] = {};
      return nestedKeyWrite(afterToken, value, object[beforeToken]);
    }
  }
  else if (match[2]){ // token is "[]"
    if (beforeToken === "") throw new Error("Array without key encountered");
    else if (afterToken === ""){
      object[beforeToken] = Array.isArray(value) ? value : [value];
      return;
    }
    else{
      if (!Array.isArray(object[beforeToken])) object[beforeToken] = [];
      if (Array.isArray(value)){
        value.forEach((v, i) => {
          if (!object[beforeToken][i]) object[beforeToken][i] = {};
          nestedKeyWrite(afterToken, v, object[beforeToken][i])
        });
      }
      return;
    }
  }
  else if (match[3]){ // token is [{key, index, withKey}] - expect object for value to write as array of objects
    if (beforeToken === "") throw new Error("Object to Array without key encountered");
    else if (afterToken !== "") throw new Error("Object to Array must be last element");
    else{
      if (value === undefined){
        object[beforeToken] = [];
        return;
      }
      else{
        const args = match[3].split(',').map(s => s.trim());
        const objKey = args[0] === "" ? "key" : args[0];
        const index = args[1];
        const copyKeys = args.slice(2);
        const entries = Object.entries(value);
        if (index) entries.sort((a, b) => a[1][index] < b[1][index] ? -1 : 1);
        object[beforeToken] = entries.map(([k, v]) => {
          if (!copyKeys.length) return ({...v, [objKey]: k});
          return Object.assign({}, ...copyKeys.map(ck => ({[ck]: v[ck]})));          
        });
        return;
      }
    }
  }
  return; // should never get here...
}

/**
 * Rename keys of an object using a key map {[toKey]: fromKey}. Creates a new object but does not deep copy any fields. Arrays can be denoted with "[]"
 * @param {{[string]: string}} keyMap - Object defining rename mappings of the form: {[toKey]: fromKey}. fromKey can reference a nested field.
 * @param {object} object - Object with keys to be renamed.
 * @param {boolean=} reverse - flip the from->to direction of the mapping
 * @return {object} - new remapped object
 */
export function remapKeys(keyMap, object, reverse=false, preappend=undefined) {
  const rtn = {};
  Object.entries(keyMap).forEach(([toKey, fromKey]) => {
    if (typeof toKey !== "string" || typeof fromKey !== "string") return; // only operate on strings in the map
    if (reverse) [toKey, fromKey] = [fromKey, toKey]; // flip to and from if reverse
    const value = nestedKey(fromKey, object);
    if (value !== undefined && value !== null){
      nestedKeyWrite(preappend ? preappend + toKey : toKey, value, rtn);
    }
  });
  return rtn;
}

/**
 * Deep equality check for simple serializable objects
 * @param {*} a 
 * @param {*} b 
 */
export function deepEqual(a, b){
  if (a === b) return true;
  if (typeof a !== typeof b || typeof a !== "object") return false;
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) return false;
  for (let key of aKeys){
    if (!deepEqual(a[key], b[key])) return false;
  }
  return true;
}

/**
 * Inserts escape characters for a regular expression. Taken from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
 * @param {*} string 
 */
export function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Returns true if a string contains only letters, numbers, underscores, or dashes. No spaces, periods, or special characters.
 * @param {*} string 
 */
export function simpleString(string){
  return /^[a-z0-9_-]*$/i.test(string);
}

/**
 * Returns string as lower case, without special characters and spaces replaced with _
 * @param {*} string 
 */
export function simplifyString(string){
  let rtn = string.toLowerCase();
  rtn = rtn.replace(/\s/i, "_");
  rtn = rtn.replace(/[\W]/, "");
  return rtn;
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}
export function isNumber(value) {
  return typeof value === 'number' && isFinite(value);
}
export function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}
export function isBoolean(value) {
  return typeof value === 'boolean';
}
export function isNull(value) {
  return value === null;
}
export function isUndefined(value) {
  return typeof value === 'undefined';
}
export function robustType(value) {
  if (Array.isArray(value)) { return "array" }
  else if (isObject(value)) { return "object" }
  else if (isString(value)) { return "string" }
  else if (isNumber(value)) { return "number" }
  else if (isBoolean(value)) { return "boolean" }
  else if (isNull(value)) { return "null" }
  else if (isUndefined(value)) { return "undefined" }
  else { return typeof value }
}

/**
 * Calculate a Levenshtein distance between a string and a target (https://stackoverflow.com/questions/11919065/sort-an-array-by-the-levenshtein-distance-with-best-performance-in-javascript)
 * @param {*} s 
 * @param {*} t 
 */
export function levDist(s = "", t = "") {
  var d = []; //2d matrix

  // Step 1
  var n = s.length;
  var m = t.length;

  if (n === 0) return m;
  if (m === 0) return n;

  //Create an array of arrays in javascript (a descending loop is quicker)
  for (var i = n; i >= 0; i--) d[i] = [];

  // Step 2
  for (i = n; i >= 0; i--) d[i][0] = i;
  for (var j = m; j >= 0; j--) d[0][j] = j;

  // Step 3
  for (i = 1; i <= n; i++) {
      var s_i = s.charAt(i - 1);

      // Step 4
      for (j = 1; j <= m; j++) {

          //Check the jagged ld total so far
          if (i === j && d[i][j] > 4) return n;

          var t_j = t.charAt(j - 1);
          var cost = (s_i === t_j) ? 0 : 1; // Step 5

          //Calculate the minimum
          var mi = d[i - 1][j] + 1;
          var b = d[i][j - 1] + 1;
          var c = d[i - 1][j - 1] + cost;

          if (b < mi) mi = b;
          if (c < mi) mi = c;

          d[i][j] = mi; // Step 6

          //Damerau transposition
          if (i > 1 && j > 1 && s_i === t.charAt(j - 2) && s.charAt(i - 2) === t_j) {
              d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
          }
      }
  }

  // Step 7
  return d[n][m];
}

/**
 * Does a deep comparison between to object and returns all the parts of objB that are different than objA
 * @param {*} objA 
 * @param {*} objB 
 */
export function deepDifference(objA, objB) {
  if (objA === objB) return undefined
  const typeA = robustType(objA)
  const typeB = robustType(objB)
  if (typeA !== typeB) return objB

  if (typeA === "array") {
    const diffArray = []
    let equal = true
    objA.forEach((v, i) => {
      const diff = deepDifference(v, objB[i])
      if (diff !== undefined || (v !== undefined && objB[i] === undefined)) {
        diffArray[i] = diff
        equal = false
      }
    })
    if (objB.length > objA.length) {
      diffArray.concat(...objB.slice(objA.length))
      equal = false
    }
    return equal ? undefined : diffArray
  }
  if (typeA === "object") {
    const diffObject = {}
    let equal = true
    for (const [key, value] of Object.entries(objA)) {
      const diff = deepDifference(value, objB[key])
      if (diff !== undefined || (value !== undefined && objB[key] === undefined)) {
        diffObject[key] = diff
        equal = false
      }
    }
    for (const [key, value] of Object.entries(objB)) {
      if (objA[key] === undefined && value !== undefined) {
        diffObject[key] = value
        equal = false
      }
    }
    return equal ? undefined : diffObject
  }
  return objB
}

/**
 * Checks if input string is a valid CAS Registry Number
 * http://www.cas.org/support/documentation/chemical-substances/checkdig
 */
export function validateCasNo(cas) {

  const reCasNo = /^(\d{2,7})(-\d{2})(-\d{1})$/;

  // Check if input string is CAS syntax: {2-7 digits}-{2 digits}-{1 digit}
  if (cas?.match?.(reCasNo)) {

      // Check if input string is a valid CAS number

      // Create list of integers from "numeric strings" in CAS input string (remove dashes)
      const filtered = cas.split("").filter(function(value, index, arr){ 
          return value !== "-";
      }).map(x => parseInt(x));

      // Extract "Check Digit" and reverse list order
      const checkDigit = filtered.reverse().shift()

      // Calculate sum of products (digit*index) in reversed order list (index-1)
      const sumCas = filtered.map(function(item, i, arr) {
          return item*(i+1)
      } ).reduce((a, b) => a + b, 0)
      
      // sumCas mod 10 must equal the checkDigit to be a valid CAS no.
      if (sumCas % 10 === checkDigit) {
          return true
      } else {
          return false
      }

  } else {
      return false
  }
}

/**
 * Dual Sort Array of Arrays by Values at Two Indices
 * @param {*} arr 
 * @param {*} idx1 
 * @param {*} idx2 
 */
export function dualSortArrayOfArrays (arr, idx1, idx2) {
  let sortedArray = arr.sort((a, b) => {
    if (a[idx1] === b[idx1]) {
      return a[idx2] - b[idx2];
    }
    return a[idx1] - b[idx1];
  });
  return sortedArray
}

/**
 * Create array of array chunks of defined size
 * @param {*} array 
 * @param {*} size 
 */
export function chunkArray (array, size) {
  let result = []
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result
}

/**
 * Mutate changes array to (mostly) match Excel behavior 
 * @param {*} changes 
 * @param {*} selVals 
 */
export function autoFillDownExcel(changes, selVals) {

  const reEndNum = /^(.*?)(\d+)$/
  selVals.forEach((v, i, arr) => arr[i] = v || "") // null safety
  // Determine ordering and base increment in selVals (if applicable)
  let order = ""
  let diff = []
  let baseIncr = 1
  if (selVals.length === 1) {
    if (!Number(selVals[0]) && selVals[0].match(reEndNum)) {
      order = "increment"
    }
  } else if (selVals.length > 1) {
    if (selVals.every(x => Number(x))) {
      diff = selVals.map((x, i, selVals) => selVals[i+1] ? Number(selVals[i+1]) - Number(x) : 0);

    } else if (selVals.every(x => !Number(x) && x.match?.(reEndNum))) {
      if (selVals.every(x => x.match(reEndNum)[1] === selVals[0].match(reEndNum)[1])) {
        diff = selVals.map((x, i, selVals) => selVals[i+1] && Number(selVals[i+1].match(reEndNum)[2]) ? 
        Number(selVals[i+1].match(reEndNum)[2]) - Number(selVals[i].match(reEndNum)[2]) : 0);
      } 
    }
    if (diff.length >= 1 && diff.slice(0, -1).every(x => x === diff[0])) {
      order = diff[0] > 0 ? "increment" : "decrement"
      baseIncr = diff[0]
    }
  }

  // Create "fillType" array for each cell in selVals
  let fillTypeArr = []
  if (selVals.length > 1 && selVals.every(x => x === selVals[0])) {
    fillTypeArr = selVals.map(val => ["duplicate", val]);
  } else {
    for (let val of selVals) {
      if (Number(val)) { 
        fillTypeArr.push([val?.includes?.(".") ? "float" : "integer", val]);
      } else if (val?.match(reEndNum)) {
        fillTypeArr.push(["endnumber", val]);
      } else {
        fillTypeArr.push(["duplicate", val]);
      }
    }
  }

  // Generate new values list (may be longer than "changes")
  let precision = null
  let baseVal = null
  let endNum = null
  let zeros = null
  let addZeros = null
  let newVals = []
  let incr = 0
  for (let i = 0; i < chunkArray(changes, selVals.length).length; i++) {
    for (let val of fillTypeArr) {

      if (order) {
        incr = baseIncr*(selVals.length*(i + 1)) // baseIncr will be negative for decreasing so same for both order = increasing & decreasing
      } else {
        incr = i + baseIncr
      }

      if (val[0] === "float") {
        precision = (val[1] + "").split(".")[1].length;
        newVals.push(`${(Number(val[1]) + incr).toFixed(precision)}`);
      } else if (val[0] === "integer") {
        newVals.push(`${((Number(val[1])) + incr)}`);
      } else if (val[0] === "endnumber") {
        baseVal = val[1].match(reEndNum)[1]
        endNum = val[1].match(reEndNum)[2]
        zeros = endNum.length - `${(Number(endNum) + incr)}`.length
        addZeros = (endNum.match(/^0+/) || [''])[0].length && zeros > 0 ? "0".repeat(zeros) : ""
        newVals.push(`${baseVal}${addZeros}${Number(endNum) + incr}`);
      } else {
        newVals.push(val[1])
      }
    }
  }

  // Update "changes" with new values
  for (const [i, item] of changes.entries()) {
    if (selVals.length === 1 && Number(selVals[0])) {
      item[3] = selVals[0]
    } else {
      item[3] = newVals[i]
    }
  }

  return changes
}

/** sort string that end in a number fist be prefix match then by the number */
export function sortTextWithPostfixNumber(a, b) {
  if (a === b) return 0
  const regEx = /(.*)(\d+)\s*$/
  const am = a?.match?.(regEx)
  const bm = b?.match?.(regEx)
  if (am[1] !== bm[1]) {
    return am[1] < bm[1] ? -1 : 1
  }
  const an = Number(am[2])
  const bn = Number(bm[2])
  if (an === bn) return 0
  return an < bn ? -1 : 1
}