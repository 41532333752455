export const plotSizeTopLevel = {
    width: 1000, height: 600
}
export const imageSizeTopLevel = {
    width: 1000, height: 750
}
export const xPlotMenu = {
    direction: 'up', showactive: true, x: 1, y: -0.1, xanchor: 'right', yanchor: 'top'
}
export const yPlotMenu = {
    direction: 'down', showactive: true, y: 1, x: -0.1, xanchor: 'left', yanchor: 'bottom'
}
export const xTypeMenu = {
    direction: 'up', showactive: true, type: 'dropdown', x: 1.21, xanchor: 'right',
    y: -0.1, yanchor: 'top'
}
export const yTypeMenu = {
    direction: 'down', showactive: true, type: 'dropdown', x: -0.1, xanchor: 'left',
    y: 1.115, yanchor: 'bottom'
}
export const modeMenu = {
    direction: 'down', showactive: true, type: 'dropdown', y: 1, x: 0.5, xanchor: 'left',
    yanchor: 'bottom'
}
export function linearButton(axis_name) {
    if (axis_name === 'x') {
        return { 'args': ['xaxis.type', 'linear'], 'label': 'Plot Linear', 'method': 'relayout' }
    }
    return { 'args': ['yaxis.type', 'linear'], 'label': 'Plot Linear', 'method': 'relayout' }
}
export function logButton(axis_name) {
    if (axis_name === 'x') {
        return { 'args': ['xaxis.type', 'log'], 'label': 'Plot Log', 'method': 'relayout' }
    }
    return { 'args': ['yaxis.type', 'log'], 'label': 'Plot Log', 'method': 'relayout' }
}
export function modeButton() {
    let modes = [{ 'args': [{ 'type': 'scatter', "mode": "lines" }], 'label': 'Line', 'method': 'restyle', "member": "ptype" }];
    modes = [...modes, { 'args': [{ 'type': 'scatter', "mode": "markers" }], 'label': 'Scatter', 'method': 'restyle', "member": "ptype" }];
    modes = [...modes, { 'args': [{ 'type': 'bar', "mode": "lines" }], 'label': 'Bar', 'method': 'restyle', "member": "ptype" }];
    modes = [...modes, { 'args': [{ 'type': 'heatmap', "mode": "lines" }], 'label': 'HeatMap', 'method': 'restyle', "member": "ptype" }];
    modes = [...modes, { 'args': [{ 'type': 'pie', "mode": "lines" }], 'label': 'Pie', 'method': 'restyle', "member": "ptype" }];
    modes = [...modes, { 'args': [{ 'type': 'box', "mode": "lines" }], 'label': 'Box', 'method': 'restyle', "member": "ptype" }];
    modes = [...modes, { 'args': [{ 'type': 'candlestick', "mode": "lines" }], 'label': 'Candlestick', 'method': 'restyle', "member": "ptype" }];
    return modes
}


export function UpdateUpdatemenus(stateLayout, toAddLayout, buttonLayoutInfo) {
    /* ********************************************************************************************
    As new plots arrive in responses, we need to stitch together the updatemenus content with
    each axis so that a single plot object works seamlessly. Specifically, each new plot to be
    combined together to form one multiplot comes with updatemenus of each varible plotted, and
    we need to map each of these variables from the new plot ("toAddLayout") to the existing, 
    newly created multiplot ("stateLayout").

    For example:

    * plotA, plotB, and plotC have variables 'force', 'extension', 'time', and 'cut'.
    
    In order for plotA, plotB, and plotC to be compared variable against variable, we need to 
    properly link each plot data set with each single axis variable, so when selecting 'force' vs.
    'time', we can see the 'force' data sets for plotA, plotB, and plotC altogether.

    stateLayout (obj) - "master" layout stored in state; object to be updated by new plot objects
    toAddLayout (obj) - "new" plot layout object to be weaved into/added to stateLayout
    buttonLayoutInfo (list) - [number of indices needing mapping, number of plotable axis items]
    ******************************************************************************************** */

    let indicesToMap = buttonLayoutInfo[0];
    let loops = buttonLayoutInfo[1];
    let key, j, adjIndex;
    let layoutKeys = {};
    let newKeys = [];

    for (j = 0; j < toAddLayout['updatemenus'][indicesToMap[0]]['buttons'].length; j++) {
        // Capture all keys available in 'toAddLayout'
        layoutKeys[toAddLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['label']] = j;
        newKeys = [...newKeys, false];
    }

    for (j = 0; j < loops; j++) {
        // We need to map specifically the updatemenus items that have data content to existing menus.
        // X-axis first, then y-axis.  Structure follows plotly backend.
        key = stateLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['label'];

        if (layoutKeys[key] !== undefined) {
            adjIndex = layoutKeys[key];

            stateLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['args'][0]['x'] = [
                ...stateLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['args'][0]['x'],
                toAddLayout['updatemenus'][indicesToMap[0]]['buttons'][adjIndex]['args'][0]['x'][0]
            ];
            stateLayout['updatemenus'][indicesToMap[1]]['buttons'][j]['args'][0]['y'] = [
                ...stateLayout['updatemenus'][indicesToMap[1]]['buttons'][j]['args'][0]['y'],
                toAddLayout['updatemenus'][indicesToMap[1]]['buttons'][adjIndex]['args'][0]['y'][0]
            ];
            newKeys[layoutKeys[key]] = true;

        } else {
            stateLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['args'][0]['x'] = [
                ...stateLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['args'][0]['x'],
                []
            ];

            stateLayout['updatemenus'][indicesToMap[1]]['buttons'][j]['args'][0]['y'] = [
                ...stateLayout['updatemenus'][indicesToMap[1]]['buttons'][j]['args'][0]['y'],
                []
            ];
        }
    }

    for (j = 0; j < newKeys.length; j++) {

        if (newKeys[j] === false) {
            // This key is not part of the current set of layouts, add it.
            let numButtons = stateLayout['updatemenus'][indicesToMap[0]]['buttons'].length;
            let numDatasets = stateLayout['updatemenus'][indicesToMap[0]]['buttons'][0]['args'][0]['x'].length;
            stateLayout['updatemenus'][indicesToMap[0]]['buttons'] = [...stateLayout['updatemenus'][indicesToMap[0]]['buttons'], {}];
            stateLayout['updatemenus'][indicesToMap[1]]['buttons'] = [...stateLayout['updatemenus'][indicesToMap[1]]['buttons'], {}];
            stateLayout['updatemenus'][indicesToMap[0]]['buttons'][numButtons]['args'] = [{ 'x': [] }];
            stateLayout['updatemenus'][indicesToMap[0]]['buttons'][numButtons]['label'] = toAddLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['label'];
            stateLayout['updatemenus'][indicesToMap[1]]['buttons'][numButtons]['args'] = [{ 'y': [] }];
            stateLayout['updatemenus'][indicesToMap[1]]['buttons'][numButtons]['label'] = toAddLayout['updatemenus'][indicesToMap[1]]['buttons'][j]['label'];

            for (var k = 0; k < numDatasets; k++) {
                stateLayout['updatemenus'][indicesToMap[0]]['buttons'][numButtons]['args'][0]['x'] = [
                    ...stateLayout['updatemenus'][indicesToMap[0]]['buttons'][numButtons]['args'][0]['x'],
                    []
                ]
                stateLayout['updatemenus'][indicesToMap[1]]['buttons'][numButtons]['args'][0]['y'] = [
                    ...stateLayout['updatemenus'][indicesToMap[1]]['buttons'][numButtons]['args'][0]['y'],
                    []
                ]
            }

            stateLayout['updatemenus'][indicesToMap[0]]['buttons'][numButtons]['args'][0]['x'] = [
                ...stateLayout['updatemenus'][indicesToMap[0]]['buttons'][numButtons]['args'][0]['x'],
                toAddLayout['updatemenus'][indicesToMap[0]]['buttons'][j]['args'][0]['x'][0]
            ]
            stateLayout['updatemenus'][indicesToMap[1]]['buttons'][numButtons]['args'][0]['y'] = [
                ...stateLayout['updatemenus'][indicesToMap[1]]['buttons'][numButtons]['args'][0]['y'],
                toAddLayout['updatemenus'][indicesToMap[1]]['buttons'][j]['args'][0]['y'][0]
            ]
        }
    }
    return stateLayout;
}