import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
    const totalElementHeight = theme.spacing(2) + (theme.spacing(1));
    return ({
        fcLabelledPortGroup: {
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            justifyContent: "space-around",
            height: props => `${props.portsHeight * totalElementHeight}px`,
            top: `${theme.spacing(2) + (theme.spacing(1))}px`,
            "&.left":{
                left: `-${theme.spacing(0.5)}px`,
            },
            "&.right":{
                right: `-${theme.spacing(0.5)}px`,
            },
        }
    });
});

function LabelledPortGroup(props, ref){
    const {config, className, side, children, ...rest} = props;
    const classes = useStyles({portsHeight: children && children.length});
    return (
        <div ref={ref} className={clsx(className, classes.fcLabelledPortGroup, side)} children={children} {...rest}/>
    );
}
export default React.forwardRef(LabelledPortGroup);