import React, { useState, useCallback } from 'react';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    filter: {
        backgroundColor: theme.palette.grey["100"],
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center"
    },
    switch: {
        margin: theme.spacing(0, 1),
    },
    selectContainer: {
        display: "flex"
    },
    selectInner: {
        flex: 1,
    },
}))

/**
 * Used to create selector components with a table detail select dialog
 * @param props props forwarded to selector
 * @param props.Selector the selector to ues for basic object selection
 * @param props.TableSelectorDiallog the material-ui compatible dialog to be launched for deltail selection
 */
export default function DefinitionSelectorBase({selected, onChange, Selector, selectSingle, TableSelectorDialog, ...remainingProps }){
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleOpenDialog = useCallback(() => setDialogOpen(true), [])
    const handleCloseDialog = useCallback((values) => {values && onChange && onChange(undefined, values || []); setDialogOpen(false);}, [onChange]);
    return (
        <div className={classes.selectContainer}>
            <TableSelectorDialog selectSingle={selectSingle} open={dialogOpen} onClose={handleCloseDialog} initSelected={selected} />
            <div className={classes.selectInner}>
                <Selector selectSingle={selectSingle} selected={selected} onChange={onChange} {...remainingProps}/>
            </div>
            <IconButton size="small" onClick={handleOpenDialog}>
                <TableChartOutlinedIcon />
            </IconButton>
        </div>
    );
}