import React from 'react';
import Spinner from 'react-spinkit';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
    background: {
        pointerEvents: "none",
        backgroundColor: theme.palette.type === "dark" ? `#FFFFFF30` : `rgba(255,255,255,0.7)`,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        textAnchor: 'middle',
        textAlign: 'center',
    },
    spinner: {
        position: 'relative',
        top: '40%',
        left: '0%',
    },
    message: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(1),
    }
}));

export default function TableSpinner({loading, message = "Loading Data..."}){
    const classes = useStyles();
    const theme = useTheme();
    return (
        loading &&
        <div className={classes.background}>
            <div className={classes.spinner}>
                <Spinner name='line-scale-pulse-out' color={theme.palette.text.secondary} fadeIn='none' />
                <Typography className={classes.message}>
                    {message}
                </Typography>
            </div>
        </div>
    )
}