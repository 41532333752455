import React, { useState, useMemo } from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';

import { useQueryAutocomplete } from '../../API/queryHooks';
import MultiSelector from './MultiSelector';
import { useDebounceState } from '../../utils/utils';
import DefinitionSelectorBase from './DefinitionSelectorBase';
import SelectProcessTableDialog from './SelectProcessTableDialog';


const useStyles = makeStyles(theme => ({
    filter: {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["100"],
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center"
    }
    })
)

const EXTEND_LIST_TEXT = "Start typing to filter results and extend list...";

/**
 * React Component for performing a multiselect on on processes
 * @param props most props forwared to MultiSelector
 * @param props.selected forwared as the value prop
 */
export default function ProcessSelector(props){
    return (
        <DefinitionSelectorBase Selector={ProcessSelectorBase} TableSelectorDialog={SelectProcessTableDialog} {...props}/>
    );
}

export function ProcessSelectorBase({selected, onChange, ...remainingProps }){
    const [queryInput, setQueryInput] = useState({});
    const [input, setInput] = useDebounceState(queryInput, setQueryInput);
    const params = useMemo(() => ({
        Model: "ProcessDefinition", 
        fields: ["title", "id", "meta"],
        sort: ["title"],
    }), []);
    let { data: {data: options} = {}, isFetching } = useQueryAutocomplete(queryInput, params, {logName: "Process Selector"});

    let hasAtLeastOneProcess = false;
    if (selected && selected.length > 0) {
        // Process nodes should only have ONE process assigned to them. By disabling options after one has been added,
        // it will force only one process per node.
        options = undefined;
        hasAtLeastOneProcess = true;
    }

    const labelContent = `Select Processes (${EXTEND_LIST_TEXT})`;

    return (
        <MultiSelector
            label={labelContent}
            missingLabel="Missing or Private Process"
            loading={isFetching}
            options={options || []}
            value={selected || []}
            onBlur={() => setInput({})}
            onChange={(...args) => {onChange && onChange(...args); setInput({})}}
            inputValue={input.title || ""}
            onInputChange={(ev, newVal) => {ev && setInput({title: ev?.target?.value || ""})}}
            data-testid="process selector"
            PaperComponent={hasAtLeastOneProcess ? undefined : ListContainer}
            {...remainingProps}
            />
    );
}


const ListContainer = React.forwardRef(function ListContainer({children, ...rest}, ref){
    const classes = useStyles();
    return (
        <div>
            <Paper>
                <div className={classes.filter}>
                    <Typography style={{paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px'}}>{EXTEND_LIST_TEXT}</Typography>
                </div>
            </Paper>
            <Paper ref={ref} {...rest}>
                {children}
            </Paper>
        </div>
    )
})