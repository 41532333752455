import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

const inlineIconStyle = { marginRight: "6px" };

/**
 * Wraps material-ui button adding a "+" icon for new. All props and ref forwared to the Button.
 */
export default React.forwardRef(function NewButton({children, ...props}, ref){
    return (
        <Button ref={ref} {...props}>
            <AddIcon style={inlineIconStyle} />
            {children}
        </Button>
    )
});