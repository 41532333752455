import React from 'react';
import FreeSoloSelector from './FreeSoloSelector';
import { useLocalForageState } from '../../utils/useLocalforageState';

export default function TagSelector({onChange, ...rest}){

    const [tags, setTags] = useLocalForageState("Tags", undefined);
    
    const handleChange = (ev, values) => {
        const newTags = values.reverse().concat((tags || []).filter(t => !values.includes(t)));
        setTags(newTags.slice(-10));
        onChange && onChange(ev, values);
    }

    return (
        <FreeSoloSelector
            variant="outlined"
            fullWidth
            label="Tags"
            helperText="Press enter to deliminate tags"
            options={tags}
            onChange={handleChange}
            data-testid="tag selector"
            {...rest} />
    )
}