import { Sample, Lab } from '../models'
import { Schema, schema } from '../schemaUtils'

export class LabToSample {
    id?: string             // always undefined, adding appeases typescript
    creatorId?: string
    timeCreated?: Date
    accessLevel?: "reader" | "writer"
    sample?: Sample
    lab?: Lab

    static readonly [schema]: Schema<LabToSample> = {
        relationships: {
            sample: { map: "sample", model: "Sample", toMany: false, reverse: "labs", required: true },
            lab: { map: "lab", model: "Lab", toMany: false, reverse: "samples", required: true },
        },
        map: {
            "creatorId": "creator_id",
            "timeCreated": "created",
            "accessLevel": "access_level",
            "sample.id": "sample_id",
        }
    }

    constructor(toCopy?: Partial<LabToSample>) {
        Object.assign(this, toCopy)
    }
}