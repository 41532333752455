/**
 * @format
 */

import { AxiosResponse } from "axios"
import * as Models from "schema/models"
import {
    Model,
    modelDeepMerge,
    ModelOfInstance,
    ModelWithSchema,
    schema,
    Schema,
    assumeType,
    updateReverseRelationships,
} from "schema/schemaUtils"
import { deepEqual, nestedKey } from "utils/utils"
import {
    convertFields,
    convertFilter,
    convertMutateRequest,
    convertQueryResponse,
    convertSort,
} from "./firecrackerConverters"
import {
    Field,
    QueryNode,
    QueryParam,
    Sort,
    Arr,
    Filter,
    ArrayResponse,
    Query,
    MutateParam,
    MutateRequest,
    MutateNode,
    MutateTrack,
    MutateData,
    MutateDepends,
    MutateNodeArray,
    MutateNodeSingle,
    firecrackerFilter,
    QueryParamSingle,
    OnProgressCallback,
    ProgressTracking,
} from "./firecrackerTypes"
import { mmAxios, mbxAxios, emAxios } from "./mmAxios"
import { splitGet } from "./splitGet"

/** Max number of relationship objects requested in a single call. 50 is about 2000 characters in the header filter and matches the current BE pagination limit */
const MAX_QUERY_BATCH_SIZE = 50
const BASE_FC_USER_PREFS_URL = `${process.env.REACT_APP_EM_API_URL}user_preferences`

/** Get the version of the FC BE */
export async function getBEVersion() {
    return mmAxios.get("version").then(res => res.data?.firecracker)
}

export async function getETLYProcessors() {
    const resp = mbxAxios.get("upload/tabular").then(res =>
        Object.keys(res.data.data).map((key: string) => {
            return { id: key, label: res.data.data[key]["title"] }
        }),
    )
    //console.log(resp);
    return resp
}

export async function getUserPreferences(model?: string, _id?: string) {
    let url = BASE_FC_USER_PREFS_URL
    if (model) {
        url += `/${model}`
    }
    if (_id) {
        url += `/${_id}`
    }
    return emAxios.get(url).then(res => res.data)
}

/** Get a list of unique QIDs from the FC BE */
export function getQIDs(count: number) {
    return count > 0
        ? mmAxios
              .get(`qids/${count}`)
              .then(res => (res.data?.data?.qids as string[]) || [])
              .catch(error => {
                  console.error(error)
                  return []
              })
        : Promise.resolve([] as string[])
}

/**
 * Base function for queries into FC.
 * @param params QueryParam object that defines the call to be made into the BE. If left undefined, an empty data array will be returned
 * @param onProgress an optional callback that can be used to update a progress bar
 * @returns
 */
export async function getQueryFC<T extends InstanceType<ModelWithSchema>>(
    params?: QueryParam<T>,
    onProgress?: OnProgressCallback,
): Promise<ArrayResponse<T>> {
    if (!params) return { data: [] }
    const queryNode = queryToQueryNode<T>(
        params as Query<T>,
        Models[params.Model] as ModelOfInstance<T>,
    )
    let tracking = { completed: 0, total: 0 }
    countNodeCalls(queryNode as QueryNode<any>, tracking)
    return getQueryNode<T>(queryNode, tracking, onProgress)
}
function countNodeCalls(queryNode: QueryNode<any>, tracking: ProgressTracking) {
    tracking.total++
    if (queryNode.relationships)
        for (const rel of queryNode.relationships) {
            countNodeCalls(rel as QueryNode<any>, tracking)
        }
}
export async function getQueryFCSingle<T extends InstanceType<ModelWithSchema>>(
    params?: QueryParamSingle<T>,
    onProgress?: OnProgressCallback,
): Promise<T | undefined> {
    if (!params) return undefined
    const singleParams = {
        ...params,
        pageSize: 1,
        pageNumber: params.pageNumber || 1,
    }
    return getQueryFC<T>(singleParams, onProgress).then(res => res.data[0])
}

/** Request a mutation. Payload and previous are compaired to determine posts, patches, and deletes for the root object and all relationships defined in param */
export async function mutateRequestFC<T extends InstanceType<ModelWithSchema>>(
    param: MutateParam<T>,
    payload: T | undefined,
    previous: T | undefined,
    onProgress?: OnProgressCallback,
): Promise<T | undefined>
export async function mutateRequestFC<T extends InstanceType<ModelWithSchema>>(
    param: MutateParam<T>,
    payload: T[] | undefined,
    previous: T[] | undefined,
    onProgress?: OnProgressCallback,
): Promise<T[] | undefined>
export async function mutateRequestFC<T extends InstanceType<ModelWithSchema>>(
    param: MutateParam<T>,
    payload: T | T[] | undefined,
    previous: T | T[] | undefined,
    onProgress?: OnProgressCallback,
): Promise<T | T[] | undefined> {
    if (
        (!payload || (Array.isArray(payload) && !payload.length)) &&
        (!previous || (Array.isArray(previous) && !previous.length))
    ) {
        console.warn("mutateRequestFC called with no data: ", param)
        return payload
    } else if (payload === previous) {
        console.warn("mutateRequestFC called with no data changes: ", param)
        return payload
    }
    const tacker: MutateTrack[] = []
    const mutateNode = mutateParamToMutateNode(
        param as MutateRequest<T>,
        (Models as any)[param.Model],
        payload,
        previous,
        tacker,
    )

    if (!mutateNode) return payload
    const errors: Error[] = []
    const tracking = { completed: 0, total: tacker.length + 1 }
    return runMutateNode(mutateNode, errors, tracking, onProgress).then(res => {
        if (errors.length) {
            if (errors.length === 1) throw errors[0]
            throw errors
        }
        return res === null ? undefined : res
    })
}

/** Get user roles from FC BE */
export async function getUserRoles() {
    return mmAxios
        .get("user_roles", {
            headers: { "Content-Type": "application/json; charset=utf-8" },
        })
        .then(res => res.data.data)
}

function isMaxIdFilter(filter: firecrackerFilter) {
    return (
        filter &&
        filter.name === "id" &&
        filter.op === "in_" &&
        (filter.val as any)?.length > MAX_QUERY_BATCH_SIZE
    )
}

/** Used by getQuery and getQueryFC. Recursively performs get calls to get all object in the supplied QueryNode tree */
export async function getQueryNode<T extends InstanceType<ModelWithSchema>>(
    queryNode: QueryNode<T>,
    tracking: ProgressTracking,
    onProgress?: OnProgressCallback,
    override?: ArrayResponse<T>,
): Promise<ArrayResponse<T>> {
    const querySchema = queryNode.Model[schema] as Schema<T>
    if (!querySchema?.endPoint && !override)
        throw new Error("EndPoint not defined")
    // convert parameters
    const filter =
        queryNode.filter && !override
            ? // @ts-ignore
              convertFilter(queryNode.filter, querySchema)
            : undefined
    const sort =
        queryNode.sort && !override && convertSort(queryNode.sort, querySchema)
    const fields =
        queryNode.fields &&
        !override &&
        convertFields(queryNode.fields, querySchema)

    // check batch query where greater than MAX_QUERY_BATCH_SIZE ids are requested and no pagination is given
    let paramArr = []
    if (!override) {
        if (
            filter &&
            !queryNode.pageSize &&
            (isMaxIdFilter(filter) ||
                (filter.and?.length && filter.and.some(f => isMaxIdFilter(f))))
        ) {
            if (sort)
                console.warn(
                    `Sort on id specific query ignored because the number of ids exceeds max batch size (${MAX_QUERY_BATCH_SIZE}) and is being split`,
                )
            let baseFilters: any[] = []
            let ids: string[]
            if (filter.and) {
                baseFilters = filter.and.filter(f => !isMaxIdFilter(f))
                ids = filter.and.find(f => isMaxIdFilter(f))
                    ?.val as unknown as string[]
            } else {
                ids = filter.val as unknown as string[]
            }
            tracking.total += Math.ceil(ids.length / MAX_QUERY_BATCH_SIZE) - 1
            for (let i = 0; i < ids.length; i += MAX_QUERY_BATCH_SIZE) {
                let idsFilter: firecrackerFilter = {
                    name: "id",
                    op: "in_",
                    val: ids.slice(i, i + MAX_QUERY_BATCH_SIZE),
                } as any
                if (baseFilters.length > 0)
                    idsFilter = { and: [idsFilter, ...baseFilters] } as any
                paramArr.push({
                    "page[number]": queryNode.pageNumber,
                    "page[size]": queryNode.pageSize,
                    filter: JSON.stringify([idsFilter]),
                    sort,
                    [`fields[${querySchema.endPoint}]`]: fields,
                })
            }
        } else {
            paramArr.push({
                "page[number]": queryNode.pageNumber,
                "page[size]": queryNode.pageSize,
                filter: filter ? JSON.stringify([filter]) : undefined,
                sort,
                [`fields[${querySchema.endPoint}]`]: fields,
            })
        }
    }

    // API Promise Chain
    // ---> Get Current Node (splitGet) across batch
    // ---> Then convert response to Model object
    // ---> Merge Batche(s)
    // ---> Then make relationship getQueryNode recursive calls in batches of MAX_QUERY_BATCH_SIZE relationship objects (breadth in parallel)
    // ---> ---> Then concat batches
    // ---> ---> Then merge in relationships to root Model
    // ---> Then run any responsePostConversion functions
    return Promise.all(
        override
            ? []
            : paramArr.map(params =>
                  splitGet(querySchema.endPoint, {
                      params,
                  }) // convert response
                      .then(res => {
                          tracking.completed++
                          if (onProgress) onProgress(tracking)
                          return convertQueryResponse(
                              res,
                              queryNode.Model,
                          ) as ArrayResponse<T>
                      }),
              ),
    )
        .then(res =>
            override
                ? override
                : res.length > 1
                ? res.reduce(
                      (agg, r) => {
                          agg.data.push(...r.data)
                          if (agg.count && r.count) agg.count += r.count
                          return agg
                      },
                      { data: [], count: 0 },
                  )
                : res[0],
        )
        .then(resModel =>
            queryAndMergeRelationships<T>(
                resModel,
                queryNode,
                tracking,
                onProgress,
            ),
        )
        .then(resModel => {
            // run post response conversion functions
            if (!querySchema.responsePostConvertion) return resModel
            const data = resModel.data.map(querySchema.responsePostConvertion)
            return {
                ...resModel,
                data,
            }
        })
}

/**
 * Used by getQueryNode. Given root resModel and queryNode, queries all requested relationships and merges them into the resModel.
 * Calls are split into batches of MAX_QUERY_BATCH_SIZE
 */
async function queryAndMergeRelationships<
    T extends InstanceType<ModelWithSchema>,
>(
    resModel: ArrayResponse<T>,
    queryNode: QueryNode<T>,
    tracking: ProgressTracking,
    onProgress?: OnProgressCallback,
) {
    const querySchema = queryNode.Model[schema]
    if (!queryNode.relationships?.length || !querySchema.relationships)
        return resModel

    // get all relationships
    const relPromises = [] as Promise<any>[]
    for (const queryRel of queryNode.relationships) {
        // Get target relationship schema and key
        if (!queryRel) continue
        const [key, schemaRel] = (Object.entries(
            querySchema.relationships,
        ).find(([key, val]) => key === queryRel.name) || []) as [
            keyof T | undefined,
            BaseQueryRelationship | undefined,
        ]
        if (!key || !schemaRel) continue
        if (!resModel.data?.length) continue

        // if indirect, use filter on root id rather than relationship ids
        if (schemaRel.indirect) {
            if (!schemaRel.reverse)
                throw new Error(
                    "Bad Model schema. Indirect relationship requested when reverse not defined",
                )
            let relFilter: any = {
                name: `${schemaRel.reverse}.id`,
                op: "in_",
                val: resModel.data.map(d => d.id),
            }
            if (queryRel.filter)
                relFilter = { and: [relFilter, queryRel.filter as any] }

            relPromises.push(
                getQueryNode(
                    {
                        ...queryRel,
                        filter: relFilter,
                    } as any,
                    tracking,
                    onProgress,
                ).then(res => ({
                    ...res,
                    key,
                    schemaRel,
                })),
            )
            continue
        }

        // not indirect so get all ids part of this relationship
        const relIds = resModel.data.reduce(
            schemaRel.toMany
                ? (ids, d) => {
                      if (!d[key]) return ids
                      for (const r of d[key] as unknown as {
                          id?: string
                      }[]) {
                          if (r.id && !ids.includes(r.id)) ids.push(r.id)
                      }
                      return ids
                  }
                : (ids, d) => {
                      const r = d[key] as unknown as { id: string } | undefined
                      if (r && !ids.includes(r.id)) ids.push(r.id)
                      return ids
                  },
            [] as string[],
        )

        // build rel filter based on ids
        let relFilter: Filter<Model> = {
            name: "id",
            op: "in_",
            val: relIds,
        }
        if (queryRel.filter)
            relFilter = { and: [relFilter, queryRel.filter as any] }

        let override = undefined
        if (schemaRel.meta) {
            const data = resModel.data?.reduce((agg, d) => {
                if (d[key]) {
                    if (Array.isArray(d[key])) {
                        agg.push(...(d[key] as unknown as any[]))
                    } else agg.push(d[key])
                }
                return agg
            }, [] as any[])
            override = { data, count: data.length }
        }

        relPromises.push(
            getQueryNode(
                {
                    ...queryRel,
                    filter: relFilter,
                } as any,
                tracking,
                onProgress,
                override,
            ).then(res => ({ data: res.data, key, schemaRel })),
        )
    }
    // merge relationships
    return Promise.all(relPromises).then(rels => {
        resModel.data.forEach(mod => {
            for (const rel of rels) {
                const { key, schemaRel, data } = rel as {
                    key: keyof T
                    schemaRel: BaseQueryRelationship
                    data: any[]
                }
                // merge in relationship by matching rel id or reverse rel id or because it's the sole option
                // sole option may lead to bugs if wiping out ids of unknown objects becomes a problem....
                let mergedRels: any
                if (resModel.data.length === 1) {
                    // sole option?
                    mergedRels = data
                } else {
                    const modKeyArr = (
                        mod[key]
                            ? schemaRel.toMany
                                ? mod[key]
                                : [mod[key]]
                            : []
                    ) as Model[]
                    // find all returned elements that belong to mod[key]
                    const matching = data.filter(d => {
                        const dId = schemaRel.meta?.through
                            ? d[schemaRel.meta.through]?.id
                            : d.id
                        if (modKeyArr.find(m => m.id === dId)) return true
                        else if (schemaRel.meta?.through) return false
                        const rev = d[schemaRel.reverse as any]
                        if (Array.isArray(rev)) {
                            return !!rev.find(r => r.id && r.id === mod.id)
                        }
                        return rev?.id && rev.id === mod.id
                    })
                    // merge with existing when found
                    mergedRels = modKeyArr.map(modKey => {
                        const index = matching.findIndex(
                            d => d.id === (modKey as any).id,
                        )
                        if (index < 0) return modKey
                        const dm = modelDeepMerge(
                            modKey,
                            matching[index],
                            Models[schemaRel.model],
                        )
                        matching.splice(index, 1)
                        return dm ? dm : modKey
                    })
                    // merge any leftover that match but don't already exist
                    if (matching.length) mergedRels.push(...matching)
                }
                const modKey = schemaRel.toMany ? mergedRels : mergedRels[0]
                if (modKey) mod[key] = modKey
            }
        })
        return resModel
    })
}

type BaseQueryRelationship = {
    toMany: boolean
    model: keyof typeof Models
    map: string
    reverse?: string
    indirect?: boolean
    meta?: { of: string; through: string }
}

/**
 * Converts a Query object to a recursive QueryNode object. QueryNode allows simplier tree traversal logic to be used during the get call
 */
export function queryToQueryNode<T extends Model>(
    query: Query<T> | undefined,
    model: ModelOfInstance<T>,
): QueryNode<T> {
    if (!query) return { Model: model }
    const [fields, relationships] = splitFields(
        query?.fields as any,
        model,
    ) as any
    return mergeQueryNodeRelationships<T>({
        Model: model,
        pageNumber: query?.pageNumber,
        pageSize: query?.pageSize,
        sort: arrayifySort(query?.sort as any),
        // @ts-ignore
        filter: flattenFilter(query?.filter as any),
        fields,
        relationships,
    }) as QueryNode<T>
}

/**
 * Recursively splits and flattens relationship keys out of field keys
 */
function splitFields<T>(
    queryFields: Field<T>[] | undefined,
    // @ts-ignore
    model: ModelOfInstance<T>,
): any[] {
    const querySchema = model[schema]
    if (!queryFields) return []
    const fields: any[] = []
    const relationships: any[] = []
    for (const qf of queryFields) {
        if (!qf) continue
        const field: any =
            typeof qf === "string" ? { name: qf as any } : (qf as any)

        // check relationships
        let relKey: string | undefined
        let remaining = ""
        relKey = Object.keys(querySchema.relationships || {}).find(key =>
            field.name.match(new RegExp(`^${key}(.[a-zA-Z0-9-_]+)?`)),
        )

        if (!relKey) {
            // no relationship found must be an attribute field
            if (!fields.includes(field.name)) fields.push(field.name)
            continue
        }

        remaining = field.name.substring(relKey.length + 1)
        let subFields = []
        let filter = flattenFilter(field.filter) as
            | typeof field.filter
            | undefined
        let sort = arrayifySort(field.sort)
        let pageNumber = field.pageNumber
        let pageSize = field.pageSize
        const subModel = (Models as any)[
            (querySchema as any).relationships[relKey].model
        ]
        if (remaining) {
            subFields.push({
                name: remaining,
                fields: field.fields,
                filter,
                sort,
                pageNumber,
                pageSize,
            })
            filter = undefined
            sort = undefined
            pageNumber = undefined
            pageSize = undefined
        } else if (field.fields) subFields.push(...field.fields)
        const [relFields, relRelationships] = splitFields(
            subFields as any,
            subModel,
        )
        relationships.push({
            Model: subModel,
            name: relKey,
            fields: relFields,
            relationships: relRelationships,
            filter,
            sort,
            pageNumber,
            pageSize,
        })
    }
    return [
        fields.length ? fields : undefined,
        relationships.length ? relationships : undefined,
    ]
}

/**
 * Recursively looks at query node relationships are merges relationships that share the same name when possible.
 */
function mergeQueryNodeRelationships<T extends Model>(
    queryNode: QueryNode<T>,
): QueryNode<T> {
    if (!queryNode.relationships || queryNode.relationships.length <= 1)
        return queryNode
    const merged = [] as any[]
    // merge rels without a filter
    for (const rel of queryNode.relationships) {
        if (rel && !rel.filter) {
            const index = merged.findIndex(
                r =>
                    r.name === rel.name &&
                    r.pageNumber === rel.pageNumber &&
                    r.pageSize === rel.pageSize,
            )
            if (index < 0) {
                merged.push(rel)
            } else {
                const sort = merged[index].sort || rel.sort
                const relationships = merged[index].relationships
                    ? merged[index].relationships.concat(
                          rel.relationships || [],
                      )
                    : rel.relationships
                const fields = mergeFieldArrays(
                    rel.fields,
                    merged[index].fields,
                )
                merged[index] = {
                    ...merged[index],
                    sort,
                    fields,
                    relationships,
                }
            }
        }
    }
    // add rels with filters
    merged.push(...queryNode.relationships.filter(rel => rel?.filter))
    if (merged.length === queryNode.relationships.length) return queryNode
    return {
        ...queryNode,
        relationships: merged.map(rel =>
            mergeQueryNodeRelationships(rel),
        ) as any,
    }
}

function arrayifySort<T extends Model>(
    obj?: Arr<Sort<T>>,
): Sort<T>[] | undefined {
    if (Array.isArray(obj)) return obj.length ? obj : undefined
    if (obj) return [obj]
    return undefined
}

function flattenFilter<T>(filter: Arr<Filter<T>>): Filter<T> | undefined {
    if (Array.isArray(filter)) {
        if (!filter.length) return undefined
        if (filter.every(f => typeof f === "string")) {
            return {
                name: "id",
                op: "in_",
                val: filter,
            } as Filter<Model> as unknown as Filter<T>
        }
        return { and: filter } as Filter<T>
    }
    return filter
}

function mergeFieldArrays(
    a: any[] | undefined,
    b: any[] | undefined,
): any[] | undefined {
    if (!a?.length || !b?.length) return undefined
    return a.reduce((arr, curr) => {
        if (arr.indexOf(curr) < 0) arr.push(curr)
        return arr
    }, b.slice())
}

/** Used by mutate request to recursively run all mutations and relationships in an array */
export async function runMutateNode<T extends InstanceType<ModelWithSchema>>(
    mutateNode: MutateNode<T>,
    errors: Error[],
    tracking: ProgressTracking,
    onProgress?: OnProgressCallback,
) {
    if (!mutateNode) {
        handleProgressTracking(tracking, onProgress)
        return undefined
    }
    if (mutateNode.isArray) {
        assumeType<MutateNodeArray<T>>(mutateNode)
        const returnPayload = [...(mutateNode.payload || [])]
        const promises: Promise<T | undefined | null>[] = []
        mutateNode.operation.forEach((operation, i) => {
            promises.push(
                handleMutateNode(
                    mutateNode.Model,
                    operation,
                    mutateNode.payload?.[i],
                    mutateNode.dependsOn[i],
                    mutateNode.dependents[i],
                    errors,
                    tracking,
                    onProgress,
                ).then(res => {
                    handleProgressTracking(tracking, onProgress)
                    if (res) returnPayload[i] = res as T
                    return res
                }),
            )
        })
        return Promise.all(promises).then(() => returnPayload)
    } else {
        assumeType<MutateNodeSingle<T>>(mutateNode)
        return handleMutateNode(
            mutateNode.Model,
            mutateNode.operation,
            mutateNode.payload,
            mutateNode.dependsOn,
            mutateNode.dependents,
            errors,
            tracking,
            onProgress,
        ).then(res => {
            handleProgressTracking(tracking, onProgress)
            return res
        })
    }
}
function handleProgressTracking(
    tracking: ProgressTracking,
    onProgress?: OnProgressCallback,
) {
    tracking.completed++
    if (onProgress) onProgress(tracking)
}

/** Used by runMutateNode to get a single mutation and fire off relationships */
async function handleMutateNode<T extends InstanceType<ModelWithSchema>>(
    Model: ModelOfInstance<T>,
    mutateData: MutateData<T>,
    nodePayload: T | undefined | null,
    dependsOn: MutateDepends<T> | undefined,
    dependents: MutateDepends<T> | undefined,
    errors: Error[],
    tracking: ProgressTracking,
    onProgress?: OnProgressCallback,
) {
    const modelSchema = Model[schema]
    const endPoint = modelSchema.endPoint
    if (!endPoint)
        throw new Error(
            `Mutate request made on model without endpoint defined (${Model.name})`,
        )
    // check if result has not been requested yet
    if (mutateData?.result === undefined) {
        mutateData.result = new Promise<T | null>((resolve, reject) => {
            // figure out call requests this one might depend on and do them
            runRelationshipMutations(
                nodePayload,
                Model,
                dependsOn,
                errors,
                tracking,
                onProgress,
            )
                // after dependsOn relationships do the actual post/patch/delete requests for this object
                .then(rawPayload => {
                    let payload = rawPayload
                    if (payload && modelSchema.mutatePreConversion) {
                        payload = modelSchema.mutatePreConversion(
                            payload,
                        ) as any
                    }
                    const handlePostPatchReturn = (res: AxiosResponse<any>) => {
                        const convert = convertQueryResponse(res, Model) as any
                        const merged = modelDeepMerge(
                            payload as any,
                            convert,
                            Model,
                        )
                        if (merged) updateReverseRelationships(merged, Model) // this mutates so no need to copy
                        return merged
                    }

                    switch (mutateData.op) {
                        case "post": {
                            assumeType<T>(payload)
                            payload.id = undefined
                            const converted = convertMutateRequest(
                                payload as T,
                                Model,
                            )
                            return mmAxios
                                .post(endPoint, JSON.stringify(converted), {
                                    headers: {
                                        "Content-Type":
                                            "application/json; charset=utf-8",
                                    },
                                })
                                .then(handlePostPatchReturn)
                        }
                        case "patch": {
                            assumeType<T>(payload)
                            if (!payload.id)
                                throw new Error(
                                    "Patch request made on something without an id",
                                )
                            const converted = convertMutateRequest(
                                payload as T,
                                Model as ModelOfInstance<T>,
                                mutateData.rels,
                            )
                            return mmAxios
                                .patch(
                                    `${endPoint}/${payload.id}`,
                                    JSON.stringify(converted),
                                    {
                                        headers: {
                                            "Content-Type":
                                                "application/json; charset=utf-8",
                                        },
                                    },
                                )
                                .then(handlePostPatchReturn)
                        }
                        case "delete": {
                            if (!mutateData.previous?.id)
                                throw new Error(
                                    "Delete request made on something without an id",
                                )
                            return mmAxios
                                .delete(`${endPoint}/${mutateData.previous.id}`)
                                .then(() => null) // successful delete converts to null
                                .catch(error => {
                                    if (error.response?.status !== 400)
                                        throw error
                                    return null // ignore not found delete errors
                                })
                        }
                        default:
                            return rawPayload
                    }
                })
                // after main request run all dependent relationships
                .then(res => {
                    if (!dependents) return res
                    return runRelationshipMutations<T>(
                        res as any,
                        Model,
                        dependents,
                        errors,
                        tracking,
                        onProgress,
                    )
                })
                // record error then continue so other operations can complete. Will handle error array after all calls
                .catch(error => {
                    errors.push(error)
                    return nodePayload
                })
                // things complete successfully then resolve the main promise
                .then(res => {
                    resolve(res as any)
                })
                // something went wrong that couldn't be suppressed, reject the main promise
                .catch(error => {
                    reject(error)
                })
        })
    }
    return mutateData.result
}

/** Used by handleMutateNode to make relationship calls */
async function runRelationshipMutations<
    T extends InstanceType<ModelWithSchema>,
>(
    payload: T | undefined | null,
    Model: ModelOfInstance<T>,
    relationships: MutateDepends<T> | undefined,
    errors: Error[],
    tracking: ProgressTracking,
    onProgress?: OnProgressCallback,
) {
    // figure out call requests this one might depend on and do them
    let depends: any
    const promises: Promise<any>[] = []
    if (relationships) {
        depends = {}
        for (const [key, node] of Object.entries(relationships)) {
            assumeType<MutateNode<Model>>(node)
            promises.push(
                runMutateNode(node as any, errors, tracking, onProgress).then(
                    res => {
                        if (!res) return res
                        if (depends[key]) {
                            if (Array.isArray(res)) {
                                res.forEach((r, i) => {
                                    depends[key][i] = r
                                })
                            } else {
                                depends[key] = res
                            }
                        } else {
                            depends[key] = res
                        }
                    },
                ),
            )
        }
    }
    // after dependent requests complete, merge results into request payload and make request
    if (!promises.length) return payload || undefined
    return Promise.all(promises).then(() => {
        if (!depends) return payload
        if (!payload) return undefined
        const rtn = new Model(payload as any)
        for (const [key, val] of Object.entries(depends)) {
            if (!(rtn as any)[key]) (rtn as any)[key] = val
            if (Array.isArray(val)) {
                val.forEach((v, i) => {
                    ;(rtn as any)[key][i] = v
                })
            } else {
                ;(rtn as any)[key] = val
            }
        }
        return rtn
    })
}

/** Recursively converts a MutateRequest to a MutateNode that makes explicit post/patch/delete requests by compairing payload with previous and splits out relationships based on dependencies */
export function mutateParamToMutateNode<
    T extends InstanceType<ModelWithSchema>,
>(
    param: MutateRequest<T>,
    model: ModelOfInstance<T>,
    payload: T | T[] | undefined,
    previous: T | T[] | undefined,
    tracker: MutateTrack[] = [],
    includedInParent?: boolean,
): MutateNode<T> | undefined {
    if (!payload && !previous) return undefined
    const payloadIsArray = Array.isArray(payload)
    const previousIsArray = Array.isArray(previous)
    if (payload && previous && payloadIsArray !== previousIsArray) {
        throw new Error("Payload and Previous array type mismatch")
    }
    const isArray = payloadIsArray || previousIsArray

    if (!isArray) {
        return handleConvertRequestNode<T>(
            param,
            model,
            payload as T | undefined,
            previous as T | undefined,
            tracker,
            includedInParent,
        )
    }
    assumeType<T[] | undefined>(payload)
    assumeType<T[] | undefined>(previous)
    const nodes = []
    if (payload) {
        for (let i = 0; i < payload.length; ++i) {
            const payloadEl = payload[i]
            if (payloadEl) {
                const prev =
                    (payloadEl?.id &&
                        previous?.find(
                            prev => prev?.id && prev.id === payloadEl.id,
                        )) ||
                    undefined
                const node = handleConvertRequestNode<T>(
                    param,
                    model,
                    payloadEl as T | undefined,
                    prev as T | undefined,
                    tracker,
                    includedInParent,
                )
                if (node) nodes[i] = node
            }
        }
    }
    if (previous) {
        const offset = payload?.length || 0
        for (let i = 0; i < previous.length; ++i) {
            const prev = previous[i]
            if (prev?.id && !payload?.find(pl => pl.id === prev.id)) {
                const node = handleConvertRequestNode<T>(
                    param,
                    model,
                    undefined,
                    prev as T | undefined,
                    tracker,
                    includedInParent,
                )
                if (node) nodes[i + offset] = node
            }
        }
    }
    if (nodes.length <= 0 || !nodes.some(n => !!n)) return undefined

    // aggregate node array to MutateNodeArray
    return nodes.reduce(
        (agg, node, i) => {
            if (node) {
                if (node.operation) agg.operation[i] = node.operation
                if (Object.keys(node.dependents).length)
                    agg.dependents[i] = node.dependents
                if (Object.keys(node.dependsOn).length)
                    agg.dependsOn[i] = node.dependsOn
            }
            return agg
        },
        {
            Model: model,
            payload,
            operation: [],
            dependsOn: [],
            dependents: [],
            isArray: true,
        } as MutateNodeArray<T>,
    )
}

function handleConvertRequestNode<T extends Model>(
    param: MutateRequest<T>,
    model: ModelOfInstance<T>,
    payload: T | undefined,
    previous: T | undefined,
    tracker: MutateTrack[] = [],
    includedInParent?: boolean,
): MutateNodeSingle<T> | undefined {
    if (!payload && !previous) return undefined
    const modelSchema = model[schema]
    let operation: MutateData<T> | undefined
    let dependents: { [K in keyof T]?: any } = {}
    let dependsOn: { [K in keyof T]?: any } = {}

    // handle parent deleted
    if (param.allow.delete && !payload && previous?.id && !includedInParent) {
        operation = getOperation(
            tracker,
            { op: "delete", previous },
            previous,
            model,
        )
    }

    // handle parent post
    if (param.allow.post && !!payload && (!payload.id || !previous)) {
        operation = getOperation(tracker, { op: "post" }, payload, model)
    }

    // handle child relationships
    if (param.relationships) {
        for (const [key, rel] of Object.entries(param.relationships)) {
            if (!rel) continue
            assumeType<keyof T>(key)
            // @ts-ignore
            assumeType<MutateRequest<T[keyof T]>>(rel)
            const schemaRel = (modelSchema.relationships as any)?.[key]
            if (schemaRel) {
                if (schemaRel.meta) {
                    throw new Error(
                        "Model Schema Not Supported. Direct mutations through meta relationships is not currently supported.",
                    )
                }
                const relModel = (Models as any)[schemaRel.model]
                const relModelSchema =
                    schemaRel.reverse &&
                    relModel[schema]?.relationships?.[schemaRel.reverse]
                if (relModelSchema?.required && schemaRel.required) {
                    throw new Error(
                        `Model Schema invalid. Bi-directional required relationship encountered. (${schemaRel.model}.${schemaRel.reverse})`,
                    )
                }
                if (relModelSchema?.required && operation?.op === "delete")
                    continue // required parent was deleted, BE should cascade the delete automatically
                const node = mutateParamToMutateNode(
                    rel,
                    relModel,
                    (payload as any)?.[key],
                    (previous as any)?.[key],
                    tracker,
                    schemaRel.includeFull,
                )
                if (node) {
                    if (relModelSchema?.required) {
                        dependents[key] = node
                    } else {
                        dependsOn[key] = node
                    }
                }
            }
        }
    }

    // handle patch
    if (
        param.allow.patch &&
        !!payload?.id &&
        previous?.id === payload.id &&
        !includedInParent
    ) {
        // check if object has changed
        if (!modelsEqual(payload, previous, model)) {
            operation = getOperation(
                tracker,
                {
                    op: "patch",
                    previous,
                    rels: changedRels(payload, previous, model),
                },
                payload,
                model,
            )
        }
    }

    // handle filler no-op (relationships only)
    if (
        operation === undefined &&
        (Object.keys(dependents).length || Object.keys(dependsOn).length)
    ) {
        operation = { op: "none" }
    }

    if (operation === undefined) {
        return undefined
    }

    return {
        Model: model,
        payload,
        operation,
        dependsOn,
        dependents,
        isArray: false,
    }
}

/** used by mutateParamToMutateNode to safely add an new mutate operation to a mutate tracking array */
function getOperation<T>(
    tracker: MutateTrack[],
    op: MutateData,
    payload: T,
    model: ModelWithSchema,
) {
    const track = { Model: model, payload, op } as MutateTrack
    const existing = getMatchingMutate(tracker, track)
    if (existing) {
        if (track.op.op !== existing.op.op)
            throw new Error(
                "Missmatching operations tried on the same object during mutate",
            )
        if (track.op.op !== "delete") {
            if (!modelsEqual(track.payload, existing.payload, track.Model))
                throw new Error(
                    "Conflicting patch or post operations tried on the same object during mutate",
                )
        }
    } else {
        tracker.push(track)
    }
    return existing?.op || op
}

/** find a matching mutate operation within the mutate array */
function getMatchingMutate(tracker: MutateTrack[], op: MutateTrack) {
    return tracker.find(
        existing =>
            existing.Model === op.Model &&
            (existing.payload === op.payload ||
                (op.payload.id && op.payload.id === existing.payload.id)),
    )
}

function modelsEqual<T extends InstanceType<ModelWithSchema>>(
    payload: T | undefined,
    previous: T | undefined,
    model: ModelOfInstance<T>,
) {
    const modelSchema = model[schema]
    if (payload === previous) return true
    if (!previous || !payload) return false
    // check attributes
    if (modelSchema.map) {
        const keys = Object.keys(modelSchema.map) as string[]
        for (const key of keys) {
            if (!deepEqual(nestedKey(key, payload), nestedKey(key, previous)))
                return false
        }
    }
    if (modelSchema.relationships) {
        const entries = Object.entries(modelSchema.relationships) as [
            keyof T,
            any,
        ][]
        for (const [key, rel] of entries) {
            if (rel.toMany) {
                if (
                    (payload[key] as any)?.length !==
                    (previous[key] as any)?.length
                )
                    return false
                if (
                    payload[key] &&
                    !(payload[key] as any)?.every((p: any) => {
                        const prev = (previous[key] as any)?.find?.(
                            (prev: any) =>
                                rel.meta
                                    ? prev[rel.meta.through].id ===
                                      p[rel.meta.through].id
                                    : prev.id === p.id,
                        )
                        return (
                            !!prev &&
                            (!rel.includeFull ||
                                modelsEqual(
                                    p,
                                    prev,
                                    (Models as any)[rel.model],
                                ))
                        )
                    })
                )
                    return false
            } else {
                if (
                    rel.meta
                        ? (previous as any)[rel.meta.through].id ===
                          (payload as any)[rel.meta.through].id
                        : (payload[key] as any)?.id !==
                          (previous[key] as any)?.id
                )
                    return false
                else if (
                    rel.includeFull &&
                    !modelsEqual(
                        // @ts-ignore
                        payload[key],
                        previous[key],
                        (Models as any)[rel.model],
                    )
                )
                    return false
            }
        }
    }
    return true
}

function changedRels<T extends InstanceType<ModelWithSchema>>(
    payload: T,
    previous: T | undefined,
    model: ModelOfInstance<T>,
): (keyof T)[] | undefined {
    const modelSchema = model[schema]
    if (!previous || !modelSchema.relationships) return undefined
    const rels: (keyof T)[] = []
    const addRel = (key: keyof T) => {
        if (!rels.includes(key)) rels.push(key)
    }
    const entries = Object.entries(modelSchema.relationships) as [
        keyof T,
        any,
    ][]
    for (const [key, rel] of entries) {
        if (rel.toMany) {
            if (
                rel.alwaysInclude ||
                (payload[key] as any)?.length !== (previous[key] as any)?.length
            )
                addRel(key)
            else if (payload[key]) {
                if (
                    !(payload[key] as any)?.every((p: any) =>
                        (previous[key] as any)?.find?.((prev: any) =>
                            rel.meta
                                ? prev[rel.meta.through].id ===
                                  p[rel.meta.through].id
                                : prev.id === p.id,
                        ),
                    )
                )
                    addRel(key)
                else if (
                    rel.includeFull &&
                    !(payload[key] as any)?.every((p: any) => {
                        const prev = (previous[key] as any)?.find?.(
                            (prev: any) => prev.id === p.id,
                        )
                        return (
                            !!prev &&
                            modelsEqual(p, prev, (Models as any)[rel.model])
                        )
                    })
                )
                    addRel(key)
            }
        } else {
            if (
                rel.alwaysInclude ||
                (rel.meta
                    ? (previous as any)[rel.meta.through].id ===
                      (payload as any)[rel.meta.through].id
                    : (payload[key] as any)?.id !== (previous[key] as any)?.id)
            )
                addRel(key)
            else if (
                rel.includeFull &&
                !modelsEqual(
                    // @ts-ignore
                    payload[key],
                    previous[key],
                    (Models as any)[rel.model],
                )
            )
                addRel(key)
        }
    }
    return rels
}
