import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";

import TaskStatusStepper from "../Optimize/TaskStatusStepper";
import useTrainModel from "./useTrainModel";
import ErrorTab from "./ErrorTab";
import DisplayModelPlot from "./DisplayModelPlot";

export default function PlotModelResultsTab({ model, id }) {
    const { enqueueSnackbar } = useSnackbar()
    const { PollTask, GetTaskStatus, StopTask, stopTaskIsLoading } = useTrainModel({ enqueueSnackbar })
    const [status, setStatus] = useState("Queued")
    const [isPolling, setIsPolling] = useState(false)
    const mounted = useRef(true)

    useEffect(() => () => { mounted.current = false }, [])
    useEffect(() => {
        const endPolling = () => { setIsPolling(false) }
        const task_id = model.task_id
        const error = model.error
        if (!error && task_id && !isPolling) {
            setStatus(GetTaskStatus(task_id))
            setIsPolling(true)
            PollTask(task_id, mounted, setStatus, endPolling, endPolling)
        }
    }, [model.task_id, model.error, PollTask, GetTaskStatus, isPolling, setIsPolling])

    return (
        <>
            {model.plot && (
                <DisplayModelPlot model={model} id={id} />
            )}
            {model.error && (
                <ErrorTab model={model} />
            )}
            {!model.error && model.task_id && (
                <TaskStatusStepper taskStatus={status} stopTask={() => { StopTask(model.task_id) }} loading={stopTaskIsLoading} />
            )}
        </>
    )
}