import { Base } from '../Base'
import { Test } from '../models'
import { Schema, schema } from '../schemaUtils'

export class File extends Base {
    title?: string
    category?: string
    file_name?: string
    parent_id?: string
    tests?: Test[]
    url?: string

    static readonly [schema]: Schema<File> = {
        endPoint: "files",
        type: "file",
        relationships: {
            ...Base[schema].relationships,
            tests: { map: "tests", model: "Test", toMany: true, reverse: "files" }
        },
        map: {
            ...Base[schema].map,
            title: "title",
            file_name: "file_name",
            url: "url",
            parent_id: "parent_id",
            category: "category",
        }
    }

    constructor(toCopy?: Partial<File>) {
        super()
        Object.assign(this, toCopy)
    }
}
