/**
 * @format
 */
import React, { useMemo, useContext, useEffect } from "react"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import common_styles from "../../styles/common_styles"
import FreeSoloSelector from "../General/FreeSoloSelector"
import LabSelector from "../General/LabSelector"
import EditCardBase, {
    useCheckReadOnly,
    useDefaultMutateHandlers,
} from "../General/EditCardBase"
import ProcessNodePreview from "./ProcessNodePreview"
import Hidden from "@material-ui/core/Hidden"
import ProcessVariablesTable from "./ProcessVariablesTable"
import BufferTextField from "../General/BufferTextField"
import { DefaultLabContext } from "../../DefaultLabProvider"
import { LabToProcessDefinition, ProcessDefinition } from "../../schema/models"
import ConfirmationDialog, {
    useConfirmDialog,
} from "../General/ConfirmationDialog"
import { checkLabs } from "utils/invalidators"
import { AccountContext } from "context"
import useFormState from "utils/useFormState/useFormState"
import { value } from "utils/useFormState/useFormStateUtils"

const useStyles = makeStyles(common_styles)

function makeInvalidators() {
    return [
        {
            path: "title",
            validator: title => {
                if (!title || title === "") return "Name required"
            },
        },
        {
            path: "processVariables",
            validator: processVariables => {
                if (processVariables) {
                    if (
                        !processVariables.every(
                            pv => pv.title && pv.title !== "",
                        )
                    )
                        return "All variables must have a name"
                    if (
                        !processVariables.every(
                            (pv, i) =>
                                !processVariables.find(
                                    (v, j) => i !== j && v.id === pv.id,
                                ),
                        )
                    )
                        return "Variable names must be unique"
                }
            },
        },
        {
            path: "labs",
            validator: checkLabs,
        },
    ]
}

const Model = "ProcessDefinition"

export default function EditProcess({
    initialProcessDefinition: initialProcessDefinitionRaw,
    onClose,
}) {
    const classes = useStyles()
    const account = useContext(AccountContext)
    const defaultLab = useContext(DefaultLabContext)
    const initialProcessDefinition = useMemo(
        () =>
            initialProcessDefinitionRaw ||
            new ProcessDefinition({
                labs: [
                    new LabToProcessDefinition({
                        lab: defaultLab,
                        accessLevel: "writer",
                    }),
                ],
            }),
        [defaultLab, initialProcessDefinitionRaw],
    )
    const invalidators = useMemo(
        () => makeInvalidators(initialProcessDefinition),
        [initialProcessDefinition],
    )
    const {
        data: processDef,
        updateData,
        validateAll,
        undoRedo,
        status,
    } = useFormState({
        initialState: { data: initialProcessDefinition },
        validators: invalidators,
    })
    const undo = useMemo(
        () => ({ callback: undoRedo.onUndo, name: undoRedo.undoName }),
        [undoRedo.onUndo, undoRedo.undoName],
    )
    const redo = useMemo(
        () => ({ callback: undoRedo.onRedo, name: undoRedo.redoName }),
        [undoRedo.onRedo, undoRedo.redoName],
    )

    // update lab if default had not loaded
    useEffect(() => {
        if (
            defaultLab &&
            processDef.labs?.length === 1 &&
            processDef.labs[0]?.lab === undefined
        ) {
            updateData({
                labs: [
                    new LabToProcessDefinition({
                        lab: defaultLab,
                        accessLevel: "writer",
                    }),
                ],
            })
        }
    }, [defaultLab, processDef.labs, updateData])

    const {
        handleDelete,
        handleSave,
        handleCopy,
        handleSaved,
    } = useDefaultMutateHandlers({
        Model,
        onClose,
        initialObject: initialProcessDefinition,
        object: processDef,
        validateAll,
        objectName: "Process Definition",
        onCopy(newProcessDefinition) {
            updateData(newProcessDefinition, {
                name: `Copy ${initialProcessDefinition?.title}`,
            })
        },
        updateData,
        account,
    })

    const {
        getConfirmation: confirmSave,
        props: confirmContributorsProps,
    } = useConfirmDialog({
        callback: handleSave,
        title: "Confirm New Contributor",
        message:
            "You do not appear to be a past contributor. Please make sure the owners are aware of any edits you make. Do you wish to be added as a contributor and save these changes?",
        autoConfirm: useMemo(() => {
            if (!processDef.id) {
                return true
            }
            if (
                processDef.id &&
                processDef.contributors?.indexOf(account.name) < 0
            ) {
                return false
            }
            return true
        }, [account.name, processDef.contributors, processDef.id]),
    })
    const {
        getConfirmation: confirmDelete,
        props: confirmDeleteProps,
    } = useConfirmDialog({
        callback: handleDelete,
        title: "Confirm Delete Process Definition",
        message:
            "Deleting cannot be undone, are you sure you wish to permanently delete this process definition?",
    })

    const readOnly = useCheckReadOnly({
        initialObject: initialProcessDefinition,
        object: processDef,
    })

    return (
        <EditCardBase
            onDelete={confirmDelete}
            onSave={confirmSave}
            onSaved={handleSaved}
            onCopy={handleCopy}
            onClose={onClose}
            title={initialProcessDefinition.title}
            editing={!!processDef.id}
            objectName="Process"
            cardDetail="Define the process step below by providing a unique name and associated properties"
            undo={undo}
            redo={redo}
            readOnly={readOnly}
            data-testid="edit process"
        >
            <ConfirmationDialog {...confirmDeleteProps} />
            <ConfirmationDialog {...confirmContributorsProps} />
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className={classes.cardSubtitle}
                            >
                                Basic Information
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BufferTextField
                                size="small"
                                required
                                defaultValue={processDef.title || ""}
                                error={!!status?.invalid?.title?.[value]}
                                helperText={status?.invalid?.title?.[value]}
                                onBlur={ev => {
                                    updateData(
                                        { title: ev.target.value },
                                        { name: "Title Edit" },
                                    )
                                }}
                                fullWidth
                                label="Name"
                                placeholder="Enter Process Name"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FreeSoloSelector
                                value={processDef.tags}
                                onChange={(ev, newVal) =>
                                    updateData(
                                        { tags: newVal },
                                        { name: "Alternate Names Edit" },
                                    )
                                }
                                fullWidth
                                label="Alternate Names, Keywords, and Tags"
                                placeholder={
                                    processDef.tags?.length === 0 ? "" : ""
                                }
                                helperText="Press enter between keywords"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={6}>
                        <ProcessNodePreview process={processDef} />
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <BufferTextField
                        size="small"
                        defaultValue={processDef.description || ""}
                        onBlur={ev =>
                            updateData(
                                { description: ev.target.value },
                                { name: "Description Edit" },
                            )
                        }
                        fullWidth
                        label="Description"
                        placeholder="Enter any notes or description about this process"
                        multiline
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        color={
                            !!status?.invalid?.processVariables?.[value]
                                ? "error"
                                : undefined
                        }
                        variant="subtitle2"
                        className={classes.cardSubtitle}
                    >
                        Process Variables
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ProcessVariablesTable
                        processVariables={processDef.processVariables}
                        onChange={processVariables => {
                            updateData(
                                { processVariables },
                                { name: "Variables Edit" },
                            )
                        }}
                    />
                    {!!status?.invalid?.processVariables && (
                        <Typography variant="caption" color="error">
                            {status?.invalid?.processVariables?.[value]}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        className={classes.cardSubtitle}
                    >
                        Access Permissions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LabSelector
                        error={!!status?.invalid?.labs?.[value]}
                        helperText={status?.invalid?.labs?.[value]}
                        selected={processDef.labs || []}
                        onSelect={labs => {
                            updateData({ labs }, { name: "Labs Edit" })
                        }}
                        variant="outlined"
                        Constructor={LabToProcessDefinition}
                    />
                </Grid>
            </Grid>
        </EditCardBase>
    )
}
