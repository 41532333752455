import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { 
    useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters
} from 'react-table';

import { Button } from '@material-ui/core';

import { useQueryPagination } from '../../API/queryHooks';
import { useAppStoreKey } from '../../AppStore';

import StyledReactTable, { defaultColumn, LOADING_ROW } from '../ReactTable/StyledReactTable';
import { DEFAULT_PAGE_SIZE, DEFAULT_TABLE_STATE } from '../ReactTable/constants';
import CustomizeColumnsDialog from '../ReactTable/CustomizeColumnsDialog';
import { makeQueryFilters, useColumns } from '../ReactTable/reactTableUtils';
import { addOpenColumn } from '../ReactTable/addOpenColumn';

import { instrumentColumns, defaultColumns } from './InstrumentColumns';
import TableButtonGroup from '../General/TableButtonGroup';


function InstrumentTable({ users, tags, onEdit, onNew }) {
    const [serializedColumns, setSerializedColumns, columns, columnsLoading] = useColumns("InstrumentTableColumns", instrumentColumns, defaultColumns);
    const [editColumns, setEditColumns] = useState(false);
    const handleColumns = useCallback(() => setEditColumns(true), []);
    const handleCloseColumns = useCallback((cols) => { setEditColumns(false); setSerializedColumns(cols) }, [setSerializedColumns]);
    const [tableState = { ...DEFAULT_TABLE_STATE }, setTableState] = useAppStoreKey("instrumentTable");

    const queryParam = useMemo(() => {
        // merge table and user filters
        const userFilter = users?.length ? [{
            or: [
                { name: "creator.name", val: users.map(u => `"${u}"`).join(" "), op: "text" },
                { name: "modifier.name", val: users.map(u => `"${u}"`).join(" "), op: "text" }
            ]
        }] : [];
        const tagFilter = tags?.length > 0 ? {
            or: [
                { name: "tagsRel.title", val: tags, op: "ilike" },
                { name: "instruments.tagsRel.title", val: tags, op: "ilike" },
            ]
        } : [];
        const filters = userFilter.concat(tagFilter, makeQueryFilters(tableState?.filters, columns));
        const fields = [];
        if (columns.find(col => col.id === "tags" || ((col.id || col.accessor) !== "instruments.length" && (col.id || col.accessor).startsWith("instruments"))))
            fields.push("instruments");
        // if (!!serializedColumns.find(col => col.colId.startsWith("labs")))
        //     fields.push("labs.title");

        // convert sort and append timeModified
        const sort = (tableState?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");
        return ({
            Model: "Instrument",
            pageNumber: tableState?.pageIndex + 1,
            pageSize: tableState?.pageSize,
            filter: filters,
            sort,
            fields: fields.length > 0 ? fields : undefined
        });
    }, [columns, tableState?.filters, tableState?.pageIndex, tableState?.pageSize, tableState?.sortBy, tags, users]);

    const { data: resolvedData, isLoading, isFetching } = useQueryPagination((tableState.pageIndex || 0) + 1, queryParam, {logName: "Instrument Table"}, LOADING_ROW);
    const loading = columnsLoading;
    const data = useMemo(() => resolvedData?.data || [], [resolvedData]);
    const handleEdit = useCallback((row) => {
        onEdit(row)
    }, [onEdit])

    const table = useTable({
        columns,
        defaultColumn,
        initialState: tableState,
        data,
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        autoResetFilters: false,
        totalRows: resolvedData?.count,
        pageCount: Math.max(Math.ceil((resolvedData?.count || 0) / (tableState?.pageSize || DEFAULT_PAGE_SIZE)), 1),
        getRowId: useCallback((row, relIndex, parent) => parent ? [parent.id, row.id || relIndex] : (row.id || relIndex), []),
        autoResetSelectedRows: false,
        onEdit: handleEdit
    },
        useFlexLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        addOpenColumn,
    );

    const gotoPage = table.gotoPage;
    useEffect(() => {
        gotoPage && gotoPage(0);
    }, [users, tags, gotoPage]);
    const { state: { filters }, setAllFilters } = table;
    const handleClearFilters = useCallback(() => {
        setAllFilters && setAllFilters([]);
    }, [setAllFilters]);

    return (
        <div style={{ textAlign: "initial" }}>
            <CustomizeColumnsDialog
                columnDefinitions={instrumentColumns}
                serializedColumns={serializedColumns}
                defaultColumns={defaultColumns}
                open={editColumns}
                onClose={handleCloseColumns}
            />
            <TableButtonGroup>
                <Button disabled={!filters || filters.length === 0} color='primary' variant='text' onClick={handleClearFilters}>
                    Clear Filters
                </Button>
                <Button onClick={handleColumns}>
                    Edit Columns and Filters
                </Button>
            </TableButtonGroup>
            <StyledReactTable
                {...table}
                loading={loading || (isLoading && !data?.length)}
                updating={isFetching || (isLoading && data?.length)}
                onChangeParam={setTableState}
            />
        </div>
    );
}
export default React.memo(InstrumentTable);