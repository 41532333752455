/**
 * @format
 */
import React, {
    useRef,
    useImperativeHandle,
    useState,
    useEffect,
    useMemo,
} from "react"
import {
    TextField,
    InputAdornment,
    IconButton,
    ClickAwayListener,
    Popper,
    Typography,
    makeStyles,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import { ExpressionHelpDialog } from "./ExpressionHelpDialog"
import { matchSorter, rankings } from "match-sorter"
import { mathjsFunctions } from "./functionList"

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: "1301",
    },
    popperInner: {
        background: theme.palette.grey[200],
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: "4px",
        color: theme.palette.grey[800],
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1, 2),
    },
}))

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value",
).set
const nativeTextareaValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLTextAreaElement.prototype,
    "value",
).set

export default React.forwardRef(function ExpressionInput(
    {
        expression,
        setExpression,
        inputRef,
        onChange,
        onKeyUp,
        className,
        scope,
        multiline,
        noHelp,
        ...props
    },
    ref,
) {
    const classes = useStyles()

    const expressionRef = useRef()
    useImperativeHandle(inputRef, () => expressionRef?.current)

    const timer = useRef(undefined)
    useEffect(() => () => timer.current && clearTimeout(timer.current), []) // clear any timer on unmount

    const [openHelp, setOpenHelp] = useState(false)

    const [popperOpen, setPopperOpen] = useState(undefined)

    const handleChange = ev => {
        setExpression(ev.target.value)
        onChange && onChange(ev)
    }

    const keywords = useMemo(() => {
        if (!scope) return mathjsFunctions
        return scope.concat(mathjsFunctions)
    }, [scope])

    // on key up debounce check for autocomplete of mathjs function
    const handleKeyUp = ev => {
        onKeyUp && onKeyUp(ev)

        const {
            target: { selectionStart, value },
            key,
        } = ev

        timer.current && clearTimeout(timer.current)

        if (selectionStart && key.length === 1 && key.match(/[a-zA-Z0-9]/)) {
            timer.current = setTimeout(() => {
                let popperValue = undefined
                const beforeStart = value.substring(0, selectionStart)
                const match = beforeStart.match(/(?:\s|^)([a-zA-Z]+[0-9]*)$/s)
                if (match) {
                    const toComplete = match[1]
                    const matchingKeywords = matchSorter(keywords, toComplete, {
                        keys: [{ key: "0", threshold: rankings.STRING_CASE }],
                    })
                    if (matchingKeywords.length > 0) {
                        const bestMatch = matchingKeywords[0][0]
                        if (bestMatch.startsWith(toComplete)) {
                            const newVal =
                                value.substring(
                                    0,
                                    selectionStart - toComplete.length,
                                ) +
                                bestMatch +
                                value.substring(selectionStart)
                            ;(multiline
                                ? nativeTextareaValueSetter
                                : nativeInputValueSetter
                            ).call(expressionRef.current, newVal)
                            expressionRef.current.setSelectionRange(
                                selectionStart,
                                selectionStart -
                                    toComplete.length +
                                    bestMatch.length,
                            )
                            expressionRef.current.dispatchEvent(
                                new Event("input", { bubbles: true }),
                            )
                        }
                        popperValue = matchingKeywords.slice(0, 5)
                    }
                }
                setPopperOpen(popperValue)
                timer.current = undefined
            }, 100)
        } else if (!["Shift", "Control", "Alt"].includes(key)) {
            popperOpen && setPopperOpen(undefined)
        }
    }

    return (
        <div className={className} style={{ display: "flex", width: "100%" }}>
            {!noHelp && (
                <ExpressionHelpDialog
                    open={openHelp}
                    onClose={() => setOpenHelp(false)}
                />
            )}
            <ClickAwayListener
                onClickAway={() => popperOpen && setPopperOpen(undefined)}
            >
                <div className={className} style={{ width: "100%" }}>
                    <Popper
                        className={classes.popper}
                        open={!!popperOpen}
                        anchorEl={expressionRef.current}
                        placement="bottom-start"
                    >
                        <div className={classes.popperInner}>
                            {popperOpen &&
                                popperOpen.map((value, key) => {
                                    return (
                                        <Typography key={key} variant="caption">
                                            {value[1]}
                                        </Typography>
                                    )
                                })}
                        </div>
                    </Popper>
                    <TextField
                        variant="outlined"
                        size="small"
                        value={expression}
                        onChange={handleChange}
                        fullWidth
                        onKeyUp={handleKeyUp}
                        onKeyDown={() =>
                            timer.current && clearTimeout(timer.current)
                        }
                        InputProps={{
                            spellCheck: false,
                            className: classes.input,
                            endAdornment: !!expression ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setExpression("")}
                                        size="small"
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined,
                        }}
                        ref={ref}
                        inputRef={expressionRef}
                        multiline={multiline}
                        {...props}
                    />
                </div>
            </ClickAwayListener>
            {!noHelp && (
                <IconButton onClick={() => setOpenHelp(true)} size="small">
                    <HelpOutlineIcon fontSize="small" />
                </IconButton>
            )}
        </div>
    )
})
