import { useEffect, useState } from 'react';
import {
    Checkbox, ClickAwayListener, FormControlLabel, makeStyles, Paper,
    Slide, Typography
} from '@material-ui/core';
import { useAppStoreDispatchKey } from 'AppStore';

import { useLocalForageState } from 'utils/useLocalforageState';
import { UserGuide } from '../TrainingContent/TrainingContent';

const useStyles = makeStyles(theme => ({
    wrapper: {
        zIndex: 10000,
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
    },
    paper: {
        zIndex: 10000,
        margin: "auto",
        marginTop: theme.spacing(4),
        maxWidth: "800px",
        position: "relative",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.type === "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
        color: "white"
    },
    link: {
        color: theme.palette.type === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark
    },
    linkBold: {
        color: theme.palette.type === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
        fontWeight: "bold",
        fontSize: "20px"
    }
}))

// edit messageId whenever the message text changes
export const currentMessageId = "update 1.3.9"
const endDate = new Date(2022, 12, 20)


export default function UpdateMessage({ open, setOpen }) {

    const classes = useStyles()

    const [timer, setTimer] = useState(false)
    const [messageId, setMessageId, isLoading] = useLocalForageState("Startup Message", null)
    const setHasMessage = useAppStoreDispatchKey("hasMessage")
    const releaseGHLink = "https://github.mmm.com/MMM/MaterialsManager/blob/master/release_notes/user_feedback_20221021.md";
    const emailSignupLink = process.env.REACT_APP_EMAIL_SIGNUP_FORM_URL;

    useEffect(() => {
        if (endDate > new Date()) {
            const timer = setTimer(() => {
                setTimer(true)
            }, 3000)
            return () => { clearTimeout(timer) }
        }
    }, [])

    useEffect(() => {
        if (!isLoading) {
            if (timer) {
                if (messageId === null) {
                    setOpen(true)
                }
                if (messageId !== currentMessageId && messageId !== null) {
                    setHasMessage(true)
                }
                else {
                    setHasMessage(false)
                }
            }
        }
    }, [isLoading, messageId, setHasMessage, setMessageId, setOpen, timer])

    useEffect(() => {
        if (open && messageId !== null && messageId !== currentMessageId) {
            setMessageId(currentMessageId)
        }
    }, [messageId, open, setMessageId])

    return (
        <div className={classes.wrapper}>
            <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                <div>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <Paper elevation={4} className={classes.paper}>
                            <Typography variant="h6">Recent Materials Manager Updates</Typography>
                            <br />
                            <Typography variant="subtitle1">Release Update to 1.3.9, 11/10/2022</Typography>
                            <Typography variant="body2" >
                                Numerous updates have been made and features implemented in this release. These updates are documented in the <UserGuide fontSize={14} /> and in the <a className={classes.link} href={releaseGHLink} target="_blank" rel="noopener noreferrer">release notes</a>.
                            </Typography>
                            <br />
                            <Typography variant="body2">
                                Some notable updates include:<br />
                                - <a className={classes.linkBold} href={emailSignupLink} target="_blank" rel="noopener noreferrer">Skylab Email Notification Signup</a><br />
                                <br />
                                These emails are intended to alert you, the user, when outages occur and when major features are implemented.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!messageId}
                                        onChange={(ev) => setMessageId(ev.target.checked ? currentMessageId : null)}
                                        color="default"
                                    />
                                }
                                label="Do not show this message again"
                            />
                        </Paper>
                    </ClickAwayListener>
                </div>
            </Slide>
        </div>
    )
}
