import { FlowChart } from "schema/FlowChart"
import { autoArrange, nodeSortFunc } from "components/FlowChart/flowChartUtils"
import { commonProperties } from "components/PropertyLibrary/commonProperties"
import { NodeAliases, ProcessChart, ProcessDefinition, ProcessInstance, ProcessNodes, PropertyDefinition, PropertyInstance, Sample, SampleInstance, SampleSet } from "schema/models"
import { updateProcessNodesFromSamples, _ProcessChartFromOldFlowchart } from "components/FlowChart/processChartUtils"
import { deepmerge } from "./deepmerge"
import { v4 } from 'uuid'
import { useCallback, useEffect } from "react"
import { assumeType } from "schema/schemaUtils"
import { format } from "date-fns"
import { compileExpression, getExpressionResults } from "components/ExpressionEditor/expressionUtils"

export function groupSetInstancesByNode({ sampleSet }: { sampleSet: SampleSet}) {
    const componentGroups: any = []
    const componentAdded: any = {}
    const grouplessComponents: any = []
    const grouplessComponentsAdded: any = {}
    const processGroups: any = []
    const processAdded: any = {}
    const propertyGroups: any = []
    const propertyAdded: any = {}
    const grouplessProperties: any = []
    const grouplessPropertiesAdded: any = {}
    const flowChart =
        sampleSet.flowChart &&
        autoArrange(
            new FlowChart(sampleSet.flowChart),
            { width: 100, height: 16, force: true },
            { x: 0, y: 0, scale: 1 },
        )
    if (flowChart) {
        const nodes: any = Object.values(flowChart.nodes).sort(nodeSortFunc)
        for (const node of nodes) {
            switch (node.type) {
                case "material":
                    if (!componentAdded[node.id]) {
                        componentAdded[node.id] = true
                        componentGroups.push(node)
                    }
                    break
                case "process":
                    if (!processAdded[node.id]) {
                        processAdded[node.id] = true
                        processGroups.push(node)
                    }
                    break
                default:
                    if (!propertyAdded[node.id]) {
                        propertyAdded[node.id] = true
                        propertyGroups.push(node)
                    }
            }
        }
    }

    if (sampleSet.samples) {
        for (const sample of sampleSet.samples) {
            if (sample.components) {
                for (const component of sample.components) {
                    if (
                        component.definition &&
                        (!component.node?.id ||
                            !componentAdded[component.node?.id]) &&
                        !grouplessComponentsAdded[component.definition.id || ""]
                    ) {
                        grouplessComponentsAdded[component.definition.id || ""] = true
                        grouplessComponents.push(component.definition)
                    }
                }
            }
            if (sample.properties) {
                for (const property of sample.properties) {
                    if (
                        property.definition &&
                        (!property.node?.id ||
                            !propertyAdded[property.node?.id]) &&
                        !grouplessPropertiesAdded[property.definition.id || ""]
                    ) {
                        grouplessPropertiesAdded[property.definition.id || ""] = true
                        grouplessProperties.push(property.definition)
                    }
                }
            }
        }
    }

    return {
        componentGroups,
        componentAdded,
        grouplessComponents,
        processGroups,
        propertyGroups,
        propertyAdded,
        grouplessProperties,
        flowChart,
    }
}

export function buildDataArray({
    sampleSet
}: { sampleSet: SampleSet}) {
    const samples = sampleSet.samples?.filter(Boolean) || []
    const data: any = []
    const headers = ["Title", "QID"]
    samples.forEach((s,i) => data[i] = [s?.title, s?.qid])

    const {
        componentGroups,
        componentAdded,
        grouplessComponents,
        processGroups,
        propertyGroups,
        propertyAdded,
        grouplessProperties,
    } = groupSetInstancesByNode({ sampleSet })

    let colIndex = 2

    // write all group components
    for (const group of componentGroups) {
        const end =
            Object.keys(group.properties?.definitions || {}).length + colIndex
        const start = colIndex
        const componentDefs: any = Object.values(
            group.properties?.definitions || {},
        ).sort((a: any, b: any) => (a?.title > b?.title ? 1 : -1))

        for (; colIndex < end; colIndex++) {
            const componentDef = componentDefs[colIndex - start]
            for (let i = 0; i < samples.length; ++i) {
                const component = samples[i].components?.find(
                    instance =>
                        instance.definition?.id === componentDef?.id &&
                        instance.node?.id === group.id,
                )
                headers[colIndex] = `${componentDef?.title} | ${group.properties?.label}`
                data[i][colIndex] = parseFloat(component?.totalFraction as any) || 0
            }
        }
    }

    // write groupless components
    if (grouplessComponents.length) {
        const end = grouplessComponents.length + colIndex
        const start = colIndex
        for (colIndex; colIndex < end; colIndex++) {
        const componentDefinition = grouplessComponents[colIndex - start]
            for (let i = 0; i < samples.length; ++i) {
                const component = samples[i].components?.find(
                    instance =>
                        instance.definition?.id === componentDefinition.id &&
                        !componentAdded[instance.node?.id || ""],
                )
                headers[colIndex] = `${componentDefinition?.title}`
                data[i][colIndex] = parseFloat(component?.totalFraction as any) || 0
            }
        }
    }

    // write all process variables 
    for (const group of processGroups) {
        const end =
            (group.properties?.definition?.processVariables?.length || 1) +
            colIndex
        const start = colIndex
        const processVariables =
            group.properties?.definition?.processVariables || []

        for (colIndex; colIndex < end; colIndex++) {
            const processVariable = processVariables[colIndex - start] || {}

            for (let i = 0; i < samples.length; ++i) {
                const processStep = samples[i].processSteps?.find(
                    instance => instance.node?.id === group.id,
                )
                headers[colIndex] = `${group.properties?.label} | ${processVariable?.title}`
                data[i][colIndex] =
                    (processStep?.data || {})[processVariable?.title] || ""
            }
        }
    }

    // write all properties
    for (const group of propertyGroups) {
        const end =
            Object.keys(group.properties?.definitions || {}).length + colIndex
        const start = colIndex
        const propertyDefs: any = Object.values(
            group.properties?.definitions || {},
        ).sort((a: any, b: any) => (a?.title > b?.title ? 1 : -1))

        for (colIndex; colIndex < end; colIndex++) {
            const propertyDef = propertyDefs[colIndex - start]
            for (let i = 0; i < samples.length; ++i) {
                const property = samples[i].properties?.find(
                    instance =>
                        instance.definition?.id === propertyDef?.id &&
                        instance.node?.id === group.id,
                )
                headers[colIndex] = `${propertyDef?.title} | ${group.properties?.label}`
                data[i][colIndex] = property?.data || ""
            }
        }
    }

    // write groupless properties
    if (grouplessProperties.length) {
        grouplessProperties.sort((a: any, b: any) => {
            if (a.title === b.title) return 0
            if (a.title === commonProperties.molecularWeight) return -1
            if (b.title === commonProperties.molecularWeight) return 1
            if (a.title === commonProperties.density) return -1
            if (b.title === commonProperties.density) return 1
            return a.title < b.title ? -1 : 1
        })
        const end = grouplessProperties.length + colIndex
        const start = colIndex
        for (colIndex; colIndex < end; colIndex++) {
            const propertyDefinition = grouplessProperties[colIndex - start]
            for (let i = 0; i < samples.length; ++i) {
                const property = samples[i].properties?.find(
                    instance =>
                        instance.definition?.id === propertyDefinition.id &&
                        !propertyAdded[instance.node?.id || ""],
                )
                headers[colIndex] = `${propertyDefinition?.title}`
                data[i][colIndex] = property?.data || ""
            }
        }
    }

    // make sure head names are unique
    const duplicates = headers.map((header, i, arr) => {
        let count = 1
        for (let j = 0; j < i; ++j) {
            if (header === arr[j]) count++
        }
        return count
    })
    duplicates.forEach((count, i) => {
        if (count > 1) headers[i] = `${headers[i]} (${count})`
    })

    return { data, headers }
}

export function calculateTotal(sample?: Sample, nodeIds?: (string | undefined)[]) {
    if (!sample?.components?.length) return null
    let total = 0
    for (const comp of sample.components) {
        if (!nodeIds?.length || nodeIds.includes(comp.nodeId))
        total += comp.amount || 0
    }
    return total
}

export function applyNodeAlias(nodeId?: string, nodeAliases?: NodeAliases, sampleId?: string): string {
    if (!nodeId || !nodeAliases || !sampleId || !nodeAliases[sampleId]) return nodeId || ""
    return Object.entries(nodeAliases[sampleId]).find(([, aliasId]) => nodeId === aliasId)?.[0] || nodeId
}

export function mergeSampleSets(sets: SampleSet[]) {
    // look up or create processChart
    const processCharts = sets.map(set => {
        let processChart = set.processChart
        if (!processChart) {
            if (set.flowChart) {
                processChart = updateProcessNodesFromSamples(
                    _ProcessChartFromOldFlowchart(
                        set.flowChart,
                    ),
                    set.samples
                )
            }
        }
        return processChart
    })

    // compile all set aliases
    const idToLabel = new Map<string, string>()
    const labelToId = new Map<string, string>()
    const setAliases: {oldId: string, newId: string}[][] = []

    for (let i = 0; i < processCharts.length; ++i) {
        let chartNodes = processCharts[i]?.nodes
        if (!chartNodes) continue
        for (const [nodeId, node] of Object.entries(chartNodes)) {
            if (!nodeId) {
                continue
            }
            if (!node.label) continue

            const existingLabelId = labelToId.get(node.label.trim())
            if (!existingLabelId) {
                const existingIdLabel = idToLabel.get(nodeId)
                if (existingIdLabel) { // label doesn't exist but id is already in use
                    const newId = v4()
                    if (!setAliases[i]) setAliases[i] = []
                    setAliases[i].push({ oldId: nodeId, newId })
                    labelToId.set(node.label.trim(), newId)
                    idToLabel.set(newId, node.label.trim())
                    continue
                }
                else {
                    labelToId.set(node.label.trim(), nodeId)
                    idToLabel.set(nodeId, node.label.trim())
                    continue
                }
            }
            else if (existingLabelId !== nodeId) { // label exists but the id doesn't match
                if (!setAliases[i]) setAliases[i] = []
                setAliases[i].push({ oldId: nodeId, newId: existingLabelId })
            }
        }
    }

    // build new nodes
    const nodes: ProcessNodes = {}
    for (let i = 0; i < processCharts.length; ++i) {
        let chartNodes = processCharts[i]?.nodes
        if (!chartNodes) continue
        for (const [nodeId, oldNode] of Object.entries(chartNodes)) {
            const to = oldNode?.to?.map(to => {
                const alias = setAliases[i]?.find(({oldId}) => oldId === to)
                return alias?.newId || to
            })
            const node = to ? {...oldNode, to} : oldNode
            if (!nodeId) {
                nodes[""] = deepmerge(node, nodes[""] || {})
                continue
            }
            if (!node.label) continue
            const newId = setAliases[i]?.find(({oldId}) => oldId === nodeId)?.newId || nodeId
            if (nodes[newId]) {
                if (nodes[newId]?.type && nodes[newId]?.type !== node.type) {
                    nodes[newId].type = "mixed"
                }
                nodes[newId] = deepmerge(node || {}, nodes[newId] || {})
            }
            else {
                nodes[newId] = {...node}
            }
        }
    }

    // update all aliases
    const nodeAliases: NodeAliases = {}
    for (let i = 0; i < sets.length; ++i) {
        const currentAliases = sets?.[i]?.nodeAliases || {}
        if (!sets[i].samples) continue
        for (const sample of (sets[i].samples || [])) {
            if (!sample.id) continue
            const csa = currentAliases[sample.id]
            if (setAliases[i]) {
                if (!nodeAliases[sample.id]) nodeAliases[sample.id] = {}
                for (let {oldId, newId} of setAliases[i]) {
                    if (csa) {
                        const a = Object.entries(csa).find(([, originalAlias]) => originalAlias === oldId)
                        if (a) oldId = a[0]
                    }
                    nodeAliases[sample.id][oldId] = newId
                }
            }
            if (csa){
                for (const [oldId, newId] of Object.entries(csa)) {
                    if (!nodeAliases[sample.id]?.[oldId]) {
                        if (!nodeAliases[sample.id]) nodeAliases[sample.id] = {}
                        nodeAliases[sample.id][oldId] = newId
                    }
                }
            }
        }
    }

    // merge samples
    const newSamplesMap = new Map<string, Sample>()
    for (const set of sets) {
        if (set.samples) {
            for (const sample of set.samples) {
                if (sample?.id)
                    newSamplesMap.set(sample.id, sample)
            }
        }
    }

    // build process chart
    let processChart = new ProcessChart({nodes})

    const description = `Merged from: ${sets.map(s => s.title).join(", ")}`

    const samples = Array.from(newSamplesMap.values())
    return new SampleSet({samples, processChart: processChart, nodeAliases, description })
}

export function mergeSamples(samples: Sample[]) {
    // compile Sets
    const setMap = new Map<string, SampleSet>()
    const noChart: Sample[] = []
    for (const sample of samples) {
        if (sample.processChart?.id) {
            const current = setMap.get(sample.processChart.id)
            if (current) {
                current.samples?.push(sample)
            }
            else {
                setMap.set(sample.processChart.id, new SampleSet({
                    title: sample.processChart.title,
                    samples: [sample],
                    processChart: sample.processChart
                }))
            }
        }
        else {
            noChart.push(sample)
        }
    }
    const sets = Array.from(setMap.values())
    if (noChart.length)
        sets.push(new SampleSet({samples: noChart}))
    const set =  mergeSampleSets(sets)
    set.description = "Merged from samples"
    return set
}

/** Takes the defs and pushes them into the instances of the samples in the set. References remain stable if nothing changes */
export function addDefsToSet(sampleSet: SampleSet, original: SampleSet | undefined, materialDefs: Sample[] | undefined, processDefs: ProcessDefinition[] | undefined, propertyDefs: PropertyDefinition[] | undefined) {
    let newSet = sampleSet
    if (!newSet.samples) return sampleSet
    for (let i = 0; i < (newSet.samples as any).length; ++i) {
        const sample = addDefsToSample((newSet.samples as Sample[])[i], original?.samples?.[i], materialDefs, processDefs, propertyDefs)
        if (sample !== newSet.samples?.[i]) {
            if (newSet === original) {
                newSet = {...newSet}
            }
            if (newSet.samples === original?.samples) {
                newSet.samples = [...(newSet.samples || [])]
            }
            (newSet.samples as Sample[])[i] = sample
        }
    }
    return newSet
}

/** Takes the defs and pushes them into the instances of the sample. References remain stable if nothing changes */
export function addDefsToSample(sample: Sample, original: Sample | undefined, materialDefs: Sample[] | undefined, processDefs: ProcessDefinition[] | undefined, propertyDefs: PropertyDefinition[] | undefined) {
    let newSample = sample
    newSample = addDefsToInstances(newSample, original, "components", materialDefs)
    newSample = addDefsToInstances(newSample, original, "processSteps", processDefs)
    newSample = addDefsToInstances(newSample, original, "properties", propertyDefs)
    return newSample
}
function addDefsToInstances(sample: Sample, original: Sample | undefined, defKey: "components" | "processSteps" | "properties", defs: any[] | undefined) {
    if (!sample[defKey] || !defs) return sample
    for (let i = 0; i < (sample[defKey] as any).length; ++i) {
        const inst = (sample[defKey] as any)[i]
        if (!inst.definition?.id) continue
        if (!defs.includes(inst.definition)) {
            const def = defs.find(def => def.id === inst.definition?.id)
            if (!def) continue
            if (sample === original) {
                sample = {...sample}
            }
            if (sample[defKey] === original?.[defKey]) {
                sample[defKey] = [...(sample[defKey] || [])] as any
            }
            if ((sample[defKey] as any)[i] === original?.[defKey]?.[i]) {
                (sample[defKey] as any)[i] = {...(sample[defKey] as any)[i]}
            }
            (sample[defKey] as any)[i].definition = def
        }
    }
    return sample
}

/** updates defsRef and calls updateFunction if any of the instanceDef change. This assumes a transform has been defined to merge the updated defs */
export function useUpdateDefs(defsRef: any, updateFunction: any, materialDefs: Sample[] | undefined, processDefs: ProcessDefinition[] | undefined, propertyDefs: PropertyDefinition[] | undefined) {
    useEffect(() => {
        if (!defsRef.current ||
        (materialDefs !== defsRef.current.materialDefs ||
        processDefs !== defsRef.current.processDefs ||
        propertyDefs !== defsRef.current.propertyDefs)){
            const init = !defsRef.current
            defsRef.current = {
                materialDefs,
                processDefs,
                propertyDefs,
            }
            if (!init)
                updateFunction?.((d: any) => d)
        }
    }, [defsRef, materialDefs, processDefs, propertyDefs, updateFunction])
}

type NodeMove = {
    newNodeId: string
    defId: string
}
/** Handle moved and removed nodes from a sampleSet */
export function useChangeSampleSetNode(updateSampleSet: any) {
    return useCallback((nodeId: string, removed: string[], moved: NodeMove[]) => {
        if (!removed.length && !moved.length) return
        updateSampleSet((sampleSet?: SampleSet) => {
            if (!sampleSet?.samples?.length) return sampleSet
            let newSet = sampleSet
            for (let i = 0; i < sampleSet.samples.length; ++i) {
                const sample: Sample = sampleSet.samples[i]
                if (!sample) continue
                const oldNodeId = applyNodeAlias(nodeId, sampleSet.nodeAliases, sample.id)
                for (const removeId of removed) {
                    newSet = updateInstanceRemove(i, oldNodeId, removeId, sample, newSet, sampleSet, "components")
                    newSet = updateInstanceRemove(i, oldNodeId, removeId, sample, newSet, sampleSet, "processSteps")
                    newSet = updateInstanceRemove(i, oldNodeId, removeId, sample, newSet, sampleSet, "properties")
                }
                for (let move of moved) {
                    if (sample.id && sampleSet.nodeAliases) {
                        move = {...move, newNodeId: applyNodeAlias(move.newNodeId, sampleSet.nodeAliases, sample.id)}
                    }
                    newSet = updateInstanceMove(i, oldNodeId, move, sample, newSet, sampleSet, "components")
                    newSet = updateInstanceMove(i, oldNodeId, move, sample, newSet, sampleSet, "processSteps")
                    newSet = updateInstanceMove(i, oldNodeId, move, sample, newSet, sampleSet, "properties")
                }
            }
            return newSet
        }, "Change Nodes")
    }, [updateSampleSet])
}
function updateInstanceRemove(i: number, nodeId: string, defId: string, sample: Sample, newSet: SampleSet, sampleSet: SampleSet, instKey: "components" | "processSteps" | "properties") {
    if (sample[instKey]?.length) {
        const newInst = (sample[instKey] as (any[])).filter((inst: any) => inst.definition?.id !== defId)
        if (newInst.length !== sample[instKey]?.length) {
            if (newSet === sampleSet) {
                newSet = { ...newSet }
            }
            if (newSet.samples === sampleSet.samples) {
                newSet.samples = [...(newSet.samples as any)]
            }
            assumeType<Sample[]>(newSet.samples)
            if (newSet.samples[i] === (sampleSet.samples as any)[i]) {
                newSet.samples[i] = { ...newSet.samples[i] }
            }
            newSet.samples[i][instKey] = newInst
        }
    }
    return newSet
}
function updateInstanceMove(i: number, oldNodeId: string, change: NodeMove, sample: Sample, newSet: SampleSet, sampleSet: SampleSet, instKey: "components" | "processSteps" | "properties") {
    if (sample[instKey]?.length) {
        for (let j = 0; j < (sample[instKey]?.length || 0); ++j) {
            const inst = (sample[instKey] as (SampleInstance | ProcessInstance | PropertyInstance)[])[j]
            if (inst.nodeId === oldNodeId && (!change.defId || inst.definition?.id === change.defId)) {
                if (newSet === sampleSet) {
                    newSet = { ...newSet }
                }
                if (newSet.samples === sampleSet.samples) {
                    newSet.samples = [...(newSet.samples as any)]
                }
                assumeType<Sample[]>(newSet.samples)
                if (newSet.samples[i] === (sampleSet.samples as any)[i]) {
                    newSet.samples[i] = { ...newSet.samples[i] }
                }
                if (newSet.samples[i][instKey] === (sampleSet.samples as any)[i][instKey]) {
                    newSet.samples[i][instKey] = [...(newSet.samples[i][instKey] as any)]
                }
                if ((newSet.samples[i][instKey] as any)[j] === ((sampleSet.samples as any)[i][instKey] as any)[j]) {
                    (newSet.samples[i][instKey] as any)[j] = {...((newSet.samples[i][instKey] as any)[j])}
                }
                (newSet.samples[i][instKey] as any)[j].nodeId = change.newNodeId
            }
        }
    }
    return newSet
}

const MAX_ITERATIONS = 2
export function updateSetCalculatedProperties(sampleSet: SampleSet, original: SampleSet | undefined, compiledMap: Map<string, any> = new Map<string, any>()) {
    if (!sampleSet.samples || sampleSet.samples === original?.samples) return sampleSet
    let newSet = sampleSet
    for (let i = 0; i < (sampleSet.samples?.length || 0); ++i) {
        const sample = updateSampleCalculatedProperties(sampleSet.samples[i], original?.samples?.[i], compiledMap)
        if (sample !== original?.samples?.[i]) {
            (newSet.samples as Sample[])[i] = sample
        }
    }
    return newSet
}
export function updateSampleCalculatedProperties(sample: Sample, original: Sample | undefined, compiledMap: Map<string, any> = new Map<string, any>()) {
    if (!sample?.properties || sample === original) return sample
    if (!sample.properties.some(p => p.definition?.source === "cv")) return sample // no calculated properties to update.
    let newSample = sample
    const sourceNotes = `Calculated: ${format(new Date(), "Pp")}`
    for (let i = 0; i < MAX_ITERATIONS; ++i) {
        let changed = false
        for (let j = 0; j < (sample.properties?.length || 0); ++j) {
            const prop = sample.properties[j]
            let def: PropertyDefinition | undefined = prop.definition
            let expression = def?.sourceParameters?.expression
            if (def?.source === "cv" && expression) {
                try {
                    // use compiled map cache if available for performance
                    let compiled = compiledMap.get(def.id || "")
                    if (!compiled) {
                        compiled = compileExpression(expression)
                        compiledMap.set(def.id || "", compiled)
                    }
                    let result = getExpressionResults({
                        sample: newSample,
                        expression: compiled,
                    })
                    if (def.type === "number") result = parseFloat(result)
                    if (prop.data !== result) {
                        changed = true
                        if (newSample.properties === original?.properties) {
                            newSample.properties = [...(newSample.properties || [])]
                        }
                        (newSample.properties as PropertyInstance[])[j] = new PropertyInstance({
                            ...prop,
                            data: result,
                            sourceNotes,
                        })
                    }
                } catch (error) {
                    // @ts-ignore
                    if (prop.data !== error.message) {
                        changed = true
                        if (newSample.properties === original?.properties) {
                            newSample.properties = [...(newSample.properties || [])]
                        }
                        (newSample.properties as PropertyInstance[])[i] = new PropertyInstance({
                            ...prop,
                            // @ts-ignore
                            data: error.message,
                            sourceNotes,
                        })
                    }
                    continue
                }
            }
        }
        if (!changed) break
    }
    return newSample
}

export function getCVProps(properties: PropertyDefinition[] | undefined) {
    if (!properties) return properties
    const map = new Map<string, PropertyDefinition>()
    for (const prop of properties) {
        if (prop.source === "cv") {
            map.set(prop.id || "", prop)
        }
    }
    return Array.from(map.values())
}

export function addSampleSetCalProps(sampleSet: SampleSet, original: SampleSet | undefined, nodes: ProcessNodes | undefined, properties: PropertyDefinition[] | undefined) {
    if (!sampleSet.samples) return sampleSet
    //check if the calc props are actually being removed from the list
    const props = getCVProps(properties)?.filter(prop => {
        if (sampleSet.samples) {
            for (const sample of sampleSet.samples) {
                if (sample.properties) {
                    for (const property of sample.properties) {
                        if (property.definition?.id === prop.id) {
                            return true
                        }
                    }
                }
            }
        }
        if (original && original.samples){
            for (const sample of original.samples) {
                if (sample.properties) {
                    for (const property of sample.properties) {
                        if (property.definition?.id === prop.id) {
                            console.log("found prop in original list")
                            return false
                        }
                    }
                }
            }
        }
        if (nodes && Object.values(nodes).find(node => node.propertyDefs?.[prop.id || ""])) {
            return true
        }
        return false
    })

    for (let i = 0; i < (sampleSet.samples?.length || 0); ++i) {
        const newSample = addSampleCalProps((sampleSet.samples as Sample[])[i], original?.samples?.[i], nodes, props, true)
        if (newSample !== (sampleSet.samples as Sample[])[i]) {
            if (sampleSet === original) sampleSet = {...sampleSet}
            if (sampleSet.samples === original?.samples) {
                sampleSet.samples = [...(sampleSet.samples || [])]
            }
            (sampleSet.samples as Sample[])[i] = newSample
        }
    }
    return sampleSet
}
export function addSampleCalProps(sample: Sample, original: Sample | undefined, nodes: ProcessNodes | undefined, properties: PropertyDefinition[] | undefined, filtered: boolean = false) {
    if (!properties) return sample
    const cvProps = (filtered ? properties : getCVProps(properties)) || []
    if (cvProps.length <= 0) return sample
    for (const prop of cvProps) {
        if (!sample.properties?.find(p => p.definition?.id === prop.id)) {
            if (sample === original) {
                sample = {...sample}
            }
            if (!sample.properties || sample.properties === original?.properties) {
                sample.properties = [...(sample.properties || [])]
            }
            const nodeId = nodes && Object.entries(nodes).find(([id, node]) => node.propertyDefs?.[prop.id || ""])?.[0]
            sample.properties.push(new PropertyInstance({definition: prop, nodeId}))
        }
    }
    return sample
}

/**
 * Returns true if a sample object has no significant user input data.
 * @param {*} sample
 */
 export function checkEmptySample(sample: Sample) {
    return !(
        sample?.qid ||
        sample?.title ||
        (sample?.components?.length &&
            sample.components.some(inst => !!Number(inst.amount))) ||
        (sample?.processSteps?.length &&
            sample.processSteps.some(
                inst => !!inst.data && Object.values(inst.data).some(v => !!v),
            )) ||
        (sample?.properties?.length &&
            sample.properties.some(inst => !!inst.data)) ||
        sample?.notes
    )
}