import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import { useAppStoreKey } from "../../../../AppStore"
import PreprocessFuncsBase from "./PreprocessFuncsBase";
import { makeStyles } from "@material-ui/core/styles";
import common_styles from "../../../../styles/common_styles";
import { SelectColumns } from "./utils";
const useStyles = makeStyles(common_styles);


export default function CategoricalEncoding({pfKey, handleRemovePlot}) {
    const funcName = 'Categorical Encoding'
    const description = ''
    const [workflow] = useAppStoreKey("Workflow");
    const [allCols, setAllCols] = useState([])
    const [selectedCols, setSelectedCols] = useState([])
    const classes = useStyles();

    const updateDict = () => {
        const selected_idx = []
        allCols.forEach((value, idx) => { if (selectedCols[idx]) selected_idx.push(idx) })
        return {
            "CategoricalEncoder": {
                "kwargs": {
                    "cols": selected_idx.map(idx => workflow.data.info[workflow.data.active_sheet].all_headers[idx]),
                }
            }
        }
    }
    const getCatCols = () => {
        return workflow.data.info[workflow.data.active_sheet].all_headers
    }

    return (
        <>
            <PreprocessFuncsBase
              funcName={funcName}
              description={description}
              pfKey={pfKey}
              handleRemovePlot={handleRemovePlot}
              updateDict={updateDict}
            >
                <div style={{display: 'flex', justifyContent: 'flex-start', marginLeft: 24}}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        {getCatCols().length > 0 ?<>
                                <SelectColumns
                                allCols={allCols}
                                setAllCols={setAllCols}
                                selectedCols={selectedCols}
                                setSelectedCols={setSelectedCols}
                                legendText={'Select input columns to be encoded. No need to encode output columns.'}
                                />
                            </> :
                            <FormLabel component="legend">All categorical columms are encoded. </FormLabel>}
                    </FormControl>
                </div>
            </PreprocessFuncsBase>
        </>
    );
}