import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import { shiftColumn } from './reactTableUtils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    draggableHeader: ({isOver, isDragging}) => ({
        width: "100%",
        padding: "6px 24px 6px 16px", 
        backgroundColor: isOver ? 'rgba(0,0,0,0.1)' : undefined, 
        opacity: isDragging ? 0.5 : 1,
        '&:hover':{
            cursor: "move",
            backgroundColor: (!isOver && !isDragging) ? theme.palette.primary.main + "20" : undefined,
        }
    })
}))

export function useDragColumn({columns}){
    columns.push(columns => columns.map((col, index) => ({
        ...col,
        headerPadding: 'none',
        Header: props => {
            const ref = useRef();
            const [{isOver}, drop] = useDrop({
                accept: 'column',
                drop(item){
                    if (!ref.current || item.id === col.id) return;
                    const itemIndex = columns.findIndex(col => col.id === item.id);
                    itemIndex >= 0 && props.onColumnReorder && props.onColumnReorder(shiftColumn(columns, itemIndex, index - itemIndex));
                },
                collect: monitor => ({
                    isOver: monitor.canDrop() && monitor.isOver(),
                })
            })
            const [{ isDragging }, drag, preview] = useDrag({
                item: { type: 'column', id: col.id },
                collect: monitor => ({
                    isDragging: monitor.isDragging(),
                })
            })
            preview(drag(drop(ref)));
            const classes = useStyles({isDragging, isOver});
            return (
            <div title="Drag to Move" ref={ref} className={classes.draggableHeader}>
                {typeof col.Header === "function" ? col.Header(props) : col.Header}
            </div>);
        } 
    })
    ))
  }