import React, {useCallback, useState} from 'react';
import clsx from 'clsx';
import { Typography, Card, Grid, makeStyles } from '@material-ui/core';

import common_styles from '../../styles/common_styles';
import UserSelector from '../General/UserSelector';
import { useUsers } from './SampleOverviewHooks';
import SampleSetTable from './SampleSetTable';
import { useLocalForageState } from '../../utils/useLocalforageState';
import TagSelector from '../General/TagSelector';
import { useAppStoreDispatchKey } from '../../AppStore';
import { CompileSet } from '../General/CompileSet';
import LearnMore, { LearnMoreEnum } from '../General/LearnMore';


const SAMPLE_SET_LIBRARY_TITLE = "Sample Set Library";
const SAMPLE_SET_LIBRARY_INFO = "A Sample Set is a grouping or collection of Samples or Materials " +
    "that often have associated Processes, Properties, and Tests as part of a study around a set " +
    "of formulations. Sample Sets can also be created and used for general performance and " +
    "property comparisons between user-selected Materials/Samples. "

const useStyles = makeStyles(theme => ({...common_styles(theme),
    switchText:{
        textAlign: 'left',
        marginBottom: 0,
        ...theme.typography.body2,
    },
    selectorContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    buttons: {
        whiteSpace: "nowrap",
    },
}));
const emptyArray = [];
function SampleSetLibrary({navigate}) {

    const classes = useStyles();
    const [users, setUsers, loadingUsers] = useUsers();
    const [tags, setTags, loadingTags] = useLocalForageState("SampleSetTags", emptyArray);
    // const [selectedOnly, setSelectedOnly, selectedLoading] = useLocalForageState("SampleSetLibrarySelected", false);
    const setSetStore = useAppStoreDispatchKey("EditSampleSet");
    const setInitialSet = useAppStoreDispatchKey("InitSampleSet");

    const handleNew = useCallback(() => {
        setSetStore(undefined); // reset store data on new
        setInitialSet(undefined);
        navigate('/SampleSetLibrary/new');
    }, [setSetStore, setInitialSet, navigate]);

    const handleUserChange = useCallback((ev, values) => {
        setUsers(values);
    }, [setUsers]);

    const handleTagsChange = useCallback((ev, values) => {
        setTags(values);
    }, [setTags]);

    const [openIds, setOpenIds] = useState();

    const handleEdit = useCallback(selected => { 
        if (selected.length === 1) {
            setSetStore(undefined); // reset store data on edit
            navigate(selected[0].id);
        }
        else if (selected.length){
            setOpenIds(selected.map(sample => sample.id));
        }
    }, [navigate, setSetStore]);

    return (
        <div className={clsx(classes.layout, '.mui-fixed')}>
            <CompileSet setIds={openIds} onFail={() => setOpenIds(undefined)}/>
            <Card className={classes.paperBody} elevation={3}>
                <Typography className={classes.cardTitle} gutterBottom>
                    {SAMPLE_SET_LIBRARY_TITLE}
                </Typography>
                <Typography className={classes.cardBodyTextEnhanced}>
                    {SAMPLE_SET_LIBRARY_INFO}
                    <LearnMore model={LearnMoreEnum.sampleSet} />
                </Typography>
                {/* <FormGroup row>
                    <FormControlLabel label="Only show selected sample sets" control={
                        <Switch checked={selectedOnly} onChange={(ev)=>setSelectedOnly(ev.target.checked)} color="primary"/>
                    }/>
                </FormGroup> */}
                <Grid container justifyContent="flex-start" spacing={2} className={classes.selectorContainer}>
                    <Grid item xs={12} md={6}>
                        <UserSelector 
                        label={"Show sample sets belonging to"} 
                        placeholder={users?.length ? "" : "User name(s). Start typing to extend list..."}
                        fullWidth 
                        value={users || []}
                        onChange={handleUserChange}
                        variant="outlined"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TagSelector 
                            label="Show sample sets with these Tags" 
                            placeholder="Tags (optional)"
                            value={tags} 
                            helperText={undefined}
                            onChange={handleTagsChange}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>
                        <SampleSetTable 
                            // selectedOnly={selectedOnly}
                            loading={(loadingUsers || loadingTags)/* && selectedLoading*/} 
                            users={users} 
                            tags={tags} 
                            onEdit={handleEdit}
                            onNew={handleNew}
                        />
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}
export default React.memo(SampleSetLibrary);