import React, { useCallback } from 'react'
import { Chip, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  container: {
    background: theme.palette.action.hover,
    border: "2px dashed",
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.text.disabled,
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    cursor: prop => !prop ? "pointer" : "default",
    padding: theme.spacing(0.5, 1),
    "&:hover": {
      borderColor: prop => prop ? theme.palette.text.disabled : theme.palette.action.active,
    }
  },
  dragging: {
    borderColor: theme.palette.primary.main,
  },
  item: {
    margin: theme.spacing(0.5, 0.5),
  },
  message: {
    margin: "auto",
    padding: theme.spacing(0.5)
  },
  chipList: {
    display: "flex",
    flexFlow: "row wrap"
  },
  existingText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.info.main,
    }
  },
  pendingDelete: {
    extend: "existingText",
    textDecoration: "line-through",
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      textDecoration: "none",
      color: theme.palette.secondary.contrastText
    }
  },
}));
/**
 * Component for displaying a file drop zone to handle selecting files to upload, showing already attached files, and queuing files for deletion
 * @param {*} props
 * @param {File[]} props.existingFiles files currently uploaded
 * @param {string[]} props.pendingDelete array of file ids queued for deletion
 * @param {(pending: string[] => void)} props.setPendingDelete callback to set pendingDelete
 * @param {File[]} props.newFiles array of new files to upload
 * @param {(newFiles: File[]) => void} props.setNewFiles callback to set new files
 * @param {boolean} props.disabled if true component is disabled
 * @param {string} props.message optional message override to display in the drop zone (could be used for error reporting)
 * @param {boolean} props.messageError if true error styling is used
 */
export default function FileDropzone({ existingFiles, pendingDelete, setPendingDelete, setNewFiles, newFiles, disabled, message, messageError }) {
  const classes = useStyles(disabled);

  const handleDelete = useCallback(file =>
    setNewFiles(newFiles.filter(f => f !== file))
    , [setNewFiles, newFiles]);

  const handleDrop = useCallback(files => {
    setNewFiles(newFiles.concat(files))
  }, [newFiles, setNewFiles])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDrop, disabled })
  const DROP_ZONE_OK_MESSAGE = "Drag file(s) or click here to attach new files. Existing file(s) may need a PAGE REFRESH to appear."

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, isDragActive && classes.dragging)} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classes.chipList}>
          {existingFiles?.length > 0 && existingFiles.map((file, i) => {
            const pending = pendingDelete?.indexOf(file.id) >= 0;
            const label =
              <Tooltip title={pending ? "File marked for deleting. Click to keep it instead." : "Download File"} placement="top">
                <Typography
                  className={clsx(classes.existingText, pending && classes.pendingDelete)}
                  variant="caption"
                  component={pending ? undefined : "a"}
                  href={pending ? undefined : file.url}
                >
                  {file.file_name || file.name}
                </Typography>
              </Tooltip>
            return (
              <Chip
                key={i}
                className={classes.item}
                size="small"
                onClick={e => { e.stopPropagation(); if (pending) setPendingDelete(pendingDelete.filter(id => id !== file.id)) }}
                onDelete={pending ? undefined : e => { e.stopPropagation(); setPendingDelete(pendingDelete.concat(file.id)) }}
                label={label}
                color={pending ? "secondary" : "default"}
              />
            )
          }
          )}
          {newFiles?.length > 0 ?
            newFiles.map((file, i) => {
              const label =
                <Tooltip title="File upload pending save" placement="top">
                  <Typography variant="caption" >
                    {file.name}
                  </Typography>
                </Tooltip>
              return <Chip
                key={i}
                className={classes.item}
                size="small"
                clickable={false}
                onClick={e => e.stopPropagation()}
                onDelete={e => { e.stopPropagation(); handleDelete(file) }}
                label={label}
                color={"primary"}
              />
            })
            : undefined}
        </div>
        <Typography
          className={clsx(classes.item, classes.message)}
          color={messageError ? "error" : undefined}
          variant={"caption"}>
            {message || DROP_ZONE_OK_MESSAGE}
        </Typography>
      </div>
    </div>
  )
}