import React, { useState, useCallback, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { DialogProps, FormControlLabel, Checkbox } from '@material-ui/core';

export type ConfirmationDialogProps = DialogProps & {
  onClose?: (val: any) => void,
  title?: ReactNode,
  message?: ReactNode,
  checkBoxMessage?: string,
  onChecked?: (checked?: boolean) => void,
  confirmValue?: undefined,
  yes?: ReactNode,
  cancel?: ReactNode,
}
export default function ConfirmationDialog({
  onClose = undefined,
  open = false,
  title = undefined,
  message = undefined,
  confirmValue = undefined,
  yes = undefined,
  cancel = undefined,
  checkBoxMessage = undefined,
  onChecked = undefined,
  ...other 
}: ConfirmationDialogProps) {
  const [checked, setChecked] = useState(false)
  return (
    <Dialog
      disableBackdropClick={yes !== null && cancel !== null}
      disableEscapeKeyDown={yes !== null && cancel !== null}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={!!open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {message}
        {checkBoxMessage &&
        <>
          <div style={{width: "100%"}}/>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={ev => {
                  setChecked(ev.target.checked)
                  onChecked?.(ev.target.checked as boolean)
                }}
                color="primary"
              />
            }
            label={checkBoxMessage || ""}
          />
          </>
        }
      </DialogContent>
      <DialogActions>
        {yes !== null && 
        <Button onClick={() =>{ onClose?.(confirmValue || true); if(checked) setChecked(false)}} color="primary">
          {yes || "Yes"}
        </Button>}
        {cancel !== null && 
        <Button onClick={() => {onClose?.(false); if(checked) setChecked(false)}} color="primary">
          {cancel || "Cancel"}
        </Button>}
      </DialogActions>
    </Dialog>
  );
}

export function useConfirmDialog({callback, title, message, confirmValue, callOnCancel, yes, cancel, autoConfirm, ...rest}: any){
  const [state, setState] = useState<any>({open: false});
  const getConfirmation = useCallback(async () => {
    if (autoConfirm) return callback && callback(confirmValue || true)
    return new Promise((resolve) => {   
      setState({
        open: true,
        onClose: (confirm: any) => {
          setState({ open: false });
          resolve(callback && (confirm ? callback(confirm) : (callOnCancel ? callback(false) : false)));
        },
      });
    });
  }, [autoConfirm, callOnCancel, callback, confirmValue]);
  return {getConfirmation, props: {open: state.open, onClose: state.onClose, title, message, confirmValue, yes, cancel, ...rest}};
}