import React, { useCallback } from 'react';
import { useParams } from '@reach/router';

import { Grid, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import common_styles from '../../styles/common_styles';
import PropertyTable from './PropertyTable';
import EditProperty from './EditProperty';
import { useQueryFCSingle } from '../../API/queryHooks';
import DialogWithLoading from '../General/DialogWithLoading';
import LearnMore, { LearnMoreEnum} from "../General/LearnMore";


const PROPERTY_LIBRARY_TITLE = "Property Library";
const PROPERTY_LIBRARY_INFO = "A Property is a user-defined field that is associated with a Sample. " +
    "These fields can include a test measurement/result (e.g., “Density”), " + 
    "calculation (e.g., “Acrylic Acid/Acrylamide” ratio), classifier (e.g., “Manufacturer”), " +
    "observation (e.g., “Solution Color”), or imported value (e.g., “PubChem ID”) " +
    "of a Sample that describe various attributes. If storage of additional metadata associated " +
    "with a field is required, users may want to store this data as Tests instead of Properties. ";

const useStyles = makeStyles(common_styles);

function PropertyLibrary({navigate}) {
    const classes = useStyles();
    const {propertyId} = useParams();
    const {data: propertyDefinition, isFetching} = useQueryFCSingle((propertyId !== "" && propertyId !== "new") ? {
        Model: "PropertyDefinition",
        filter: propertyId,
        fields: ["labs.lab"]
    } : undefined, { logName: "Full Property Definition"});
    const handleNew = useCallback(() => navigate("new"), [navigate]);
    const handleEdit = useCallback(row => navigate(row.original.id), [navigate]);
    const handleCloseEdit = useCallback(() => navigate(""), [navigate]);
    const openEdit = propertyId !== "" && (propertyId === "new" || propertyDefinition || isFetching);
    const idMatch = propertyId === propertyDefinition?.id || (propertyId === "new" && !propertyDefinition?.id)

    return (
        <div className={classes.layout}>
            <DialogWithLoading onClose={handleCloseEdit} open={!!openEdit} loading={isFetching && !propertyDefinition}>
                <EditProperty initialPropertyDefinition={idMatch ? propertyDefinition : undefined} onClose={handleCloseEdit} />
            </DialogWithLoading>
            <Card className={classes.paperBody} elevation={3}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <div>
                        <Typography className={classes.cardTitle} gutterBottom>
                            {PROPERTY_LIBRARY_TITLE}
                        </Typography>
                        <Typography className={classes.cardBodyTextEnhanced}>
                            {PROPERTY_LIBRARY_INFO}
                            <LearnMore model={LearnMoreEnum.property} />
                        </Typography>
                    </div>
                </div>
                <Grid container justify="flex-start" alignItems="flex-end" spacing={3}>
                    <Grid item xs={12}>
                        <PropertyTable onEdit={handleEdit} onNew={handleNew}/>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
}

export default React.memo(PropertyLibrary);