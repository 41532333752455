import React, { useEffect, useRef, useState } from "react"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useSnackbar } from "notistack"
import common_styles from "../../../../styles/common_styles"
import MLModelBase from "./MLModelBase";
import useTrainModel from "../useTrainModel";

const useStyles = makeStyles(common_styles);


function NN({handleRemovePlot, modelKey}) {

  const classes = useStyles();
  const modelName = "Neural Network Regression";
  const modelDescription = "Neural Network (NN) is a non-linear function approximation technique\n" +
    "that works well modeling complex variable relationships, but is prone to overfitting when\n" +
    "used on small datasets."
  const [params, setParams] = useState({'hidden_layer_sizes': 'auto', 'activation':'auto', 'solver': 'auto'})  // default values
  const [isLoading, ] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const { TrainModel } = useTrainModel({ enqueueSnackbar })
  const currentComponent = useRef(true)
  useEffect(() => () => currentComponent.current = false, [])
  const parameter_space = {
    "hidden_layer_sizes": ['auto', 2, 10, 20],
    "activation": ['auto', "logistic", "tanh", "relu"],
    "solver": ['auto', "lbfgs", "sgd", "adam"],
  }

  const handleParamChange = (paramName) => (event) => {
    let newParam = {}
    newParam[paramName] = event.target.value
    setParams({...params, ...newParam})
  }

  let modelParams = {
    NN_model: {
      kwargs: { 'params': params }
    }
  }

  return (
    <>
      <MLModelBase
        modelName={modelName}
        modelDescription={modelDescription}
        handleTrainModel={TrainModel(modelParams, modelName)}
        handleRemovePlot={handleRemovePlot}
        isLoading={isLoading}
        modelKey={modelKey}
      >
        {Object.keys(parameter_space).map((paramName, idx) => {
          return (
            <div key={idx} style={{ marginLeft: 36}}>
              <FormControl component="fieldset" className={classes.formControl}>
                <InputLabel id={paramName + "select-label"}>{paramName}</InputLabel>
                <Select
                  labelId={paramName + "select-label"}
                  id={paramName + "select-id"}
                  value={params[paramName]}
                  onChange={handleParamChange(paramName)}
                >
                  {parameter_space[paramName].map((paramOption, idx) => {
                    return (<MenuItem key={'param_' + idx} value={paramOption}>{paramOption}</MenuItem>);
                  })}
                </Select>
              </FormControl>
            </ div>
          );
        })}
      </MLModelBase>
    </>
  );
}

export default React.memo(NN);