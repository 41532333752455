import { sanitizeFlowChartProperties } from 'components/FlowChart/flowChartUtils'
import { Base } from '../Base'
import { FlowChart } from '../FlowChart'
import { LabToSampleSet, Sample, ProcessChart } from '../models'
import { Model, Schema, schema } from '../schemaUtils'

export class SampleSet extends Base {
    contributors?: string[]
    title?: string
    description?: string
    intent?: string
    resultSummary?: string
    flowChart?: FlowChart
    samples?: Sample[]
    labs?: LabToSampleSet[]
    
    processChart?: ProcessChart
    nodeAliases?: NodeAliases

    static readonly [schema]: Schema<SampleSet> = {
        endPoint: "sample_sets",
        type: "sample_set",
        relationships: {
            ...Base[schema].relationships,
            samples: { map: "samples", model: "Sample", toMany: true, reverse: "sets" },
            processChart: { map: "process_chart", model: "ProcessChart", toMany: false, reverse: "sets" },
            labs: { map: "labs", model: "LabToSampleSet", toMany: true, reverse: "sampleSet", meta: { of: "labs", through: "lab" } },
        },
        map: {
            ...Base[schema].map,
            "contributors": "contributors",
            "title": "title",
            "description": "description",
            "intent": "meta.intent", //FIRECRACKER ATTRIBUTE NAME is meta.intent; in the FE we call it intent
            "resultSummary": "meta.results_summary",
            "flowChart": "meta.process_flow_chart",
            "nodeAliases": "meta.nodeAliases",
            "processChart.id": "process_chart_id",
        },
        responsePostConvertion(model: Model) {
            const sampleSet = model as SampleSet
            // Define regex for base part and last number in QID e.g., QID = <base><last num>
            const reQid = /(.+-)(\d+)$/

            // sort samples by qid
            if (sampleSet.samples) {
                // First sort by full QID name
                sampleSet.samples.sort((a, b) => a.qid === b.qid ? 0 : (a.qid || "") > (b.qid || "") ? 1 : -1)
                // Second sort on last number if QIDs have same base part
                sampleSet.samples.sort((a, b) => {
                    const am = a.qid?.match(reQid) || []
                    const bm = b.qid?.match(reQid) || []
                    if (am[1] === bm[1]) {
                        if (parseInt(am[2]) === parseInt(bm[2])) return 0;
                        else if (parseInt(am[2]) > parseInt(bm[2])) return 1;
                        else return -1;
                    } else {
                        return 0
                    }
                })
            }

            // convert old FC to new ProcessFlow
            return sampleSet
        },
        mutatePreConversion(model) {
            const sampleSet = model as SampleSet
            if (!sampleSet.flowChart) return sampleSet
            const newFlowChart = sanitizeFlowChartProperties(sampleSet.flowChart)
            return newFlowChart === sampleSet.flowChart ? sampleSet : new SampleSet({ ...sampleSet, flowChart: newFlowChart })
        }
    }

    constructor(toCopy?: Partial<SampleSet>) {
        super()
        Object.assign(this, toCopy)
        if (toCopy?.flowChart)
            this.flowChart = new FlowChart(toCopy.flowChart) // because react-flow-chart mutates the chart, always deep copy
    }
}

export type NodeAliases = {
    [sampleId: string]: {
        [sampleNodeId: string]: string
    }
}