/**
 * @format
 */

import { AxiosResponse } from "axios"
import { get, set } from "lodash"
import * as Models from "schema/models"
import {
    ModelKey,
    ModelWithSchema,
    OmitSchema,
    Schema,
    schema,
    ModelOfInstance,
    assumeType,
} from "schema/schemaUtils"
import { remapKeys } from "utils/utils"
import {
    ArrayResponse,
    Filter,
    firecrackerData,
    firecrackerFilter,
    firecrackerPayload,
    Sort,
} from "./firecrackerTypes"

export function convertMutateRequest<T extends InstanceType<ModelWithSchema>>(
    model: T | T[],
    Model: ModelOfInstance<T>,
    rels?: (keyof T)[],
): firecrackerPayload {
    const modelSchema = Model[schema] as Schema<T>
    const handleConvert = (mod: T) => {
        const data: firecrackerData = {}
        data.type = modelSchema.type
        // copy id
        if (mod.id) {
            data.id = mod.id
        }
        // get attributes
        if (modelSchema.map) {
            const remap = remapKeys(
                modelSchema.map,
                sanitizeModel(mod),
                true,
            ) as any
            if (Object.keys(remap).length > (remap.id ? 1 : 0)) {
                data.attributes = remap
            }
        }
        // get relationships
        if (modelSchema.relationships) {
            const relationships: any = {}
            for (const [relKey, rel] of Object.entries(
                modelSchema.relationships,
            )) {
                assumeType<keyof T>(relKey)
                if (
                    (!rels || rels.includes(relKey)) &&
                    rel?.map &&
                    mod[relKey]
                ) {
                    if (rel.meta) {
                        const relSchema = Models[rel.model][schema]
                        const throughSchema = (Models as any)[
                            (relSchema.relationships as any)?.[rel.meta.through]
                                ?.model
                        ]?.[schema]
                        relationships[rel.meta.of] = Array.isArray(mod[relKey])
                            ? {
                                  data: (mod[relKey] as any).map((r: any) => ({
                                      type: throughSchema?.type,
                                      id: r[(rel.meta as any).through].id,
                                      meta: remapKeys(
                                          relSchema.map || {},
                                          sanitizeModel(r),
                                          true,
                                      ),
                                  })),
                              }
                            : {
                                  data: {
                                      type: throughSchema?.type,
                                      id: (mod[relKey] as any)[
                                          (rel.meta as any).through
                                      ].id,
                                      meta: remapKeys(
                                          relSchema.map || {},
                                          sanitizeModel(mod[relKey] as any),
                                          true,
                                      ),
                                  },
                              }
                    } else {
                        const relPayload = rel.includeFull
                            ? mod[relKey]
                            : Array.isArray(mod[relKey])
                            ? (mod[relKey] as unknown as any[])
                                  .filter(r => r.id)
                                  .map(r => ({ id: r.id }))
                            : (mod[relKey] as any).id && {
                                  id: (mod[relKey] as any).id,
                              }
                        relationships[rel.map] = convertMutateRequest(
                            relPayload,
                            Models[rel.model],
                        )
                    }
                }
            }
            if (Object.keys(relationships).length) {
                data.relationships = relationships
            }
        }
        return data
    }
    return Array.isArray(model)
        ? { data: model.map(mod => mod && handleConvert(mod)) }
        : { data: model && handleConvert(model) }
}

/**
 * Prep standard attributes for mutating, clearing user info handled by the backend
 */
export function sanitizeModel<T>(model: T): T {
    return {
        ...model,
        creatorId: undefined,
        creatorName: undefined,
        timeCreated: undefined,
        modifierId: undefined,
        modifierName: undefined,
        timeModified: undefined,
    }
}

/**
 * Converts return payload to Model object
 */
export function convertQueryResponse<T extends InstanceType<ModelWithSchema>>(
    res: Partial<AxiosResponse<firecrackerPayload>>,
    Model: ModelOfInstance<T>,
    meta?: { of: string; through: string },
): T | ArrayResponse<T> {
    const querySchema = Model[schema] as Schema<T>
    const count = res.data?.meta?.["total-count"]
    const isArray = Array.isArray(res.data?.data)
    const data = ((isArray ? res.data?.data : [res.data?.data]) as any[]).map(
        object => {
            if (!object) return new Model({})
            let direct: Partial<T>
            if (meta) {
                direct = remapKeys(querySchema?.map || {}, object.meta)
                direct.id = undefined
                if (direct) {
                    ;(direct as any)[meta.through] = { id: object.id }
                }
            } else {
                direct = object.id
                    ? remapKeys(querySchema?.map || {}, { id: object.id })
                    : {} // id is not always in attributes for relationships
            }
            const attributes: Partial<T> = remapKeys(
                querySchema?.map || {},
                object.attributes,
            )
            const relationships: Partial<T> = {}
            if (object.relationships) {
                for (let [relKey, queryRel] of Object.entries(
                    querySchema.relationships || {},
                ) as [keyof T, any][]) {
                    const rel = object.relationships[queryRel.map]
                    if (rel) {
                        if (queryRel.toMany) {
                            relationships[relKey] = (
                                convertQueryResponse(
                                    { data: rel } as any,
                                    (Models as any)[queryRel.model],
                                    queryRel.meta,
                                ) as any
                            )?.data
                        } else {
                            relationships[relKey] = convertQueryResponse(
                                { data: rel } as any,
                                (Models as any)[queryRel.model],
                                queryRel.meta,
                            ) as any
                        }
                    }
                }
            }
            return new Model({ ...direct, ...attributes, ...relationships })
        },
    ) as T[]
    return isArray
        ? {
              count,
              data,
          }
        : data[0]
}

/**
 * Convert QueryNode fields to firecracker API fields
 */
export function convertFields<T>(
    fields: (keyof OmitSchema<T>)[],
    // @ts-ignore
    querySchema: Schema<T>,
) {
    const f = fields?.map(field => {
        if (typeof field !== "string") return ""
        const { name } = matchMapName(field, querySchema)
        return name?.match(/^[^.]+/)?.[0] || ""
    })
    if (f?.length && !f.includes("id")) f.push("id")
    return f?.length ? f.join(",") : undefined
}

/**
 * Convert QueryNode sort to firecracker API sort
 */
// @ts-ignore
export function convertSort<T>(sort: Sort<T>[], querySchema: Schema<T>) {
    return sort
        .map(s => {
            const match = s.match(/^-?([a-z0-9_]+)/i)
            if (!match || !match[1]) return ""
            const key = querySchema?.map?.[match[1]]
            if (!key)
                throw new Error(
                    `Name (${match[1]}) not found in querySchema (${querySchema?.endPoint})`,
                )
            return key ? s.replace(/[^-]+/, key) : ""
        })
        .join(",")
}

/**
 * Convert QueryNode filter to firecracker API filter
 */
export function convertFilter<T>(
    rawFilter: Filter<T>,
    // @ts-ignore
    querySchema: Schema<T>,
): firecrackerFilter {
    if (!rawFilter) return undefined
    if (typeof rawFilter === "string") {
        return {
            name: "id",
            op: "eq",
            val: rawFilter,
        } as firecrackerFilter
    }

    type BaseFilter = {
        and?: BaseFilter[]
        or?: BaseFilter[]
        not?: BaseFilter
        name?: string
        op?: string
        val?: any
    }
    const filter = rawFilter as BaseFilter

    // temporary error for filters using a deprecated structure. Once all code as been tested adn updated, this can be removed.
    if (!filter.name && "id" in filter)
        throw new Error(`Old style filter encountered. (${filter})`)

    //handle boolean operators
    if (filter.and) {
        return {
            and: filter.and
                ?.map(f => convertFilter<T>(f as Filter<T>, querySchema))
                .filter(f => !!f),
        } as firecrackerFilter
    } else if (filter.or) {
        return {
            or: filter.or
                ?.map(f => convertFilter<T>(f as Filter<T>, querySchema))
                .filter(f => !!f),
        } as firecrackerFilter
    } else if (filter.not) {
        return {
            not: convertFilter<T>(filter.not as Filter<T>, querySchema),
        } as firecrackerFilter
    }

    // Group one matches the form <group1>.*
    // Group two matches the form ["<group2>"].*
    // Group two matches the form ['<group3>'].*
    const match = filter.name?.match(
        /^(?:([a-z_][a-z0-9_]*)|(?:\["([^"]+)"])|(?:\['([^']+)']))(?:\.?)/i,
    )
    if (!match) {
        throw new Error(`Invalid filter key: ${filter.name}`)
    }
    const root = match[1] || match[2] || match[3]
    const remaining =
        filter.name?.substring(
            root.length + (filter.name[root.length] === "." ? 1 : 0),
        ) || ""

    // is name a relationship?
    if ((querySchema?.relationships as any)?.[root]) {
        const rel = (querySchema.relationships as any)[root] as unknown as {
            model: ModelKey
        }
        let val
        if (remaining) {
            val = convertFilter<
                InstanceType<(typeof Models)[typeof rel.model]>
            >({ ...filter, name: remaining } as any, Models[rel.model][schema])
        } else {
            val =
                typeof filter.val !== "object" || filter.val instanceof Date
                    ? filter.val
                    : convertFilter<
                          InstanceType<(typeof Models)[typeof rel.model]>
                      >(filter.val as any, Models[rel.model][schema])
        }
        // special catch for labs (labs.lab.xyz is broken, needs to be labs.xyz for filters)
        if ((rel as any).meta) {
            val = val.val // get labs.lab[val]
        }
        return {
            name: (rel as any).map,
            op: (rel as any).toMany ? "any" : "has",
            val,
        } as firecrackerFilter
    }
    // name is not a relationship

    // find name match
    let { name, rest } = matchMapName(filter?.name || "", querySchema)
    name += rest // append un-transformed remaining

    // handle custom types
    switch (filter.op) {
        case "list":
            return arrayToOr({
                name,
                op: "any",
                val: filter.val,
            })
        case "equal":
            return { name, op: "eq", val: filter.val }
        case "date":
            return {
                name,
                op: "between",
                val: [filter.val.startDate, filter.val.endDate] as any,
            } as firecrackerFilter
        case "text":
        case undefined:
            return Array.isArray((filter as BaseFilter).val)
                ? {
                      or: (filter as BaseFilter).val.map((v: any) =>
                          parseTextFilterExpression({ name, val: v }),
                      ),
                  }
                : (parseTextFilterExpression({
                      name,
                      val: (filter as BaseFilter).val,
                  }) as firecrackerFilter)
        case "between":
            const match = filter.val.match(
                /(^[-+]?(?:(?:\d*\.\d+)|(?:\d+\.?))(?:[Ee][+-]?\d+)?)(?:\s*(?:to|-)\s*)([-+]?(?:(?:\d*\.\d+)|(?:\d+\.?))(?:[Ee][+-]?\d+)?$)/,
            )
            return {
                and: [
                    {
                        name,
                        op: "ge",
                        val: Number(match[1]),
                    },
                    {
                        name,
                        op: "le",
                        val: Number(match[2]),
                    },
                ],
            }
        default:
            if (
                ["in_", "notin_", "contains", "is_", "isnot"].includes(
                    filter.op as string,
                )
            )
                return {
                    ...filter,
                    name,
                } as firecrackerFilter
            return arrayToOr({
                ...filter,
                name,
            })
    }
}

function parseTextFilterExpression({
    name,
    val,
}: {
    name: string
    val: string
}) {
    // escape postgres charactors
    const escaped = val.replace(/([_%\\])/g, "\\$1")
    // Match any character that's not a space, single-quote or double-quote
    // Match any character that's not a double-quote within double-quotes: "<anystring>"
    // Match any character that's not a single-quote within single-quotes: '<anystring>'
    const matches = escaped.match(/[^\s"']+|"([^"]*)"|'([^']*)'/g) || []
    const vals = []
    for (const match of matches) {
        const exact = match.match(/"([^"]*)"|'([^']*)'/)
        vals.push(exact ? `%${exact[1] || exact[2]}%` : `%${match}%`)
    }
    return vals.length <= 1
        ? {
              name,
              op: "ilike",
              val: vals[0],
          }
        : {
              and: vals.map(v => ({
                  name,
                  op: "ilike",
                  val: v,
              })),
          }
}

/**
 * Find the map key with the longest partial match
 */
// @ts-ignore
function matchMapName<T>(field: string, querySchema: Schema<T>) {
    let name = querySchema?.map?.[field]
    let rest = ""
    const fieldObj = set({}, field, true)
    if (!name) {
        let longest = 0
        let remaining = undefined
        for (const [key, val] of Object.entries(querySchema?.map || {})) {
            const test = get(fieldObj, key)
            if (key.length > longest && !!test) {
                longest = key.length
                name = val
                remaining = test
            }
        }
        if (remaining) {
            rest = `.${getPath(remaining)}`
        }
    }

    // peal off end of filter name until first overlap match with querySchema map found
    if (!name) {
        const vals = Object.values(querySchema?.map || {}) as string[]
        if (
            vals.includes(field) ||
            vals.some(v => !!get(fieldObj, v)) ||
            field === "meta"
        ) {
            return { name: field, trunk: field }
        }
        throw new Error(
            `Name (${field}) not found in querySchema (${querySchema?.endPoint})`,
        )
    }
    return { name, rest }
}
function getPath(obj: any): string {
    const k = Object.keys(obj)[0]
    return obj[k] === true ? k : `${k}.${getPath(obj[k])}`
}

function arrayToOr({ name, op, val }: any) {
    if (Array.isArray(val)) {
        return {
            or: val.map(v => ({
                name,
                op,
                val: v,
            })),
        }
    }
    return {
        name,
        op,
        val,
    }
}
