import React from "react"
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ListItemText from "@material-ui/core/ListItemText"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function OptimizationConstraint(props) {
    const { classes, cols, optConstrain, allConstraints, setOptConstraints, constrainIdx, removeConstraint } = props;

    const handleSetConstrainValue = (key) => (event) => {
        let newConstraints = [...allConstraints]
        newConstraints[constrainIdx][key] = event.target.value
        setOptConstraints(newConstraints)
    }

    const isEqualityConstraint = (idx) =>
        (allConstraints[idx]['operation'] === '=') |
        (allConstraints[idx]['operation'] === '<=') |
        (allConstraints[idx]['operation'] === '>=');

    const handleSetConstrainCols = (event) => {
        let newConstraints = [...allConstraints]
        newConstraints[constrainIdx]["col_idx"] = event.target.value.map(value => {
            return cols.indexOf(value)
        })
        newConstraints[constrainIdx]["col_names"] = event.target.value
        setOptConstraints(newConstraints)
    }


    const constrainTypes = ['sum', 'ratio', '>=', '<=', '=']

    const getSelectedColNames = () => {
        return optConstrain.col_idx.map(idx => cols[idx])
    }
    const disableCheckbox = () => {
        return optConstrain.operation === 'ratio' && optConstrain.col_idx.length >= 2
    }

    const getAvailableCols = () => {
        if (disableCheckbox())
            return optConstrain.col_idx.map(idx => cols[idx])
        else
            return cols
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", width: "90%" }}>
            {/*select columns*/}
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="opt_select_cols">Columns</InputLabel>
                <Select
                    labelId="opt_select_cols_label"
                    id="opt_select_cols_id"
                    multiple
                    value={getSelectedColNames()}
                    onChange={handleSetConstrainCols}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {getAvailableCols().map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={getSelectedColNames().indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/*dropdown menu for operation*/}
            <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor={"model_opt_type_select_" + constrainIdx}>
                    Operation type
                </InputLabel>
                <Select
                    style={{ textAlign: "left" }}
                    value={optConstrain.operation}
                    onChange={handleSetConstrainValue('operation')}
                    input={
                        <Input
                            name={"opt_constrain_operation_select_" + constrainIdx}
                            id={"opt_constrain_operation_select_" + constrainIdx}
                        />
                    }
                >
                    {constrainTypes.map(value => {
                        return <MenuItem key={value} value={value}>
                            {value}
                        </MenuItem>;
                    })}
                </Select>
            </FormControl>
            {/*Target*/}
            <FormControl fullWidth className={classes.formControl}>
                <TextField
                    id={"opt_constrain_value_" + constrainIdx}
                    label={"Value"}
                    type="number"
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin="normal"
                    onChange={handleSetConstrainValue('target')}
                    value={optConstrain.target}
                    style={{ marginTop: 0, width: "100%" }}
                    data-cy={"opt_target_" + constrainIdx}
                />
            </FormControl>
            {/*Tolerance*/}
            {isEqualityConstraint(constrainIdx) ? null : (
                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        id={"opt_constrain_tol_" + constrainIdx}
                        label={"Tolerance"}
                        type="number"
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        onChange={handleSetConstrainValue('tolerance')}
                        value={optConstrain.tolerance}
                        style={{ marginTop: 0, width: "100%" }}
                        data-cy={"opt_tolerance_" + constrainIdx}
                    />
                </FormControl>
            )}
            <IconButton
                color="secondary"
                onClick={removeConstraint}
            >
                <DeleteIcon />
            </IconButton>
        </div>
    );
}

export default React.memo(OptimizationConstraint)
