/**
 * @format
 */
import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    itemLabel: {
        display: "flex",
        whiteSpace: "nowrap",
        justifyContent: "space-between",
    },
    labelKey: {
        color: theme.palette.text.disabled,
        whiteSpace: "nowrap",
        maxWidth: "20ch",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: theme.spacing(1),
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
}))

export default function TreeItemLabel({
    label,
    labelRight = "Insert",
    rightProps,
}) {
    const classes = useStyles()

    return (
        <div className={classes.itemLabel}>
            {label}
            <div className={classes.labelKey} {...rightProps}>
                {labelRight}
            </div>
        </div>
    )
}
