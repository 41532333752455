import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';
import common_styles from '../../styles/common_styles';
import LoadingOverlay from './LoadingOverlay';

const useStyles = makeStyles(theme => ({
    ...common_styles(theme),
    loadingPaper: {
        background: "transparent",
    },
}));

/**
 * Material-ui dialog with a toggleable loading overlay and close button in the corner
 * @param props props are forwarded to the material-ui dialog component
 * @param {boolean} props.loading if true the loading overlay will be displayed
 * @param {string} props.loadingText text to display with the loading overlay. default = "Opening"
 */
export default function DialogWithLoading({onClose, loading, loadingText = "Opening", children, ...rest}) {
    const classes = useStyles();

    return (
        (loading) ?
        <LoadingOverlay filled/>
        :
        <Dialog disableEscapeKeyDown disableBackdropClick onClose={onClose} fullWidth maxWidth="lg" scroll={"body"} PaperProps={loading ? {elevation: 0, className: classes.loadingPaper} : undefined} {...rest}>
            <div className={classes.dialogPaperBody}>
                {children}
                <IconButton onClick={onClose} className={classes.dialogCloseButton}>
                    <CloseIcon />
                </IconButton>
            </div>
        </Dialog>
    );
}