import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, ButtonGroup, Tooltip } from '@material-ui/core'
import ButtonWithSpinner from './ButtonWithSpinner'
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import UndoIcon from "@material-ui/icons/Undo"
import RedoIcon from "@material-ui/icons/Redo"

/**
 * The styled button group used for most of the Edit views. Includes default buttons for Copy, Save, Save and Close, Close, Delete, undo, and redo. Additional buttons can be added through the props
 * @param props 
 * @param {boolean} props.editing if true "editing" mode will be on, meaning the save and delete buttons will be visible
 * @param {() => void} props.onClose callback when the close button is clicked
 * @param {string} props.closeButtonTitle label text for the close button, default "Close"
 * @param {string} props.objectName of object passed to snackbase on success (just Delete now)
 * @param {() => Promise<any>} props.onDelete callback for delete
 * @param {() => Promise<any>} props.onSave callback for save
 * @param {(res: any) => void | Promise<any>} props.onSaved callback called after successful save. Passed the result from onSave
 * @param {undefined | {name: string, callback: () => void}} props.undo object with name and callback for undo operations. undefined to disable
 * @param {undefined | {name: string, callback: () => void}} props.redo object with name and callback for redo operations. undefined to disable
 * @param props.ActionButtons material-ui compatible Button components to be added to the ButtonGroup
 * @param {boolean} props.readOnly if true Save, Delete, Undo, and edo buttons will be disabled
 * @param {number} props.progress number to show in save, load, etc. progress spinners. If left undefined during an operation, an indeterminate progress spinner will be dislayed
 * @param {boolean} props.hasTests.  If true, disable the delete button as user cannot delete sample with tests.
*/
export default function EditButton({
  editing = undefined,
  onClose = undefined,
  closeButtonTitle = "Close",
  objectName = undefined,
  onDelete = undefined,
  onSave = undefined,
  onSaved = undefined, // called after successfull save that does not close
  onCopy = undefined,
  undo = undefined,
  redo = undefined,
  ActionButtons = undefined,
  readOnly = undefined,
  progress = undefined,
  hasTests = false
}) {
  const [saving, setSaving] = useState(false)
  const [savingAndClose, setSavingAndClose] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [copying, setCopying] = useState(false)

  const busy = copying || saving || savingAndClose || deleting

  const mounted = useRef(true)
  useEffect(() => () => (mounted.current = false), [])

  const handleDelete = useCallback(
    async confirmed => {
      setDeleting(true)
      if (confirmed) {
        onDelete &&
          (await onDelete()
            .then(res => res !== false && onClose())
            .catch(error => console.error(error)))
      }
      if (mounted.current) setDeleting(false)
    },
    [onClose, onDelete],
  )

  const handleSave = useCallback(async () => {
    setSaving(true)
    if (onSave)
      await onSave()
        .then(res => res && onSaved && onSaved(res))
        .catch(error => console.error(error))
    if (mounted.current) setSaving(false)
  }, [onSave, onSaved])

  const handleSaveAndClose = useCallback(async () => {
    setSavingAndClose(true)
    if (onSave)
      await onSave()
        .then(res => res && onClose())
        .catch(error => console.error(error))
    if (mounted.current) setSavingAndClose(false)
  }, [onSave, onClose])

  const handleCopy = useCallback(async () => {
    setCopying(true)
    if (onCopy) await onCopy().catch(error => console.error(error))
    if (mounted.current) setCopying(false)
  }, [onCopy])

  return (
    <div style={{ whiteSpace: "nowrap" }}>
      {!readOnly && undo !== undefined && (
        <Tooltip title={`Undo ${undo?.name || ""}`}>
          <span>
            <IconButton
              color="inherit"
              disabled={!undo?.callback}
              onClick={undo?.callback}
            >
              <UndoIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!readOnly && redo !== undefined && (
        <Tooltip title={`Redo ${redo?.name || ""}`}>
          <span>
            <IconButton
              color="inherit"
              disabled={!redo?.callback}
              onClick={redo?.callback}
            >
              <RedoIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!readOnly && editing && (
        <Tooltip title={hasTests ? `Cannot delete when sample has tests present`: `Delete ${objectName}`}>
          <span>
            <ButtonWithSpinner
              color="inherit"
              spinnerColor={
                editing ? "secondary" : "primary"
              }
              loading={deleting}
              Component={IconButton}
              onClick={handleDelete}
              disabled={busy || readOnly || !onDelete || hasTests}
              value={progress}
            >
              <DeleteIcon />
            </ButtonWithSpinner>
          </span>
        </Tooltip>
      )}
      <ButtonGroup
        color={editing ? "secondary" : "primary"}
        variant="contained"
      >
        {ActionButtons}
        {onCopy &&
          <ButtonWithSpinner
            onClick={handleCopy}
            spinnerColor={editing ? "secondary" : "primary"}
            loading={copying}
            disabled={busy || !onCopy}
          >
            Copy
          </ButtonWithSpinner>
        }
        {!readOnly &&
          <ButtonWithSpinner
            onClick={handleSave}
            spinnerColor={editing ? "secondary" : "primary"}
            loading={saving}
            disabled={busy || readOnly || !onSave}
            value={progress}
          >
            Save
          </ButtonWithSpinner>}
        {!readOnly &&
          <ButtonWithSpinner
            onClick={handleSaveAndClose}
            spinnerColor={editing ? "secondary" : "primary"}
            loading={savingAndClose}
            disabled={busy || readOnly || !onSave}
            value={progress}
          >
            Save and Close
          </ButtonWithSpinner>}
        <Button onClick={onClose}>
          {closeButtonTitle}
        </Button>
      </ButtonGroup>
    </div>
  )
}