import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    fcNodeInnerText: {
        padding: theme.spacing(0.5,3),
        color: (props = {}) => (props.node && props.node.type && theme.palette[props.node.type]) ? theme.palette.getContrastText(theme.palette[props.node.type].dark) : "#FFFFFF",
    },
    fcNodeInner: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "&.-highlighted": {
            border: "solid 2px #FFFF00",
            borderRadius: theme.spacing(1),
        }
    },
    fcNodeInnerTitleBar: {
        height: `${theme.spacing(4) + (2 * theme.spacing(0.5))}px`,
        minHeight: "auto !important",     
        background: (props = {}) => (props.node && props.node.type && theme.palette[props.node.type]) ? theme.palette[props.node.type].dark : theme.palette.grey[800],
        borderRadius: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: (props = {}) => (props.node && props.node.type && theme.palette[props.node.type]) ? theme.palette.getContrastText(theme.palette[props.node.type].dark) : "#FFFFFF",
    },
    fcNodeInnerTitleText: {
        width: "100%",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        textAlign: "center",
        color: (props = {}) => (props.node && props.node.type && theme.palette[props.node.type]) ? theme.palette.getContrastText(theme.palette[props.node.type].dark) : "#FFFFFF",
    },
    accordian: {
        background: "none",
        borderRadius: theme.spacing(1) + "px !important",
    },
    list: {
        padding: 0
    }
}));


const defaultLabel = "*Missing Label*";
export const InnerComponent = React.forwardRef(
    function ({ dispatch, node, config, className, ...rest }, ref) {
        const classes = useStyles({node});
        const [collapsed, setCollapsed] = useState(config.collapseInfo || false); //default false when undefined

        useEffect(() => {
            // update Collapsed when user toggles collapse/expand all
            setCollapsed(config.collapseInfo);
        }, [setCollapsed, config.collapseInfo])

        const titles = []
        const keys = ["materialDefs", "processDefs", "propertyDefs"]
        keys.forEach(key => {
            if (node.properties && node.properties[key]) {
                for (const [id, def] of Object.entries(node.properties[key])) {
                    titles.push(def.title || config?.[key]?.find(d => d.id === id)?.title || defaultLabel)
                }
            }
        })
        const displayTitles = titles
        const titlesSize = titles.length

        return (
            <div ref={ref} className={clsx(className, classes.fcNodeInner, node.type && ("type-" + node.type))} {...rest}>
                <Accordion className={classes.accordian} expanded={!!titlesSize && collapsed}>
                    <AccordionSummary
                        className={classes.fcNodeInnerTitleBar}
                        expandIcon={!!titlesSize ? <ExpandIcon onClick={() => setCollapsed(!collapsed)} htmlColor="white" /> : <div/>}
                    >
                        <Typography 
                            variant="h6" 
                            className={classes.fcNodeInnerTitleText}
                        >
                            {(node.properties && node.properties.label)}
                        </Typography>
                        {titlesSize > 0 &&
                        <Typography
                            varian="caption"
                            style={{alignSelf: "center"}}
                        >
                            ({titlesSize})
                        </Typography>}
                    </AccordionSummary>
                    <AccordionDetails>
                        <List className={classes.list}>
                            {displayTitles.map((title, i) =>
                                <ListItem key={i} className={classes.fcNodeInnerText}>
                                    <Typography variant="h6">
                                        {title.substring(0, 40)}{title.length > 40 ? "..." : null}
                                    </Typography>
                                </ListItem>
                            )}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
);

/**
 * Get react component based on node type. For use in react-flow-chart-mmm config
 * @param {React.Component} node 
 */
export function getInnerComponent(node) {
    switch (node.type) {
        case 'material':
        case 'process':
        case 'output':
        case 'property':
            return InnerComponent;
        default:
            return undefined;
    }
}