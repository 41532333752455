import { Relationship, Schema, schema } from "schema/schemaUtils"
import { SampleSet } from "./SampleSet"
import { Material } from "./Material"

/** This model is identical to sample but hits the material end point to combine saving instances with samples */
export class MaterialSet extends SampleSet {

    static readonly [schema]: Schema<MaterialSet> = {
        ...SampleSet[schema],
        relationships: {
            ...SampleSet[schema].relationships,
            samples: { ...SampleSet[schema].relationships?.samples, model: "Material" } as Relationship<MaterialSet, Material[]>,
        },
    }

    constructor(toCopy?: Partial<MaterialSet>) {
        super()
        Object.assign(this, toCopy)
    }
}