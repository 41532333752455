import React, { useState } from 'react';
import { useETLYProcessorsList } from "../../API/queryHooks";
import MultiSelector from '../General/MultiSelector';
/**
 * React Component for performing an ETLY Processor selection
 */
export default function ProcessorSelector({ selected, onChange, ...remainingProps }) {
    const [input, setInput] = useState("");
    const { data, isLoading } = useETLYProcessorsList();
    return (
        <MultiSelector
            label="Select Processor"
            missingLabel="Unknown ETLY Processor"
            loading={isLoading}
            value={selected || []}
            options={data || []}
            onBlur={() => setInput("")}
            onChange={(...args) => { onChange && onChange(...args); setInput("") }}
            inputValue={input || ""}
            onInputChange={(ev) => { ev && setInput(ev?.target?.value || "") }}
            data-testid="processor selector"
            selectSingle
            {...remainingProps}
        />
    );
}