import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { Popper } from "@material-ui/core";

export default function ColumnSelectField({ label, value, setValue, headers }) {
  const styles = (theme) => ({
    popper: {
       width: "fit-content"
    }
  });

  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };
  return (
    <>
      <br />
      <Autocomplete
        id="combo-box-demo"
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        options={headers}
        PopperComponent={PopperMy}
        renderInput={(params) => <TextField {...params} label={label} />}
        style={{ textAlign: "left"}}
        value={value}
      />
    </>
  )

}
