import React, { useState, useEffect, useRef, useCallback } from "react";
import classNames from "classnames";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import _ from "lodash";
import axios from "axios";
import blue from "@material-ui/core/colors/blue";
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/core/styles';
import common_styles from '../../../styles/common_styles';
import { useAppStoreKey } from '../../../AppStore';


const useStyles = makeStyles((theme) => ({
  ...common_styles(theme),
  root: {
    flexGrow: 1,
    "& div": {
      zIndex: 1
    }
  },
  input: {
    display: "flex",
    padding: 0,
    height: 36,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    height: 36
    // overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    marginBottom: 0,
    marginTop: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white"
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === "light" ? blue[300] : blue[700], 0.08)
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "relative",
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: 0
  }
}));

function ShareUserMultiSelect({ shareUsersSelected }) {
  const [selectedSharedUsers, setSelectedSharedUsers] = useState([]);
  const [shareUserSearching, setShareUserSearching] = useState(true);
  const [shareUsers, setShareUsers] = useState([]);
  const [modelContext, setModelContext] = useAppStoreKey("ModelContext");

  useEffect(() => {
    setSelectedSharedUsers(modelContext.shareUsers ? modelContext.shareUsers : []);
  }, [modelContext.shareUsers])

  function NoOptionsMessage(props) {
    const classes = useStyles();

    return (
      shareUserSearching ? (
        <Typography color="textSecondary" className={classes.noOptionsMessage} {...props.innerProps}>
          {"Searching..."}
        </Typography>
      ) : (
        <Typography color="textSecondary" className={classes.noOptionsMessage} {...props.innerProps}>
          {props.children}
        </Typography>)
    );
  }

  function Control(props) {
    const classes = useStyles();


    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        data-cy="share_model_name"
        onChange={ev => searchusername.current(ev.target.value)}
        {...props.selectProps.textFieldProps}
      />
    );
  }


  let SearchUserName = useCallback(
    (name) => {
      setShareUserSearching(true);
      if (name.length > 2) {
        const cookies = new Cookies();
        var graph_token = cookies.get('graph_token')

        let url = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${name}')&$select=displayName,userPrincipalName,department`;
        axios.get(url,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${graph_token}`
            }
          }).then(res => {
            var users = [];
            res['data']['value'].forEach(element => {
              if (element['userPrincipalName'].includes('@mmm.com')) {
                var userPrincipalName = element.userPrincipalName.replace("@mmm.com", "");
                users.push({
                  userPrincipalName: userPrincipalName,
                  label: element.displayName + "–" + element.department + " (" + userPrincipalName + ")",
                  value: element.displayName + "–" + element.department + " (" + userPrincipalName + ")",
                  displayName: element.displayName
                });
              }
            });

            let selected = Object.assign([], selectedSharedUsers); // todo fix this ^^^
            let options = users.concat(selected);
            setShareUsers(options);
          });
      }
    }, [selectedSharedUsers]);


  function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
  }

  function Option(props) {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  function Placeholder(props) {
    const classes = useStyles();

    return (
      <Typography color="textSecondary" className={classes.placeholder} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  function SingleValue(props) {
    const classes = useStyles();

    return (
      <Typography className={classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }

  function MultiValue(props) {
    const classes = useStyles();

    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(classes.chip, {
          [classes.chipFocused]: props.isFocused
        })}
        // primary
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    );
  }

  function Menu(props) {
    const classes = useStyles();

    return (
      <Paper square className={classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }

  function IndicatorSeparator(props) {
    return null;
  }

  function DropdownIndicator(props) {
    return null;
  }

  const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorSeparator,
    DropdownIndicator
  };


  const classes = useStyles();
  let searchusername = useRef();

  useEffect(() => {
    searchusername.current = _.debounce(SearchUserName.bind(this), 750);
  }, [SearchUserName]);

  const handleChange = name => value => {
    let newSelected = Object.assign([], shareUsersSelected);
    let newArray = value;
    newArray.concat(newSelected);

    // TODO - update in context
    setSelectedSharedUsers(newArray);
    setModelContext({ ...modelContext, shareUsers: newArray });
  };



  const selectStyles = {
    input: base => ({
      ...base,
      color: 'blue', // TODO theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  };

  return (
    <div className={classes.root}>
      <Select
        classes={classes}
        styles={selectStyles}
        // textFieldProps={{
        //   sourceprops: this.props
        // }}
        options={shareUsers}
        components={components}
        value={selectedSharedUsers}
        onChange={handleChange("multi")}
        placeholder="Enter names here"
        isMulti
      />
    </div>
  );
}


export default React.memo(ShareUserMultiSelect);
