import { Relationship, Schema, schema } from "schema/schemaUtils"
import { LabToSample } from "./LabToSample"
import { ProcessInstance } from "./ProcessInstance"
import { PropertyInstance } from "./PropertyInstance"
import { Sample } from "./Sample"
import { SampleInstance } from "./SampleInstance"

/** This model is identical to sample but hits the material end point to combine saving instances with samples */
export class Material extends Sample {

    static readonly [schema]: Schema<Material> = {
        ...Sample[schema],
        endPoint: "materials",
        relationships: {
            ...Sample[schema].relationships,
            components: { ...Sample[schema].relationships?.components, includeFull: true } as Relationship<Material, SampleInstance[]>,
            processSteps: { ...Sample[schema].relationships?.processSteps, includeFull: true } as Relationship<Material, ProcessInstance[]>,
            properties: { ...Sample[schema].relationships?.properties, includeFull: true } as Relationship<Material, PropertyInstance[]>,
            labs: { ...Sample[schema].relationships?.labs, alwaysInclude: true } as Relationship<Material, LabToSample[]>,
        },
    }

    constructor(toCopy?: Partial<Material>) {
        super()
        Object.assign(this, toCopy)
    }
}