import { useAppStoreKey } from 'AppStore'
import { useCallback, useMemo } from 'react'
import { getOriColumns } from "../utils"

export default function useModelData({ modelID }) {
  const [workflow,] = useAppStoreKey("Workflow")

  const GetModelData = useCallback(() => {
    let data;

    if(modelID !== undefined) {
      data = workflow.models[modelID].data[0];
    } else {
      data = workflow.data;
    }
    const selected_sheet = data.active_sheet
    if (!data || !selected_sheet || !data.info[selected_sheet].input_cols) return {}

    return { data: data, selected_sheet: selected_sheet }
  }, [modelID, workflow.data, workflow.models])

  const OriginalColumnOrdering = useMemo(() => {
    const { data, selected_sheet } = GetModelData();
    if (!data) return { input: [], output: [] }
    const transform = data.info[selected_sheet].columns_transform
    const inputCols = data.info[selected_sheet].input_cols
    const outputCols = data.info[selected_sheet].output_cols
    let inputColNames = [...inputCols]
    let outputColNames = [...outputCols]

    inputColNames = getOriColumns(transform, inputColNames)
    outputColNames = getOriColumns(transform, outputColNames)
    return {
      input: inputColNames,
      output: outputColNames
    }
  }, [GetModelData])

  const ActiveInfo = useMemo(() => {
    const { data, selected_sheet } = GetModelData();
    return data.info[selected_sheet]
  }, [GetModelData])

  const DecodeCategorical = useCallback((data) => {
    let decoded = {}
    OriginalColumnOrdering.input.forEach((col) => {
      if (ActiveInfo.column_types[col] !== "string") {
        decoded[col] = data[col]
      } else {
        // find whichCat
        let children = ActiveInfo.columns_transform[col].children
        let categories = ActiveInfo.ori_data_range[col]
        // we need to un
        decoded[col] = categories[children.map((c) => data[c]).indexOf(1)]
      }
    })
    return decoded
  }, [ActiveInfo, OriginalColumnOrdering])
  return {
    GetModelData,
    OriginalColumnOrdering,
    ActiveInfo,
    DecodeCategorical
  }
}