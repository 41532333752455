import React, { useCallback, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useAppStoreKey } from "../../AppStore";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import common_styles from "../../styles/common_styles";
import MsCreateModel from "./CreateAndImport/MsCreateModel";
import UndoIcon from "@material-ui/icons/Undo";
import SaveIcon from "@material-ui/icons/Save";
import CreateIcon from '@material-ui/icons/Create';
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import StepButton from "@material-ui/core/StepButton";
import CloudUploadIcon from "mdi-material-ui/CloudUploadOutline";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import EditIcon from "@material-ui/icons/Edit";
import SpeedIcon from "@material-ui/icons/Speed";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import BarChartIcon from "@material-ui/icons/BarChart";
import MsEditModel from "./Edit/MsEditModel";
import SaveModelDialog from "./Dialogs/SaveModelDialog";
import ShareModelDialog from "./Dialogs/ShareModelDialog";
import DeleteModelDialog from "./Dialogs/DeleteModelDialog";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Tooltip } from "@material-ui/core";
import { useNavigate } from "@reach/router"
import MsPredictModel from "./Predict/MsPredictModel";
import MsOptimizeModel from "./Optimize/MsOptimizeModel";
import MsTrainModel from "./TrainModel/MsTrainModel";
import MsVisualizeModel from "../MixingStudio-proto/Visualize/MsVisualizeModel";
import { aichemyProtoAxios } from "../../API/mmAxios";
import { GetModelList } from "./CreateAndImport/ListModels";
import { AccountContext } from '../../context';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    ...common_styles(theme),
    headerTextColor: {
        color: theme.palette.text.primary
    },
    standardIcon: {
        backgroundColor: theme.palette.primary.dark,
        fontSize: 10,
        padding: 5,
        borderRadius: '50%',
        width: 25,
        height: 25,
        marginTop: -5
    },
    disabledIcon: { backgroundColor: "#bdbdbd" },
    enabledIcon: {
        backgroundColor: theme.palette.primary.light
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));
const EVALUATE = 'Evaluate';
const SELECT_DATA = 'Select Data';



export default function HorizontalLinearStepper() {
    const classes = useStyles();
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useAppStoreKey("ActiveStep");
    const [displayNotification, setDisplayNotification] = useState(true);

    // set default active step
    useEffect(() => {
        if (activeStep === undefined) setActiveStep(0)
    }, [activeStep, setActiveStep])
    const [modelContext, setModelContext] = useAppStoreKey("ModelContext");
    const [workflow, setWorkflow] = useAppStoreKey("Workflow");

    const [modelDialogContext, setModelDialogContext] = useAppStoreKey("ModelDialogContext");
    const [currentModels, setCurrentModels] = useState([]);
    const [, setIsLoading] = useState(true);
    const [deleteContext, setDeleteContext] = useState({
        type: "",
        model_idx: "",
        workflow_id: "",
        model_name: ""
    });

    const account = useContext(AccountContext);

    const disableSteps = (disableList, allViews) => allViews.map((view, idx) => {
        let currentView = { ...view }
        currentView['disabled'] = !(disableList.indexOf(idx) > -1);
        return currentView;
    })


    const getSteps = useCallback(() => {
        const allViews = [
            { label: 'Create or Load', content: <MsCreateModel />, Icon: CloudUploadIcon, enabledType: "" },
            { label: 'Edit', content: <MsEditModel />, Icon: EditIcon, enabledType: "" },
            { label: 'Visualize', content: <MsVisualizeModel />, Icon: BarChartIcon, enabledType: "new" },
            { label: 'Model', content: <MsTrainModel />, Icon: BubbleChartIcon, enabledType: "new" },
            { label: 'Predict', content: <MsPredictModel />, Icon: ImageSearchIcon, enabledType: "existing" },
            { label: 'Optimize', content: <MsOptimizeModel />, Icon: SpeedIcon, enabledType: "existing" },
        ]

        const initViews = [0]
        const afterWfLoadedViews = [0, 1, 2, 3]
        const afterModelTrainedViews = [0, 1, 2, 3, 4, 5]
        if (!workflow) return disableSteps(initViews, allViews)
        else {
            const nVisibleModels = workflow.models.filter(m => !m.hide).length;
            if (nVisibleModels > 0) return disableSteps(afterModelTrainedViews, allViews)
            return disableSteps(afterWfLoadedViews, allViews)
        }
    }, [workflow])

    const handleReset = useCallback(() => {
        setActiveStep(0);
    }, [setActiveStep]);


    useEffect(() => {
        if (modelDialogContext && modelDialogContext.deleted) {
            setModelDialogContext({ deleteOpen: false, deleted: false, saveOpen: false, saved: false, shareOpen: false, shared: false });
            setModelContext({
                sourceId: "",
                selectedFileSheet: undefined,
                modelName: "",
                fileSheets: undefined,
                allData: [],
                loadType: "",
                loaded: false,
                dataSnapshot: undefined,
                postId: "",
                sharedWith: [],
                modelId: "",
                currentModels: modelContext.currentModels,
                loadedModelData: undefined,
                allConstraints: [],
                shareUsers: [],
                predictionInput: undefined,
                predictionOutput: undefined,
                contourData: undefined,
                Saved: false
            });
        }
        //Navigates to the 'EVALUATE' step when model data is loaded or generated (but not on delete)
        else if (modelContext.loaded) {
            getSteps().forEach((step, index) => {
                if (step.label === EVALUATE) {
                    setActiveStep(index);
                }
            });
            setModelContext({ ...modelContext, loaded: false })
        }
        //Navigates to data selection when file is loaded manually
        else if (modelContext.fileUploaded) {
            getSteps().forEach((step, index) => {
                if (step.label === SELECT_DATA) {
                    setActiveStep(index);
                }
            });
            setModelContext({ ...modelContext, fileUploaded: false })
        }
    }, [modelContext, modelDialogContext, setModelContext, setModelDialogContext, handleReset, setActiveStep, getSteps, navigate])

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const saveModel = () => {
        //Show save dialog
        setModelDialogContext({ ...modelDialogContext, saveOpen: true, saved: false });
    };

    const shareModel = () => {
        setModelDialogContext({ ...modelDialogContext, shareOpen: true, shared: false });
    }

    const deleteModel = () => {
        setDeleteContext({
            type: "current",
            workflow_id: workflow.uuid,
            model_name: workflow.name,
            model_idx: ""
        })
        setModelDialogContext({ ...modelDialogContext, deleteOpen: true, deleted: false });
    }

    useEffect(() => {
        GetModelList(setCurrentModels, setIsLoading)
    }, [])

    //determines if used can delete a selected item
    const allowDelete = (workflow) => {
        let creator_name = workflow.creator_name;
        return !(creator_name === account.name);
    }

    // convert the operation name in history to the actual operation name
    let parseOperationName = (operationArgs) => {
        let regexs = [
            "<class '([a-zA-z\\s.]*)'>",     // data cleaning
            "<core.workflow.scalers.([a-zA-z\\s.]*) object", // scalars
            "<core.workflow.models.([a-zA-z\\s.]*) object",  // model training
        ]
        let regexResults = regexs.map(regex => {
            let match = operationArgs.match(regex);
            if (match) {
                return match[1];
            }
            return undefined;
        })
        // use the first match
        regexResults = regexResults.filter(result => result !== undefined)
        let funcName = regexResults[0]
        let splitFuncName = funcName.split(".");
        return splitFuncName[splitFuncName.length - 1];
    }

    let getLastStep = () => {
        // NOTE(AWL): separated as some legacy models don't contain this field...
        const post_process = workflow.history.model_post_process !== undefined ? workflow.history.model_post_process : []
        let allHistory = [
            ...workflow.history.data_prep,
            ...workflow.history.data_cleaning,
            ...workflow.history.model_training,
            ...post_process,
            ...workflow.history.optimization,
            ...workflow.history.visualization,
        ]
        if (allHistory.length < 2) return '';  // The first step is always the 'load_data' step
        else {
            // sort allHistory by time
            allHistory.sort((a, b) => {
                return new Date(a.timestamp) - new Date(b.timestamp);
            })
            return parseOperationName(allHistory[allHistory.length - 1].args);
        }

    }


    let undoWorkflow = () => {
        const url = `workflow/` + workflow.uuid + '/undo'
        const config = {
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }
        aichemyProtoAxios.post(url, {}, config)
            .then(res => {
                const wf = res.data
                // Object.keys(wf.data.data_df).forEach(key => wf.data.data_df[key] = JSON.parse(wf.data.data_df[key]))
                setWorkflow(wf);
            }).catch(err => console.error(err))
    }
    // Add a banner to notifiy users that the 2.0 version is under beta testing. And provide a link to the 2.0 version.
    const migrationBanner = () => {
        return <AppBar style={{ marginTop: workflow ? 128 : 64, backgroundColor: '#f31515', height: 35}} position="absolute">
            <div className='flex flex-row' >
                <Toolbar>
                    <Typography variant="body1" className={classes.title}>
                    </Typography>

                    <div style={{ display: "flex", marginTop: -30, marginRight: -15, padding: 5, justifySelf: "flex-end", flexDirection: "row", alignItems: "flex-end" }}>
                        <div>
                            <Typography variant="body1" className={classes.title}>
                                Join MixingStudio 2.0 Testing Today! <a href="https://mixing-studio.mi.cld.3m.com/" target="_blank" rel="noopener noreferrer">Upgrade</a> Now to Explore Enhanced Features and a Fresh Experience!
                            </Typography>
                        </div>
                        <Tooltip title="Close Ruthlessly">
                            <IconButton
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => setDisplayNotification(false)}
                              className={classes.button}
                              style={{paddingBottom: 0}}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div></Toolbar>
            </div>
        </AppBar>
    }

    return (
        <div className={classes.root}>
            {displayNotification && migrationBanner()}
            {workflow && <AppBar style={{ marginTop: 64 }} position="absolute">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                    </Typography>
                    <Typography variant="h6" className={classes.title}>
                    </Typography>
                    <div style={{ display: "flex", marginTop: -5, marginRight: -24, padding: 5, justifySelf: "flex-end", flexDirection: "row", alignItems: "flex-end" }}>
                        <div>
                            {/*TODO: set the model status*/}
                            {!workflow.saved &&
                                <Typography color="secondary" style={{ fontSize: 14 }}>
                                    <ErrorOutlineIcon color="secondary" style={{ fontSize: 16, marginTop: 5, marginRight: 3, marginBottom: -3 }} />Model has not been saved.
                                </Typography>
                            }
                            <Typography className={classes.cardSubtitle}>Current Model: {!workflow.name ? "N/A" : workflow.name}</Typography>
                        </div>
                        <div style={{ display: "flex", justifySelf: "flex-end", flexDirection: "row", alignItems: "flex-end" }}>
                            <Tooltip title={`Undo ${getLastStep()}`}>
                                <span>
                                    <IconButton
                                        disabled={getLastStep() === ''}
                                        variant="contained"
                                        size="small"
                                        color="inherit"
                                        onClick={undoWorkflow}
                                        className={classes.button}
                                    >
                                        <UndoIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={workflow.saved ? "Edit title & description" : "Save Model"}>
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    color="inherit"
                                    onClick={saveModel}
                                    className={classes.button}
                                >
                                    {workflow.saved ? <CreateIcon /> : <SaveIcon />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Share Model">
                                <IconButton
                                    disabled={!workflow?.saved}
                                    variant="contained"
                                    color="inherit"
                                    size="small"
                                    onClick={shareModel}
                                    className={classes.button}
                                >
                                    <ShareIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Model">
                                <IconButton
                                    disabled={allowDelete(workflow)}
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={deleteModel}
                                    className={classes.button}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>     </Toolbar>
            </AppBar>}
            <div className={classes.paperBody} style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>

                </div>
            </div>
            <Stepper style={{ background: 'transparent', paddingTop: 50 }} alternativeLabel nonLinear activeStep={activeStep}>
                {getSteps().map(({ label, Icon, enabledType, disabled }, index) => {
                    let classStyle
                    if (disabled) classStyle = classes.disabledIcon;
                    else if (index === activeStep) classStyle = classes.enabledIcon
                    else classStyle = classes.standardIcon
                    return (
                        <Step key={label}>
                            <StepButton
                                disabled={disabled}
                                icon={<Icon
                                    color={disabled ? "disabled" : "action"}
                                    className={clsx(classes.standardIcon, classStyle)} />}
                                onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === getSteps().length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </div>
                ) : (
                    <div>
                        {getSteps()[activeStep ? activeStep : 0].content}
                    </div>
                )}
            </div>
            <SaveModelDialog />
            <ShareModelDialog open={false} />
            {
                (
                    deleteContext.type !== "" && workflow !== undefined &&
                    <DeleteModelDialog
                        deleteContext={deleteContext}
                        setDeleteContext={setDeleteContext}
                        currentModels={currentModels}
                        setCurrentModels={setCurrentModels}
                        navigate={navigate}
                    />
                )
            }
        </div>
    );
}