
import React from 'react';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    itemRoot:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    item: props => {
        const backgroundColor = (props.themePalette && theme.palette[props.themePalette]) ? theme.palette[props.themePalette].main : theme.palette.grey[300];
        return {
            background: backgroundColor,
            color: theme.palette.getContrastText(backgroundColor),
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1)/2,
            width: `calc(100% - ${theme.spacing(2)}px)`,
            borderRadius: theme.spacing(1),
            cursor: props.readOnly ? undefined : "move",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
            ...theme.typography.caption,
        }
    }
}));

export function DragNode({data = {}, className, readOnly, ...rest}){
    const classes = useStyles({themePalette: data.themePalette, readOnly});

    const handleDrag = React.useCallback((event) => {
        
        if (data.data){
            event.dataTransfer.clearData(); // not sure if this does anything. Was seeing intermittent issue where drag was failing silently.
            event.dataTransfer.setData("text/plain", JSON.stringify(data.data));
        }
    }, [data]);

    return (
        <div 
                className={clsx(className, classes.item)}
                draggable={!readOnly}
                onDragStart={(!readOnly && handleDrag) || undefined}
                {...rest}
            >
                {data.label ? data.label : "Item"}
        </div>
    )
}

function DragListNode(props){
    const { style, index, data } = props;
    const classes = useStyles();
    const itemData = data ? data[index] : undefined;

    return (
        <div className={classes.itemRoot} style={style}>
            <DragNode data={itemData}/>
        </div>
    );
}
export default React.memo(DragListNode);