export const dragMaterialGroupData = {
    label: "Material Group",
    themePalette: "material",
    data: {
        type: "material",
        properties: {
            label: "Materials",
            data: [],
        },
        ports: {
            output: {
                id: "output",
                type: "right",
            }
        }
    }
}

export const dragOutputLabelData = {
    label: "Output Data",
    themePalette: "label",
    data: {
        type: "property",
        properties: {
            label: "Output",
            data: [],
        },
        ports: {
            "0": {
                id: "0",
                type: "left",
            }
        }
    }
}

export const dragProcessStepData = {
    label: "Process Step",
    themePalette: "process",
    data: {
        type: "process",
        properties: {
            label: "Process",
            data: [],
        },
        ports: {
            "0": {
                id: "0",
                type: "left",
            },
            output: {
                id: "output",
                type: "right",
            }
        }
    }
}

export function nodeToDragData(node){
    let themePalette = "label";
    let data;
    if (node.type === "material") {
        themePalette = "material";
        data = {
            id: node.id,
            type: "material",
            properties: node.properties,
            ports: {
                output: {
                    id: "output",
                    type: "right",
                }
            }
        };
    }
    else if (node.type === "process") {
        themePalette = "process";
        data = {
            id: node.id,
            type: "process",
            properties: node.properties,
            ports: {
                "0": {
                    id: "0",
                    type: "left",
                },
                output: {
                    id: "output",
                    type: "right",
                }
            }
        };
    }
    else {
        data = {
            id: node.id,
            type: "output",
            properties: node.properties,
            ports: {
                "0": {
                    id: "0",
                    type: "left",
                }
            }
        }
    }
    return {
        label: node.properties.label,
        themePalette,
        data,
    }
}

export function processStepToDragData(process){
    return {
        label: process.name,
        themePalette: "process",
        data: {
            type: "process",
            properties: {
                label: process.name,
                data: [],
            },
            ports: {
                input: {
                    id: "input",
                    type: "left",
                },
                output: {
                    id: "output",
                    type: "right",
                }
            }
        }
    }
}