import {
    IChart,
    IChartOffset,
    INode,
    ILink,
    ISelectedOrHighlighted,
    IHovered,
} from "react-flow-chart-mmm"


export class FlowChart implements IChart {
    offset: IChartOffset
    nodes: {
        [id: string]: INode,
    }
    links: {
        [id: string]: ILink,
    }
    properties?: any

    /** System Temp */
    selected: ISelectedOrHighlighted
    highlighted?: ISelectedOrHighlighted
    hovered: IHovered
    constructor(toCopy?: FlowChart) {
        this.offset = {...(toCopy?.offset || defaultOffset)}
        this.nodes = JSON.parse(JSON.stringify(toCopy?.nodes || {}))
        this.links = JSON.parse(JSON.stringify(toCopy?.links || {}))
        this.selected = JSON.parse(JSON.stringify(toCopy?.selected || {}))
        this.hovered = JSON.parse(JSON.stringify(toCopy?.hovered || {}))
        this.properties = toCopy?.properties
    }
}

export const defaultOffset: IChartOffset = {x: -4000, y: -4000, scale: 1};
