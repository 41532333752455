import React, { useState } from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core'; 
import { useQueryAutocomplete } from '../../API/queryHooks';
import MultiSelector from './MultiSelector';
import DefinitionSelectorBase from './DefinitionSelectorBase';
import SelectPropertyTableDialog from './SelectPropertyTableDialog';


const useStyles = makeStyles(theme => ({
    filter: {
        backgroundColor: theme.palette.type === "dark" ? theme.palette.grey["700"] : theme.palette.grey["100"],
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center"
    }
    })
)

const params = {
    Model: "PropertyDefinition",
    fields: ["title", "id", "alternateNames", "description", "meta"],
}

const EXTEND_LIST_TEXT = "Start typing to filter results and extend list...";

/**
 * React Component for performing a multiselect on on properties
 * @param props most props forwared to MultiSelector
 * @param props.selected forwared as the value prop
 */
export default function PropertySelector(props){
    return (
        <DefinitionSelectorBase Selector={PropertySelectorBase} TableSelectorDialog={SelectPropertyTableDialog} {...props}/>
    );
}

export function PropertySelectorBase({selected, onChange, ...remainingProps }){
    const [input, setInput] = useState({});
    const { data: {data: options} = {}, isFetching } = useQueryAutocomplete(input, params, {logName: "Property Selector"});

    const labelContent = `Select Properties (${EXTEND_LIST_TEXT})`;
    return (
        <MultiSelector
            label={labelContent}
            missingLabel="Missing or Private Property"
            loading={isFetching}
            options={options || []}
            value={selected || []}
            onBlur={() =>  setInput({})}
            onChange={(...args) => {onChange && onChange(...args); setInput({})}}
            inputValue={input.title || ""}
            onInputChange={(ev, newVal) => {ev && setInput({title: ev?.target?.value || ""})}}
            data-testid="property selector"
            PaperComponent={ListContainer}
            {...remainingProps}
            />
    );
}

const ListContainer = React.forwardRef(function ListContainer({children, ...rest}, ref){
    const classes = useStyles();
    return (
        <div>
            <Paper>
                <div className={classes.filter}>
                    <Typography style={{paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px'}}>{EXTEND_LIST_TEXT}</Typography>
                </div>
            </Paper>
            <Paper ref={ref} {...rest}>
                {children}
            </Paper>
        </div>
    )
})