/**
 * @format
 */
import React, { useState } from "react"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { makeStyles, IconButton } from "@material-ui/core"
import AppendedSelector from "./AppendedSelector"
import PropertySelector from "../General/PropertySelector"
import TreeItemLabel from "./TreeItemLabel"
import ClearIcon from "@material-ui/icons/Clear"

const useStyles = makeStyles(theme => ({
    label: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: "14ch",
    },
}))

export function ComponentTree({
    onSelect,
    filters,
    setFilters,
    sample,
    onClickKey,
}) {
    const [expanded, setExpanded] = useState(["component"])
    const { componentProperties } = filters

    return (
        <TreeView
            expanded={expanded}
            onNodeToggle={(ev, nodeIds) => setExpanded(nodeIds)}
            onNodeSelect={(ev, value) => onSelect && onSelect(value)}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            <TreeItem nodeId="component" label="component">
                <TreeItem
                    nodeId="component.amount"
                    label={
                        <TreeItemLabel
                            label="Amount"
                            rightProps={{
                                onClick() {
                                    onClickKey("component.amount")
                                },
                            }}
                        />
                    }
                />
                <TreeItem
                    nodeId="component.units"
                    label={
                        <TreeItemLabel
                            label="Units"
                            rightProps={{
                                onClick() {
                                    onClickKey("component.units")
                                },
                            }}
                        />
                    }
                />
                <TreeItem
                    nodeId="component.definition.title"
                    label={
                        <TreeItemLabel
                            label="Name"
                            rightProps={{
                                onClick() {
                                    onClickKey("component.definition.title")
                                },
                            }}
                        />
                    }
                />
                <PropertyItems
                    rootId="component.definition"
                    properties={componentProperties}
                    onClickKey={onClickKey}
                    onDelete={prop =>
                        setFilters(current => ({
                            ...current,
                            componentProperties: current.componentProperties.filter(
                                p => p !== prop,
                            ),
                        }))
                    }
                />
                <MorePropertiesTreeItem
                    nodeId="component.moreProperties"
                    onSelect={(ev, values) => {
                        setFilters(current => ({
                            ...current,
                            componentProperties: (
                                current.componentProperties || []
                            )
                                .concat(values)
                                .filter((p, i, arr) => arr.indexOf(p) === i),
                        }))
                        const nodeId = makePropertyNodeId(
                            "component.definition",
                            values[0],
                        )
                        setExpanded(current => current.concat(nodeId))
                    }}
                />
            </TreeItem>
        </TreeView>
    )
}

function MorePropertiesTreeItem({ nodeId, onSelect, appendOptions }) {
    const classes = useStyles()

    return (
        <TreeItem
            nodeId={nodeId}
            label={
                <div
                    className={classes.label}
                    onClick={ev => ev.stopPropagation()}
                >
                    <AppendedSelector
                        selectSingle
                        Selector={PropertySelector}
                        selected={[]}
                        placeholder="More Properties..."
                        onChange={onSelect}
                        appendOptions={appendOptions}
                    />
                </div>
            }
        />
    )
}

function PropertyItems({ rootId, properties = [], onClickKey, onDelete }) {
    return properties.map((property, i) => {
        const nodeId = makePropertyNodeId(rootId, property)
        const valueId = nodeId + ".map(f(prop,i,arr)=prop.data)"
        const unitsId = nodeId + ".map(f(prop,i,arr)=prop.definition.units)"
        const meanId = `mean(${valueId})`
        const firstId = `${valueId}[1]`

        return (
            <TreeItem
                key={i}
                nodeId={nodeId}
                label={
                    <div>
                        {property.title}
                        <IconButton
                            size="small"
                            onClick={ev => {
                                ev.stopPropagation()
                                onDelete && onDelete(property)
                            }}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </div>
                }
            >
                <TreeItem
                    nodeId={meanId}
                    label={
                        <TreeItemLabel
                            label="Mean Value"
                            rightProps={{
                                onClick() {
                                    onClickKey(meanId)
                                },
                            }}
                        />
                    }
                />
                <TreeItem
                    nodeId={valueId}
                    label={
                        <TreeItemLabel
                            label="All Values"
                            rightProps={{
                                onClick() {
                                    onClickKey(valueId)
                                },
                            }}
                        />
                    }
                />
                <TreeItem
                    nodeId={firstId}
                    label={
                        <TreeItemLabel
                            label="First Value"
                            rightProps={{
                                onClick() {
                                    onClickKey(firstId)
                                },
                            }}
                        />
                    }
                />
                <TreeItem
                    nodeId={unitsId}
                    label={
                        <TreeItemLabel
                            label="Units"
                            rightProps={{
                                onClick() {
                                    onClickKey(unitsId)
                                },
                            }}
                        />
                    }
                />
            </TreeItem>
        )
    })
}

function makePropertyNodeId(rootId, property) {
    return `${rootId}.properties.filter(f(prop,i,arr)=equalText(prop.definition.title,"${property.title}"))`
}
