import React, { useCallback, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useAppStoreKey } from "../../../AppStore";
import { ListItem, ListItemIcon, ListItemText, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AsteriskIcon from "mdi-material-ui/Asterisk";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { aichemyProtoAxios } from "../../../API/mmAxios";
import IconButton from "@material-ui/core/IconButton";
import { LargeTooltip } from "../components/LargeTooltip";
export const plotHeightMapper = { RMSE: 16, Sensitivity: 16, Parity: 16 }

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function ModelSelector({ modelCompareResultsRef }) {
  const classes = useStyles();
  const [selectedModel, setSelectedModel] = useState({ model_idx: "", model_name: "" })
  const [selectedWorkflow, setSelectedWorkflow] = useState({ wf_uuid: "", wf_name: "" })
  const [modelToCompare, setModelToCompare] = useState([])
  const [workflow, ] = useAppStoreKey("Workflow");
  const [MSState, setMSState] = useAppStoreKey("MixingStudioState");
  const [allWfs, setAllWfs] = useState({})

  const initalLayout = (idx, plot) => {
    const title = plot.layout.title
    const funcKey = Object.keys(plotHeightMapper).filter(key => title.toLowerCase().includes(key.toLowerCase()))[0]
    return {
      x: (idx * 6) % 12,
      y: Infinity,
      w: 6,
      h: plotHeightMapper[funcKey],
      minH: plotHeightMapper[funcKey],
      maxH: plotHeightMapper[funcKey],
      minW: 4,
      i: String(idx),
    };
  }

  const getWorkflows = useCallback((baseModel = '') => {
    // if no model is selected, return all workflows
    if (!baseModel) {
      // get all workflows from API
      let url = `workflow/models`;
      let config = {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      }
      aichemyProtoAxios.get(url, {}, config)
        .then(res => {
          setAllWfs(res.data ? res.data : [])
        })
        .catch(err => console.error(err))
    } else {
      // get compaitble workflows from API
      let url = `workflow/${baseModel.wf_uuid}/${baseModel.model_idx}/compatible_models`;
      let config = {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      }
      aichemyProtoAxios.get(url, {}, config)
        .then(res => {
          setAllWfs(res.data ? res.data : [])
        })
        .catch(err => console.error(err))
    }
  }, [])

  useEffect(() => {
    if (Object.keys(allWfs).length === 0) {
      getWorkflows()
    }
  }, [allWfs, getWorkflows])


  const getModels = (wf_uuid) => {
    if (!allWfs) {
      return []
    } else {
      if (wf_uuid === workflow.uuid) { // if the selected workflow is the current workflow
        const currentModels = workflow.models.map((model, idx) => {
          return { model_idx: idx, model_name: `${model.info.name}`, hide: model.hide };
        });
        if (modelToCompare.length === 0) {
          return currentModels
      } else {
          const modelToCompareIdx = modelToCompare.map(model => model.model_idx)
          return currentModels.filter(model => !modelToCompareIdx.includes(model.model_idx))
        }
      }
      if (!allWfs[wf_uuid]) {
        return []
      } else {
        return allWfs[wf_uuid].map((model, idx) => {
          return { model_idx: idx, model_name: `${model.model_name}`, hide: model.hide};
        });
      }
    }
  }

  const stageModelForCompare = () => {
    const newModelToCompare = [...modelToCompare, {
      wf_uuid: selectedWorkflow.wf_uuid,
      wf_name: selectedWorkflow.wf_name,
      model_idx: selectedModel.model_idx,
      model_name: selectedModel.model_name
    }]
    // save the selection
    setModelToCompare(newModelToCompare)
    // reset the selection
    setSelectedWorkflow({ wf_uuid: "", wf_name: "" })
    setSelectedModel({ model_idx: "", model_name: "" })
    // get workflow and model data again and check for compatibility
    getWorkflows(newModelToCompare[0])

  };

  const handleCompareModel = () => {
    let url = `workflow/compare`;
    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    }

    let payload = {
      wf_id_list: modelToCompare.map(model => model.wf_uuid),
      model_id_list: modelToCompare.map(model => model.model_idx),
    }


    let body = JSON.stringify(payload)

    aichemyProtoAxios.post(url, body, config)
      .then(res => {
        const newPlots = res.data
        const initLayout = newPlots.map((plot, idx) => initalLayout(idx, plot))
        const initParams = newPlots.map(() => { return { expand: true } })
        setMSState({
          ...MSState,
          modelComparePlotLayout: initLayout,
          modelComparePlotParams: initParams,
          modelComparePlots: newPlots,
        })
        // scroll to results
        modelCompareResultsRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      })
      .catch(err => console.error(err))
  }

  const handleSelectWf = (e) => {
    const wf_uuid = e.target.value
    setSelectedWorkflow({ wf_uuid: wf_uuid, wf_name: allWfs[wf_uuid][0].workflow_name })
  };

  const handleSelectModel = (e) => {
    const idx = e.target.value
    setSelectedModel({ model_idx: idx, model_name: getModels(selectedWorkflow.wf_uuid).find((model) => model.model_idx === idx).model_name })
  };

  const handleCompareReset = () => {
    setModelToCompare([])
    setMSState({
      ...MSState,
      modelComparePlotLayout: [],
      modelComparePlotParams: [],
      modelComparePlots: [],
    })
  }

  const getSelectedModel = () => {
    return selectedModel.model_idx !== undefined ? selectedModel.model_idx : ""
  }

  return (
    <div>
      <Typography
        variant="h6"
        style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
      >
        Select models to compare
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ textAlign: "left", marginLeft: 24, color: "#757575" }}
      >
        Select a model and click the shopping cart button to stage.
      </Typography>
      <div style={{ marginLeft: 0, marginTop: 12 }}>
        <LargeTooltip title='Add for comparison' placement='top'>
          <IconButton
            onClick={stageModelForCompare}
            color="primary"
            style={{ marginLeft: 24, marginTop: 20, marginRight: 10 }}
          >
            <AddShoppingCartIcon />
          </IconButton>
        </LargeTooltip>
        <FormControl className={classes.formControl}>
          <InputLabel id="workflow">Workflow</InputLabel>
          <Select
            labelId="workflow"
            value={selectedWorkflow.wf_uuid ? selectedWorkflow.wf_uuid : ""}
            onChange={handleSelectWf}
          >
            {Object.keys(allWfs).map((wf_uuid, i) => {
              return <MenuItem key={i} value={wf_uuid} >{allWfs[wf_uuid][0].workflow_name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="model">Model</InputLabel>
          <Select
            labelId="model"
            value={getSelectedModel()}
            onChange={handleSelectModel}
          >
            {getModels(selectedWorkflow.wf_uuid).map((model, i) => {
              if (model.hide) return <div key={i}></div>
              return <MenuItem key={i} value={model.model_idx} >{model.model_name}</MenuItem>
            })}
          </Select>
        </FormControl>


      </div>
      {modelToCompare.length > 0 &&
        <div>
          <Typography
            variant="h6"
            style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
          >
            Staged models
          </Typography>
          <List style={{ marginTop: 12, marginLeft: 18 }}>
            {modelToCompare.map((model, idx) => {
              return (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <AsteriskIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${model.wf_name}/${model.model_name}`} />
                </ListItem>
              )
            })}
          </List>
          <Button
            disabled={modelToCompare.length < 2}
            onClick={handleCompareModel}
            color="primary"
            variant="contained"
            style={{ marginLeft: 34, marginTop: 24 }}
          >
            Compare
          </Button>
          <Button
            disabled={modelToCompare.length === 0}
            onClick={handleCompareReset}
            color="secondary"
            variant="contained"
            style={{ marginLeft: 24, marginTop: 24 }}
          >
            Reset
          </Button>
        </div>
      }

    </div>
  )
}

export default React.memo(ModelSelector);