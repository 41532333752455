import React, { useMemo, useCallback, useState } from 'react';
import { useTable, usePagination, useFlexLayout, useResizeColumns, useSortBy, useFilters, useRowSelect } from 'react-table';
import { useQueryPagination } from 'API/queryHooks';

import { Button, Typography, IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { defaultColumn, LOADING_ROW }  from '../ReactTable/StyledReactTable';
import { DEFAULT_TABLE_STATE, DEFAULT_PAGE_SIZE }  from '../ReactTable/constants';
import { useColumns, makeQueryFilters, useOnRowSelect } from '../ReactTable/reactTableUtils';
import StyledReactTable from '../ReactTable/StyledReactTable';
import { addSelectColumn } from '../ReactTable/addSelectColumn';
import CustomizeColumnsDialog from '../ReactTable/CustomizeColumnsDialog';
import { addOpenColumn } from '../ReactTable/addOpenColumn';
import { useAppStoreKey } from '../../AppStore';
import { defaultColumns, materialColumns } from './materialsColumns';
import TableButtonGroup from '../General/TableButtonGroup';
import NewButton from '../General/NewButton';

function MaterialsTable({
    onEdit, 
    onNew,
    loading, 
    category,
    selectedOnly,
    hide,
    tags
}){
    const [serializedColumns, setSerializedColumns, columns, columnsLoading] = useColumns("MaterialTableColumns", materialColumns, defaultColumns);
    const [editColumns, setEditColumns] = useState(false);
    const handleColumns = useCallback(() => setEditColumns(true), []);
    const handleCloseColumns = useCallback((cols) => {setEditColumns(false); setSerializedColumns(cols)}, [setSerializedColumns]);
    loading = loading || columnsLoading;

    const [tableState=DEFAULT_TABLE_STATE, setTableState] = useAppStoreKey("materialTable");
    const queryParam = useMemo(() => {
        const tagFilter = tags.length > 0 ? [{
            or: [
                { name: "tagsRel.title", val: tags, op: "ilike" },
            ]
        }] : [];
        const filters = tagFilter.concat(makeQueryFilters(tableState?.filters, columns));

        if (category) filters.push( {name: "categories", val: category, op: "list"} )
        // convert sort and append timeModified
        const sort = (tableState?.sortBy?.map(s => (s.desc ? "-" : "") + s.id) || []);
        if (!sort.find(s => s.endsWith("timeModified"))) sort.push("-timeModified");

        const fields = [];
        if (!!serializedColumns.find(col => col.colId.startsWith("components")) )
            fields.push("components.definition.title", "components.definition.tags", "components.definition.qid", "components.definition.alternateNames");
        if (!!serializedColumns.find(col => col.colId.startsWith("processSteps")) )
            fields.push("processSteps.definition.title", "processSteps.definition.tags", "processSteps.definition.description");
        if (!!serializedColumns.find(col => col.colId.startsWith("properties")) )
            fields.push("properties.definition.title", "properties.definition.tags", "properties.definition.description");
        if (!!serializedColumns.find(col => col.colId.startsWith("sets")) )
            fields.push("sets");
        if (!!serializedColumns.find(col => col.colId.startsWith("tests")) )
            fields.push("tests.instrument.title", "tests.instrument.id", "tests.instrument.description");
        if (!!serializedColumns.find(col => col.colId.startsWith("labs")) )
            fields.push("labs.lab.title");
        return (tableState && {
                Model: "Sample",
                pageNumber: tableState.pageIndex+1, 
                pageSize: tableState.pageSize, 
                filter: filters, 
                sort,
                fields
            });
    }, [category, columns, serializedColumns, tableState, tags]);

    const { data: resolvedData, isLoading, isFetching } = useQueryPagination((tableState.pageIndex || 0) + 1, queryParam, {logName: "Material Table"}, LOADING_ROW);
    const data = useMemo(()=>resolvedData?.data || [], [resolvedData]);

    const handleEdit = useCallback((row) => {
        onEdit([row.original])
    }, [onEdit])

    const table = useTable({
        columns,
        defaultColumn,
        initialState: tableState,
        data,
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        autoResetFilters: false,
        totalRows: resolvedData?.count,
        pageCount: Math.max(Math.ceil((resolvedData?.count || 0) / (tableState?.pageSize || DEFAULT_PAGE_SIZE)), 1),
        getRowId: useCallback((row, relIndex, parent) => parent ? [parent.id, row.id || relIndex] : (row.id || relIndex), []),
        autoResetSelectedRows: false,
        onEdit: handleEdit,
    },
        useFlexLayout,
        useResizeColumns,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        addOpenColumn,
        addSelectColumn,
    );
    const [selected, setSelected] = useState([]); // selected is an array of modified objects that table returns
    const [selectedRows, setSelectedRows] = useState([]); // selectedData is intended to be the same objects as the original data passed in s.t. they can be used interchangeably 

    // Toggle single row
    const onToggleRowSelected = useCallback(() => {
        return table.toggleRowSelected
    }, [table]);

    // Clear row selection from the TableButtonGroup header
    const onClearAllSelectedRows = () => {
        setSelected([]);
        setSelectedRows([]);
        table.toggleAllRowsSelected(false);
    }

    useOnRowSelect({selectedRowIds: table.state.selectedRowIds, toggleRowSelected: onToggleRowSelected, data, selected, setSelected, selectedRows, setSelectedRows, rows: table.rows});

    const {state: {filters}, setAllFilters} = table;
    const handleClearFilters = useCallback(() => {
        setAllFilters && setAllFilters([]);
    }, [setAllFilters]);
    const qidString = selected.map( item => item.qid ).join('&qid=')
    const dashURL = selected?.length !== 0 ? `${process.env.REACT_APP_DASH_FRONTEND_URL}?qid=${qidString}` : "";

    return (
        hide ? null : 
        <div style={{ textAlign: "initial"}}>
            <CustomizeColumnsDialog 
                columnDefinitions={materialColumns} 
                serializedColumns={serializedColumns} 
                defaultColumns={defaultColumns} 
                open={editColumns} 
                onClose={handleCloseColumns}
            />
            <TableButtonGroup
                left={
                    selected?.length > 0 &&
                    <>
                        <Typography variant="subtitle2" color="primary" style={{marginLeft: 8}}>
                            {`${selected.length} Material${selected.length > 1 ? "s" : ""} Selected`}
                        </Typography>
                        <IconButton onClick={onClearAllSelectedRows} size="small">
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </>
                    }
            >
                <Button disabled={!filters || filters.length === 0 } color='primary' variant='text' onClick={handleClearFilters}>
                    Clear Filters
                </Button>
                <Button onClick={handleColumns}>
                    Edit Columns and Filters
                </Button>
                <Button 
                    color='primary'
                    variant='text'
                    disabled={selected.length === 0}
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={dashURL}
                >
                    Compile Test Data
                </Button>
                {selected?.length ? 
                <Tooltip title={selected?.length > 1 ? "Compare selected materials and/or create a new set" : ""}>
                    <Button onClick={() => {setSelected([]); setSelectedRows([]); onEdit(selected);}}>
                        {selected?.length > 1 ? "Compare Materials" : "Open Material"}
                    </Button>
                </Tooltip>
                :
                <NewButton onClick={onNew}>
                    New Material
                </NewButton>
                }
            </TableButtonGroup>
            <StyledReactTable 
                {...table}
                loading={loading || (isLoading && !data?.length)}
                updating={isFetching || (isLoading && data?.length)}
                onChangeParam={setTableState}
                selectedOnly={selectedOnly}
                selectedRows={selectedRows}
                />
        </div>
    );
}
export default React.memo(MaterialsTable);