import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import KRR from "./MLModels/KRR";
import GPR from "./MLModels/GPR";
import NN from "./MLModels/NN";
import PLS from "./MLModels/PLS";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { useAppStoreKey } from "../../../AppStore";
import NextIcon from "mdi-material-ui/ChevronDoubleDown";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
// import RFC from "./MLModels/RFC";
import AutoMLReg from "./MLModels/AutoML";

export default function TrainModelCard2({ nextRef }) {
  const [visibleModelCards, setVisibleModelCards] = useAppStoreKey('MS/Training/ModelCards')

  useEffect(() => {
    if (visibleModelCards === undefined) {
      setVisibleModelCards(["auto_reg", "pls", "gpr"])
    }
  }, [visibleModelCards, setVisibleModelCards])

  const handleAddCard = (key) => {
    setVisibleModelCards([...visibleModelCards, key])
  }

  const handleRemoveCard = (key) => {
    setVisibleModelCards(visibleModelCards.filter((item) => item !== key))
  }

  const funcLists = {
    'Auto ML Regression': { key: "auto_reg", component: <AutoMLReg modelKey={'auto_reg'} handleRemovePlot={handleRemoveCard} /> },
    'Partial Least Square Regression(PLS)': { key: "pls", component: <PLS modelKey={'pls'} handleRemovePlot={handleRemoveCard} /> },
    'Gaussian Process Regression(GPR)': { key: "gpr", component: <GPR modelKey={'gpr'} handleRemovePlot={handleRemoveCard} /> },
    'Kernel Ridge Regression(KRR)': { key: "krr", component: <KRR modelKey={'krr'} handleRemovePlot={handleRemoveCard} /> },
    // 'Support Vector Regression(SVR)': { key: "svr", component: <SVR modelKey={'svr'} handleRemovePlot={handleRemoveCard}/> },
    'Neural Network Regression(NN)': { key: "nn", component: <NN modelKey={'nn'} handleRemovePlot={handleRemoveCard} /> },
    // 'Random Forest Classification(RFC)': { key: "rfc", component: <RFC modelKey={'pls'} handleRemovePlot={handleRemoveCard} /> },
    // TODO: remove this before deploy to prod...
    // 'Advanced Mode': { key: "jtam", component: <JustTrainAModel key={'jtam'} handleRemovePlot={handleRemoveFuncs}/> },
  }

  const handleModelClick = (name) => () => {
    const selectedModelKey = funcLists[name].key
    if (visibleModelCards.includes(selectedModelKey)) {
      handleRemoveCard(selectedModelKey)
    } else {
      handleAddCard(selectedModelKey)
    }
  }

  const buttonStyle = (name) => {
    return {
      margin: 10,
      color: visibleModelCards.includes(funcLists[name].key) ? "#2196f3" : "#bdbdbd"
    }
  }

  const getComponent = (funcKey) => {
    let fullFuncName = Object.keys(funcLists).filter((fullName) => funcLists[fullName].key === funcKey)[0]
    return funcLists[fullFuncName].component
  }
  if (visibleModelCards === undefined) return <></>

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ textAlign: "left", marginTop: 24, marginLeft: 24 }}
        >
          Train ML Model
        </Typography>
        {visibleModelCards?.length > 0 && (
          <Tooltip title={<Typography variant="h6">Next</Typography>} placement="top">
            <IconButton
              onClick={() => nextRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
              color="primary"
              style={{ textAlign: "right", marginTop: 12, marginLeft: 60 }}
            >
              <NextIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Typography
        variant="subtitle1"
        style={{
          textAlign: "left",
          marginLeft: 24,
          marginRight: 24,
          marginBottom: 0
        }}
      >
        Select a ML model and click to add.
      </Typography>
      {Object.keys(funcLists).map(name => (
        <Button
          variant="outlined"
          key={name}
          style={buttonStyle(name)}
          onClick={handleModelClick(name)}
        >
          {name}
        </Button>
      ))}
      <Divider variant="middle" style={{ marginBottom: 12 }} />
      <Grid container>
        {visibleModelCards.map((key) => {
          return (
            <Grid key={key} item xs={6} style={{ padding: 10 }}>
              {getComponent(key)}
            </Grid>
          );
        })
        }
      </Grid>
    </>
  )
}