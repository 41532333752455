import React from 'react'
import { makeStyles } from '@material-ui/core';
import {ResizableBox} from 'react-resizable';

const useStyles = makeStyles(theme =>({
    handle: {
        width: "100%",
        height: "16px",
        background: "repeating-linear-gradient(90deg, #CCC, #CCC 8px, transparent 8px, transparent 16px)",
        backgroundSize: "100% 2px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0px 7px",
        cursor: "s-resize",
    }
}))
/**
 * vertically user resizable element. Will limit height to view height.
 * @param {*} props
 * @param {number | undefined} props.minHeight minimum allowed height
 * @param {number | undefined} props.maxHeight maximum allowed height
 * @param {number} props.height initial height
 * @returns 
 */
export default function StyledResizableBox({children, minHeight = undefined, maxHeight = undefined, height, ...rest}) {

    const classes = useStyles()

    const adjMax = Math.max(maxHeight || (window.innerHeight - 180), minHeight || 50)

    return (
        <ResizableBox 
            handle={<div className={classes.handle}/>} 
            width={Infinity}
            minConstraints={minHeight && [Infinity, minHeight]}
            maxConstraints={[Infinity, adjMax]}
            axis="y"
            height={Math.min(height, adjMax)}
            {...rest}
        >
            {children}
        </ResizableBox>
    )
}