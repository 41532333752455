/**
 * @format
 */
import React, { useMemo, useContext, useEffect } from "react"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import common_styles from "../../styles/common_styles"
import MenuItem from "@material-ui/core/MenuItem"
import FreeSoloSelector from "../General/FreeSoloSelector"
import ManualValueFields from "./ManualValueFields"
import LabSelector from "../General/LabSelector"
import EditCardBase, {
    useCheckReadOnly,
    useDefaultMutateHandlers,
} from "../General/EditCardBase"
import {
    LabToPropertyDefinition,
    PropertyDefinition,
} from "../../schema/models"
import { DefaultLabContext } from "../../DefaultLabProvider"
import ConfirmationDialog, {
    useConfirmDialog,
} from "../General/ConfirmationDialog"
import BufferTextField from "../General/BufferTextField"
import CalculatedFields from "./CalculatedFields"
import { checkLabs } from "utils/invalidators"
import { AccountContext } from "context"
import useFormState from "utils/useFormState/useFormState"
import { value } from "utils/useFormState/useFormStateUtils"

const useStyles = makeStyles(common_styles)

function makeInvalidators() {
    return [
        {
            path: "title",
            validator: title => {
                if (!title || title === "") return "Name required"
            },
        },
        {
            path: "labs",
            validator: checkLabs,
        },
    ]
}

const Model = "PropertyDefinition"

export default function EditProperty({
    initialPropertyDefinition: initialPropertyDefinitionRaw,
    onClose,
}) {
    const classes = useStyles()
    const account = useContext(AccountContext)
    const defaultLab = useContext(DefaultLabContext)
    const initialPropertyDefinition = useMemo(
        () =>
            initialPropertyDefinitionRaw ||
            new PropertyDefinition({
                labs: [
                    new LabToPropertyDefinition({
                        lab: defaultLab,
                        accessLevel: "writer",
                    }),
                ],
            }),
        [defaultLab, initialPropertyDefinitionRaw],
    )
    const invalidators = useMemo(
        () => makeInvalidators(initialPropertyDefinition),
        [initialPropertyDefinition],
    )
    const {
        data: propertyDef,
        status,
        updateData,
        validateAll,
        undoRedo,
    } = useFormState({
        initialState: { data: initialPropertyDefinition },
        validators: invalidators,
    })
    const undo = useMemo(
        () => ({ callback: undoRedo.onUndo, name: undoRedo.undoName }),
        [undoRedo.onUndo, undoRedo.undoName],
    )
    const redo = useMemo(
        () => ({ callback: undoRedo.onRedo, name: undoRedo.redoName }),
        [undoRedo.onRedo, undoRedo.redoName],
    )

    // update lab if default had not loaded
    useEffect(() => {
        if (
            defaultLab &&
            propertyDef.labs?.length === 1 &&
            propertyDef.labs[0]?.lab === undefined
        ) {
            updateData({
                labs: [
                    new LabToPropertyDefinition({
                        lab: defaultLab,
                        accessLevel: "writer",
                    }),
                ],
            })
        }
    }, [defaultLab, propertyDef.labs, updateData])

    const {
        handleDelete,
        handleSave,
        handleCopy,
        handleSaved,
    } = useDefaultMutateHandlers({
        Model,
        onClose,
        initialObject: initialPropertyDefinition,
        object: propertyDef,
        validateAll,
        objectName: "Property Definition",
        onCopy(newProperty) {
            updateData(newProperty, {
                name: `Copy ${initialPropertyDefinition?.title}`,
            })
        },
        updateData,
        account,
    })

    const {
        getConfirmation: confirmSave,
        props: confirmContributorsProps,
    } = useConfirmDialog({
        callback: handleSave,
        title: "Confirm New Contributor",
        message:
            "You do not appear to be a past contributor. Please make sure the owners are aware of any edits you make. Do you wish to be added as a contributor and save these changes?",
        autoConfirm: useMemo(() => {
            if (!propertyDef.id) {
                return true
            }
            if (
                propertyDef.id &&
                propertyDef.contributors?.indexOf(account.name) < 0
            ) {
                return false
            }
            return true
        }, [account.name, propertyDef.contributors, propertyDef.id]),
    })

    const {
        getConfirmation: confirmDelete,
        props: confirmDeleteProps,
    } = useConfirmDialog({
        callback: handleDelete,
        title: "Confirm Delete Property Definition",
        message:
            "Deleting cannot be undone, are you sure you wish to permanently delete this property definition?",
    })

    const readOnly = useCheckReadOnly({
        initialObject: initialPropertyDefinition,
        object: propertyDef,
    })

    return (
        <EditCardBase
            onDelete={confirmDelete}
            onSave={confirmSave}
            onSaved={handleSaved}
            onCopy={handleCopy}
            onClose={onClose}
            editing={!!propertyDef.id}
            title={initialPropertyDefinition.title}
            objectName="Property"
            cardDetail="Define the property below by providing description, data source, and desired values"
            undo={undo}
            redo={redo}
            readOnly={readOnly}
        >
            <ConfirmationDialog {...confirmDeleteProps} />
            <ConfirmationDialog {...confirmContributorsProps} />
            <Typography variant="subtitle2" className={classes.cardSubtitle}>
                Basic Information
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BufferTextField
                        size="small"
                        required
                        defaultValue={propertyDef.title || ""}
                        error={!!status?.invalid?.title?.[value]}
                        helperText={status?.invalid?.title?.[value]}
                        onBlur={ev => {
                            updateData(
                                { title: ev.target.value },
                                { name: "Title Edit" },
                            )
                        }}
                        fullWidth
                        label="Name"
                        placeholder="Enter Property Name"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FreeSoloSelector
                        value={propertyDef.tags || []}
                        onChange={(ev, newVal) =>
                            updateData(
                                { tags: newVal },
                                { name: "Alternate Names Edit" },
                            )
                        }
                        fullWidth
                        label="Alternate Names and Keywords"
                        placeholder={
                            propertyDef?.tags?.length !== 0
                                ? ""
                                : "(eg. Molecular Weight; Equivalent; Amine)"
                        }
                        helperText="Press enter between keywords"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        defaultValue={propertyDef.description || ""}
                        onBlur={ev =>
                            updateData(
                                { description: ev.target.value },
                                { name: "Description Edit" },
                            )
                        }
                        fullWidth
                        label="Description"
                        placeholder="Enter any notes or description about this property"
                        multiline
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        className={classes.cardSubtitle}
                    >
                        Data Information
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        select
                        value={propertyDef.type || "text"}
                        onChange={ev =>
                            updateData(
                                { type: ev.target.value },
                                { name: "Data Type Edit" },
                            )
                        }
                        fullWidth
                        label="Data Type"
                        variant="outlined"
                    >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="number">Numeric</MenuItem>
                        <MenuItem value="categorical">Categorical</MenuItem>
                        <MenuItem value="smiles">SMILES</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        fullWidth
                        select
                        value={propertyDef.source || ""}
                        onChange={ev =>
                            updateData(
                                { source: ev.target.value },
                                { name: "Source Edit" },
                            )
                        }
                        label="Data Source"
                        variant="outlined"
                    >
                        <MenuItem value="mm">
                            Manual Entry in Materials Manager
                        </MenuItem>
                        <MenuItem value="cv">Calculated Value</MenuItem>
                    </TextField>
                </Grid>
                {(propertyDef.source === "mm" && (
                    <ManualValueFields
                        propertyDefinition={propertyDef}
                        onChange={updateData}
                    />
                )) ||
                    (propertyDef.source === "cv" && (
                        <CalculatedFields
                            propertyDefinition={propertyDef}
                            onChange={updateData}
                        />
                    ))}
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        className={classes.cardSubtitle}
                    >
                        Access Permissions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LabSelector
                        required
                        error={!!status?.invalid?.labs?.[value]}
                        helperText={status?.invalid?.labs?.[value]}
                        selected={propertyDef.labs || []}
                        onSelect={labs => {
                            updateData({ labs }, { name: "Labs Edit" })
                        }}
                        variant="outlined"
                        Constructor={LabToPropertyDefinition}
                    />
                </Grid>
            </Grid>
        </EditCardBase>
    )
}
