import { aichemyProtoAxios } from "../../../API/mmAxios";
import { timeToExpire } from "../utils";

export const GetModelList = (setCurrentModels, setIsLoading) => {
    let url = `workflow`
    let config = {
        headers: { "Content-Type": "application/json; charset=utf-8" },
    }
    aichemyProtoAxios.get(url, config)
        .then(res => {
            let all_wf = res.data
            let newTableData = []
            let lastSavedWf
            let displayWf = []
            all_wf.forEach((wf) => {
                if (wf.expires) {
                    if (lastSavedWf) {
                        if (lastSavedWf.expires < wf.expires) {
                            lastSavedWf = wf
                        }
                    } else {
                        lastSavedWf = wf
                    }
                } else {
                    displayWf.push(wf)
                }
            })
            if (lastSavedWf) {
              displayWf.splice(0, 0, lastSavedWf)
            }
            displayWf.forEach((wf) => {
                newTableData.push({
                    model_title: wf.title,
                    model_description: wf.description ? wf.description : "",
                    model_role: wf.role,
                    creation_time: wf.create_time,
                    update_time: wf.update_time,
                    workflow_id: wf.workflow_id,
                    expires: wf.expires? timeToExpire(wf.expires) : "",
                })
            })
            setCurrentModels(formatAIChemyModelList(newTableData))
            setIsLoading(false)
        }).catch(e => {
            console.error(e)
            return []
        })

}

const formatAIChemyModelList = (models) => {
    let return_models = [];
    models.forEach(function (model) {
        model['post_id'] = model['id'];
        model['id'] = model['train_id'];
        return_models.push(model);
    });
    return return_models;
}