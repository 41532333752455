import React, { useCallback, useEffect, useRef, useState, useMemo } from "react"
import Plot from "react-plotly.js";
import IconButton from "@material-ui/core/IconButton";
import { GetSavePlotFunc, plotHeightMapper, plotSizeMapper } from "./utils";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles"
import common_styles from "../../../styles/common_styles"
import { useAppStoreKey } from "../../../AppStore";
import SizeSIcon from "mdi-material-ui/SizeS";
import SizeMIcon from "mdi-material-ui/SizeM";
import SizeLIcon from "mdi-material-ui/SizeL";

import ArrowCollapseIcon from "mdi-material-ui/ArrowCollapse";
import ArrowExpandIcon from "mdi-material-ui/ArrowExpand";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress, Paper } from "@material-ui/core";

import PlotDownload from './PlotDownload.js';
import ColumnSelectField from "./ColumnSelectField";
import SaveIcon from "@material-ui/icons/Save";
import { objectEquals } from "../utils";

const useStyles = makeStyles(common_styles);

function PlotCard({ data, getTrace, getLayout, savedParams, paramsToSave, plotWidthSize, setPlotWidthSize,
  getForm, getIcon, cardName, visible, plotType, plot_uuid,
  idx, handleRemovePlot, onSheetChange, currentSheet, setCurrentSheet, isLoading = false }) {
  const classes = useStyles();
  const [sheets, setSheets] = useState([])
  const [MSState, setMSState] = useAppStoreKey('MixingStudioState')
  const [workflow, ] = useAppStoreKey('Workflow')
  const [uuid, setUuid] = useState(plot_uuid)
  const parentRef = useRef(null);

  const handleSizeChange = (event, newWidthSize) => {
    if (newWidthSize === null) {
      return
    }
    setPlotWidthSize(newWidthSize)
    if (newWidthSize === 'hide') {

    }
    setPlotWidthSize(newWidthSize)
    setMSState({
      ...MSState,
      plotLayout: MSState.plotLayout.map((plot, i) => {
        if (i === idx) {
          return { ...plot, w: plotSizeMapper[newWidthSize] }
        }
        return plot;
      })
    })
  }

  const plotColumns = useMemo(() => {
    const cols = plotSizeMapper[plotWidthSize]
    return cols === undefined ? 12 : cols
  }, [plotWidthSize])

  const handleHidePlot = () => {
    if (MSState.plotParams[idx].expand) {
      // plot is expanded, hide it
      setMSState({
        ...MSState,
        plotLayout: MSState.plotLayout.map((plot, i) => {
          if (i === idx) {
            return { ...plot, h: 4, minH: 4, maxH: 4, w: 3, maxW: 3, minW: 3 };
          }
          return plot;
        }),
        plotParams: MSState.plotParams.map((plot, i) => {
          if (i === idx) {
            return { ...plot, expand: false };
          }
          return plot;
        })
      })
    } else {
      // plot is collapsed, open it
      setMSState({
        ...MSState,
        plotLayout: MSState.plotLayout.map((plot, i) => {
          if (i === idx) {
            const funcKey = MSState.plotCurrentList[i].split('_')[0]
            const height = plotHeightMapper[funcKey]
            return { ...plot, h: height, minH: height, maxH: height, w: 6, maxW: undefined, minW: undefined };
          }
          return plot;
        }),
        plotParams: MSState.plotParams.map((plot, i) => {
          if (i === idx) {
            return { ...plot, expand: true };
          }
          return plot;
        })
      })
    }
  }

  const handleSheetChange = useCallback((selectedSheet) => {
    if (!data[selectedSheet]) return
    if (onSheetChange) {
      const currentHeaders = data[selectedSheet].columns
      const currentData = data[selectedSheet].data
      const currentPlotData = {}
      // add headers
      currentHeaders.map(header => currentPlotData[header] = [])
      // add data
      currentData.forEach((row) => {
        row.forEach((value, idx) => {
          currentPlotData[currentHeaders[idx]].push(value)
        })
      })
      onSheetChange(currentHeaders, currentPlotData)
    }
    if (setCurrentSheet) {
      setCurrentSheet(selectedSheet)
    }
  }, [data, onSheetChange]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const sheets = Object.keys(data)
      setSheets(sheets)
      handleSheetChange(sheets[0])
    }
  }, [data, handleSheetChange])

  const plotSizeButton = () => {
    if (!savedParams && !MSState.plotParams[idx].expand) { return }

    return (
      <ToggleButtonGroup
        value={plotWidthSize}
        exclusive
        onChange={handleSizeChange}
        aria-label="text alignment"
      >
        <ToggleButton value="s" aria-label="left aligned">
          <SizeSIcon />
        </ToggleButton>
        <ToggleButton value="m" aria-label="centered">
          <SizeMIcon />
        </ToggleButton>
        <ToggleButton value="l" aria-label="right aligned">
          <SizeLIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }

  let handleSavePlot
  if (savedParams) {
    handleSavePlot = GetSavePlotFunc(paramsToSave, savedParams, plotType, idx, uuid, setUuid, null)
  } else {
    handleSavePlot = GetSavePlotFunc(paramsToSave, savedParams, plotType, idx, uuid, setUuid, handleRemovePlot)
  }

  const isChanged = () => {
    if (uuid === '') return true
    else {
      for (let i=0; i<workflow.viz_plot_params.length; i++) {
        const plot = workflow.viz_plot_params[i]
        if (plot.uuid === uuid ) {
          if (!objectEquals(plot.params, paramsToSave)) {
            return true
          }
        }
      }
      return false
  }
  }


  return (
    <Grid item sm={plotColumns}>
      <Paper variant="outlined" style={{margin: 24}}>
          <div style={{ display: "flex", flexDirection: "row", margin: 24 }}>
            {getIcon && getIcon()}
            <Typography variant="subtitle1" style={{ textAlign: "left", marginTop: 12, marginLeft: 12, marginRight: 12 }}>
              {cardName}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}>
              <PlotDownload
                visible={visible}
                getTrace={getTrace}
                plotType={cardName}
              />
              {plotSizeButton()}
              {!savedParams && <IconButton
              color="primary"
              onClick={() => {
                handleHidePlot(idx);
                }}
              >
                {MSState.plotParams[idx].expand ? <ArrowCollapseIcon /> : <ArrowExpandIcon />}
              </IconButton>}
            {<IconButton
              color="secondary"
              onClick={() => {
                handleSavePlot(idx);
              }}
              disabled={!isChanged()}
            >
              <SaveIcon />
            </IconButton>}
              <IconButton
                color="secondary"
                onClick={() => {
                  handleRemovePlot(idx)
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {(savedParams || MSState.plotParams[idx].expand) &&
            <div style={{marginLeft: 24, marginRight: 24}}>
              {sheets.length !== 0 && (
              <ColumnSelectField
                label="Sheet"
                value={currentSheet}
                setValue={handleSheetChange}
                headers={sheets}
              />
            )}
            {getForm && getForm()}
            {isLoading ? <CircularProgress
              size={200}
              style={{ marginLeft: 245, marginTop: 100 }}
              color={'primary'}
            /> :
              <div className={classes.plot} ref={parentRef}>
                <Plot data={getTrace()} layout={getLayout()} style={{ alignItems: "inherit" }} />
              </div>
            }
          </div>
        }
    </Paper></Grid>
  )
}

export default React.memo(PlotCard);