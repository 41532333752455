import React, { useCallback } from 'react';
import { useParams } from '@reach/router';

import { Grid, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import common_styles from '../../styles/common_styles';
import { useQueryFCSingle } from '../../API/queryHooks';
import DialogWithLoading from '../General/DialogWithLoading';
import InstrumentsTable from './InstrumentsTable';
import EditInstrumentsCard from './EditInstrumentsCard';
import LearnMore, { LearnMoreEnum } from '../General/LearnMore';


const INSTRUMENT_LIBRARY_TITLE = "Instrument Library";
const INSTRUMENT_LIBRARY_INFO = "An Instrument is used to represent the equipment and/or setup used" +
    " in material testing processes, and trace the origin of test results from one or more physical " +
    "tests on a Sample or Material. This is an advanced feature for experienced Users and will " +
    "require contact with the Skylab team directly. ";

const useStyles = makeStyles(common_styles);
function InstrumentsLibrary({ navigate }) {
    const classes = useStyles();
    const { instrumentId } = useParams();

    const { data: instrument, isFetching } = useQueryFCSingle((instrumentId !== "" && instrumentId !== "new") ? {
        Model: "Instrument",
        filter: instrumentId,
        fields: ["labs"]
    } : undefined, { logName: "Full Instrument Info" });


    const handleNew = useCallback(() => navigate("new"), [navigate]);
    const handleEdit = useCallback(row => navigate(row.original.id), [navigate]);
    const handleCloseEdit = useCallback(() => navigate(""), [navigate]);
    const idMatch = instrumentId === instrument?.id || (instrumentId === "new" && !instrument?.id)
    const openEdit = instrumentId !== "" && (instrumentId === "new" || instrumentId || isFetching);

    return (
        <div className={classes.layout}>
            <DialogWithLoading onClose={handleCloseEdit} open={openEdit} loading={isFetching && !instrument}>
                <EditInstrumentsCard initialInstr={idMatch ? instrument : undefined} onClose={handleCloseEdit} />
            </DialogWithLoading>

            <Card className={classes.paperBody} elevation={3}>
                <div>
                    <Typography className={classes.cardTitle} gutterBottom>
                        {INSTRUMENT_LIBRARY_TITLE}
                    </Typography>
                    <Typography className={classes.cardBodyTextEnhanced}>
                        {INSTRUMENT_LIBRARY_INFO}
                        <LearnMore model={LearnMoreEnum.instrument} />
                    </Typography>
                </div>
                <Grid container justify="flex-start" alignItems="flex-end" spacing={3}>
                    <Grid item xs={12}>
                        <InstrumentsTable
                            onEdit={handleEdit}
                            onNew={handleNew}
                        />
                    </Grid>
                </Grid>
            </Card>

        </div >
    );
}

export default React.memo(InstrumentsLibrary);