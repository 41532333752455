import { LinearProgress, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useQueryFC } from "API/queryHooks";
import SmilesSVG from "components/General/SmilesSVG";
import { useMemo } from "react"
import commonProperties from "../PropertyLibrary/commonProperties";

const properties = [commonProperties.molecularWeight, commonProperties.smiles, commonProperties.casId, commonProperties.pubChemId, commonProperties._3MId]

const useStyles = makeStyles(theme => ({

    container: {
        display: "flex",
        flexDirection: "row",
    },
    info: {
        display: "flex",
        flexDirection: "column",
        width: "400px"
    },
    altNames: {
        marginBottom: theme.spacing(1),
        maxHeight: "60px",
        overflow: "hidden"
    },
    item: {
        marginBotton: theme.spacing(0.5),
    },
    empty: {
        color: theme.palette.text.secondary,
    },
    smilesSVG: {
        width: "150px",
    },
    progress: {
        width: "400px",
        padding: theme.spacing(2)
    },
}))

export default function MaterialSummaryCard({ id, material }) {

    const classes = useStyles()
    const theme = useTheme()

    const query = useMemo(() => (!id || !!material) ? undefined : {
        Model: "Sample",
        fields: [{
            name: "properties",
            fields: ["definition"],
            filter: {
                name: "definition.title",
                val: properties,
                op: "in_"
            }
        }],
        filter: id
    }, [id, material])
    const {data: latestData, isFetching} = useQueryFC(query, {logName: "Material Card Query"})
    const sample = useMemo(() => material || (latestData?.data && latestData?.data[0]) || {}, [material, latestData])

    const cas = sample.properties?.find(property => property.definition?.title === commonProperties.casId)
    const pubChem = sample.properties?.find(property => property.definition?.title === commonProperties.pubChemId)
    const _3MId = sample.properties?.find(property => property.definition?.title === commonProperties._3MId)
    const molecularWeight = sample.properties?.find(property => property.definition?.title === commonProperties.molecularWeight)
    const smiles = sample.properties?.find(property => property.definition?.title === commonProperties.smiles)

    return (sample && !isFetching) ? (
        <div className={classes.container}>
            <div className={classes.info}>
                <Typography variant="subtitle1" color="primary" >
                    {sample.title}
                </Typography>
                <Typography className={classes.altNames} variant="caption" >
                    {sample.alternateNames ? sample.alternateNames.replace(/\n/g, " | ") : "No alternate names listed"}
                </Typography>
                <Property classes={classes} name="CAS" data={cas?.data} />
                <Property classes={classes} name="PubChem" data={pubChem?.data} />
                <Property classes={classes} name="3M RM ID" data={_3MId?.data} />
                <Property classes={classes} name="Mol Weight" data={molecularWeight?.data} />
            </div>
            {
                smiles && 
                <div className={classes.smilesSVG}>
                    <SmilesSVG
                        smiles={smiles.data}
                        theme={
                            theme.palette.type === "dark" ? "dark" : "light"
                        }
                    />
                </div>
            }
        </div>

    ) : (
        <div className={classes.progress}>
            <LinearProgress />
        </div>
    )
}

function Property({classes, data, name}) {
    return (
        <Typography className={classes.item} variant="body2" >
            <span>{`${name}: `}</span>
            {data ? `${data}` : <span className={classes.empty}>N/A</span>}
        </Typography>
    )
}