import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import Plot from 'react-plotly.js';

import { LinearProgress, Typography } from '@material-ui/core';

import { MapPlotsToCorrectPlot, MapLayoutToCorrectLayout, PlotsToLists } from './LayoutBuilder';
import { imageSizeTopLevel } from './MenuBuilder';
import { MessageTranslator } from './ResponseHandler';
import { mbxAxios } from "API/mmAxios";


//change base url to MBX where we have gzip encoding for the plot object
const BASE_URL = process.env.REACT_APP_MBX_API_URL
const IMAGE_HEIGHT = imageSizeTopLevel.height;
const IMAGE_WIDTH = imageSizeTopLevel.width;
const TRANSPARENT = "#00000000";
const GRAY = "gray";
const LEGEND_DESCENT_STEP = 0.04;
const LEGEND_DESCENT_OFFSET = -0.25;
const PLOT_HEIGHT_BASE = 800;
const PLOT_HEIGHT_STEP = 25;

const getCarouselConfigProps = () => ({
    showThumbs: false
})

/* ***************************************************************** */
/* MULTI-PLOTTING - [Recursively] render plot content                */
/*                                                                   */
/* For more details, checkout the markdown file in linked directory. */
/* ***************************************************************** */

export default class TestMultiPlot extends React.Component {
    /*
        Render a plot object or image gallery object for multiplotting. The input to this class is
        list of test IDs to group together in a single multiplot.

        ASSUMPTIONS:
            * list of IDs passed are all of like content (same tabular content that can be plotted
               on the same plot together.) This is derived by filtering on etly processor.
            * image content is flagged as an image in response (default for image ingestion)
    
        Returns:
            plotholder - an plotly.js plot of multiple variables and data sets or an image gallery
                            component with at least one image.
    */

    constructor(props) {
        super(props);
        this.state = {
            plotIsLoading: false,
            tempPlotData: [],
            plotData: [],
            tempPlotLayout: [],
            tempKeyIndex: {},
            accumulatedKeySet: {},
            plotLayout: [],
            plotFrames: [],
            correctedLayout: [],
            accumlatedLayoutKeySet: {},
            plotLength: [0, 0],
            plotStatus: 'init-normal',
            titles: "",
            error: false,
            labeledErrors: [],
            typeOfErrors: [],
            imageUrl: [],
            imageTitle: [],
            type: 'plot',
            itemsReceived: 0,
            plotConfig: { "editable": true, "responsive": true }
        };
    }

    componentDidMount() {

        this.setState({ plotIsLoading: true, titles: this.props.title });
        let url_plot;
        let currentPlotData, currentPlotLayout, currentKeyIndex;

        this.props.selectedIds.forEach((recordId, i) => {
            // All plot or image content can be retrieved by the /plot endpoint using the uuid.
            url_plot = BASE_URL + `${this.props.resource}/${recordId}/plot`
            mbxAxios.get(url_plot)
                .then(response => {

                    // Try and determine if plots can be linked via index ("old way") or if need to
                    // be linked by keys (in the case where resources are inconsistent).
                    let status = PlotStateCheck(this.state, response);

                    this.setState({
                        tempPlotData: [],
                        tempPlotLayout: [],
                        tempKeyIndex: {},
                        tempKeyLayoutIndex: {},
                        plotStatus: status
                    })

                    if (response.headers['content-type'].startsWith('image')) {
                        // Image content, stored in the form of a list of objects with 'url' key.
                        let titleArray = response.data.split('raw/')[1].split('?');
                        let parsedText = titleArray[0].replace(/%20/g, ' ');
                        parsedText = parsedText.replace(/%E2%80%99/g, "'");
                        parsedText = parsedText.replace(/%2C/g, ",");
                        let newImage = { 'url': response.data };
                        this.setState({
                            type: 'image',
                            imageUrl: [...this.state.imageUrl, newImage],
                            imageTitle: [...this.state.imageTitle, parsedText],
                            plotIsLoading: false
                        })

                    }

                    else {

                        // Default for plotting content; pull out plot data from layout object.
                        let returnedInfo = CreateKeyObject(response);
                        let latestInfo = returnedInfo[1];
                        currentKeyIndex = returnedInfo[0];
                        let numPlots = [0, response.data.length];

                        for (var a = 0; a < response.data.length; a++) {
                            currentPlotData = response.data[a].data[0]
                            currentPlotLayout = response.data[a].layout

                            if (currentPlotLayout !== undefined) {
                                if (currentPlotLayout.title !== undefined) {
                                    currentPlotData.name = currentPlotLayout.title.text;
                                } else if (currentPlotLayout.name !== undefined) {
                                    currentPlotData.name = currentPlotLayout.name;
                                } else {
                                    currentPlotData.name = "";
                                }

                                if (response.data[a].plot_title !== undefined) {
                                    currentPlotLayout.title.text = response.data[a].plot_title;
                                    currentPlotData['plot_title'] = response.data[a].plot_title;
                                }

                                // Modify chart colors for dark mode
                                response.data[a].layout["plot_bgcolor"] = TRANSPARENT;
                                response.data[a].layout["paper_bgcolor"] = TRANSPARENT;
                                response.data[a].layout["xaxis"]["showgrid"] = false;
                                response.data[a].layout["yaxis"]["showgrid"] = false;
                                response.data[a].layout["fig_bgcolor"] = TRANSPARENT;
                                response.data[a].layout["font"] = { color: GRAY };


                                this.setState({
                                    tempPlotData: [...this.state.tempPlotData, currentPlotData],
                                    tempPlotLayout: [...this.state.tempPlotLayout, currentPlotLayout],
                                    tempKeyIndex: currentKeyIndex,
                                    tempKeyLayoutIndex: currentKeyIndex,
                                    latestInfo: latestInfo
                                })

                                numPlots[0] += 1;

                            } else {
                                // Hybrid case: resources contain images AND plots
                                let newImage = {
                                    'url': `data:${response.data[a].media};base64,${response.data[a].data}`
                                };
                                let parsedText = response.data[a].name;
                                this.setState({
                                    type: 'hybrid',
                                    imageUrl: [...this.state.imageUrl, newImage],
                                    imageTitle: [...this.state.imageTitle, parsedText]
                                })
                            }
                        }

                        // These functions handle multi-resourced tests (tests with more than one
                        // plot object, and properly map them as desired.)
                        let returnedContent = MapPlotsToCorrectPlot(this.state);
                        let newPlot = returnedContent[0];
                        let newKeySet = returnedContent[1];

                        let newLayoutObj = MapLayoutToCorrectLayout(this.state);
                        let newLayout = newLayoutObj[0];

                        // Modify chart colors to support dark mode
                        newLayout[0]["plot_bgcolor"] = TRANSPARENT;
                        newLayout[0]["paper_bgcolor"] = TRANSPARENT;
                        newLayout[0]["fig_bgcolor"] = TRANSPARENT;
                        newLayout[0]["xaxis"]["showgrid"] = false;
                        newLayout[0]["yaxis"]["showgrid"] = false;
                        newLayout[0]["font"] = { color: GRAY };

                        // We want to make sure we are putting the legend content beneath each plot.
                        // We also want to make sure the plot size doesn't shrink with each addition
                        // of a legend name. We are adding the size as such.
                        for (let q = 0; q < numPlots[0]; q++) {
                            newLayout[q]["legend"] = newLayout[q]["legend"] ? 
                                { 
                                    x: newLayout[q]["legend"]["x"],
                                    y: newLayout[q]["legend"]["y"] - LEGEND_DESCENT_STEP
                                } : 
                                    { x: 0, y: LEGEND_DESCENT_OFFSET };
                            newLayout[q]["height"] = newLayout[q]["height"] ? 
                                newLayout[q]["height"] + PLOT_HEIGHT_STEP : PLOT_HEIGHT_BASE;
                        }

                        let newLayoutKeySet = newLayoutObj[1];
                        let plots = PlotsToLists(newPlot);

                        this.setState({
                            plotData: plots,
                            plotLayout: newLayout,
                            plotIsLoading: false,
                            accumulatedKeySet: newKeySet,
                            accumlatedLayoutKeySet: newLayoutKeySet,
                            plotLength: numPlots
                        })
                    }

                })
                .catch((error) => {
                    // Capture errors and display them to the user. Typically they are failed uploads
                    // and missing resources.
                    console.log("Catch error axios TestMultiPlot: ", error, error.response);
                    console.log(error.response);
                    //TODO Fix this
                    let plot_id = error.response ? error.response.config.url.split('/') : 0;
                    plot_id = plot_id[plot_id.length - 2];
                    let newLabeledErrors = [...this.state.labeledErrors, plot_id];
                    let newErrorType = MessageTranslator(error.response ? error.response.status : "");

                    this.setState({
                        error: false,
                        plotIsLoading: false,
                        labeledErrors: newLabeledErrors,
                        typeOfErrors: newErrorType
                    })
                });
        })
    }

    render() {
        let plotHolder;
        let errorHolder = <div></div>
        if (this.state.labeledErrors.length > 0) {
            errorHolder = <div>
                <p />
                <div>
                    Test IDs that failed to load:
                    <p />
                    <ul>
                        {this.state.labeledErrors.map((err, le) => (
                            <li key={le}>{err}{this.state.typeOfErrors[le]}</li>
                        ))}
                    </ul>
                </div>
            </div>
        }

        if (this.state.error) {
            plotHolder = <div style={{ fontWeight: "bold", fontSize: 15 }}>
                <br />No Results Found<br /></div>;

        } else {
            if (this.state.plotIsLoading) {
                plotHolder =
                    <div style={{ textAlign: "center" }}>
                        <Typography>{`Loading ${this.props.title}`}</Typography>
                        <LinearProgress />
                        <br />
                    </div>
            }

            else {
                if (this.state.type === 'image') {
                    plotHolder = <div>
                        <div style={{ 'height': IMAGE_HEIGHT + 150 + 15 * this.state.imageTitle.length }}>
                            <Carousel {...getCarouselConfigProps()}>
                                {this.state.imageUrl.map((image, im) =>
                                    <div key={im}>
                                        <img
                                            src={image.url}
                                            key={im}
                                            alt={this.state.imageTitle[im]}
                                            width={IMAGE_WIDTH}
                                            height={IMAGE_HEIGHT} />
                                        <p>{this.state.imageTitle[im]}</p>
                                    </div>
                                )}
                            </Carousel>
                            <br />
                            {/* <ol>
                                {this.state.imageTitle.map((title, j) =>
                                    <li key={j}>{title}</li>
                                )}
                            </ol> */}
                        </div>
                        {errorHolder}
                    </div>

                } else if (this.state.type === 'hybrid') {
                    plotHolder = <div>
                        <div style={{ 'height': IMAGE_HEIGHT + 150 + 15 * this.state.imageTitle.length }}>

                            <Carousel {...getCarouselConfigProps()}>
                                {this.state.imageUrl.map((image, im) =>
                                    <div key={im}>
                                        <img
                                            src={image.url}
                                            key={im}
                                            alt={this.state.imageTitle[im]}
                                            width={IMAGE_WIDTH}
                                            height={IMAGE_HEIGHT} />
                                        <p>{this.state.imageTitle[im]}</p>
                                    </div>
                                )}
                            </Carousel>
                            <br />
                            {/* <ol>
                                {this.state.imageTitle.map((title, k) =>
                                    <li key={k}>{title}</li>
                                )}
                            </ol> */}

                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            {this.state.plotData.map((plot, ind) => (
                                <div style={{ margin: 20 }} key={ind}>
                                    <Plot
                                        data={plot}
                                        layout={this.state.plotLayout[ind]}
                                        frames={this.state.plotFrames}
                                        onInitialized={(figure) => this.setState(figure)}
                                        onUpdate={(figure) => this.setState(figure)}
                                        config={this.state.plotConfig}
                                    />
                                    <br />
                                </div>
                            ))}
                        </div>
                        {errorHolder}
                    </div>

                } else {
                    plotHolder = <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {this.state.plotData.map((plot, ind) => (
                            <div style={{ margin: 20 }} key={ind}>
                                <Plot
                                    data={plot}
                                    layout={this.state.plotLayout[ind]}
                                    frames={this.state.plotFrames}
                                    onInitialized={(figure) => this.setState(figure)}
                                    onUpdate={(figure) => this.setState(figure)}
                                    config={this.state.plotConfig}
                                    key={ind}
                                />
                                <br />
                            </div>
                        ))}
                    </div>
                }
            }
        }
        return (
            <div>{plotHolder}</div>
        );
    }
}


function PlotStateCheck(state, responseData) {
    // Try and determine if plots can be linked via index ("old way") or if need to
    // be linked by keys (in the case where resources are inconsistent).
    let status;
    if (responseData.headers['content-type'].startsWith('image')) {
        status = 'array-map';
    }

    else if (state.plotLength[0] === 0) {
        status = 'array-map';
    }

    else {
        // Default will be key-map now.
        status = 'key-map';
    }

    return status;
}


const CreateKeyObject = (responseData) => {
    // Generate a key object (similar to "keyIndex" or "accumulatedKeySet").
    let newObj = {};
    let latestInfo = 'many';
    let returnComponents;

    newObj = {};
    responseData.data.forEach((obj, i) => {
        let currentPlotLayout = obj.layout;
        let currentPlotData = obj.data[0];

        if (obj.plot_title !== undefined) {
            newObj[obj.plot_title] = i;
            newObj[i.toString()] = obj.plot_title;
        }
        else {
            if (currentPlotLayout !== undefined) {
                if (currentPlotLayout.title !== undefined) {
                    currentPlotData.name = currentPlotLayout.title.text;
                } else if (currentPlotLayout.name !== undefined) {
                    currentPlotData.name = currentPlotLayout.name;
                } else {
                    currentPlotData.name = "";
                }

                newObj[currentPlotData.name] = i;
                newObj[i.toString()] = currentPlotData.name;
            }
        }
    })

    returnComponents = [newObj, latestInfo];
    return returnComponents;
}