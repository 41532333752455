import { createTheme } from '@material-ui/core/styles';

// Main application theme
export const themeBase = {
    palette: {
        secondary: {
            dark: '#aa2e25',
            main: '#f44336',
            light: '#f6685e',
            contrastText: '#ffffff',
        },
        primary: {
            light: '#4dabf5',
            main: '#2196f3',
            dark: '#1769aa',
            contrastText: '#ffffff',
        },
        black:
        {
            light: 'black',
            main: 'black',
            dark: 'black',
        },
        material: {
          light: "#4caf50",
          main: "#43a047",
          dark: "#2E7D31",
          contrastText: '#ffffff',
        },
        process: {
          light: '#4dabf5',
          main: '#2196f3',
          dark: '#1769aa',
          contrastText: '#ffffff',
        },
        label: {
          light: '#ffab91',
          main: '#f4511e',
          dark: '#d84315',
          contrastText: '#ffffff',
        },
        output: {
          light: '#ffab91',
          main: '#f4511e',
          dark: '#d84315',
          contrastText: '#ffffff',
        },
        property: {
          light: '#ffab91',
          main: '#f4511e',
          dark: '#d84315',
          contrastText: '#ffffff',
        },
        selected: {
          light: '#ffef62',
          main: '#ffeb3b',
          dark: 'b2a429',
          contrast: `#000000`
        }
    },
};
const theme = createTheme(themeBase)
export default theme;

export const darkTheme = createTheme({...themeBase, palette: {...themeBase.palette, type: "dark"}})

