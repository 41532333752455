import React, { useCallback, useEffect, useState } from "react";
import { GetRemovePlotFunc, InitVizParams, plotlyColorSchemeRGBA } from "./utils";
import { ChartBox } from "mdi-material-ui";
import PlotCard from "./PlotCard";
import ColumnMultiSelectField from "./ColumnMultiSelectField";

function BoxPlot({ data, handleRemovePlot, idx, visible, savedParams='', plot_uuid }) {
  const [headers, setHeaders] = useState([])
  const [plotData, setPlotData] = useState({})
  const [selectedAxis, setSelectedAxis] = InitVizParams(savedParams,'selectedAxis', [])
  const [currentSheet, setCurrentSheet] = InitVizParams(savedParams, 'currentSheet', '')
  const [plotWidthSize, setPlotWidthSize] = InitVizParams(savedParams, 'plotWidthSize','m')
  const [paramsToSave, setParamsToSave] = useState({})
  const plotType = 'box'
  const cardName = 'Box Plot'

  const handleRemoveCurrentPlot = GetRemovePlotFunc(savedParams, handleRemovePlot)

  // update params to save
  useEffect(() => {
    setParamsToSave({
      ...paramsToSave,
      selectedAxis: selectedAxis,
      currentSheet: currentSheet,
      plotWidthSize: plotWidthSize
    })
  }, [selectedAxis, currentSheet, plotWidthSize]) // eslint-disable-line react-hooks/exhaustive-deps

  const getForm = () => {

    return (
      <ColumnMultiSelectField
        label={"Headers"}
        value={selectedAxis}
        setValue={setSelectedAxis}
        headers={headers}
      />
    )
  }
  const getIcon = () => {
    return <ChartBox style={{ marginTop: 14 }} />
  }
  const getTrace = () => {
    const trace = []
    selectedAxis.forEach((axis, idx) => {
      const x = plotData[axis]
      if (!x) return []
      const newTrace = {
        x: x,
        type: 'box',
        name: axis,
        boxpoints: 'all',
        jitter: 0.3,
        pointpos: 1.3,
        marker: {
          color: plotlyColorSchemeRGBA(idx, 1)
        }
      }
      trace.push(newTrace)
    })
    return trace
  }
  const getLayout = () => {
    return {
      autosize: true,
      title: "Box plot of " + selectedAxis,
      yaxis: { visible: false }
    }
  }

  const onSheetChange = useCallback((currentHeaders, currentData) => {
    setHeaders(currentHeaders)
    setPlotData(currentData)
    if (setSelectedAxis && selectedAxis.length === 0) {
      setSelectedAxis([currentHeaders[0]])
    }
  }, [setHeaders, setPlotData, setSelectedAxis]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PlotCard
      plot_uuid={plot_uuid}
      data={data}
      getTrace={getTrace}
      getLayout={getLayout}
      getForm={getForm}
      getIcon={getIcon}
      cardName={cardName}
      savedParams={savedParams}
      plotWidthSize={plotWidthSize}
      setPlotWidthSize={setPlotWidthSize}
      currentSheet={currentSheet}
      setCurrentSheet={setCurrentSheet}
      paramsToSave={paramsToSave}
      plotType={plotType}
      setParamsToSave={setParamsToSave}
      idx={idx}
      handleRemovePlot={handleRemoveCurrentPlot}
      onSheetChange={onSheetChange}
      visible={visible}
    />
  )
}

export default React.memo(BoxPlot);