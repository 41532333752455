/**
 * @format
 */
import React, { useMemo, useCallback, useEffect, useState } from "react"

import { makeStyles, Tooltip } from "@material-ui/core"
import WrapIcon from "@material-ui/icons/WrapText"
import TransposeIcon from "mdi-material-ui/RotateLeftVariant"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

import StyledHoTRaw from "../HoT/StyledHoT"
import TableButtonGroup from "components/General/TableButtonGroup"
// import SmallErrorHoover from "components/Alert/SmallErrorHoover.js"
import {
    Column,
    NodeAliases,
    NodeDefinitions,
    ProcessChart,
    ProcessDefinition,
    ProcessInstance,
    ProcessNodes,
    PropertyDefinition,
    PropertyInstance,
    Sample,
    SampleInstance,
    SampleSet,
} from "schema/models"
import {
    getDefinition,
    getDefKey,
    getProcessChartDefinition,
} from "components/FlowChart/processChartUtils"
import { assumeType } from "schema/schemaUtils"
import {
    applyNodeAlias,
    calculateTotal,
    checkEmptySample,
} from "utils/schemaUtils"
import {
    ColumnInfo,
    DataChange,
    HeaderChange,
    Options,
    useStyledHoT,
} from "components/HoT/HoTUtils"
import { sortTextWithPostfixNumber } from "utils/utils"
import { buildDeleteRenderer } from "components/HoT/DeleteRenderer"
import ExpandingMenuButton, {
    ExpandingMenuButtonTypes,
} from "./ExpandingMenuButton"
import { useEnqueueDialogs } from "components/General/GlobalConfirmationDialog"

const StyledHoT: any = StyledHoTRaw

const COL_HEADERS = 4
const ROW_HEADER_LABELS = ["Group", "Def", "Var", "Units"]

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        sandbox: "allow-downloads",
    },
    toggleGroup: {
        margin: theme.spacing(0, 0.5),
    },
    toggleButton: {
        padding: theme.spacing(1),
        lineHeight: "initial",
    },
    toggleContainer: {
        display: "flex",
    },
}))

export type MasterTableProps = {
    sampleSet: SampleSet
    processChart?: ProcessChart
    updateProcessChart?: React.Dispatch<
        React.SetStateAction<ProcessChart | undefined>
    >
    updateSampleSet?: (
        updates: React.SetStateAction<SampleSet | undefined>,
        name?: string,
    ) => void
    selectedNodes: string[]
    materialDefs?: Sample[]
    processDefs?: ProcessDefinition[]
    propertyDefs?: PropertyDefinition[]
    height?: number
    setDataHeight?: (height: number) => void
    minRows?: number
    minEmptyRows?: number
    readOnly?: boolean
    onTableSelect?: (selected?: {
        samples: number[]
        columns: number[]
    }) => void
    allowExport?: boolean
}

export function MasterTable({
    sampleSet,
    processChart,
    updateProcessChart,
    updateSampleSet,
    selectedNodes,
    materialDefs,
    processDefs,
    propertyDefs,
    height,
    setDataHeight,
    minRows,
    minEmptyRows,
    readOnly,
    onTableSelect,
    allowExport,
}: MasterTableProps) {
    const classes = useStyles()
    const [visibleAmountColumns, setVisibleAmountColumns] = useState<
        VisibleAmountColumns[]
    >(() => ["absolute"])
    const [totalColumns, setTotalColumns] = useState<TotalColumns[]>(() => [
        "total",
    ])
    const [options, setOptions] = useState<Options[]>(() => [])
    const [qidUnlocked, setQidUnlocked] = useState(false)
    const enqueueDialogs = useEnqueueDialogs()
    const columnsInfo = useColumnsInfo(
        selectedNodes,
        sampleSet.nodeAliases,
        processChart,
        visibleAmountColumns,
        totalColumns,
        materialDefs,
        processDefs,
        propertyDefs,
        readOnly,
        updateSampleSet,
        updateProcessChart,
        qidUnlocked,
    )

    const {
        tableRef,
        data,
        columns,
        rowHeaders,
        cells,
        mergeCells,
        afterChange,
        afterSelection,
        afterDeselect,
        beforeColumnMove,
        //beforeColumnSort,
        transpose,
    } = useStyledHoT({
        rows: sampleSet?.samples,
        columnsInfo,
        options,
        colHeaders: COL_HEADERS,
        rowHeaderLabels: ROW_HEADER_LABELS,
        minRows,
        minEmptyRows,
        onDataChange: useOnDataChange(updateSampleSet),
        onHeaderChange: useOnHeaderChange(updateProcessChart),
        onSelect: useOnSelected(onTableSelect),
        updateColumnOrder: useUpdateColumnOrder(updateProcessChart),
        updateRowOrder: useUpdateRowOrder(updateSampleSet),
    })

    useEffect(() => {
        setDataHeight && setDataHeight(data.length)
    }, [data.length, setDataHeight])

    const toggleQidLock2 = () => {
        setQidUnlocked(!qidUnlocked)
    }

    return data.length > 0 && (columns?.length || 0) > 0 ? (
        <div className={classes.container}>
            <TableButtonGroup
                // @ts-ignore
                left={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {allowExport && (
                            <ExpandingMenuButton
                                sampleSet={sampleSet}
                                updateSampleSet={updateSampleSet}
                                enqueueDialogs={enqueueDialogs}
                                columnsInfo={columnsInfo}
                                menuType={ExpandingMenuButtonTypes.Export}
                                toggleQIDFunction={toggleQidLock2}
                            />
                        )}
                        <ExpandingMenuButton
                            sampleSet={sampleSet}
                            updateSampleSet={updateSampleSet}
                            enqueueDialogs={enqueueDialogs}
                            columnsInfo={columnsInfo}
                            menuType={ExpandingMenuButtonTypes.Advanced}
                            toggleQIDFunction={toggleQidLock2}
                        />
                        <Tooltip title="Please save data and refresh before submitting becomes active">
                            <ExpandingMenuButton
                                sampleSet={sampleSet}
                                updateSampleSet={updateSampleSet}
                                enqueueDialogs={enqueueDialogs}
                                columnsInfo={columnsInfo}
                                menuType={ExpandingMenuButtonTypes.Calculation}
                                toggleQIDFunction={toggleQidLock2}
                            />
                        </Tooltip>
                    </div>
                }
                // @ts-ignore
                right={
                    <div className={classes.toggleContainer}>
                        <ToggleButtonGroup
                            className={classes.toggleGroup}
                            value={totalColumns}
                            onChange={(ev, selected) =>
                                setTotalColumns(selected)
                            }
                        >
                            <ToggleButton
                                className={classes.toggleButton}
                                value="total"
                            >
                                Total
                                {/* <SmallErrorHoover message="Changing the Component Totals value will not properly scale the corresponding Component amounts. Fix is underway. Until fix is deployed, please be aware that this is not updating at this time. Thank you!" /> */}
                            </ToggleButton>
                            <ToggleButton
                                className={classes.toggleButton}
                                value="selected"
                            >
                                Selected Subtotal
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            className={classes.toggleGroup}
                            value={visibleAmountColumns}
                            onChange={(ev, selected) =>
                                setVisibleAmountColumns(selected)
                            }
                        >
                            <ToggleButton
                                className={classes.toggleButton}
                                value="absolute"
                            >
                                Absolute
                            </ToggleButton>
                            <ToggleButton
                                className={classes.toggleButton}
                                value="%total"
                            >
                                Fraction of Total
                            </ToggleButton>
                            <ToggleButton
                                className={classes.toggleButton}
                                value="%group"
                            >
                                Fraction of Selected
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            className={classes.toggleGroup}
                            value={options}
                            onChange={(ev, selected) => setOptions(selected)}
                        >
                            <ToggleButton
                                className={classes.toggleButton}
                                value="Wrap"
                            >
                                <Tooltip
                                    title="Toggle Text Wrapping"
                                    placement="top"
                                >
                                    <WrapIcon fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                                className={classes.toggleButton}
                                value="Transpose"
                                disabled={selectedNodes.length === 0}
                            >
                                <Tooltip
                                    title="Transpose Table"
                                    placement="top"
                                >
                                    <TransposeIcon fontSize="small" />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                }
            />
            <StyledHoT
                ref={tableRef}
                height={height}
                data={data}
                columns={columns}
                colHeaders={transpose ? rowHeaders : true}
                rowHeaders={transpose ? true : rowHeaders}
                rowHeaderWidth={transpose ? undefined : 60}
                cells={cells}
                mergeCells={mergeCells}
                afterChange={afterChange}
                outsideClickDeselects
                parentOverflow={"horizontal"}
                stretchH="last"
                autoColumnSize={true}
                fixedColumnsLeft={
                    transpose ? COL_HEADERS : 2 + totalColumns.length
                }
                fixedRowsTop={transpose ? 2 + totalColumns.length : COL_HEADERS}
                observeChanges
                manualRowMove={transpose}
                beforeRowMove={transpose ? beforeColumnMove : undefined}
                manualColumnMove={!transpose}
                beforeColumnMove={transpose ? undefined : beforeColumnMove}
                //columnSorting={!transpose}                // these have been commented because there is a bug with HoT and sorting that causes a crash if the number of columns changes with sorting enabled
                //beforeColumnSort={beforeColumnSort}
                afterSelection={afterSelection}
                afterDeselect={afterDeselect}
                columnInfo={columnsInfo}
            />
        </div>
    ) : null
}

export default MasterTable

/*  **************************  */

type VisibleAmountColumns = "absolute" | "%total" | "%group"
type TotalColumns = "total" | "selected"

function useColumnsInfo(
    selectedNodes: string[],
    nodeAliases: NodeAliases | undefined,
    processChart: ProcessChart | undefined,
    visibleAmountColumns: VisibleAmountColumns[],
    totalColumns: TotalColumns[],
    materialDefs: Sample[] | undefined,
    processDefs: ProcessDefinition[] | undefined,
    propertyDefs: PropertyDefinition[] | undefined,
    readOnly: boolean | undefined,
    updateSampleSet: any,
    updateProcessChart: any,
    lockQid?: boolean,
) {
    return useMemo(() => {
        // identifier columns
        const columnsInfo: ColumnInfo[] = [
            {
                type: "text",
                customSort: sortTextWithPostfixNumber,
                headerRows: [
                    {
                        id: "identifiers",
                        title: "Identifiers",
                        placeholder: "",
                        className: "htCenter groupHeader",
                    },
                    {
                        id: "QID",
                        title: "Sample",
                        placeholder: "",
                    },
                    {
                        id: "QID",
                        title: "QID",
                        placeholder: "",
                    },
                    {
                        id: "QID",
                        title: null,
                        placeholder: "",
                    },
                ],
                get: buildGetKey("qid"),
                set: buildSetKey("qid"),
                width: 130,
                className: "innerRowSubHeader",
                placeholder: "Autogenerated",
                locked: !lockQid,
            },
            {
                type: "text",
                customSort: sortTextWithPostfixNumber,
                headerRows: [
                    {
                        id: "identifiers",
                        title: "Identifiers",
                        className: "htCenter groupHeader",
                    },
                    {
                        id: "Name",
                        title: "Sample",
                    },
                    {
                        id: "Name",
                        title: "Name",
                    },
                ],
                get: buildGetKey("title"),
                set: buildSetKey("title"),
                width: 130,
                className: "innerRowSubHeader",
            },
        ]

        // add totals columns
        for (const tcol of totalColumns) {
            let colInfo: ColumnInfo | undefined = undefined
            if (tcol === "total") {
                colInfo = {
                    type: "numeric",
                    headerRows: [
                        {
                            id: "Total",
                            title: "Totals",
                            className: "htCenter groupHeader",
                        },
                        {
                            id: "Total",
                            title: "Component Totals",
                        },
                        {
                            id: "Total",
                            title: "Total",
                        },
                        {
                            id: "Total",
                            title: "g",
                        },
                    ],
                    get: buildGetTotals("totalAmount"),
                    set: buildSetTotals(),
                    className: "htRight innerRowSubHeader",
                }
            } else if (tcol === "selected") {
                colInfo = {
                    type: "numeric",
                    headerRows: [
                        {
                            id: "Total",
                            title: "Totals",
                            className: "htCenter groupHeader",
                        },
                        {
                            id: "Total",
                            title: "Component Totals",
                        },
                        {
                            id: "Selected Subtotal",
                            title: "Selected Subtotal",
                        },
                        {
                            id: "Selected Subtotal",
                            title: "g",
                        },
                    ],
                    get: buildGetTotals(
                        "selectedTotal",
                        selectedNodes,
                        nodeAliases,
                    ),
                    set: buildSetTotals(selectedNodes, nodeAliases),
                    className: "htRight innerRowSubHeader",
                }
            }
            if (colInfo) columnsInfo.push(colInfo)
        }

        // add node columns
        if (processChart?.columns) {
            for (
                let colIndex = 0;
                colIndex < processChart.columns.length;
                ++colIndex
            ) {
                const col = processChart.columns[colIndex]
                if (
                    selectedNodes &&
                    selectedNodes.length !== 0 &&
                    !selectedNodes.includes(col.nodeId)
                )
                    continue
                const nodeLabel =
                    processChart.nodes?.[col.nodeId]?.label || "*Unlabelled*"
                const processChartDef = getProcessChartDefinition(
                    col,
                    processChart.nodes,
                )
                const definition = getDefinition(
                    col,
                    materialDefs,
                    processDefs,
                    propertyDefs,
                )
                const defTitle =
                    processChartDef?.title ||
                    definition?.title ||
                    "*Missing Label*"
                const count = processChartDef?.count || 1
                const column = processChartDef?.cols?.[col.colKey]
                const defKey = getDefKey(col)
                switch (defKey) {
                    case "materialDefs": {
                        for (let index = 0; index < count; ++index) {
                            for (
                                let i = 0;
                                i < visibleAmountColumns.length;
                                ++i
                            ) {
                                const colInfo: ColumnInfo = {
                                    colIndex,
                                    selectValue: colIndex,
                                    headerRows: [
                                        {
                                            id: col.nodeId,
                                            title: nodeLabel,
                                            className:
                                                "htCenter groupHeader material",
                                            set: buildSetLabel(col.nodeId),
                                            renderer: buildDeleteNodeRenderer(
                                                updateProcessChart,
                                                col.nodeId,
                                            ),
                                        },
                                        {
                                            id: col.defId + col.nodeId,
                                            title: defTitle,
                                            renderer:
                                                buildDeleteInstanceRenderer(
                                                    updateSampleSet,
                                                    updateProcessChart,
                                                    col.nodeId,
                                                    col.defId,
                                                    index,
                                                    "components",
                                                    count,
                                                ),
                                        },
                                        {
                                            id:
                                                col.colKey +
                                                col.defId +
                                                col.nodeId,
                                            title: col.colKey,
                                        },
                                        {
                                            id:
                                                index +
                                                visibleAmountColumns[i] +
                                                col.colKey +
                                                col.defId +
                                                col.nodeId,
                                            title: null,
                                        },
                                    ],
                                    get: () => null,
                                    className: "htRight",
                                }
                                if (visibleAmountColumns[i] === "absolute") {
                                    colInfo.type = "numeric"
                                    colInfo.get = buildGetComponentAmount(
                                        col.nodeId,
                                        col.defId,
                                        index,
                                        nodeAliases,
                                    )
                                    colInfo.set = buildSetComponentAmount(
                                        col.nodeId,
                                        col.defId,
                                        index,
                                        definition as Sample | undefined,
                                        nodeAliases,
                                    )
                                    colInfo.headerRows[3] = {
                                        ...colInfo.headerRows[3],
                                        title: column?.units || "g",
                                        type: "dropdown",
                                        source: ["g", "g/min"],
                                        visibleRows: 2,
                                        strict: true,
                                        className: "htCenter innerColSubHeader",
                                    }
                                } else if (
                                    visibleAmountColumns[i] === "%total"
                                ) {
                                    colInfo.get = buildGetComponentFraction(
                                        "selectedTotal",
                                        col.nodeId,
                                        col.defId,
                                        index,
                                    )
                                    colInfo.headerRows[3] = {
                                        ...colInfo.headerRows[3],
                                        title: "Total Fraction",
                                    }
                                } else if (
                                    visibleAmountColumns[i] === "%group"
                                ) {
                                    colInfo.get = buildGetComponentFraction(
                                        "selectedTotal",
                                        col.nodeId,
                                        col.defId,
                                        index,
                                        selectedNodes,
                                        nodeAliases,
                                    )
                                    colInfo.headerRows[3] = {
                                        ...colInfo.headerRows[3],
                                        title: "Selected Fraction",
                                    }
                                }
                                columnsInfo.push(colInfo)
                            }
                        }
                        break
                    }
                    case "processDefs": {
                        for (let index = 0; index < count; ++index) {
                            columnsInfo.push({
                                colIndex,
                                selectValue: colIndex,
                                type: "text",
                                headerRows: [
                                    {
                                        id: col.nodeId,
                                        title: nodeLabel,
                                        className:
                                            "htCenter groupHeader process",
                                        set: buildSetLabel(col.nodeId),
                                    },
                                    {
                                        id: col.defId + col.nodeId,
                                        title: defTitle,
                                        renderer: buildDeleteInstanceRenderer(
                                            updateSampleSet,
                                            updateProcessChart,
                                            col.nodeId,
                                            col.defId,
                                            index,
                                            "processSteps",
                                            count,
                                        ),
                                    },
                                    {
                                        id: col.colKey + col.defId + col.nodeId,
                                        title: col.colKey,
                                    },
                                    {
                                        id:
                                            index +
                                            col.colKey +
                                            col.defId +
                                            col.nodeId,
                                        title:
                                            column?.units ||
                                            (
                                                definition as ProcessDefinition
                                            )?.processVariables?.find(
                                                pv => pv.title === col.colKey,
                                            )?.units ||
                                            null,
                                    },
                                ],
                                get: buildGetProcessVar(
                                    col.nodeId,
                                    col.defId,
                                    index,
                                    col.colKey,
                                    nodeAliases,
                                ),
                                set: buildSetProcessVar(
                                    col.nodeId,
                                    col.defId,
                                    index,
                                    col.colKey,
                                    definition as ProcessDefinition | undefined,
                                    nodeAliases,
                                ),
                            })
                        }
                        break
                    }
                    case "propertyDefs": {
                        assumeType<PropertyDefinition | undefined>(definition)
                        for (let index = 0; index < count; ++index) {
                            columnsInfo.push({
                                colIndex,
                                selectValue: colIndex,
                                type:
                                    definition?.source !== "mm"
                                        ? undefined
                                        : ({
                                              text: "text",
                                              number: "numeric",
                                              categorical: "autocomplete",
                                              undefined: undefined,
                                          }[definition?.type || "undefined"] as
                                              | "text"
                                              | "numeric"
                                              | "autocomplete"
                                              | undefined),
                                source: definition?.allowedValues,
                                filter: false,
                                allowInvalid: false,
                                headerRows: [
                                    {
                                        id: col.nodeId,
                                        title: nodeLabel,
                                        className:
                                            "htCenter groupHeader output",
                                        set: buildSetLabel(col.nodeId),
                                    },
                                    {
                                        id: col.defId + col.nodeId,
                                        title: defTitle,
                                        renderer: buildDeleteInstanceRenderer(
                                            updateSampleSet,
                                            updateProcessChart,
                                            col.nodeId,
                                            col.defId,
                                            index,
                                            "properties",
                                            count,
                                        ),
                                    },
                                    {
                                        id: col.colKey + col.defId + col.nodeId,
                                        title: col.colKey,
                                    },
                                    {
                                        id:
                                            index +
                                            col.colKey +
                                            col.defId +
                                            col.nodeId,
                                        title:
                                            column?.units ||
                                            (definition as PropertyDefinition)
                                                ?.units ||
                                            null,
                                    },
                                ],
                                get: buildGetProperty(
                                    col.nodeId,
                                    col.defId,
                                    index,
                                    nodeAliases,
                                ),
                                set: buildSetProperty(
                                    col.nodeId,
                                    col.defId,
                                    index,
                                    definition as
                                        | PropertyDefinition
                                        | undefined,
                                    nodeAliases,
                                ),
                            })
                        }
                        break
                    }
                    default:
                }
            }
        }

        // add notes column
        columnsInfo.push({
            type: "text",
            headerRows: [
                {
                    id: "notes",
                    title: "Notes",
                    className: "htCenter groupHeader",
                },
                {
                    id: "notes",
                    title: "Sample",
                },
            ],
            get: buildGetKey("notes"),
            set: buildSetKey("notes"),
            width: 200,
        })

        // handle readonly
        if (readOnly) {
            columnsInfo.forEach(ci => {
                if (ci.set) ci.set = undefined
                ci.headerRows.forEach(hr => {
                    if (hr?.set) hr.set = undefined
                })
            })
        }

        return columnsInfo
    }, [
        materialDefs,
        nodeAliases,
        processChart?.columns,
        processChart?.nodes,
        processDefs,
        propertyDefs,
        readOnly,
        selectedNodes,
        totalColumns,
        updateSampleSet,
        updateProcessChart,
        visibleAmountColumns,
        lockQid,
    ])
}

function useUpdateColumnOrder(
    updateProcessChart:
        | undefined
        | React.Dispatch<React.SetStateAction<ProcessChart | undefined>>,
) {
    return useCallback(
        (toMove: number[], target: number) => {
            updateProcessChart &&
                updateProcessChart(processChart => {
                    if (!processChart?.columns || toMove.length === 0)
                        return processChart
                    const newChart = { ...processChart }
                    const newColumns = (newChart.columns as Column[]).filter(
                        (c, i) => !toMove.includes(i),
                    )
                    const moved = toMove.map(
                        i => (newChart.columns as Column[])[i],
                    )
                    newColumns.splice(target, 0, ...moved)
                    newChart.columns = newColumns
                    return newChart
                })
        },
        [updateProcessChart],
    )
}

function useUpdateRowOrder(
    updateSampleSet?: (
        updates: React.SetStateAction<SampleSet | undefined>,
        name?: string,
    ) => void,
) {
    return useCallback(
        (order: number[]) => {
            updateSampleSet?.(sampleSet => {
                if (!sampleSet?.samples) return sampleSet
                const newSet = new SampleSet(sampleSet)
                const length = Math.max(order.length, sampleSet.samples.length)
                newSet.samples = []
                for (let i = 0; i < length; ++i) {
                    let index = order[i]
                    if (index === undefined) index = i
                    if (index <= sampleSet.samples.length) {
                        if (!checkEmptySample(sampleSet.samples[index]))
                            newSet.samples.push(sampleSet.samples[index])
                    }
                }
                return newSet
            })
        },
        [updateSampleSet],
    )
}

function useOnDataChange(
    updateSampleSet?: (
        updates: React.SetStateAction<SampleSet | undefined>,
        name?: string,
    ) => void,
) {
    return useCallback(
        (changes: DataChange[]) => {
            if (!changes?.length || !updateSampleSet) return
            updateSampleSet(sampleSet => {
                const newSet = new SampleSet(sampleSet)
                newSet.samples = newSet.samples ? [...newSet.samples] : []
                for (const change of changes) {
                    const index = change[0]
                    const setter = change[1]
                    const val = change[2]
                    if (!newSet.samples[index])
                        newSet.samples[index] = new Sample()
                    newSet.samples[index] = setter(
                        newSet.samples[index],
                        sampleSet?.samples?.[index],
                        val,
                    )
                }
                return newSet
            }, "Sample Data")
        },
        [updateSampleSet],
    )
}

function useOnHeaderChange(
    updateProcessChart?: (
        updates: React.SetStateAction<SampleSet | undefined>,
        name?: string,
    ) => void,
) {
    return useCallback(
        (changes: HeaderChange[]) => {
            if (!changes?.length || !updateProcessChart) return
            updateProcessChart(processChart => {
                let newChart = processChart || new ProcessChart()
                for (const change of changes) {
                    const setter = change[1]
                    const val = change[2]
                    newChart = setter(newChart, processChart, val)
                }
                return newChart
            }, "Sample Data")
        },
        [updateProcessChart],
    )
}

function useOnSelected(
    onTableSelect?: (selected: {
        samples: number[]
        columns: number[]
    }) => void,
) {
    const onSelect = useCallback(
        (rows: number[], cols: number[]) => {
            onTableSelect?.({ samples: rows, columns: cols })
        },
        [onTableSelect],
    )
    return onSelect
}

// headers getters and setters
function buildGetKey(key: keyof Sample, defaultValue: any = "") {
    return (sample?: Sample) => {
        return sample?.[key] || defaultValue
    }
}

function buildSetKey(key: keyof Sample) {
    return (
        sample?: Sample,
        original?: Sample,
        val?: string | number | null,
    ) => {
        if (!sample || sample === original) {
            sample = new Sample(original)
        }
        ;(sample as any)[key] = val ? String(val) : undefined
        return sample
    }
}

function buildGetTotals(
    contextKey: string,
    selectedNodes?: string[],
    nodeAliases?: NodeAliases,
) {
    return (
        sample: Sample | undefined,
        context: { [key: string]: number | null },
    ) => {
        if (!context[contextKey])
            context[contextKey] = calculateTotal(
                sample,
                selectedNodes?.map(node =>
                    applyNodeAlias(node, nodeAliases, sample?.id),
                ),
            )
        return context[contextKey]?.toPrecision(6) || null
    }
}

function buildSetTotals(selectedNodes?: string[], nodeAliases?: NodeAliases) {
    return (
        sample: Sample,
        original: Sample | undefined,
        val: number | string | null,
    ) => {
        const newTotal = Number(val)
        if (!sample.components?.length || isNaN(newTotal)) return sample
        const selected =
            selectedNodes?.map(node =>
                applyNodeAlias(node, nodeAliases, sample?.id),
            ) || []

        const total = calculateTotal(sample, selected)
        if (!total) return sample
        const scale = newTotal / total
        let newSample = sample

        if (newSample === original) newSample = new Sample(newSample)
        if (newSample.components === original?.components)
            newSample.components = newSample.components
                ? [...newSample.components]
                : []
        if (newSample.components) {
            for (let i = 0; i < newSample.components.length; ++i) {
                const comp = newSample.components[i]

                // This logic is as follows, handling both the "Total" and "Selected Subtotal"
                // columns: if a node is selected, then selected will be a list with a nodeID in it.
                // For the value to change on total, comp.amount should have a value, selected
                // should have a nodeID that matches comp.nodeId, and selected.length > 0.
                //
                // If no node is selected, then selected.length == 0, comp.amount will have a value,
                // and selected will have no matching nodeId. The if clause below is a filter to
                // skip updating the values via updating a total.
                if (
                    !comp.amount ||
                    (comp.nodeId &&
                        !selected.includes(comp.nodeId) &&
                        selected.length !== 0)
                )
                    continue
                newSample.components[i] = new SampleInstance({
                    ...comp,
                    amount: comp.amount * scale,
                })
            }
        }
        return newSample
    }
}

function buildSetLabel(nodeId: string) {
    return (
        newChart: ProcessChart,
        processChart: ProcessChart | undefined,
        label: string | number | null,
    ) => {
        if (newChart === processChart) {
            newChart = new ProcessChart(processChart)
        }
        if (newChart.nodes === processChart?.nodes) {
            newChart.nodes = { ...newChart.nodes }
        }
        if (newChart.nodes?.[nodeId] === processChart?.nodes?.[nodeId]) {
            ;(newChart.nodes as ProcessNodes)[nodeId] = {
                ...(newChart.nodes as ProcessNodes)[nodeId],
            }
        }
        ;(newChart.nodes as ProcessNodes)[nodeId].label = `${label}`
        return newChart
    }
}
function buildGetComponentAmount(
    nodeId: string,
    defId: string,
    index: number,
    nodeAliases?: NodeAliases,
) {
    return (sample?: Sample) => {
        const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
        return (
            sample?.components
                ?.filter(
                    inst =>
                        (inst.nodeId === nodeIdAlias ||
                            (!inst.nodeId && !nodeId)) &&
                        inst.definition?.id === defId,
                )
                [index]?.amount?.toPrecision(6) || null
        )
    }
}
function buildSetComponentAmount(
    nodeId: string,
    defId: string,
    index: number,
    definition?: Sample,
    nodeAliases?: NodeAliases,
) {
    return (
        sample: Sample,
        original: Sample | undefined,
        val: number | string | null,
    ) => {
        const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
        let newSample = sample
        if (newSample === original) newSample = new Sample(sample)
        if (newSample.components === original?.components)
            newSample.components = [...(original?.components || [])]
        let count = 0
        let i
        for (i = 0; i < (newSample.components as any[]).length; ++i) {
            const inst = newSample.components?.[i]
            if (
                inst &&
                (inst.nodeId === nodeIdAlias || (!inst.nodeId && !nodeId)) &&
                inst.definition?.id === defId
            ) {
                if (count === index) {
                    break
                }
                count++
            }
        }
        if (i >= (newSample.components as any[]).length) {
            ;(newSample.components as SampleInstance[]).push(
                new SampleInstance({
                    amount: parseFloat((val || 0) as string),
                    definition:
                        definition ||
                        new Sample({
                            id: defId,
                        }),
                    nodeId: nodeIdAlias,
                }),
            )
        } else {
            ;(newSample.components as SampleInstance[])[i] = new SampleInstance(
                {
                    ...(newSample.components as SampleInstance[])[i],
                    amount: parseFloat((val || 0) as string),
                    definition:
                        definition ||
                        new Sample({
                            id: defId,
                        }),
                    nodeId: nodeIdAlias,
                },
            )
        }
        return newSample
    }
}
function buildGetComponentFraction(
    contextKey: string,
    nodeId: string,
    defId: string,
    index: number,
    selectedNodes?: string[],
    nodeAliases?: NodeAliases,
) {
    return (
        sample: Sample | undefined,
        context: {
            [key: string]: number | null
        },
    ) => {
        const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
        if (!context[contextKey])
            context[contextKey] =
                calculateTotal(
                    sample,
                    selectedNodes?.map(node =>
                        applyNodeAlias(node, nodeAliases, sample?.id),
                    ),
                ) || 0
        if (!context[contextKey]) return null
        return (
            (sample?.components?.filter(
                inst =>
                    (inst.nodeId === nodeIdAlias ||
                        (!inst.nodeId && !nodeId)) &&
                    inst.definition?.id === defId,
            )[index]?.amount || 0) / (context[contextKey] || 0)
        ).toPrecision(3)
    }
}
function buildGetProcessVar(
    nodeId: string,
    defId: string,
    index: number,
    colKey: string,
    nodeAliases?: NodeAliases,
) {
    return (sample: Sample | undefined) => {
        const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
        return (
            sample?.processSteps?.filter(
                inst =>
                    (inst.nodeId === nodeIdAlias ||
                        (!inst.nodeId && !nodeId)) &&
                    inst.definition?.id === defId,
            )[index]?.data?.[colKey] || null
        )
    }
}
function buildSetProcessVar(
    nodeId: string,
    defId: string,
    index: number,
    colKey: string,
    definition: ProcessDefinition | undefined,
    nodeAliases?: NodeAliases,
) {
    return (
        sample: Sample,
        original: Sample | undefined,
        val: number | string | null,
    ) => {
        const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
        let newSample = sample
        if (newSample === original) newSample = new Sample(sample)
        if (newSample.processSteps === original?.processSteps)
            newSample.processSteps = [...(original?.processSteps || [])]
        let count = 0
        let i
        for (i = 0; i < (newSample.processSteps as any[]).length; ++i) {
            const inst = newSample.processSteps?.[i]
            if (
                inst &&
                (inst.nodeId === nodeIdAlias || (!inst.nodeId && !nodeId)) &&
                inst.definition?.id === defId
            ) {
                if (count === index) {
                    break
                }
                count++
            }
        }
        if (i >= (newSample.processSteps as any[]).length) {
            ;(newSample.processSteps as ProcessInstance[]).push(
                new ProcessInstance({
                    data: {
                        [colKey]: val,
                    },
                    definition:
                        (definition as ProcessDefinition) ||
                        new ProcessDefinition({ id: defId }),
                    nodeId: nodeIdAlias,
                }),
            )
        } else {
            ;(newSample.processSteps as ProcessInstance[])[i] =
                new ProcessInstance({
                    ...(newSample.processSteps as ProcessInstance[])[i],
                    data: {
                        ...(newSample.processSteps as ProcessInstance[])[i]
                            .data,
                        [colKey]: val,
                    },
                    definition:
                        (definition as ProcessDefinition) ||
                        new ProcessDefinition({
                            id: defId,
                        }),
                    nodeId: nodeIdAlias,
                })
        }
        return newSample
    }
}
function buildGetProperty(
    nodeId: string,
    defId: string,
    index: number,
    nodeAliases?: NodeAliases,
) {
    return (sample: Sample | undefined) => {
        const nodeIdAlias = applyNodeAlias(nodeId, nodeAliases, sample?.id)
        const props = sample?.properties?.filter(
            inst =>
                (inst.nodeId === nodeIdAlias || (!inst.nodeId && !nodeId)) &&
                inst.definition?.id === defId,
        )

        const data = props?.[index]?.data
        return data === undefined ? null : data
    }
}
function buildSetProperty(
    nodeId: string,
    defId: string,
    index: number,
    definition: PropertyDefinition | undefined,
    nodeAliases?: NodeAliases,
) {
    return definition?.source === "mm"
        ? (
              sample: Sample,
              original: Sample | undefined,
              val: number | string | null,
          ) => {
              const nodeIdAlias = applyNodeAlias(
                  nodeId,
                  nodeAliases,
                  sample?.id,
              )
              let newSample = sample
              if (newSample === original) newSample = new Sample(sample)
              if (newSample.properties === original?.properties)
                  newSample.properties = [...(original?.properties || [])]
              let count = 0
              let i
              for (i = 0; i < (newSample.properties as any[]).length; ++i) {
                  const inst = newSample.properties?.[i]
                  if (
                      inst &&
                      (inst.nodeId === nodeIdAlias ||
                          (!inst.nodeId && !nodeId)) &&
                      inst.definition?.id === defId
                  ) {
                      if (count === index) {
                          break
                      }
                      count++
                  }
              }
              if (i >= (newSample.properties as any[]).length) {
                  ;(newSample.properties as PropertyInstance[]).push(
                      new PropertyInstance({
                          data: val === null ? undefined : val,
                          definition:
                              definition ||
                              new PropertyDefinition({
                                  id: defId,
                              }),
                          nodeId: nodeIdAlias,
                      }),
                  )
              } else {
                  ;(newSample.properties as PropertyInstance[])[i] =
                      new PropertyInstance({
                          ...(newSample.properties as PropertyInstance[])[i],
                          data: val === null ? undefined : val,
                          definition:
                              definition ||
                              new PropertyDefinition({ id: defId }),
                          nodeId: nodeIdAlias,
                      })
              }
              return newSample
          }
        : undefined
}

function buildDeleteInstanceRenderer(
    updateSampleSet: any,
    updateProcessChart: any,
    nodeId: string,
    defId: string,
    index: number,
    key: "components" | "processSteps" | "properties",
    count: number,
) {
    let nodeKey: "materialDefs" | "processDefs" | "propertyDefs"
    switch (key) {
        case "components":
            nodeKey = "materialDefs"
            break
        case "processSteps":
            nodeKey = "processDefs"
            break
        case "properties":
        default:
            nodeKey = "propertyDefs"
            break
    }
    const onDelete = () => {
        updateSampleSet((sampleSet: SampleSet) => {
            if (!sampleSet.samples) return sampleSet
            const newSamples = [...sampleSet.samples]
            for (let i = 0; i < newSamples.length; ++i) {
                const sample = newSamples[i]
                if (!sample?.[key]) continue
                let count = 0
                let j = 0
                const aliasNodeId = applyNodeAlias(
                    nodeId,
                    sampleSet.nodeAliases,
                    sample.id,
                )
                for (j = 0; j < (sample[key] as any).length; ++j) {
                    const inst = (sample[key] as any)[j]
                    if (
                        inst &&
                        inst.nodeId === aliasNodeId &&
                        inst.definition?.id === defId
                    ) {
                        count++
                        if (count >= index) {
                            break
                        }
                    }
                }
                const newInstances = [...(sample[key] as any)]
                newInstances.splice(j, 1)
                newSamples[i] = { ...sample, [key]: newInstances }
            }
            return new SampleSet({ ...sampleSet, samples: newSamples })
        }, "Delete Column")
        if (count === 1) {
            updateProcessChart((processChart: ProcessChart) => {
                const newChart = { ...processChart }
                newChart.nodes = { ...newChart.nodes }
                for (const [pcNodeId, node] of Object.entries(newChart.nodes)) {
                    if (pcNodeId !== nodeId) continue
                    if (!node[nodeKey]) continue
                    const newNode = { ...node }
                    newChart.nodes[pcNodeId] = newNode
                    newNode[nodeKey] = { ...(newNode[nodeKey] || {}) }
                    delete (newNode[nodeKey] as NodeDefinitions)[defId]
                    if (Object.keys(newNode[nodeKey] as any).length === 0) {
                        newNode[nodeKey] = undefined
                    }
                }
                newChart.columns = newChart.columns?.filter(
                    c => !(c.nodeId === nodeId && c.defId === defId),
                )
                return newChart
            })
        }

        return false
    }
    return buildDeleteRenderer(onDelete)
}

function buildDeleteNodeRenderer(updateProcessChart: any, nodeId: string) {
    const onDelete = () => {
        updateProcessChart((processChart: ProcessChart) => {
            const newChart = { ...processChart }
            newChart.nodes = { ...processChart.nodes }
            delete newChart.nodes[nodeId]
            newChart.columns = newChart.columns?.filter(
                c => c.nodeId !== nodeId,
            )
            return newChart
        }, "Delete Node")

        return false
    }
    return buildDeleteRenderer(onDelete)
}
