import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from '@material-ui/core/styles';
import { useAppStoreKey } from 'AppStore';
import common_styles from 'styles/common_styles';
import TableSpinner from '../../ReactTable/TableSpinner';
import { aichemyProtoAxios } from "API/mmAxios"
import BufferTextField from "../../General/BufferTextField"
import ButtonWithSpinner from "../../General/ButtonWithSpinner"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    ...common_styles(theme),
    root: {
        flexGrow: 1,
        // height: '100vh',
        minHeight: "95vh",
        margin: "auto",
        zIndex: 1,
        //overflow: 'hidden',
        position: "relative",
        display: "flex",
        width: "100%"
    },
    appBar: {
        position: "fixed",
        marginLeft: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        color: "white"
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            position: "fixed"
        },
        minHeight: "100vh"
    },
    content: {
        minHeight: `calc(100vh - 48px)`,
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        width: "100%",
        marginLeft: "auto",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    loader: {
        // position: 'absolute',
        // top: '40%',
        // left: '50%',
        // margin: '0px',
        marginLeft: "0px",
        [theme.breakpoints.up("md")]: {
            //left: `calc(50% - ${drawerWidth/2}px)`,
            marginLeft: `calc(${drawerWidth}px)`
        }
    }
}));



function SaveModelDialog() {
    const classes = useStyles();
    const [modelContext,] = useAppStoreKey("ModelContext");
    const [workflow, setWorkflow] = useAppStoreKey("Workflow");
    const [modelDialogContext, setModelDialogContext] = useAppStoreKey("ModelDialogContext");
    const [isSaving, setIsSaving] = useState(false);

    const [state, setState] = useState({
        mobileOpen: false,
        view: "upload",
        createProjOpen: false,
        modelDeleteName: "",
        modelSaveName: "",
        modelDescription: ""
    });

    useEffect(() => {
        setState({
            mobileOpen: false,
            view: "upload",
            createProjOpen: false,
            modelDeleteName: "",
            modelSaveName: modelContext.currentModels && modelContext.loadedModelData ? modelContext.currentModels.filter(el => {
                return modelContext.loadedModelData.id === el.id;
            })[0]?.model_title : [""],
            modelDescription: modelContext.currentModels && modelContext.loadedModelData ? modelContext.currentModels.filter(el => {
                return modelContext.loadedModelData.id === el.id;
            })[0]?.model_description : [""]
        })
    }, [modelDialogContext?.saveOpen, modelContext.currentModels, modelContext.loadedModelData])

    let updateSaveModelName = event => {
        let currVal = event.target.value;
        setWorkflow({...workflow, name: currVal})
    };

    let updateSaveModelDescription = event => {
        let currVal = event.target.value;
        setWorkflow({...workflow, description: currVal})
    };

    let saveModel = event => {
        setIsSaving(true);
        let url = `workflow/` + workflow.uuid + `/save`
        let body = JSON.stringify({name: String(workflow.name), description:String(workflow.description)})
        let config = {
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }
        aichemyProtoAxios.post(url, body, config)
            .then(res => {
                setIsSaving(false);
                setModelDialogContext({ ...modelDialogContext, saveOpen: false, saved: true });
                setWorkflow({...workflow, saved: true})
                }
            ).catch(err => {
                console.error(err);
                setIsSaving(false);
        })

    };

    // Handles the cancel action
    const handleClose = () => {
        setModelDialogContext({ ...modelDialogContext, saveOpen: false, saved: false });
    };

    return (
        <Dialog open={modelDialogContext && modelDialogContext.saveOpen ? modelDialogContext.saveOpen : false} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{workflow?.saved ? "Update Current Model" : "Save Current Model"}</DialogTitle>
            <DialogContent>
            <TableSpinner loading={isSaving || false} message={"Saving Model..."} />
                <DialogContentText id="alert-dialog-description">
                    Please specify a model name and provide a brief description (optional).
                </DialogContentText>
                <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                    <BufferTextField
                        id="save_name_input"
                        label="Model Name"
                        defaultValue={workflow?.name}
                        placeholder="Enter a Model Name"
                        className={classes.textField}
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        onBlur={ev => updateSaveModelName(ev)}
                        data-cy="model_name_txt"
                    />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth style={{ width: "100%" }}>
                    <BufferTextField
                        id="save_description_input"
                        label="Model Description"
                        placeholder="Enter a Model Description"
                        defaultValue={workflow?.description}
                        className={classes.textField}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        onBlur={ev => updateSaveModelDescription(ev)}
                        data-cy="model_desc_txt"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <ButtonWithSpinner onClick={saveModel} color="primary" autoFocus
                    disabled={!state.modelSaveName || state.modelSaveName.length === 0}
                    data-cy="save_model_btn" loading={isSaving}>
                    Save
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    )

}


export default React.memo(SaveModelDialog);