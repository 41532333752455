import React, {useCallback} from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

/**
 * Styled wrapper around material-ui Autocomplete with free solo selection. Most props forwarded to Autocomplete
 */
function FreeSoloSelector({
    label,
    variant,
    placeholder,
    error,
    helperText,
    required,
    chipColor,
    options = [],
    onChange,
    onBlur,
    value=[],
    fullWidth,
    InputProps,    
    TextFieldProps,
    ...remainingProps
}){
    const renderInput = useCallback(params => (
        <TextField
            {...params}
            variant={variant || "outlined"}
            label={label}
            placeholder={placeholder}
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            required={required}
            InputProps={{...params.InputProps, ...InputProps}}
            {...TextFieldProps}
        />
    ), [variant, label, placeholder, fullWidth, error, helperText, required, InputProps, TextFieldProps]);
    const renderTags = useCallback((val, getTagProps) => 
        val.map((option, index) => (
            <Chip size="small" label={option} color={chipColor} {...getTagProps({ index })} />
        ))
    , [chipColor]);
    const handleBlur = useCallback((ev) => {
        if (ev.target.value && ev.target.value !== ""){
            const newVal = Array.isArray(value) ? value.slice() : [];
            newVal.push(ev.target.value);
            onChange(ev, newVal);
        }
    }, [value, onChange])

    return (
        <Autocomplete
            multiple
            freeSolo
            options={options}
            value={value}
            filterSelectedOptions
            renderInput={renderInput}
            renderTags={renderTags}
            onChange={onChange}
            onBlur={onBlur || handleBlur}
            size="small"
            {...remainingProps}
            />
    );
}

export default (FreeSoloSelector);