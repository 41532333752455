import {useCallback, useMemo} from 'react';
import { matchSorter } from 'match-sorter'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Chip, Tooltip } from '@material-ui/core';


function getOptionSelected(options, values){
    return options?.id === values?.id;
}

const defaultKeys = ["label", "title"]
function getKey(option, keys) {
    if (!option) return undefined;
    for (const key of keys) {
        if (option[key]) return option[key];
    }
    return undefined;
}

/**
 * React Component for array selection, wrapper around Autocomplete, prefer StyledAutocomplete
 */
function MultiSelector({label,
    placeholder = undefined,
    variant = undefined,
    required = undefined,
    selectSingle = undefined,
    chipColor = undefined,
    options = undefined,
    appendOptions = [],
    onChange = undefined,
    value = undefined,
    fullWidth = undefined,
    loading = undefined,
    missingLabel = undefined,
    error = undefined,
    helperText = undefined,
    InputProps = undefined,
    TextFieldProps = undefined,
    highlightTooltip = undefined,
    keys=defaultKeys,
    groupBy = undefined,
    displayWarning = undefined,
    ...remainingProps 
}){

    const renderInput = useCallback(params => (
        <TextField
            {...params}
            variant={variant || "outlined"}
            label={label}
            placeholder={placeholder}
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            required={required}
            InputProps={{...params.InputProps, ...InputProps, endAdornment: InputProps?.endAdornment ? <>{InputProps?.endAdornment} {params.InputProps.endAdornment}</> : params.InputProps.endAdornment}}
            {...TextFieldProps}
        />
    ), [variant, label, placeholder, fullWidth, error, helperText, required, TextFieldProps, InputProps]);

    const getOptionLabel = useCallback(
        option => getKey(option, keys) || (loading ? "Loading label..." : (missingLabel || "Missing Label..."))
    ,[keys, loading, missingLabel]);

    const filterOptions = useCallback((options, {inputValue}) => matchSorter(options, inputValue, {keys}).sort((a, b) => {
        if (!groupBy || groupBy(a) === groupBy(b)) return 0
        return groupBy(a) < groupBy(b) ? -1 : 1
    }), [groupBy, keys]);

    const renderOption = useCallback((option) => {
        if (highlightTooltip) {
            return <Tooltip {...highlightTooltip}>
                <span>{getOptionLabel(option)}</span>
            </Tooltip>
        } else {
            return <Tooltip title={option?.description || ""}>
                <span>{getOptionLabel(option)}</span>
            </Tooltip>
        }
    },[getOptionLabel, highlightTooltip]);

    const renderTags = useCallback((val, getTagProps) => 
        val.map((option, index) => {
            const {key, ...tagProps} = getTagProps({ index });
            return (
            <Tooltip key={key} title={option?.description || option?.alternateNames?.replace(/\n/g,' | ') || ""}>
                <Chip style={{maxWidth: '25ch'}} size="small" label={getOptionLabel(option)} color={chipColor} {...tagProps} />
            </Tooltip>
        )})

    , [getOptionLabel, chipColor]);

    const missingOptions = useMemo(() => (value && value.filter(v => options.indexOf(v) < 0)) || [],[options, value]);
    const filledOptions = useMemo(() => appendOptions.concat(options, missingOptions), [options, missingOptions, appendOptions]);
    const getOptionDisabled = useCallback(option => missingOptions.indexOf(option) >= 0, [missingOptions]);

    const handleChange = useCallback((ev, values) => {
        if (onChange){
            if (!selectSingle || !values || values.length < 2) onChange(ev, values);
            else {
                const v = values.find(v => value?.indexOf(v) < 0)
                onChange(ev, v ? [v] : [])
            }
        }
    }, [onChange, selectSingle, value]);

    // The displayWarning input is an optional function that can provide an optional render message or alert
    // for some different functionality. Right now, it's sole purpose to display a message to the user about
    // process nodes only allowed to have ONE process per node.

    return (
        <div>
            <Autocomplete
                multiple
                options={filledOptions}
                getOptionDisabled={getOptionDisabled}
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                value={value || []}
                filterSelectedOptions
                getOptionSelected={getOptionSelected}
                renderInput={renderInput}
                renderOption={renderOption}
                renderTags={renderTags}
                onChange={handleChange}
                size="small"
                loading={loading}
                groupBy={groupBy}
                {...remainingProps}
            />
            {displayWarning ? 
                displayWarning :
                <div />
            }
        </div>
    );
}

export default (MultiSelector);