import React, { useState, useMemo } from 'react';
import { useQueryAutocomplete } from '../../API/queryHooks';
import MultiSelector from './MultiSelector';

/**
 * React Component for performing a multiselect on on tests
 * @param props most props forwared to MultiSelector
 * @param props.selected forwared as the value prop
 */
export default function TestSelector({selected, onChange, filter, ...remainingProps }){
    const [input, setInput] = useState({});
    const params = useMemo(() => ({
        Model: "Test",
        fields: ["title", "id", "meta", "instrument"],
        filter,
    }), [filter])
    const { data: {data: options} = {}, isFetching } = useQueryAutocomplete(input, params, {logName: "Test Selector"});
    return (
        <MultiSelector
            label="Select Test"
            missingLabel="Missing or Private Test"
            loading={isFetching}
            options={options || []}
            value={selected || []}
            onBlur={() =>  setInput({})}
            onChange={(...args) => {onChange && onChange(...args); setInput({})}}
            inputValue={input.title || ""}
            onInputChange={(ev, newVal) => {ev && setInput({title: ev?.target?.value || ""})}}
            data-testid="test selector"
            {...remainingProps}
            />
    );
}