import { Base } from '../Base'
import { LabToPropertyDefinition, PropertyInstance } from '../models'
import { Schema, schema } from '../schemaUtils'

export class PropertyDefinition extends Base {
    contributors?: string[]
    title?: string
    alternateNames?: string // \n delimited string
    description?: string
    key?: string
    source?: "mm" | "em" | "cv" = "mm" // em is deprecated
    sourceParameters?: { [id: string]: any }
    type?: "text" | "number" | "categorical" = "text"
    units?: string
    aggregation?: string
    allowedValues?: string[]

    labs?: LabToPropertyDefinition[]
    instances?: PropertyInstance[]

    static readonly [schema]: Schema<PropertyDefinition> = {
        endPoint: "property_definitions",
        type: "property_definition",
        relationships: {
            ...Base[schema].relationships,
            labs: { map: "labs", model: "LabToPropertyDefinition", toMany: true, reverse: "propertyDefinition", meta: { of: "labs", through: "lab" } },
            instances: { map: "property_instances", model: "PropertyInstance", toMany: true, reverse: "definition" }
        },
        map: {
            ...Base[schema].map,
            "alternateNames": "alternate_names",
            "contributors": "contributors",
            "title": "title",
            "description": "description",
            "key": "meta.key",
            "source": "meta.source",
            "sourceParameters": "meta.source_parameters",
            "type": "meta.type",
            "units": "meta.units",
            "aggregation": "meta.aggregation",
            "allowedValues": "meta.allowed_values",
        }
    }

    constructor(toCopy?: Partial<PropertyDefinition>) {
        super()
        Object.assign(this, toCopy)
    }
}