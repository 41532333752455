/**
 * @format
 */
import { useState, useCallback, useEffect, useRef, useMemo } from "react"
import { useNavigate } from "@reach/router"
import { useSnackbar } from "notistack"

import {
    Typography,
    Tooltip,
    IconButton,
    ButtonGroup,
    Button,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import DeleteIcon from "@material-ui/icons/DeleteForever"
import UndoIcon from "@material-ui/icons/Undo"
import RedoIcon from "@material-ui/icons/Redo"

import common_styles from "../../styles/common_styles"
import ButtonWithSpinner from "../General/ButtonWithSpinner"
import { useMutationFC } from "../../API/mutationHooks"

import LoadingOverlay from "./LoadingOverlay"
import { useQueryRoles } from "API/queryHooks"

const useStyles = makeStyles(theme => ({
    ...common_styles(theme),
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    header: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    body: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    actions: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        display: "flex",
        justifyContent: "left", //"flex-end",
        alignItems: "center",
    },
    dialogUndoButton: {
        position: "absolute",
        top: 0,
        right: 0,
        marginTop: "20px",
        marginRight: "68px",
    },
}))

const ACCEPTABLE_PLATFORM_ADMIN_OVERRIDES = ["Instrument"]
const canPlatformAdminOverride = model => {
    if (ACCEPTABLE_PLATFORM_ADMIN_OVERRIDES.indexOf(model) >= 0) {
        return true
    }
    return false
}

/**
 * Default layout object for editing objects. Older component used primarily by EditProcess and EditProperty. Future views should probably use EditButtons.
 * @param props forwarded to wrapping div
 * @param {string} props.title text to display at top of the layout
 * @param {boolean} props.editing if true "editing" mode will be on, meaning the save and delete buttons will be visible
 * @param {() => void} props.onClose callback when the close button is clicked
 * @param {string} props.closeButtonTitle label text for the close button, default "Close"
 * @param {string} props.objectName of object passed to snackbase on success (just Delete now)
 * @param {() => Promise<any>} props.onDelete callback for delete
 * @param {() => Promise<any>} props.onSave callback for save
 * @param {(res: any) => void | Promise<any>} props.onSaved callback called after successful save. Passed the result from onSave
 * @param {undefined | {name: string, callback: () => void}} props.undo object with name and callback for undo operations. undefined to disable
 * @param {undefined | {name: string, callback: () => void}} props.redo object with name and callback for redo operations. undefined to disable
 * @param props.ActionButtons material-ui compatible Button components to be added to the ButtonGroup
 * @param {boolean} props.readOnly if true Save, Delete, Undo, and edo buttons will be disabled
 * @param {number} props.progress number to show in save, load, etc. progress spinners. If left undefined during an operation, an indeterminate progress spinner will be displayed
 * @param {boolean} props.forceReadOnly if true, the "copy, save, save-and-close" buttons are permanently disabled. (used for instruments)
 */
export default function EditCardBase({
    title,
    editing,
    onClose,
    closeButtonTitle = "Close",
    objectName,
    cardDetail,
    children,
    onDelete,
    onSave,
    onSaved, // called after successfull save that does not close
    onCopy,
    undo,
    redo,
    Actions,
    ActionButtons,
    loading,
    readOnly,
    progress,
    forceReadOnly = false,
    ...rest
}) {
    const classes = useStyles()
    const [saving, setSaving] = useState(false)
    const [savingAndClose, setSavingAndClose] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [copying, setCopying] = useState(false)
    const heading = readOnly
        ? `Read Only Viewing "${title || objectName}"`
        : editing
        ? `Editing "${title || "Untitled"}"`
        : `Create New ${objectName}`

    const mounted = useRef(true)
    useEffect(() => () => (mounted.current = false), [])

    const handleDelete = useCallback(
        async confirmed => {
            setDeleting(true)
            if (confirmed) {
                onDelete &&
                    (await onDelete()
                        .then(res => res !== false && onClose())
                        .catch(error => console.error(error)))
            }
            if (mounted.current) setDeleting(false)
        },
        [onClose, onDelete],
    )

    const handleSave = useCallback(async () => {
        setSaving(true)
        if (onSave)
            await onSave()
                .then(res => res && onSaved && onSaved(res))
                .catch(error => console.error(error))
        if (mounted.current) setSaving(false)
    }, [onSave, onSaved])

    const handleSaveAndClose = useCallback(async () => {
        setSavingAndClose(true)
        if (onSave)
            await onSave()
                .then(res => res && onClose())
                .catch(error => console.error(error))
        if (mounted.current) setSavingAndClose(false)
    }, [onSave, onClose])

    const handleCopy = useCallback(async () => {
        setCopying(true)
        if (onCopy) await onCopy().catch(error => console.error(error))
        if (mounted.current) setCopying(false)
    }, [onCopy])

    const busy = copying || saving || savingAndClose || deleting

    return (
        <div className={classes.container} {...rest}>
            {loading && <LoadingOverlay filled />}
            <div className={classes.dialogUndoButton}>
                {undo !== undefined && (
                    <Tooltip title={`Undo ${undo?.name || ""}`} placement="top">
                        <span>
                            <IconButton
                                disabled={!undo || forceReadOnly}
                                onClick={undo?.callback}
                            >
                                <UndoIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                {redo !== undefined && (
                    <Tooltip title={`Redo ${redo?.name || ""}`} placement="top">
                        <span>
                            <IconButton
                                disabled={!redo || forceReadOnly}
                                onClick={redo?.callback}
                            >
                                <RedoIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </div>
            <div className={classes.actions}>
                {Actions}
                {editing && (
                    <Tooltip title={`Delete ${objectName}`} placement="top">
                        <span>
                            <ButtonWithSpinner
                                spinnerColor={editing ? "secondary" : "primary"}
                                loading={deleting}
                                Component={IconButton}
                                onClick={handleDelete}
                                disabled={busy || readOnly || forceReadOnly}
                                value={progress}
                            >
                                <DeleteIcon />
                            </ButtonWithSpinner>
                        </span>
                    </Tooltip>
                )}
                <ButtonGroup
                    color={editing ? "secondary" : "primary"}
                    variant="contained"
                    style={{ marginRight: 24 }}
                >
                    {ActionButtons}
                    {editing && (
                        <ButtonWithSpinner
                            onClick={handleCopy}
                            spinnerColor={editing ? "secondary" : "primary"}
                            loading={copying}
                            disabled={busy || forceReadOnly}
                        >
                            Copy
                        </ButtonWithSpinner>
                    )}
                    <ButtonWithSpinner
                        onClick={handleSave}
                        spinnerColor={editing ? "secondary" : "primary"}
                        loading={saving}
                        disabled={busy || readOnly || forceReadOnly}
                        value={progress}
                    >
                        Save
                    </ButtonWithSpinner>
                    <ButtonWithSpinner
                        onClick={handleSaveAndClose}
                        spinnerColor={editing ? "secondary" : "primary"}
                        loading={savingAndClose}
                        disabled={busy || readOnly || forceReadOnly}
                        value={progress}
                    >
                        Save and Close
                    </ButtonWithSpinner>
                    <Button onClick={onClose}>{closeButtonTitle}</Button>
                </ButtonGroup>
            </div>
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography
                        color={readOnly ? "secondary" : "primary"}
                        className={classes.cardTitle}
                    >
                        {heading}
                    </Typography>
                    {cardDetail && cardDetail !== "" && (
                        <Typography className={classes.cardBodyText}>
                            {cardDetail}
                        </Typography>
                    )}
                </div>
                <div className={classes.body}>{children}</div>
            </div>
        </div>
    )
}

export function useDefaultMutateHandlers({
    Model,
    initialObject,
    object,
    validateAll,
    objectName,
    onCopy,
    updateData,
    account,
}) {
    const { enqueueSnackbar } = useSnackbar()
    const { mutateAsync } = useMutationFC()
    const navigate = useNavigate()

    const handleDelete = useCallback(async () => {
        const param = { Model, allow: { delete: true } }
        await mutateAsync(
            { param, previous: initialObject },
            {
                errorMessage: () => `Error: Failed to delete ${objectName}`,
                successMessage: () => `${objectName} deleted.`,
            },
        )
    }, [Model, initialObject, mutateAsync, objectName])

    const handleSave = useCallback(async () => {
        const allValid = await validateAll()
        if (allValid.length) {
            // add user to contributors list
            const payload = {
                ...object,
                contributors: (object.contributors || [])
                    .filter(c => c !== account.name)
                    .concat([account.name]),
            }
            const prevValid =
                initialObject?.id && object.id === initialObject?.id
            const previous = prevValid ? initialObject : undefined
            const param = {
                Model,
                allow: { patch: prevValid, post: !prevValid },
            }
            return mutateAsync(
                {
                    param,
                    payload,
                    previous,
                },
                {
                    errorMessage: () => `Error: Failed to save ${object.title}`,
                    successMessage: previous?.id
                        ? () => `${object.title} updated.`
                        : () => `${object.title} saved.`,
                },
            )
        } else {
            enqueueSnackbar("Incomplete or bad data: Check Fields", {
                variant: "error",
            })
        }
    }, [
        validateAll,
        object,
        account.name,
        initialObject,
        Model,
        mutateAsync,
        enqueueSnackbar,
    ])

    const handleSaved = useCallback(
        async saved => {
            updateData && updateData(saved, { replace: true })
            navigate(`./${saved.id}`, { replace: true })
            return saved
        },
        [navigate, updateData],
    )

    const handleCopy = useCallback(async () => {
        const newObject = {
            ...object,
            id: undefined,
            contributors: undefined,
            title: undefined,
        }
        const copyError = await onCopy(newObject)
        enqueueSnackbar(
            copyError ||
                `${
                    object.title || objectName
                } copied. Click save to upload as a new ${objectName}.`,
            { variant: copyError ? "error" : "info" },
        )
    }, [object, onCopy, enqueueSnackbar, objectName])

    return { handleDelete, handleSave, handleSaved, handleCopy }
}

/**
 * Checks to see if an entity should be READ-ONLY or if not [i.e. it's has a 'write' permission].
 * This also handles the rare cases of platform admin overrides
 * @param {object} initialObject beginning of an object, such as one created from a constructor
 * @param {string} object the actual object that exists in the DB, if present
 * @param {boolean} platformAdmin if true, allows specific object to have special super-admin/platform-admin override capabilities if those roles exist for the user (sent from DB). (e.g. Instrument, Lab)
 * @param {string} objectType model name, such as "Instrument". This must match ACCEPTABLE_..._OVERRIDES array above for usage.
 *
 * @returns {boolean} TRUE on read-only, FALSE on 'write' permissions (edit, delete)
 */
export function useCheckReadOnly({
    initialObject,
    object,
    platformAdmin = false,
    objectType = "",
}) {
    const { data: roles } = useQueryRoles()
    return useMemo(() => {
        if (!roles) return true
        if (!object?.id) return false
        const labs = initialObject?.labs

        if (
            platformAdmin &&
            roles &&
            roles.platform_admin &&
            canPlatformAdminOverride(objectType)
        ) {
            return false
        }

        if (Array.isArray(labs)) {
            return !labs?.find(
                lab =>
                    roles?.user_labs.indexOf(lab.lab.id) >= 0 ||
                    roles?.admin_labs.indexOf(lab.lab.id) >= 0,
            )
        }
        // handle single "labs" object
        else {
            return !(
                roles?.user_labs.includes(labs?.id) ||
                roles?.admin_labs.includes(labs?.id)
            )
        }
    }, [initialObject, object, roles, platformAdmin, objectType])
}
