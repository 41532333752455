import React, { useMemo } from 'react';
import { Grid, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import BufferTextField from '../General/BufferTextField';
import FreeSoloSelector from '../General/FreeSoloSelector';
import TagSelector from '../General/TagSelector';
import LabSelector from '../General/LabSelector';
import UserSelector from '../General/UserSelector';
import FileTab from './FileTab';
import { value } from "utils/useFormState/useFormStateUtils"
import { LabToSample } from 'schema/models';

function MaterialInfoFieldsRaw({material, invalid, updateMaterial, accountName, fileUploadProps}){
    let rawComponentBoxState = true;
    if (material.categories && material.categories.length) {
        if (!(material.categories.includes("chemical"))) {
            rawComponentBoxState = false;
        } 
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={10}>
                <BufferTextField
                    variant="outlined"
                    size="small"
                    required
                    defaultValue={material.title || ""}
                    error={!!invalid?.title?.[value]}
                    helperText={invalid?.title?.[value]}
                    onBlur={ev => { updateMaterial({ title: ev.target.value }, "Title Edit")}}
                    fullWidth
                    label="Material Name"
                    placeholder="Enter Material Name" />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
                <BufferTextField
                    variant="outlined"
                    size="small"
                    defaultValue={material.qid || ""}
                    error={!!invalid?.qid?.[value]}
                    helperText={invalid?.qid?.[value]}
                    onBlur={ev => { updateMaterial({ qid: ev.target.value }, "QID Edit")}}
                    fullWidth
                    label="QID"
                    placeholder="Autogenerate"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FreeSoloSelector
                    variant="outlined"
                    value={useMemo(() => material.alternateNames?.split("\n").filter(v => v) || [], [material.alternateNames])}
                    onChange={(ev, newVal) => updateMaterial({ alternateNames: newVal.map(v => v.trim()).filter(v => v).join("\n") }, "Alternate Names Edit")}
                    fullWidth
                    label="Alternate Names"
                    helperText="Press enter to deliminate names"
                    limitTags={5} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TagSelector
                    value={material.tags || []}
                    onChange={(ev, newVal) => updateMaterial({ tags: newVal }, "Tags Edit")}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <LabSelector
                    variant="outlined"
                    error={!!invalid?.labs?.[value]}
                    helperText={invalid?.labs?.[value]}
                    selected={material.labs || []}
                    onSelect={labs => { updateMaterial({ labs }, "Labs Edit")}} 
                    Constructor={LabToSample} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <UserSelector
                    variant="outlined"
                    label={"Contributors"}
                    fullWidth
                    value={material.contributors || (accountName && [accountName]) || []}
                    disabled
                    onChange={(ev, values) => updateMaterial({ contributors: values }, "Contributors Edit")} />
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={10} xl={10}>
                <BufferTextField
                    variant="outlined"
                    size="small"
                    defaultValue={material.notes || ""}
                    onBlur={ev => updateMaterial({ notes: ev.target.value }, "Notes Edit")}
                    fullWidth
                    label="Notes"
                    placeholder="Enter any notes that cannot be captured through other fields."
                    multiline />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                <Tooltip title="Materials flagged as raw will be easily visible when selecting input components. You may also wish to flag pre-reacts and other materials as raw if they are commonly used as components.">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={rawComponentBoxState}
                                onChange={ev => updateMaterial({ categories: ev.target.checked ? (material.categories || []).filter(c => c !== "chemical").concat("chemical") : (material.categories || []).filter(c => c !== "chemical").filter(c => c !== "formulation").concat("formulation") }, "Raw Edit")}
                                color="primary"
                            />
                        }
                        label="Raw Component"
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <FileTab 
                        material={material} 
                        docInstrument={fileUploadProps.docInstrument} 
                        isFetching={fileUploadProps.isFetching} 
                        pendingDelete={fileUploadProps.pendingDelete} 
                        setPendingDelete={fileUploadProps.setPendingDelete} 
                        files={fileUploadProps.files}
                        setFiles={fileUploadProps.setFiles}
                    />
            </Grid>
        </Grid>
    )
}

const MaterialInfoFields = React.memo(MaterialInfoFieldsRaw)
export default MaterialInfoFields