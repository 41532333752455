import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { format } from "date-fns"
import { NavigateFn } from "@reach/router"

import { getQIDs, getQueryFC } from "API/firecrackerAPI"
import {
    NodeAliases, ProcessChart, ProcessInstance, PropertyInstance,
    Sample, SampleInstance, SampleSet
} from "schema/models"

import {
    addDefsToSet, addSampleSetCalProps, applyNodeAlias,
    updateSetCalculatedProperties, checkEmptySample
} from "utils/schemaUtils"
import {
    updateProcessNodesFromSamples, _ProcessChartFromOldFlowchart
} from "components/FlowChart/processChartUtils"
import { useAppStoreKey } from "AppStore"
import { State, UpdateTransform, Validator } from "utils/useFormState/useFormStateTypes"
import useFormState from "utils/useFormState/useFormState"
import { simpleString } from "utils/utils"
import { checkLabs } from "utils/invalidators"


type useSaveProps = {
    validateAll: any,
    sampleSet: SampleSet,
    account: any,
    initialSampleSet: SampleSet,
    processChart: ProcessChart | undefined,
    initialProcessChart: ProcessChart | undefined,
    mutateAsync: any,
    enqueueSnackbar: any,
    enqueueDialogs: any,
}
export function useSave({
    validateAll,
    sampleSet,
    account,
    initialSampleSet,
    processChart,
    initialProcessChart,
    mutateAsync,
    enqueueSnackbar,
    enqueueDialogs,
}: useSaveProps) {

    const handleSave = useCallback(async (deleteSamples: boolean) => {
        // add user to contributors list
        const payload = await preSetSave(
            sampleSet,
            initialSampleSet,
            account.name,
        )
        const prevValid =
            initialSampleSet?.id && initialSampleSet.id === payload.id
        const existingSamples = payload.samples?.some(sample => !!sample.id)
        const previous = prevValid
            ? new SampleSet({
                ...initialSampleSet,
                samples: initialSampleSet.samples?.map(
                    sample =>
                        new Sample({
                            ...sample,
                            tests: undefined,
                            sets: undefined,
                        }),
                ),
            })
            : existingSamples
                ? { samples: initialSampleSet.samples?.filter(sample => payload.samples?.find(s => s.id === sample.id)) }
                : undefined

        // save sample set
        const savedSet: SampleSet = await mutateAsync(
            {
                param: {
                    Model: "MaterialSet",
                    allow: { patch: true, post: true },
                    relationships: {
                        samples: {
                            allow: {
                                post: true,
                                patch: true,
                                delete: prevValid && deleteSamples,
                            },
                        },
                    },
                },
                payload,
                previous,
            },
            {
                errorMessage: () =>
                    `Server Error: Failed to save ${sampleSet.title}`,
                successMessage: prevValid
                    ? () => `${sampleSet.title} updated.`
                    : () => `${sampleSet.title} saved.`,
            },
        )

        // save process chart
        let savedProcessChart = processChart

        //check if new samples are being added to the set
        let isNewSample = savedSet?.samples?.some(
            (sample) =>
                !("processChart" in sample)
        )
        if ((savedSet && processChart !== initialProcessChart) || isNewSample) {
            const payload = { ...processChart }
            payload.samples = savedSet?.samples?.reduce((agg, sample) => {
                if (!sample?.processChart?.id || sample.processChart.id === initialProcessChart?.id) {
                    agg.push({ id: sample.id })
                }
                return agg
            }, [] as Sample[])
            payload.sets = [{ id: savedSet.id }]
            payload.title = savedSet.title || ""
            savedProcessChart = await mutateAsync(
                {
                    param: {
                        Model: "ProcessChart",
                        allow: { patch: true, post: true },
                    },
                    payload,
                    previous: initialProcessChart,
                },
                {
                    errorMessage: () =>
                        `Server Error: Failed to save Process Chart`,
                },
            )
            savedSet.samples = savedSet.samples?.map(sample => {
                if (!sample?.processChart?.id || sample?.processChart?.id === initialProcessChart?.id) {
                    return new Sample({ ...sample, processChart: { id: savedProcessChart?.id } })
                }
                return sample
            })
            savedSet.processChart = new ProcessChart({ id: savedProcessChart?.id })
        }
        return { sampleSet: savedSet, processChart: savedProcessChart }

    }, [sampleSet, initialSampleSet, account.name, mutateAsync, processChart, initialProcessChart])

    const userIsContributor = useMemo(() => {
        if (
            !sampleSet.id &&
            (!sampleSet.samples ||
                sampleSet.samples?.every(sample => !sample?.id))
        ) {
            return true
        }
        if (
            sampleSet.id &&
            (!sampleSet.contributors || sampleSet.contributors.indexOf(account.name) < 0)
        ) {
            return false
        }
        if (
            sampleSet.samples?.some(
                (sample, i) =>
                    sample?.id &&
                    sample !== (initialSampleSet?.samples || [])[i] &&
                    (!sample.contributors || sample.contributors.indexOf(account.name) < 0),
            )
        ) {
            return false
        }
        return true
    }, [
        account.name,
        initialSampleSet,
        sampleSet.contributors,
        sampleSet.id,
        sampleSet.samples,
    ])
    const qidChanges = useMemo(() => {
        return (
            (sampleSet.samples?.filter(
                sample =>
                    sample?.id &&
                    initialSampleSet.samples?.find(
                        initSample =>
                            sample?.id === initSample?.id &&
                            sample?.qid !== initSample?.qid,
                    ),
            ).length || 0) > 0
        )
    }, [sampleSet.samples, initialSampleSet.samples])
    const samplesDeleted = useMemo(() => {
        if (!initialSampleSet.samples?.length || sampleSet.id !== initialSampleSet.id) return false
        for (const sample of initialSampleSet.samples) {
            if (sample?.id && !sampleSet.samples?.find(s => s.id === sample.id)) {
                return true
            }
        }
    }, [initialSampleSet.id, initialSampleSet.samples, sampleSet.id, sampleSet.samples])
    const onSave = useCallback(async () => {
        const allValid = (await validateAll()).filter(Boolean)
        if (allValid.length > 0) {
            enqueueSnackbar(allValid[0] || "Incomplete or bad data: Check Fields", {
                variant: "error",
            })
            return
        }

        const dialogs: any[] = []
        const deleteSamples = { current: false }
        let deleteIndex = -1
        if (!userIsContributor) {
            dialogs.push({
                title: "Confirm New Contributor",
                message:
                    "You do not appear to be a past contributor to this set and/or its samples. Please make sure the owners are aware of any edits you make. Do you wish to be added as a contributor and save these changes?",
            })
        }
        if (qidChanges) {
            dialogs.push({
                title: "Confirm QID Changes",
                message:
                    "Changing QIDs for existing samples is generally not recommended unless you are fixing an error in recently created samples. Changing QIDs will NOT associate already uploaded tests. Do you wish to continue with the QID and other changes?",
            })
        }
        if (samplesDeleted) {
            deleteIndex = dialogs.length
            dialogs.push({
                title: "Delete Samples?",
                message:
                    "You removed samples from this set, do you wish to delete them from the database? This cannot be undone.",
                yes: "Delete Samples",
                cancel: "Only Remove from Set",
                onClose(val: any) { deleteSamples.current = !!val }
            })
        }
        return enqueueDialogs({
            propArray: dialogs,
            onYes: async () => handleSave(deleteSamples.current),
            onCheck: (res: any, vals: any[]) => res || vals.every((v, i) => v || i === deleteIndex)
        })
    }, [validateAll, userIsContributor, qidChanges, samplesDeleted, enqueueDialogs, enqueueSnackbar, handleSave])

    return onSave
}
async function preSetSave(sampleSet: SampleSet, initialSampleSet: SampleSet, accountName: any) {
    const sampleSetTags = sampleSet.tags || []
    const initialSetTags = initialSampleSet.tags || []

    //return symmetrical difference of the two sets to propagate tags to samples
    //ref. https://medium.com/@alvaro.saburido/set-theory-for-arrays-in-es6-eb2f20a61848
    const tagDiffs = initialSetTags
        .filter(x => !sampleSetTags.includes(x))
        .concat(sampleSetTags.filter(x => !initialSetTags.includes(x)))

    const samples = (sampleSet.samples || [])
        .filter(sample => !!sample)
        .map(sample => {
            // filter empty instances, delete reverse data id
            const properties = (sample.properties || []).filter(
                p =>
                    p.definition?.id &&
                    p.data !== undefined &&
                    p.data !== null &&
                    p.data !== "",
            )
            const components = (sample.components || []).filter(
                c => c.definition?.id && parseFloat(c.amount as any),
            )
            const processSteps = (sample.processSteps || []).filter(
                c => c.definition?.id,
            )

            //propagate new sample set tags to each sample
            let tags = sample.tags?.slice() || []
            if (!tags?.length) {
                sampleSetTags.forEach(setTag => {
                    tags.push(setTag)
                })
            } else {
                tagDiffs.forEach(setTag => {
                    if (!tags.find(tag => tag === setTag)) {
                        tags.push(setTag)
                    } else {
                        tags = tags.filter(tag => tag !== setTag)
                    }
                })
            }

            // make sure samples have same lab associations as the set?
            let labs = sample.labs?.slice() || []
            let labsToRemove: any[] = []
            sampleSet.labs?.forEach(setLab => {
                if (
                    !labs.find(
                        lab =>
                            lab.lab?.id === setLab.lab?.id &&
                            lab.accessLevel === setLab.accessLevel,
                    )
                ) {
                    // Add labs from a sample set to the linked samples for inheritance.
                    labs.push(setLab)
                }
            })
            initialSampleSet.labs?.forEach(popLab => {
                if (
                    !sampleSet.labs?.find(
                        lab =>
                            lab.lab?.id === popLab.lab?.id &&
                            lab.accessLevel === popLab.accessLevel,
                    )
                ) {
                    // Check to remove labs from a sample set of the linked samples for inheritance.
                    labsToRemove.push(popLab)
                }
            })
            labsToRemove.forEach(setLab => {
                if (
                    labs.find(
                        lab =>
                            lab.lab?.id === setLab.lab.id &&
                            lab.accessLevel === setLab.accessLevel,
                    )
                ) {
                    // Remove the labs if they match the id and accessLevel to remove from samples.
                    labs = labs.filter(
                        lab =>
                            lab.lab?.id !== setLab.lab.id ||
                            lab.accessLevel !== setLab.accessLevel,
                    )
                }
            })

            // add formulation category
            const categories = [...(sample.categories || [])]
            if (!sample.id && !categories.includes("formulation"))
                categories.push("formulation")

            // clear empty qids
            const qid = sample.qid ? sample.qid : undefined
            
            // For those deleting notes in the master table, this needs to be forced to empty string
            // so it actually patches.
            if (!sample.notes) sample.notes = "";

            const saveSample = new Sample({
                ...sample,
                qid,
                properties,
                components,
                processSteps,
                labs,
                categories,
                tags,
                tests: undefined,
            })
            // remove reverse references to set and processChart
            delete saveSample.processView
            delete saveSample.sets
            delete saveSample.processChart
            return saveSample
        })
        .map((sample, i) => {
            // clear empty samples
            if (checkEmptySample(sample)) return undefined

            // add user to contributors list
            sample.contributors = (sample.contributors || [])
                .filter(c => c !== accountName)
                .concat([accountName])

            // force a sample title if none
            sample.title =
                (sample.title !== "" && sample.title) ||
                `${sampleSet.title} ${i + 1}`
            return sample
        })
        .filter(v => v !== undefined)

    // fill any missing qids
    const missingQIDSamples = samples.filter(
        sample => !sample?.qid || sample.qid === "",
    )
    if (missingQIDSamples.length > 0) {
        const currentQIDs = samples
            .map(sample => sample?.qid)
            .filter(qid => !!qid)
        // do current qids fit the standard pattern?
        let fillDefault = true
        if (currentQIDs.length) {
            const qidRoot = currentQIDs[0]?.slice(0, 11)
            if (
                currentQIDs[0]?.match(/^[0-9]{6}-[A-Z0-9]{3}-[0-9]+/i) &&
                currentQIDs.every(qid => qid?.slice(0, 11) === qidRoot)
            ) {
                fillDefault = false
                let lastNumber = 0
                let decades = 0
                for (let numLength = 2; numLength < 7; ++numLength) {
                    const lastSample = await getQueryFC<Sample>({
                        Model: "Sample",
                        fields: ["qid"],
                        filter: {
                            name: "qid",
                            op: "~",
                            val: `^${qidRoot}[0-9]{${numLength}}`,
                        },
                        pageNumber: 1,
                        pageSize: 1,
                        sort: "-qid",
                    })
                    if (lastSample?.count) {
                        lastNumber = Number(lastSample.data[0].qid?.slice(11))
                        decades =
                            Math.floor(
                                (lastNumber + missingQIDSamples.length) / 10,
                            ) - Math.floor(lastNumber / 10)
                        if (numLength + decades >= 7) {
                            fillDefault = true
                            break
                        }
                    }
                    decades--
                    if (decades < 0) {
                        break
                    }
                }
                if (!fillDefault) {
                    missingQIDSamples.forEach((sample, i) => {
                        if (!sample) return
                        lastNumber++
                        const number =
                            lastNumber < 10
                                ? "0" + lastNumber.toString()
                                : lastNumber.toString()
                        sample.qid = qidRoot + number
                    })
                }
            }
        }
        if (fillDefault) {
            let fallback = true
            const splitName = accountName.split(" ")
            if (splitName.length > 1) {
                const dateRoot = format(new Date(), "yyMMdd")
                const initials =
                    splitName[0][0] + splitName[splitName.length - 1][0]
                const qidRoot = `${dateRoot}-${initials}`
                const last = await getQueryFC<Sample>({
                    Model: "Sample",
                    fields: ["qid"],
                    filter: {
                        name: "qid",
                        op: "~",
                        val: `^${qidRoot}[0-9]-[0-9]+`,
                    },
                    pageNumber: 1,
                    pageSize: 1,
                    sort: "-qid",
                })

                const lastNumber = Number(
                    (last?.data?.length && last.data[0].qid?.[9]) || 0,
                )
                if (lastNumber < 9) {
                    fallback = false
                    missingQIDSamples.forEach((sample, i) => {
                        if (!sample) return
                        let number = (i + 1).toString()
                        if (i < 9) number = "0" + number // fill with leading zero if less than 10
                        sample.qid = `${qidRoot}${lastNumber + 1}-${number}`
                    })
                }
            }
            if (fallback) {
                const QIDs = await getQIDs(missingQIDSamples.length)
                missingQIDSamples.forEach(
                    (sample, i) => { if (sample) sample.qid = QIDs[i] || undefined },
                ) // safe to mutate since new samples have been created above
            }
        }
    }

    // update process chart
    const contributors = (sampleSet.contributors || [])
        .filter(c => c !== accountName)
        .concat([accountName])
    const savedSet = new SampleSet({
        ...sampleSet,
        flowChart: undefined,
        contributors,
        samples: samples as Sample[],
    })
    delete savedSet.processChart

    return savedSet
}

type useSavedProps = { navigate: NavigateFn, updateData: any }
export function useSaved({ navigate, updateData }: useSavedProps) {
    return useCallback(
        // @ts-ignore
        saved => {
            updateData(saved)
            navigate(`./${saved.sampleSet.id}`)
            return saved
        },
        [navigate, updateData],
    )
}

type useDeleteProps = { mutateAsync: any, sampleSet: SampleSet, enqueueDialogs: any }
export function useDelete({ mutateAsync, sampleSet, enqueueDialogs }: useDeleteProps) {
    // @ts-ignore
    const handleDelete = useCallback(async (deleteSamples) => {
        const allow = { delete: true }
        await mutateAsync(
            {
                param: {
                    Model: "SampleSet",
                    allow: { delete: true },
                    relationships: deleteSamples
                        ? {
                            samples: {
                                allow: { delete: true },
                                relationships: {
                                    properties: { allow },
                                    processSteps: { allow },
                                    components: { allow },
                                },
                            },
                        }
                        : undefined,
                },
                previous: sampleSet,
            },
            {
                errorMessage: () => `Error: Failed to delete Sample Set`,
                successMessage: () => `Sample Set deleted.`,
            },
        )
    }, [mutateAsync, sampleSet])

    return useCallback(async () => {
        const deleteSamples = { current: false }
        return enqueueDialogs({
            propArray: [{
                title: "Deleting Set",
                message: "Deleting cannot be undone, are you sure you wish to permanently delete this set?",
                yes: "Delete",
                // checkBoxMessage: "Delete All Samples in Set",
                // onChecked(checked: boolean) { deleteSamples.current = checked }
            }],
            onYes: async () => handleDelete(deleteSamples.current),
        })
    }, [enqueueDialogs, handleDelete])
}

type useCopyProps = { enqueueSnackbar: any, sampleSet: SampleSet, processChart: ProcessChart | undefined, updateData: (update: StateData, options?: { name?: string, replace?: boolean }) => void }
export function useCopy({ enqueueSnackbar, sampleSet, processChart, updateData }: useCopyProps) {
    const onCopy = useCallback(
        async () => {
            const newSet = new SampleSet({
                ...sampleSet,
                id: undefined,
                contributors: undefined,
                title: undefined,
                processChart: undefined,
                samples: sampleSet.samples?.map(
                    sample =>
                        new Sample({
                            id: undefined,
                            contributors: undefined,
                            qid: undefined,
                            tests: undefined,
                            processChart: undefined,
                            components: sample.components?.map(
                                instance =>
                                    new SampleInstance({
                                        ...instance,
                                        id: undefined,
                                    }),
                            ),
                            processSteps: sample.processSteps?.map(
                                instance =>
                                    new ProcessInstance({
                                        ...instance,
                                        id: undefined,
                                    }),
                            ),
                            properties: sample.properties?.map(
                                instance =>
                                    new PropertyInstance({
                                        ...instance,
                                        id: undefined,
                                    }),
                            ),
                        }),
                ),
            }
            )
            const newProcessChart = processChart && new ProcessChart({ ...processChart, id: undefined, sets: undefined, samples: undefined })
            updateData({ processChart: newProcessChart, sampleSet: newSet }, { name: "Copy Set", replace: true })
            enqueueSnackbar(
                `${sampleSet.title
                } copied. Click save to upload.`,
                { variant: "info" },
            )
        },
        [enqueueSnackbar, processChart, sampleSet, updateData],
    )
    return sampleSet?.id ? onCopy : undefined
}

export function updateSamplesOnNodeDelete(newSamples: Sample[], samples: Sample[], newChart: ProcessChart, processChart: ProcessChart | undefined, nodeAliases?: NodeAliases): Sample[] {
    if (!processChart?.nodes) return newSamples

    function checkInstances(index: number, nodeId: string, instKey: "components" | "processSteps" | "properties") {
        const sample = samples[index]
        if (sample?.[instKey]) {
            for (let i = 0; i < (sample[instKey]?.length || 0); ++i) {
                if (sample[instKey]?.[i]?.nodeId === nodeId) {
                    if (newSamples === samples) {
                        newSamples = [...samples]
                    }
                    if (newSamples[index] === samples[index]) {
                        newSamples[index] = new Sample(newSamples[index])
                    }
                    if (newSamples[index][instKey] === samples[index][instKey]) {
                        newSamples[index][instKey] = [...(newSamples[index][instKey] || [])] as any
                    }
                    (newSamples[index][instKey] as any[]).splice(i, 1)
                }
            }
        }
    }

    for (const nodeId of Object.keys(processChart.nodes)) {
        if (!newChart.nodes?.[nodeId]) {
            for (let i = 0; i < newSamples.length; ++i) {
                const aliasNodeId = applyNodeAlias(nodeId, nodeAliases, newSamples[i]?.id)
                checkInstances(i, aliasNodeId, "components")
                checkInstances(i, aliasNodeId, "processSteps")
                checkInstances(i, aliasNodeId, "properties")
            }
        }
    }
    return newSamples
}

type StateData = { sampleSet: SampleSet; processChart?: ProcessChart }

export function useEditSampleSetState(initialSampleSet: SampleSet, defsRef: any) {
    const [selectedNodes, setSelectedNodes] = useState([])
    const [tableSelected, setTableSelected] =
        useState<{ samples: number[]; columns: number[] }>()

    const invalidators = useMemo(
        () => makeInvalidators(initialSampleSet),
        [initialSampleSet],
    )
    const [sampleSetStore, setSampleSetStore] = useAppStoreKey("EditSampleSet")

    const initialState: State<StateData> = useMemo(() => {
        let processChart = initialSampleSet?.processChart
        if (!processChart) {
            processChart = initialSampleSet.flowChart
                ? _ProcessChartFromOldFlowchart(
                    initialSampleSet.flowChart as any,
                )
                : new ProcessChart()
        }
        processChart = updateProcessNodesFromSamples(
            processChart,
            initialSampleSet.samples,
            initialSampleSet.nodeAliases,
        )
        let rtn
        if (
            sampleSetStore &&
            initialSampleSet?.id === sampleSetStore?.data?.sampleSet?.id
        ) {
            if (sampleSetStore.data.processChart) rtn = sampleSetStore
            rtn = {
                ...sampleSetStore,
                data: { ...sampleSetStore.data, processChart },
            }
        }
        rtn = { data: { sampleSet: initialSampleSet, processChart } }
        return rtn
    }, [initialSampleSet, sampleSetStore])

    const { state, data, status, updateData, validateAll, undoRedo } =
        useFormState({
            initialState,
            validators: invalidators,
            transforms: useTransforms(defsRef),
        })
    const undo = useMemo(
        () => ({ callback: undoRedo.onUndo, name: undoRedo.undoName }),
        [undoRedo.onUndo, undoRedo.undoName],
    )
    const redo = useMemo(
        () => ({ callback: undoRedo.onRedo, name: undoRedo.redoName }),
        [undoRedo.onRedo, undoRedo.redoName],
    )
    // create sampleSet update function
    const updateSampleSet = useCallback(
        // @ts-ignore
        (updates, name?: string, replace?: boolean) => {
            updateData(updates, {
                name,
                path: "sampleSet",
                method: replace ? "replace" : undefined,
            })
        },
        [updateData],
    )

    // create processChart update function
    const updateProcessChart = useCallback(
        // @ts-ignore
        (updates, name?: string, replace?: boolean) => {
            updateData(updates, {
                name,
                path: "processChart",
                method: replace ? "replace" : undefined,
            })
        },
        [updateData],
    )

    // update AppStore on unexpected unmount
    const closedRef = useRef(false)
    const prevState = useRef(state)
    useEffect(() => {
        prevState.current = state
    })
    useEffect(
        () => () => {
            if (!closedRef.current) setSampleSetStore(prevState.current)
        },
        [setSampleSetStore],
    )

    return {
        data,
        status,
        updateData,
        updateSampleSet,
        updateProcessChart,
        validateAll,
        undo,
        redo,
        selectedNodes,
        setSelectedNodes,
        tableSelected,
        setTableSelected,
        setSampleSetStore,
        closedRef,
    }
}

function makeInvalidators(initSampleSet: SampleSet) {
    return [
        {
            path: "sampleSet.title",
            validator: (title?: string, { saveAs }: any = {}) => {
                if (!title || title === "") return "Set Name required"
                else if (saveAs && title === initSampleSet.title)
                    return "Set Name must be unique"
            },
        },
        {
            path: "sampleSet.labs",
            validator: checkLabs,
        },
        {
            path: "sampleSet.samples[].qid",
            validator: (qid?: string) =>
                qid && !simpleString(qid)
                    ? "A qid cannot have special characters"
                    : undefined,
        },
    ] as Validator<StateData>[]
}

function useTransforms(defsRef: any) {
    return useMemo<UpdateTransform<StateData>[]>(() => [
        { transform: deleteNodeUpdateTransform },
        { transform: sampleSetUpdateTransform, path: "sampleSet" },
        { transform: sampleSetToProcessChartTransform, field: "sampleSet.samples" },
        { transform: buildDefsTransform(defsRef), path: "sampleSet" },
        { transform: buildAddCalcProps(defsRef) },
        { transform: updateCalculatedPropsTransform, path: "sampleSet" },
    ], [defsRef])
}

function deleteNodeUpdateTransform(
    updated: StateData,
    current: StateData,
    status: any,
    options: any,
) {
    if (
        options?.name === "Delete Node" &&
        updated.processChart &&
        updated.processChart !== current.processChart
    ) {
        let sampleSet = updated.sampleSet
        if (sampleSet?.samples) {
            const newSamples = updateSamplesOnNodeDelete(
                sampleSet.samples as Sample[],
                sampleSet.samples as Sample[],
                updated.processChart,
                current.processChart as ProcessChart | undefined,
            )
            if (newSamples !== sampleSet.samples) {
                sampleSet = new SampleSet({
                    ...sampleSet,
                    samples: newSamples,
                } as SampleSet)
            }
        }
        updated.sampleSet = sampleSet
    }
    return updated
}
function sampleSetUpdateTransform(
    updated: SampleSet,
    sampleSet: SampleSet,
    status: any,
    options: any,
) {
    // wipe info of cleared samples and protect against undefined samples
    if (updated.samples && updated.samples !== sampleSet.samples) {
        for (let i = 0; i < (updated.samples.length || 0); ++i) {
            if (updated.samples[i] !== sampleSet.samples?.[i]) {
                if (
                    updated.samples[i]?.id &&
                    checkEmptySample(updated.samples[i])
                )
                    updated.samples[i] = new Sample()
            }
            if (!updated.samples[i]) {
                updated.samples[i] = new Sample()
            }
        }
    }

    // TODO: handle updating derrived property values
    return updated
}

function sampleSetToProcessChartTransform(
    updated: StateData,
    current: StateData,
    status: any,
    options: any,
) {
    const sampleSet = updated.sampleSet || current.sampleSet
    updated.processChart = updateProcessNodesFromSamples(
        updated.processChart || current.processChart || new ProcessChart(),
        sampleSet.samples,
        sampleSet.nodeAliases,
    )
    return updated
}

function buildDefsTransform(defsRef: any) {
    return (
        updated: SampleSet,
        current: SampleSet,
        status: any,
        options: any) => {
        if (!defsRef?.current) return updated
        return addDefsToSet(updated, current, defsRef.current.materialDefs, defsRef.current.processDefs, defsRef.current.propertyDefs)
    }
}

function buildAddCalcProps(defsRef: any) {
    return (
        updated: StateData,
        current: StateData,
        status: any,
        options: any) => {
        if (!defsRef?.current) return updated
        updated.sampleSet = addSampleSetCalProps(updated?.sampleSet, current?.sampleSet, updated.processChart?.nodes, defsRef.current.propertyDefs)
        return updated
    }
}

function updateCalculatedPropsTransform(
    updated: SampleSet,
    current: SampleSet,
    status: any,
    options: any,
) {
    return updateSetCalculatedProperties(updated, current)
}