import React, { useCallback, useState, useEffect } from "react"
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import { useSnackbar } from "notistack"
import { useParams, useNavigate, useLocation } from "@reach/router"
import Typography from '@material-ui/core/Typography';
import { useAppStoreDispatchKey, useAppStoreKey } from "AppStore"
import { makeStyles } from '@material-ui/core/styles';
import common_styles from 'styles/common_styles';
import { aichemyProtoAxios } from "API/mmAxios";
import DeleteModelDialog from '../Dialogs/DeleteModelDialog';
import LoadingOverlay from '../../General/LoadingOverlay';
import ModelsTable from "./ModelsTable";
import { GetModelList } from "./ListModels";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui//icons/Refresh';
import { defaultMixingStudioState, getMSConfig } from "../utils";

const useStyles = makeStyles((theme) => ({
  ...common_styles(theme),
  importButton: {
    color: theme.palette.primary.main,
    paddingRight: 10,
  },

  importSpan: {
    '&:hover': {
      backgroundColor: '#00000005'
    },
    display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 5
  }

}));

function MsCreateModel() {
  const { workflowId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [dataSelectionContext, setDataSelectionContext] = useAppStoreKey("DataSelectionContext");
  const [modelContext, setModelContext] = useAppStoreKey("ModelContext");
  const [workflow, setWorkflow] = useAppStoreKey("Workflow");
  const [, setMSState] = useAppStoreKey('MixingStudioState')
  const [MSConfig, setMSConfig] = useAppStoreKey('MixingStudioConfig')
  const [activeStep, setActiveStep] = useAppStoreKey("ActiveStep");

  const setStashedSinglePredictionData = useAppStoreDispatchKey("MS/Predict/Single")
  const setStashedBatchPredictionData = useAppStoreDispatchKey("MS/Predict/Batch")
  const setStashed1DSweepData = useAppStoreDispatchKey("MS/Predict/1D-Sweep")
  const setTrainingState = useAppStoreDispatchKey("MS/Training/JobState")
  const setTrainingTab = useAppStoreDispatchKey("MS/Training/CurrentTab")

  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState(modelContext && modelContext.uploadedFileName ? modelContext.uploadedFileName : undefined);
  const [percentCompleted, setPercentCompleted] = useState(undefined);
  const [completed, setCompleted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [modelDialogContext, setModelDialogContext] = useAppStoreKey("ModelDialogContext");
  const [selectedModelLoading, setSelectedModelLoading] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentModels, setCurrentModels] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [deleteContext, setDeleteContext] = useState({
    type: "",
    model_idx: "",
    workflow_id: "",
    model_name: ""
  });

  const clearStashedData = useCallback(() => {
    setStashed1DSweepData(undefined)
    setStashedBatchPredictionData(undefined)
    setStashedSinglePredictionData(undefined)
    setTrainingState(undefined) // remove polling tasks
    setTrainingTab(undefined) // unset the current tab used in prediction
  }, [setStashed1DSweepData, setStashedBatchPredictionData, setStashedSinglePredictionData, setTrainingState, setTrainingTab])

  const deleteModel = (model) => {
    setDeleteContext({
      type: workflow?.uuid === model.original.workflow_id ? "current" : "selected",
      workflow_id: model.original.workflow_id,
      model_name: model.original.model_title,
      model_idx: model.id
    })
    setModelDialogContext({ ...modelDialogContext, deleteOpen: true, deleted: false });
  }

  useEffect(() => {
    if (activeStep === 0 || workflow === undefined) {
      GetModelList(setCurrentModels, setIsLoading)
    }
  }, [activeStep, workflow])


  const LoadModelsClick = useCallback((model) => {
    let wf_uuid = model.original.workflow_id
    setSelectedModelLoading(wf_uuid);
    clearStashedData()
    let url = `workflow/` + wf_uuid + `/load`
    let config = {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    }

    aichemyProtoAxios.get(url, config)
      .then(res => {
        let wf = res.data
        // let data_ap = wf.data
        // Object.keys(data_ap.data_df).forEach(key => data_ap.data_df[key] = JSON.parse(data_ap.data_df[key]))
        // wf.data = data_ap
        setWorkflow(wf)
        setMSState({ ...defaultMixingStudioState(wf), nModel: wf.models.length })
        enqueueSnackbar(`Successfully loaded model ${wf.name}.`, { variant: "success" });
      }).catch(err => {
        console.error(err);
        enqueueSnackbar("Error loading model.", { variant: "error" });
        navigate("/MixingStudio", { replace: false })
        setSelectedModelLoading("");
      })
  }, [clearStashedData, enqueueSnackbar, setMSState, setWorkflow, navigate])


  useEffect(() => {
    // check if redirect was due to the delete
    if (location?.state?.deleted) {
      navigate("/MixingStudio", { replace: true })
      // nuke any remaining state, needs to clear here otherwise can throw due to other views updating
      setWorkflow(undefined)
    } else if (selectedModelLoading === workflow?.uuid) {
      // fully loaded in and able to advance pages
      setSelectedModelLoading("")
      setActiveStep(1)
      navigate(`/MixingStudio/${workflow.uuid}`, { replace: false })
    } else if (selectedModelLoading === "" && workflowId !== "" && workflow?.uuid !== workflowId) {
      const model = { original: { workflow_id: workflowId } }
      LoadModelsClick(model)
    }
  }, [workflowId, workflow, LoadModelsClick, location, navigate, setWorkflow, selectedModelLoading, setActiveStep])

  const onUploadClick = () => hiddenFileInput.current.click()

  const onChangeFile = () => {
    setSelectedFile(document.getElementById("singleFileInput").files[0].name);
    setModelContext({ ...modelContext, uploadedFileName: document.getElementById("singleFileInput").files[0].name })
    loadFile();
  };

  const removeFile = () => {
    setCompleted(false);
    setSelectedFile(undefined);
    setPercentCompleted(undefined);
    setModelContext({ ...modelContext, sourceId: "", selectedFileSheet: undefined, modelName: "", fileSheets: undefined, allData: [], loadType: "", dataSnapshot: undefined, fileUploaded: false, uploadedFileName: "" });
  };

  const progressUpdate = (event) => {
    setPercentCompleted(Math.round((event.loaded * 100) / event.total));
  }

  const loadFile = () => {
    progressUpdate(0);
    clearStashedData()
    setModelContext({ ...modelContext, sourceId: "", selectedFileSheet: undefined, modelName: "", fileSheets: undefined, allData: [], loadType: "", dataSnapshot: undefined, fileUploaded: false, uploadedFileName: "" });
    setDataSelectionContext({ ...dataSelectionContext, inputHeaders: { selected_input_headers: [] }, outputHeaders: { selected_output_headers: [] }, unselected_all_headers: [] })
    let data = new FormData();
    data.append("input_file", document.getElementById("singleFileInput").files[0]);
    data.append("name", document.getElementById("singleFileInput").files[0].name);
    data.append("description", 'Temporary saved workflow');
    let config = {
      onUploadProgress: (event) => progressUpdate(event),
    };
    navigate("/MixingStudio", { replace: true })
    aichemyProtoAxios.post("workflow", data, config).then((res) => {
      let response = res.data;
      let wf = response.workflow
      setWorkflow(wf)
      setCompleted(true);
      setModelContext({ ...modelContext, modelName: wf.name, loadedModelData: wf.name })
      setMSState(defaultMixingStudioState())
      setActiveStep(1)
      navigate(`/MixingStudio/${wf.uuid}`, { replace: false })
    })
      .catch((error) => {
        console.error(error);
        setSelectedFile(undefined)
        enqueueSnackbar("Error in processing uploaded file. Please upload an excel/csv file with input variables as columns, and experiments as rows.", { variant: "error" });
      });

    // init MixingStudio config
    if (MSConfig === undefined) {
      getMSConfig(setMSConfig)
    }
  };

  return (
    <div >
      {(completed || modelContext.loadType === "new") && selectedFile && <span style={{ display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20 }}>
        <Typography style={{ marginRight: 10 }} className={classes.cardTitle} gutterBottom>
          Uploaded File:
        </Typography>
        <Chip label={selectedFile} onDelete={removeFile} color="primary" />
      </span>}
      {!completed && selectedFile && percentCompleted && <LoadingOverlay loadingText="Uploading File..." filled progress={percentCompleted} />}

      <Card className={classes.paperBody} elevation={1}>
        {selectedModelLoading !== "" && <LoadingOverlay loadingText="Loading Model Data..." filled />}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 12 }}>
          <div>
            <Typography className={classes.cardTitle} gutterBottom>
              Models
            </Typography>
            <Typography className={classes.cardBodyText}>
              Select an existing model from the list below, or upload a file to begin creating a new model.
            </Typography>
          </div>
          <div>

            <Tooltip title={"Refresh model list"}>
              <span>
                <IconButton
                  disabled={isLoading}
                  // variant="contained"
                  // size="medium"
                  color="inherit"
                  onClick={() => GetModelList(setCurrentModels, setIsLoading)}
                  className={classes.button}
                  style={{ marginTop: 18 }}
                >
                  <RefreshIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Button variant="contained" color="primary" style={{ marginTop: 18 }} onClick={onUploadClick}>
              <Typography variant='button'>
                + Upload File
              </Typography>
              <input
                ref={hiddenFileInput}
                id="singleFileInput"
                type="file"
                onChange={onChangeFile}
                data-cy="choose_file_btn"
                hidden
              />
            </Button>
          </div>
        </div>
        {/*<TableButtonGroup>*/}
        {/*  */}
        {/*</TableButtonGroup>*/}
        <ModelsTable loading={isLoading} modelsData={currentModels} onDelete={(model) => deleteModel(model)} onOpen={(model) => LoadModelsClick(model)} onPageChange={() => { }} />
        {(deleteContext.type !== "" && (
          <DeleteModelDialog
            navigate={navigate}
            deleteContext={deleteContext}
            setDeleteContext={setDeleteContext}
            currentModels={currentModels}
            setCurrentModels={setCurrentModels}
          />
        ))}
      </Card>
    </div>
  );
}

export default React.memo(MsCreateModel);