import React, { useEffect } from "react";
import { Input, InputLabel, MenuItem} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SelectParam({funcName, param, multi, selectedParams, setSelectedParams}) {
  const classes = useStyles();
  const getValue = () => {
    if (Object.keys(selectedParams).indexOf(funcName) === -1) {
      setSelectedParams({...selectedParams, [funcName]: multi ? [] : ''});
      return multi ? [] : ''
    } else {
      return selectedParams[funcName]
    }
  }

  const handleChange = (event) => {
    setSelectedParams({...selectedParams, [funcName]: event.target.value})
  }

  return <>
    <FormControl className={classes.formControl}>
      <InputLabel id="select-param">{funcName}</InputLabel>
      <Select
        id="select-param"
        multiple={multi}
        value={getValue()}
        onChange={handleChange}
        input={<Input id="select-param" />}
        renderValue={(selected) => {
          return  <div className={classes.chips}>
              {multi ? selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              )) : <Chip key={selected} label={selected} className={classes.chip} />}
            </div>;
        }}
        MenuProps={MenuProps}
      >
        {param.value.map((name) => {
          return <MenuItem key={name} value={name}>
            {name}
          </MenuItem>;
        })}
      </Select>
    </FormControl>
  </>
}

function SliderParam({funcName, param, valueType, selectedParams, setSelectedParams}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const getValue = () => {
    if (Object.keys(selectedParams).indexOf(funcName) === -1) {
      setSelectedParams({...selectedParams, [funcName]: param.default ? param.default : (param.max - param.min)/2})
      return param.default ? param.default : (param.max - param.min)/2
    } else {
      return selectedParams[funcName]
    }
  }
  // check if value is in range
  useEffect(() => {
    if (selectedParams[funcName] < param.min) {
      enqueueSnackbar(`${funcName} value is too small.`, {variant: 'error'});
      setSelectedParams({...selectedParams, [funcName]: param.min})
    } else if (selectedParams[funcName] > param.max) {
      enqueueSnackbar(`${funcName} value is too big.`, {variant: 'error'});
      setSelectedParams({...selectedParams, [funcName]: param.max})
    }
  }, [selectedParams, funcName, param.min, param.max, enqueueSnackbar, setSelectedParams])

  const handleInputChange = (event) => {
    setSelectedParams({...selectedParams, [funcName]: event.target.value === '' ? '' : Number(event.target.value)})
  }

  const handleSliderChange = (event, newValue) => {
    setSelectedParams({...selectedParams, [funcName]: newValue})
  }

  const getStep = () => {
    if (valueType === 'int') {
      return 1
    } else {
      return (param.max - param.min)/100
    }
  }

  return <>
    <FormControl className={classes.formControl}>
      <Typography id="input-slider" variant="subtitle2" gutterBottom style={{color: 'grey'}}>
        {funcName}
      </Typography>
      <FormControl style={{ marginLeft: 0, width: "100%" }} >
        <Input
          value={getValue()} // remove leading zeros from number
          type="number"
          onChange={handleInputChange}
          style={{ marginTop: 0 }}
          onWheel={event => { event.preventDefault()}}
        />
      </FormControl>
      <Slider
        value={Number(getValue())} // need to convert for purposes of handling the slider
        min={param.min}
        max={param.max}
        onChange={handleSliderChange}
        step={getStep()}
      />
    </FormControl>
  </>
}




export default function FpFuncParams({params, funcName, selectedParams, setSelectedParams}) {

  // reset selected params when funcName changes
  useEffect(() => {
    setSelectedParams({})
  }, [funcName, setSelectedParams])

  return <>
    {Object.keys(params).length > 0 && Object.keys(params).map(paramKey => {
      if (params[paramKey].type === "multi_select" || params[paramKey].type === "single_select") {
        return <SelectParam
          key={paramKey}
          funcName={paramKey}
          param={params[paramKey]}
          multi={params[paramKey].type === "multi_select"}
          selectedParams={selectedParams}
          setSelectedParams={setSelectedParams}/>
      } else if (params[paramKey].type === 'int' || params[paramKey].type === 'float') {
        return <SliderParam
          key={paramKey}
          funcName={paramKey}
          param={params[paramKey]}
          valueType={params[paramKey].type}
          selectedParams={selectedParams}
          setSelectedParams={setSelectedParams}
        />
      }  else  {
        console.error("unknown param type: " + params[paramKey].type)
        return ''
      }
    })}
  </>
}

