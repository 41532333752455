import React, { useMemo, useEffect, useState } from 'react';
import { useQueryFC } from '../../API/queryHooks';
import LoadingOverlay from './LoadingOverlay';
import { useNavigate } from '@reach/router';
import { useSnackbar } from 'notistack';
import { useAppStoreDispatchKey } from '../../AppStore';
import { mergeSamples, mergeSampleSets } from 'utils/schemaUtils';

/**
 * Component that when rendered will automatically start building a merged set. Once the set merge is complete it will force a navigation to EditSampleSet with the merged set loaded.
 * @param props
 * @param {string[] | undefined} props.sampleIds optional sample ids to merge into the new set
 * @param {string[] | undefined} props.setIds optional setIds to merge
 * @param {string} props.failRoute optional route to forward to on fail. Default SampleSetLibrary
 * @param onFail optional callback function to be called if merge fails
 * @returns 
 */
export function CompileSet({sampleIds=undefined, setIds=undefined, failRoute="/SampleSetLibrary/", onFail = undefined}){
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const setSampleSetStore = useAppStoreDispatchKey("EditSampleSet");
    const setInitialSet = useAppStoreDispatchKey("InitSampleSet");
    const queryParam = useMemo(() => {
        if (sampleIds?.length) {
            return {
                Model: "Sample",
                fields: ["labs.lab", "components", "processSteps", "properties", "processChart", 
                {
                    name: "tests",
                    fields: ["instrument"],
                    filter: {
                        name: "valid",
                        op: "eq",
                        val: true,
                    },
                }],
                filter: sampleIds
            }
        }
        else if (setIds?.length){
            return {
                Model: "SampleSet",
                fields: ["labs.lab", "samples.labs.lab", "samples.components", "samples.processSteps", "samples.properties", "processChart",
                {
                    name: "samples.tests",
                    fields: ["instrument"],
                    filter: {
                        name: "valid",
                        op: "eq",
                        val: true,
                    },
                },],
                filter: setIds
            }
        }
        return undefined;
    }, [sampleIds, setIds]);
    const {data: resolvedData, isFetching} = useQueryFC(queryParam, {logName: "Compiled Set", keepPreviousData: true});

    const [compiledSet, setCompiledSet] = useState();

    useEffect(() => {
        if (compiledSet){
            const sampleSet = compiledSet
            const processChart = compiledSet?.processChart
            setInitialSet(sampleSet);
            setSampleSetStore({data: { sampleSet, processChart }});
            navigate("/SampleSetLibrary/new");
        }
    }, [compiledSet, enqueueSnackbar, failRoute, navigate, setInitialSet, setSampleSetStore])

    useEffect(() => {
        if (queryParam && !isFetching){
            if (resolvedData?.count > 0){
                if (setIds){
                    const sets = [...resolvedData.data];
                    setCompiledSet(mergeSampleSets(sets))
                }
                else if (sampleIds) {
                    const samples = [...resolvedData.data]
                    setCompiledSet(mergeSamples(samples))
                }
            }
            else{
                enqueueSnackbar("Failed to build set", {variant: "error"});
                navigate(failRoute);
                onFail && onFail()
            }
        }
    }, [enqueueSnackbar, failRoute, isFetching, navigate, onFail, queryParam, resolvedData, sampleIds, setIds, setSampleSetStore]);

    return (
        queryParam ? <LoadingOverlay filled loadingText="Building Set..."/> : null
    )
}