import { useQueryFC } from 'API/queryHooks';
import { createContext, useContext } from 'react';

export const DefaultLabContext = createContext(undefined);
export function useDefaultLab(){ return useContext(DefaultLabContext) }
DefaultLabContext.displayName = "DefaultLabContext";

/** @type QueryParam<Lab> */
const queryParams = {
    Model: "Lab",
    filter: process.env.REACT_APP_DEFAULT_LAB_ID,
    fields: ["id", "title", "description"],
    pageNumber: 1,
    pageSize: 1
}

export default function DefaultLabProvider({children}){
    const {data} = useQueryFC(queryParams, {errorMessage: () => "Error: Failed to get default lab", logName: "Default Lab", staleTime: 60*60*1000, cacheTime: 60*60*1000});
    return (
        <DefaultLabContext.Provider value={data?.data[0]}>
            {children}
        </DefaultLabContext.Provider>
    );
}