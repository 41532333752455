import { aichemyProtoAxios } from "../../../API/mmAxios"
import { poll } from "../msUtils/utils"
import { checkTaskStatus, getTaskResults } from "../utils"

const SinglePointPrediction = (workflowID, inputData, modelIndex, onSuccess, onFailure) => {
  let url = `workflow/${workflowID}/predict`;
  let config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }

  let pred_data = {}
  Object.keys(inputData).forEach((key) => {
    pred_data[key] = { '0': inputData[key] }
  })
  let body = JSON.stringify({ pred_data: pred_data, model_idx: modelIndex })

  aichemyProtoAxios.post(url, body, config)
    .then(res => { onSuccess && onSuccess(res.data) })
    .catch(err => onFailure && onFailure(err))
}

const TaskPolling = (currentTaskID, row_remap, onSuccess, onFailure) => {
  const IsComplete = (res) => {
    return res ? ["complete", "complete#error", "complete#success"].includes(res.data.status) : false
  }
  const IsSuccess = (res) => {
    return res ? ["complete", "complete#success"].includes(res.data.status) : false
  }
  if (currentTaskID) {
    let interval = 1000; // ms
    poll(checkTaskStatus, IsComplete, interval, currentTaskID)
      .then(res => {
        getTaskResults(currentTaskID)
          .then(taskResult => {
            if (IsSuccess(res)) {
              let data = {
                data: taskResult.data.data.result,
                remap: row_remap,
                shap: taskResult.data.data.shap,
              }
              onSuccess && onSuccess(data)
            } else {
              onFailure && onFailure(taskResult)
            }
          })
      })
      .catch(err => onFailure && onFailure(err))
  }
}

const BatchPrediction = (
  workflowID,
  inputData,
  columns,
  column_info,
  modelIndex,
  onPostSuccess,
  onPostFailure,
  onPollingSuccess,
  onPollingFailure) => {
  let url = 'tasks'
  let config = {
    headers: { "Content-Type": "application/json; charset=utf-8" },
  }
  let pred_data = {}
  columns.input.map(value => pred_data[value] = {})
  let nData = 0;
  let row_remap = {};
  inputData.forEach((row, rowIndex) => {
    if (row === "") {
      return
    }
    let colData = {}
    row.forEach((value, colIndex) => {
      if (colIndex < columns.input.length) {
        if (column_info[columns.input[colIndex]] === "number") {
          colData[columns.input[colIndex]] = Number(value)
        } else {
          colData[columns.input[colIndex]] = value
        }

      }
    })
    // only use complete rows
    if (Object.keys(colData).length === columns.input.length) {
      Object.keys(colData).forEach((col) => {
        pred_data[col][String(rowIndex)] = colData[col]
      });
      row_remap[nData++] = rowIndex
    }
  })
  if (nData === 0) {
    return false
  }
  let operation_args = JSON.stringify({ pred_data: pred_data, model_idx: modelIndex })
  let body = JSON.stringify({
    workflow_id: workflowID,
    operation: 'predict',
    operation_args: operation_args
  })

  aichemyProtoAxios.post(url, body, config)
    .then(res => {
      onPostSuccess && onPostSuccess(res.data);
      TaskPolling(res.data.task_id, row_remap, onPollingSuccess, onPollingFailure)
    }).catch(err => onPostFailure && onPostFailure(err))
  return true
}

export {
  SinglePointPrediction,
  BatchPrediction
}
