import {all} from 'mathjs';

export const mathjsKeywords = Object.values(all).map(v => v.fn);

/**
 * Below values pulled from mathjs docs: https://mathjs.org/docs/index.html
 */

// operators
export const mathjsOperators = [
    ["(, ) - Grouping"],
    ["[, ]", "Matrix, Index, 1 based"],
    ["{, }", "Object"],
    [",", "Parameter separator"],
    [".", "Property accessor"],
    [";", "Statement separator"],
    [";", "Row separator"],
    ["+", "Add"],
    ["+", "Unary plus"],
    ["-", "Subtract"],
    ["-", "Unary minus"],
    ["*", "Multiply"],
    [".*", "Element-wise multiply"],
    ["/", "Divide"],
    ["./", "Element-wise divide"],
    ["%, mod", "Modulus"],
    ["^", "Power"],
    [".^", "Element-wise power"],
    ["'", "Transpose"],
    ["!", "Factorial"],
    ["&", "Bitwise and"],
    ["~", "Bitwise not"],
    ["|", "Bitwise or"],
    ["^|", "Bitwise xor"],
    ["<<", "Left shift"],
    [">>", "Right arithmetic shift"],
    [">>>", "Right logical shift"],
    ["and", "Logical and"],
    ["not", "Logical not"],
    ["or", "Logical or"],
    ["xor", "Logical xor"],
    ["=", "Assignment"],
    ["? :", "<condition> ? <if true> : <if false>"],
    [":", "Range"],
    ["to, in", "Unit conversion"],
    ["==", "Equal"],
    ["!=", "Unequal"],
    ["<", "Smaller"],
    [">", "Larger"],
    ["<=", "Smallereq"],
    [">=", "Largereq"],
];

// constants
export const mathjsConstants = [
    ["e","Euler’s number, the base of the natural logarithm. - 2.718281828459045"],
    ["E","Euler’s number, the base of the natural logarithm. - 2.718281828459045"],
    ["i","Imaginary unit, defined as ii=-1. A complex number is described as a + bi, where a is the real part, and b is the imaginary part. - sqrt(-1)"],
    ["Infinity","Infinity, a number which is larger than the maximum number that can be handled by a floating point number. - Infinity"],
    ["LN2","Returns the natural logarithm of 2. - 0.6931471805599453"],
    ["LN10","Returns the natural logarithm of 10. - 2.302585092994046"],
    ["LOG2E","Returns the base-2 logarithm of E. - 1.4426950408889634"],
    ["LOG10E","Returns the base-10 logarithm of E. - 0.4342944819032518"],
    ["NaN","Not a number. - NaN"],
    ["null","Value null. - null"],
    ["phi","Phi is the golden ratio. Two quantities are in the golden ratio if their ratio is the same as the ratio of their sum to the larger of the two quantities. Phi is defined as (1 + sqrt(5)) / 2 - 1.618033988749895"],
    ["pi","The number pi is a mathematical constant that is the ratio of a circle's circumference to its diameter. - 3.141592653589793"],
    ["PI","The number pi is a mathematical constant that is the ratio of a circle's circumference to its diameter. - 3.141592653589793"],
    ["SQRT1_2","Returns the square root of 1/2. - 0.7071067811865476"],
    ["SQRT2","Returns the square root of 2. - 1.4142135623730951"],
    ["tau","Tau is the ratio constant of a circle's circumference to radius, equal to 2 * pi. - 6.283185307179586"],
    ["undefined","An undefined value. Preferably, use null to indicate undefined values. - undefined"],
    ["version","Returns the version number of math.js. - For example 0.24.1"],
]

// all other functions
export const mathjsFunctions = [
    ["abs", "abs(x) - Calculate the absolute value of a number."],
	["add", "add(x, y) - Add two or more values, x + y."],
	["cbrt", "cbrt(x [, allRoots]) - Calculate the cubic root of a value."],
	["ceil", "ceil(x) - Round a value towards plus infinity If x is complex, both real and imaginary part are rounded towards plus infinity."],
	["cube", "cube(x) - Compute the cube of a value, x * x * x."],
	["divide", "divide(x, y) - Divide two values, x / y."],
	["dotDivide", "dotDivide(x, y) - Divide two matrices element wise."],
	["dotMultiply", "dotMultiply(x, y) - Multiply two matrices element wise."],
	["dotPow", "dotPow(x, y) - Calculates the power of x to y element wise."],
	["exp", "exp(x) - Calculate the exponent of a value."],
	["expm1", "expm1(x) - Calculate the value of subtracting 1 from the exponential value."],
	["fix", "fix(x) - Round a value towards zero."],
	["floor", "floor(x) - Round a value towards minus infinity."],
	["gcd", "gcd(a, b) - Calculate the greatest common divisor for two or more values or arrays."],
	["hypot", "hypot(a, b, …) - Calculate the hypotenusa of a list with values."],
	["lcm", "lcm(a, b) - Calculate the least common multiple for two or more values or arrays."],
	["log", "log(x [, base]) - Calculate the logarithm of a value."],
	["log10", "log10(x) - Calculate the 10-base logarithm of a value."],
	["log1p", "log1p(x) - Calculate the logarithm of a value+1."],
	["log2", "log2(x) - Calculate the 2-base of a value."],
	["mod", "mod(x, y) - Calculates the modulus, the remainder of an integer division."],
	["multiply", "multiply(x, y) - Multiply two or more values, x * y."],
	["norm", "norm(x [, p]) - Calculate the norm of a number, vector or matrix."],
	["nthRoot", "nthRoot(a) - Calculate the nth root of a value."],
	["nthRoots", "nthRoots(x) - Calculate the nth roots of a value."],
	["pow", "pow(x, y) - Calculates the power of x to y, x ^ y."],
	["round", "round(x [, n]) - Round a value towards the nearest integer."],
	["sign", "sign(x) - Compute the sign of a value."],
	["sqrt", "sqrt(x) - Calculate the square root of a value."],
	["square", "square(x) - Compute the square of a value, x * x."],
	["subtract", "subtract(x, y) - Subtract two values, x - y."],
	["unaryMinus", "unaryMinus(x) - Inverse the sign of a value, apply a unary minus operation."],
	["unaryPlus", "unaryPlus(x) - Unary plus operation."],
	["xgcd", "xgcd(a, b) - Calculate the extended greatest common divisor for two values."],
	["derivative", "derivative(expr, variable) - Takes the derivative of an expression expressed in parser Nodes."],
	["lsolve", "lsolve(L, b) - Solves the linear equation system by forwards substitution."],
	["lup", "lup(A) - Calculate the Matrix LU decomposition with partial pivoting."],
	["lusolve", "lusolve(A, b) - Solves the linear system A * x = b where A is an [n x n] matrix and b is a [n] column vector."],
	["qr", "qr(A) - Calculate the Matrix QR decomposition."],
	["rationalize", "rationalize(expr) - Transform a rationalizable expression in a rational fraction."],
	["simplify", "simplify(expr) - Simplify an expression tree."],
	["slu", "slu(A, order, threshold) - Calculate the Sparse Matrix LU decomposition with full pivoting."],
	["usolve", "usolve(U, b) - Solves the linear equation system by backward substitution."],
	["bitAnd", "bitAnd(x, y) - Bitwise AND two values, x & y."],
	["bitNot", "bitNot(x) - Bitwise NOT value, ~x."],
	["bitOr", "bitOr(x, y) - Bitwise OR two values, x | y."],
	["bitXor", "bitXor(x, y) - Bitwise XOR two values, x ^ y."],
	["leftShift", "leftShift(x, y) - Bitwise left logical shift of a value x by y number of bits, x << y."],
	["rightArithShift", "rightArithShift(x, y) - Bitwise right arithmetic shift of a value x by y number of bits, x >> y."],
	["rightLogShift", "rightLogShift(x, y) - Bitwise right logical shift of value x by y number of bits, x >>> y."],
	["bellNumbers", "bellNumbers(n) - The Bell Numbers count the number of partitions of a set."],
	["catalan", "catalan(n) - The Catalan Numbers enumerate combinatorial structures of many different types."],
	["composition", "composition(n, k) - The composition counts of n into k parts."],
	["stirlingS2", "stirlingS2(n, k) - The Stirling numbers of the second kind, counts the number of ways to partition a set of n labelled objects into k nonempty unlabelled subsets."],
	["arg", "arg(x) - Compute the argument of a complex value."],
	["conj", "conj(x) - Compute the complex conjugate of a complex value."],
	["im", "im(x) - Get the imaginary part of a complex number."],
	["re", "re(x) - Get the real part of a complex number."],
	["distance", "distance([x1, y1], [x2, y2]) - Calculates: The eucledian distance between two points in N-dimensional spaces."],
	["intersect", "intersect(endPoint1Line1, endPoint2Line1, endPoint1Line2, endPoint2Line2) - Calculates the point of intersection of two lines in two or three dimensions and of a line and a plane in three dimensions."],
	["and", "and(x, y) - Logical and."],
	["not", "not(x) - Logical not."],
	["or", "or(x, y) - Logical or."],
	["xor", "xor(x, y) - Logical xor."],
	["apply", "apply(A, dim, callback) - Apply a function that maps an array to a scalar along a given axis of a matrix or array."],
	["column", "column(value, index) - Return a column from a Matrix."],
	["concat", "concat(a, b, c, … [, dim]) - Concatenate two or more matrices."],
	["cross", "cross(x, y) - Calculate the cross product for two vectors in three dimensional space."],
	["ctranspose", "ctranspose(x) - Transpose and complex conjugate a matrix."],
	["det", "det(x) - Calculate the determinant of a matrix."],
	["diag", "diag(X) - Create a diagonal matrix or retrieve the diagonal of a matrix When x is a vector, a matrix with vector x on the diagonal will be returned."],
	["dot", "dot(x, y) - Calculate the dot product of two vectors."],
	["eigs", "eigs(x) - Compute eigenvalue and eigenvector of a real symmetric matrix."],
	["expm", "expm(x) - Compute the matrix exponential, expm(A) = e^A."],
	["filter", "filter(x, test) - Filter the items in an array or one dimensional matrix."],
	["flatten", "flatten(x) - Flatten a multi dimensional matrix into a single dimensional matrix."],
	["forEach", "forEach(x, callback) - Iterate over all elements of a matrix/array, and executes the given callback function."],
	["getMatrixDataType", "getMatrixDataType(x) - Find the data type of all elements in a matrix or array, for example ‘number’ if all items are a number and ‘Complex’ if all values are complex numbers."],
	["identity", "identity(n) - Create a 2-dimensional identity matrix with size m x n or n x n."],
	["inv", "inv(x) - Calculate the inverse of a square matrix."],
	["kron", "kron(x, y) - Calculates the kronecker product of 2 matrices or vectors."],
	["map", "map(x, callback) - Create a new matrix or array with the results of the callback function executed on each entry of the matrix/array."],
	["ones", "ones(m, n, p, …) - Create a matrix filled with ones."],
	["partitionSelect", "partitionSelect(x, k) - Partition-based selection of an array or 1D matrix."],
	["range", "range(start, end [, step]) - Create an array from a range."],
	["reshape", "reshape(x, sizes) - Reshape a multi dimensional array to fit the specified dimensions."],
	["resize", "resize(x, size [, defaultValue]) - Resize a matrix."],
	["row", "row(value, index) - Return a row from a Matrix."],
	["size", "size(x) - Calculate the size of a matrix or scalar."],
	["sort", "sort(x) - Sort the items in a matrix."],
	["sqrtm", "sqrtm(A) - Calculate the principal square root of a square matrix."],
	["squeeze", "squeeze(x) - Squeeze a matrix, remove inner and outer singleton dimensions from a matrix."],
	["subset", "subset(x, index [, replacement]) - Get or set a subset of a matrix or string."],
	["trace", "trace(x) - Calculate the trace of a matrix: the sum of the elements on the main diagonal of a square matrix."],
	["transpose", "transpose(x) - Transpose a matrix."],
	["zeros", "zeros(m, n, p, …) - Create a matrix filled with zeros."],
	["combinations", "combinations(n, k) - Compute the number of ways of picking k unordered outcomes from n possibilities."],
	["combinationsWithRep", "combinationsWithRep(n, k) - Compute the number of ways of picking k unordered outcomes from n possibilities, allowing individual outcomes to be repeated more than once."],
	["factorial", "factorial(n) - Compute the factorial of a value Factorial only supports an integer value as argument."],
	["gamma", "gamma(n) - Compute the gamma function of a value using Lanczos approximation for small values, and an extended Stirling approximation for large values."],
	["kldivergence", "kldivergence(x, y) - Calculate the Kullback-Leibler (KL) divergence between two distributions."],
	["multinomial", "multinomial(a) - Multinomial Coefficients compute the number of ways of picking a1, a2, ."],
	["permutations", "permutations(n [, k]) - Compute the number of ways of obtaining an ordered subset of k elements from a set of n elements."],
	["pickRandom", "pickRandom(array) - Random pick one or more values from a one dimensional array."],
	["random", "random([min, max]) - Return a random number larger or equal to min and smaller than max using a uniform distribution."],
	["randomInt", "randomInt([min, max]) - Return a random integer number larger or equal to min and smaller than max using a uniform distribution."],
	["compare", "compare(x, y) - Compare two values."],
	["compareNatural", "compareNatural(x, y) - Compare two values of any type in a deterministic, natural way."],
	["compareText", "compareText(x, y) - Compare two strings lexically."],
	["deepEqual", "deepEqual(x, y) - Test element wise whether two matrices are equal."],
	["equal", "equal(x, y) - Test whether two values are equal."],
	["equalText", "equalText(x, y) - Check equality of two strings."],
	["larger", "larger(x, y) - Test whether value x is larger than y."],
	["largerEq", "largerEq(x, y) - Test whether value x is larger or equal to y."],
	["smaller", "smaller(x, y) - Test whether value x is smaller than y."],
	["smallerEq", "smallerEq(x, y) - Test whether value x is smaller or equal to y."],
	["unequal", "unequal(x, y) - Test whether two values are unequal."],
	["setCartesian", "setCartesian(set1, set2) - Create the cartesian product of two (multi)sets."],
	["setDifference", "setDifference(set1, set2) - Create the difference of two (multi)sets: every element of set1, that is not the element of set2."],
	["setDistinct", "setDistinct(set) - Collect the distinct elements of a multiset."],
	["setIntersect", "setIntersect(set1, set2) - Create the intersection of two (multi)sets."],
	["setIsSubset", "setIsSubset(set1, set2) - Check whether a (multi)set is a subset of another (multi)set."],
	["setMultiplicity", "setMultiplicity(element, set) - Count the multiplicity of an element in a multiset."],
	["setPowerset", "setPowerset(set) - Create the powerset of a (multi)set."],
	["setSize", "setSize(set) - Count the number of elements of a (multi)set."],
	["setSymDifference", "setSymDifference(set1, set2) - Create the symmetric difference of two (multi)sets."],
	["setUnion", "setUnion(set1, set2) - Create the union of two (multi)sets."],
	["erf", "erf(x) - Compute the erf function of a value using a rational Chebyshev approximations for different intervals of x."],
	["mad", "mad(a, b, c, …) - Compute the median absolute deviation of a matrix or a list with values."],
	["max", "max(a, b, c, …) - Compute the maximum value of a matrix or a list with values."],
	["mean", "mean(a, b, c, …) - Compute the mean value of matrix or a list with values."],
	["median", "median(a, b, c, …) - Compute the median of a matrix or a list with values."],
	["min", "min(a, b, c, …) - Compute the minimum value of a matrix or a list of values."],
	["mode", "mode(a, b, c, …) - Computes the mode of a set of numbers or a list with values(numbers or characters)."],
	["prod", "prod(a, b, c, …) - Compute the product of a matrix or a list with values."],
	["quantileSeq", "quantileSeq(A, prob[, sorted]) - Compute the prob order quantile of a matrix or a list with values."],
	["std", "std(a, b, c, …) - Compute the standard deviation of a matrix or a list with values."],
	["sum", "sum(a, b, c, …) - Compute the sum of a matrix or a list with values."],
	["variance", "variance(a, b, c, …) - Compute the variance of a matrix or a list with values."],
	["format", "format(value [, precision]) - Format a value of any type into a string."],
	["print", "print(template, values [, precision]) - Interpolate values into a string template."],
	["acos", "acos(x) - Calculate the inverse cosine of a value."],
	["acosh", "acosh(x) - Calculate the hyperbolic arccos of a value, defined as acosh(x) = ln(sqrt(x^2 - 1) + x)."],
	["acot", "acot(x) - Calculate the inverse cotangent of a value, defined as acot(x) = atan(1/x)."],
	["acoth", "acoth(x) - Calculate the hyperbolic arccotangent of a value, defined as acoth(x) = atanh(1/x) = (ln((x+1)/x) + ln(x/(x-1))) / 2."],
	["acsc", "acsc(x) - Calculate the inverse cosecant of a value, defined as acsc(x) = asin(1/x)."],
	["acsch", "acsch(x) - Calculate the hyperbolic arccosecant of a value, defined as acsch(x) = asinh(1/x) = ln(1/x + sqrt(1/x^2 + 1))."],
	["asec", "asec(x) - Calculate the inverse secant of a value."],
	["asech", "asech(x) - Calculate the hyperbolic arcsecant of a value, defined as asech(x) = acosh(1/x) = ln(sqrt(1/x^2 - 1) + 1/x)."],
	["asin", "asin(x) - Calculate the inverse sine of a value."],
	["asinh", "asinh(x) - Calculate the hyperbolic arcsine of a value, defined as asinh(x) = ln(x + sqrt(x^2 + 1))."],
	["atan", "atan(x) - Calculate the inverse tangent of a value."],
	["atan2", "atan2(y, x) - Calculate the inverse tangent function with two arguments, y/x."],
	["atanh", "atanh(x) - Calculate the hyperbolic arctangent of a value, defined as atanh(x) = ln((1 + x)/(1 - x)) / 2."],
	["cos", "cos(x) - Calculate the cosine of a value."],
	["cosh", "cosh(x) - Calculate the hyperbolic cosine of a value, defined as cosh(x) = 1/2 * (exp(x) + exp(-x))."],
	["cot", "cot(x) - Calculate the cotangent of a value."],
	["coth", "coth(x) - Calculate the hyperbolic cotangent of a value, defined as coth(x) = 1 / tanh(x)."],
	["csc", "csc(x) - Calculate the cosecant of a value, defined as csc(x) = 1/sin(x)."],
	["csch", "csch(x) - Calculate the hyperbolic cosecant of a value, defined as csch(x) = 1 / sinh(x)."],
	["sec", "sec(x) - Calculate the secant of a value, defined as sec(x) = 1/cos(x)."],
	["sech", "sech(x) - Calculate the hyperbolic secant of a value, defined as sech(x) = 1 / cosh(x)."],
	["sin", "sin(x) - Calculate the sine of a value."],
	["sinh", "sinh(x) - Calculate the hyperbolic sine of a value, defined as sinh(x) = 1/2 * (exp(x) - exp(-x))."],
	["tan", "tan(x) - Calculate the tangent of a value."],
	["tanh", "tanh(x) - Calculate the hyperbolic tangent of a value, defined as tanh(x) = (exp(2 * x) - 1) / (exp(2 * x) + 1)."],
	["to", "to(x, unit) - Change the unit of a value."],
	["clone", "clone(x) - Clone an object."],
	["hasNumericValue", "hasNumericValue(x) - Test whether a value is an numeric value."],
	["isInteger", "isInteger(x) - Test whether a value is an integer number."],
	["isNaN", "isNaN(x) - Test whether a value is NaN (not a number)."],
	["isNegative", "isNegative(x) - Test whether a value is negative: smaller than zero."],
	["isNumeric", "isNumeric(x) - Test whether a value is an numeric value."],
	["isPositive", "isPositive(x) - Test whether a value is positive: larger than zero."],
	["isPrime", "isPrime(x) - Test whether a value is prime: has no divisors other than itself and one."],
	["isZero", "isZero(x) - Test whether a value is zero."],
	["numeric", "numeric(x) - Convert a numeric input to a specific numeric type: number, BigNumber, or Fraction."],
	["typeOf", "typeOf(x) - Determine the type of a variable."],
];